import type { FC } from 'react';
import { forwardRef, useCallback, useContext, useState } from 'react';

import Alert from '@/Alert';
import PlanContext from '@/PlanContext';

import { useSendEmailFromTemplateForTimeZest } from '../hooks';
import { INITIAL_CALENDAR_TEMPLATE, RECIPIENTS } from '../constants';
import FormError from '../FormError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import Action from '@shared/Action';
import type { Content as LineContent } from '@shared/LineEditor';
import LineEditor, { defaultTemplate as defaultLineTemplate } from '@shared/LineEditor';
import type { Content as NoteContent } from '@shared/NoteEditor';
import NoteEditor from '@shared/NoteEditor';
import RadioButtonRow from '@ui/RadioButtonRow';
import Row from '@ui/Row';
import EmailTemplateSelect from '@ui/EmailTemplateSelect';
import type { EmailTemplate, TimeZestSendEmailFromTemplateAction } from '@graphql/generated';
import { TimeZestSendEmailFromTemplateRecipientsType } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will send an email to the client, or <strong>all</strong> scheduled users,
      using the configured template. Templates can be edited in <a href="/settings/email">Email Settings</a>.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When configured to send to scheduled users, and TimeZest has not yet selected which users are to be scheduled.
      </li>
    </ul>
    <p>
      TimeZest decides which users to schedule (primarily when scheduling one user from a team), based on availability,
      when these users select a time for their appointment. For triggers which run before the client has selected a
      time, these users will not be known.
    </p>
  </>
);

const Summary: FC<{ recipients: string; emailTemplateName: string; guestsListAllowed: boolean }> = ({
  recipients,
  emailTemplateName,
  guestsListAllowed,
}) => (
  <>
    Send an email to the {recipient(recipients, guestsListAllowed)} using the <strong>{emailTemplateName}</strong>{' '}
    template.
  </>
);

const SendEmailFromTemplate = forwardRef<HTMLFormElement, WorkflowActionProps<TimeZestSendEmailFromTemplateAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { allowsGuestInvitations: guestsListAllowed } = useContext(PlanContext);
    const { templateMode } = useContext(WorkflowActionContext);

    const recipientsList = guestsListAllowed
      ? RECIPIENTS
      : RECIPIENTS.filter(r => r.value !== TimeZestSendEmailFromTemplateRecipientsType.ClientWithGuests);
    const recipientOptions = recipientsList.map(r => ({ ...r, disabled: readOnly }));
    const { errors: mutationErrors, loading, succeeded, submit } = useSendEmailFromTemplateForTimeZest();
    const [calendarInvite, setCalendarInvite] = useStateFromProp(action.attachCalendarInvite);
    const [showAlert, setShowAlert] = useState(false);
    const [recipients, setRecipients] = useStateFromProp(
      action.recipients,
      recipients => recipients || (guestsListAllowed ? 'client_with_guests' : 'client')
    );

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      const attachCalendarInvite = data.get('attach_calendar_invite') === 'true';
      const calendarTemplate = attachCalendarInvite
        ? ((data.get('calendar_template') || '') as string)
        : JSON.stringify(action.calendarTemplate || INITIAL_CALENDAR_TEMPLATE);

      return {
        attachCalendarInvite,
        calendarTemplate,
        location: data.get('location') as string,
        emailTemplateId: (data.get('email_template_id') || '') as string,
        recipients: data.get('recipients') as TimeZestSendEmailFromTemplateRecipientsType,
      };
    });

    const handleSelectLegacyEmailTemplate = useCallback((emailTemplate: EmailTemplate | undefined) => {
      setShowAlert(emailTemplate?.legacy || false);
    }, []);

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="email"
            summary={
              <Summary
                emailTemplateName={action.emailTemplateName}
                recipients={action.recipients || (guestsListAllowed ? 'client_with_guests' : 'client')}
                guestsListAllowed={guestsListAllowed}
              />
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              label="Recipient"
              name="recipients"
              value={!guestsListAllowed ? (recipients === 'client_with_guests' ? 'client' : recipients) : recipients}
              options={recipientOptions}
              error={errors.recipients}
              readOnly={readOnly}
              onChange={r => setRecipients(r as TimeZestSendEmailFromTemplateRecipientsType)}
            />

            <EmailTemplateSelect
              name="email_template_id"
              value={action.emailTemplateId}
              readOnly={readOnly}
              templateType="all"
              label="Template"
              helpText="TimeZest will use this template to send an email."
              error={errors.email_template_id}
              templateMode={templateMode}
              onChange={handleSelectLegacyEmailTemplate}
            />
            {showAlert && (
              <Row label="" width={6}>
                <Alert
                  icon="triangle-exclamation"
                  color="yellow"
                  className="mt-0"
                  content="This is a legacy email template, which TimeZest will only support until May 30, 2024. This
                template should be replaced with a new email template created using the new WYSIWYG email
                editor."
                />
              </Row>
            )}

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              label="Calendar Invite"
              name="attach_calendar_invite"
              value={(calendarInvite || false).toString()}
              options={[
                { label: 'Send email with a calendar invite attached.', value: 'true', disabled: readOnly },
                { label: 'Send email without a calendar invite attached.', value: 'false', disabled: readOnly },
              ]}
              error={errors.attach_calendar_invite}
              readOnly={readOnly}
              onChange={newValue => setCalendarInvite(newValue === 'true')}
            />

            {calendarInvite && (
              <>
                <Row label="Calendar Template" width={10}>
                  <NoteEditor
                    name="calendar_template"
                    initialValue={(action.calendarTemplate || INITIAL_CALENDAR_TEMPLATE) as NoteContent}
                    readOnly={readOnly}
                  />
                </Row>

                <Row
                  label="Location"
                  helpText={
                    <>
                      TimeZest will use value of this custom attribute in the location field of the calendar invite sent
                      to the {recipient(recipients, guestsListAllowed)}.
                    </>
                  }
                >
                  <LineEditor
                    name="location"
                    initialValue={(action.location || defaultLineTemplate) as LineContent}
                    readOnly={readOnly}
                  />
                </Row>
              </>
            )}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

function recipient(recipients: string, guestsListAllowed: boolean): string {
  switch (recipients) {
    case 'client':
      return 'client';
    case 'client_with_guests':
      if (guestsListAllowed) {
        return 'client and any invited guests';
      }

      return 'client';
    default:
      return 'scheduled user(s)';
  }
}

export default SendEmailFromTemplate;

import type { FC, PropsWithChildren } from 'react';

import sha256 from 'sha256';
import fetch from 'cross-fetch';

import type { FetchResult } from '@apollo/client';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';

const httpLink = new HttpLink({ fetch, uri: '/graphql' });
const persistedQueryLink = createPersistedQueryLink({ sha256 });
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: persistedQueryLink.concat(httpLink),
});

export const GqlProvider: FC<PropsWithChildren<{}>> = ({ children }) => (
  <ApolloProvider client={(window as any).apolloClient || client}>{children}</ApolloProvider>
);

export function refetchOnSuccess(operation: string, ...queries: string[]) {
  return (result: FetchResult) => {
    if (result.errors) return [];

    const data = result.data;
    const payload = data?.[operation];

    if (payload?.errors && Object.keys(payload.errors).length > 0) return [];

    return queries;
  };
}

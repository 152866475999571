import { forwardRef, useContext, useMemo, useState } from 'react';

import LocationAndStatusRow from '@/ui/LocationAndStatusRow';
import ActivityOptions from '@/connectWise/ActivityOptions';

import FormError from '../FormError';
import { useCreateActivityForConnectWise } from '../hooks';

import ReminderRow from './ReminderRow';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { Content } from '@shared/NoteEditor';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import Row from '@ui/Row';
import type { ConnectWiseCreateActivityAction } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create an activity in ConnectWise PSA <strong>only</strong> for those
      users who have ConnectWise PSA configured as their calendar to write to.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When the scheduling request has been cancelled.</li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
    <p>
      This is a <strong>critical action</strong>, which means that if it doesn&apos;t complete successfully, no actions
      for this trigger after it will be executed.
    </p>
  </>
);

const CreateActivity = forwardRef<HTMLFormElement, WorkflowActionProps<ConnectWiseCreateActivityAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const [locationsAndStatusesLoaded, setLocationsAndStatusesLoaded] = useState(false);
    const [optionsLoaded, setOptionsLoaded] = useState(false);
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateActivityForConnectWise();
    const handleLocationAndStatusesLoaded = useMemo(() => {
      return () => setLocationsAndStatusesLoaded(true);
    }, [setLocationsAndStatusesLoaded]);
    const handleOptionsLoaded = useMemo(() => {
      return () => setOptionsLoaded(true);
    }, [setOptionsLoaded]);

    const formEnabled = optionsLoaded && locationsAndStatusesLoaded;

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      const reminder = data.get('psa_reminder_id');

      return {
        locationId: (data.get('psa_service_location_id') || '') as string,
        notes: (data.get('notes') || '') as string,
        reminderId: reminder ? (reminder as string) : null,
        scheduleStatusId: (data.get('psa_schedule_status_id') || '') as string,
        statusId: (data.get('psa_status_id') || '') as string,
        typeId: (data.get('psa_type_id') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="task-plus"
            summary={
              <>
                Create an <strong>activity</strong> in ConnectWise PSA.
              </>
            }
            readOnly={readOnly || !formEnabled}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <ActivityOptions
              disabled={readOnly}
              readOnly={readOnly}
              typeError={errors.psa_type_id}
              typeFieldName="psa_type_id"
              statusError={errors.psa_status_id}
              statusFieldName="psa_status_id"
              selectedStatusId={Number(action.psaStatusId)}
              selectedTypeId={Number(action.psaTypeId)}
              templateMode={templateMode}
              onLoaded={handleOptionsLoaded}
            />

            <LocationAndStatusRow
              disabled={readOnly}
              readOnly={readOnly}
              locationError={errors.psa_service_location_id}
              locationFieldName="psa_service_location_id"
              statusError={errors.psa_schedule_status_id}
              statusFieldName="psa_schedule_status_id"
              selectedScheduleStatusId={String(action.psaScheduleStatusId)}
              selectedServiceLocationId={String(action.psaServiceLocationId)}
              templateMode={templateMode}
              onLoaded={handleLocationAndStatusesLoaded}
            />

            <Row label="Notes" width={10}>
              <NoteEditor
                name="notes"
                initialValue={(action.notes as Content) || defaultTemplate}
                readOnly={readOnly}
              />
            </Row>

            <ReminderRow
              readOnly={readOnly}
              error={errors.psa_reminder_id}
              value={action.psaReminderId}
              templateMode={templateMode}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateActivity;

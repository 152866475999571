import type { FC } from 'react';
import { useContext, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import ConfirmationModal from '@/ui/ConfirmationModal';

import ConfigContext from '../ConfigContext';
import { changeNumberOfColumns, updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import type { IndexedMjmlSection } from '../EmailTemplate';

import SpaceInput from './Inputs/Space';
import ColorInput from './Inputs/Color';
import Input from './Inputs/Input';
import ConditionalSetter from './shared/ConditionalSetter';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';

const SectionPropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

export const Option = styled.div`
  background-color: var(--tz-grey-200);
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
`;

interface Props {
  node: IndexedMjmlSection;
}

const SectionProperties: FC<Props> = ({ node }) => {
  const { readOnly } = useContext(ConfigContext);

  const [numberOfColumnDesired, setNumberOfColumnDesired] = useState<number | null>(null);
  const [showConfirmationChangeColumns, setShowConfirmationChangeColumns] = useState(false);

  const dispatch = useAppDispatch();

  const changeColumns = (newNumber: number) => {
    if (newNumber === node.childIds.length) return;

    if (newNumber < node.childIds.length) {
      setNumberOfColumnDesired(newNumber);
      setShowConfirmationChangeColumns(true);
    } else {
      dispatch(changeNumberOfColumns({ parentId: node.id, numberOfColumnDesired: newNumber }));
    }
  };

  const handleCloseModal = () => {
    setNumberOfColumnDesired(null);
    setShowConfirmationChangeColumns(false);
  };

  const handleConfirmChangeNumberOfColumns = () => {
    dispatch(changeNumberOfColumns({ parentId: node.id, numberOfColumnDesired: numberOfColumnDesired || 0 }));
    setShowConfirmationChangeColumns(false);
    setNumberOfColumnDesired(null);
  };

  const handleOnChange = (property: string) => (value: any) => {
    dispatch(updateProperty({ elementId: node.id, property, value }));
  };

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this section and all its content. Are you sure?"
    >
      <SectionPropertiesContainer>
        <PropertiesCollapsible title="SECTION PROPERTIES">
          <ColorInput
            id={`${node.id}-background-color`}
            label="Background Color"
            name="backgroundColor"
            value={node.backgroundColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('backgroundColor')}
          />

          <Input
            value={node.width || '600px'}
            type="number"
            id={`${node.id}-width`}
            label="Width"
            min={1}
            unit="px"
            disabled={readOnly}
            onChange={handleOnChange('width')}
            onInput={handleOnChange('width')}
          />

          <SpaceInput
            id={`${node.id}-padding`}
            label="Padding"
            value={node.padding || {}}
            disabled={readOnly}
            onInput={handleOnChange('padding')}
          />
        </PropertiesCollapsible>

        <PropertiesCollapsible title="DISPLAY CONDITIONS">
          <ConditionalSetter nodeId={node.id} nodeType={node.type} condition={node.condition} disabled={readOnly} />
        </PropertiesCollapsible>

        <PropertiesCollapsible title="COLUMNS">
          <ul className={classNames('d-flex flex-column gap-2 p-0 m-0', { 'pe-none opacity-50': readOnly })}>
            {Array(1, 2, 3, 4).map(i => {
              return (
                <Option key={i.toString()} className="list-unstyled">
                  {renderSectionOptions(i.toString(), i, changeColumns)}
                </Option>
              );
            })}
          </ul>
        </PropertiesCollapsible>
      </SectionPropertiesContainer>

      <ConfirmationModal
        title="Delete Columns"
        description={`You will lose ${node.childIds.length - numberOfColumnDesired!} column${
          node.childIds.length - numberOfColumnDesired! > 1 ? 's' : ''
        } and all content on them. Are you sure?`}
        ariaLabel="Delete Columns"
        isOpen={showConfirmationChangeColumns}
        onConfirm={handleConfirmChangeNumberOfColumns}
        onClose={handleCloseModal}
      />
    </PropertiesContainer>
  );
};

export default SectionProperties;

const renderSectionOptions = (
  parentId: string,
  numberOfColumnDesired: number,
  changeColumns: (newNumber: number) => void
): JSX.Element => {
  return (
    <button
      type="button"
      className="w-100 p-0 border border-dark opacity-25 d-flex justify-content-center align-items-center"
      onClick={() => changeColumns(numberOfColumnDesired)}
    >
      {Array(numberOfColumnDesired)
        .fill(0)
        .map((i, index) => (
          <div key={`${parentId}${index.toString()}`} className="border border-dark flex-grow-1 py-4" />
        ))}
    </button>
  );
};

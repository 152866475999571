import type { FC } from 'react';

import Row from '@/ui/Row';

const DefaultEmailTemplate: FC = () => {
  return (
    <Row
      label="Default Email Template"
      helpText="Edit default template that is used for new email templates and send email actions."
      withInput={false}
    >
      <a href="/settings/default_email_template/edit">Edit Default Email Template</a>
    </Row>
  );
};

export default DefaultEmailTemplate;

import type { FC } from 'react';

import classnames from 'classnames';

import { useAppDispatch } from './hooks';
import { setDraggingElement } from './actions';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  selected?: boolean;
}

const DragHandle: FC<Props> = ({ selected = true }) => {
  const dispatch = useAppDispatch();

  return (
    <button
      className={classnames('pe-0', 'ps-1', {
        EmailEditor__Drag_Handle: true,
        'd-inline-block': selected,
      })}
      onMouseDown={() => dispatch(setDraggingElement({ draggingElement: true }))}
    >
      <FontAwesomeIcon icon="arrows" style={{ color: '#c1c1c1' }} size="sm" />
    </button>
  );
};

export default DragHandle;

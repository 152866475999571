import type { FC } from 'react';
import { forwardRef } from 'react';

import FormError from '../FormError';
import { useUpdateTaskToPresetStateForServiceNow } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { ServiceNowUpdateTaskToPresetStateAction } from '@graphql/generated';
import PlainTextRow from '@ui/PlainTextRow';
import SelectRow from '@ui/SelectRow';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const STATE_NAMES = [
  { value: 'after_cancel', name: 'State after cancellation', summaryName: 'after-cancellation' },
  { value: 'after_create', name: ' State after creation', summaryName: 'after-creation' },
  { value: 'after_schedule', name: 'State after scheduling', summaryName: 'after-scheduling' },
  { value: 'no_response', name: 'No-Response state', summaryName: 'no-response' },
];

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update the state in ServiceNow of a task associated with this
      scheduling request to the state configured on{' '}
      <a href="/settings/integrations/service_now">ServiceNow integration</a> page.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no task associated with this scheduling request.</li>
      <li>When the configured state cannot be found in ServiceNow, or is marked as &apos;inactive&apos;.</li>
      <li>When no ServiceNow integration is configured.</li>
    </ul>
  </>
);

const Summary: FC<{ selectedStateType: string }> = ({ selectedStateType }) => {
  const stateDisplay = STATE_NAMES.find(sn => sn.value === selectedStateType)?.summaryName;

  return (
    <>
      Update the <strong>task</strong> in ServiceNow to the <strong>{stateDisplay}</strong> state configured.
    </>
  );
};

const UpdateTaskToPresetState = forwardRef<
  HTMLFormElement,
  WorkflowActionProps<ServiceNowUpdateTaskToPresetStateAction>
>(({ action, readOnly, saveable }, ref) => {
  const { errors: mutationErrors, loading, succeeded, submit } = useUpdateTaskToPresetStateForServiceNow();

  const HELP_TEXT = (
    <>
      TimeZest will update the task to the state configured on the{' '}
      <a href="/settings/integrations/service_now">ServiceNow integration</a> page.
    </>
  );

  const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
    return {
      stateType: (data.get('state_type') || '') as string,
    };
  });

  const errors = action.errors || mutationErrors;

  return (
    <ErrorBoundary>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Action
          action={action}
          details={DETAILS}
          icon="collapse-right"
          summary={<Summary selectedStateType={action.stateType} />}
          readOnly={readOnly}
          saveable={saveable}
          saving={loading}
          succeeded={succeeded}
        >
          <FormError action={action} errors={errors} />

          {readOnly ? (
            <PlainTextRow label="State" helpText={HELP_TEXT}>
              {STATE_NAMES.find(sn => sn.value === action.stateType)?.name}
            </PlainTextRow>
          ) : (
            <SelectRow
              label="State"
              helpText={HELP_TEXT}
              name="state_type"
              options={STATE_NAMES}
              error={errors.state}
              value={action.stateType}
            />
          )}
        </Action>
      </form>
    </ErrorBoundary>
  );
});

export default UpdateTaskToPresetState;

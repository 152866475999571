import type { FC } from 'react';

import YesNoConfirmation from '@/YesNoConfirmation';

import type { IntegrationSettings } from './type';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  authUrl: string;
  newIntegrationUrl?: string;
  removeIntegrationUrl: string;
  integration: IntegrationSettings | null;
  integrationIsProcessing: boolean;
}

const IntegrationStatus: FC<Props> = ({
  authUrl,
  newIntegrationUrl,
  removeIntegrationUrl,
  integration,
  integrationIsProcessing,
}) => {
  if (!integration) {
    return (
      <div className="d-flex">
        <div className="flex-grow-1 fw-bold pt-2">
          TimeZest has not yet been connected with your Microsoft 365 tenant.
        </div>
        <a href={newIntegrationUrl || authUrl} className="btn btn-primary">
          <FontAwesomeIcon icon="sign-in-alt" /> &nbsp; Authenticate with Microsoft 365
        </a>
      </div>
    );
  }

  if (integrationIsProcessing) {
    return (
      <div className="text-success">
        <FontAwesomeIcon spin icon="circle-notch" />
        &nbsp;&nbsp;
        <span className="fw-bold">
          TimeZest is connecting your scheduleable users with their Microsoft Teams accounts.
        </span>
        <div className="progress mt-2">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            aria-valuenow={integration.progress || 0}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: `${integration.progress}%` }}
          />
        </div>
      </div>
    );
  }

  if (integration.state === 'connected') {
    return (
      <div className="d-flex">
        <div className="flex-grow-1">
          <span className="text-success">
            <FontAwesomeIcon icon="circle-check" />
            &nbsp;&nbsp;
            <span className="fw-bold">TimeZest has been successfully connected to your Microsoft 365 tenant.</span>
          </span>
        </div>
        <YesNoConfirmation className="d-flex" url={removeIntegrationUrl} text="Remove This Integration" />
      </div>
    );
  }

  return (
    <div className="d-flex">
      <div className="flex-grow-1 text-danger pt-2">
        <FontAwesomeIcon icon="circle-xmark" />
        &nbsp;&nbsp;
        <span className="fw-bold">TimeZest was not able to connect with your Microsoft 365 tenant.</span>
      </div>
      <a className="btn btn-primary flex-shrink-0" href={authUrl}>
        <FontAwesomeIcon icon="sign-in-alt" /> &nbsp; Authenticate with Microsoft 365 to retry
      </a>
    </div>
  );
};

export default IntegrationStatus;

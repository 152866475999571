import type { FC } from 'react';
import { useEffect } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetCompanyTodoOptionsForAutotaskQuery } from '@graphql/generated';

const HELP_TEXT = 'TimeZest will create the new To-Do with this Action Type.';

interface Props {
  selectedTypeId: number;
  fieldName: string;
  disabled?: boolean;
  readOnly?: boolean;
  error: string | null;
  templateMode?: boolean;
  onLoaded?: () => void;
}

const CompanyTodoOptions: FC<Props> = ({
  disabled,
  readOnly,
  error,
  fieldName,
  selectedTypeId,
  templateMode,
  onLoaded,
}) => {
  const queryResult = useGetCompanyTodoOptionsForAutotaskQuery({ skip: templateMode });
  const loaded = !queryResult.loading && queryResult.data?.autotask.companyTodoTypes;

  useEffect(() => {
    if (loaded && onLoaded) onLoaded();
  }, [loaded, onLoaded]);

  return (
    <SelectRowFromGraphQL
      label="Action Type"
      field="autotask.companyTodoTypes"
      name={fieldName}
      error={error || undefined}
      value={(selectedTypeId || '').toString()}
      disabled={disabled}
      readOnly={readOnly}
      templateMode={templateMode}
      helpText={HELP_TEXT}
      queryResult={queryResult}
      emptyDataMessage="Something went wrong when TimeZest attempted to load action types from Autotask."
    />
  );
};

export default CompanyTodoOptions;

import { useContext, type FC } from 'react';

import Row from '@/ui/Row';
import CurrentUserContext from '@/CurrentUserContext';
import AccountContext from '@/AccountContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const TwoFactorAuthenticationSettings: FC = () => {
  const { twoFactorEnabled } = useContext(CurrentUserContext);
  const { twoFactorForced } = useContext(AccountContext);

  return (
    <Row label="Two-Factor Authentication" width={6}>
      <>
        {twoFactorEnabled ? (
          <div className="text-success mb-2">
            <FontAwesomeIcon icon="check" /> &nbsp; 2FA is enabled for your user account.
          </div>
        ) : (
          <div className="text-danger mb-2">
            <FontAwesomeIcon icon="times" /> &nbsp; 2FA is not enabled for your user account.
          </div>
        )}

        <div className="small text-muted mb-2">
          Two-factor authentication provides additional security when logging in to TimeZest. TimeZest supports 2FA with
          authenticator apps such as Google Authenticator, or Authy.
        </div>

        {twoFactorEnabled ? (
          twoFactorForced ? (
            <>
              <button className="btn btn-danger" disabled={true}>
                Disable Two-Factor Authentication
              </button>
              <p className="small text-muted mb-0 mt-2">
                Two-factor authentication is required for all users, and cannot be disabled.
              </p>
            </>
          ) : (
            <a
              className="btn btn-danger"
              data-confirm="Are you sure?"
              rel="nofollow"
              data-method="delete"
              href="/profile/two_factor"
            >
              Disable Two-Factor Authentication
            </a>
          )
        ) : (
          <a className="btn btn-primary" href="/profile/two_factor/new">
            Enable Two-Factor Authentication &nbsp;
            <FontAwesomeIcon icon="angle-right" />
          </a>
        )}
      </>
    </Row>
  );
};

export default TwoFactorAuthenticationSettings;

import type { FC } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import IntegrationStatus from './IntegrationStatus';

import UsersList from '../../UsersList';

interface Props {
  authUrl: string;
  users: { connected: boolean; name: string }[];
  usersCount: number;
  usersPerPage: number;
  integrationAvailable: boolean;
}

const Zoom: FC<Props> = ({ authUrl, integrationAvailable, users, usersCount, usersPerPage }) => {
  return (
    <>
      <Header title="Zoom Integration" />
      <Flash />
      <IntegrationStatus authUrl={authUrl} integrationAvailable={integrationAvailable} />
      <hr />
      <p>
        TimeZest&apos;s integration with Zoom allows TimeZest to automatically create a Zoom online meeting for an
        appointment, and include the relevant details in the emails and internal notes it creates for the appointment.
      </p>
      <p>
        Adding a Zoom online meeting to an appointment is configured on a per-appointment-type basis &mdash; after
        connecting the integration, go to <a href="/settings/appointment_types">Appointment Types</a> to configure it
        for your appointment types.
      </p>
      {integrationAvailable && (
        <>
          <hr />
          <UsersList
            users={users}
            usersCount={usersCount}
            usersPerPage={usersPerPage}
            connectedMessage="This user is associated with a Zoom user account."
            notConnectedMessage="This user is not associated with a Zoom user account."
          />
        </>
      )}
    </>
  );
};

export default asScreen(Zoom);

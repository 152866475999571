import type { FC } from 'react';

import ReactPaginate from 'react-paginate';

interface Props {
  totalPages: number | undefined;
  currentPage: number;
  searchTerm: string;
  perPage: number;
  setSearch: React.Dispatch<
    React.SetStateAction<{
      currentPage: number;
      searchTerm: string;
    }>
  >;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: FC<Props> = ({ totalPages, currentPage, searchTerm, perPage, setSearch, setOffset }) => {
  if (totalPages === undefined) {
    return null;
  }

  if (totalPages <= 1) {
    return null;
  }

  return (
    <ReactPaginate
      pageCount={totalPages}
      pageRangeDisplayed={currentPage}
      marginPagesDisplayed={2}
      containerClassName="pagination justify-content-center mb-0 mt-3"
      disableInitialCallback={true}
      forcePage={currentPage - 1}
      breakClassName="page-item"
      breakLinkClassName="page-link"
      pageClassName="page-item"
      previousClassName="page-item"
      nextClassName="page-item"
      pageLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      activeClassName="active"
      onPageChange={({ selected }) => {
        setOffset(selected * perPage);
        setSearch({ currentPage: selected + 1, searchTerm });
      }}
    />
  );
};

export default Pagination;

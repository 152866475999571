import { type FC, useContext } from 'react';

import AccountContext from '@/AccountContext';

import ActiveAccount from './ActiveAccount';
import TrialAccount from './TrialAccount';
import TrialExpiredAccount from './TrialExpiredAccount';
import CancelledAccount from './CancelledAccount';

import type { BillingModel } from '@models/Billing';

interface Props {
  billing: BillingModel;
}

const Billing: FC<Props> = ({ billing }) => {
  const { status } = useContext(AccountContext);

  if (status === 'active') {
    return <ActiveAccount billing={billing} />;
  }

  if (status === 'trial') {
    return <TrialAccount billing={billing} />;
  }

  if (status === 'trial_expired') {
    return <TrialExpiredAccount billing={billing} />;
  }

  return <CancelledAccount billing={billing} />;
};

export default Billing;

import type { FC } from 'react';

import type { ContentState, ContentBlock, SelectionState } from 'draft-js';
import { EditorBlock } from 'draft-js';

interface Props {
  block: ContentBlock;
  contentState: ContentState;
  selection: SelectionState;
  blockProps: {
    pushContentState: (newContentState: ContentState) => void;
    readOnly: boolean;
    setReadOnly: (readOnly: boolean) => void;
  };
}

const Paragraph: FC<Props> = props => {
  return (
    <div className="LineEditor__Paragraph">
      <div className="LineEditor__ParagraphContent">
        <EditorBlock {...props} />
      </div>
    </div>
  );
};

export default Paragraph;

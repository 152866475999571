import map from 'lodash/map';
import keyBy from 'lodash/keyBy';

import { generateId } from '../utilities';

import type { Index, TemplateState } from './templateReducer';
import { getElementFromIndex, insertChildId } from './utilities';

import type { ColumnProperties, NodeType } from '@models/EmailTemplate';

const insertElement: (
  state: TemplateState,
  type: NodeType,
  parentId: string,
  insertionIndex: number,
  defaultProperties: {}
  // eslint-disable-next-line max-params
) => TemplateState = (state, type, parentId, insertionIndex, defaultProperties) => {
  const [id, index] = createElement(parentId, type, defaultProperties);
  const parent = getElementFromIndex(state, parentId);
  const newParent = { ...parent, childIds: insertChildId(parent.childIds, id, insertionIndex) };

  return {
    ...state,
    index: { ...state.index, ...index, [parentId]: newParent },
  };
};

function createElement(parentId: string, type: NodeType, defaultProperties: {}): [string, Index] {
  switch (type) {
    case 'mjml-section':
      return newSection(parentId, defaultProperties);
    default:
      return newElement(parentId, type, defaultProperties);
  }
}

function newElement(parentId: string, type: NodeType, defaultProperties: {}): [string, Index] {
  const id = generateId();
  const node = { id, parentId, type, childIds: [], ...defaultProperties };
  return [id, { [id]: node }];
}

function newSection(parentId: string, defaultProperties: { columnProperties?: ColumnProperties[] }): [string, Index] {
  const id = generateId();
  const columns = (defaultProperties.columnProperties || []).map(properties => {
    return {
      id: generateId(),
      parentId: id,
      childIds: [],
      type: 'mjml-column' as NodeType,
      ...properties,
    };
  });

  const node = { id, parentId, type: 'mjml-section' as NodeType, childIds: map(columns, 'id'), ...defaultProperties };
  return [id, { [id]: node, ...keyBy(columns, 'id') }];
}

export default insertElement;

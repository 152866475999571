import type { FC } from 'react';

import Description from './Description';
import Header from './Header';

import type { AppointmentType } from '@graphql/generated';

interface Props {
  appointmentType: AppointmentType;
}

const AppointmentTypeSummary: FC<Props> = ({ appointmentType }) => {
  return (
    <div className="col-4">
      <a className="appointment-type" href={`/settings/appointment_types/${appointmentType.id}`}>
        <div className="card h-100 mb-2">
          <Header appointmentType={appointmentType} />
          <div className="card-body appointment-type-card py-2">
            <Description appointmentType={appointmentType} />
          </div>
        </div>
      </a>
    </div>
  );
};

export default AppointmentTypeSummary;

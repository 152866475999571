import { forwardRef, useMemo } from 'react';

import Row from '@/ui/Row';

import FormError from '../FormError';
import { useSendMessageToScheduledUsersForMSTeams } from '../hooks';
import BaseError from '../BaseError';

import Action from '@shared/Action';
import type { Content } from '@shared/NoteEditor';
import NoteEditor from '@shared/NoteEditor';
import type { TeamsSendMessageToScheduledUsersAction } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import useFormSubmit from '@shared/hooks/useFormSubmit';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will interpolate actual values into the message, and post it in a chat
      message to the scheduled users in Microsoft Teams.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following circumstances:</p>
    <ul className="mb-3">
      <li>When no longer connected to a Microsoft Teams (Messaging) integration.</li>
      <li>When none of the scheduled users are associated with a Microsoft Teams user account.</li>
    </ul>
  </>
);

const SendMessageToScheduledUsers = forwardRef<
  HTMLFormElement,
  WorkflowActionProps<TeamsSendMessageToScheduledUsersAction>
>(({ action, readOnly, saveable }, ref) => {
  const initialNote = useMemo(() => {
    return (action.message || []) as Content;
  }, [action.message]);
  const { errors: mutationErrors, loading, succeeded, submit } = useSendMessageToScheduledUsersForMSTeams();

  const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
    return {
      message: (data.get('message') || '') as string,
    };
  });

  const errors = action.errors || mutationErrors;

  return (
    <ErrorBoundary>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Action
          action={action}
          details={DETAILS}
          icon="message"
          summary="Post a chat message in Microsoft Teams to the scheduled users."
          readOnly={readOnly}
          saveable={saveable}
          saving={loading}
          succeeded={succeeded}
        >
          <FormError action={action} errors={errors} />

          <Row label="Message" width={10}>
            <NoteEditor name="message" initialValue={initialNote} readOnly={readOnly} />
          </Row>

          {errors.base && <BaseError errorMessage={errors.base} />}
        </Action>
      </form>
    </ErrorBoundary>
  );
});

export default SendMessageToScheduledUsers;

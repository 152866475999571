import type { FC } from 'react';

import DurationWithBusinessHours from './DurationWithBusinessHours';

import type { DurationUnit } from '@models/AppointmentType';
import Row from '@ui/Row';

interface Props {
  nameDuration: string;
  nameUnit: string;
  duration: number;
  unit: DurationUnit;
  label: string;
  helpText: string | JSX.Element | null;
  hintText?: string | JSX.Element;
  error: string;
}

const DurationWithBusinessHoursRow: FC<Props> = ({
  nameDuration,
  nameUnit,
  duration,
  unit,
  label,
  helpText,
  hintText,
  error,
}) => {
  return (
    <Row label={label} helpText={helpText}>
      <DurationWithBusinessHours
        nameDuration={nameDuration}
        nameUnit={nameUnit}
        initialDuration={duration}
        initialUnit={unit}
        error={error}
      />
      {hintText && <p className="mt-3 mb-0">{hintText}</p>}
    </Row>
  );
};

export default DurationWithBusinessHoursRow;

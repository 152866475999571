import type { FC } from 'react';
import { useContext } from 'react';

import styled from 'styled-components';

import ConfigContext from '../ConfigContext';
import { updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import type { IndexedMjmlLogo } from '../EmailTemplate';

import SpaceInput from './Inputs/Space';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';

const LogoPropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

interface Props {
  node: IndexedMjmlLogo;
}

const LogoProperties: FC<Props> = ({ node }) => {
  const { readOnly } = useContext(ConfigContext);

  const dispatch = useAppDispatch();

  const handleOnChange = (property: string) => (newValue: any) => {
    dispatch(updateProperty({ elementId: node.id, property, value: newValue }));
  };

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this Logo. Are you sure?"
    >
      <LogoPropertiesContainer>
        <PropertiesCollapsible title="LOGO PROPERTIES">
          <SpaceInput
            id={`${node.id}-padding`}
            label="Padding"
            value={node.padding || {}}
            disabled={readOnly}
            onInput={handleOnChange('padding')}
          />
        </PropertiesCollapsible>
      </LogoPropertiesContainer>
    </PropertiesContainer>
  );
};

export default LogoProperties;

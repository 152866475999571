import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a Zoom online meeting, and associate it with the scheduling
      request.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When a Zoom online meeting is already associated with the scheduling request.</li>
      <li>When no Zoom integration is configured.</li>
    </ul>
  </>
);

const CreateMeeting: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="camera"
      summary={
        <>
          Create a <strong>online meeting</strong> in Zoom.
        </>
      }
      readOnly={readOnly}
    >
      <p>Schedule Zoom meeting for the appointment.</p>
    </Action>
  </ErrorBoundary>
);

export default CreateMeeting;

import type { FC } from 'react';

import { displayAvailability } from '@/utilities';

import type { AvailabilityBlock } from '@models/Availability';

interface Props {
  availabilityAllowed: boolean;
  availabilityBlocks: AvailabilityBlock[];
  usesCustomAvailability: boolean;
}

const Availability: FC<Props> = ({ availabilityAllowed, availabilityBlocks, usesCustomAvailability }) => {
  if (!usesCustomAvailability) {
    return null;
  }

  const availabilityText = displayAvailability(availabilityBlocks, availabilityAllowed);

  return (
    <span className="small">
      <strong>Custom availability</strong>: {availabilityText || 'Not available to be scheduled.'}
    </span>
  );
};

export default Availability;

import type { FC } from 'react';
import { useContext, useEffect, useMemo, useRef, useState, createContext } from 'react';

import type { DraftHandleValue } from 'draft-js';
import { EditorState, getDefaultKeyBinding } from 'draft-js';
import classNames from 'classnames';
import defer from 'lodash/defer';
import keyBy from 'lodash/keyBy';

import ConfigContext from '../ConfigContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import { updateTextContent } from '../actions';
import Variable from '../Entities/Variable';
import Entry from '../Entry';
import { atomicEntityPlugin } from '../atomicEntityPlugin';
import { richTextPlugin } from '../richTextPlugin';
import { deserialize, serialize } from '../serialization';
import TextFormattingBar, { TextFormattingBarPortal } from '../TextFormattingBar';
import DragHandle from '../DragHandle';
import { decorators } from '../Decorators';
import type { IndexedMjmlText } from '../EmailTemplate';
import { selectDraggingElement, selectSelectedElementId } from '../Reducers/uiReducer';
import { selectTemplateIndex } from '../Reducers/templateReducer';

import { paddingStyle, selectElementHandler } from './utilities';

import { useSortable } from '@dnd-kit/sortable';
import type PluginEditor from '@draft-js-plugins/editor/lib/Editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import DraftEditor from '@draft-js-plugins/editor';
import VariablesContext from '@shared/VariablesContext';
import { capitalize } from '@shared/text';
import { handleBeforeInput, handlePaste } from '@shared/Editor/editorManipulators';

export const LinkColorContext = createContext<{ linkColor: string | undefined }>({ linkColor: undefined });

export const fontStacks = {
  'Sans-serif':
    '-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif',
  Serif:
    'Iowan Old Style, Apple Garamond, Baskerville, Times New Roman, Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  Mono: 'Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace',
};

interface Props {
  node: IndexedMjmlText;
  selected: boolean;
  leaves: any[];
}

const MjmlText: FC<Props> = ({ leaves: _leaves, node, selected }) => {
  const { variables } = useContext(VariablesContext);
  const { readOnly } = useContext(ConfigContext);

  const editorId = useAppSelector(state => state.template.editorId);
  const draggingElement = useAppSelector(selectDraggingElement);
  const index = useAppSelector(selectTemplateIndex);
  const { initialSelectedElementId, selectedElementId } = useAppSelector(selectSelectedElementId);

  const insertableVariables = useMemo(() => {
    return variables.filter(v => v.insertable);
  }, [variables]);

  const keyedByNameVariablesList = useMemo(() => {
    return keyBy(insertableVariables, 'name');
  }, [insertableVariables]);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: {
      type: 'mjml-text',
    },
  });

  const editorRef = useRef<PluginEditor>(null);
  const mentionSuggestionsRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(insertableVariables);
  const [hovering, setHovering] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createWithContent(deserialize(node.content!), decorators);
  });
  const dispatch = useAppDispatch();

  const { MentionSuggestions, mentionPlugin } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent: Variable,
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '{',
      popperOptions: {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'mentionSuggestions',
            enabled: true,
            phase: 'beforeWrite',
            fn: ({ state }) => {
              state.styles.popper.maxHeight = '250px';
              state.styles.popper.overflow = 'auto';
              state.styles.popper.zIndex = '999';
              state.styles.popper.fontSize = '0.9rem';
              state.styles.popper.fontFamily = 'Lato, sans-serif';
              state.styles.popper.color = 'var(--bs-body-color)';
              state.styles.popper.textAlign = 'left';
            },
          },
        ],
      },
    });

    const { MentionSuggestions } = mentionPlugin;
    return { mentionPlugin, MentionSuggestions };
  }, []);

  const style = useMemo(() => {
    return {
      transition,
      zIndex: selected ? 999 : 'unset',
      opacity: isDragging ? 0.5 : 1,
      backgroundColor: node.backgroundColor,
      fontSize: node.font?.size,
      fontFamily: fontStacks[node.font?.family || 'Sans-serif'],
      color: node.text?.color,
      padding: paddingStyle(node.padding),
    };
  }, [isDragging, node.backgroundColor, node.font, node.padding, node.text?.color, selected, transition]);

  useEffect(() => {
    if (selected) {
      // We need to wrap this in a defer block to defer the focus of DraftJS editor everytime we re-initialize it by moving to another Column
      // so that all plugins and decorators are loaded first before the focus happen.
      defer(() => editorRef.current?.focus());

      setHovering(true);
    } else {
      editorRef.current?.blur();
      setHovering(false);
    }
  }, [selected]);

  // We need to re-focus editor on node alignment change so style change is applied immediately
  useEffect(() => {
    // Without this if check, editor state will be inflicted with race condiiton everytime a text element is inserted
    // and the mention suggestions box will be wiped because it is only built once due to useMemo.
    if (selected) {
      defer(() => editorRef.current?.focus());
    }
  }, [selected, node.text?.alignment, node.font]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickInsideMentionSuggestions);

    return () => {
      document.removeEventListener('mousedown', handleClickInsideMentionSuggestions);
    };
  }, [editorState]);

  const handleMouseEvents = (event: React.MouseEvent) => {
    if (selected && !draggingElement) {
      event.stopPropagation();
    }
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  const handleBlur = () => {
    dispatch(updateTextContent({ elementId: node.id, content: serialize(editorState.getCurrentContent()) }));
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === 'escape') {
      if (!hovering) {
        dispatch(updateTextContent({ elementId: node.id, content: serialize(editorState.getCurrentContent()) }));
      }

      return 'handled';
    }

    return 'not-handled';
  };

  const handleKeyBinding = (e: React.KeyboardEvent<Element>): string | null | undefined => {
    if (e.key === 'Escape') {
      return 'escape';
    }

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      return undefined;
    }

    return getDefaultKeyBinding(e);
  };

  const handleOnChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const handleSearchChange = ({ value }: { value: string }) => {
    const lcValue = value.toLowerCase();
    const suggestedVariables = insertableVariables.filter(v => v.name.toLowerCase().indexOf(lcValue) >= 0);
    setSuggestions(suggestedVariables);
  };

  const handleClickInsideMentionSuggestions = (e: MouseEvent): void => {
    if (mentionSuggestionsRef.current?.contains(e.target as Node)) {
      editorRef.current?.focus();
      e.preventDefault();
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    selectElementHandler(dispatch, node.id, index, selectedElementId, initialSelectedElementId)(event);

    editorRef.current?.focus();
  };

  return (
    <div>
      {
        // This div is only to capture bubbled click events.
        /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events */
        <LinkColorContext.Provider value={{ linkColor: node.text?.linkColor }}>
          <div
            id={node.id}
            ref={setNodeRef}
            style={style}
            className={classNames({
              EmailEditor__Element: true,
              DraftEditor: true,
              'EmailEditor__Element--highlighted': (hovering && !draggingElement) || selected,
            })}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onPointerMove={handleMouseEvents}
          >
            {/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events */}
            <DraftEditor
              ref={editorRef}
              readOnly={(!selected && !hovering) || readOnly}
              editorState={editorState}
              decorators={[decorators]}
              plugins={[mentionPlugin, atomicEntityPlugin, richTextPlugin]}
              blockStyleFn={() => `align${capitalize(node.text?.alignment || 'left')} pe-none`}
              handleKeyCommand={handleKeyCommand}
              handleBeforeInput={handleBeforeInput(setEditorState)}
              keyBindingFn={handleKeyBinding}
              handlePastedText={handlePaste(setEditorState, keyedByNameVariablesList)}
              onChange={handleOnChange}
              onBlur={handleBlur}
            />
            <div ref={mentionSuggestionsRef}>
              <MentionSuggestions
                open={open}
                suggestions={suggestions}
                entryComponent={Entry}
                onOpenChange={setOpen}
                onSearchChange={handleSearchChange}
              />
            </div>
            <TextFormattingBarPortal editorId={editorId}>
              {selected && (
                <TextFormattingBar
                  textElementSelected={selected}
                  textFormattingBarPlaceholder={false}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  onBlur={handleBlur}
                />
              )}
            </TextFormattingBarPortal>

            <div className="EmailEditor__Element_Identifier">
              Text
              <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
                <DragHandle selected={selected} />
              </span>
            </div>
          </div>
        </LinkColorContext.Provider>
      }
    </div>
  );
};

export default MjmlText;

import { useState, type FC } from 'react';

import Row from '@/ui/Row';

import Select from '@shared/ui/Select';
import Alert from '@shared/Alert';
import { idStringifier } from '@shared/text';

import type { TicketStatus } from '..';

interface Props {
  ticketStatuses: TicketStatus[];
  afterCancelPsaStatusId: string;
  afterCreatePsaStatusId: string;
  afterSchedulePsaStatusId: string;
  noResponsePsaStatusId: string;
}

const TicketStatuses: FC<Props> = ({
  ticketStatuses,
  afterCancelPsaStatusId,
  afterCreatePsaStatusId,
  afterSchedulePsaStatusId,
  noResponsePsaStatusId,
}) => {
  const [afterCreateStatusId, setAfterCreateStatusId] = useState(afterCreatePsaStatusId);
  const [afterCancelStatusId, setAfterCancelStatusId] = useState(afterCancelPsaStatusId);
  const [afterScheduleStatusId, setAfterScheduleStatusId] = useState(afterSchedulePsaStatusId);
  const [noResponseStatusId, setNoResponseStatusId] = useState(noResponsePsaStatusId);

  const options = [{ name: '(None)', value: '' }, ...ticketStatuses.map(idStringifier)];

  return (
    <>
      <h5 className="mb-3">Preset Statuses</h5>
      {ticketStatuses.length > 0 ? (
        <>
          <Row
            label="Status after creation"
            labelFor="halo_psa_integration[after_create_psa_status_id]"
            helpText="If a status is selected here, TimeZest will update the ticket to that status after a scheduling request is sent."
          >
            <Select
              name="halo_psa_integration[after_create_psa_status_id]"
              value={afterCreateStatusId || ''}
              options={options}
              onChange={e => setAfterCreateStatusId(e.target.value)}
            />
          </Row>

          <Row
            label="Status after scheduling"
            labelFor="halo_psa_integration[after_schedule_psa_status_id]"
            helpText="After a customer selects a time, TimeZest will update the ticket to this status, if selected."
          >
            <Select
              name="halo_psa_integration[after_schedule_psa_status_id]"
              value={afterScheduleStatusId || ''}
              options={options}
              onChange={e => setAfterScheduleStatusId(e.target.value)}
            />
          </Row>

          <Row
            label="Status after cancellation"
            labelFor="halo_psa_integration[after_cancel_psa_status_id]"
            helpText="If the client cancels an appointment, move the ticket into this status, if selected."
          >
            <Select
              name="halo_psa_integration[after_cancel_psa_status_id]"
              value={afterCancelStatusId || ''}
              options={options}
              onChange={e => setAfterCancelStatusId(e.target.value)}
            />
          </Row>

          <Row
            label="No-response Status"
            labelFor="halo_psa_integration[no_response_psa_status_id]"
            helpText="If the no-response deadline configured for an appointment type passes after sending the client a notification, but they haven't chosen a time, move the ticket into this status."
          >
            <Select
              name="halo_psa_integration[no_response_psa_status_id]"
              value={noResponseStatusId || ''}
              options={options}
              onChange={e => setNoResponseStatusId(e.target.value)}
            />
          </Row>
        </>
      ) : (
        <Alert
          icon="circle-xmark"
          color="red"
          title="TimeZest could not retrieve the list of statuses from your HaloPSA account
          because the configured credentials were rejected."
          content="Check that the API application credentials in HaloPSA are still configured correctly,
          and the API credentials are correctly set in TimeZest."
        />
      )}
    </>
  );
};

export default TicketStatuses;

import { type FC, useContext } from 'react';
import { useRef, useState } from 'react';

import classNames from 'classnames';

import HtmlEditor from '@/HtmlEditor';
import PlanContext from '@/PlanContext';
import { getCSRFToken } from '@/utilities';
import CurrentUserContext from '@/CurrentUserContext';
import Form from '@/Form';

import AppointmentTypesList from '../AppointmentTypesList';

import VariablesList from './VariablesList';

import type { CustomAttribute } from '@models/CustomAttribute';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import TextInput from '@shared/ui/Inputs/TextInput';
import type { TemplateTypes } from '@models/EmailTemplateModel';

interface Props {
  appointmentTypes: string[];
  customAttributes: CustomAttribute[];
  errors: Errors;
  htmlTemplate: string;
  name: string;
  readOnly: boolean;
  subjectTemplate: string;
  templateType: TemplateTypes;
  url: string;
}

type Errors = Record<string, string[] | undefined>;
type TestEmailStatus = 'normal' | 'sending' | 'sent';

const OldEmailEditor: FC<Props> = ({
  appointmentTypes,
  customAttributes,
  errors: initialErrors,
  htmlTemplate: initialHtmlTemplate,
  name: initialName,
  readOnly,
  subjectTemplate: initialSubjectTemplate,
  templateType,
  url,
}) => {
  const { email: currentUserEmail } = useContext(CurrentUserContext);
  const { allowsCustomEmails } = useContext(PlanContext);

  const ref = useRef<HTMLFormElement>(null);
  const [name, setName] = useStateFromProp(initialName);
  const [previewErrors, setPreviewErrors] = useState<Errors>({});
  const [htmlTemplate, setHtmlTemplate] = useState(initialHtmlTemplate);
  const [subjectTemplate, setSubjectTemplate] = useState(initialSubjectTemplate);
  const [testEmailStatus, setTestEmailStatus] = useStateWithCallback<TestEmailStatus>('normal');

  const errors = { ...previewErrors, ...initialErrors };
  const hasCustomAttributes = customAttributes.length > 0 && allowsCustomEmails;

  const sendPreview = () => {
    setPreviewErrors({});

    let subject = subjectTemplate;

    if (ref.current) {
      const data = new FormData(ref.current);
      subject = (data.get('email_template[subject_template_json]') as string) || '{}';
    }

    setTestEmailStatus('sending', () => {
      fetch('/settings/email_templates/test_email', {
        method: 'POST',
        body: JSON.stringify({
          legacy: true,
          subject_template: subject,
          template_html: htmlTemplate,
          template_type: templateType,
        }),
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': getCSRFToken() },
      }).then((response: Response) => {
        switch (response.status) {
          case 200:
            setTestEmailStatus('sent');
            setTimeout(() => setTestEmailStatus('normal'), 5000);
            break;
          case 422:
            response.json().then(errors => setPreviewErrors(errors));
            setTestEmailStatus('normal');
            break;
          default:
            throw Error(response.statusText);
        }
      });
    });
  };

  return (
    <>
      <div className="col-sm-8">
        <Form url={url} ref={ref} method="patch">
          <div className="mb-3">
            <label className="fw-bold" htmlFor="email_template_name">
              Name:
            </label>
            <TextInput
              id="email_template_name"
              disabled={readOnly}
              name="email_template[name]"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="fw-bold" htmlFor="email_template_subject_template">
              Subject Line Template:
            </label>
            <TextInput
              id="email_template_subject_template"
              className={formClasses(errors, 'subject_template')}
              disabled={readOnly}
              name="email_template[subject_template]"
              type="text"
              value={subjectTemplate}
              onChange={e => setSubjectTemplate(e.target.value)}
            />
            <div className="invalid-feedback">{error(errors, 'subject_template')}</div>
          </div>
          <div className="mb-3">
            <label className="fw-bold" htmlFor="email_template_template_html">
              HTML Template:
            </label>
            <div className={formClasses(errors, 'template_html', 'form-control', 'html-editor-container')}>
              <HtmlEditor readOnly={readOnly} value={htmlTemplate} onChange={newValue => setHtmlTemplate(newValue)} />
            </div>
            <div className="invalid-feedback">{error(errors, 'template_html')}</div>
          </div>
          <div className="d-flex">
            <div className="flex-grow-1">
              <input type="submit" className="btn btn-primary" value="Save Changes" />
            </div>
            <div>
              {!readOnly && (
                <button
                  className="btn btn-outline-danger"
                  disabled={testEmailStatus !== 'normal'}
                  type="button"
                  onClick={sendPreview}
                >
                  {testEmailStatus === 'normal' && <>Send preview to {currentUserEmail}</>}
                  {testEmailStatus === 'sending' && <>Sending...</>}
                  {testEmailStatus === 'sent' && <>Preview sent to {currentUserEmail}</>}
                </button>
              )}
            </div>
          </div>
        </Form>
      </div>
      <div className="col-sm-4">
        <AppointmentTypesList
          appointmentTypes={appointmentTypes}
          readOnly={readOnly}
          templateType={templateType}
          url={url}
        />
        {!readOnly && (
          <>
            <hr />
            <VariablesList templateType={templateType} />
            {hasCustomAttributes && (
              <>
                <hr />
                <small>
                  {customAttributes.map((attr, i) => (
                    <div key={`attr_${i}`}>
                      <code>{`{{ scheduled_member.${attr.name} }}`}</code> &ndash; {attr.description}
                    </div>
                  ))}
                </small>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

function formClasses(errors: Errors, name: string, ...classes: string[]): string {
  return classNames(...classes, {
    'is-invalid': !!errors[name],
  });
}

function error(errors: Errors, name: string): string | undefined {
  return (errors[name] || [])[0];
}

export default OldEmailEditor;

import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update all appointments and online meetings associated with the
      scheduling request to reflect the client&apos;s selected appointment time.
    </p>
    <p>
      This is a <strong>critical action</strong>, which means that if it doesn&apos;t complete successfully, no actions
      for this trigger after it will be executed.
    </p>
  </>
);

const UpdateAppointments: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="tomorrow"
      summary="Update all associated appointments and online meetings"
      readOnly={readOnly}
    >
      <p>Updates all appointments and online meetings associated with the scheduling request.</p>
    </Action>
  </ErrorBoundary>
);

export default UpdateAppointments;

import type { FC } from 'react';
import { useContext, useState } from 'react';

import styled from 'styled-components';
import omit from 'lodash/fp/omit';

import ConfigContext from '../ConfigContext';
import { updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import type { IndexedMjmlImage } from '../EmailTemplate';

import SpaceInput from './Inputs/Space';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';
import Input from './Inputs/Input';
import ButtonGroup from './Inputs/ButtonGroup';
import { ALIGNMENTS } from './shared/constants';

const ImagePropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

interface Props {
  node: IndexedMjmlImage;
}

const ImageProperties: FC<Props> = ({ node }) => {
  const { readOnly } = useContext(ConfigContext);

  const [error, setError] = useState<Record<string, string>>({});

  const dispatch = useAppDispatch();

  const handleOnChange = (property: string) => (newValue: any) => {
    if (property === 'width' && node.height && !newValue) {
      setError({ ...error, width: 'An accompanying width value is needed for the specified height.' });
      return;
    }

    if (property === 'height' && error.width && !newValue) {
      dispatch(updateProperty({ elementId: node.id, property: 'width', value: '' }));
      setError(omit('width', error));
    }

    if (error[property]) setError(omit(property, error));
    dispatch(updateProperty({ elementId: node.id, property, value: newValue }));
  };

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this Image. Are you sure?"
    >
      <ImagePropertiesContainer>
        <PropertiesCollapsible title="IMAGE PROPERTIES">
          <Input
            id={`${node.id}-image-url`}
            label="Image URL"
            type="text"
            value={node.url || ''}
            disabled={readOnly}
            onChange={handleOnChange('url')}
          />
          <Input
            id={`${node.id}-image-alt-text`}
            label="Alt text"
            type="text"
            value={node.altText || ''}
            disabled={readOnly}
            onChange={handleOnChange('altText')}
          />
          <Input
            id={`${node.id}-link-to-url`}
            label="Link to URL"
            type="text"
            value={node.href || ''}
            disabled={readOnly}
            onChange={handleOnChange('href')}
          />
          <ButtonGroup
            id={`${node.id}-alignment`}
            label="Alignment"
            value={node.alignment || ''}
            options={ALIGNMENTS}
            disabled={readOnly}
            onChange={handleOnChange('alignment')}
          />
          <Input
            value={node.height || ''}
            type="number"
            id={`${node.id}-height`}
            label="Height"
            min={0}
            unit="px"
            disabled={readOnly}
            onChange={handleOnChange('height')}
            onInput={handleOnChange('height')}
          />
          <Input
            value={node.width || ''}
            type="number"
            id={`${node.id}-width`}
            label="Width"
            min={0}
            unit="px"
            disabled={readOnly}
            error={error.width}
            onChange={handleOnChange('width')}
            onInput={handleOnChange('width')}
          />
          <SpaceInput
            id={`${node.id}-padding`}
            label="Padding"
            value={node.padding || {}}
            disabled={readOnly}
            onInput={handleOnChange('padding')}
          />
        </PropertiesCollapsible>
      </ImagePropertiesContainer>
    </PropertiesContainer>
  );
};

export default ImageProperties;

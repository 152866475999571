import type { FC } from 'react';

import classNames from 'classnames';

import type { InputProps, NumberInputProps } from '@models/InputControl';

interface Props extends InputProps {
  prepend?: string | JSX.Element;
  append?: string | JSX.Element;
}

const InputGroup: FC<Props & NumberInputProps> = props => {
  const { className, ariaLabel, prepend, append, error, onKeyDown, ...rest } = props;
  const { type, min } = rest;
  const preventNegativeNumbers = type === 'number' && min === 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }

    if (onKeyDown) onKeyDown(e);
  };

  return (
    <>
      <div className={classNames('input-group', className)}>
        {typeof prepend === 'string' ? (
          <span
            key={`${props.name}-input-group-text-prepend`}
            className={classNames('input-group-text pe-none', { 'is-invalid': !!error })}
            style={{ padding: '6px 10px' }}
          >
            {prepend}
          </span>
        ) : (
          prepend
        )}
        <input
          key={`${props.name}-input`}
          id={props.name}
          aria-label={ariaLabel}
          className={classNames('form-control px-2', { 'is-invalid': !!error })}
          onKeyDown={preventNegativeNumbers ? handleKeyDown : onKeyDown}
          {...rest}
        />
        {typeof append === 'string' ? (
          <span
            key={`${props.name}-input-group-text-append`}
            style={{ padding: '6px 10px' }}
            className={classNames('input-group-text pe-none', { 'is-invalid': !!error })}
          >
            {append}
          </span>
        ) : (
          append
        )}
      </div>
      {error && <p className="text-danger small mb-0 mt-1">{error}</p>}
    </>
  );
};

export default InputGroup;

import type { FC } from 'react';

import Flash from '@/Flash';
import Header from '@/Header';
import asScreen from '@/Screen';

import CustomDomainDetails from './CustomDomain';

import type DomainModel from '@models/Domain';

interface Props {
  domain: DomainModel;
}

const CustomDomain: FC<Props> = ({ domain }) => {
  return (
    <>
      <Header title={domain.name} subtitle="Custom Domain" />
      <Flash />
      <CustomDomainDetails domain={domain} />
    </>
  );
};

export default asScreen(CustomDomain);

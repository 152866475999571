import type { FC, PropsWithChildren, ReactNode } from 'react';

import Row from '@ui/Row';

interface Props {
  children?: ReactNode;
  value?: string;
  label: string;
  helpText: string | JSX.Element | null;
}

const TextRow: FC<PropsWithChildren<Props>> = ({ value, label, helpText, children }) => {
  return (
    <Row label={label} helpText={helpText} withInput={false}>
      {children}
      {value}
    </Row>
  );
};

export default TextRow;

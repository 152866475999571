import type { FC } from 'react';

import startCase from 'lodash/startCase';

import asScreen from '@/Screen';
import Row from '@/ui/Row';
import SelectRow from '@/ui/SelectRow';
import Form from '@/Form';
import Header from '@/Header';
import Flash from '@/Flash';

import { useGetTaskStatesForServiceNowQuery } from '@graphql/generated';
import { idStringifier, snakeCaseToNormalText } from '@shared/text';

interface Props {
  preset: {
    afterCancelPsaStateId: string | null;
    afterCreatePsaStateId: string | null;
    afterSchedulePsaStateId: string | null;
    noResponsePsaStateId: string | null;
  };
  type: string;
}

const Preset: FC<Props> = ({ preset, type }) => {
  const { data } = useGetTaskStatesForServiceNowQuery({ variables: { taskType: type } });

  const states = data?.serviceNow.taskStates || [];

  const options = [{ name: '(None)', value: '' }, ...states.map(idStringifier)];

  const typeName = snakeCaseToNormalText(type);

  return (
    <>
      <Header title={startCase(typeName)} subtitle="Presets" />
      <Flash />
      <Form method="patch" url={`/settings/integrations/service_now/presets/${type}`}>
        <SelectRow
          helpText={`If a state is selected here, TimeZest will update the ${typeName} to that state after a scheduling request is sent.`}
          label="State after creation"
          name="service_now_integration_state_preset[after_create_psa_state_id]"
          options={options}
          value={preset.afterCreatePsaStateId || ''}
        />
        <SelectRow
          helpText={`After a customer selects a time, TimeZest will update the ${typeName} to this state, if selected.`}
          label="State after scheduling"
          name="service_now_integration_state_preset[after_schedule_psa_state_id]"
          options={options}
          value={preset.afterSchedulePsaStateId || ''}
        />
        <SelectRow
          helpText={`If the client cancels an appointment, move the ${typeName} into this state, if selected.`}
          label="State after cancellation"
          name="service_now_integration_state_preset[after_cancel_psa_state_id]"
          options={options}
          value={preset.afterCancelPsaStateId || ''}
        />
        <SelectRow
          helpText={`If the client hasn't chosen a time within a configured limit, move the ${typeName} into this state.`}
          label="No-Response state"
          name="service_now_integration_state_preset[no_response_psa_state_id]"
          options={options}
          value={preset.noResponsePsaStateId || ''}
        />
        <hr />
        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(Preset);

import type { FC } from 'react';

import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';

import { camelCaseToNormalText } from '@shared/text';

import type { StatePresetAttributes } from '.';

const PresetStates: FC<{ presetType: string; presetStates: StatePresetAttributes }> = ({
  presetType,
  presetStates,
}) => {
  const name = camelCaseToNormalText(presetType);

  const {
    afterCancelPsaStateId,
    afterCancelPsaStateName,
    afterCreatePsaStateId,
    afterCreatePsaStateName,
    afterSchedulePsaStateId,
    afterSchedulePsaStateName,
    noResponsePsaStateId,
    noResponsePsaStateName,
  } = presetStates;

  return (
    <a href={`/settings/integrations/service_now/presets/${snakeCase(presetType)}`}>
      <div className="card mb-2">
        <div className="card-body d-flex py-2">
          <div className="flex-grow-1">
            <div className="title mb-1">
              <span className="fw-bold align-middle">{startCase(name)}</span>
            </div>

            <div className="d-grid gap-1">
              {afterCancelPsaStateId && (
                <small>
                  Update {name} state to <strong>{afterCancelPsaStateName}</strong> after the scheduling request is
                  cancelled.
                </small>
              )}

              {afterCreatePsaStateId && (
                <small>
                  Update {name} state to <strong>{afterCreatePsaStateName}</strong> after the scheduling request is
                  created.
                </small>
              )}

              {afterSchedulePsaStateId && (
                <small>
                  Update {name} state to <strong>{afterSchedulePsaStateName}</strong> after the scheduling request is
                  scheduled.
                </small>
              )}

              {noResponsePsaStateId && (
                <small>
                  Update {name} state to <strong>{noResponsePsaStateName}</strong> if the client hasn&apos;t chosen a
                  time within a configured limit.
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default PresetStates;

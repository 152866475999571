import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';

import PlainTextRow from './PlainTextRow';
import RadioButtonRow from './RadioButtonRow';

import type { NewEntitySummaryMode } from '@models/AppointmentType';
import TextInput from '@shared/ui/Inputs/TextInput';

interface Props {
  summaryMode: NewEntitySummaryMode | null;
  summary: string | null;
  appointmentNames: string[];
  id?: string;
  modelName?: string;
  readOnly?: boolean;
  error?: string;
}

const SummaryRow: FC<Props> = ({ id, readOnly, appointmentNames, error, modelName, summaryMode, summary }) => {
  const { handleDirty } = useContext(DirtyContext);

  const [newEntitySummary, setNewEntitySummary] = useState(summary);
  const [newEntitySummaryMode, setNewEntitySummaryMode] = useState(summaryMode || 'ask_client');

  const summaryName = modelName ? `${modelName}[new_entity_summary]` : 'new_entity_summary';
  const summaryModeName = modelName ? `${modelName}[new_entity_summary_mode]` : 'new_entity_summary_mode';

  const helpText = (
    <>
      TimeZest will use this value as the summary of the {appointmentNames.join(' / ')} it creates. You can also use the
      variables <code>{'{{ client.name }}'}</code> and <code>{'{{ company.name }}'}</code>, which TimeZest will
      substitute with the values given by the client.
    </>
  );

  const handleNewEntitySummaryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleDirty) handleDirty();

    setNewEntitySummary(event.target.value);
  };

  const handleNewEntitySummaryModeChange = (value: string): void => {
    if (handleDirty) handleDirty();

    setNewEntitySummaryMode(value as NewEntitySummaryMode);
  };

  useEffect(() => {
    setNewEntitySummary(summary);
    setNewEntitySummaryMode(summaryMode || 'ask_client');
  }, [summary, summaryMode]);

  if (readOnly) {
    return (
      <PlainTextRow label="Summary" helpText={helpText}>
        {summaryMode === 'ask_client'
          ? 'Require the client to provide a description of their issue, and use this.'
          : `Use '${summary}' as the ticket summary`}
      </PlainTextRow>
    );
  }

  return (
    <RadioButtonRow
      id={id}
      label="Summary"
      helpText={helpText}
      name={summaryModeName}
      value={newEntitySummaryMode}
      options={[
        { label: 'Require the client to provide a description of their issue, and use this.', value: 'ask_client' },
        {
          label: `Use a specific value for the ${appointmentNames.join(' / ')} description:`,
          value: 'fixed',
          appendedElement: (
            <div className="w-100 mt-1">
              <TextInput
                id="new_entity_summary"
                name={summaryName}
                value={newEntitySummary || ''}
                error={error}
                disabled={newEntitySummaryMode === 'ask_client'}
                onChange={handleNewEntitySummaryChange}
              />
              <input type="hidden" name={summaryName} value={newEntitySummary || ''} />
            </div>
          ),
        },
      ]}
      onChange={handleNewEntitySummaryModeChange}
    />
  );
};

export default SummaryRow;

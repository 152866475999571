import type { FC } from 'react';
import { useContext, useMemo } from 'react';

import styled from 'styled-components';
import compact from 'lodash/compact';

import ConfigContext from '../ConfigContext';
import { updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import type { IndexedMjmlButton } from '../EmailTemplate';

import ButtonGroup from './Inputs/ButtonGroup';
import ColorInput from './Inputs/Color';
import Input from './Inputs/Input';
import SelectInput from './Inputs/Select';
import SpaceInput from './Inputs/Space';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';
import { ALIGNMENTS } from './shared/constants';

import VariablesContext from '@shared/VariablesContext';

const AVAILABLE_URLS = [
  'appointment.scheduling_url',
  'appointment.rescheduling_url',
  'online_meeting.join_url',
  'online_meeting.start_url',
];

const ButtonPropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

interface Props {
  node: IndexedMjmlButton;
}

const ButtonProperties: FC<Props> = ({ node }) => {
  const variablesContext = useContext(VariablesContext);
  const { readOnly } = useContext(ConfigContext);

  const url = node.url || '';
  const customUrl = !AVAILABLE_URLS.includes(url);
  const dispatch = useAppDispatch();
  const urls = useMemo(() => {
    const variables = AVAILABLE_URLS.map(url => variablesContext.variables.find(v => v.variable === url));
    return compact(variables).map(v => {
      return { name: v.name, value: v.variable };
    });
  }, [variablesContext]);

  const handleOnChange = (property: string) => (value: any) => {
    dispatch(updateProperty({ elementId: node.id, property, value }));
  };

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this button. Are you sure?"
    >
      <ButtonPropertiesContainer>
        <PropertiesCollapsible title="BUTTON PROPERTIES">
          <ColorInput
            id={`${node.id}-background-color`}
            label="Background Color"
            name="backgroundColor"
            value={node.backgroundColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('backgroundColor')}
          />
          <ColorInput
            id={`${node.id}-button-color`}
            label="Button Color"
            name="buttonColor"
            value={node.buttonColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('buttonColor')}
          />
          <ColorInput
            id={`${node.id}-text-color`}
            label="Text Color"
            name="textColor"
            value={node.textColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('textColor')}
          />
          <ButtonGroup
            id={`${node.id}-alignment`}
            label="Alignment"
            value={node.alignment || ''}
            options={ALIGNMENTS}
            disabled={readOnly}
            onChange={handleOnChange('alignment')}
          />
          <SpaceInput
            id={`${node.id}-inner-padding`}
            label="Inner padding"
            value={node.innerPadding || {}}
            disabled={readOnly}
            onInput={handleOnChange('innerPadding')}
          />
          <SpaceInput
            id={`${node.id}-outer-padding`}
            label="Outer padding"
            value={node.outerPadding || {}}
            disabled={readOnly}
            onInput={handleOnChange('outerPadding')}
          />
          <SelectInput
            id={`${node.id}-url`}
            label="URL"
            options={[...urls, { name: 'Custom URL', value: '' }]}
            value={url}
            disabled={readOnly}
            onChange={handleOnChange('url')}
          />
          {customUrl && (
            <Input
              id={`${node.id}-custom-url`}
              label="Custom URL"
              type="text"
              value={url}
              disabled={readOnly}
              onChange={handleOnChange('url')}
            />
          )}
        </PropertiesCollapsible>
      </ButtonPropertiesContainer>
    </PropertiesContainer>
  );
};

export default ButtonProperties;

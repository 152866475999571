import type { FC } from 'react';

import { ErrorMessage } from '@/ui/ErrorMessage';

interface Props {
  errorType: string | undefined;
}

const Error: FC<Props> = ({ errorType }) => {
  if (errorType === 'unauthorized') {
    return (
      <ErrorMessage>
        <p className="mb-0">
          TimeZest could not retrieve appointment type options from Autotask because the API user does not have
          sufficient permissions.
        </p>
        <p className="mt-3 mb-0">
          Please check the API user has correct permissions assigned as&nbsp;
          <a
            href="https://help.timezest.com/en/articles/4840136-creating-a-security-level-for-autotask"
            target="_blank"
            rel="noreferrer"
          >
            described here
          </a>
          .
        </p>
      </ErrorMessage>
    );
  }

  return (
    <ErrorMessage>
      <p className="mb-0">TimeZest encountered an unexpected error. Please reload the page and try again</p>
    </ErrorMessage>
  );
};

export default Error;

import { forwardRef, useContext } from 'react';

import type { WorkflowActionProps } from '@/ui/Workflow/types';

import StatusSelect from './StatusSelect';
import RatingSelect from './RatingSelect';
import TypeSelect from './TypeSelect';
import StageSelect from './StageSelect';
import CampaignSelect from './CampaignSelect';

import ErrorBoundary from '@shared/ErrorBoundary';
import Action from '@shared/Action';
import type { Content, Content as LineContent } from '@shared/LineEditor';
import LineEditor from '@shared/LineEditor';
import Row from '@ui/Row';
import type { ConnectWiseFindOrCreateOpportunityAction } from '@graphql/generated';
import useFormSubmit from '@shared/hooks/useFormSubmit';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import FormError from '../../FormError';
import { useFindOrCreateOpportunityForConnectWise } from '../../hooks';

const DETAILS = (
  <>
    <p className="mb-1">
      When this action appears in a workflow, TimeZest will first attempt to find an opportunity in ConnectWise PSA
      associated with the contact that is in the specified status. If one is found, this opportunity will be associated
      with the current scheduling request.
    </p>
    <p className="mb-1">
      If an opportunity is not found, TimeZest will create a new opportunity in ConnectWise PSA with the specified field
      values.
    </p>
    <p className="mb-1">The action will be skipped in the following circumstances:</p>
    <ul className="mb-1">
      <li>Where there is already a ConnectWise opportunity associated with the scheduling request. </li>
      <li>Where there is no ConnectWise contact associated with the scheduling request </li>
      <li>Where there is no ConnectWise company associated with the scheduling request. </li>
      <li>Where the scheduled user does not have a ConnectWise account. </li>
      <li>Where there is no scheduled user for the scheduling request</li>
    </ul>
  </>
);

const defaultNameValue: Content = {
  type: 'paragraph',
  children: [{ text: 'Opportunity Created from TimeZest' }],
};

const FindOrCreateOpportunityForConnectWise = forwardRef<
  HTMLFormElement,
  WorkflowActionProps<ConnectWiseFindOrCreateOpportunityAction>
>(({ action, readOnly, saveable }, ref) => {
  const { templateMode } = useContext(WorkflowActionContext);

  const { errors: mutationErrors, loading, succeeded, submit } = useFindOrCreateOpportunityForConnectWise();

  const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
    return {
      name: data.get('name') as string,
      psaStatusId: data.get('status_id') as string,
      psaStatusName: (data.get('status_name') as string) || null,
      psaStageId: data.get('stage_id') as string,
      psaStageName: (data.get('stage_name') as string) || null,
      psaRatingId: data.get('rating_id') as string,
      psaRatingName: (data.get('rating_name') as string) || null,
      psaTypeId: data.get('type_id') as string,
      psaTypeDescription: (data.get('type_description') as string) || null,
      psaCampaignId: data.get('campaign_id') as string,
      psaCampaignName: (data.get('campaign_name') as string) || null,
    };
  });

  const errors = action.errors || mutationErrors;

  return (
    <ErrorBoundary>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Action
          action={action}
          details={DETAILS}
          icon="task-plus"
          summary={
            <>
              Find or create an <strong>opportunity</strong> in ConnectWise PSA.
            </>
          }
          readOnly={readOnly}
          saveable={saveable}
          saving={loading}
          succeeded={succeeded}
        >
          <FormError action={action} errors={errors} />

          <Row label="Name" width={6}>
            <LineEditor
              name="name"
              initialValue={(action.name || defaultNameValue) as LineContent}
              readOnly={readOnly}
              error={errors.name}
            />
          </Row>

          <StatusSelect
            error={errors.status}
            statusId={action.psaStatusId || ''}
            templateMode={templateMode}
            readOnly={readOnly}
          />
          <RatingSelect
            error={errors.rating}
            ratingId={action.psaRatingId || ''}
            templateMode={templateMode}
            readOnly={readOnly}
          />
          <StageSelect
            error={errors.stage}
            stageId={action.psaStageId || ''}
            templateMode={templateMode}
            readOnly={readOnly}
          />
          <TypeSelect
            error={errors.type}
            typeId={action.psaTypeId || ''}
            templateMode={templateMode}
            readOnly={readOnly}
          />
          <CampaignSelect
            error={errors.campaign}
            campaignId={action.psaCampaignId || ''}
            templateMode={templateMode}
            readOnly={readOnly}
          />
        </Action>
      </form>
    </ErrorBoundary>
  );
});

export default FindOrCreateOpportunityForConnectWise;

import type { FC } from 'react';
import { useContext } from 'react';

import Expander from '@/ui/Expander';
import PlanContext from '@/PlanContext';

import Config from './Config';
import Summary from './Summary';

import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';

export function shareableUrlHasErrors(errors: Errors): boolean {
  return !!(
    errors.shareableUrlTicketMode ||
    errors.shareableUrlContactHandling ||
    errors.catchallPsaCompanyId ||
    errors.newEntityPsaQueueId ||
    errors.newEntitySummary ||
    errors.newTicketPsaCategoryId ||
    errors.newTicketPsaPriorityId ||
    errors.newTicketPsaQueueId ||
    errors.newTicketPsaSourceId ||
    errors.newTicketPsaStatusId ||
    errors.newTicketPsaTypeId ||
    errors.newTicketPsaSubTypeId
  );
}

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  dirty: boolean;
  expanded: boolean;
  canExpand: boolean;
  onDetailsExpand: () => void;
  onExpand: (expanded: boolean) => void;
}

const ShareableUrlConfig: FC<Props> = ({
  appointmentType,
  canExpand,
  dirty,
  errors,
  expanded,
  onDetailsExpand,
  onExpand,
}) => {
  const { allowsShareableUrls } = useContext(PlanContext);

  const url = allowsShareableUrls ? `/settings/appointment_types/${appointmentType.id}/classic/shareable_url` : null;
  return (
    <Expander
      title="Shareable URLs"
      summary={<Summary allowsShareableUrls={allowsShareableUrls} appointmentType={appointmentType} />}
      url={url}
      method="PATCH"
      icon="earth"
      hasErrors={shareableUrlHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      {allowsShareableUrls ? (
        <Config appointmentType={appointmentType} dirty={dirty} errors={errors} onDetailsExpand={onDetailsExpand} />
      ) : (
        <>
          <p className="text-danger">
            Shareable URLs are only available to subscribers to the TimeZest Professional plan.
          </p>
          <p>
            <a href="/settings/account">Upgrade Now</a>
          </p>
        </>
      )}
    </Expander>
  );
};

export default ShareableUrlConfig;

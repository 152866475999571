import { useState, type FC } from 'react';

interface Props {
  appointmentTypes: string[];
  readOnly: boolean;
  templateType: string;
  url: string;
}

const AppointmentTypesList: FC<Props> = ({ appointmentTypes, readOnly, templateType, url }) => {
  const [appointmentsListExpand, setAppointmentsListExpand] = useState(false);

  const appointmentTypesVisible = appointmentTypes.slice(0, 4);
  const appointmentTypesCollapsed = appointmentTypes.slice(4);
  return (
    <>
      {appointmentTypes.length === 0 ? (
        <>
          <p>
            This <strong>{templateType}</strong> template is not currently used by any appointment types.
          </p>
          {!readOnly && (
            <a href={url} className="btn btn-danger" rel="nofollow" data-method="delete">
              Delete this Email Template
            </a>
          )}
        </>
      ) : (
        <>
          <p>
            This <strong>{templateType}</strong> template is currently used by the following appointment types:
          </p>
          <ul>
            {appointmentsListExpand
              ? appointmentTypes.map((at, i) => <li key={i}>{at}</li>)
              : appointmentTypesVisible.map((at, i) => <li key={i}>{at}</li>)}
            {appointmentTypesCollapsed.length > 0 && (
              <button
                type="button"
                className="text-primary m-0 mt-2 border-0 bg-transparent p-0"
                onClick={() => setAppointmentsListExpand(!appointmentsListExpand)}
              >
                {appointmentsListExpand
                  ? 'collapse'
                  : `and ${appointmentTypesCollapsed.length} ${
                      appointmentTypesCollapsed.length === 1 ? 'other' : 'others'
                    }`}
              </button>
            )}
          </ul>
        </>
      )}
    </>
  );
};

export default AppointmentTypesList;

import type { FC, PropsWithChildren } from 'react';

export type BadgeColor = 'blue' | 'red' | 'green' | 'grey' | 'yellow' | 'purple' | 'sky';

interface Props {
  color: BadgeColor;
}

const Badge: FC<PropsWithChildren<Props>> = ({ children, color }) => {
  return <span className={`badge badge-${color}`}>{children}</span>;
};

export default Badge;

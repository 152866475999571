import type { FC, PropsWithChildren } from 'react';

import { ErrorBoundary as ErrorBoundaryWrapper } from 'react-error-boundary';

import Alert from '@/Alert';

interface Props {
  error?: JSX.Element;
}

const Fallback: FC = () => {
  return (
    <Alert
      className="ErrorBoundary__WorkflowAction d-flex align-items-center my-0 py-2"
      icon="circle-xmark"
      title="TimeZest encountered an unexpected error. Please reload the page and try again."
      color="red"
    />
  );
};

const ErrorBoundary: FC<PropsWithChildren<Props>> = ({ children, error }) => {
  if (error) {
    return <ErrorBoundaryWrapper fallback={error}>{children}</ErrorBoundaryWrapper>;
  }

  return <ErrorBoundaryWrapper FallbackComponent={Fallback}>{children}</ErrorBoundaryWrapper>;
};

export default ErrorBoundary;

import { useEffect } from 'react';
import type { FC } from 'react';

import upperFirst from 'lodash/upperFirst';

import Overlay from './Overlay';

import { usePotentialUserQuery } from '@graphql/generated';
import Select from '@shared/ui/Select';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type { IntegrationModel } from '@models/Integration';

interface Props {
  email: string;
  role: string;
  integrations: IntegrationModel[];
  onRoleChange: (email: string, role: string) => void;
  onStatusChange: (email: string, available: boolean) => void;
}

const PotentialUser: FC<Props> = ({ email, integrations, role, onRoleChange, onStatusChange }) => {
  const { error, data, loading } = usePotentialUserQuery({ variables: { email } });

  const loaded = !loading && !error;
  const user = data?.timezest?.potentialUser;
  const userIntegrations = (user?.integrations || []).reduce((integrations, integration) => {
    integrations[integration.psaType] = integration.psaId;
    return integrations;
  }, {});
  const name = user?.name || extractName(email);
  const showRole = !loaded || user?.status === 'available';

  useEffect(() => {
    if (loaded && onStatusChange) onStatusChange(email, user?.status === 'available');
  }, [email, loaded, user?.status, onStatusChange]);

  return (
    <tr key={email}>
      <td className="align-middle">
        <p className="mb-0 me-1">{email}</p>
        {user?.status !== 'connected' && user?.status !== 'exist' && (
          <>
            <input name="invitations[][email]" type="hidden" value={email} />
            <input name="invitations[][name]" type="hidden" value={name} />
            <input name="invitations[][role]" type="hidden" value={role} />
          </>
        )}
      </td>
      {showRole ? (
        <td className="align-middle">
          <div className="d-flex align-items-center">
            <Select
              className="form-select-sm w-50 me-2"
              name="users[][role]"
              value={role}
              options={[
                { name: 'User', value: 'user' },
                { name: 'Administrator', value: 'administrator' },
              ]}
              onChange={e => onRoleChange(email, e.target.value)}
            />
            <Overlay
              text={
                <>
                  <span>
                    <strong>Administrators</strong> have full access to TimeZest, including configuring appointment
                    types and users, seeing billing information and inviting other users.
                  </span>
                  <span>
                    <strong>Users</strong> can only see their own information, and details of URLs for scheduling
                    appointments.
                  </span>
                </>
              }
              icon={<FontAwesomeIcon icon="question-circle" style={{ color: 'var(--tz-grey-400)' }} />}
            />
          </div>
        </td>
      ) : (
        <td className="align-middle">
          <small className="text-light-emphasis">{emailLabel(user)}</small>
        </td>
      )}

      {Object.values(integrations).map(integration => (
        <td key={integration.type} className="text-center align-middle">
          {status(user, integration, !!userIntegrations[integration.type], loaded)}
        </td>
      ))}
    </tr>
  );
};

function status(user, integration: IntegrationModel, available: boolean, loaded: boolean) {
  if (!loaded) return <FontAwesomeIcon spin color="grey" icon="spinner" />;

  if (user?.status === 'exist') {
    return <FontAwesomeIcon color="grey" icon="question-circle" />;
  }

  if (available) {
    return (
      <Overlay
        text={`TimeZest was able to associate ${user.email} with a user account on ${integration.name}.`}
        icon={<FontAwesomeIcon color="green" icon="circle-check" />}
      />
    );
  } else {
    return (
      <Overlay
        text={`TimeZest was not able to associate ${user.email} with a user account on ${integration.name}.`}
        icon={<FontAwesomeIcon color="red" icon="circle-xmark" />}
      />
    );
  }
}

function emailLabel(user: { status: string } | null | undefined) {
  switch (user?.status) {
    case 'connected':
      return 'This user is already added to your TimeZest account.';
    case 'exist':
      return 'This email address is associated with another TimeZest account.';
    default:
      return null;
  }
}

function extractName(email: string) {
  const username = email.split('@')[0];
  return username.split(/[\._]/).map(upperFirst).join(' ');
}

export default PotentialUser;

import type { FC } from 'react';

import startCase from 'lodash/startCase';

import asScreen from '@/Screen';
import Row from '@/ui/Row';
import SelectRow from '@/ui/SelectRow';
import Form from '@/Form';
import Header from '@/Header';
import Flash from '@/Flash';

import type { TicketStatus } from '@models/TicketStatus';
import { idStringifier } from '@shared/text';

type Board = {
  board: { name: string; id: number };
  config: {
    afterCancelPsaStatusId: string | null;
    afterCreatePsaStatusId: string | null;
    afterSchedulePsaStatusId: string | null;
    noResponsePsaStatusId: string | null;
  };
  statuses: TicketStatus[];
};

interface Props {
  boardDetails: Board;
}

const Preset: FC<Props> = ({ boardDetails: { board, config, statuses } }) => {
  const options = [{ name: '(None)', value: '' }, ...statuses.map(idStringifier)];

  return (
    <>
      <Header title={startCase(board.name)} subtitle="Preset Statuses" />
      <Flash />
      <Form method="patch" url={`/settings/integrations/connect_wise/boards/${board.id}`}>
        <SelectRow
          helpText="If a status is selected here, TimeZest will update the ticket to that status after a scheduling request is sent."
          label="Status after creation"
          name="board_configuration[after_create_psa_status_id]"
          options={options}
          value={config.afterCreatePsaStatusId || ''}
        />
        <SelectRow
          helpText="After a customer selects a time, TimeZest will update the ticket to this status, if selected."
          label="Status after scheduling"
          name="board_configuration[after_schedule_psa_status_id]"
          options={options}
          value={config.afterSchedulePsaStatusId || ''}
        />
        <SelectRow
          helpText="If the client cancels an appointment, move the ticket into this status, if selected."
          label="Status after cancellation"
          name="board_configuration[after_cancel_psa_status_id]"
          options={options}
          value={config.afterCancelPsaStatusId || ''}
        />
        <SelectRow
          helpText="If the no-response deadline configured for an appointment type passes after sending the client a notification, but they haven't chosen a time, move the ticket into this status."
          label="No-Response status"
          name="board_configuration[no_response_psa_status_id]"
          options={options}
          value={config.noResponsePsaStatusId || ''}
        />
        <hr />
        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(Preset);

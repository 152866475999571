import { useContext, useState, type FC } from 'react';

import asScreen from '@/Screen';
import Form from '@/Form';
import TextInputRow from '@/ui/TextInputRow';
import AccountContext from '@/AccountContext';
import Row from '@/ui/Row';
import InputGroupRow from '@/ui/InputGroupRow';
import SelectRow from '@/ui/SelectRow';
import Header from '@/Header';
import Flash from '@/Flash';

import Billing from './Billing';

import { extractSubdomain } from '@shared/text';
import type { BillingModel } from '@models/Billing';

interface Props {
  countries: any;
  errors: Record<string, string>;
  billing: BillingModel;
}

const AccountBillingShow: FC<Props> = ({ countries, errors, billing }) => {
  const { name, host, countryCode } = useContext(AccountContext);

  const [subdomain, setSubdomain] = useState(extractSubdomain(host));

  return (
    <>
      <Header title="Account & Billing" />
      <Flash />

      <Billing billing={billing} />
      <hr />

      <Form url="/settings/account" method="patch">
        <TextInputRow
          name="account[name]"
          label="Account Name"
          helpText="This value is used by TimeZest in emails and calendar invites sent to your clients."
          value={name}
          error={errors.name}
        />

        <InputGroupRow
          label="Subdomain"
          value={subdomain}
          name="account[subdomain]"
          helpText="When set, this subdomain will be used when your clients schedule appointments."
          append={<span className="input-group-text">.timezest.com</span>}
          error={errors.subdomain}
          onChange={e => setSubdomain(e.target.value)}
        />

        <SelectRow
          helpText="This value is used by TimeZest to detect country code in phone numbers provided by contacts."
          label="Country"
          name="account[country_code]"
          options={countriesForSelect(countries)}
          value={countryCode}
        />

        <hr />

        <Row>
          <input className="btn btn-primary" type="submit" value="Save Changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(AccountBillingShow);

function countriesForSelect(countries: string[][]) {
  return countries.map(([code, name]) => ({ name, value: code }));
}

import type { FC } from 'react';

const HaloPSATeamDescription: FC = () => {
  return (
    <div className="form-text text-muted small">
      Use an existing team already defined in your HaloPSA instance, and managed from there.
    </div>
  );
};

export default HaloPSATeamDescription;

import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';
import TeamDetailsSummary from '@/TeamDetailsSummary';
import MembershipSummary from '@/MembershipSummary';
import Expander from '@/ui/Expander';
import Membership from '@/ui/Membership';
import UnsavedConfirmationModal from '@/ui/UnsavedConfirmationModal';
import PersonalTeamPage from '@/ui/PersonalTeamPage';
import asScreen from '@/Screen';
import PlanContext from '@/PlanContext';
import AccountContext from '@/AccountContext';
import Header from '@/Header';
import Flash from '@/Flash';
import { UsageStats } from '@/screens/UsageStats';

import DetailsConfig from './DetailsConfig';

import type AppointmentTypeModel from '@models/AppointmentType';
import type { Member } from '@models/TeamMember';
import { hasIntegrationCapability, psaTeamName } from '@shared/utilities';
import Alert from '@shared/Alert';
import type { TeamType } from '@models/Integration';

interface Props {
  appointmentTypeIds: number[];
  appointmentTypes: AppointmentTypeModel[];
  errors: { [attr: string]: string };
  externalId: string;
  externalName: string | null;
  id: string;
  name: string;
  integrationName: string;
  members: Member[];
  teamType: TeamType;
  schedulingAlgorithm: string;
  slug: string;
  teamPageEnabled: boolean;
}

const PsaTeam: FC<Props> = ({
  appointmentTypeIds,
  appointmentTypes,
  errors,
  externalId,
  externalName,
  id,
  name,
  integrationName,
  members,
  teamType,
  schedulingAlgorithm,
  slug,
  teamPageEnabled,
}) => {
  const currentPlan = useContext(PlanContext);
  const { integrations } = useContext(AccountContext);

  const hasTeamDetailsErrors = !!(errors.internalName || errors.externalName || errors.slug);
  const hasTeamPageError = !!errors.appointmentTypeIds;

  const [dirty, setDirty] = useState(false);
  const [openExpander, setOpenExpander] = useState<string | null>(
    (hasTeamDetailsErrors && 'teamDetails') || (hasTeamPageError && 'teamPage') || null
  );

  const canExpand = openExpander === null || dirty === false;
  const showUrlBasedSchedulingUrl =
    currentPlan.allowsUrlBasedScheduling && hasIntegrationCapability(integrations, 'canAccessUrlBasedScheduling');

  const handleToggleExpander = (section: string) => (expanded: boolean) => {
    setOpenExpander(expanded ? section : null);
  };

  return (
    <DirtyContext.Provider
      value={{
        handleDirty() {
          setDirty(true);
        },
      }}
    >
      <Header
        title={name}
        subtitle={psaTeamName(teamType)}
        headerButton={{
          url: `/settings/teams/${teamType}/${id}`,
          icon: 'trash-alt',
          color: 'red',
          method: 'delete',
          text: 'Delete this Team',
        }}
      />
      <Flash />
      {!name && (
        <Alert
          icon="circle-xmark"
          color="red"
          className="mb-3"
          title={<>It was not possible to retrieve this team from {integrationName}.</>}
        />
      )}
      <UsageStats name={name} resourceId={externalId} />
      <Expander
        title="Team Details"
        summary={
          <TeamDetailsSummary
            internalName={name}
            externalName={externalName}
            schedulingAlgorithm={schedulingAlgorithm}
          />
        }
        url={`/settings/teams/${teamType}/${id}`}
        method="PATCH"
        icon="note-information"
        hasErrors={hasTeamDetailsErrors}
        canExpand={canExpand}
        expanded={openExpander === 'teamDetails'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('teamDetails')}
      >
        <DetailsConfig
          id={id}
          externalName={externalName}
          name={name}
          allowUrlBasedScheduling={showUrlBasedSchedulingUrl}
          slug={slug}
          schedulingAlgorithm={schedulingAlgorithm}
          errors={errors}
        />
      </Expander>
      <Expander
        title="Membership"
        summary={<MembershipSummary members={members} />}
        method="PATCH"
        icon="group"
        hasErrors={false}
        canExpand={canExpand}
        expanded={openExpander === 'membership'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('membership')}
      >
        <Membership members={members} />
      </Expander>
      <Expander
        title="Team Page"
        summary={
          teamPageEnabled
            ? `Team page enabled. ${appointmentTypeIds.length} appointment ${
                appointmentTypeIds.length > 1 ? 'types' : 'type'
              }  available for end users to book.`
            : 'Team page disabled.'
        }
        url={currentPlan.allowsShareableUrls && !!slug ? `/settings/teams/${id}/resource_page` : null}
        method="PATCH"
        icon="layout"
        hasErrors={hasTeamPageError}
        canExpand={canExpand}
        expanded={openExpander === 'teamPage'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('teamPage')}
      >
        {(() => {
          if (currentPlan.allowsShareableUrls && !!slug) {
            return (
              <PersonalTeamPage
                appointmentTypes={appointmentTypes}
                appointmentTypeIds={appointmentTypeIds}
                enabled={teamPageEnabled}
                error={errors.appointmentTypeIds}
                resourceSlug={slug}
                page="team"
              />
            );
          } else if (currentPlan.allowsShareableUrls) {
            return (
              <p className="mb-4">
                You need to assign a URL slug in the{' '}
                <button
                  className="btn btn-link btn-inline text-decoration-none"
                  onClick={() => handleToggleExpander('teamDetails')(true)}
                >
                  Team Details
                </button>{' '}
                section to enable the team page for this team.
              </p>
            );
          } else {
            return (
              <>
                <p className="fw-bold">Team pages are only available to TimeZest Professional subscribers.</p>
                <p className="mb-4">
                  They allow your end users to select the appointment type they wish to book from a list you configure,
                  giving your end users the flexibility to choose the appointment type they need.
                </p>
              </>
            );
          }
        })()}
      </Expander>
      <UnsavedConfirmationModal dirty={dirty} />
    </DirtyContext.Provider>
  );
};

export default asScreen(PsaTeam);

import type { FC } from 'react';

import type AppointmentTypeModel from '@models/AppointmentType';
import useDisplayDuration from '@shared/hooks/useDisplayDuration';

interface Props {
  appointmentType: AppointmentTypeModel;
}

const Summary: FC<Props> = ({ appointmentType }) => {
  const displayDuration = useDisplayDuration();

  const externalName = appointmentType.externalName
    ? `External name '${appointmentType.externalName}'`
    : 'No external name';

  let text = (
    <>
      {displayDuration(appointmentType.durationMins)} appointment; {externalName}
    </>
  );

  if (appointmentType.slug) {
    text = (
      <>
        {text}; Uses <code>{appointmentType.slug}</code> for URL-based/shareable URL scheduling.
      </>
    );
  } else {
    text = <>{text}; No shareable URL slug.</>;
  }

  if (appointmentType.askClientForPhoneNumber) {
    text = <>{text} Ask for best contact number.</>;
  } else {
    text = <>{text} Don&apos;t ask for best contact number.</>;
  }

  return text;
};

export default Summary;

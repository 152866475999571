import { useEffect, useState } from 'react';

export default function useStateFromProp<T1, T2 = T1>(
  prop: T1,
  mapper: (prop: T1) => T2 = prop => prop as unknown as T2
): [T2, React.Dispatch<React.SetStateAction<T2>>] {
  const [value, setValue] = useState<T2>(mapper(prop));

  useEffect(() => {
    setValue(mapper(prop));
  }, [prop]); // eslint-disable-line react-hooks/exhaustive-deps
  // Rule react-hooks/exhaustive-deps is disabled because mapper is defined in the same component that uses the hook.
  // Mapper function is going to be a different object on every re-render (despite having the same logic)
  // unless it's passed as a prop to the component or wrapped with `useCallback` hook.
  // Since it's additional overhead on the consumer of the hook with the same effect it's not worth the effort.

  return [value, setValue];
}

import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';
import TeamDetailsSummary from '@/TeamDetailsSummary';
import MembershipSummary from '@/MembershipSummary';
import Expander from '@/ui/Expander';
import TeamMembers from '@/ui/TeamMembers';
import UnsavedConfirmationModal from '@/ui/UnsavedConfirmationModal';
import PersonalTeamPage from '@/ui/PersonalTeamPage';
import PlanContext from '@/PlanContext';
import asScreen from '@/Screen';
import Flash from '@/Flash';
import Header from '@/Header';
import { UsageStats } from '@/screens/UsageStats';

import TimezestTeamDetailsConfig from './TimezestTeamDetailsConfig';

import type AppointmentTypeModel from '@models/AppointmentType';
import type { PsaType } from '@models/Integration';
import type { Member } from '@models/TeamMember';

interface Props {
  id: string;
  name: string;
  externalName: string | null;
  externalId: string;
  schedulingAlgorithm: string;
  errors: { [attr: string]: string };
  slug: string;
  members: Member[];
  integration: PsaType;
  appointmentTypes: AppointmentTypeModel[];
  appointmentTypeIds: number[];
  teamPageEnabled: boolean;
}

const TimezestTeam: FC<Props> = ({
  id,
  name,
  externalName,
  externalId,
  errors,
  schedulingAlgorithm,
  slug,
  members: initialMembers,
  integration,
  appointmentTypes,
  appointmentTypeIds,
  teamPageEnabled,
}) => {
  const currentPlan = useContext(PlanContext);

  const hasTeamDetailsErrors = !!(errors.internalName || errors.externalName || errors.slug);

  const hasTeamPageError = !!errors.appointmentTypeIds;

  const [dirty, setDirty] = useState(false);
  const [openExpander, setOpenExpander] = useState<string | null>(
    (hasTeamDetailsErrors && 'teamDetails') || (hasTeamPageError && 'teamPage') || null
  );
  const [members, setMembers] = useState<Member[]>(initialMembers);

  const canExpand = openExpander === null || dirty === false;

  const handleToggleExpander = (section: string) => (expanded: boolean) => {
    setOpenExpander(expanded ? section : null);
  };

  return (
    <DirtyContext.Provider
      value={{
        handleDirty() {
          setDirty(true);
        },
      }}
    >
      <Header
        title={name}
        subtitle="TimeZest-Defined Team"
        headerButton={{
          url: `/settings/teams/timezest/${id}`,
          icon: 'trash-alt',
          color: 'red',
          method: 'delete',
          text: 'Delete this Team',
        }}
      />
      <Flash />
      <UsageStats name={name} resourceId={externalId} />
      <Expander
        title="Team Details"
        summary={
          <TeamDetailsSummary
            internalName={name}
            externalName={externalName}
            schedulingAlgorithm={schedulingAlgorithm}
          />
        }
        url={`/settings/teams/timezest/${id}`}
        method="PATCH"
        icon="note-information"
        hasErrors={hasTeamDetailsErrors}
        canExpand={canExpand}
        expanded={openExpander === 'teamDetails'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('teamDetails')}
      >
        <TimezestTeamDetailsConfig
          id={id}
          internalName={name}
          externalName={externalName}
          slug={slug}
          schedulingAlgorithm={schedulingAlgorithm}
          errors={errors}
          integration={integration}
        />
      </Expander>
      <Expander
        title="Membership"
        summary={<MembershipSummary members={members} />}
        method="PATCH"
        icon="group"
        hasErrors={false}
        canExpand={canExpand}
        expanded={openExpander === 'membership'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('membership')}
      >
        <TeamMembers teamId={Number(id)} members={members} setMembers={setMembers} />
      </Expander>
      <Expander
        title="Team Page"
        summary={
          teamPageEnabled
            ? `Team page enabled. ${appointmentTypeIds.length} appointment ${
                appointmentTypeIds.length > 1 ? 'types' : 'type'
              }  available for end users to book.`
            : 'Team page disabled.'
        }
        url={currentPlan.allowsShareableUrls && !!slug ? `/settings/teams/${id}/resource_page` : null}
        method="PATCH"
        icon="layout"
        hasErrors={hasTeamPageError}
        canExpand={canExpand}
        expanded={openExpander === 'teamPage'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('teamPage')}
      >
        {(() => {
          if (currentPlan.allowsShareableUrls && !!slug) {
            return (
              <PersonalTeamPage
                appointmentTypes={appointmentTypes}
                appointmentTypeIds={appointmentTypeIds}
                enabled={teamPageEnabled}
                error={errors.appointmentTypeIds}
                resourceSlug={slug}
                page="team"
              />
            );
          } else if (currentPlan.allowsShareableUrls) {
            return (
              <p className="mb-4">
                You need to assign a URL slug in the{' '}
                <button
                  className="btn btn-link btn-inline text-decoration-none"
                  onClick={() => handleToggleExpander('teamDetails')(true)}
                >
                  Team Details
                </button>{' '}
                section to enable the team page for this team.
              </p>
            );
          } else {
            return (
              <>
                <p className="fw-bold">Team pages are only available to TimeZest Professional subscribers.</p>
                <p className="mb-4">
                  They allow your end users to select the appointment type they wish to book from a list you configure,
                  giving your end users the flexibility to choose the appointment type they need.
                </p>
              </>
            );
          }
        })()}
      </Expander>
      <UnsavedConfirmationModal dirty={dirty} />
    </DirtyContext.Provider>
  );
};

export default asScreen(TimezestTeam);

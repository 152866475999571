import type { FC } from 'react';

import Header from '@/Header';
import Flash from '@/Flash';
import asScreen from '@/Screen';

import CustomAttributeForm from '../CustomAttributeForm';

import UsersList from './Users';
import DeleteCustomAttribute from './DeleteCustomAttribute';

import type User from '@models/User';
import type { CustomAttribute as CustomAttributeModel } from '@models/CustomAttribute';

interface Props {
  customAttribute: CustomAttributeModel;
  errors: Record<string, string>;
  usersWithValues: (User & { value: string })[];
  usersWithoutValues: User[];
}

const CustomAttribute: FC<Props> = ({ customAttribute, errors, usersWithValues, usersWithoutValues }) => {
  return (
    <>
      <Header title={customAttribute.name} subtitle="Custom Attribute" />
      <Flash />
      <CustomAttributeForm
        customAttribute={customAttribute}
        errors={errors}
        url={`/settings/custom_attributes/${customAttribute.id}`}
        method="patch"
      />
      <hr />
      <UsersList usersWithValues={usersWithValues} usersWithoutValues={usersWithoutValues} />
      <hr />
      <DeleteCustomAttribute id={customAttribute.id || 0} />
    </>
  );
};

export default asScreen(CustomAttribute);

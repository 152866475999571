import type { FC } from 'react';

import Badge from '@/ui/Badge';

interface Props {
  currentPlan: boolean;
  annualPlan: boolean;
}

const PlanBadge: FC<Props> = ({ currentPlan, annualPlan }) => {
  if (currentPlan) {
    return <Badge color="green">Your current plan!</Badge>;
  } else if (annualPlan) {
    return <Badge color="blue">Save 17%</Badge>;
  }

  return null;
};

export default PlanBadge;

import type { FC } from 'react';

import AfterCreateStatus from './AfterCreateStatus';
import Badge from './Badge';
import BlankSlate from './BlankSlate';
import CancellationStatus from './CancellationStatus';
import ClosedStatusWarning from './ClosedStatusWarning';
import NoResponseStatus from './NoResponseStatus';
import UpdateStatus from './UpdateStatus';

interface Props {
  name: string;
  id: number;
  project: boolean;
  afterCreatePsaStatusName?: string | null;
  afterSchedulePsaStatusName?: string | null;
  afterCancelPsaStatusName?: string | null;
  noResponsePsaStatusName?: string | null;
  closedStatusName?: string | null;
}

const Board: FC<Props> = ({
  name,
  id,
  project,
  afterCreatePsaStatusName,
  afterSchedulePsaStatusName,
  afterCancelPsaStatusName,
  noResponsePsaStatusName,
  closedStatusName,
}) => {
  const renderBlankSlate = !(
    afterSchedulePsaStatusName ||
    afterCancelPsaStatusName ||
    noResponsePsaStatusName ||
    afterCreatePsaStatusName
  );

  return (
    <a className="board" href={`/settings/integrations/connect_wise/boards/${id}`}>
      <div className="card mb-2">
        <div className="card-body d-flex py-2">
          <div className="flex-grow-1">
            <div className="title">
              <span className="align-middle fw-bold">
                {name}
                &nbsp;&nbsp;
              </span>
              <Badge project={project} />
            </div>
            {afterCreatePsaStatusName && <AfterCreateStatus name={afterCreatePsaStatusName} />}
            {afterSchedulePsaStatusName && <UpdateStatus name={afterSchedulePsaStatusName} />}
            {afterCancelPsaStatusName && <CancellationStatus name={afterCancelPsaStatusName} />}
            {noResponsePsaStatusName && <NoResponseStatus name={noResponsePsaStatusName} />}
            {closedStatusName && <ClosedStatusWarning name={closedStatusName} project={project} />}
            {renderBlankSlate && <BlankSlate />}
          </div>
        </div>
      </div>
    </a>
  );
};

export default Board;

import type { FC } from 'react';

interface Props {
  filtering: boolean;
}

const NoUsers: FC<Props> = ({ filtering }) => {
  if (filtering) {
    return <span>No users match this filter.</span>;
  } else {
    return <span>No users were found.</span>;
  }
};

export default NoUsers;

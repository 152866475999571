import type { FC } from 'react';

const TimeZestTeamDescription: FC = () => {
  return (
    <div className="form-text text-muted small">
      Create a team in TimeZest by selecting which members will belong to it.
    </div>
  );
};

export default TimeZestTeamDescription;

import type { FC } from 'react';

import Pagination from '@/Pagination';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  users: { connected: boolean; name: string }[];
  usersCount: number;
  usersPerPage: number;
  connectedMessage: string;
  notConnectedMessage: string;
}

const UsersList: FC<Props> = ({ users, usersCount, usersPerPage, connectedMessage, notConnectedMessage }) => {
  return (
    <>
      {users.map((u, i) => (
        <div key={i} className="card mb-2">
          <div className="card-body d-flex py-2">
            <div className="flex-grow-1 fw-bold">{u.name}</div>
            {u.connected ? (
              <>
                <span className="text-success">
                  <FontAwesomeIcon icon="check" />
                </span>
                &nbsp; {connectedMessage}
              </>
            ) : (
              <>
                <span className="text-danger">
                  <FontAwesomeIcon icon="times" />
                </span>
                &nbsp; {notConnectedMessage}
              </>
            )}
          </div>
        </div>
      ))}

      <Pagination itemsCount={usersCount} itemsPerPage={usersPerPage} />
    </>
  );
};

export default UsersList;

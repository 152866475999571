import type { FC } from 'react';

import Form from '@/Form';
import TextInputRow from '@/ui/TextInputRow';
import SelectRow from '@/ui/SelectRow';
import Row from '@/ui/Row';

import type { CustomAttribute } from '@models/CustomAttribute';

const OPTIONS = [
  { value: 'string', name: 'String' },
  { value: 'text', name: 'Text' },
];

interface Props {
  customAttribute: CustomAttribute;
  errors: Record<string, string>;
  url: string;
  method: 'post' | 'patch';
}

const CustomAttributeForm: FC<Props> = ({ customAttribute, errors, url, method }) => {
  return (
    <Form url={url} method={method}>
      <TextInputRow
        name="custom_attribute[name]"
        label="Name"
        value={customAttribute.name}
        helpText={
          <>
            The name of the attribute used when including it in an email template — e.g.
            <code>webex_url</code>
          </>
        }
        error={errors.name}
      />

      <TextInputRow
        name="custom_attribute[description]"
        label="Description"
        value={customAttribute.description}
        helpText="Human-readable name for the attribute, used in the TimeZest UI."
        error={errors.description}
      />

      <SelectRow
        name="custom_attribute[attr_type]"
        label="Type"
        value={customAttribute.type || 'string'}
        options={OPTIONS}
        width={6}
      />

      {!customAttribute.id && <hr />}

      <Row>
        <input
          className="btn btn-primary"
          type="submit"
          value={customAttribute.id ? 'Save Changes' : 'Create Custom Attribute'}
          data-disable-with={customAttribute.id ? 'Saving' : 'Create Custom Attribute'}
        />
      </Row>
    </Form>
  );
};

export default CustomAttributeForm;

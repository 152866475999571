import { useState, type FC } from 'react';

import Row from '@/ui/Row';
import TextInputRow from '@/ui/TextInputRow';
import PasswordInputRow from '@/ui/PasswordInputRow';
import Form from '@/Form';

import TicketStatuses from './TicketStatuses';

import InputGroup from '@shared/ui/Inputs/InputGroup';

import type { IntegrationSettings, TicketStatus } from '..';

interface Props {
  integration: IntegrationSettings;
  ticketStatuses: TicketStatus[];
}

const Settings: FC<Props> = ({ integration, ticketStatuses }) => {
  const [resourceServer, setResouceServer] = useState(integration.resourceServer);
  const [authServer, setAuthServer] = useState(integration.authServer);

  return (
    <>
      <p>These are the credentials and settings that TimeZest will use to connect to your instance of HaloPSA.</p>

      <Form url="/settings/integrations/halo_psa" method="patch">
        <TextInputRow
          label="Client ID"
          name="halo_psa_integration[client_id]"
          width={6}
          value={integration.clientId}
          error={integration.errors.clientId}
        />

        <PasswordInputRow
          label="Client Secret"
          name="halo_psa_integration[client_secret]"
          width={6}
          value={integration.clientSecret}
          error={integration.errors.clientSecret}
        />

        <Row label="Resource Server" width={6} labelFor="halo_psa_integration[resource_server]">
          <InputGroup
            prepend="https://"
            name="halo_psa_integration[resource_server]"
            value={resourceServer}
            error={integration.errors.resourceServer}
            onChange={e => setResouceServer(e.target.value)}
          />
        </Row>

        <Row label="Auth Server" width={6} labelFor="halo_psa_integration[auth_server]">
          <InputGroup
            prepend="https://"
            name="halo_psa_integration[auth_server]"
            value={authServer}
            error={integration.errors.authServer}
            onChange={e => setAuthServer(e.target.value)}
          />
        </Row>

        {integration.connected && (
          <>
            <hr />
            <TicketStatuses
              ticketStatuses={ticketStatuses}
              afterCancelPsaStatusId={integration.afterCancelPsaStatusId}
              afterCreatePsaStatusId={integration.afterCreatePsaStatusId}
              afterSchedulePsaStatusId={integration.afterSchedulePsaStatusId}
              noResponsePsaStatusId={integration.noResponsePsaStatusId}
            />
          </>
        )}

        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default Settings;

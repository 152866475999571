import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import type { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
import classNames from 'classnames';
import keyBy from 'lodash/keyBy';

import Entry from './Entry';
import Variable from './Variable';
import { handleBeforeInput, handlePaste } from './editorManipulators';

import type { EditorPlugin } from '@draft-js-plugins/editor';
import DraftEditor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import VariablesContext from '@shared/VariablesContext';
import Badge from '@ui/Badge';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';

const scrollFunctionRestorer = HTMLElement.prototype.scrollIntoView;

interface Props {
  name: string;
  initialValue: EditorState;
  paragraph: FC;
  plugins?: EditorPlugin[];
  readOnly: boolean;
  error?: string | null;
  serialize: (contentState: ContentState) => string;
}

const Editor: FC<Props> = ({ initialValue, name, paragraph, plugins: initialPlugins, readOnly, error, serialize }) => {
  const { variables } = useContext(VariablesContext);

  const insertableVariables = useMemo(() => {
    return variables.filter(v => v.insertable);
  }, [variables]);

  const keyedByNameVariablesList = useMemo(() => {
    return keyBy(insertableVariables, 'name');
  }, [insertableVariables]);

  const [editorState, setEditorState] = useStateFromProp(initialValue);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(insertableVariables);
  const [serialized, setSerialized] = useState('');
  const [focused, setFocused] = useState(false);
  const [conditionalShown, setConditionalShown] = useState(false);

  useEffect(() => {
    if (focused) {
      HTMLElement.prototype.scrollIntoView = () => {};
    } else {
      HTMLElement.prototype.scrollIntoView = scrollFunctionRestorer;
    }
  }, [focused]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent: Variable,
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '{',
      popperOptions: {
        modifiers: [
          {
            name: 'mention',
            enabled: true,
            phase: 'beforeWrite',
            fn: ({ state }) => {
              state.styles.popper.maxHeight = '400px';
              state.styles.popper.overflow = 'auto';
              state.styles.popper.zIndex = '2000';
            },
          },
        ],
      },
    });

    const { MentionSuggestions } = mentionPlugin;

    const plugins = [mentionPlugin, ...(initialPlugins || [])];
    return { plugins, MentionSuggestions };
  }, [initialPlugins]);

  useEffect(() => {
    setSerialized(serialize(editorState.getCurrentContent()));
  }, [editorState, serialize]);

  const handleSearchChange = ({ value }: { value: string }) => {
    const lcValue = value.toLowerCase();
    const suggestedVariables = insertableVariables.filter(v => v.name.toLowerCase().indexOf(lcValue) >= 0);
    setSuggestions(suggestedVariables);
  };

  const handleBlur = () => {
    const contentState = editorState.getCurrentContent();
    setSerialized(serialize(contentState));

    setFocused(false);
  };

  const customBlockRenderer = (block: Draft.DraftModel.ImmutableData.ContentBlock) => {
    const type = block.getType();

    if (type === 'unstyled') {
      return {
        component: paragraph,
        props: {
          pushContentState: (newContentState: ContentState) => {
            const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data');
            setEditorState(newEditorState);
          },
          setConditionalShown,
          readOnly,
        },
      };
    }
  };

  return (
    <>
      <div className={classNames('form-control h-auto', { 'is-invalid': error })}>
        <input type="hidden" name={name} value={serialized} />

        <DraftEditor
          editorState={editorState}
          plugins={plugins}
          blockRendererFn={customBlockRenderer}
          readOnly={conditionalShown || readOnly}
          stripPastedStyles={true}
          handleBeforeInput={handleBeforeInput(setEditorState)}
          handlePastedText={handlePaste(setEditorState, keyedByNameVariablesList)}
          onChange={setEditorState}
          onBlur={handleBlur}
          onFocus={() => {
            setFocused(true);
          }}
        />

        <MentionSuggestions
          open={open}
          suggestions={suggestions}
          entryComponent={Entry}
          onOpenChange={setOpen}
          onSearchChange={handleSearchChange}
        />
      </div>
      <div className="invalid-feedback">{error}</div>
      {!readOnly && (
        <div className="text-muted mt-1">
          <span className="small">Press </span>
          <Badge color="grey">Shift</Badge>
          <span className="small"> + </span>
          <Badge color="grey">{'{'}</Badge>
          <span className="small"> to insert a variable for TimeZest to fill in.</span>
        </div>
      )}
    </>
  );
};

export default Editor;

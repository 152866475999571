import type { FC } from 'react';
import { StrictMode, useCallback, useEffect } from 'react';

import { add, formatISO } from 'date-fns';

import asScreen from '@/Screen';

import DashboardFilter from './Filter';
import RequestRatesStats from './Stats/RequestRatesStats';
import TqlSchedulingRequests from './TqlSchedulingRequests';

import type Reference from '@models/Reference';
import type { Filter } from '@models/Filter';
import { generateTQLString } from '@shared/ui/Filter/utilities';
import useStateFromProp from '@shared/hooks/useStateFromProp';

import { getDateDaysBeforeNow } from '../../../../shared/time';

import 'react-calendar/dist/Calendar.css';

type Props = {
  appointmentTypes: Reference[];
  userRole: string;
  tql: Filter[];
};

const TWO_WEEKS_FROM_NOW_TIME_RANGE = {
  from: getDateDaysBeforeNow(14),
  to: new Date(),
};

const { from, to } = TWO_WEEKS_FROM_NOW_TIME_RANGE;

const DashboardContent: FC<Props> = ({ appointmentTypes, tql }) => {
  const [tqlString, setTqlString] = useStateFromProp(generateTQLString(tql));

  const popStateHandler = useCallback(e => {
    e.preventDefault();
    (window as any).Turbolinks.visit(window.location.href, { action: 'replace' });
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', popStateHandler);

    return () => {
      window.removeEventListener('popstate', popStateHandler);
    };
  }, [popStateHandler]);

  useEffect(() => {
    if (tql.length === 0) {
      setTqlString(generateTQLString(getTqlFilterDateRange(from, to)));
    }
  }, [setTqlString, tql.length]);

  return (
    <div className="dashboard">
      <h6 className="fw-bold mb-3">TimeZest Usage</h6>

      <DashboardFilter
        appointmentTypes={appointmentTypes}
        tqlFilters={tql.length === 0 ? [...getTqlFilterDateRange(from, to).slice(0, 1)] : tql}
        onUpdateTqlString={setTqlString}
      />

      <RequestRatesStats tqlString={tqlString} timeRange={TWO_WEEKS_FROM_NOW_TIME_RANGE} />

      <div className="d-flex align-items-center justify-content-between mb-2 mt-5">
        <h6 className="fw-bold mb-0">Scheduling Requests</h6>
        <a href={`/scheduling_requests.csv?filter=${tqlString}`} className="btn btn-primary btn-sm">
          Download as CSV
        </a>
      </div>

      <TqlSchedulingRequests tqlString={tqlString} />
    </div>
  );
};

const Dashboard: FC<{
  userRole: string;
  appointmentTypes: Reference[];
  tql: Filter[];
}> = ({ appointmentTypes, userRole, tql }) => (
  <StrictMode>
    <DashboardContent appointmentTypes={appointmentTypes} userRole={userRole} tql={tql} />
  </StrictMode>
);

export default asScreen(Dashboard);

function getTqlFilterDateRange(from: Date, to: Date): Filter[] {
  return [
    {
      attribute: 'scheduling_request.created_at',
      operator: 'GTE' as const,
      value: formatISO(from, { representation: 'date' }),
      valueName: '',
      persisted: false,
    },
    {
      attribute: 'scheduling_request.created_at',
      operator: 'LT' as const,
      value: formatISO(add(to, { days: 1 }), { representation: 'date' }),
      valueName: '',
      persisted: false,
    },
  ];
}

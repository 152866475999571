import type { FC } from 'react';

import classNames from 'classnames';

import type { InputProps, NumberInputProps } from '@models/InputControl';

const NumberInput: FC<InputProps & NumberInputProps> = props => {
  const { className, ariaLabel, error, onKeyDown, ...rest } = props;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }

    if (onKeyDown) onKeyDown(e);
  };

  return (
    <>
      <input
        type="number"
        aria-label={ariaLabel}
        className={classNames('form-control', { 'is-invalid': !!error }, className)}
        onKeyDown={handleKeyDown}
        {...rest}
      />
      {error && <p className="text-danger small mb-0 mt-1">{error}</p>}
    </>
  );
};

export default NumberInput;

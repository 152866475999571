import type { FC } from 'react';

import Row from '@/ui/Row';
import Badge from '@/ui/Badge';

import TemplateBadge from './TemplateBadge';

import type { EmailTemplateModel } from '@models/EmailTemplateModel';

interface Props {
  emailTemplates: EmailTemplateModel[];
}

const Templates: FC<Props> = ({ emailTemplates }) => {
  return (
    <>
      {emailTemplates.map((template, i) => (
        <Row key={`template_${i}`} label={i === 0 ? 'Email Templates' : <>&nbsp;</>} lastItem={true} withInput={false}>
          <div className={`py-2 ${i > 0 && 'border-top'}`}>
            <a href={`/settings/email_templates/${template.id}`}>
              {template.name}
              &nbsp;
              <TemplateBadge templateType={template.templateType} />
              &nbsp;
              {template.readOnly && <Badge color="purple">Built-in template</Badge>}
              {template.legacy && <Badge color="grey">Legacy template</Badge>}
            </a>
          </div>
        </Row>
      ))}
    </>
  );
};

export default Templates;

import type { FC } from 'react';

import Row from '@/ui/Row';

import type { StaticIPConfiguration } from '.';

interface Props {
  applicationHost: string;
  url: string;
  staticIpConfiguration: StaticIPConfiguration;
}

const Configuration: FC<Props> = ({ applicationHost, url, staticIpConfiguration }) => {
  const { useStaticIp, ip1, ip2 } = staticIpConfiguration;

  return (
    <>
      <p>
        Use the following settings to configure the TimeZest pod in ConnectWise PSA. These are configured in the
        &apos;Manage Hosted API&apos; setup table.
      </p>
      <Row label="Description" withInput={false}>
        <code>TimeZest</code>
      </Row>
      <Row
        label="Screen"
        helpText="If you wish to use TimeZest for both Project and Service tickets, you'll have to add the pod twice, once for each screen."
        withInput={false}
      >
        <code>Service Ticket</code> or <code>Project Ticket</code>
      </Row>
      <Row label="Origin" withInput={false}>
        <code>{applicationHost}</code>
      </Row>
      <Row label="URL" withInput={false}>
        <code>{url}</code>
      </Row>
      <Row label="Type" withInput={false}>
        <code>Pod</code>
      </Row>
      <Row label="Pod Height" withInput={false}>
        <code>{380}</code>
      </Row>

      {useStaticIp ? (
        <>
          <p>
            TimeZest is configured to use static IP addresses when accessing your instance of ConnectWise PSA. The IP
            addresses we will use are:
          </p>
          <p className="mb-0">
            <strong>{ip1}</strong> and <strong>{ip2}</strong>
          </p>
        </>
      ) : (
        <p className="mb-0">
          If you wish to have TimeZest use static IP addresses when accessing your instance of ConnectWise PSA, send an
          email to <a href="mailto:help@timezest.com">help@timezest.com</a> and we&apos;ll enable it for your account.
        </p>
      )}
    </>
  );
};

export default Configuration;

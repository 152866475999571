import type { FC } from 'react';

interface Props {
  text: string;
}

const TemplateTypeSubText: FC<Props> = ({ text }) => {
  return <div className="form-text text-muted small">{text}</div>;
};

export default TemplateTypeSubText;

import {
  useGetConnectWiseCompanyQuery,
  useGetAutotaskCompanyQuery,
  useGetHaloPsaCompanyQuery,
} from '@graphql/generated';

export default function useGetSingleCompanyName(
  selectedAttribute: string,
  value: number,
  valueName: string
): [string, boolean] {
  const needInitialLoad = !valueName;

  const { data: connectwiseCompanyData, loading: connectwiseCompanyLoading } = useGetConnectWiseCompanyQuery({
    skip: !selectedAttribute.includes('connectwise_psa_company_id') || !needInitialLoad,
    variables: { companyId: value },
  });

  const { data: autotaskCompanyData, loading: autotaskCompanyLoading } = useGetAutotaskCompanyQuery({
    skip: !selectedAttribute.includes('autotask_company_id') || !needInitialLoad,
    variables: { companyId: value },
  });

  const { data: haloPsaCompanyData, loading: haloPsaCompanyLoading } = useGetHaloPsaCompanyQuery({
    skip: !selectedAttribute.includes('halo_psa_company_id') || !needInitialLoad,
    variables: { companyId: value },
  });

  const loading = connectwiseCompanyLoading || autotaskCompanyLoading || haloPsaCompanyLoading;

  const selectedCompanyName = (
    autotaskCompanyData?.['autotask'] ||
    connectwiseCompanyData?.['connectWise'] ||
    haloPsaCompanyData?.['haloPsa']
  )?.company?.name;

  return [selectedCompanyName || '', loading];
}

import type { FC } from 'react';

import Event from './Event';

import type EventModel from '@models/Event';

interface Props {
  events: EventModel[];
}

const AuditTrail: FC<Props> = ({ events }) => {
  const eventsForDisplay = events.slice(0).sort((a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;

    return 0;
  });

  return (
    <div className="card mt-4">
      <div className="card-header">
        <div className="mb-0 flex-grow-1">Audit Trail</div>
      </div>
      <div className="card-body">
        {eventsForDisplay.map((event, index) => (
          <Event event={event} index={index} key={`event${index}`} />
        ))}
      </div>
    </div>
  );
};

export default AuditTrail;

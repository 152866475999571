import type { FC } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import Settings from './Settings';
import PresetStates from './PresetStates';

import Alert from '@shared/Alert';

export type IntegrationSettings = {
  errors: Record<string, string>;
  presharedKey: string;
  host: string;
  username: string;
  password: string;
  connected: boolean;
  statePresets: Record<string, StatePresetAttributes>;
};

export type StatePresetAttributes = {
  afterCancelPsaStateId: string | null;
  afterCreatePsaStateId: string | null;
  afterSchedulePsaStateId: string | null;
  noResponsePsaStateId: string | null;
  afterCancelPsaStateName: string | null;
  afterCreatePsaStateName: string | null;
  afterSchedulePsaStateName: string | null;
  noResponsePsaStateName: string | null;
};

interface Props {
  integration: IntegrationSettings;
  webhookToken: string;
}

const ServiceNow: FC<Props> = ({ integration, webhookToken }) => {
  return (
    <>
      <Header title="ServiceNow Integration" />
      <Flash />
      {integration.errors.base && (
        <Alert
          color="red"
          className="mb-3"
          icon="circle-xmark"
          title="TimeZest was unable to connect to your ServiceNow instance with the credentials provided."
          content={<p className="mb-0">Reason from ServiceNow: {integration.errors.base}</p>}
        />
      )}
      <Settings integration={integration} webhookToken={webhookToken} />
      {integration.connected && (
        <>
          <hr />
          <h5 className="mb-3">Preset States</h5>
          {Object.entries(integration.statePresets).map(([type, states], i) => (
            <PresetStates key={i} presetType={type} presetStates={states} />
          ))}
        </>
      )}
    </>
  );
};

export default asScreen(ServiceNow);

import { useContext, type FC } from 'react';

import CopyToClipboard from '@/CopyToClipboard';
import AccountContext from '@/AccountContext';

interface Props {
  appointmentTypeSlug: string | null;
  host: string | null;
  resourceSlugs: string | undefined;
}

const UrlBasedUrl: FC<Props> = ({ appointmentTypeSlug, host, resourceSlugs }) => {
  const { psaType } = useContext(AccountContext);

  let ticketId = '';

  switch (psaType) {
    case 'autotask':
      ticketId = '[Ticket: Number]';
      break;
    case 'connect_wise':
      ticketId = '[srnumber]';
      break;
    case 'halo_psa':
      ticketId = '[ID: Number]';
      break;
    case 'service_now':
      ticketId = '[Task: Number]';
      break;
    default:
      break;
  }

  const url = `${host}/${resourceSlugs}/${appointmentTypeSlug}/ticket/${ticketId}`;

  return (
    <div className="small">
      <strong className="text-muted">URL-based scheduling URL:</strong>
      &nbsp;&nbsp;
      {url}
      &nbsp;&nbsp;
      <CopyToClipboard text={url} />
    </div>
  );
};

export default UrlBasedUrl;

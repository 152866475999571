import type { FC } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { EditorState, RichUtils } from 'draft-js';
import classNames from 'classnames';

import ConfigContext from './ConfigContext';
import {
  getLinkUrl,
  isCursorAtLink,
  isNormalTextHighlighted,
  removeLink,
  upsertLink,
  upsertVariable,
} from './textManipulators';

import VariablesContext from '@shared/VariablesContext';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.025);
`;

const ToolbarButton = styled('div')<{ $enabled: boolean; $selected: boolean | undefined }>`
  padding: 8px;
  opacity: ${props => (props.$enabled ? '1' : '0.4')};
  cursor: ${props => (props.$enabled ? 'pointer' : 'not-allowed')};
  background-color: ${props => (props.$selected ? '#87cefa' : '')};

  &:hover {
    background-color: ${props => (props.$enabled ? '#87cefa' : '')};
  }
`;

const VariablesDropdownMenu = styled('div')<{ $openVariablesDropdownMenu: boolean }>`
  display: ${props => (props.$openVariablesDropdownMenu ? 'block' : 'none')};
  position: absolute;
  top: 38px;
  left: 0px;
  z-index: 1000;
  width: max-content;
  max-height: 250px;
  overflow: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const VariableOption = styled.span`
  cursor: pointer;
  padding: 8px 15px;
  background-color: #ffffff;

  &:hover {
    background-color: #e7f4ff;
  }
`;

const LinkOption = styled('input')<{ $open: boolean }>`
  display: ${props => (props.$open ? 'inline-block' : 'none')};
  border: none;
  padding: 5px;
  padding-right: 40px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 37.609px;
  transition: width 100ms linear;

  :focus {
    outline: none;
    box-shadow: 0 0 0 2px #87cefa inset;
  }
`;

interface Props {
  textElementSelected: boolean;
  textFormattingBarPlaceholder: boolean;
  editorState?: EditorState;
  setEditorState?: (editorState: EditorState) => void;
  onBlur?: () => void;
}

export const TextFormattingBarPortal = ({ children, editorId }) => {
  let domElement = document.getElementById(`${editorId}-text-formatting-bar-wrapper`);

  if (!domElement) return null;

  return createPortal(children, domElement);
};

const TextFormattingBar: FC<Props> = ({
  textElementSelected,
  textFormattingBarPlaceholder,
  editorState,
  setEditorState,
  onBlur: updateEditorState,
}) => {
  const { readOnly } = useContext(ConfigContext);
  const { variables } = useContext(VariablesContext);

  const variablesDropdownMenu = useRef<HTMLDivElement>(null);
  const variablesDropdownMenuButton = useRef<HTMLDivElement>(null);

  const [openVariablesDropdownMenu, setOpenVariablesDropdownMenu] = useState(false);
  const [pressedEnter, setPressedEnter] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent): void => {
    if (
      !variablesDropdownMenu.current?.contains(e.target as Node) &&
      !variablesDropdownMenuButton.current?.contains(e.target as Node)
    ) {
      setOpenVariablesDropdownMenu(false);
    }
  };

  const toggleInlineTextStyle = (value: string) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!editorState || !textElementSelected || !setEditorState) return;

    setEditorState(RichUtils.toggleInlineStyle(editorState, value));
  };

  const handleOpenVariablesDropdownMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!textElementSelected) return;

    setOpenVariablesDropdownMenu(!openVariablesDropdownMenu);
  };

  const handleInsertVariable = (
    variableValue: string,
    variableName: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (!editorState || !setEditorState) return;

    const ContentStateAndEditorState = upsertVariable(editorState, variableValue, variableName);

    if (!ContentStateAndEditorState) return;

    const [textWithEntity, newEditorState] = ContentStateAndEditorState;

    setEditorState(EditorState.forceSelection(newEditorState, textWithEntity.getSelectionAfter()));

    setOpenVariablesDropdownMenu(false);
  };

  const handleLinkify = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (
      !textElementSelected ||
      !editorState ||
      !setEditorState ||
      (!isNormalTextHighlighted(editorState) && !isCursorAtLink(editorState))
    )
      return;

    if (!isCursorAtLink(editorState)) {
      const [entityKey, newEditorState] = upsertLink(editorState, 'https://');

      setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    }
  };

  const handleOnChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!textElementSelected || !editorState || !setEditorState) return;

    const [entityKey, newEditorState] = upsertLink(editorState, e.target.value);

    setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
  };

  const handleRemoveLink = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!editorState || !setEditorState) return;

    setEditorState(removeLink(editorState));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (updateEditorState) updateEditorState();

      if (!pressedEnter) setPressedEnter(true);
    }
  };

  const handleBlur = () => {
    if (!pressedEnter && updateEditorState) {
      updateEditorState();
    }

    setPressedEnter(false);
  };

  return (
    <Container
      className={classNames({ 'pe-none': readOnly })}
      hidden={textElementSelected && textFormattingBarPlaceholder}
    >
      <ToolbarButton
        $enabled={textElementSelected}
        $selected={editorState?.getCurrentInlineStyle().has('BOLD')}
        onMouseDown={toggleInlineTextStyle('BOLD')}
      >
        <FontAwesomeIcon icon="bold" />
      </ToolbarButton>
      <ToolbarButton
        $enabled={textElementSelected}
        $selected={editorState?.getCurrentInlineStyle().has('ITALIC')}
        onMouseDown={toggleInlineTextStyle('ITALIC')}
      >
        <FontAwesomeIcon icon="italic" />
      </ToolbarButton>
      <ToolbarButton
        $enabled={textElementSelected}
        $selected={editorState?.getCurrentInlineStyle().has('UNDERLINE')}
        onMouseDown={toggleInlineTextStyle('UNDERLINE')}
      >
        <FontAwesomeIcon icon="underline" />
      </ToolbarButton>
      <ToolbarButton
        ref={variablesDropdownMenuButton}
        $enabled={textElementSelected}
        $selected={openVariablesDropdownMenu}
        onMouseDown={handleOpenVariablesDropdownMenu}
      >
        <FontAwesomeIcon icon="paperclip" />
      </ToolbarButton>
      <VariablesDropdownMenu ref={variablesDropdownMenu} $openVariablesDropdownMenu={openVariablesDropdownMenu}>
        {variables
          .filter(v => v.insertable)
          .map(variable => (
            <VariableOption
              key={variable.variable}
              className="d-block"
              onClick={event => handleInsertVariable(variable.variable, variable.name, event)}
            >
              {variable.name}
            </VariableOption>
          ))}
      </VariablesDropdownMenu>
      <ToolbarButton
        $enabled={
          (textElementSelected && isCursorAtLink(editorState)) ||
          (textElementSelected && isNormalTextHighlighted(editorState))
        }
        $selected={isCursorAtLink(editorState)}
        onMouseDown={handleLinkify}
      >
        <FontAwesomeIcon icon="link" />
      </ToolbarButton>
      <div className="position-relative">
        <LinkOption
          $open={isCursorAtLink(editorState)}
          className="m-0"
          type="text"
          value={getLinkUrl(editorState)}
          placeholder="https://"
          onChange={handleOnChangeLink}
          onClick={e => {
            e.stopPropagation();
          }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <FontAwesomeIcon
          className={classNames(
            'TextFormattingBar__RemoveLinkBtn',
            { 'd-inline-block': isCursorAtLink(editorState) },
            { 'd-none': !isCursorAtLink(editorState) }
          )}
          icon="trash-alt"
          onMouseDown={handleRemoveLink}
        />
      </div>
    </Container>
  );
};

export default TextFormattingBar;

import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import type { FieldProps } from './Field';
import Field from './Field';

import TextInput from '@shared/ui/Inputs/TextInput';
import InputGroup from '@shared/ui/Inputs/InputGroup';
import NumberInput from '@shared/ui/Inputs/NumberInput';
import useStateFromProp from '@shared/hooks/useStateFromProp';

import { extractNumber, updateValueWithUnit } from '../../utilities';

interface Props extends FieldProps {
  value: string;
  type: string;
  disabled?: boolean;
  name?: string;
  max?: number;
  min?: number;
  step?: number;
  unit?: string;
  error?: string;
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
}

const Input: FC<PropsWithChildren<Props>> = props => {
  const { value: initialValue, unit, children, error, id, onChange, onInput, ...rest } = props;

  const [value, setValue] = useStateFromProp(initialValue, v => extractNumber(v, unit));
  const [pressedEnter, setPressedEnter] = useState(false);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onChange) onChange(updateValueWithUnit(value, unit));

      if (!pressedEnter) setPressedEnter(true);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    setValue(newValue);

    if (onInput) onInput(updateValueWithUnit(newValue, unit));

    setPressedEnter(false);
  };

  const handleOnBlur = () => {
    if (!pressedEnter && onChange) onChange(updateValueWithUnit(value, unit));

    setPressedEnter(false);
  };

  const handlers = {
    onKeyDown: handleOnKeyDown,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  };

  return (
    <Field id={props.id} label={props.label} className={props.className}>
      {unit ? (
        <InputGroup name={id} id={id} value={value} append={props.unit} error={error} {...handlers} {...rest} />
      ) : props.type === 'text' ? (
        <TextInput name={id} id={id} value={value} {...handlers} {...rest} />
      ) : (
        <NumberInput name={id} id={id} value={value} {...handlers} {...rest} />
      )}

      {children}
    </Field>
  );
};

export default Input;

import { type FC, useContext } from 'react';

import CopyToClipboard from '@/CopyToClipboard';
import AccountContext from '@/AccountContext';
import PlanContext from '@/PlanContext';
import CurrentUserContext from '@/CurrentUserContext';

import InputGroup from '@shared/ui/Inputs/InputGroup';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const PersonalPageStatus: FC = () => {
  const { resourcePageEnabled, role, slug } = useContext(CurrentUserContext);

  const { host } = useContext(AccountContext);

  const { allowsShareableUrls } = useContext(PlanContext);

  if (resourcePageEnabled) {
    return (
      <InputGroup
        disabled={true}
        append={
          <span className="input-group-text">
            <CopyToClipboard text={host.concat(`/${slug}`)} />
          </span>
        }
        name=""
        value={host.concat(`/${slug}`)}
      />
    );
  }

  if (allowsShareableUrls) {
    return <p className="col-form-label">When your personal page is enabled, you can find its URL here.</p>;
  }

  return (
    <>
      <p className="col-form-label">Personal pages are only available to TimeZest Professional subscribers.</p>
      {role === 'administrator' && (
        <p className="mb-0">
          <a href="/settings/account" className="btn btn-danger">
            Upgrade Now &nbsp; <FontAwesomeIcon icon="angle-right" />
          </a>
        </p>
      )}
    </>
  );
};

export default PersonalPageStatus;

import type { FC } from 'react';

import styled from 'styled-components';

import { useAppSelector } from './hooks';
import type { Index } from './Reducers/templateReducer';

import { capitalize } from '@shared/text';

const GENERAL_STYLE = {
  border: 'none',
  backgroundColor: '#87cefa',
  position: 'relative',
  zIndex: 998,
};

const HORIZONTAL_PLACEHOLDER_STYLE = {
  height: '3px',
  width: '100%',
  ...GENERAL_STYLE,
};
const VERTICAL_PLACEHOLDER_STYLE = {
  height: '105px',
  width: '3px',
  ...GENERAL_STYLE,
};
const PLACEHOLDER_LINE_STYLES = {
  'mjml-body': HORIZONTAL_PLACEHOLDER_STYLE,
  'mjml-column': HORIZONTAL_PLACEHOLDER_STYLE,
  'mjml-section': VERTICAL_PLACEHOLDER_STYLE,
};

const PlaceholderContainer = styled('div')<{ $vertical: boolean }>`
  max-height: 0px;
  max-width: ${props => (props.$vertical ? '0px' : 'auto')};
`;

const PlaceholderBody = styled('div')<{ $vertical: boolean }>`
  position: absolute;
  width: max-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${props => props.$vertical && 'rotate(-90deg)'};
  padding: 1px 10px;
  margin: 1px 0;
  border-radius: 8px;
  background-color: #87cefa;
  font-weight: bold;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
`;

interface Props {
  parentElementType: string;
}

const Placeholder: FC<Props> = ({ parentElementType }) => {
  const index = useAppSelector(state => state.template.index);
  const selectedElementId = useAppSelector(state => state.ui.selectedElementId);

  const [elementType, isColumn] = getDraggingElementType(index, selectedElementId || '');

  return selectedElementId ? (
    <PlaceholderContainer $vertical={isColumn}>
      <div style={PLACEHOLDER_LINE_STYLES[parentElementType]}>
        <PlaceholderBody $vertical={isColumn}>Insert {elementType} here</PlaceholderBody>
      </div>
    </PlaceholderContainer>
  ) : (
    <div />
  );
};

export default Placeholder;

const getDraggingElementType = (index: Index, selectedElementId: string): [string, boolean] => {
  if (!selectedElementId.match(/^new\-/) && !index[selectedElementId]) return ['', false];

  const name = selectedElementId.match(/^new\-/) ? selectedElementId : index[selectedElementId].type;
  const type = name.split('-')[1];

  return [capitalize(type), type === 'column'];
};

import type { FC } from 'react';
import { useContext, useState, Fragment } from 'react';

import { DirtyContext } from '@/DirtyContext';
import Expander from '@/ui/Expander';

import Summary from './Summary';

import type { Unit } from '@ui/DurationRow';
import DurationRow from '@ui/DurationRow';
import EmailTemplateSelect from '@ui/EmailTemplateSelect';
import type Errors from '@models/Errors';
import type ReminderModel from '@models/Reminder';

export function remindersHasErrors(errors: Errors[]): boolean {
  return errors.some(e => Object.keys(e).length > 0);
}

interface Props {
  url: string;
  reminders: ReminderModel[];
  remindersErrors: Errors[];
  expanded: boolean;
  canExpand: boolean;
  onExpand: (expanded: boolean) => void;
  dirty: boolean;
}

const NUMBER_TO_ORDINAL = { 1: 'first', 2: 'second', 3: 'third' };

const REMINDER_UNITS: Unit[] = ['minutes', 'hours', 'days'];

const RemindersConfig: FC<Props> = ({
  url,
  reminders: initialReminders,
  remindersErrors,
  expanded,
  canExpand,
  dirty,
  onExpand,
}) => {
  const { handleDirty } = useContext(DirtyContext);
  const [reminders, setReminders] = useState<ReminderModel[]>(initialReminders);

  const addReminder = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (handleDirty) handleDirty();

    setReminders([...reminders, { emailTemplateId: 0, remindAfterMins: 0 }]);
  };

  const removeReminder =
    (index: number) =>
    (e: React.MouseEvent): void => {
      e.preventDefault();
      if (handleDirty) handleDirty();

      const remindersWithoutReminder = reminders.slice(0);
      remindersWithoutReminder.splice(index, 1);
      setReminders(remindersWithoutReminder);
    };

  return (
    <Expander
      title="Reminders"
      summary={<Summary reminders={reminders} />}
      url={url}
      method="PATCH"
      icon="alarm"
      hasErrors={remindersHasErrors(remindersErrors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      {reminders.length === 0 ? (
        <p className="mb-4">
          No reminders have been configured.
          <input type="hidden" name="appointment_type[reminders][]" />
        </p>
      ) : (
        reminders.map((reminder, i) => (
          <Fragment key={i}>
            {i > 0 && <hr className="mb-4" />}

            <EmailTemplateSelect
              name="appointment_type[reminders][][email_template_id]"
              value={reminder.emailTemplateId}
              templateType="reminder"
              label="Reminder Template"
              helpText={`Template to use for a ${NUMBER_TO_ORDINAL[i + 1]} reminder email.`}
            />
            <DurationRow
              name="appointment_type[reminders][][remind_after_mins]"
              value={reminder.remindAfterMins}
              units={REMINDER_UNITS}
              label="Send after time"
              helpText={`If the client hasn't selected a time after this period,
              TimeZest send a ${NUMBER_TO_ORDINAL[i + 1]} reminder.`}
              error={remindersErrors[i]?.remindAfterMins}
            />
            <div className="row mb-4">
              <div className="col-sm-3">
                <button className="btn btn-outline-danger" onClick={removeReminder(i)}>
                  Remove reminder
                </button>
              </div>
            </div>
          </Fragment>
        ))
      )}
      {reminders.length < 2 && (
        <>
          <hr className="mb-4" />
          <div className="row mb-4">
            <div className="col-sm-3">
              <button className="btn btn-outline-primary" onClick={addReminder}>
                Add a reminder
              </button>
            </div>
          </div>
        </>
      )}
    </Expander>
  );
};

export default RemindersConfig;

import type { FC } from 'react';

import Badge from '@/ui/Badge';

import type User from '@models/User';

interface Props {
  users: (User & { value: string })[] | User[];
}

const UsersList: FC<Props> = ({ users }) => {
  return (
    <>
      {users.map(u => (
        <div key={u.id} className="card mb-3">
          <div className="card-body d-flex py-2">
            <div className="flex-grow-1">
              <div className="fw-bold">
                {u.name} &nbsp;
                {u.schedulable ? <Badge color="green">Active</Badge> : <Badge color="grey">Inactive</Badge>}
              </div>
              <div className="small">{u.value ? `Value: ${u.value}` : 'No value set'}</div>
            </div>
            {u.schedulable && (
              <div className="small text-right">
                <a href={`/settings/users/${u.id}`}>Configure</a>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default UsersList;

import type { FC } from 'react';
import { useContext } from 'react';

import Expander from '@/ui/Expander';
import PlanContext from '@/PlanContext';
import AccountContext from '@/AccountContext';

import Summary from './Summary';

import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import RadioButtonRow from '@ui/RadioButtonRow';
import { hasIntegration } from '@shared/utilities';

export function onlineMeetingsHasErrors(errors: Errors): boolean {
  return !!errors.videoCallingMode;
}

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  expanded: boolean;
  canExpand: boolean;
  onExpand: (expanded: boolean) => void;
  dirty: boolean;
}

const OnlineMeetingsConfig: FC<Props> = ({ appointmentType, errors, expanded, canExpand, dirty, onExpand }) => {
  const { allowsOnlineMeetings } = useContext(PlanContext);
  const { integrations } = useContext(AccountContext);

  const mode = appointmentType.videoCallingModes[0]?.type || 'none';
  const teamsInstalled = hasIntegration(integrations, 'teams_meetings');
  const zoomInstalled = hasIntegration(integrations, 'zoom');
  const url = allowsOnlineMeetings ? `/settings/appointment_types/${appointmentType.id}/classic/online_meeting` : null;

  return (
    <Expander
      title="Online Meetings"
      summary={<Summary appointmentType={appointmentType} allowsOnlineMeetings={allowsOnlineMeetings} />}
      url={url}
      method="PATCH"
      icon="video-cam"
      hasErrors={onlineMeetingsHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      {allowsOnlineMeetings ? (
        <RadioButtonRow
          name="appointment_type[video_calling_mode]"
          value={mode}
          label="Online Meeting"
          helpText="TimeZest can automatically schedule a online meeting for the agreed time if you have installed the relevant integration."
          options={[
            { label: "Don't schedule an online meeting.", value: 'none' },
            {
              label: 'Schedule a Zoom meeting with this appointment.',
              value: 'zoom_video',
              disabled: !zoomInstalled,
            },
            {
              label: 'Schedule a Microsoft Teams online meeting with this appointment',
              value: 'teams_video',
              disabled: !teamsInstalled,
            },
          ]}
        />
      ) : (
        <>
          <p className="text-danger">
            Online Meetings are only available to subscribers to the TimeZest Professional plan.
          </p>
          <p>
            <a href="/settings/account">Upgrade Now</a>
          </p>
        </>
      )}
    </Expander>
  );
};

export default OnlineMeetingsConfig;

import type { FC } from 'react';

import FilterControl from '@shared/ui/Filter';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import usePersistedFilters from '@shared/hooks/usePersistedFilters';
import type { Filter } from '@models/Filter';

const ATTRIBUTES = [
  { name: 'Internal Name', value: 'appointment_type.internal_name', type: 'string' as const },
  {
    name: 'External Name ',
    value: 'appointment_type.external_name',
    type: 'string' as const,
  },
  { name: 'URL Slug', value: 'appointment_type.url_slug', type: 'string' as const },
  {
    name: 'Duration ',
    value: 'appointment_type.duration_mins',
    type: 'duration' as const,
    specialControlType: 'duration' as const,
  },
];

const POSSIBLE_VALUES = {
  'appointment_type.internal_name': { values: [], loading: false },
  'appointment_type.external_name': { values: [], loading: false },
  'appointment_type.url_slug': { values: [], loading: false },
  'appointment_type.duration_mins': {
    values: [],
    loading: false,
  },
};

interface Props {
  tqlFilters: Filter[];
  onUpdateTqlString: (tqlString: string) => void;
}

const AppointmentTypesFilter: FC<Props> = ({ tqlFilters, onUpdateTqlString }) => {
  const persistedFilters = usePersistedFilters(tqlFilters, POSSIBLE_VALUES);

  const [filters, setFilters] = useStateWithCallback<Filter[]>(persistedFilters);

  return (
    <FilterControl
      attributes={ATTRIBUTES}
      filters={filters}
      className="my-4"
      possibleValues={POSSIBLE_VALUES}
      onSetFilter={setFilters}
      onUpdateTqlString={onUpdateTqlString}
    />
  );
};

export default AppointmentTypesFilter;

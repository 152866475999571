import type { FC } from 'react';
import { useEffect } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetActivityOptionsForConnectWiseQuery } from '@graphql/generated';

const ACTIVITY_TYPE_HELP_TEXT = 'TimeZest will create the new Activity with this Activity Type';
const ACTIVITY_STATUS_HELP_TEXT = 'TimeZest will create the new Activity with this Activity Status';

interface Props {
  statusError: string | null;
  statusFieldName: string;
  typeError: string | null;
  typeFieldName: string;
  selectedStatusId: number;
  selectedTypeId: number;
  readOnly?: boolean;
  disabled?: boolean;
  templateMode?: boolean;
  onLoaded?: () => void;
}

const ActivityOptions: FC<Props> = ({
  readOnly,
  disabled,
  typeError,
  typeFieldName,
  statusError,
  statusFieldName,
  selectedStatusId,
  selectedTypeId,
  templateMode,
  onLoaded,
}) => {
  const queryResult = useGetActivityOptionsForConnectWiseQuery({ skip: templateMode });
  const loaded = !queryResult.loading && queryResult.data?.connectWise.id;

  useEffect(() => {
    if (loaded && onLoaded) onLoaded();
  }, [loaded, onLoaded]);

  return (
    <>
      <SelectRowFromGraphQL
        label="Activity Type"
        loadingMessage="Loading from ConnectWise PSA..."
        field="connectWise.activityTypes"
        name={typeFieldName}
        disabled={disabled}
        readOnly={readOnly}
        templateMode={templateMode}
        error={typeError || undefined}
        value={(selectedTypeId || '').toString()}
        helpText={ACTIVITY_TYPE_HELP_TEXT}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        label="Activity Status"
        loadingMessage="Loading from ConnectWise PSA..."
        field="connectWise.activityStatuses"
        name={statusFieldName}
        disabled={disabled}
        readOnly={readOnly}
        templateMode={templateMode}
        error={statusError || undefined}
        value={(selectedStatusId || '').toString()}
        helpText={ACTIVITY_STATUS_HELP_TEXT}
        queryResult={queryResult}
      />
    </>
  );
};

export default ActivityOptions;

import type { FC } from 'react';

import Flash from '@/Flash';
import Header from '@/Header';
import asScreen from '@/Screen';

import CustomAttributeForm from '../CustomAttributeForm';

import type { CustomAttribute } from '@models/CustomAttribute';

interface Props {
  customAttribute: CustomAttribute;
  errors: Record<string, string>;
}

const NewCustomAttribute: FC<Props> = ({ customAttribute, errors }) => {
  return (
    <>
      <Header title="New Custom Attribute" />
      <Flash />
      <CustomAttributeForm
        customAttribute={customAttribute}
        errors={errors}
        url="/settings/custom_attributes"
        method="post"
      />
    </>
  );
};

export default asScreen(NewCustomAttribute);

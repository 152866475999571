import type { FC } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import PlainTextRow from '@ui/PlainTextRow';
import { useGetServiceCallStatusesForAutotaskQuery } from '@graphql/generated';

const HELP_TEXT = 'TimeZest will create the new service call with this status.';

interface Props {
  selectedStatusId: string;
  fieldName: string;
  disabled?: boolean;
  readOnly?: boolean;
  error: string | null;
  templateMode?: boolean;
  onChange?: (value: string | undefined, name: string | undefined) => void;
}

const ServiceCallOptions: FC<Props> = ({
  selectedStatusId,
  fieldName,
  disabled,
  readOnly,
  error,
  templateMode,
  onChange,
}) => {
  if (templateMode)
    return (
      <PlainTextRow label="Status" helpText={HELP_TEXT}>
        To be selected when using this workflow template.
      </PlainTextRow>
    );

  const queryResult = useGetServiceCallStatusesForAutotaskQuery({ skip: templateMode });

  return (
    <SelectRowFromGraphQL
      label="Status"
      field="autotask.serviceCallStatuses"
      loadingMessage="Loading From Autotask..."
      error={error || undefined}
      value={selectedStatusId}
      disabled={disabled}
      readOnly={readOnly}
      templateMode={templateMode}
      name={fieldName}
      helpText={HELP_TEXT}
      queryResult={queryResult}
      onChange={onChange}
    />
  );
};

export default ServiceCallOptions;

import type { FC, PropsWithChildren } from 'react';

import Modal from '../Modal';

import './styles.scss';

interface Props {
  title: string;
  description: string;
  cancelLabel?: string;
  confirmLabel?: string;
  ariaLabel: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onClose: () => void;
}

const ConfirmationModal: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  cancelLabel,
  confirmLabel,
  description,
  ariaLabel,
  isOpen,
  onClose,
  onConfirm,
}) => (
  <Modal ariaLabel={ariaLabel} className="ui-confirmation-modal" isOpen={isOpen} onClose={onClose}>
    <h5 className="title">{title}</h5>

    <p>{description}</p>

    {children}

    <footer>
      <button className="btn-text" type="button" onClick={onClose}>
        {cancelLabel || 'Cancel'}
      </button>

      <button className="btn btn-sm btn-primary" onClick={onConfirm}>
        {confirmLabel || 'Confirm'}
      </button>
    </footer>
  </Modal>
);

export default ConfirmationModal;

import type { FC } from 'react';

import Badge from '@ui/Badge';

interface Props {
  loading: boolean;
  schedulable: boolean;
}

const Status: FC<Props> = ({ loading, schedulable }) => {
  if (loading) {
    return <Badge color="grey">{schedulable ? 'Deactivating...' : 'Activating...'}</Badge>;
  }

  if (schedulable) {
    return <Badge color="green">Active</Badge>;
  }

  return null;
};

export default Status;

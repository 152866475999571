{
  "units": {
    "hour_one": "1 hora",
    "minute_one": "1 minuto",
    "minute_other": "{{count}} minutos",
    "hour_other": "{{count}} horas"
  },
  "scheduling_ui": {
    "appointment_details": {
      "appointment": "Cita",
      "with": "Con",
      "date": "Fecha",
      "ticket": "Ticket",
      "time": "Hora",
      "duration": "Duraci\u00f3n"
    },
    "appointment_selector": {
      "instruction": "Seleccione una fecha y hora para su {{appointment_name}} con {{appointment_resources}}.",
      "instruction_reschedule": "Seleccione una nueva fecha y hora para su {{appointment_name}} con {{appointment_resources}}."
    },
    "date_selector": {
      "loading": "Buscando fechas con citas disponibles..."
    },
    "select_prompt": {
      "prompt": "Seleccione una fecha para ver las horas disponibles."
    },
    "timezone_selector": {
      "indicator": "Todas las horas est\u00e1n en {{timezone}}.",
      "change": "Modificar"
    },
    "time_selector": {
      "no_available_time": {
        "notification": "No hay horas disponibles en esta fecha.",
        "instruction": "Por favor, seleccione otro d\u00eda."
      },
      "available_time": "Las horas que se muestran a continuaci\u00f3n est\u00e1n en la zona horaria <strong>{{timezone}}</strong>. Si se encuentra en otra zona horaria, puede modificarla a continuaci\u00f3n."
    }
  }
}

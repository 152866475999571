import type { FC } from 'react';

interface Props {
  notificationTemplateName: string;
  confirmationTemplateName: string;
  cancellationTemplateName: string;
}

const Summary: FC<Props> = ({ notificationTemplateName, confirmationTemplateName, cancellationTemplateName }) => {
  return (
    <>
      Notification with &lsquo;{notificationTemplateName}&rsquo; template; Confirmation with &lsquo;
      {confirmationTemplateName}&rsquo; template; Cancellation with &lsquo;{cancellationTemplateName}&rsquo; template.
    </>
  );
};

export default Summary;

import includes from 'lodash/includes';

import type {
  BodyProperties,
  ButtonProperties,
  ColumnProperties,
  ImageProperties,
  LogoProperties,
  NodeType,
  SectionProperties,
  SpacerProperties,
  TextProperties,
} from '@models/EmailTemplate';

type AbstractIndexedNode<T extends NodeType, P = {}> = {
  id: string;
  parentId: string | null;
  type: T;
  childIds: string[];
} & Partial<P>;

// An IndexedMjmlNode is a normalised node which we store in the index, and which only contains data about
// itself and references to its child nodes
export type IndexedMjmlNode =
  | IndexedMjml
  | IndexedMjmlBody
  | IndexedMjmlButton
  | IndexedMjmlHead
  | IndexedMjmlSection
  | IndexedMjmlColumn
  | IndexedMjmlText
  | IndexedMjmlLogo
  | IndexedMjmlSpacer
  | IndexedMjmlImage;

// Root Mjml document node
type IndexedMjml = AbstractIndexedNode<'mjml'>;

export type IndexedMjmlBody = AbstractIndexedNode<'mjml-body', BodyProperties>;

export type IndexedMjmlButton = {
  id: string;
  parentId: string | null;
  type: 'mjml-button';
  childIds: string[];
} & Partial<ButtonProperties>;

// Head
export type IndexedMjmlHead = AbstractIndexedNode<'mjml-head'>;

export type IndexedMjmlSection = AbstractIndexedNode<'mjml-section', SectionProperties>;

export type IndexedMjmlColumn = AbstractIndexedNode<'mjml-column', ColumnProperties>;

export type IndexedMjmlText = {
  id: string;
  parentId: string | null;
  type: 'mjml-text';
  childIds: string[];
} & Partial<TextProperties>;

export type IndexedMjmlLogo = AbstractIndexedNode<'mjml-logo', LogoProperties>;

export type IndexedMjmlSpacer = AbstractIndexedNode<'mjml-spacer', SpacerProperties>;

export type IndexedMjmlImage = AbstractIndexedNode<'mjml-image', ImageProperties>;

const ACCEPTS = {
  mjml: ['mjml-body', 'mjml-head'],
  'mjml-head': [],
  'mjml-body': ['mjml-section'],
  'mjml-section': ['mjml-column'],
  'mjml-column': ['mjml-button', 'mjml-text', 'mjml-logo', 'mjml-spacer', 'mjml-image'],
};

export function accepts(parentType: string, childType: string): boolean {
  return includes(ACCEPTS[parentType], childType);
}

import type { FC } from 'react';
import { forwardRef, useCallback, useState } from 'react';

import type { WorkflowActionProps } from '@/ui/Workflow/types';

import BoardAndStatusRows from './BoardAndStatusRows';

import ErrorBoundary from '@shared/ErrorBoundary';
import Action from '@shared/Action';
import type { ConnectWiseMoveTicketToBoardAndStatusAction } from '@graphql/generated';
import useFormSubmit from '@shared/hooks/useFormSubmit';

import FormError from '../../FormError';
import { useMoveTicketToBoardAndStatusForConnectWise } from '../../hooks';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will move a ConnectWise PSA ticket associated with the scheduling request
      to the configured board and status.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no ConnectWise PSA ticket associated with this scheduling request.</li>
      <li>When the configured status cannot be found in ConnectWise PSA, or is marked as &apos;inactive&apos;.</li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
  </>
);

const Summary: FC<{ selectedBoardName: string; selectedStatusName: string }> = ({
  selectedBoardName,
  selectedStatusName,
}) => {
  return (
    <>
      Move the <strong>service ticket</strong> to the <strong>{selectedBoardName}</strong> board with the{' '}
      <strong>{selectedStatusName}</strong> status.
    </>
  );
};

const MoveTicketToBoardAndStatus = forwardRef<
  HTMLFormElement,
  WorkflowActionProps<ConnectWiseMoveTicketToBoardAndStatusAction>
>(({ action, readOnly, saveable }, ref) => {
  const [formEnabled, setFormEnabled] = useState(false);
  const { errors: mutationErrors, loading, succeeded, submit } = useMoveTicketToBoardAndStatusForConnectWise();
  const handleOptionsLoaded = useCallback(() => {
    setFormEnabled(true);
  }, [setFormEnabled]);

  const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
    return {
      boardName: (data.get('psa_board_name') as string) || null,
      boardId: (data.get('psa_board_id') as string) || null,
      statusId: (data.get('psa_status_id') as string) || null,
      statusName: (data.get('psa_status_name') as string) || null,
    };
  });

  const errors = action.errors || mutationErrors;

  return (
    <ErrorBoundary>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Action
          action={action}
          details={DETAILS}
          icon="collapse-right"
          summary={
            <Summary selectedBoardName={action.psaBoardName || ''} selectedStatusName={action.psaStatusName || ''} />
          }
          readOnly={readOnly || !formEnabled}
          saveable={saveable}
          saving={loading}
          succeeded={succeeded}
        >
          <FormError action={action} errors={errors} />

          <BoardAndStatusRows
            boardId={action.psaBoardId}
            boardError={errors.psa_board_id}
            boardName={action.psaBoardName}
            statusId={action.psaStatusId}
            statusError={errors.psa_status_id}
            statusName={action.psaStatusName}
            readOnly={readOnly}
            onLoaded={handleOptionsLoaded}
          />
        </Action>
      </form>
    </ErrorBoundary>
  );
});

export default MoveTicketToBoardAndStatus;

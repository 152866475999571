import type { FC } from 'react';
import { useContext, useMemo, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';

import { timezonesForSelect } from './utilities';
import RadioButtonRow from './ui/RadioButtonRow';
import AccountContext from './AccountContext';

import type { RailsTimezone } from '@models/Timezone';
import Select from '@shared/ui/Select';

interface Props {
  allZones: RailsTimezone[];
  timeZone: string | null;
  allowsPsaTimeZone: boolean;
  usesCustomTimeZone: boolean;
  psaName: string;
  psaTimeZone: string;
}

const UserTimeZone: FC<Props> = ({
  allZones,
  timeZone: initialTimeZone,
  allowsPsaTimeZone,
  usesCustomTimeZone: initialUsesCustomTimezone,
  psaName,
  psaTimeZone,
}) => {
  const { countryCode } = useContext(AccountContext);

  const [usesCustomTimeZone, setUsesCustomTimezone] = useState<boolean>(initialUsesCustomTimezone);
  const [timeZone, setTimeZone] = useState<string | null>(initialTimeZone);

  const timezones = useMemo(() => {
    return timezonesForSelect(countryCode, allZones);
  }, [countryCode, allZones]);

  const { handleDirty } = useContext(DirtyContext);

  const handleOnCustomTimeZoneChange = (value: string): void => {
    if (handleDirty) handleDirty();

    setUsesCustomTimezone(value === 'true');
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();

    if (handleDirty) handleDirty();

    setTimeZone(e.target.value);
  };

  return (
    <RadioButtonRow
      label="Time Zone"
      helpText="This setting determines how TimeZest interprets the start and end times in your calendar."
      name="availability[uses_custom_timezone]"
      value={usesCustomTimeZone.toString()}
      options={[
        ...(allowsPsaTimeZone
          ? [
              {
                label: (
                  <>
                    Use the default time zone configured for this user in {psaName}
                    {psaTimeZone && (
                      <>
                        &mdash; <strong>{psaTimeZone}</strong>
                      </>
                    )}
                    .
                  </>
                ),
                disabled: !allowsPsaTimeZone,
                value: 'false',
              },
            ]
          : []),
        {
          label: 'Use a custom time zone:',
          value: 'true',
          appendedElement: (
            <Select
              name="availability[custom_timezone]"
              disabled={!usesCustomTimeZone}
              value={timeZone || 'Eastern Time (US & Canada)'}
              className="w-100 mt-1"
              options={timezones}
              onChange={handleChange}
            />
          ),
        },
      ]}
      onChange={handleOnCustomTimeZoneChange}
    />
  );
};

export default UserTimeZone;

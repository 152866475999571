import type { FC } from 'react';

import { ErrorMessage } from '@ui/ErrorMessage';
import type { PsaType } from '@models/Integration';

interface Props {
  psaType: PsaType;
}

const Alert: FC<Props> = ({ psaType }) => {
  if (psaType === 'autotask') {
    return (
      <ErrorMessage>
        <p>
          TimeZest was unable to retrieve the list of companies from Autotask, as the TimeZest API user does not have
          the necessary permissions.
        </p>
        <p className="mb-0">
          Ensure that the TimeZest security role has permission to access <strong>Your Organization</strong> assigned
          and full permission assigned to <strong>CRM</strong> area in the <strong>Admin</strong> section.
        </p>
      </ErrorMessage>
    );
  }

  if (psaType === 'connect_wise') {
    return (
      <ErrorMessage>
        <p>
          TimeZest was unable to retrieve the list of companies from ConnectWise PSA, as the TimeZest API user does not
          have the necessary permissions.
        </p>
        <p className="mb-0">
          Please ensure that the TimeZest API user has the <strong>Inquire</strong> permission for{' '}
          <strong>Company Maintenance</strong> in the <strong>Companies</strong> section.
        </p>
      </ErrorMessage>
    );
  }

  return null;
};

export default Alert;

import type { FC } from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import first from 'lodash/fp/first';

import Select from '../Select';

import FilterHeader from './FilterHeader';
import type { Attribute, FilterItem, Operator } from './types';
import ValueControl from './ValueControl';

import useGetSingleCompanyName from '@shared/hooks/useGetSingleCompanyName';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { Filter } from '@models/Filter';

import { POSSIBLE_OPERATORS, VALUE_FORMATTER } from '.';

interface Props {
  filter: Filter;
  index: number;
  attributes: Attribute[];
  possibleValues: Record<string, { values: FilterItem[] }>;
  onApplyFilter: (filter: Filter) => void;
  onDeleteFilter: (e: React.MouseEvent) => void;
}

const FilterBlock: FC<Props> = ({ attributes, filter, index, possibleValues, onApplyFilter, onDeleteFilter }) => {
  const [open, setOpen] = useState(!filter.persisted);
  const [selectedAttributeId, setSelectedAttributeId] = useStateFromProp(filter.attribute);
  const [selectedOperatorId, setSelectedOperatorId] = useStateFromProp(filter.operator);
  const [value, setValue] = useStateFromProp(filter.value);
  const [valueName, setValueName] = useStateFromProp(filter.valueName);
  const [changed, setChanged] = useState(filter.persisted ? true : false);
  const [error, setError] = useState('');

  const attribute = attributes.find(a => a.value === filter.attribute);
  const operatorName = POSSIBLE_OPERATORS[attribute?.type || 'string'].find(o => o.value === filter.operator)?.name;

  const [companyName, companyLoading] = useGetSingleCompanyName(
    filter.attribute,
    Number(filter.value),
    filter.valueName
  );

  const unapplied = !open && !changed;
  const applied = !open && changed;

  const applyFilter = () => {
    const filterToBeApplied: Filter = {
      ...filter,
      attribute: selectedAttributeId,
      operator: selectedOperatorId,
      value: value,
      valueName: valueName,
    };

    if (value) {
      setOpen(false);
      setChanged(true);
      setError('');
      onApplyFilter(filterToBeApplied);
    } else {
      setOpen(true);
      setChanged(false);
      setError('Please provide a filter value.');
    }
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilter();
    }
  };

  const handleToggleOpenFilterBody = () => {
    setOpen(!open);
  };

  const handleAttributeChange = (newValue: string) => {
    const valueType = attributes.find(a => a.value === newValue)?.type;

    const firstValue = first(possibleValues[newValue].values);

    if (valueType !== undefined) {
      const operator = valueType === 'string' ? 'LIKE' : POSSIBLE_OPERATORS[valueType][0].value;

      setSelectedAttributeId(newValue);
      setSelectedOperatorId(operator);
      setValue(firstValue?.value || '');
      setValueName(firstValue?.name || '');
      setChanged(false);
    }
  };

  const handleOperatorChange = (newValue: Operator) => {
    setSelectedOperatorId(newValue);
    setChanged(false);
  };

  const handleValueChange = (newValue: string, newName?: string) => {
    setValue(newValue);
    setValueName(newName || newValue);

    setChanged(false);
  };

  return (
    <div className="d-flex h-100 gap-2">
      {index > 0 && <p className="m-0 mt-2">AND</p>}

      <div className="position-relative" style={{ minWidth: '260px' }}>
        <button
          type="button"
          className={classNames(
            'w-100 d-flex align-items-start border-0 p-2',
            open ? 'rounded-top' : 'rounded',
            companyLoading && 'pe-none bg-dark-subtle',
            unapplied ? 'filter-unapplied-block-header' : 'filter-block-header'
          )}
          onClick={handleToggleOpenFilterBody}
        >
          {!companyLoading && (
            <FontAwesomeIcon
              className="filter-block-delete-button me-1 rounded p-1"
              icon="xmark"
              height={10}
              onClick={onDeleteFilter}
            />
          )}

          <FilterHeader
            attributeName={attribute?.name || ''}
            initialLoading={companyLoading}
            operatorName={operatorName || ''}
            unapplied={unapplied}
            applied={applied}
            valueName={companyName || filter.valueName}
            valueFormatter={
              VALUE_FORMATTER[attributes.find(a => a.value === filter.attribute)?.type || ''] || undefined
            }
          />
        </button>

        {open && (
          <div className="text-secondary position-absolute z-1 w-100 d-grid rounded-bottom top-100 start-0 gap-2 bg-white p-2 shadow-sm">
            <Select
              name={`scheduling_request_attribute_${index}`}
              value={selectedAttributeId}
              options={attributes}
              onChange={e => handleAttributeChange(e.target.value)}
            />
            <Select
              name={`scheduling_request_operator_${index}`}
              value={selectedOperatorId}
              options={POSSIBLE_OPERATORS[attributes.find(a => a.value === selectedAttributeId)?.type || 'string']}
              onChange={e => handleOperatorChange(e.target.value as Operator)}
            />

            <ValueControl
              attribute={attributes.find(a => a.value === selectedAttributeId)}
              value={value}
              valueName={companyName || valueName}
              index={index}
              possibleValues={possibleValues}
              onKeyDown={handleKeydown}
              onValueChange={handleValueChange}
            />

            {error && <div className="invalid-feedback d-block mt-0">{error}</div>}
            <button type="button" className="btn btn-primary btn-sm w-25" onClick={applyFilter}>
              Apply
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBlock;

import type { FC } from 'react';
import { useState } from 'react';

import RadioButtonRow from './ui/RadioButtonRow';

import Select from '@shared/ui/Select';
import useDisplayDuration from '@shared/hooks/useDisplayDuration';

interface Props {
  appointmentTypes: { name: string; id: string; durationMins: number }[];
  copyFromExistingId: string;
  workflowTemplateId: string;
  workflowTemplates: { name: string; id: string; internal: boolean }[];
}

const CopyAppointmentType: FC<Props> = ({
  appointmentTypes,
  copyFromExistingId: initialCopyFromExistingId,
  workflowTemplateId: initialWorkflowTemplateId,
  workflowTemplates,
}) => {
  const displayDuration = useDisplayDuration();
  const [source, setSource] = useState(selectedSource(!!initialCopyFromExistingId, initialWorkflowTemplateId));
  const [copyFromExistingId, setCopyFromExistingId] = useState(initialCopyFromExistingId);
  const [workflowTemplateId, setWorkflowTemplateId] = useState(initialWorkflowTemplateId);

  const handleCopyFromExistingIdChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setCopyFromExistingId(event.target.value);
  };

  const handleWorkflowTemplateIdChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setWorkflowTemplateId(event.target.value);
  };

  return (
    <RadioButtonRow
      label="Source"
      helpText=" "
      name="appointment_type[source]"
      options={[
        { label: 'A new appointment type with default values.', value: 'none' },
        {
          label: 'Workflow template',
          value: 'workflow_template',
          appendedElement: (
            <div className="mt-2">
              <Select
                name="appointment_type[workflow_template_id]"
                value={workflowTemplateId || ''}
                options={workflowTemplates.map(wt => ({
                  name: `${wt.name}`,
                  value: wt.id,
                }))}
                disabled={source !== 'workflow_template'}
                onChange={handleWorkflowTemplateIdChange}
              />
            </div>
          ),
        },
        {
          label: 'An existing appointment type:',
          value: 'appointment_type',
          appendedElement: (
            <div className="mt-2">
              <Select
                name="appointment_type[copy_from_existing_id]"
                value={copyFromExistingId || ''}
                options={appointmentTypes.map(at => ({
                  name: `${at.name} (${displayDuration(at.durationMins)})`,
                  value: at.id,
                }))}
                disabled={source !== 'appointment_type'}
                onChange={handleCopyFromExistingIdChange}
              />
            </div>
          ),
        },
      ]}
      value={source}
      onChange={setSource}
    />
  );
};

function selectedSource(copyFromExisting: boolean, workflowTemplateId: string) {
  if (copyFromExisting) return 'appointment_type';
  if (workflowTemplateId) return 'workflow_template';

  return 'none';
}

export default CopyAppointmentType;

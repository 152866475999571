import type { FC } from 'react';
import { useEffect } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetLocationsQuery } from '@graphql/generated';
import type { ShareableUrlAppointmentType } from '@models/AppointmentType';
import { capitalize, pluralize } from '@shared/text';

interface Props {
  selectedServiceLocationId: string | null;
  selectedScheduleStatusId: string | null;
  locationError?: string | null;
  locationFieldName: string;
  statusError?: string | null;
  statusFieldName: string;
  disabled?: boolean;
  readOnly?: boolean;
  appointmentType?: ShareableUrlAppointmentType;
  templateMode?: boolean;
  onLoaded?: () => void;
  onLocationChange?: (value: string | undefined, name: string | undefined) => void;
  onStatusChange?: (value: string | undefined, name: string | undefined) => void;
}

const LocationAndStatusRow: FC<Props> = ({
  appointmentType,
  disabled,
  readOnly,
  selectedServiceLocationId,
  selectedScheduleStatusId,
  locationError,
  locationFieldName,
  statusError,
  statusFieldName,
  templateMode,
  onLoaded,
  onLocationChange,
  onStatusChange,
}) => {
  const queryResult = useGetLocationsQuery({ skip: templateMode });
  const { loading, data, error } = queryResult;
  const loaded = !loading && data?.connectWise.id;

  useEffect(() => {
    if (loaded && onLoaded) onLoaded();
  }, [loaded, onLoaded]);

  return (
    <>
      <SelectRowFromGraphQL
        label="Service Location"
        name={locationFieldName}
        field="connectWise.serviceLocations"
        loadingMessage="Loading from ConnectWise PSA..."
        helpText={`${capitalize(
          pluralize(appointmentType || 'appointment', 2)
        )} created for this appointment type will have this set as their service location in ConnectWise PSA.`}
        prompt="Use the default specified in ConnectWise PSA"
        error={locationError || undefined}
        templateMode={templateMode}
        disabled={Boolean(error) || disabled || false}
        readOnly={Boolean(error) || readOnly || false}
        value={selectedServiceLocationId || ''}
        queryResult={queryResult}
        onChange={onLocationChange}
      />

      <SelectRowFromGraphQL
        label="Schedule Status"
        name={statusFieldName}
        field="connectWise.scheduleStatuses"
        loadingMessage="Loading from ConnectWise PSA..."
        helpText={`When TimeZest creates an ${
          appointmentType || 'appointment'
        } in ConnectWise PSA, this will determine the status of the created appointment.`}
        prompt="Use the default specified in ConnectWise PSA"
        error={statusError || undefined}
        templateMode={templateMode}
        disabled={Boolean(error) || disabled || false}
        readOnly={Boolean(error) || readOnly || false}
        value={selectedScheduleStatusId || ''}
        queryResult={queryResult}
        onChange={onStatusChange}
      />
    </>
  );
};

export default LocationAndStatusRow;

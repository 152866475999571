import type { FC } from 'react';

const CreditCardRequired: FC<{ nextEstimateTotal: number }> = ({ nextEstimateTotal }) => {
  return (
    <p>
      &mdash; You&apos;ll need to enter details for a credit card, which will be charged{' '}
      <strong>
        US{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(nextEstimateTotal)}
      </strong>{' '}
      immediately.
    </p>
  );
};

export default CreditCardRequired;

import type { FC } from 'react';

import YesNoConfirmation from '@/YesNoConfirmation';

import type { IntegrationSettings } from './type';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  authUrl: string;
  removeIntegrationUrl: string;
  integration: IntegrationSettings | null;
  integrationIsProcessing: boolean;
}

const IntegrationStatus: FC<Props> = ({ authUrl, removeIntegrationUrl, integration, integrationIsProcessing }) => {
  if (!integration) {
    return (
      <div className="d-flex">
        <div className="flex-grow-1 fw-bold pt-2">TimeZest has not yet been connected with your HubSpot account.</div>
        <a href={authUrl} className="btn btn-primary">
          <FontAwesomeIcon icon="sign-in-alt" /> &nbsp; Authenticate with HubSpot
        </a>
      </div>
    );
  }

  if (integrationIsProcessing) {
    return (
      <div className="text-success">
        <FontAwesomeIcon spin icon="circle-notch" />
        &nbsp;&nbsp;
        <span className="fw-bold">TimeZest is connecting your scheduleable users with their HubSpot accounts.</span>
        <div className="progress mt-2">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-success"
            role="progressbar"
            aria-valuenow={integration.progress || 0}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: `${integration.progress}%` }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <span className="text-success">
          <FontAwesomeIcon icon="circle-check" />
          &nbsp;&nbsp;
          <span className="fw-bold">TimeZest has been successfully connected to your HubSpot account.</span>
        </span>
      </div>
      <YesNoConfirmation className="d-flex" url={removeIntegrationUrl} text="Remove This Integration" />
    </div>
  );
};

export default IntegrationStatus;

import type { FC } from 'react';

interface Props {
  id: string;
  name: string;
  onSelect: (id: string, name: string) => void;
}

const Option: FC<Props> = ({ id, name, onSelect }) => {
  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    onSelect(id, name);
  };

  return (
    <button className="dropdown-item" onClick={handleClick}>
      {name}
    </button>
  );
};

export default Option;

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ACTUAL_ENV,
  integrations: [Sentry.replayIntegration(), Sentry.replayCanvasIntegration()],
  release: process.env.HEROKU_BUILD_COMMIT,

  // Capture errors from scripts hosted on TimeZest domains.
  allowUrls: [/timezest\.com/, /timezest-demo\.com/],

  // Capture 50% of transactions for performance monitoring.
  tracesSampleRate: 0.5,

  // Capture Replay for 5% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
});

export function captureException(error: Error): void {
  Sentry.captureException(error);
}

import type { FC } from 'react';
import { useContext } from 'react';

import ConfigContext from '../ConfigContext';

import NewToolCard from './NewToolCard';

import { useDraggable } from '@dnd-kit/core';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const Image: FC = () => {
  const { readOnly } = useContext(ConfigContext);

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-image',
    data: {
      type: 'mjml-image',
      defaultProperties: {
        url: '',
        href: '',
        altText: '',
        alignment: 'center',
        height: '',
        width: '',
        padding: { setAll: true, all: '0px', left: '0px', right: '0px', top: '0px', bottom: '0px' },
      },
    },
  });

  return (
    <NewToolCard ref={setNodeRef} {...listeners} {...attributes}>
      <p className="small text-muted m-0 mb-2">Image</p>
      <FontAwesomeIcon icon="image" size="3x" color="#585858" opacity={readOnly ? 0.5 : 1} />
    </NewToolCard>
  );
};

export default Image;

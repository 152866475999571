import { StrictMode } from 'react';

import BoardsList from './BoardsList';

const Boards = () => {
  return (
    <StrictMode>
      <BoardsList />
    </StrictMode>
  );
};

export default Boards;

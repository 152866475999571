import type { FC } from 'react';

interface Props {
  title: string;
  summary: string | JSX.Element;
}

const ExpanderSummary: FC<Props> = ({ title, summary }) => {
  return (
    <div className="flex-grow-1 w-50">
      <span className="text-dark">{title}</span>
      <div className="small text-muted">{summary}</div>
    </div>
  );
};

export default ExpanderSummary;

import { forwardRef } from 'react';

import classNames from 'classnames';

import type { InputProps } from '@models/InputControl';

const TextInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, ariaLabel, error, ...rest } = props;

  return (
    <>
      <input
        ref={ref}
        aria-label={ariaLabel}
        className={classNames('form-control', { 'is-invalid': !!error }, className)}
        {...rest}
      />
      {error && <p className="text-danger small mb-0 mt-1">{error}</p>}
    </>
  );
});

export default TextInput;

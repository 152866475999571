import type { FC } from 'react';
import { useState } from 'react';

import HistoricalUsageDetails from './HistoricalUsageDetails';

import type { HistoricalUsageData } from '@models/HistoricalUsage';

interface Props {
  data: HistoricalUsageData[];
}

const HistoricalUsage: FC<Props> = ({ data }) => {
  const [toggleOpenTable, setToggleOpenTable] = useState(false);

  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-center align-items-center">
        <div className="flex-grow-1">Historical Usage</div>

        <div className="w-15">
          <div className="form-check form-switch">
            <input
              id="historical_usage_table"
              name="historical_usage_table"
              className="form-check-input"
              type="checkbox"
              checked={toggleOpenTable}
              value={toggleOpenTable.toString()}
              onChange={() => setToggleOpenTable(!toggleOpenTable)}
            />
            <label className="form-check-label" htmlFor="historical_usage_table">
              Show table
            </label>
          </div>
        </div>
      </div>

      <div className="card-body">
        <HistoricalUsageDetails data={data} toggleOpenTable={toggleOpenTable} />
      </div>
    </div>
  );
};

export default HistoricalUsage;

import { type FC, useEffect } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import { refreshCurrentPage } from '@shared/utilities';

import IntegrationStatus from '../../IntegrationStatus';
import UsersList from '../../../UsersList';
import type { IntegrationSettings } from '../../type';

interface Props {
  authUrl: string;
  integration: IntegrationSettings | null;
  users: { connected: boolean; name: string }[];
  usersCount: number;
  usersPerPage: number;
}

const TeamsMeetings: FC<Props> = ({ authUrl, integration, users, usersCount, usersPerPage }) => {
  const integrationIsProcessing = integration ? integration.processing : false;

  useEffect(() => {
    if (integrationIsProcessing) {
      refreshCurrentPage(5000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header title="Microsoft Teams (Meetings) integration" />
      <Flash />
      <IntegrationStatus
        integration={integration}
        authUrl={authUrl}
        newIntegrationUrl="/settings/integrations/teams_meetings/new"
        removeIntegrationUrl="/settings/integrations/teams_meetings"
        integrationIsProcessing={integrationIsProcessing}
      />
      <hr />
      <p>
        TimeZest&apos;s integration with Microsoft Teams allows TimeZest to automatically create a Teams online meeting
        for an appointment, and include the relevant details in the emails and internal notes it creates for the
        appointment.
      </p>
      <p>
        Adding a Teams online meeting to an appointment is configured on a per-appointment-type basis — after connecting
        the integration, go to
        <a href="/settings/appointment_types"> Appointment Types </a>
        to configure it for your appointment types.
      </p>
      {integration && (
        <>
          <hr />
          <UsersList
            users={users}
            usersCount={usersCount}
            usersPerPage={usersPerPage}
            connectedMessage="This user is associated with a Teams user account."
            notConnectedMessage="This user is not associated with a Teams user account."
          />
        </>
      )}
    </>
  );
};

export default asScreen(TeamsMeetings);

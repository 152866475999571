import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';

import Row from './Row';

import Badge from '@ui/Badge';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type { Member } from '@models/TeamMember';
import { psaName } from '@shared/utilities';

interface Props {
  members: Member[];
}

const Membership: FC<Props> = ({ members }) => {
  const { psaType } = useContext(AccountContext);

  return (
    <Row
      label={`Users (${members.length})`}
      helpText={`To alter the members of this team, edit it in ${psaName(psaType)}.`}
    >
      {members.map(member => (
        <div key={member.id} className="member__card">
          <div className="card mb-2">
            <div className="card-body d-flex py-2">
              <div className="flex-grow-1">
                <div>
                  <span className="fw-bold">{member.name}</span> &nbsp;&nbsp;
                  {member.schedulable && <Badge color="green">Active</Badge>}
                </div>
                <div className="small mb-1">{member.email}</div>
                {!member.schedulable && (
                  <div className="flex-grow-1 text-danger small text-right">
                    <FontAwesomeIcon icon="exclamation-triangle" />
                    &nbsp;
                    <span>This team member is not activated in TimeZest, so cannot be scheduled.</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Row>
  );
};

export default Membership;

import type { FC, PropsWithChildren } from 'react';

interface Props {
  name: string;
}

const Section: FC<PropsWithChildren<Props>> = ({ children, name }) => {
  return (
    <>
      <tr>
        <td colSpan={5} className="fw-bold bg-transparent">
          {name}
        </td>
      </tr>
      {children}
    </>
  );
};

export default Section;

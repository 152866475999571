import type { FC } from 'react';

import Row from '@/ui/Row';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const UpgradePrompt: FC = () => {
  return (
    <div className="mt-3">
      <Row withInput={false} label="Email Templates">
        <div className="fw-bold text-danger mb-2">
          Custom Emails are only available to TimeZest Professional subscribers.
        </div>
        <p>
          Completely customize the emails that TimeZest sends your clients, with a powerful template system that allows
          you to completely control the look, feel and content of emails.
        </p>
        <p className="mb-0">
          <a className="btn btn-danger" href="/settings/account">
            Upgrade Now &nbsp;
            <FontAwesomeIcon icon="angle-right" />
          </a>
        </p>
      </Row>
    </div>
  );
};

export default UpgradePrompt;

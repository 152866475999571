import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When it executes this action, TimeZest will update the agent on a HaloPSA ticket associated with the scheduling
      request to the scheduled user.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When no scheduled users have been determined for the scheduling request (i.e. prior to the client selecting a
        time).
      </li>
      <li>When no HaloPSA ticket is associated with the scheduling request.</li>
      <li>When no HaloPSA integration is configured.</li>
    </ul>
    <p>
      When using panel scheduling, the first resource for the scheduling request will determine the scheduled user to
      whom the ticket will be assigned.
    </p>
  </>
);

const HaloPSAUpdateAgentForTicket: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="user-b"
      summary={
        <>
          Update the <strong>agent</strong> a HaloPSA ticket is assigned to.
        </>
      }
      readOnly={readOnly}
    >
      <p>Update the agent a HaloPSA ticket is assigned to.</p>
    </Action>
  </ErrorBoundary>
);

export default HaloPSAUpdateAgentForTicket;

import type { FC } from 'react';

import Form from '@/Form';
import PasswordInputRow from '@/ui/PasswordInputRow';
import Row from '@/ui/Row';

const PasswordSettings: FC<{ errors: Record<string, string> }> = ({ errors }) => {
  return (
    <Form url="/profile" method="patch">
      <PasswordInputRow
        name="user[current_password]"
        value=""
        label="Current Password"
        error={errors.currentPassword}
        width={6}
      />

      <PasswordInputRow
        name="user[password]"
        value=""
        label="New Password"
        helpText="At least 6 characters. We recommend using a longer password with numbers, symbols and upper- and lowercase letters."
        error={errors.password}
      />

      <PasswordInputRow
        name="user[password_confirmation]"
        value=""
        label="Confirm New Password"
        error={errors.passwordConfirmation}
        width={6}
      />

      <Row label="">
        <input
          className="btn btn-primary"
          type="submit"
          value="Change My Password"
          data-disable-with="Change My Password"
        />
      </Row>
    </Form>
  );
};

export default PasswordSettings;

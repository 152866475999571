import { type FC, useContext } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';
import AccountContext from '@/AccountContext';
import PlanContext from '@/PlanContext';

import IntegrationCard from './IntegrationCard';

import { hasIntegration } from '@shared/utilities';

interface Props {
  integrationUrl: string;
  upgradeUrl: string;
}

const Integrations: FC<Props> = ({ integrationUrl, upgradeUrl }) => {
  const { integrations, psaType } = useContext(AccountContext);
  const { allowsOnlineMeetings, allowsOffice365Calendars } = useContext(PlanContext);

  return (
    <>
      <Header title="Integrations" />
      <Flash />

      <div className="row pb-3">
        {psaType === 'autotask' && (
          <IntegrationCard
            description="Configure your TimeZest account to work with your Autotask instance."
            integrationName="Autotask"
            integrationUrl={`${integrationUrl}/autotask`}
            logoClass="autotask"
            connected={hasIntegration(integrations, 'autotask')}
            upgrade={false}
            upgradeUrl={upgradeUrl}
          />
        )}

        <IntegrationCard
          description="Configure your TimeZest account to work with your BambooHR instance."
          integrationName="BambooHR"
          integrationUrl={`${integrationUrl}/bamboo_hr`}
          logoClass="bamboo-hr"
          connected={hasIntegration(integrations, 'bamboo_hr')}
          upgrade={false}
          upgradeUrl={upgradeUrl}
        />

        {psaType === 'connect_wise' && (
          <IntegrationCard
            description="Configure your TimeZest account to work with your ConnectWise PSA instance."
            integrationName="ConnectWise PSA"
            integrationUrl={`${integrationUrl}/connect_wise`}
            logoClass="connectwise"
            connected={hasIntegration(integrations, 'connect_wise')}
            upgrade={false}
            upgradeUrl={upgradeUrl}
          />
        )}

        {psaType === 'halo_psa' && (
          <IntegrationCard
            description="Configure your TimeZest account to work with your Halo PSA instance."
            integrationName="Halo PSA"
            integrationUrl={`${integrationUrl}/halo_psa`}
            logoClass="halo-psa"
            connected={hasIntegration(integrations, 'halo_psa')}
            upgrade={false}
            upgradeUrl={upgradeUrl}
          />
        )}

        <IntegrationCard
          description="Create contacts and record meetings in HubSpot when end users schedule an appointment"
          integrationName="HubSpot"
          integrationUrl={`${integrationUrl}/hub_spot`}
          logoClass="hubspot"
          connected={hasIntegration(integrations, 'hub_spot')}
          upgrade={!allowsOnlineMeetings}
          upgradeUrl={upgradeUrl}
        />

        <IntegrationCard
          description="Create appointments in and read availability from Microsoft 365 calendars."
          integrationName="Microsoft 365 calendars"
          integrationUrl={`${integrationUrl}/office_365_calendar`}
          logoClass="office365"
          connected={hasIntegration(integrations, 'office_365')}
          upgrade={psaType !== 'office_365' && !allowsOffice365Calendars}
          upgradeUrl={upgradeUrl}
        />

        <IntegrationCard
          description="Automatically create Teams meetings when clients schedule an appointment."
          integrationName="Microsoft Teams"
          integrationUrl={`${integrationUrl}/teams_meetings`}
          logoClass="teams"
          connected={hasIntegration(integrations, 'teams_meetings')}
          upgrade={!allowsOnlineMeetings}
          upgradeUrl={upgradeUrl}
        />

        <IntegrationCard
          description="Automatically post messages to Teams channels and users when clients schedule an appointment."
          integrationName="Microsoft Teams"
          integrationUrl={`${integrationUrl}/teams_messaging`}
          logoClass="teams"
          connected={hasIntegration(integrations, 'teams_messaging')}
          upgrade={!allowsOnlineMeetings}
          upgradeUrl={upgradeUrl}
        />

        {psaType === 'service_now' && (
          <IntegrationCard
            description="Configure your TimeZest account to work with your ServiceNow instance."
            integrationName="ServiceNow"
            integrationUrl={`${integrationUrl}/service_now`}
            logoClass="service-now"
            connected={hasIntegration(integrations, 'service_now')}
            upgrade={false}
            upgradeUrl={upgradeUrl}
          />
        )}

        <IntegrationCard
          description="Automatically create Zoom meetings when clients schedule an appointment."
          integrationName="Zoom"
          integrationUrl={`${integrationUrl}/zoom`}
          logoClass="zoom"
          connected={hasIntegration(integrations, 'zoom')}
          upgrade={!allowsOnlineMeetings}
          upgradeUrl={upgradeUrl}
        />
      </div>
    </>
  );
};

export default asScreen(Integrations);

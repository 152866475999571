import type { FC } from 'react';

import chunk from 'lodash/chunk';

import { displayDuration } from '@shared/text';

type AppointmentType = { id: number; name: string; duration: number };

interface Props {
  appointmentTypes: AppointmentType[];
  selectedAppointmentTypeIds: number[];
  disabled?: boolean;
  onChange: (selelectedAppointmentTypeIds: number[]) => void;
}

const AppointmentTypes: FC<Props> = ({ appointmentTypes, disabled, selectedAppointmentTypeIds, onChange }) => {
  const selectedAppointmentTypes = appointmentTypes.filter(at => selectedAppointmentTypeIds.includes(at.id));

  return (
    <>
      <p>Select the Appointment Types which you wish to add this Quick Edit to:</p>
      <div className="row mb-3">
        {disabled
          ? split(selectedAppointmentTypes).map((appointmentTypes, i) => (
              <SelectedAppointmentTypes key={i} appointmentTypes={appointmentTypes} />
            ))
          : split(appointmentTypes).map((appointmentTypes, i) => (
              <SelectAppointmentTypes
                key={i}
                appointmentTypes={appointmentTypes}
                selectedAppointmentTypeIds={selectedAppointmentTypeIds}
                onChange={onChange}
              />
            ))}
      </div>
    </>
  );
};

function SelectAppointmentTypes({ appointmentTypes, selectedAppointmentTypeIds, onChange }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.currentTarget.value);

    if (e.currentTarget.checked) {
      onChange([...selectedAppointmentTypeIds, value]);
    } else {
      onChange(selectedAppointmentTypeIds.filter(at => at !== value));
    }
  };

  return (
    <div className="col-sm-6">
      {appointmentTypes.map(at => (
        <div key={at.id} className="form-check">
          <input
            id={at.id.toString()}
            className="form-check-input"
            type="checkbox"
            value={at.id}
            checked={selectedAppointmentTypeIds.includes(at.id)}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={at.id.toString()}>
            {at.name}
          </label>
          <br />
          <span className="small text-muted">{displayDuration(at.duration)}</span>
        </div>
      ))}
    </div>
  );
}

function SelectedAppointmentTypes({ appointmentTypes }: { appointmentTypes: AppointmentType[] }) {
  return (
    <div className="col-sm-6">
      <ul>
        {appointmentTypes.map(at => (
          <li key={at.id}>
            {at.name}
            <br />
            <span className="small text-muted">{displayDuration(at.duration)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

function split<T>(array: Array<T>): [Array<T>, Array<T>] {
  return chunk(array, Math.ceil(array.length / 2)) as [Array<T>, Array<T>];
}

export default AppointmentTypes;

import type { FC } from 'react';

import Row from '@/ui/Row';
import Badge from '@/ui/Badge';
import LocalTime from '@/LocalTime';

import type { LoginActivityModel } from '@models/User';

const LoginActivities: FC<{ loginActivities: LoginActivityModel[] }> = ({ loginActivities }) => {
  return (
    <Row label="Most Recent Login Attempts">
      <table className="table-striped table-light table">
        <thead>
          <tr>
            <th className="border-top-0">Status</th>
            <th className="border-top-0">Date / Time</th>
            <th className="border-top-0">Location</th>
            <th className="border-top-0">IP Address</th>
          </tr>
        </thead>
        <tbody>
          {loginActivities.length === 0 ? (
            <tr>
              <td className="text-muted text-center" colSpan={4}>
                No login attempts have been recorded for this user.
              </td>
            </tr>
          ) : (
            <>
              {loginActivities.map((activity, i) => (
                <tr key={i}>
                  <td>
                    {activity.succeeded ? <Badge color="green">Succeeded</Badge> : <Badge color="red">Failed</Badge>}
                  </td>
                  <td>
                    <LocalTime value={activity.date} />
                  </td>
                  <td>{activity.location || '(Unknown)'}</td>
                  <td>
                    <code>{activity.ipAddress}</code>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </Row>
  );
};

export default LoginActivities;

import type { FC } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import InsightCreationResult from './InsightCreationResult';
import InsightUpgradeResult from './InsightUpgradeResult';
import Settings from './Settings';
import InsightsUpgrade from './InsightsUpgrade';

import type { TicketStatus } from '@models/TicketStatus';
import Alert from '@shared/Alert';

export type InsightCreationResultType = {
  status: 'ok' | 'unauthorized';
  error?: string;
} | null;

export type InsightUpgradeResultType = {
  status: 'bad_credentials' | 'not_found' | 'server_problem' | 'unauthorized';
  error?: string;
} | null;

export type IntegrationSettings = {
  afterCancelPsaStatusId: string;
  afterCreatePsaStatusId: string;
  afterSchedulePsaStatusId: string;
  noResponsePsaStatusId: string;
  errors: Record<string, string>;
  connected: boolean;
  persisted: boolean;
  requiresInsightUpgrade: boolean;
  password: string;
  username: string;
};

interface Props {
  integration: IntegrationSettings;
  insightCreationResult: InsightCreationResultType;
  insightUpgradeResult: InsightUpgradeResultType;
  ticketStatuses: TicketStatus[];
}

const Autotask: FC<Props> = ({ integration, insightCreationResult, insightUpgradeResult, ticketStatuses }) => {
  return (
    <>
      <Header title="Autotask Integration" />
      <Flash />
      {integration.errors.base && (
        <Alert
          color="red"
          className="mb-3"
          icon="circle-xmark"
          title="TimeZest was unable to connect to your Autotask account with the credentials provided."
          content={<p className="mb-0">Reason from Autotask: {integration.errors.base}</p>}
        />
      )}
      {insightCreationResult && <InsightCreationResult insightCreationResult={insightCreationResult} />}
      {insightUpgradeResult && <InsightUpgradeResult insightUpgradeResult={insightUpgradeResult} />}

      <Settings integration={integration} ticketStatuses={ticketStatuses} />

      {integration.requiresInsightUpgrade && integration.persisted && (
        <>
          <hr />
          <InsightsUpgrade />
        </>
      )}
    </>
  );
};

export default asScreen(Autotask);

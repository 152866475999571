import type { UIState } from './uiReducer';

const setDraggingElement: (state: UIState, draggingElementId: boolean) => UIState = (state, draggingElement) => {
  return {
    ...state,
    draggingElement,
  };
};

export default setDraggingElement;

import type { FC } from 'react';

import type { Time } from './Show';

interface Props {
  earliestDate: string | null;
  earliestTime: Time | null;
  latestDate: string | null;
  latestTime: Time | null;
  earliestDateTime: string;
  latestDateTime: string;
  timeClosed: boolean;
}

const Limit: FC<Props> = ({
  earliestDate,
  earliestTime,
  latestDate,
  latestTime,
  earliestDateTime,
  latestDateTime,
  timeClosed,
}) => {
  const formattedEarliestTime = earliestTime
    ? `${earliestTime.hour}:${earliestTime.minute}:${earliestTime.second}`
    : '';
  const formattedLatestTime = latestTime ? `${latestTime.hour}:${latestTime.minute}:${latestTime.second}` : '';

  if (earliestDate && latestDate) {
    return (
      <>
        <strong>
          {earliestDateTime} - {latestDateTime}
        </strong>
        {timeClosed && (
          <div className="small">
            Business hours: {formattedEarliestTime} - {formattedLatestTime}
          </div>
        )}
      </>
    );
  }

  if (earliestDate) {
    return <strong>After {earliestDateTime}</strong>;
  }

  if (latestDate) {
    return <strong>Before {latestDateTime}</strong>;
  }

  if (timeClosed) {
    return (
      <strong>
        Business hours: {formattedEarliestTime} - {formattedLatestTime}
      </strong>
    );
  }

  return <>(None)</>;
};

export default Limit;

import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { loadTemplate } from './actions';
import Mjml from './Blocks/Mjml';
import MjmlBody from './Blocks/MjmlBody';
import MjmlButton from './Blocks/MjmlButton';
import MjmlColumn from './Blocks/MjmlColumn';
import MjmlSection from './Blocks/MjmlSection';
import MjmlText from './Blocks/MjmlText';
import { useAppDispatch, useAppSelector } from './hooks';
import Placeholder from './Placeholder';
import type { Index } from './Reducers/templateReducer';
import MjmlLogo from './Blocks/MjmlLogo';
import TextFormattingBar from './TextFormattingBar';
import MjmlSpacer from './Blocks/MjmlSpacer';
import MjmlImage from './Blocks/MjmlImage';
import type { IndexedMjmlNode } from './EmailTemplate';

import type { EmailTemplate, MjmlNode } from '@models/EmailTemplate';

const WorkArea = styled.div`
  width: 66%;
  flex-grow: 1;
  min-width: 400px;
  background-color: #f6f6f6;
  height: 750px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TextFormattingBarContainer = styled('div')<{ $dragging: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${props => (props.$dragging ? '10' : '1000')};
  background-color: #f6f6f6;
`;

const ContentContainer = styled.div`
  margin-top: -4px;
  overflow-y: auto;
  flex-grow: 1;
`;

interface Props {
  initialTemplate: EmailTemplate;
  name: string;
}

const Preview: FC<Props> = ({ initialTemplate, name }) => {
  const dispatch = useAppDispatch();
  const index = useAppSelector(state => state.template.index);
  const insertionPoint = useAppSelector(state => state.ui.insertionPoint);
  const rootId = useAppSelector(state => state.template.rootId);
  const selectedElementId = useAppSelector(state => state.ui.selectedElementId);
  const editorId = useAppSelector(state => state.template.editorId);
  const dragging = useAppSelector(state => state.ui.draggingElement);
  const template = useMemo(() => {
    return JSON.stringify(isEmpty(index) ? {} : buildJson(index, rootId));
  }, [index, rootId]);

  useEffect(() => {
    dispatch(loadTemplate({ template: initialTemplate }));
  }, [initialTemplate, dispatch]);

  const textElementSelected =
    index[selectedElementId as string] && index[selectedElementId as string].type === 'mjml-text';

  return (
    <>
      <WorkArea id="preview-pane">
        <TextFormattingBarContainer $dragging={dragging}>
          <TextFormattingBar textElementSelected={textElementSelected} textFormattingBarPlaceholder={true} />
          <div id={`${editorId}-text-formatting-bar-wrapper`} />
        </TextFormattingBarContainer>
        <ContentContainer>{renderNode(index, rootId, insertionPoint, selectedElementId)}</ContentContainer>
      </WorkArea>
      <input type="hidden" name={name} value={template} />
    </>
  );
};

export default Preview;

interface InsertionPoint {
  parentId: string;
  insertionIndex: number;
}

function buildJson(index: Index, rootId: string): MjmlNode {
  const node = index[rootId] as IndexedMjmlNode;
  const element = omit(node, ['childIds', 'id', 'parentId', 'columnProperties']) as MjmlNode;
  element.children = (node.childIds || []).map(id => buildJson(index, id));
  return element;
}

export function renderNode(
  index: Index,
  id: string,
  insertionPoint: InsertionPoint | null,
  selectedElementId: string | null
): JSX.Element {
  const node: IndexedMjmlNode = index[id];
  const selected = id === selectedElementId;

  if (!node) return <>Initializing...</>;

  switch (node.type) {
    // MJML Nodes
    case 'mjml':
      return (
        <Mjml key={id} id={id}>
          {renderChildrenWithInsertionPoint(index, node, insertionPoint, selectedElementId)}
        </Mjml>
      );
    case 'mjml-body':
      return (
        <MjmlBody key={id} node={node} childIds={node.childIds}>
          {renderChildrenWithInsertionPoint(index, node, insertionPoint, selectedElementId)}
        </MjmlBody>
      );
    case 'mjml-section':
      return (
        <MjmlSection key={id} node={node} selected={selected}>
          {renderChildrenWithInsertionPoint(index, node, insertionPoint, selectedElementId)}
        </MjmlSection>
      );
    case 'mjml-column':
      return (
        <MjmlColumn key={id} node={node} selected={selected}>
          {renderChildrenWithInsertionPoint(index, node, insertionPoint, selectedElementId)}
        </MjmlColumn>
      );
    case 'mjml-button':
      return <MjmlButton key={id} node={node} selected={selected} />;
    case 'mjml-text':
      return <MjmlText leaves={[]} key={id} node={node} selected={selected} />;
    case 'mjml-logo':
      return <MjmlLogo key={id} node={node} selected={selected} />;
    case 'mjml-spacer':
      return <MjmlSpacer key={id} node={node} selected={selected} />;
    case 'mjml-image':
      return <MjmlImage key={id} node={node} selected={selected} />;
    // Other
    default:
      return <>Unknown Node</>;
  }
}

function renderChildrenWithInsertionPoint(
  index: Index,
  node: IndexedMjmlNode,
  insertionPoint: InsertionPoint | null,
  selectedElementId: string | null
): JSX.Element[] {
  const childNodes = node.childIds.map(id => renderNode(index, id, insertionPoint, selectedElementId));

  if (insertionPoint && insertionPoint.parentId === node.id) {
    return [
      ...childNodes.slice(0, insertionPoint.insertionIndex),
      <Placeholder parentElementType={node.type} key="placeholder" />,
      ...childNodes.slice(insertionPoint.insertionIndex),
    ];
  }

  return childNodes;
}

import type { FC, PropsWithChildren } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const AllSetting: FC<PropsWithChildren<{}>> = ({ children }) => {
  const popover = (
    <Popover className="shadow">
      <Popover.Body>{children}</Popover.Body>
    </Popover>
  );

  return (
    <td className="bg-transparent text-center">
      All &nbsp;
      <OverlayTrigger placement="bottom" overlay={popover}>
        <FontAwesomeIcon icon="question-circle" style={{ color: '#ccd5dd' }} />
      </OverlayTrigger>
    </td>
  );
};

export default AllSetting;

import type { FC } from 'react';

import CopyToClipboard from '@/CopyToClipboard';

import type AppointmentType from '@models/AppointmentType';

interface Props {
  appointmentType: AppointmentType;
  host: string | null;
  resourceSlugs: string | undefined;
}

const ShareableUrl: FC<Props> = ({ appointmentType, host, resourceSlugs }) => {
  if (!appointmentType.workflow && appointmentType.shareableUrlTicketMode === 'existing_ticket') return null;

  const url = `${host}/${resourceSlugs}/${appointmentType.slug}`;

  return (
    <div className="small">
      <strong className="text-muted">Shareable URL:</strong>
      &nbsp;&nbsp;
      {url}
      &nbsp;&nbsp;
      <CopyToClipboard text={url} />
    </div>
  );
};

export default ShareableUrl;

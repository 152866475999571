import type { FC } from 'react';

import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import './DateRangePicker.scss';

type Props = {
  from: Date;
  to: Date;
  onChangeTimeRange: ({ to, from }: { to: Date; from: Date }) => void;
  onClickDay: () => void;
};

const DateRangePicker: FC<Props> = ({ from, to, onChangeTimeRange, onClickDay }) => (
  <ReactDateRangePicker
    calendarProps={{ onClickDay }}
    rangeDivider="/"
    format="d-MMM-y"
    className="DateRangePicker__Container"
    clearIcon={null}
    value={[from, to]}
    onChange={([from, to]: [Date, Date]) => onChangeTimeRange({ to, from })}
  />
);

export default DateRangePicker;

import type { ChangeEvent, FC } from 'react';
import { useRef, useState } from 'react';

import classNames from 'classnames';
import isEmail from 'validator/lib/isEmail';
import defer from 'lodash/defer';
import uniq from 'lodash/uniq';

import FontAwesomeIcon from './FontAwesomeIcon';

import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  emailAddresses: string[];
  disabled: boolean;
  name: string;
  placeholder: string;
  id?: string;
  error?: string;
  limit?: number;
  onChange?: (emailAddresses: string[]) => void;
}

const MultipleEmailAddresses: FC<Props> = ({
  disabled,
  emailAddresses: initialEmailAddresses,
  id,
  name,
  error,
  placeholder,
  limit,
  onChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [emailAddresses, setEmailAddresses] = useStateFromProp(initialEmailAddresses, uniq);

  const updateEmailAddresses = (newEmailAddresses: string[]) => {
    setEmailAddresses(newEmailAddresses);

    onChange?.(newEmailAddresses);
  };

  const limitReached = emailAddresses.length === limit;

  const validateEmail = (email: string): boolean => {
    if (!isEmail(email) || emailAddresses.includes(email)) {
      return false;
    }

    return true;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (limitReached) return;

    setInputValue(event.target.value);
  };

  const handleFocus = () => {
    setFocused(true);
    emailInputRef.current?.focus();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Enter', 'Tab', ' ', ',', ';'].includes(event.key)) {
      const newEmailAddress = inputValue.trim();

      if (newEmailAddress && validateEmail(newEmailAddress)) {
        event.preventDefault();

        updateEmailAddresses([...emailAddresses, newEmailAddress]);

        setInputValue('');
      }
    }
  };

  const handleDelete = (toBeRemovedAddress: string) => {
    updateEmailAddresses(emailAddresses.filter(address => address !== toBeRemovedAddress));
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.clipboardData) return;

    const pastedString = event.clipboardData.getData('text');

    const addresses = pastedString.split(' ');

    const newEmailAddresses = addresses.filter(a => isEmail(a));

    if (newEmailAddresses) {
      const toBeAddedAddressed: string[] = newEmailAddresses.filter(address => !emailAddresses.includes(address));

      updateEmailAddresses([...emailAddresses, ...toBeAddedAddressed]);

      setFocused(false);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.preventDefault();

    if (inputValue) {
      const newEmailAddress = inputValue.trim();

      if (validateEmail(newEmailAddress)) {
        updateEmailAddresses([...emailAddresses, newEmailAddress]);
        setInputValue('');
      }
    }

    defer(() => {
      if (!containerRef.current?.contains(document.activeElement)) {
        setFocused(false);
      }
    });
  };

  return (
    <div className="mb-3">
      <div
        tabIndex={-1}
        role="button"
        ref={containerRef}
        className={classNames('multiple-email', { disabled, focused, 'is-invalid': error })}
        onClick={handleFocus}
        onKeyDown={handleFocus}
      >
        {emailAddresses.map(address => (
          <div key={address} className={classNames('multiple-email-token', { 'd-none': disabled })}>
            <p className="multiple-email-token-address">{address}</p>
            <button
              tabIndex={-1}
              type="button"
              className="multiple-email-delete-button"
              onClick={() => handleDelete(address)}
            >
              <FontAwesomeIcon style={{ cursor: 'pointer' }} icon="times" size="sm" color="#3d3d3d" />
            </button>
          </div>
        ))}

        <div className="multiple-email-input-container">
          <input
            id={id}
            className={limitReached ? 'hidden-input' : ''}
            tabIndex={0}
            placeholder={disabled || emailAddresses.length > 0 || inputValue || focused ? '' : placeholder}
            type="text"
            disabled={disabled}
            size={3}
            ref={emailInputRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            onBlur={handleBlur}
            onFocus={() => {
              setFocused(true);
            }}
          />
        </div>

        {!disabled && emailAddresses.map(address => <input key={address} type="hidden" name={name} value={address} />)}
      </div>

      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default MultipleEmailAddresses;

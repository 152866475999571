import { type FC, useState } from 'react';

import TextInputRow from '@/ui/TextInputRow';
import Row from '@/ui/Row';
import PasswordInputRow from '@/ui/PasswordInputRow';
import Form from '@/Form';

import InputGroup from '@shared/ui/Inputs/InputGroup';

import type { IntegrationSettings } from '.';

interface Props {
  integration: IntegrationSettings;
  errors: Record<string, string>;
}

const Settings: FC<Props> = ({ errors, integration }) => {
  const [host, setHost] = useState(integration.host);

  return (
    <>
      <p>
        These are the credentials and settings that TimeZest will use to connect to your instance of ConnectWise Manage.
      </p>

      <Form url="/settings/integrations/connect_wise" method="patch">
        <Row label="Hostname" width={6} labelFor="connect_wise_integration[host]">
          <InputGroup
            prepend="https://"
            name="connect_wise_integration[host]"
            value={host}
            error={errors?.host}
            onChange={e => setHost(e.target.value)}
          />
        </Row>

        <TextInputRow
          name="connect_wise_integration[company_id]"
          label="Company ID"
          value={integration.companyId}
          width={6}
          error={errors.companyId}
        />
        <TextInputRow
          name="connect_wise_integration[public_key]"
          label="Public API Key"
          value={integration.publicKey}
          width={6}
          error={errors.publicKey}
        />
        <PasswordInputRow
          name="connect_wise_integration[private_key]"
          label="Private API Key"
          value={integration.privateKey}
          width={6}
          error={errors.privateKey}
        />

        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default Settings;

import type { FC } from 'react';

import InvoiceEstimate from './InvoiceEstimate';
import CreditNoteEstimates from './CreditNoteEstimates';
import CreditCardRequired from './CreditCardRequired';

import type { CreditNoteEstimatesModel, InvoiceEstimateModel } from '..';

interface Props {
  card: { type: string; last4: string } | null;
  creditNoteEstimates: CreditNoteEstimatesModel | null;
  invoiceEstimate: InvoiceEstimateModel | null;
  nextEstimateTotal: number | null;
  activeAccount: boolean;
}

const BillingInformation: FC<Props> = ({
  activeAccount,
  nextEstimateTotal,
  card,
  creditNoteEstimates,
  invoiceEstimate,
}) => {
  if (invoiceEstimate) {
    return <InvoiceEstimate card={card} invoiceEstimate={invoiceEstimate} />;
  }

  if (creditNoteEstimates) {
    return <CreditNoteEstimates creditNoteEstimates={creditNoteEstimates} hasCard={!!card} />;
  }

  if (!activeAccount && nextEstimateTotal && nextEstimateTotal > 0) {
    <CreditCardRequired nextEstimateTotal={nextEstimateTotal} />;
  }

  return null;
};

export default BillingInformation;

import type { FC } from 'react';

import type { AttributeModel } from '@models/User';
import type User from '@models/User';
import { capitalize } from '@shared/text';

interface Props {
  user: User;
  attributes: AttributeModel[];
  attributesAllowed: boolean;
}

const UserDetailsSummary: FC<Props> = ({ user, attributes, attributesAllowed }) => {
  let summary: JSX.Element | string = `Role: ${capitalize(user.role)}`;

  if (user.slug) {
    summary = (
      <>
        {summary}; Configured for URL-based scheduling with <code>{user.slug}</code>
      </>
    );
  } else {
    summary = `${summary}; Not configured for URL-based scheduling`;
  }

  if (attributesAllowed && attributes.length > 0) {
    const count = attributes.filter(a => !!a.value).length;
    const text = count === 1 ? '1 custom attribute' : `${count} custom attributes`;
    summary = (
      <>
        {summary}; {text} set
      </>
    );
  }

  return <>{summary}.</>;
};

export default UserDetailsSummary;

import type { FC } from 'react';

import classNames from 'classnames';

interface Props {
  prevPage: string;
  nextPage: string;
  onNextPage: (value: string) => void;
  onPreviousPage: (value: string) => void;
}

export const Pagination: FC<Props> = ({ prevPage, nextPage, onNextPage, onPreviousPage }) => {
  const handlePreviousPage = () => {
    onNextPage('');
    onPreviousPage(prevPage);
  };

  const handleNextPage = () => {
    onPreviousPage('');
    onNextPage(nextPage);
  };

  return (
    <ul className="pagination justify-content-center">
      <li className={classNames('page-item', { disabled: !prevPage })}>
        <button className="page-link" onClick={handlePreviousPage}>
          Previous
        </button>
      </li>
      <li className={classNames('page-item', { disabled: !nextPage })}>
        <button className="page-link" onClick={handleNextPage}>
          Next
        </button>
      </li>
    </ul>
  );
};

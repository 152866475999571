import type { FC } from 'react';
import { useEffect, useState } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetBoardOptionsForConnectWiseQuery, useGetOptionsForConnectWiseQuery } from '@graphql/generated';
import SelectRow from '@ui/SelectRow';
import PlainTextRow from '@ui/PlainTextRow';
import RowError from '@shared/ui/RowError';
import type Reference from '@models/Reference';
import { idStringifier } from '@shared/text';

const PRIORITY_HELP_TEXT = 'TimeZest will create the new service ticket with this priority.';
const SOURCE_HELP_TEXT = 'TimeZest will use this as the source value for the new service ticket.';
const STATUS_HELP_TEXT = 'TimeZest will create the new service ticket with this status.';
const BOARD_HELP_TEXT = 'TimeZest will create the new service ticket on this board.';
const TYPE_HELP_TEXT = 'TimeZest will create the new service ticket with this type.';
const SUB_TYPE_HELP_TEXT = 'TimeZest will create the new service ticket with this subtype.';

interface SubTypeProps {
  disabled: boolean;
  error?: string;
  loading: boolean;
  name: string;
  readOnly: boolean;
  subTypeId: number | null;
  typeId: number | null;
  types: (Reference & { subTypes: Reference[] })[];
}

const SubTypeRow: FC<SubTypeProps> = ({ disabled, error, loading, name, readOnly, subTypeId, typeId, types }) => {
  const type = typeId ? types.find(t => t.id === typeId) : null;
  const subTypes = type?.subTypes || [];

  if (readOnly) {
    return (
      <PlainTextRow label="Sub-Type" helpText={SUB_TYPE_HELP_TEXT}>
        {loading ? 'Loading...' : subTypes.find(st => st.id === subTypeId)?.name || '(None)'}
      </PlainTextRow>
    );
  }

  if (loading || subTypes.length === 0) return null;

  return (
    <SelectRow
      name={name}
      label="Sub-Type"
      value={(subTypeId || '').toString()}
      options={[{ value: '', name: '(None)' }, ...subTypes.map(idStringifier)]}
      helpText={SUB_TYPE_HELP_TEXT}
      disabled={disabled}
      error={error}
    />
  );
};

interface Props {
  boardId: number | null;
  boardIdAttr: string;
  boardIdError?: string;
  disabled?: boolean;
  modelName: string;
  priorityId: number | null;
  priorityIdAttr: string;
  priorityIdError?: string;
  readOnly: boolean;
  sourceId: number | null;
  sourceIdAttr: string;
  sourceIdError?: string;
  statusId: number | null;
  statusIdAttr: string;
  statusIdError?: string;
  subTypeId: number | null;
  subTypeIdAttr: string;
  subTypeIdError?: string;
  typeId: number | null;
  typeIdAttr: string;
  typeIdError?: string;
  templateMode?: boolean;
  onLoaded?: () => void;
}

const ServiceTicketOptions: FC<Props> = ({ onLoaded, ...props }) => {
  const [boardId, setBoardId] = useState(props.boardId);
  const [typeId, setTypeId] = useState(props.typeId);
  const [subTypeId, setSubTypeId] = useState(props.subTypeId);

  const optionsQueryResult = useGetOptionsForConnectWiseQuery({ skip: props.templateMode });
  const { loading, data, error } = optionsQueryResult;

  const boardQueryResult = useGetBoardOptionsForConnectWiseQuery({
    skip: !boardId,
    variables: { boardId: boardId || 0 },
  });
  const { data: boardData, error: boardError, loading: boardLoading } = boardQueryResult;

  const loaded = !loading && data?.connectWise.id;

  const errorType = error?.graphQLErrors[0]?.extensions?.type || boardError?.graphQLErrors[0]?.extensions?.type;

  useEffect(() => {
    if (loaded && onLoaded) onLoaded();
  }, [loaded, onLoaded]);

  const handleBoardIdChange = (boardId: string): void => {
    const newBoardId = parseInt(boardId, 10) || 0;
    setBoardId(newBoardId);
  };

  const handleTypeIdChange = (typeId: string): void => {
    const newTypeId = parseInt(typeId, 10) || 0;
    setTypeId(newTypeId);
    setSubTypeId(null);
  };

  if (errorType) {
    return (
      <RowError
        width={6}
        label="Service Ticket Options"
        helpText=""
        type={errorType as string}
        unauthorizedError="TimeZest could not retrieve appointment type options from ConnectWise PSA because the API user does not have sufficient permissions."
        unauthorizedRemediation={
          <>
            Please check the API user has correct permissions assigned as&nbsp;
            <a
              href="https://help.timezest.com/en/articles/3158493-creating-a-custom-security-role-in-connectwise-manage-for-timezest"
              target="_blank"
              rel="noreferrer"
            >
              described here
            </a>
            .
          </>
        }
        error="TimeZest encountered an unexpected error. Please reload the page and try again"
      />
    );
  }

  return (
    <>
      <SelectRowFromGraphQL
        label="Board"
        field="connectWise.queues"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Select a board..."
        name={name(props.modelName, props.boardIdAttr)}
        disabled={props.disabled || false}
        readOnly={props.readOnly}
        templateMode={props.templateMode}
        error={props.boardIdError}
        helpText={BOARD_HELP_TEXT}
        value={boardId?.toString()}
        queryResult={optionsQueryResult}
        onChange={handleBoardIdChange}
      />

      <SelectRowFromGraphQL
        label="Source"
        field="connectWise.sources"
        loadingMessage="Loading from ConnectWise PSA..."
        emptyDataMessage="No sources found."
        name={name(props.modelName, props.sourceIdAttr)}
        disabled={props.disabled}
        readOnly={props.readOnly}
        templateMode={props.templateMode}
        error={props.sourceIdError}
        value={props.sourceId?.toString()}
        helpText={SOURCE_HELP_TEXT}
        queryResult={optionsQueryResult}
      />

      <SelectRowFromGraphQL
        label="Priority"
        field="connectWise.priorities"
        loadingMessage="Loading from ConnectWise PSA..."
        emptyDataMessage="No priorities found."
        name={name(props.modelName, props.priorityIdAttr)}
        disabled={props.disabled}
        readOnly={props.readOnly}
        templateMode={props.templateMode}
        error={props.priorityIdError}
        value={props.priorityId?.toString()}
        helpText={PRIORITY_HELP_TEXT}
        queryResult={optionsQueryResult}
      />

      {boardId ? (
        <>
          <SelectRowFromGraphQL
            label="Status"
            field="connectWise.ticketStatuses"
            loadingMessage="Loading from ConnectWise PSA..."
            emptyDataMessage="No ticket statuses found."
            name={name(props.modelName, props.statusIdAttr)}
            disabled={props.disabled}
            readOnly={props.readOnly}
            templateMode={props.templateMode}
            error={props.statusIdError}
            value={props.statusId?.toString()}
            helpText={STATUS_HELP_TEXT}
            queryResult={boardQueryResult}
          />
          {boardData?.connectWise.ticketTypes.length === 0 ? (
            props.readOnly ? (
              <PlainTextRow helpText={TYPE_HELP_TEXT} label="Type">
                {boardData?.connectWise.ticketTypes.find(data => data.id === props.typeId)?.name || '(Unknown)'}
              </PlainTextRow>
            ) : (
              <SelectRow
                helpText={TYPE_HELP_TEXT}
                label="Type"
                disabled={props.readOnly}
                name={name(props.modelName, props.typeIdAttr)}
                options={[{ value: '', name: '(None)' }]}
                value={(props.typeId || '').toString()}
              />
            )
          ) : (
            <SelectRowFromGraphQL
              label="Type"
              field="connectWise.ticketTypes"
              loadingMessage="Loading from ConnectWise PSA..."
              prompt="(None)"
              name={name(props.modelName, props.typeIdAttr)}
              disabled={props.disabled}
              readOnly={props.readOnly}
              templateMode={props.templateMode}
              error={props.typeIdError}
              value={props.typeId?.toString()}
              helpText={TYPE_HELP_TEXT}
              queryResult={boardQueryResult}
              onChange={handleTypeIdChange}
            />
          )}
        </>
      ) : (
        <>
          <PlainTextRow label="Status" helpText={STATUS_HELP_TEXT}>
            Select a board to see statuses.
          </PlainTextRow>
          <PlainTextRow label="Type" helpText={TYPE_HELP_TEXT}>
            Select a board to see ticket types.
          </PlainTextRow>
        </>
      )}

      <SubTypeRow
        disabled={props.disabled || false}
        error={props.subTypeIdError}
        loading={boardLoading}
        name={name(props.modelName, props.subTypeIdAttr)}
        subTypeId={subTypeId}
        typeId={typeId}
        types={boardData?.connectWise.ticketTypes || []}
        readOnly={props.readOnly}
      />
    </>
  );
};

export default ServiceTicketOptions;

function name(modelName: string, attrName: string): string {
  return `${modelName}[${attrName}]`;
}

import type { FC, PropsWithChildren } from 'react';
import { createContext, useEffect, useState } from 'react';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');

  useEffect(() => {
    document.body.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;

const NULL_THEME_CONTEXT = {
  theme: 'light',
  toggleTheme: () => {},
};

export const ThemeContext = createContext(NULL_THEME_CONTEXT);

import type { FC, PropsWithChildren } from 'react';

import Collapsible from 'react-collapsible';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

export const PropertiesCollapsible: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <Collapsible
      className="Properties mb-2"
      overflowWhenOpen="visible"
      openedClassName="Properties"
      contentOuterClassName="Properties__contentOuter"
      contentInnerClassName="Properties__contentInner"
      triggerClassName="Properties__trigger"
      triggerOpenedClassName="Properties__trigger"
      trigger={
        <>
          {title} <FontAwesomeIcon icon="chevron-up" />
        </>
      }
      triggerWhenOpen={
        <>
          {title} <FontAwesomeIcon icon="chevron-down" />
        </>
      }
      open={true}
    >
      {children}
    </Collapsible>
  );
};

import type { FC } from 'react';

interface Props {
  internalName: string;
  externalName: string | null;
  schedulingAlgorithm: string;
}

const ALGORITHMS = {
  round_robin: 'Round-robin',
  max_availability: 'Maximum availability',
};

const TeamDetailsSummary: FC<Props> = ({ internalName, externalName, schedulingAlgorithm }) => {
  return (
    <>
      Internal Name: {internalName}; {externalName && `External name: ${externalName}; `}
      {ALGORITHMS[schedulingAlgorithm]} scheduling algorithm.
    </>
  );
};

export default TeamDetailsSummary;

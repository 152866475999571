import type { FC, ReactNode } from 'react';

import ExpanderCommands from './ExpanderCommands';

interface Props {
  url?: string | null;
  dirty: boolean;
  disabled?: boolean;
  expanded: boolean;
  children: ReactNode;
  confirming: boolean;
  method?: 'POST' | 'PATCH';
  setConfirming: React.Dispatch<React.SetStateAction<boolean>>;
  onExpand: (expanded: boolean) => void;
  onDirtyStatusUpdate?: (isDirty: boolean) => void;
}

const ExpanderBody: FC<Props> = ({
  url,
  expanded,
  disabled,
  children,
  method,
  dirty,
  confirming,
  setConfirming,
  onExpand,
  onDirtyStatusUpdate,
}) => {
  if (!expanded) {
    return null;
  }

  return (
    <>
      <div className="card-body pb-0 pt-4">{children}</div>
      <div className="card-footer d-flex">
        <div className="flex-grow-1" />
        <ExpanderCommands
          url={url}
          disabled={disabled}
          dirty={dirty}
          confirming={confirming}
          method={method}
          setConfirming={setConfirming}
          onExpand={onExpand}
          onDirtyStatusUpdate={onDirtyStatusUpdate}
        />
      </div>
    </>
  );
};

export default ExpanderBody;

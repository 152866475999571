import { useCallback, useContext, useState } from 'react';
import type { FC } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import SubmitButtom from '@/SubmitButton';
import asScreen from '@/Screen';
import AccountContext from '@/AccountContext';
import Form from '@/Form';
import Header from '@/Header';

import PotentialUser from './PotentialUser';

import MultipleEmailAddresses from '@shared/MultipleEmailAddresses';

type User = { email: string; role: string; available: boolean };

const Invitations: FC = () => {
  const { integrations } = useContext(AccountContext);

  const [users, setUsers] = useState<User[]>([]);

  const handleEmailAddressesChange = (emailAddresses: string[]) => {
    const usersByEmail = keyBy(users, 'email');
    const newEmails = emailAddresses.map(email => email.toLowerCase().trim());

    const newUsers = newEmails.map(email => {
      return {
        email,
        role: get(usersByEmail, [email, 'role'], 'user'),
        available: get(usersByEmail, [email, 'available'], true),
      };
    });

    setUsers(newUsers);
  };

  const handleRoleChange = (email: string, role: string) => {
    setUsers(users => {
      return users.map(user => (user.email === email ? { ...user, role } : user));
    });
  };

  const handleStatusChange = useCallback(
    (email: string, available: boolean) => {
      setUsers(users => {
        return users.map(user => (user.email === email ? { ...user, available } : user));
      });
    },
    [setUsers]
  );

  const psaIntegrations = integrations.filter(i => i.capabilities.includes('canInviteUsers'));

  return (
    <>
      <Header title="Invite new users" />
      <div className={classNames({ 'mb-3': !!users.length })}>
        <label htmlFor="emails" className="mb-2">
          Enter the email addresses of users to invite to your TimeZest account to be able to schedule appointments
          with.
        </label>
        <MultipleEmailAddresses
          id="emails"
          name="emails"
          disabled={false}
          emailAddresses={users.map(user => user.email)}
          placeholder="Enter email addresses"
          onChange={handleEmailAddressesChange}
        />
      </div>
      {users.length > 0 ? (
        <Form url="/settings/users/invitations">
          <input type="hidden" name="redirect_path" value="/settings/users" />
          <table className="table-transparent w-100 mb-3 table">
            <thead>
              <tr>
                <th colSpan={2}>
                  <p className="fw-normal mb-0">
                    TimeZest is attempting to match these users to accounts on your connected integrations:
                  </p>
                </th>
                {psaIntegrations.map(integration => (
                  <th key={integration.type} style={{ width: '0%' }} className="text-nowrap text-end">
                    {integration.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <PotentialUser
                  key={user.email}
                  email={user.email}
                  role={user.role}
                  integrations={psaIntegrations}
                  onRoleChange={handleRoleChange}
                  onStatusChange={handleStatusChange}
                />
              ))}
            </tbody>
          </table>

          <SubmitButtom
            className="btn btn-primary"
            icon="angle-right"
            text="Add these users"
            disabled={users.every(user => !user.available)}
          />
        </Form>
      ) : (
        <p className="text-center">No email addresses entered.</p>
      )}
    </>
  );
};

export default asScreen(Invitations);

import { useContext, type FC } from 'react';

import PlanContext from '@/PlanContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  newPlanAllowsRescheduling: boolean;
}

const Rescheduling: FC<Props> = ({ newPlanAllowsRescheduling }) => {
  const { allowsRescheduling: currentPlanAllowsRescheduling } = useContext(PlanContext);

  if (newPlanAllowsRescheduling && !currentPlanAllowsRescheduling) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; Your clients and users will be able to cancel and reschedule appointments after they&apos;re scheduled.
      </div>
    );
  }

  if (!newPlanAllowsRescheduling && currentPlanAllowsRescheduling) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp; Your clients and users will no longer be able to cancel and reschedule appointments after they&apos;re
        scheduled.
      </div>
    );
  }
};

export default Rescheduling;

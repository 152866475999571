import { useContext, type FC } from 'react';

import CurrentUserContext from '@/CurrentUserContext';

import type { SchedulingRequest } from './Show';
import BasicDetailRow from './BasicDetailRow';
import Limit from './Limit';

import { displayDuration, sentence } from '@shared/text';

interface Props {
  schedulingRequest: SchedulingRequest;
}

const BasicDetails: FC<Props> = ({ schedulingRequest }) => {
  const { name: hasCurrentUser } = useContext(CurrentUserContext);

  const {
    cancellation,
    timeClosed,
    clientGuestsList,
    technicianGuestsList,
    resources,
    limit,
    status,
    durationMins,
    durationOverridden,
    client,
    companyName,
    scheduledUsers,
    scheduled,
    scheduledTime,
  } = schedulingRequest;

  return (
    <>
      <div className="alert alert-light">
        <strong className="text-success">Status:</strong> <span className="text-success">{status}</span>
      </div>

      <BasicDetailRow label="Duration:">
        <strong>{displayDuration(durationMins)}</strong>
        {durationOverridden && <div className="small">Overridden on scheduling request</div>}
      </BasicDetailRow>

      <BasicDetailRow label="Client:">
        <strong>{client?.name}</strong> at <strong>{companyName}</strong>
        <div className="small">{client?.email}</div>
      </BasicDetailRow>

      {clientGuestsList && (
        <BasicDetailRow label="Client guests:">
          <strong>{sentence(Array.from(new Set(clientGuestsList)))}</strong>
        </BasicDetailRow>
      )}

      {technicianGuestsList && (
        <BasicDetailRow label="Technician guests:">
          <strong>{sentence(Array.from(new Set(technicianGuestsList)))}</strong>
        </BasicDetailRow>
      )}

      <BasicDetailRow label={resources.length > 1 ? 'Resources:' : 'Resource:'}>
        <strong>{sentence(resources.map(r => r.name))}</strong>
        <div className="small">
          {resources
            .map(r => r.role)
            .filter(role => role !== 'DeletedUser')
            .join(' / ')
            .replace('Member', 'User')}
        </div>
      </BasicDetailRow>

      <BasicDetailRow label="Limits:">
        <Limit
          earliestDate={limit.earliestDate}
          earliestTime={limit.earliestTime}
          latestDate={limit.latestDate}
          latestTime={limit.latestTime}
          earliestDateTime={limit.earliestDateTime}
          latestDateTime={limit.latestDateTime}
          timeClosed={limit.timeClosed}
        />
      </BasicDetailRow>

      {scheduled && (
        <>
          <BasicDetailRow label="Scheduled Time:">
            {scheduledTime ? (
              <>
                <strong>{scheduledTime.time}</strong> on <strong>{scheduledTime.date}</strong>
                <div className="small">{scheduledTime.timezone}</div>
              </>
            ) : (
              '(None)'
            )}
          </BasicDetailRow>

          {scheduledUsers && (
            <BasicDetailRow label={scheduledUsers.length > 1 ? 'Scheduled Users:' : 'Scheduled User:'}>
              {scheduledUsers.length > 0 ? (
                <>
                  <strong>{sentence(scheduledUsers.map(u => u.name))}</strong>
                  {hasCurrentUser && <div className="small">{scheduledUsers.map(u => u.email).join(' / ')}</div>}
                </>
              ) : (
                '(None)'
              )}
            </BasicDetailRow>
          )}
        </>
      )}

      {cancellation && (
        <>
          <BasicDetailRow label="Cancelled by:">
            <strong>{cancellation.cancelledBy}</strong>
          </BasicDetailRow>

          {cancellation.cancelledAt && (
            <BasicDetailRow label="Cancelled at:">
              <strong>{cancellation.cancelledAt.time}</strong> on <strong>{cancellation.cancelledAt.date}</strong>
              <div className="small">{cancellation.cancelledAt.timezone}</div>
            </BasicDetailRow>
          )}
          {cancellation.cancellationReason && (
            <BasicDetailRow label="Reason:">{cancellation.cancellationReason}</BasicDetailRow>
          )}
        </>
      )}

      {timeClosed && (
        <BasicDetailRow label="Closed at:">
          <strong>{timeClosed.time}</strong> on <strong>{timeClosed.date}</strong>
          <div className="small">{timeClosed.timezone}</div>
        </BasicDetailRow>
      )}
    </>
  );
};

export default BasicDetails;

import type { FC } from 'react';

const BaseError: FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <div className="row mt-minus-3 mb-3">
      <div className="col-sm-10 offset-md-2">
        <div className="invalid-feedback d-block">{errorMessage}</div>
      </div>
    </div>
  );
};

export default BaseError;

import { type FC, useContext } from 'react';

import Header from '@/Header';
import PlanContext from '@/PlanContext';
import Flash from '@/Flash';
import asScreen from '@/Screen';
import Form from '@/Form';
import Row from '@/ui/Row';
import type { MailSendingConfigurationProps } from '@/screens/EmailSettings/Show/MailSendingConfiguration';
import TextInputRow from '@/ui/TextInputRow';
import AccountExternalEmail from '@/AccountExternalEmail';

import MailSendingMode from './MailSendingMode';
import EmailTemplates from './EmailTemplates';
import UpgradePrompt from './UpgradePrompt';
import DefaultEmailTemplate from './DefaultEmailTemplate';
import CustomDomains from './CustomDomains';

import type { EmailTemplateModel } from '@models/EmailTemplateModel';
import type DomainModel from '@models/Domain';

interface Props {
  domains: DomainModel[];
  emailTemplates: EmailTemplateModel[];
  externalEmailAddress: { useSingleExternalEmail: boolean; singleExternalEmail: string | null };
  errors: Record<string, string>;
  mailSendingConfiguration: MailSendingConfigurationProps;
  replyToAddress: string;
}

const EmailSettings: FC<Props> = ({
  domains,
  emailTemplates,
  externalEmailAddress,
  errors,
  mailSendingConfiguration,
  replyToAddress,
}) => {
  const { allowsCustomEmails, allowsCustomerSmtpServer } = useContext(PlanContext);

  return (
    <>
      {allowsCustomEmails ? (
        <Header
          title="Email Settings"
          headerButton={{
            url: '/settings/email_templates/new',
            color: 'blue',
            text: 'New Email Template',
            icon: 'plus',
          }}
        />
      ) : (
        <Header title="Email Settings" />
      )}

      <Flash />
      <div id="email-templates">
        {allowsCustomEmails ? <EmailTemplates emailTemplates={emailTemplates} /> : <UpgradePrompt />}
      </div>

      <hr />

      {allowsCustomEmails && <DefaultEmailTemplate />}

      <hr />

      {allowsCustomerSmtpServer && (
        <>
          <CustomDomains domains={domains} />
          <hr />
        </>
      )}

      <Form url="/settings/email" method="patch">
        <MailSendingMode
          allowsCustomerSmtpServer={allowsCustomerSmtpServer}
          domains={domains}
          error={errors.fromLocalPart}
          mailSendingConfiguration={mailSendingConfiguration}
        />

        <TextInputRow
          name="account[reply_to_address]"
          label="Reply-To Address"
          helpText="Emails sent by TimeZest to your clients will have this email address set as the 'Reply-To' address."
          value={replyToAddress}
          error={errors.replyToAddress}
        />

        <AccountExternalEmail
          error={errors.singleExternalEmail}
          useSingleExternalEmail={externalEmailAddress.useSingleExternalEmail}
          singleExternalEmail={externalEmailAddress.singleExternalEmail}
        />

        <hr />

        <Row>
          <input type="submit" value="Save Changes" className="btn btn-primary" data-disable-with="Save Changes" />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(EmailSettings);

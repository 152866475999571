import { useEffect, useRef, type RefObject } from 'react';

export default function useRefWithClickOutside<T extends HTMLElement>(callback: () => void): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOutside = (e: MouseEvent): void => {
    if (!ref.current?.contains(e.target as Node)) {
      callback();
    }
  };

  return ref;
}

import type { ContentBlock, ContentState } from 'draft-js';

export const getEntityStrategy =
  (type: string) =>
  (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();

      if (entityKey === null) {
        return false;
      }

      return contentState.getEntity(entityKey).getType() === type;
    }, callback);
  };

import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';
import { PlainText } from '@/utilities';

import Option from './Option';

import TextInput from '@shared/ui/Inputs/TextInput';
import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  displayName: string;
  error: string;
  readOnly: boolean;
  name: string;
  data: { name: string; value: string }[];
  value: number | string | undefined;
  loading: boolean;
  filterPlaceholder: string;
  emptyDataMessage: string;
  loadingMessage: string;
  queryErrorMessage?: string;
  onSearchTermChange: (value: string) => void;
  onChange?: (id: string, name: string) => void;
}

const SearchableDropdownMenu: FC<Props> = ({
  displayName: initialDisplayName,
  error,
  readOnly,
  name,
  loading,
  data,
  value: initialValue,
  filterPlaceholder,
  emptyDataMessage,
  loadingMessage,
  queryErrorMessage,
  onSearchTermChange,
  onChange,
}) => {
  const { handleDirty } = useContext(DirtyContext);
  const container = useRefWithClickOutside<HTMLDivElement>(() => setShowDropdown(false));

  const [value, setValue] = useStateFromProp(initialValue, initialValue => initialValue?.toString() || '');
  const [displayName, setDisplayName] = useStateFromProp(initialDisplayName);
  const [inputValue, setInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    setShowDropdown(!showDropdown && !readOnly);
  };

  const handleSelect = (id: string, name: string): void => {
    setInputValue('');
    setDisplayName(name);
    setValue(id);
    setShowDropdown(false);

    if (handleDirty) handleDirty();
    if (onChange) onChange(id, name);
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSearchTermChange(e.target.value);
    setInputValue(e.target.value);
  };

  if (readOnly) {
    return (
      <TextInput
        type="text"
        name="company-search-dropdown-button"
        className="form-select text-start"
        role="button"
        value={displayName}
        disabled={true}
        readOnly={true}
      />
    );
  }

  if (queryErrorMessage) {
    return <PlainText className="text-danger">{queryErrorMessage}</PlainText>;
  }

  if (!data.length && !loading && !inputValue) {
    return <PlainText className="text-danger">{emptyDataMessage}</PlainText>;
  }

  return (
    <div ref={container}>
      <input type="hidden" name={name} value={value || ''} />

      <TextInput
        type="text"
        name="company-search-dropdown-button"
        error={error}
        className="form-select bg-white text-start"
        role="button"
        value={displayName}
        readOnly={true}
        onClick={handleDropdownClick}
      />
      <div
        className={`dropdown-menu w-100 ${showDropdown ? 'dropdown-menu-visible' : 'dropdown-menu-hidden'}`}
        style={{ top: '36px' }}
      >
        <div className="pb-1 pe-3 ps-3 pt-1">
          <TextInput
            value={inputValue}
            name="company-search-filter"
            type="text"
            placeholder={filterPlaceholder}
            onChange={handleSearchTermChange}
          />
        </div>
        <div className="dropdown-divider" />
        {loading ? (
          <div className="dropdown-item">{loadingMessage}</div>
        ) : (
          data.map(item => (
            <Option key={item.value} id={item.value.toString()} name={item.name} onSelect={handleSelect} />
          ))
        )}
      </div>
    </div>
  );
};

export default SearchableDropdownMenu;

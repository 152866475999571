import type { FC } from 'react';

import SelectRow from '@/ui/SelectRow';
import PlainTextRow from '@/ui/PlainTextRow';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  taskType: string;
  templateMode?: boolean;
  onTaskTypeChange: (value: string) => void;
}

const HELP_TEXT = 'TimeZest will update the state for this task in ServiceNow.';
const LABEL = 'Task Type';

const OPTIONS = [
  { name: 'Select a task type...', value: '' },
  { name: 'Incident', value: 'incident' },
  { name: 'Problem', value: 'problem' },
  { name: 'Change Request', value: 'change_request' },
];

const TaskTypeSelect: FC<Props> = ({
  taskType,
  error,
  readOnly,
  templateMode,
  onTaskTypeChange: handleTaskTypeChange,
}) => {
  if (templateMode) {
    return (
      <PlainTextRow label={LABEL} helpText={HELP_TEXT}>
        To be selected when using this workflow template.
      </PlainTextRow>
    );
  }

  if (readOnly) {
    return (
      <PlainTextRow label={LABEL} helpText={HELP_TEXT}>
        {OPTIONS.find(opt => opt.value === taskType)?.name || '(Unknown)'}
      </PlainTextRow>
    );
  }

  return (
    <SelectRow
      helpText={HELP_TEXT}
      label={LABEL}
      name="task_type"
      options={OPTIONS}
      value={taskType}
      error={error}
      onChange={(value: string) => handleTaskTypeChange(value)}
    />
  );
};

export default TaskTypeSelect;

import type { FC } from 'react';

import type { QuickEdit } from '.';

const QuickEdits: FC<{ quickEdits: QuickEdit[] }> = ({ quickEdits }) => {
  return (
    <>
      {quickEdits.length > 0 && (
        <div>
          <hr />
          <h5>Quick Edits</h5>
          <p>
            Easily add new behavior to multiple appointment types with Quick Edits, which you can add new triggers and
            actions to workflows with just a few clicks.
          </p>
          {quickEdits.map((qe, i) => (
            <div key={i} className="d-flex align-items-center mb-2">
              <div className="flex-grow-1">
                <strong>{qe.name}</strong>
                <div className="text-muted">
                  <div className="small">{qe.description}</div>
                </div>
              </div>
              <a className="btn btn-primary" href={qe.url}>
                Add to Workflows &gt;&gt;
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default QuickEdits;

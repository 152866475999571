import { useEffect, useRef } from 'react';

export default function useRefs<T>(size: number): [(i: number) => T | null, (i: number) => (el: T) => void] {
  const refs = useRef<Array<T | null>>([]);

  const get = (i: number): T | null => refs.current[i];

  const set =
    (i: number) =>
    (el: T): void => {
      refs.current[i] = el;
    };

  useEffect(() => {
    refs.current = refs.current.slice(0, size);
  }, [size]);

  return [get, set];
}

import type { FC } from 'react';
import { useMemo, useState } from 'react';

import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import reduce from 'lodash/reduce';
import snakecase from 'lodash/snakeCase';

import { Action } from '@/ui/Workflow/Actions';

import { useValidateQuickEditMutation } from '@graphql/generated';
import type Errors from '@models/Errors';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';
import useRefs from '@shared/hooks/useRefs';

interface Props {
  actions: { type: string; params: Record<string, any> }[];
  children: (submit: (e: React.MouseEvent) => void) => JSX.Element;
  readOnly: boolean;
  onSubmit: (props: { type: string; params: Record<string, any> }[]) => void;
}

const ActionsForm: FC<Props> = ({ actions: initialActions, children, readOnly, onSubmit }) => {
  const actions = useMemo(() => {
    return initialActions.map(({ type, params }) => {
      return { id: 0, triggerId: 1, reviewable: false, slug: type, ...params };
    });
  }, [initialActions]);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [getFormRef, setFormRef] = useRefs<HTMLFormElement>(initialActions.length);
  const [validateQuickEdit] = useValidateQuickEditMutation();

  const handleModeChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const props = initialActions.map(({ type }, i) => {
      const formRef = getFormRef(i);
      const data = new FormData(formRef || undefined);
      const params = reduce(
        Object.fromEntries(data.entries()),
        (props, val: any, attr: string) => {
          props[camelCase(attr)] = val;
          return props;
        },
        {}
      );
      return { type, params };
    });

    validateQuickEdit({ variables: { actions: props } }).then(res => {
      const errors = res.data?.validateQuickEdit.errors || [];
      const valid = errors.every((e: Errors) => isEmpty(e));

      setErrors(errors as Errors[]);

      if (valid) {
        const attributes = props.map(p => {
          return { type: p.type, params: mapKeys(p.params, (_, k) => snakecase(k)) };
        });
        onSubmit(attributes);
      }
    });
  };

  return (
    <WorkflowActionContext.Provider
      value={{
        expandable: false,
        actionReviewable: false,
        templateMode: false,
        initialExpanded: 'all',
      }}
    >
      {actions.map((action, i) => (
        <Action
          key={action.slug}
          action={action}
          errors={errors[i]}
          readOnly={readOnly}
          ref={setFormRef(i)}
          saveable={false}
        />
      ))}
      {!readOnly && children(handleModeChange)}
    </WorkflowActionContext.Provider>
  );
};

export default ActionsForm;

import difference from 'lodash/difference';
import omit from 'lodash/omit';
import times from 'lodash/times';
import invariant from 'ts-invariant';

import { generateId } from '../utilities';
import type { IndexedMjmlNode } from '../EmailTemplate';

import type { TemplateState } from './templateReducer';
import { getElementFromIndex } from './utilities';

function decreaseNumberOfColumns(
  state: TemplateState,
  parentNode: IndexedMjmlNode,
  numberOfColumnDesired: number
): TemplateState {
  const columnIdsToRemove = parentNode.childIds.slice(numberOfColumnDesired, parentNode.childIds.length);
  const newParentNode = { ...parentNode, childIds: difference(parentNode.childIds, columnIdsToRemove) };

  return {
    ...state,
    index: {
      ...omit(state.index, columnIdsToRemove),
      [parentNode.id]: newParentNode,
    },
  };
}

function increaseNumberOfColumns(
  state: TemplateState,
  parentNode: IndexedMjmlNode,
  numberOfColumnDesired: number
): TemplateState {
  const columnsToAdd = numberOfColumnDesired - parentNode.childIds.length;
  const lastColumn = state.index[parentNode.childIds[parentNode.childIds.length - 1]];

  invariant(lastColumn, 'Section found without existing column to take existing properties from.');
  invariant(lastColumn.type === 'mjml-column', 'Non-column element found in section.');

  const { backgroundColor } = lastColumn;

  const newColumns = times(columnsToAdd, () => {
    return { type: 'mjml-column', id: generateId(), parentId: parentNode.id, childIds: [], backgroundColor };
  });

  const newParentNode = {
    ...parentNode,
    childIds: [...parentNode.childIds, ...newColumns.map(col => col.id)],
  };

  const newIndexEntries = newColumns.reduce((index, column) => {
    index[column.id] = column;
    return index;
  }, {});

  return {
    ...state,
    index: { ...state.index, [newParentNode.id]: newParentNode, ...newIndexEntries },
  };
}

const changeNumberOfColumns: (
  state: TemplateState,
  parentId: string,
  numberOfColumnDesired: number
) => TemplateState = (state, parentId, numberOfColumnDesired) => {
  const mjmlSection = getElementFromIndex(state, parentId);

  if (numberOfColumnDesired === mjmlSection.childIds.length) return state;

  if (numberOfColumnDesired < mjmlSection.childIds.length) {
    return decreaseNumberOfColumns(state, mjmlSection, numberOfColumnDesired);
  } else {
    return increaseNumberOfColumns(state, mjmlSection, numberOfColumnDesired);
  }
};

export default changeNumberOfColumns;

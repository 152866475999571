import { type FC, useEffect, useState } from 'react';

import cable from '@/cable';

type TestMessage = {
  text: string;
};

const WebsocketTest: FC<{}> = () => {
  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    const subscription = cable.subscribeTo('TestChannel');
    const subscription2 = cable.subscribeTo('TimeStash::AccountChannel');

    subscription.on('message', (message: TestMessage) => {
      console.log(message);
      setMessages([...messages, message.text]);
    });

    subscription2.on('message', message => {
      console.log(message);
      setMessages([...messages, JSON.stringify(message)]);
    });

    return () => {
      subscription.disconnect();
      subscription2.disconnect();
    };
  }, [messages]);

  return (
    <div>
      {messages.map((msg, index) => (
        <div key={`msg${index}`}>{msg}</div>
      ))}
    </div>
  );
};

export default WebsocketTest;

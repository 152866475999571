import { useContext, type FC } from 'react';

import Alert from '@/Alert';
import asScreen from '@/Screen';
import PlanContext from '@/PlanContext';
import Header from '@/Header';

import AppointmentTypeTemplate from './AppointmentTypeTemplate';

import type { AppointmentTypeTemplate as AppointmentTypeTemplateModel } from '@models/AppointmentTypeTemplate';

interface Props {
  templates: AppointmentTypeTemplateModel[];
  availableIntegrations: string[];
}

const AppointmentTypeTemplates: FC<Props> = ({ templates, availableIntegrations }) => {
  const plan = useContext(PlanContext);

  return (
    <>
      <Header title="Appointment Type Templates" />
      <Alert
        icon="lightbulb"
        color="blue"
        className="mb-4"
        content="TimeZest's Appointment Type Templates are pre-built
    appointment types covering common use cases, which can be copied into your account, and customized to suit your business."
      />
      <div className="d-grid gap-3">
        {templates.map(t => (
          <AppointmentTypeTemplate
            key={t.slug}
            template={t}
            availableIntegrations={availableIntegrations}
            allowsOnlineMeetings={plan.allowsOnlineMeetings}
          />
        ))}
      </div>
    </>
  );
};

export default asScreen(AppointmentTypeTemplates);

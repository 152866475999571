import { type FC, useContext } from 'react';

import AccountContext from '@/AccountContext';
import Flash from '@/Flash';
import Header from '@/Header';
import asScreen from '@/Screen';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const UpgradePrompt: FC = () => {
  const { psaType } = useContext(AccountContext);

  return (
    <>
      <Header title="Teams" />
      <Flash />
      <div className="card">
        <div className="card-body">
          <h5 className="text-danger">Team Scheduling is only available to TimeZest Professional subscribers.</h5>
          <p>
            {psaType === 'autotask' && <p>With team scheduling, you can define teams in TimeZest directly.</p>}
            {psaType === 'connect_wise' && (
              <p>
                With team scheduling, you can define teams in TimeZest, or use existing service or project teams from
                ConnectWise PSA.
              </p>
            )}
            TimeZest will automatically allocate appointments to an available member of the team when your clients
            select a time.
          </p>
          <p className="mb-0">
            <a href="/settings/account" className="btn btn-danger">
              Upgrade Now &nbsp;
              <FontAwesomeIcon icon="angle-right" />
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default asScreen(UpgradePrompt);

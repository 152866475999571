import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import styled from 'styled-components';

import { useAppDispatch } from '../hooks';
import { setDraggingElement } from '../actions';

const NewTool = styled.button`
  border: 2px solid var(--tz-grey-300);
  background: var(--tz-grey-100);
  width: 100%;
  height: 115px;
  padding: 15px 5px;
  margin: 0;
  text-align: center;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
`;

const NewToolCard = forwardRef<HTMLButtonElement, PropsWithChildren>((props, ref) => {
  const dispatch = useAppDispatch();

  return (
    <NewTool
      type="button"
      ref={ref}
      {...props}
      onMouseDown={() => dispatch(setDraggingElement({ draggingElement: true }))}
    >
      {props.children}
    </NewTool>
  );
});

export default NewToolCard;

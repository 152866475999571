import type { FC } from 'react';
import { useContext, useRef, useState } from 'react';

import classNames from 'classnames';

import { EmailTemplateEditor } from '@/EmailEditor';
import Form from '@/Form';
import { getCSRFToken } from '@/utilities';
import CurrentUserContext from '@/CurrentUserContext';

import AppointmentTypesList from './AppointmentTypesList';

import type { EmailTemplateModel } from '@models/EmailTemplateModel';
import LineEditor from '@shared/LineEditor';
import type VariableModel from '@models/Variable';
import VariablesContext from '@shared/VariablesContext';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import TextInput from '@shared/ui/Inputs/TextInput';
import Select from '@shared/ui/Select';

type Errors = Record<string, string[] | undefined>;
type TestEmailStatus = 'normal' | 'sending' | 'sent';

interface Props {
  availableLanguages: [string, string][];
  appointmentTypes: string[];
  availableVariables: VariableModel[];
  emailTemplate: EmailTemplateModel;
  logoUrl: string | null;
  url: string;
}

const EmailTemplate: FC<Props> = ({
  appointmentTypes,
  availableLanguages,
  availableVariables,
  emailTemplate,
  logoUrl,
  url,
}) => {
  const { email: currentUserEmail } = useContext(CurrentUserContext);

  const formRef = useRef<HTMLFormElement | null>(null);
  const [language, setLanguage] = useStateFromProp(emailTemplate.language);
  const [name, setName] = useStateFromProp(emailTemplate.name);
  const [previewErrors, setPreviewErrors] = useState<Errors>({});
  const [testEmailStatus, setTestEmailStatus] = useStateWithCallback<TestEmailStatus>('normal');

  const { templateType, readOnly, subjectTemplateJson, jsonTemplate, errors: initialErrors } = emailTemplate;

  const errors = { ...previewErrors, ...initialErrors };

  const sendPreview = () => {
    if (!formRef.current) return;

    const data = new FormData(formRef.current);
    const language = data.get('email_template[language]');
    const jsonTemplate = data.get('email_template[template_json]');
    const subjectTemplate = data.get('email_template[subject_template_json]');

    setPreviewErrors({});
    setTestEmailStatus('sending', () => {
      fetch('/settings/email_templates/test_email', {
        body: JSON.stringify({
          legacy: false,
          language: language,
          subject_template: subjectTemplate,
          template_json: jsonTemplate,
          template_type: templateType,
        }),
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': getCSRFToken() },
        method: 'POST',
      }).then((response: Response) => {
        switch (response.status) {
          case 200:
            setTestEmailStatus('sent');
            setTimeout(() => setTestEmailStatus('normal'), 5000);
            break;
          case 422:
            response.json().then(errors => setPreviewErrors(errors));
            setTestEmailStatus('normal');
            break;
          default:
            throw Error(response.statusText);
        }
      });
    });
  };

  return (
    <Form url={url} ref={formRef} method="patch">
      <div className="row">
        <div className="col-sm-8">
          <div className="mb-3">
            <label className="fw-bold" htmlFor="email_template_name">
              Name:
            </label>
            <TextInput
              id="email_template_name"
              name="email_template[name]"
              type="text"
              disabled={readOnly}
              value={name}
              error={error(errors, 'name')}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="fw-bold" htmlFor="email_template_subject_template">
              Subject Line:
            </label>
            <VariablesContext.Provider value={{ variables: availableVariables }}>
              <LineEditor
                name="email_template[subject_template_json]"
                initialValue={subjectTemplateJson}
                readOnly={readOnly}
                error={error(errors, 'subject_template_json')}
              />
            </VariablesContext.Provider>
          </div>
          <div className="mb-3">
            <label className="fw-bold" htmlFor="email_template_language">
              Language:
            </label>
            <Select
              id="email_template_language"
              error={error(errors, 'language')}
              disabled={readOnly}
              name="email_template[language]"
              value={language}
              options={availableLanguages.map(([value, label]) => ({ name: label, value: value }))}
              onChange={e => setLanguage(e.target.value)}
            />
            <p className="text-muted small mb-0 mt-1">
              The language setting of an email template tells TimeZest how to translate the variables it inserts into
              the template.
            </p>
          </div>
        </div>
        <div className="col-sm-4">
          <AppointmentTypesList
            appointmentTypes={appointmentTypes}
            readOnly={readOnly}
            templateType={templateType}
            url={url}
          />
        </div>
      </div>
      <div className="mb-3">
        <label className="fw-bold" htmlFor="email_template_template_html">
          Email Body:
        </label>
        <div className={classNames('bg-light', { 'border-danger is-invalid border': !!errors.template_json })}>
          <EmailTemplateEditor
            availableVariables={availableVariables}
            initialTemplate={jsonTemplate}
            logoUrl={logoUrl}
            name="email_template[template_json]"
            readOnly={readOnly}
          />
        </div>
        <div className="invalid-feedback">{error(errors, 'template_json')}</div>
      </div>
      {!readOnly && (
        <div className="d-flex gap-2">
          <input type="submit" className="btn btn-primary" value="Save Changes" />
          <div>
            <button
              className="btn btn-outline-danger"
              disabled={testEmailStatus !== 'normal'}
              type="button"
              onClick={sendPreview}
            >
              {testEmailStatus === 'normal' && <>Send preview to {currentUserEmail}</>}
              {testEmailStatus === 'sending' && <>Sending...</>}
              {testEmailStatus === 'sent' && <>Preview sent to {currentUserEmail}</>}
            </button>
          </div>
        </div>
      )}
    </Form>
  );
};

function error(errors: Errors, name: string): string | undefined {
  return (errors[name] || [])[0];
}

export default EmailTemplate;

import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetMarketingCampaignsQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const CAMPAIGN_HELP_TEXT = 'TimeZest will create the opportunity in ConnectWise PSA with this marketing campaign.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  campaignId: string;
  templateMode?: boolean;
}

const CampaignSelect: FC<Props> = ({ campaignId: initialCampaignId, error, readOnly, templateMode }) => {
  const [campaignId, setCampaignId] = useStateFromProp(initialCampaignId);

  const queryResult = useGetMarketingCampaignsQuery({ skip: templateMode });

  const campaign = (queryResult.data?.connectWise.marketingCampaigns || []).find(s => s.id.toString() === campaignId);

  const handleCampaignChange = (value: string) => {
    if (value !== campaignId) {
      setCampaignId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      label="Campaign"
      helpText={CAMPAIGN_HELP_TEXT}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve marketing campaign details from ConnectWise PSA because the security role does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the <strong>Inquire</strong> ability is set to <strong>All</strong> for the{' '}
          <strong>Marketing Management</strong> permission in the <strong>Marketing</strong> section of the security
          role.
        </>
      }
      error="Something went wrong when TimeZest attempted to load marketing campaigns."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        helpText={CAMPAIGN_HELP_TEXT}
        label="Campaign"
        name="campaign_id"
        field="connectWise.marketingCampaigns"
        prompt="None"
        emptyDataMessage="No opportunity campaigns found."
        templateMode={templateMode}
        readOnly={readOnly}
        error={error}
        value={campaignId}
        queryErrorMessage={queryErrorMessage}
        queryResult={queryResult}
        onChange={handleCampaignChange}
      />

      <input type="hidden" name="campaign_name" value={campaign?.name || ''} />
    </>
  );
};

export default CampaignSelect;

import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';

type RequestRate = {
  completion?: number | null;
  open?: number | null;
};

interface Props {
  requestRatesAccount?: RequestRate;
  requestRatesAll?: RequestRate;
}

const RequestRatesTable: FC<Props> = ({ requestRatesAccount, requestRatesAll }) => {
  const { name: accountName } = useContext(AccountContext);

  return (
    <table className="table-transparent mb-0 mt-3 table">
      <thead>
        <tr>
          <th className="border-top-0 border-bottom ps-0"> &nbsp;</th>
          <th className="border-top-0 border-bottom text-center">{accountName || 'Your account'}</th>
          <th className="border-top-0 border-bottom pe-0 text-center">All TimeZest Customers</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th className="w-50 ps-0">
            Open Rate
            <div className="small fw-normal">
              The percentage of notification emails sent to clients which are actually opened.
            </div>
          </th>

          <td className="text-center">{formatRates(requestRatesAccount?.open)}</td>
          <td className="pe-0 text-center">{formatRates(requestRatesAll?.open)}</td>
        </tr>

        <tr>
          <th className="ps-0">
            Completion Rate
            <div className="small fw-normal">
              The percentage of notification emails which actually result in an appointment being scheduled.
            </div>
          </th>

          <td className="text-center">{formatRates(requestRatesAccount?.completion)}</td>
          <td className="pe-0 text-center">{formatRates(requestRatesAll?.completion)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default RequestRatesTable;

function formatRates(rate: number | null | undefined): String {
  if (!rate) return 'No data';

  return `${Number(rate.toFixed(1))}%`;
}

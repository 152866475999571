import type { FC } from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';

interface Props {
  tabs: string[];
}

// This solution is temporary, as we keep state in the DOM to be compatible with Turbolinks
const HorizontalTabs: FC<Props> = ({ tabs }) => {
  const tabIds = Object.keys(tabs).join(',');
  const [_, reRenderComponent] = useState({});

  useEffect(() => {
    if (!window.location.hash) return;

    const activeId = window.location.hash.slice(1);

    tabIds.split(',').forEach(id => {
      if (id !== activeId) {
        deactivateTab(id);
      }
    });

    activateTab(activeId);
  }, [tabIds]);

  const handleClick = (e: React.MouseEvent, targetId: string) => {
    e.preventDefault();

    Object.keys(tabs).forEach(id => {
      if (id === targetId) {
        activateTab(id);
      } else {
        deactivateTab(id);
      }
    });

    reRenderComponent({});
  };

  return (
    <ul className="nav nav-tabs" role="tablist">
      {Object.entries(tabs).map(([id, name]) => (
        <li key={id} className={classNames('nav-item')} role="presentation">
          <button
            className={classNames('nav-link px-3', {
              active: !document.getElementById(id)?.classList.contains('d-none'),
            })}
            key={`${id}-tab`}
            id={`${id}-tab`}
            onClick={e => handleClick(e, id)}
          >
            {name}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default HorizontalTabs;

function activateTab(id: string) {
  document.getElementById(id)?.classList.remove('d-none');
  document.getElementById(`${id}-tab`)?.classList.add('active');
}

function deactivateTab(id: string) {
  document.getElementById(id)?.classList.add('d-none');
  document.getElementById(`${id}-tab`)?.classList.remove('active');
}

import type { FC } from 'react';

import classNames from 'classnames';

interface Props {
  name: string;
  value: string | number;
  options: { name: string; value: string | number; key?: string; disabled?: boolean }[];
  ariaLabel?: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: FC<Props> = props => {
  const { ariaLabel, name, options, id, className, error, ...rest } = props;

  return (
    <>
      <select
        aria-label={ariaLabel}
        id={id || name}
        name={name}
        className={classNames('form-select', { 'is-invalid': !!error }, className)}
        {...rest}
      >
        {options.map(option => (
          <option value={option.value} key={option.key || option.value} disabled={option.disabled || false}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <p className="text-danger small mb-0 mt-1">{error}</p>}
    </>
  );
};

export default Select;

// DEPRECATED
import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import classNames from 'classnames';

import ExpanderIcon from './ExpanderIcon';
import ExpanderSummary from './ExpanderSummary';
import ExpanderHeader from './ExpanderHeader';
import ExpanderBody from './ExpanderBody';

import ErrorBoundary from '@shared/ErrorBoundary';

import { getCSRFToken } from '../../utilities';

interface Props {
  title: string;
  url?: string | null;
  method?: 'POST' | 'PATCH';
  icon: string;
  hasErrors: boolean;
  summary: string | JSX.Element;
  canExpand: boolean;
  dirty: boolean;
  expanded: boolean;
  children: ReactNode;
  onExpand: (expanded: boolean) => void;
  onDirtyStatusUpdate?: (isDirty: boolean) => void;
}

const Expander: FC<Props> = ({
  title,
  url,
  method,
  icon,
  hasErrors,
  summary,
  canExpand,
  dirty,
  expanded,
  children,
  onExpand,
  onDirtyStatusUpdate,
}) => {
  const [confirming, setConfirming] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hovering, setHovering] = useState(false);

  return (
    <ErrorBoundary
      error={
        <section className="card border-danger mb-2">
          <div className="card-header d-flex text-danger border-bottom-0">
            <ExpanderIcon icon={icon} />
            <ExpanderSummary title={title} summary="Oops! Something went wrong." />
          </div>
        </section>
      }
    >
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <section
        role={canExpand ? 'button' : 'none'}
        className={classNames('card mb-2', {
          'border-danger': hasErrors,
          'border-dark': expanded && !hasErrors,
          'highlight-blue': hovering,
        })}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <form
          action={url || ''}
          method="POST"
          onSubmit={() => {
            setDisabled(true);
          }}
        >
          <input type="hidden" value={method} name="_method" />
          <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
          <ExpanderHeader
            url={url}
            title={title}
            icon={icon}
            summary={summary}
            canExpand={canExpand}
            expanded={expanded}
            confirming={confirming}
            disabled={disabled}
            method={method}
            dirty={dirty}
            setConfirming={setConfirming}
            onExpand={onExpand}
            onDirtyStatusUpdate={onDirtyStatusUpdate}
          />
          <ExpanderBody
            url={url}
            dirty={dirty}
            expanded={expanded}
            confirming={confirming}
            disabled={disabled}
            method={method}
            setConfirming={setConfirming}
            onExpand={onExpand}
            onDirtyStatusUpdate={onDirtyStatusUpdate}
          >
            {children}
          </ExpanderBody>
        </form>
      </section>
    </ErrorBoundary>
  );
};

export default Expander;

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import ReactPaginate from 'react-paginate';

import { ErrorMessage } from '@/ui/ErrorMessage';

import AppointmentTypeSummary from './AppointmentTypeSummary';
import NoAppointmentTypes from './NoAppointmentTypes';

import { useGetAppointmentTypesTqlQuery } from '@graphql/generated';

const PAGE_SIZE = 21;

interface Props {
  tqlString: string;
}

const TqlAppointmentTypesList: FC<Props> = ({ tqlString }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [tqlString]);

  const { data, error, loading } = useGetAppointmentTypesTqlQuery({
    variables: { filter: tqlString, offset: offset, limit: PAGE_SIZE },
  });

  const requestsData = data?.timezest.appointmentTypes;

  if (!requestsData) return null;

  const { nodes: appointmentTypes, page: currentPage, pages: pageCount } = requestsData;

  if (loading) {
    return (
      <div className="d-flex mb-4">
        <h6 className="m-auto">Loading appointment types...</h6>
      </div>
    );
  }

  if (error) {
    return (
      <ErrorMessage>Something went wrong while retrieving appointment types. (Error: {error.message})</ErrorMessage>
    );
  }

  if (appointmentTypes.length === 0) {
    return <NoAppointmentTypes filtering={!!tqlString} />;
  }

  return (
    <div className="row gy-4">
      {appointmentTypes.map(appointmentType => (
        <AppointmentTypeSummary key={`appointmentType${appointmentType.id}`} appointmentType={appointmentType} />
      ))}

      {pageCount > 1 && (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={currentPage}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center mb-0 mt-3"
          disableInitialCallback={true}
          forcePage={currentPage - 1}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={({ selected }) => {
            setOffset(selected * PAGE_SIZE);
          }}
        />
      )}
    </div>
  );
};

export default TqlAppointmentTypesList;

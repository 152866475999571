import type { IndexedMjmlNode } from '../EmailTemplate';
import type { RootState } from '../Store';
import type { TemplateAction } from '../actions';

import changeNumberOfColumns from './changeNumberOfColumns';
import deleteElement from './deleteElement';
import deleteProperty from './deleteProperty';
import insertElement from './insertElement';
import loadTemplate from './loadTemplate';
import moveElement from './moveElement';
import updateProperty from './updateProperty';
import updateTextContent from './updateTextContent';

// Index type which allows us to go directly from an ID to the relevant
// node in the tree.
export type Index = Record<string, IndexedMjmlNode>;

export interface TemplateState {
  index: Index;
  rootId: string;
  editorId: string;
}

const INITIAL_STATE: TemplateState = {
  index: {},
  rootId: 'unknown',
  editorId: '',
};

const templateReducer: (state: TemplateState, action: TemplateAction) => TemplateState = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case 'CHANGE_NUMBER_OF_COLUMNS':
      return changeNumberOfColumns(state, action.payload.parentId, action.payload.numberOfColumnDesired);
    case 'INSERT_ELEMENT':
      return insertElement(
        state,
        action.payload.type,
        action.payload.parentId,
        action.payload.insertionIndex,
        action.payload.defaultProperties
      );
    case 'LOAD_TEMPLATE':
      return loadTemplate(action.payload.template);
    case 'MOVE_ELEMENT':
      return moveElement(state, action.payload.elementId, action.payload.parentId, action.payload.insertionIndex);
    case 'UPDATE_PROPERTY':
      return updateProperty(state, action.payload.elementId, action.payload.property, action.payload.value);
    case 'UPDATE_TEXT_CONTENT':
      return updateTextContent(state, action.payload.elementId, action.payload.content);
    case 'DELETE_ELEMENT':
      return deleteElement(state, action.payload.elementId);
    case 'DELETE_PROPERTY':
      return deleteProperty(state, action.payload.elementId, action.payload.property);
  }

  return state;
};

export default templateReducer;

export const selectTemplateIndex = (state: RootState) => state.template.index;

import type { FC } from 'react';

import TextInput from '@shared/ui/Inputs/TextInput';
import Select from '@shared/ui/Select';

import type { ValueControlProps } from '.';

const StandardValueControl: FC<ValueControlProps> = ({
  attribute,
  possibleValues,
  value,
  index,
  onKeyDown,
  onValueChange,
}) => {
  if (!attribute) return;

  return (
    <>
      {possibleValues[attribute.value].values.length < 1 ? (
        <TextInput
          name={`filter_value_text_${index}`}
          value={value}
          onChange={e => onValueChange(e.target.value)}
          onKeyDown={onKeyDown}
        />
      ) : (
        <Select
          name={`filter_value_option_${index}`}
          value={value}
          options={possibleValues[attribute.value].values}
          onChange={e => onValueChange(e.target.value, e.target.options[e.target.selectedIndex].text)}
        />
      )}
    </>
  );
};

export default StandardValueControl;

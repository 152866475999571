import { useContext, type FC } from 'react';

import PlanContext from '@/PlanContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  newPlanAllowsOffice365Calendars: boolean;
}

const Office365Calendars: FC<Props> = ({ newPlanAllowsOffice365Calendars }) => {
  const { allowsOffice365Calendars: currentPlanAllowsOffice365Calendars } = useContext(PlanContext);

  if (!newPlanAllowsOffice365Calendars && currentPlanAllowsOffice365Calendars) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp; You&apos;ll no longer be able to integrate TimeZest with Microsoft 365, and all users who have Microsoft
        365 as their only calendar will be deactivated.
      </div>
    );
  }

  if (newPlanAllowsOffice365Calendars && !currentPlanAllowsOffice365Calendars) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; You&apos;ll be able to integrate TimeZest with Microsoft 365, so that it can read availability from
        there, and write appointments to calendars there.
      </div>
    );
  }
};

export default Office365Calendars;

import type { FC } from 'react';

interface Props {
  appointmentTypeSlug: string | null;
  host: string | null;
  resourceSlugs: string | null | undefined;
}

const UrlError: FC<Props> = ({ appointmentTypeSlug, host, resourceSlugs }) => {
  if (!host) {
    return (
      <div className="small text-muted font-italic">
        To enable URL-based scheduling, choose a subdomain for your account. You can do this in Account & Billing.
      </div>
    );
  }

  if (!appointmentTypeSlug) {
    return (
      <div className="small text-muted font-italic">
        To enable URL-based scheduling, assign a URL slug for this appointment type.
      </div>
    );
  }

  if (!resourceSlugs) {
    return (
      <div className="small text-muted font-italic">
        To enable URL-based scheduling, assign a URL slug for this resource.
      </div>
    );
  }

  return null;
};

export default UrlError;

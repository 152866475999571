import { useState, type FC } from 'react';

import Form from '@/Form';
import RadioButtonRow from '@/ui/RadioButtonRow';
import Row from '@/ui/Row';
import PasswordInputRow from '@/ui/PasswordInputRow';

import InputGroup from '@shared/ui/Inputs/InputGroup';

import type { PartialDay } from '.';

interface Props {
  apiKey: string;
  errors: Record<string, string>;
  partialDay: PartialDay;
  persisted: boolean;
  subdomain: string;
}

const Settings: FC<Props> = ({ apiKey, errors, partialDay, persisted, subdomain: initialSubdomain }) => {
  const [subdomain, setSelectedSubdomain] = useState(initialSubdomain);
  const submitMethod = persisted ? 'patch' : 'post';

  return (
    <>
      <p>These are the credentials and settings that TimeZest will use to connect to your instance of BambooHR.</p>
      <Form url="/settings/integrations/bamboo_hr" method={submitMethod}>
        <PasswordInputRow
          label="API Key"
          name="bamboo_hr_integration[api_key]"
          value={apiKey}
          error={errors.apiKey}
          width={6}
        />

        <Row label="Subdomain" width={6} labelFor="bamboo_hr_integration[subdomain]">
          <InputGroup
            append=".bamboohr.com"
            prepend="https://"
            name="bamboo_hr_integration[subdomain]"
            value={subdomain}
            error={errors.subdomain}
            onChange={e => setSelectedSubdomain(e.target.value)}
          />
        </Row>

        <RadioButtonRow
          label="Partial Day Handling"
          name="bamboo_hr_integration[partial_day]"
          value={partialDay}
          options={[
            {
              label: 'Allow appointments to be booked on days where less than a full day of PTO is approved.',
              value: 'available',
            },
            {
              label: 'Don’t allow appointments to be booked on days where less than a full day of PTO is approved.',
              value: 'blocked',
            },
          ]}
        />

        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default Settings;

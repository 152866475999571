import type { FC } from 'react';

import isEmpty from 'lodash/fp/isEmpty';
import { differenceInDays } from 'date-fns/differenceInDays';
import { formatISO } from 'date-fns/formatISO';
import { add } from 'date-fns/add';
import classNames from 'classnames';

import DataLoader from '@/DataLoader';
import UsageChart from '@/UsageChart';

import RequestRatesTable from './RequestRatesTable';

import { useGetRequestRatesQuery } from '@graphql/generated';
import type { RequestUsage } from '@graphql/generated';

interface Props {
  tqlString: string;
  timeRange: { from: Date; to: Date };
}

const RequestRatesStats: FC<Props> = ({ tqlString, timeRange }) => {
  const { data: stats, error, loading } = useGetRequestRatesQuery({ variables: { tqlString } });

  const data = stats?.timezest;
  const hasRequestUsage = !isEmpty(data?.requestUsage);

  return (
    <div>
      <div className={classNames('d-flex mb-4', error ? 'h-auto' : 'graph-container')}>
        <DataLoader
          error={error && `Something went wrong while retrieving statistics (Error: ${error.message}).`}
          loading={loading && !data && 'Loading stats...'}
        >
          {data && (
            <UsageChart
              labelsByKey={{
                scheduledCount: 'Appointments Booked',
                cancelledCount: 'Cancelled Appointments',
                unrespondedCount: 'No Response',
              }}
              data={generateChartData(hasRequestUsage, data.requestUsage, timeRange)}
            />
          )}
        </DataLoader>
      </div>

      <RequestRatesTable requestRatesAccount={data?.requestRatesAccount} requestRatesAll={data?.requestRatesAll} />
    </div>
  );
};

export default RequestRatesStats;

function generateChartData(
  hasRequestUsage: boolean,
  requestUsage: RequestUsage[],
  timeRange: { from: Date; to: Date }
): {
  stats: {
    cancelledCount: number;
    scheduledCount: number;
    unrespondedCount: number;
  };
  date: string;
}[] {
  if (hasRequestUsage) {
    return requestUsage.map(({ date, __typename, ...rest }) => ({
      stats: { ...rest },
      date,
    }));
  } else {
    return buildMockRequestData(timeRange);
  }
}

function buildMockRequestData(timeRange: { from: Date; to: Date }) {
  const numberOfDates = differenceInDays(timeRange.to, timeRange.from);

  const data: {
    stats: {
      cancelledCount: number;
      scheduledCount: number;
      unrespondedCount: number;
    };
    date: string;
  }[] = [];

  for (let i = 0; i <= numberOfDates; i++) {
    const date = add(new Date(timeRange.from), { days: i });

    data.push({
      stats: {
        cancelledCount: 0,
        scheduledCount: 0,
        unrespondedCount: 0,
      },
      date: formatISO(date, { representation: 'date' }),
    });
  }

  return data;
}

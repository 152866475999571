import type { FC } from 'react';
import { Fragment, useMemo } from 'react';

import { Chart } from 'react-chartjs-2';
import type { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';

import type { HistoricalUsageData } from '@models/HistoricalUsage';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  data: HistoricalUsageData[];
  toggleOpenTable: boolean;
}

const CHART_OPTIONS: ChartOptions = {
  plugins: {
    legend: { position: 'bottom' as const },
  },
  responsive: true,
  scales: {
    x: { stacked: true },
    y: { stacked: false },
  },
  maintainAspectRatio: false,
};

const HistoricalUsageDetails: FC<Props> = ({ data, toggleOpenTable }) => {
  const sortedData = useMemo(() => {
    return data.sort((a, b) => {
      return new Date(a.month).getTime() - new Date(b.month).getTime();
    });
  }, [data]);

  const chartData = useMemo(() => {
    return {
      labels: sortedData.map(item =>
        new Date(item.month).toLocaleString('default', { month: 'short', year: 'numeric', timeZone: 'UTC' })
      ),
      datasets: [
        {
          type: 'line' as const,
          label: 'Scheduled Appointments per User',
          data: sortedData.map(item => item.scheduled_requests / item.active_users || 0.0),
          borderColor: 'rgb(255,255,255)',
          backgroundColor: 'rgb(255,255,255)',
        },
        {
          type: 'bar' as const,
          label: 'Booked Appointments',
          data: sortedData.map(item => item.scheduled_requests),
          backgroundColor: 'rgb(75, 192, 192)',
          stack: 'Stack 0',
        },
        {
          type: 'bar' as const,
          label: 'Scheduling Requests',
          data: sortedData.map(item => item.total_requests),
          backgroundColor: 'rgb(255, 99, 132)',
          stack: 'Stack 0',
        },
      ],
    };
  }, [sortedData]);

  const tableDetails = useMemo(() => {
    return sortedData.map((item, key) => {
      return (
        <Fragment key={key}>
          <p className="col-sm-2">
            {new Date(item.month).toLocaleString('default', { month: 'short', year: 'numeric', timeZone: 'UTC' })}
          </p>
          <p className="col-sm-2">{item.active_users}</p>
          <p className="col-sm-2">{item.total_requests}</p>
          <p className="col-sm-2">{item.scheduled_requests}</p>
          <p className="col-sm-2">
            {item.active_users > 1 && item.total_requests > 1
              ? (item.total_requests / item.active_users).toFixed(1)
              : '—'}
          </p>
          <p className="col-sm-2">
            {item.active_users > 1 && item.scheduled_requests > 1
              ? (item.scheduled_requests / item.active_users).toFixed(1)
              : '—'}
          </p>
        </Fragment>
      );
    });
  }, [sortedData]);

  return (
    <>
      {toggleOpenTable ? (
        <div className="row">
          <p className="col-sm-2 fw-bold">Month</p>
          <p className="col-sm-2 fw-bold">Active Users</p>
          <p className="col-sm-2 fw-bold">Scheduling Requests</p>
          <p className="col-sm-2 fw-bold">Appointments</p>
          <p className="col-sm-2 fw-bold">Scheduling Requests/User</p>
          <p className="col-sm-2 fw-bold">Appointments/User</p>
          {tableDetails}
        </div>
      ) : (
        <div className="row">
          <div style={{ height: '300px' }} className="col-sm-12">
            <Chart type="bar" options={CHART_OPTIONS} data={chartData} />
          </div>
        </div>
      )}
    </>
  );
};

export default HistoricalUsageDetails;

import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';
import { DirtyContext } from '@/DirtyContext';

import type { EmailTemplate } from '@graphql/generated';
import { useGetEmailTemplatesQuery } from '@graphql/generated';

interface Props {
  name: string;
  value: number | undefined;
  label: string;
  helpText: string;
  error?: string;
  readOnly?: boolean;
  templateMode?: boolean;
  templateType: 'all' | 'confirmation' | 'cancellation' | 'notification' | 'reminder';
  onChange?: (emailTemplate: EmailTemplate | undefined) => void;
  onNameChange?: (name: string) => void;
}

const EmailTemplateSelect: FC<Props> = ({
  name,
  value,
  error,
  readOnly,
  label,
  helpText,
  templateType,
  templateMode,
  onChange,
  onNameChange,
}) => {
  const queryResult = useGetEmailTemplatesQuery({ variables: { type: templateType }, skip: templateMode });

  const { handleDirty } = useContext(DirtyContext);

  const requestsData = queryResult.data?.timezest;

  useEffect(() => {
    if (onChange) {
      if (!value) {
        onChange(requestsData?.emailTemplates?.[0]);
      } else {
        onChange(requestsData?.emailTemplates?.find(template => template.id === value));
      }
    }
  }, [value, requestsData?.emailTemplates, onChange]);

  const handleOnChange = function (value: string): void {
    if (handleDirty) handleDirty();

    const templateId = parseInt(value, 10);

    if (onNameChange && queryResult.data) {
      const name = requestsData?.emailTemplates?.find(template => template.id === templateId)?.name;
      onNameChange(name || 'Unknown Email Template');
    }

    if (onChange) {
      onChange(requestsData?.emailTemplates?.find(template => template.id === templateId));
    }
  };

  return (
    <SelectRowFromGraphQL
      field="timezest.emailTemplates"
      templateMode={templateMode}
      name={name}
      label={label}
      helpText={helpText}
      readOnly={readOnly}
      error={error}
      value={value?.toString()}
      queryResult={queryResult}
      queryErrorMessage="Error loading email templates."
      onChange={handleOnChange}
    />
  );
};

export default EmailTemplateSelect;

import type { FC } from 'react';
import { useContext } from 'react';

import PlanContext from '@/PlanContext';
import AccountContext from '@/AccountContext';

import { displayBusinessDuration } from '@shared/utilities';
import type { PsaType } from '@models/Integration';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useDisplayDuration from '@shared/hooks/useDisplayDuration';
import type { AppointmentType } from '@graphql/generated';
import type { DurationUnit } from '@models/AppointmentType';

const ICON_REFERENCE = {
  0: 'circle-plus',
  1: 'calendar',
  2: 'bell',
  3: 'clock',
};

interface Props {
  appointmentType: AppointmentType;
}

const Description: FC<Props> = ({ appointmentType }) => {
  const currentPlan = useContext(PlanContext);
  const { psaType } = useContext(AccountContext);

  const description: string[] = [];
  const displayDuration = useDisplayDuration();
  const videoCallingModes = appointmentType.videoCallingModes?.map(v => v.type);

  let bufferTime = '';
  let shareableUrlAppointmentTypeAndVideoCallingModes = appointmentMode(psaType, appointmentType);
  let minimumNotice = '';
  let availabilityMode = '';

  if (appointmentType.minimumNoticeDuration && appointmentType.minimumNoticeDuration > 0) {
    minimumNotice += `${displayBusinessDuration(
      appointmentType.minimumNoticeDuration,
      appointmentType.minimumNoticeUnit as DurationUnit
    )} minimum notice.`;
  } else {
    minimumNotice += 'No minimum notice.';
  }

  if (!appointmentType.bufferAfterMins && !appointmentType.bufferBeforeMins) {
    bufferTime += 'No buffer times defined.';
  }

  if (appointmentType.bufferBeforeMins && appointmentType.bufferBeforeMins > 0) {
    bufferTime += `${displayDuration(appointmentType.bufferBeforeMins)} buffer before${
      appointmentType.bufferAfterMins ? '' : '.'
    }`;
  }

  if (appointmentType.bufferAfterMins && appointmentType.bufferAfterMins > 0) {
    bufferTime += `${appointmentType.bufferBeforeMins ? ' and ' : ''} ${displayDuration(
      appointmentType.bufferAfterMins
    )} buffer after.`;
  }

  if (currentPlan.allowsOnlineMeetings && videoCallingModes?.includes('zoom_video')) {
    shareableUrlAppointmentTypeAndVideoCallingModes += ' with Zoom online meeting.';
  } else if (currentPlan.allowsOnlineMeetings && videoCallingModes?.includes('teams_video')) {
    shareableUrlAppointmentTypeAndVideoCallingModes += ' with Microsoft Teams online meeting.';
  } else {
    shareableUrlAppointmentTypeAndVideoCallingModes += '.';
  }

  if (currentPlan.allowsCustomAvailability && appointmentType.availabilityMode === 'combine') {
    availabilityMode += 'Has availability that combines with resource availability.';
  } else if (currentPlan.allowsCustomAvailability && appointmentType.availabilityMode === 'override') {
    availabilityMode += 'Overrides resource availability.';
  } else {
    availabilityMode += 'Uses resource availability.';
  }

  description.push(shareableUrlAppointmentTypeAndVideoCallingModes, availabilityMode, minimumNotice, bufferTime);

  return (
    <ul className="appointment-type-description-list small text-muted list-unstyled position-relative mt-3">
      {description.map((des, index) => (
        <li key={des} className="d-flex align-items-start justify-content-start m-0 mb-2 p-0">
          <FontAwesomeIcon
            className="me-2 ms-1"
            icon={ICON_REFERENCE[index]}
            color="#aaa"
            style={{ marginTop: '3px', minWidth: '15px' }}
          />
          <span>{des}</span>
        </li>
      ))}
    </ul>
  );
};

function appointmentMode(psaType: PsaType, appointmentType: AppointmentType): string {
  switch (psaType) {
    case 'autotask':
      return appointmentType.shareableUrlAppointmentType === 'activity'
        ? 'Creates a company to-do'
        : 'Creates a service call';
    case 'connect_wise':
      return appointmentType.shareableUrlAppointmentType === 'activity'
        ? 'Creates an activity'
        : 'Creates a schedule entry';
    case 'halo_psa':
      return 'Creates an appointment';
    case 'office_365':
    case 'service_now':
      return 'Creates an Office 365 Event';
    default:
      throw new Error(`${psaType} is not supported`);
  }
}

export default Description;

import type { FC } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';
import PlainTextRow from '@/ui/PlainTextRow';

import { useGetSitesForHaloPsaQuery } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  clientId: string | undefined;
  siteId: string | undefined;
  error: string;
  disabled: boolean;
  readOnly: boolean;
  templateMode: boolean;
}

const HELP_TEXT = 'TimeZest will create a new user in HaloPSA with this site.';

const SiteSelect: FC<Props> = ({ clientId, siteId: initialSiteId, error, disabled, readOnly, templateMode }) => {
  const [siteId, setSiteId] = useStateFromProp(initialSiteId);

  const queryResult = useGetSitesForHaloPsaQuery({
    variables: { clientId: Number(clientId) || 0 },
    skip: templateMode || !clientId,
  });

  const sites = queryResult.data?.haloPsa.sites || [];
  const selectedSite = sites.find(s => s.id.toString() === siteId);

  const handleSelectSite = (newSiteId: string) => {
    if (siteId !== newSiteId) {
      setSiteId(newSiteId);
    }
  };

  if (!clientId) {
    return (
      <PlainTextRow label="Site" helpText={HELP_TEXT}>
        Select a customer to see available sites.
      </PlainTextRow>
    );
  }

  const options = (s: { id: number; name: string }) => {
    return { value: s.id.toString(), name: s.name || 'Main' };
  };

  return (
    <div>
      <SelectRowFromGraphQL
        label="Site"
        field="haloPsa.sites"
        name="psa_site_id"
        emptyDataMessage="No sites found."
        error={error}
        value={siteId}
        disabled={disabled}
        readOnly={readOnly}
        templateMode={templateMode}
        helpText={HELP_TEXT}
        queryResult={queryResult}
        queryErrorMessage="Error loading sites from HaloPSA."
        loadingMessage="Loading sites from HaloPSA."
        optionRenderer={options}
        onChange={handleSelectSite}
      />
      <input type="hidden" name="psa_site_name" value={selectedSite?.name || 'Main'} />
    </div>
  );
};

export default SiteSelect;

import type { FC } from 'react';
import { useState } from 'react';

import Stats from '../ui/Stats';
import { ErrorMessage } from '../ui/ErrorMessage';
import asScreen from '../Screen';

import { getDateDaysBeforeNow } from '@shared/time';
import DateRangePicker from '@ui/DateRangePicker';
import { useGetResourceStatsQuery } from '@graphql/generated';

interface Props {
  name: string;
  resourceId: string;
}

const UsageStats: FC<Props> = ({ name, resourceId }) => {
  const [timeRange, setTimeRange] = useState({
    from: getDateDaysBeforeNow(14),
    to: new Date(),
  });
  const [isCalendarErrorVisible, setIsCalendarErrorVisible] = useState(false);

  const handleChangeTimeRange = ({ to, from }: { to: Date; from: Date }) => {
    if (to.getDate() === from.getDate()) {
      setIsCalendarErrorVisible(true);
      return;
    }

    setTimeRange({ to, from });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h6 className="fw-bold mb-0">TimeZest Usage - {name}</h6>

        <div className="d-flex justify-content-end mb-1 flex-row-reverse bg-white">
          <DateRangePicker
            from={timeRange.from}
            to={timeRange.to}
            onClickDay={() => setIsCalendarErrorVisible(false)}
            onChangeTimeRange={handleChangeTimeRange}
          />
        </div>
      </div>

      {isCalendarErrorVisible && <ErrorMessage className="fw-bold mb-3">Please select at least 2 days</ErrorMessage>}

      <Stats query={useGetResourceStatsQuery} resourceId={resourceId} timeRange={timeRange} />
    </>
  );
};

export { UsageStats };
export default asScreen(UsageStats); // TODO: Remove this export once all screens are cleaned up to have only one React entry point.

import { type FC, useContext } from 'react';

import AccountContext from '@/AccountContext';

import { hasIntegrationCapability, psaName } from '@shared/utilities';

const TechnicianConfirmationVariables: FC = () => {
  const { name, integrations, psaType } = useContext(AccountContext);
  const canAccessTicketing = hasIntegrationCapability(integrations, 'canAccessTicketing');

  return (
    <div>
      <p>TimeZest can replace the values of the following variables when it sends the email:</p>
      <small>
        <div>
          <code>{'{{ account.name }}'}</code> &ndash; The name of your TimeZest account &ndash; i.e. &quot;{name}&quot;
        </div>
        <div>
          <code>{'{{ account.logo_url }}'}</code> &ndash; The URL to display your TimeZest account logo configured on
          the Branding page.
        </div>
        <div>
          <code>{'{{ appointment.agreed_time }}'}</code> &ndash; The time the client has selected, in their timezone.
          e.g. &quot;3:30PM on Monday, 12 August 2019&quot;.
        </div>
        <div>
          <code>{'{{ appointment.client_company }}'}</code> &ndash; The client&apos;s company name.
        </div>
        <div>
          <code>{'{{ appointment.client_email }}'}</code> &ndash; The client&apos;s email.
        </div>
        <div>
          <code>{'{{ appointment.client_name }}'}</code> &ndash; The client&apos;s full name.
        </div>
        <div>
          <code>{'{{ appointment.client_first_name }}'}</code> &ndash; The client&apos;s first name.
        </div>
        <div>
          <code>{'{{ appointment.client_last_name }}'}</code> &ndash; The client&apos;s last name.
        </div>
        <div>
          <code>{'{{ appointment.duration }}'}</code> &ndash; Configured duration, e.g. &quot;30 minutes&quot; or
          &quot;2.5 hours&quot;.
        </div>
        <div>
          <code>{'{{ appointment.issue_description }}'}</code> &ndash; Description of the issue for the appointment.
        </div>
        <div>
          <code>{'{{ appointment.scheduled_member_email }}'}</code> &ndash; The email address of the scheduled member.
        </div>
        <div>
          <code>{'{{ appointment.scheduled_member_name }}'}</code> &ndash; The name of the scheduled member.
        </div>
        <div>
          <code>{'{{ appointment.rescheduling_url }}'}</code> &ndash; The URL the client can visit to reschedule or
          cancel the appointment. Blank if not permitted.
        </div>
        <div>
          <code>{'{{ appointment.resource_name }}'}</code> &ndash; The name of the resource (member or team) being
          scheduled.
        </div>
        <div>
          <code>{'{{ appointment.time_zone }}'}</code> &ndash; The timezone selected by the client.
        </div>
        <div>
          <code>{'{{ appointment_type.rescheduling_allowed }}'}</code> &ndash; If the appointment type permits client
          rescheduling and cancellation.
        </div>
        <div>
          <code>{'{{ appointment_type.name }}'}</code> &ndash; e.g. &quot;Phone Call&quot; or &quot;Remote Access to
          PC&quot;.
        </div>
        <div>
          <code>{'{{ technician.agreed_time }}'}</code> &ndash; The time the client has selected, in technician&apos;s
          timezone. e.g. &quot;3:30PM on Monday, 12 August 2019&quot;.
        </div>
        {canAccessTicketing && (
          <>
            <div>
              <code>{'{{ ticket.id }}'}</code> &ndash; Ticket ID from {psaName(psaType)}.
            </div>
            <div>
              <code>{'{{ ticket.summary }}'}</code> &ndash; Ticket summary from {psaName(psaType)}.
            </div>
          </>
        )}
      </small>
    </div>
  );
};

export default TechnicianConfirmationVariables;

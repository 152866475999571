import type { FC } from 'react';
import { useState } from 'react';

import RadioButtonOption from './ui/RadioButtonRow/RadioButtonOption';
import Alert from './Alert';

import type Reference from '@models/Reference';
import Select from '@shared/ui/Select';
import { idStringifier } from '@shared/text';

export interface CopyEmailTemplateProps {
  copyFromTemplate: boolean;
  templateId: number | undefined;
  emailTemplates: (Reference & { legacy: boolean })[];
}

const CopyEmailTemplate: FC<CopyEmailTemplateProps> = ({
  copyFromTemplate: isCopyFromTemplate,
  templateId: initialTemplateId,
  emailTemplates,
}) => {
  const [copyFromTemplate, setCopyFromTemplate] = useState(isCopyFromTemplate);
  const [templateId, setTemplateId] = useState(initialTemplateId);

  const legacyEmailTemplateSelected = emailTemplates.find(t => t.id === templateId)?.legacy;
  const showLegacyAlert = copyFromTemplate && legacyEmailTemplateSelected;

  const handleCopyTemplateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCopyFromTemplate(e.target.value === 'true');
  };

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();
    setTemplateId(parseInt(e.target.value));
  };

  return (
    <>
      <RadioButtonOption
        checked={!copyFromTemplate}
        disabled={false}
        label="Start with the default template"
        name="email_template[copy_from_template]"
        value="false"
        onChange={handleCopyTemplateChange}
      />
      <RadioButtonOption
        checked={copyFromTemplate}
        disabled={false}
        label="Copy an existing email template:"
        name="email_template[copy_from_template]"
        value="true"
        onChange={handleCopyTemplateChange}
      />
      {showLegacyAlert && (
        <Alert
          className="form-check mb-3 ms-4"
          icon="triangle-exclamation"
          color="yellow"
          content="The selected email template is a legacy template, which TimeZest cannot automatically convert to a modern WYSIWYG template. TimeZest will only support legacy templates until May 30, 2024."
        />
      )}
      <Select
        className="ms-4 mt-2 w-auto"
        name="email_template[copy_from_template_id]"
        value={templateId || 1}
        options={[...emailTemplates.map(idStringifier)]}
        disabled={!copyFromTemplate}
        onChange={handleTemplateChange}
      />
    </>
  );
};

export default CopyEmailTemplate;

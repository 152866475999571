import type { FC } from 'react';
import { useState } from 'react';

import Option from '@/ui/SearchableDropdownMenu/Option';

import TextInput from '@shared/ui/Inputs/TextInput';
import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';
import {
  useGetAutotaskCompaniesQuery,
  useGetHaloPsaCompaniesQuery,
  useGetConnectWiseCompaniesQuery,
} from '@graphql/generated';
import { idStringifier } from '@shared/text';
import useStateFromProp from '@shared/hooks/useStateFromProp';

export type CompanyData = { displayName: string; loading: boolean };

interface Props {
  displayName: string;
  selectedAttributeId: string;
  onValueChange?: (id: string, name: string) => void;
}

const CompanySearch: FC<Props> = ({ displayName: initialDisplayName, selectedAttributeId, onValueChange }) => {
  const [displayName, setDisplayName] = useStateFromProp(initialDisplayName || 'Select a Company...');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRefWithClickOutside<HTMLDivElement>(() => setShowDropdown(false));

  const { data: connectwiseCompaniesData, loading: connectwiseCompaniesLoading } = useGetConnectWiseCompaniesQuery({
    skip: !selectedAttributeId.includes('connectwise_psa_company_id'),
    variables: { query: searchTerm },
  });

  const { data: autotaskCompaniesData, loading: autotaskCompaniesLoading } = useGetAutotaskCompaniesQuery({
    skip: !selectedAttributeId.includes('autotask_company_id'),
    variables: { query: searchTerm },
  });

  const { data: haloPsaCompaniesData, loading: haloPsaCompaniesLoading } = useGetHaloPsaCompaniesQuery({
    skip: !selectedAttributeId.includes('halo_psa_company_id'),
    variables: { query: searchTerm },
  });

  const companies = (
    autotaskCompaniesData?.['autotask'] ||
    connectwiseCompaniesData?.['connectWise'] ||
    haloPsaCompaniesData?.['haloPsa']
  )?.companies;

  const idStringifiedCompanies = (companies || []).map(idStringifier);

  const companiesLoading = connectwiseCompaniesLoading || autotaskCompaniesLoading || haloPsaCompaniesLoading;

  const psaName = document.getElementById('filter-psa-name')?.['value'];

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value);
  };

  const handleDropdownClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const handleSelect = (id: string, name: string): void => {
    setSearchTerm('');
    setDisplayName(name);

    setShowDropdown(false);

    if (onValueChange) onValueChange(id, name);
  };

  return (
    <>
      <TextInput
        type="text"
        name="company-search"
        className="form-select bg-white text-start"
        role="button"
        value={displayName}
        readOnly={true}
        onClick={handleDropdownClick}
      />
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="dropdown-menu w-100 d-block position-absolute left-0 overflow-y-auto p-0"
          style={{ maxHeight: '300px', top: '36px' }}
        >
          <div className="d-inline-block w-100">
            <div>
              <TextInput
                className="border-0 shadow-none"
                value={searchTerm}
                name="company-search-filter"
                type="text"
                placeholder="Filter by company name..."
                onChange={handleSearchTermChange}
              />
            </div>
            <div className="dropdown-divider my-1" />
            {companiesLoading ? (
              <div className="dropdown-item">{`Loading companies from ${psaName}...`}</div>
            ) : (
              idStringifiedCompanies.map(item => (
                <Option key={item.value} id={item.value.toString()} name={item.name} onSelect={handleSelect} />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CompanySearch;

import set from 'lodash/fp/set';

import type { TemplateState } from './templateReducer';
import { getElementFromIndex } from './utilities';

const updateProperty: (state: TemplateState, elementId: string, property: string, value: any) => TemplateState = (
  state,
  elementId,
  property,
  value
) => {
  const element = getElementFromIndex(state, elementId);

  const properties = set(property, value, element);
  return { ...state, index: { ...state.index, [elementId]: properties } };
};

export default updateProperty;

import { forwardRef, useContext } from 'react';

import type { WorkflowActionProps } from '@/ui/Workflow/types';

import CustomerSelect from './CustomerSelect';
import SiteSelect from './SiteSelect';

import Action from '@shared/Action';
import RadioButtonRow from '@ui/RadioButtonRow';
import type { HaloPsaCreateUserAction } from '@graphql/generated';
import { CreateContactMode } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import ErrorBoundary from '@shared/ErrorBoundary';
import useFormSubmit from '@shared/hooks/useFormSubmit';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import FormError from '../../FormError';
import { useCreateUserForHaloPsa } from '../../hooks';

const AVAILABLE_MODES = [
  { value: CreateContactMode.CreateNew, label: 'Create a new user (and customer and site if required).' },
  { value: CreateContactMode.UseCatchall, label: 'Create a new user in a catch-all customer and site.' },
  {
    value: CreateContactMode.OnlyKnownEmail,
    label: 'Reject if no existing user with the given email address is found in HaloPSA.',
  },
  {
    value: CreateContactMode.OnlyKnownDomain,
    label: 'Reject if no existing user with a matching email domain is found in HaloPSA.',
  },
];

const DETAILS = (
  <>
    <p className="mb-1">When TimeZest executes this action, it will create a new user in HaloPSA.</p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When there is already a user associated with the scheduling request - for example, the user associated with an
        existing HaloPSA ticket.
      </li>
      <li>When there is already an existing user with the user&apos;s email address in HaloPSA.</li>
      <li>
        When <strong>all</strong> of the users being scheduled do not use HaloPSA as their calendar.
      </li>
      <li>When no HaloPSA integration is configured.</li>
    </ul>
    <p>
      When this action is included in a workflow, TimeZest will ask the user for their name, email address and company
      name during scheduling.
    </p>
  </>
);

const CreateUser = forwardRef<HTMLFormElement, WorkflowActionProps<HaloPsaCreateUserAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const { errors: mutationErrors, loading, succeeded, submit } = useCreateUserForHaloPsa();
    const [selectClientId, setSelectedClientId] = useStateFromProp(action.psaClientId);
    const [selectedClientName, setSelectedClientName] = useStateFromProp(
      action.psaClientName,
      name => name || 'Select a Customer...'
    );

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        mode: data.get('mode') as CreateContactMode,
        psaClientId: (data.get('psa_client_id') || '') as string,
        psaClientName: (data.get('psa_client_name') || '') as string,
        psaSiteId: (data.get('psa_site_id') || '') as string,
        psaSiteName: (data.get('psa_site_name') || '') as string,
      };
    });

    const [mode, setMode] = useStateFromProp(action.mode);
    const modes = AVAILABLE_MODES.map(m => ({ ...m, disabled: readOnly }));

    const handleModeChange = (newMode: CreateContactMode): void => {
      setMode(newMode);
    };

    const handleSelectClient = (clientId: string, clientName: string): void => {
      setSelectedClientId(clientId);
      setSelectedClientName(clientName);
    };

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="user-b"
            summary={
              <>
                Create a <strong>user</strong> in HaloPSA.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="mode"
              label="New User Handling"
              value={mode || 'only_known_email'}
              options={modes}
              error={errors.mode}
              helpText="This setting controls what TimeZest will do when the email address given by the booking user does not match an existing user in HaloPSA."
              onChange={handleModeChange}
            />

            {mode === 'use_catchall' && (
              <>
                <CustomerSelect
                  clientId={selectClientId || undefined}
                  clientName={selectedClientName}
                  error={errors.psa_client_id || errors.psa_client_name}
                  readOnly={readOnly}
                  templateMode={templateMode || false}
                  onChange={handleSelectClient}
                />
                <SiteSelect
                  clientId={selectClientId || undefined}
                  siteId={action.psaSiteId || undefined}
                  error={errors.psa_site_id || errors.psa_site_name}
                  disabled={readOnly}
                  readOnly={readOnly}
                  templateMode={templateMode || false}
                />
              </>
            )}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateUser;

import { useContext, type FC } from 'react';

import BoardSelect from '@/connectWise/BoardSelect';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import Row from '@ui/Row';
import { useGetStatusesForBoardQuery } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

const STATUS_HELP_TEXT = 'TimeZest will move the new service ticket to this status.';

interface Props {
  boardId: string | null;
  boardName: string;
  statusId: string | null;
  statusName: string;
  boardError: string | undefined;
  statusError: string | undefined;
  readOnly?: boolean;
  onLoaded?: () => void;
}

const BoardAndStatusRows: FC<Props> = ({
  boardId: initialBoardId,
  boardName: initialBoardName,
  boardError,
  statusId: initialStatusId,
  statusName: initialStatusName,
  statusError,
  readOnly,
  onLoaded,
}) => {
  const { templateMode } = useContext(WorkflowActionContext);

  const [boardId, setBoardId] = useStateFromProp(initialBoardId, boardId => Number(boardId) || null);
  const [boardName, setBoardName] = useStateFromProp(initialBoardName);
  const [statusId, setStatusId] = useStateFromProp(initialStatusId, statusId => statusId || null);
  const [statusName, setStatusName] = useStateFromProp(initialStatusName);

  const statusQueryResult = useGetStatusesForBoardQuery({
    skip: templateMode || boardId === null,
    variables: { serviceBoardId: Number(boardId) || 0 },
  });

  const handleBoardIdChange = (id: number | undefined, name: string | undefined): void => {
    setBoardId(id || null);
    setBoardName(name || '');
    setStatusId('0');
    setStatusName('');
  };

  const handleStatusIdChange = (value: string | undefined): void => {
    const id = typeof value !== 'undefined' ? value : undefined;
    const status = (statusQueryResult?.data?.connectWise?.serviceBoardStatuses || []).find(s => s.id.toString() === id);

    setStatusId(id || null);
    setStatusName(status?.name || '');
  };

  return (
    <>
      <input type="hidden" name="psa_board_name" value={boardName} />
      <input type="hidden" name="psa_status_name" value={statusName} />

      <BoardSelect
        label="Board"
        helpText="TimeZest will move service ticket to this board."
        name="psa_board_id"
        type="service"
        readOnly={readOnly}
        templateMode={templateMode}
        selectedBoardId={boardId?.toString() || null}
        error={boardError}
        onBoardIdChange={handleBoardIdChange}
        onLoaded={onLoaded}
      />

      {boardId ? (
        <SelectRowFromGraphQL
          name="psa_status_id"
          label="Status"
          helpText={STATUS_HELP_TEXT}
          prompt="Select a status"
          field="connectWise.serviceBoardStatuses"
          loadingMessage="Loading from ConnectWise PSA..."
          queryErrorMessage="Error loading service teams from ConnectWise PSA."
          readOnly={readOnly}
          templateMode={templateMode}
          error={statusError}
          disabled={false}
          value={(statusId || '').toString()}
          queryResult={statusQueryResult}
          onChange={handleStatusIdChange}
        />
      ) : (
        <Row label="Status" helpText={STATUS_HELP_TEXT}>
          <div className="form-control-plaintext">Select a board to see statuses.</div>
        </Row>
      )}
    </>
  );
};

export default BoardAndStatusRows;

import { type FC, useContext } from 'react';

import FontAwesomeIcon from '@/FontAwesomeIcon';
import LocalTime from '@/LocalTime';
import AccountContext from '@/AccountContext';

import Badge from '@ui/Badge';
import TextRow from '@ui/TextRow';
import type { LoginActivityModel } from '@models/User';

interface Props {
  lastLogins: LoginActivityModel[];
  twoFactorEnabled: boolean;
  userId: number;
}

const SecurityConfig: FC<Props> = ({ lastLogins, twoFactorEnabled, userId }) => {
  const { twoFactorForced } = useContext(AccountContext);

  return (
    <>
      <TextRow
        label="2FA Status"
        helpText={`
          Two-factor authentication provides additional security when logging in to TimeZest.
          TimeZest supports 2FA with authenticator apps such as Google Authenticator, or Authy.
        `}
      >
        {twoFactorEnabled ? (
          <p className="text-success">
            <FontAwesomeIcon icon="check" />
            &nbsp;&nbsp;2FA is enabled for this user account.
          </p>
        ) : (
          <p className="text-danger">
            <FontAwesomeIcon icon="times" />
            &nbsp;&nbsp;2FA is not enabled for this user account.
          </p>
        )}
        {twoFactorEnabled && (
          <a
            className="btn btn-danger"
            rel="nofollow"
            data-confirm="Are you sure"
            data-method="delete"
            href={`/settings/users/${userId}/two_factor`}
          >
            {twoFactorForced ? 'Disable two-factor authentication for next login' : 'Disable two-factor authentication'}
          </a>
        )}
      </TextRow>
      <div className="row">
        <div className="col-sm-2">
          <span className="fw-bold d-inline-block mb-2 mt-2">Last logins</span>
        </div>
        <div className="col-sm-10">
          <table className="table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Date</th>
                <th>Location</th>
                <th>IP Address</th>
              </tr>
            </thead>
            <tbody>
              {lastLogins.length === 0 && (
                <tr>
                  <td colSpan={4} className="bg-white">
                    This user has never logged in.
                  </td>
                </tr>
              )}
              {lastLogins.map((login, index) => (
                <tr key={index}>
                  <td>
                    {login.succeeded ? <Badge color="green">Succeeded</Badge> : <Badge color="red">Failed</Badge>}
                  </td>
                  <td>
                    <LocalTime value={login.date} />
                  </td>
                  <td>{login.location || '(Unknown)'}</td>
                  <td>{login.ipAddress || '(Unknown)'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SecurityConfig;

import type { FC, PropsWithChildren } from 'react';

import { ErrorMessage } from '@ui/ErrorMessage';

type Props = PropsWithChildren<{
  error: string | undefined;
  loading: string | boolean;
}>;

const DataLoader: FC<PropsWithChildren<Props>> = ({ error, loading, children }) => {
  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  } else if (loading) {
    return <h6 className="m-auto">{typeof loading === 'string' ? loading : 'Loading...'}</h6>;
  } else {
    return <>{children}</>;
  }
};

export default DataLoader;

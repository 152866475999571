import type { UIState } from './uiReducer';

const selectElement: (state: UIState, selectedElementId: string | null) => UIState = (state, selectedElementId) => {
  return {
    ...state,
    selectedElementId,
    initialSelectedElementId: selectedElementId,
  };
};

export default selectElement;

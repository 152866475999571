import { type FC, useContext } from 'react';

import PlanContext from '@/PlanContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  newPlanAllowsCustomEmails: boolean;
  hasCustomEmails: boolean;
}

const CustomEmail: FC<Props> = ({ hasCustomEmails, newPlanAllowsCustomEmails }) => {
  const { allowsCustomEmails: currentPlanAllowsCustomEmails } = useContext(PlanContext);

  if (!newPlanAllowsCustomEmails && currentPlanAllowsCustomEmails) {
    if (hasCustomEmails) {
      return (
        <div className="text-danger">
          <FontAwesomeIcon icon="times" />
          &nbsp; TimeZest will no longer use your custom email templates when communicating with clients, and will use
          its default, built-in emails.
        </div>
      );
    }

    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp; You&apos;ll no longer be able to customize the emails that TimeZest sends to your clients.
      </div>
    );
  }

  if (newPlanAllowsCustomEmails && !currentPlanAllowsCustomEmails) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; You&apos;ll be able to completely customise the emails TimeZest sends your clients, changing their text,
        branding and HTML exactly to what you need.
      </div>
    );
  }
};

export default CustomEmail;

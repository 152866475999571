import { CreateContactMode, TimeZestSendEmailFromTemplateRecipientsType } from '@graphql/generated';
import type { EmailTemplate } from '@models/EmailTemplate';

export const MODES: { value: CreateContactMode; label: string }[] = [
  { value: CreateContactMode.CreateNew, label: 'Create a new contact and company.' },
  { value: CreateContactMode.UseCatchall, label: 'Create a new contact in a catch-all company.' },
  { value: CreateContactMode.OnlyKnownEmail, label: 'Reject if matching email address not found.' },
  { value: CreateContactMode.OnlyKnownDomain, label: 'Reject if matching email domain not found.' },
];

export const RECIPIENTS: {
  value: TimeZestSendEmailFromTemplateRecipientsType;
  label: string;
}[] = [
  { label: 'Send to the client.', value: TimeZestSendEmailFromTemplateRecipientsType.Client },
  {
    label: 'Send to the client and any invited guests.',
    value: TimeZestSendEmailFromTemplateRecipientsType.ClientWithGuests,
  },
  { label: 'Send to the scheduled resources.', value: TimeZestSendEmailFromTemplateRecipientsType.ScheduledUsers },
];

export const INITIAL_CALENDAR_TEMPLATE = [
  {
    type: 'paragraph',
    children: [
      { type: 'variable', variable: 'appointment.external_name' },
      { text: ' with ' },
      { type: 'variable', variable: 'appointment.scheduled_user_names' },
      { text: ' from ' },
      { type: 'variable', variable: 'account.name' },
      { text: '.' },
    ],
  },
  {
    type: 'paragraph',
    children: [
      { text: 'Ticket: ' },
      { type: 'variable', variable: 'ticket.number' },
      { text: ' - ' },
      { type: 'variable', variable: 'ticket.summary' },
    ],
    condition: 'ticket.number',
  },
  {
    type: 'paragraph',
    children: [
      { text: 'This online meeting can be joined at ' },
      { type: 'variable', variable: 'online_meeting.join_url' },
    ],
    condition: 'online_meeting.join_url',
  },
  {
    type: 'paragraph',
    children: [{ text: 'Password: ' }, { type: 'variable', variable: 'online_meeting.password' }],
    condition: 'online_meeting.password',
  },
  {
    type: 'paragraph',
    children: [],
  },
];

export const INITIAL_TEMPLATE: EmailTemplate = {
  type: 'mjml',
  children: [
    {
      type: 'mjml-body',
      children: [],
      backgroundColor: '#f6f6f6',
    },
  ],
};

export const HALO_PSA_REMINDER_TIMES = [
  { name: 'None', value: '0' },
  { name: '5 minutes', value: '5' },
  { name: '10 minutes', value: '10' },
  { name: '15 minutes', value: '15' },
  { name: '30 minutes', value: '30' },
  { name: '45 minutes', value: '45' },
  { name: '1 hour', value: '60' },
  { name: '2 hours', value: '120' },
  { name: '3 hours', value: '180' },
  { name: '4 hours', value: '240' },
  { name: '5 hours', value: '300' },
  { name: '6 hours', value: '360' },
  { name: '7 hours', value: '420' },
  { name: '8 hours', value: '480' },
  { name: '10 hours', value: '600' },
  { name: '12 hours', value: '720' },
  { name: '1 day', value: '1440' },
  { name: '2 days', value: '2880' },
  { name: '3 days', value: '4320' },
  { name: '4 days', value: '5760' },
  { name: '5 days', value: '7200' },
  { name: '6 days', value: '8640' },
  { name: '1 week', value: '10080' },
  { name: '2 weeks', value: '20160' },
  { name: '3 weeks', value: '30240' },
  { name: '4 weeks', value: '40320' },
];

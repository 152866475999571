{
  "units": {
    "hour_one": "1 Stunde",
    "minute_one": "1 Minute",
    "minute_other": "{{count}} Minuten",
    "hour_other": "{{count}} Stunden"
  },
  "scheduling_ui": {
    "appointment_details": {
      "appointment": "Termin",
      "with": "mit",
      "date": "Datum",
      "ticket": "Ticket",
      "time": "Uhrzeit",
      "duration": "Dauer"
    },
    "appointment_selector": {
      "instruction": "W\u00e4hlen Sie Datum und Zeit f\u00fcr Ihr(en) {{appointment_name}} mit {{appointment_resources}}.",
      "instruction_reschedule": "W\u00e4hlen Sie ein neues Datum und eine neue Zeit f\u00fcr Ihr(en) {{appointment_name}} mit {{appointment_resources}}."
    },
    "date_selector": {
      "loading": "Suche nach Daten mit freien Terminen..."
    },
    "select_prompt": {
      "prompt": "W\u00e4hlen Sie ein Datum, um verf\u00fcgbare Zeiten zu sehen."
    },
    "timezone_selector": {
      "indicator": "Alle Zeiten sind in {{timezone}}.",
      "change": "\u00c4ndern"
    },
    "time_selector": {
      "no_available_time": {
        "notification": "F\u00fcr dieses Datum sind keine Zeiten verf\u00fcgbar.",
        "instruction": "Bitte w\u00e4hlen Sie einen anderen Tag."
      },
      "available_time": "Die unten gezeigten Zeiten sind in der Zeitzone <strong>{{timezone}}</strong>. Wenn Sie sich in einer anderen Zeitzone befinden, k\u00f6nnen Sie dies unten \u00e4ndern."
    }
  }
}

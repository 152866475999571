import { useState, type FC } from 'react';

import Form from '@/Form';
import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import { pluralize, withNegation } from '@shared/text';

interface Props {
  workflowAppointmentTypes: { appointmentTypes: { internalName: string }[]; size: number; present: boolean };
}

const Office365CalendarNew: FC<Props> = ({ workflowAppointmentTypes }) => {
  const [enabledToReadByDefault, setEnabledToReadByDefault] = useState('true');
  const [updateWorkflow, setUpdateWorkflow] = useState('true');

  return (
    <>
      <Header title="Microsoft 365 Calendar Integration" />
      <Flash />
      <h5 className="fw-bold">Microsoft 365 availability when scheduling</h5>
      <p>
        Do you want TimeZest to automatically use Microsoft 365 calendars for checking availability for users who have
        an Microsoft 365 calendar?
      </p>
      <Form url="/settings/integrations/office_365_calendar">
        <div className="mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="true"
              checked={enabledToReadByDefault === 'true'}
              name="office_365_calendar_integration[calendar_enabled_to_read_by_default]"
              id="office_365_calendar_integration_calendar_enabled_to_read_by_default_true"
              onChange={e => setEnabledToReadByDefault(e.target.value)}
            />
            <label
              className="form-check-label"
              htmlFor="office_365_calendar_integration_calendar_enabled_to_read_by_default_true"
            >
              Yes, enable the Microsoft 365 calendar for every user
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              value="false"
              checked={enabledToReadByDefault === 'false'}
              name="office_365_calendar_integration[calendar_enabled_to_read_by_default]"
              id="office_365_calendar_integration_calendar_enabled_to_read_by_default_false"
              onChange={e => setEnabledToReadByDefault(e.target.value)}
            />
            <label
              className="form-check-label"
              htmlFor="office_365_calendar_integration_calendar_enabled_to_read_by_default_false"
            >
              No, I&apos;ll manually turn it on for the users I want to use their Microsoft 365 calendar
            </label>
          </div>
        </div>

        {workflowAppointmentTypes.present && (
          <div className="mb-3">
            <h5 className="fw-bold mt-4">Update workflows to work correctly with Microsoft 365</h5>
            <p>
              You currently have {workflowAppointmentTypes.size} {pluralize('workflow', workflowAppointmentTypes.size)}{' '}
              which {withNegation(workflowAppointmentTypes.size)} have an action to create an event in Microsoft 365
              when the user being scheduled has that configured as their calendar to write appointments to. This means
              that these workflows won&apos;t function correctly for these users.
            </p>
            <p>Would you like TimeZest to automatically add the necessary actions to the affected workflows:</p>
            <ul>
              {workflowAppointmentTypes.appointmentTypes.map((at, i) => (
                <li key={i}>{at.internalName}</li>
              ))}
            </ul>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="true"
                checked={updateWorkflow === 'true'}
                name="office_365_calendar_integration[update_workflows]"
                id="office_365_calendar_integration_update_workflows_true"
                onChange={e => setUpdateWorkflow(e.target.value)}
              />
              <label className="form-check-label" htmlFor="office_365_calendar_integration_update_workflows_true">
                Yes, add a Create an Microsoft 365 Event action to these workflows, so they can be used to schedule
                appointments for users with Microsoft 365 calendar.
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="false"
                checked={updateWorkflow === 'false'}
                name="office_365_calendar_integration[update_workflows]"
                id="office_365_calendar_integration_update_workflows_false"
                onChange={e => setUpdateWorkflow(e.target.value)}
              />
              <label className="form-check-label" htmlFor="office_365_calendar_integration_update_workflows_false">
                No, I&apos;ll manually edit the workflows to add this event.
              </label>
            </div>
          </div>
        )}

        <button type="submit" className="btn btn-primary" data-disable-with="Loading">
          Continue &nbsp; <FontAwesomeIcon icon="angle-right" />
        </button>
      </Form>
    </>
  );
};

export default asScreen(Office365CalendarNew);

import type { FC, KeyboardEvent } from 'react';
import { useState } from 'react';

import Item from './Item';
import SelectedItem from './SelectedItem';

import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';
import type { VideoCallingMode } from '@models/Integration';

export interface ItemModel {
  id: string;
  name: string;
  type?: string;
  videoCalls?: VideoCallingMode[];
}

interface Props {
  name: string;
  items: ItemModel[];
  initialItemId?: string;
  maxSelectedItems?: number;
  minSelectedItems?: number;
  search?: boolean;
  handleChange: (ids: string[]) => void;
  handleFocus?: () => void;
}

const focusElement = (direction: 'up' | 'down', firstItemName: string) => {
  const listItems = document.getElementsByClassName('select-item');
  const listItemIds: string[] = [];

  for (const item of listItems) {
    listItemIds.push(item.getAttribute('id') || '');
  }

  const activeElementId = document.activeElement?.id;

  if (!activeElementId) {
    document.getElementById(listItemIds[0])?.focus();
    return;
  }

  const currentActiveElementIndex = listItemIds.indexOf(activeElementId);
  const isCurrentActiveElementLast = currentActiveElementIndex === listItemIds.length - 1;

  if (direction === 'down' && !isCurrentActiveElementLast) {
    const nextListItemId = listItemIds[currentActiveElementIndex + 1];
    document.getElementById(nextListItemId)?.focus();

    return;
  }

  const isCurretAciteElementFirst = currentActiveElementIndex === 0;

  if (direction === 'up' && !isCurretAciteElementFirst) {
    const nextListItemId = listItemIds[currentActiveElementIndex - 1];
    document.getElementById(nextListItemId)?.focus();
    return;
  }

  document.getElementById('search-input-' + firstItemName)?.focus();
};

const toggleDropdownOpen = ({
  e,
  firstItemName,
  focused,
  onFocusedSet,
}: {
  e: KeyboardEvent<HTMLDivElement>;
  firstItemName: string;
  focused: boolean;
  onFocusedSet: (v: boolean) => void;
}) => {
  let openDropDown = e.key === ' ' || e.key === 'Enter';

  if (e.key === 'Escape') {
    e.preventDefault();
    onFocusedSet(false);
    return;
  }

  if (e.type === 'click' || openDropDown) {
    e.preventDefault();
    onFocusedSet(true);
    return;
  }

  if (e.key === 'ArrowDown') {
    e.preventDefault();
    focusElement('down', firstItemName);
    if (!focused) onFocusedSet(true);
    return;
  }

  if (e.key === 'ArrowUp') {
    e.preventDefault();
    focusElement('up', firstItemName);
    return;
  }

  document.getElementById('search-input')?.focus();
};

const SearchSelect: FC<Props> = ({
  name,
  items,
  initialItemId,
  maxSelectedItems,
  minSelectedItems,
  search,
  handleChange,
  handleFocus,
}) => {
  const handleBlur = () => {
    setFocused(false);
    setSearchPhrase('');
  };

  const selectRef = useRefWithClickOutside<HTMLDivElement>(() => handleBlur());

  const [focused, setFocused] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState('');
  const selectedItem = initialItemId ? items.find(i => i.id === initialItemId) : null;
  const [selectedItems, setSelectedItems] = useState(selectedItem ? [selectedItem] : []);

  const isMulti = typeof maxSelectedItems !== 'undefined' ? maxSelectedItems > 1 : false;
  const canSelect = typeof maxSelectedItems !== 'undefined' ? selectedItems.length < maxSelectedItems : true;
  const canUnselect = typeof minSelectedItems !== 'undefined' ? selectedItems.length > minSelectedItems : true;

  const updateSelectedItems = (selectedItems: ItemModel[], onUpdateSelectedItems?: () => void) => {
    setSelectedItems(selectedItems);
    setSearchPhrase('');

    handleChange(selectedItems.map(item => item.id));

    if (onUpdateSelectedItems) onUpdateSelectedItems();
  };

  const currentIdValues = () => selectedItems.map(item => item.id).join(',');

  const suggestedItems = search
    ? items.filter(i => i.name.toLowerCase().indexOf(searchPhrase.toLowerCase()) >= 0)
    : items;

  const firstItemName = items[0]?.name;

  const handleSearchPhraseChange = e => {
    if (!focused) setFocused(true);
    setSearchPhrase(e.target.value);
  };

  const handleClick = () => {
    setFocused(true);
  };

  const handleKeyDown = e => {
    toggleDropdownOpen({ e, firstItemName, focused, onFocusedSet: setFocused });
  };

  return (
    <div
      id={'select-' + firstItemName}
      className={`select form-select ${focused ? 'focussed' : ''}`}
      ref={selectRef}
      tabIndex={0}
      role="button"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <div className="select-selection-container">
        <div className="select-selections">
          {selectedItems.map(item => (
            <SelectedItem
              key={item.id}
              {...item}
              selectedItems={selectedItems}
              minSelectedItems={minSelectedItems}
              maxSelectedItems={maxSelectedItems}
              onUpdateSelectedItems={updateSelectedItems}
            />
          ))}
        </div>

        {search && (
          <input
            id={'search-input-' + firstItemName}
            type="text"
            className="select-search"
            value={searchPhrase}
            onChange={handleSearchPhraseChange}
            onFocus={handleFocus}
          />
        )}
      </div>

      <i className="select-arrow" />

      {focused && (
        <div className="select-items">
          {suggestedItems.map(item => (
            <Item
              key={item.id}
              selectedItems={selectedItems}
              firstItemName={firstItemName}
              isMulti={isMulti}
              canSelect={canSelect}
              canUnselect={canUnselect}
              onBlur={handleBlur}
              onUpdateSelectedItems={updateSelectedItems}
              onSetSearchPhrase={setSearchPhrase}
              {...item}
            />
          ))}
        </div>
      )}

      <input type="hidden" name={name} value={currentIdValues()} />
    </div>
  );
};

export default SearchSelect;

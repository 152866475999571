import type { FC } from 'react';
import { useState } from 'react';

import Expander from '@/ui/Expander';

import type { ShareableUrlAppointmentType } from '@models/AppointmentType';
import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import RadioButtonRow from '@ui/RadioButtonRow';

import CompanyTodoOptions from '../../../autotask/CompanyTodoOptions';
import ServiceCallOptions from '../../../autotask/ServiceCallOptions';

export function autotaskHasErrors(errors: Errors): boolean {
  return !!(errors.newActivityPsaTypeId || errors.shareableUrlAppointmentType);
}

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  canExpand: boolean;
  dirty: boolean;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
}

const Autotask: FC<Props> = ({ appointmentType, canExpand, dirty, errors, expanded, onExpand }) => {
  const [shareableUrlAppointmentType, setShareableUrlAppointmentType] = useState(
    appointmentType.shareableUrlAppointmentType
  );

  const handleShareableUrlAppointmentTypeChange = (type: ShareableUrlAppointmentType) => {
    setShareableUrlAppointmentType(type);
  };

  const helpText = (
    <>
      <div className="mb-1">This setting determines the type of appointment TimeZest will make in Autotask.</div>
      {appointmentType.shareableUrlTicketMode === 'without_ticket' && (
        <>
          To select the service call option, change the <strong>Shareable URLs</strong> option in the{' '}
          <strong>URL-Based Scheduling &amp; Shareable URLs</strong> section to create/require a service ticket, as
          Autotask requires a service ticket for a service call.
        </>
      )}
    </>
  );

  return (
    <Expander
      title="Autotask Options"
      summary={`Create a new ${appointmentTypeName(appointmentType.shareableUrlAppointmentType)} in Autotask.`}
      url={`/settings/appointment_types/${appointmentType.id}/classic/autotask`}
      method="PATCH"
      icon="gear"
      hasErrors={autotaskHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      <RadioButtonRow
        name="appointment_type[shareable_url_appointment_type]"
        value={appointmentType.shareableUrlAppointmentType}
        label="Created Appointment"
        helpText={helpText}
        options={[
          {
            label: (
              <>
                Create a <strong>service call</strong> in the scheduled users&apos; Autotask calendar
              </>
            ),
            value: 'appointment',
            disabled: appointmentType.shareableUrlTicketMode === 'without_ticket',
          },
          {
            label: (
              <>
                Create a <strong>company to-do</strong> in the scheduled users&apos; Autotask calendar
              </>
            ),
            value: 'activity',
          },
        ]}
        onChange={handleShareableUrlAppointmentTypeChange}
      />

      {shareableUrlAppointmentType === 'appointment' && (
        <ServiceCallOptions
          fieldName="appointment_type[new_appointment_psa_status_id]"
          selectedStatusId={(appointmentType.newAppointmentPsaStatusId || 0).toString()}
          error={errors.newAppointmentPsaStatusId}
        />
      )}
      {shareableUrlAppointmentType === 'activity' && (
        <CompanyTodoOptions
          fieldName="appointment_type[new_activity_psa_type_id]"
          selectedTypeId={appointmentType.newActivityPsaTypeId || 0}
          error={errors.newActivityPsaTypeId}
        />
      )}
    </Expander>
  );
};

function appointmentTypeName(appointmentType: 'appointment' | 'activity'): string {
  return appointmentType === 'appointment' ? 'service call' : 'company to-do';
}

export default Autotask;

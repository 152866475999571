import type { FC } from 'react';
import { useContext } from 'react';

import classNames from 'classnames';

import { useAppDispatch } from '@/EmailEditor/hooks';
import { deleteProperty, updateProperty } from '@/EmailEditor/actions';
import RadioButtonOption from '@/ui/RadioButtonRow/RadioButtonOption';

import type { NodeType } from '@models/EmailTemplate';
import VariablesContext from '@shared/VariablesContext';
import Select from '@shared/ui/Select';

interface Props {
  nodeId: string;
  nodeType: NodeType;
  disabled: boolean;
  condition?: string | null;
}

const ConditionalSetter: FC<Props> = ({ nodeId, nodeType, condition, disabled }) => {
  const { variables } = useContext(VariablesContext);

  const initialVariable = condition?.slice(0, 1) === '!' ? condition.slice(1) : condition;
  const initialCondition = condition?.slice(0, 1) === '!' ? '!' : '';

  const dispatch = useAppDispatch();

  const handleConditionalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'false') {
      dispatch(deleteProperty({ elementId: nodeId, property: 'condition' }));
    } else {
      dispatch(updateProperty({ elementId: nodeId, property: 'condition', value: variables[0].variable }));
    }
  };

  const handleConditionVariableChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      updateProperty({
        elementId: nodeId,
        property: 'condition',
        value: initialCondition === '!' ? '!'.concat(e.target.value) : e.target.value,
      })
    );
  };

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (initialVariable) {
      dispatch(
        updateProperty({
          elementId: nodeId,
          property: 'condition',
          value: e.target.value === '!' ? '!'.concat(initialVariable) : initialVariable,
        })
      );
    }
  };

  return (
    <>
      <RadioButtonOption
        checked={!condition}
        disabled={disabled}
        label={`Always include this ${nodeType.replace('mjml-', '')} in the email.`}
        name="conditional"
        value="false"
        onChange={handleConditionalChange}
      />

      <RadioButtonOption
        label={`Only include this ${nodeType.replace('mjml-', '')} in the email when:`}
        name="conditional"
        value="true"
        disabled={disabled}
        checked={!!condition}
        appendedElement={
          <>
            <Select
              className={classNames('w-100 my-1 ps-2', { 'text-muted': disabled })}
              disabled={!condition || disabled}
              value={initialVariable || variables[0].variable}
              name="variable"
              options={variables.map(v => ({ name: v.name, value: v.variable }))}
              onChange={handleConditionVariableChange}
            />
            <Select
              className={classNames('w-100 ps-2', { 'text-muted': disabled })}
              disabled={!condition || disabled}
              value={initialCondition}
              name="variable-presence-status"
              options={[
                { value: '', name: 'is present', key: 'present' },
                { value: '!', name: 'is not present', key: 'notpresent' },
              ]}
              onChange={handleConditionChange}
            />
          </>
        }
        onChange={handleConditionalChange}
      />
    </>
  );
};

export default ConditionalSetter;

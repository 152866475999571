import type { FC } from 'react';

import Pagination from '@/Pagination';

import UserStatus from './UserStatus';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

import type { User } from '..';

interface Props {
  users: User[];
  usersCount: number;
  usersPerPage: number;
}

const UsersList: FC<Props> = ({ users, usersCount, usersPerPage }) => {
  return (
    <>
      {users.map((u, i) => (
        <div key={i} className="card mb-2">
          <div className="card-body d-flex align-items-center py-2">
            <div className="flex-grow-1 fw-bold">{u.name}</div>
            {u.integrationMember ? (
              <UserStatus
                integrationUserId={u.integrationMember.integrationUserId}
                hasAppInstalled={u.integrationMember.hasAppInstalled}
                processing={u.integrationMember.processing}
              />
            ) : (
              <>
                <span className="text-danger">
                  <FontAwesomeIcon icon="times" />
                </span>
                &nbsp; This user is not associated with a Teams user account.
              </>
            )}
          </div>
        </div>
      ))}

      <Pagination itemsCount={usersCount} itemsPerPage={usersPerPage} />
    </>
  );
};

export default UsersList;

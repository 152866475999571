import type { FC } from 'react';

import asScreen from '@/Screen';

import CancellationForm from './CancellationForm';

import type { SchedulingRequest } from '../../Show';
import BasicDetails from '../../BasicDetails';
import Header from '../../Header';

interface Props {
  schedulingRequest: SchedulingRequest;
}

const CancelSchedulingRequest: FC<Props> = ({ schedulingRequest }) => {
  return (
    <>
      <Header schedulingRequest={schedulingRequest} canCancelAppointment={false} />
      <BasicDetails schedulingRequest={schedulingRequest} />
      <hr />
      <CancellationForm token={schedulingRequest.token} scheduled={schedulingRequest.scheduled} />
    </>
  );
};

export default asScreen(CancelSchedulingRequest);

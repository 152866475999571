import type { FC } from 'react';

import RadioButtonOption from '@/ui/RadioButtonRow/RadioButtonOption';

import PlanBadge from './PlanBadge';

interface Props {
  chargebeePlanId: string;
  selectedPlan: string;
  planId: string;
  planName: string;
  planPricing: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PlanChoice: FC<Props> = ({ chargebeePlanId, selectedPlan, planId, planName, planPricing, onChange }) => {
  const annualPlan = planId.includes('annual');
  const selected = selectedPlan === planId;
  const currentPlan = chargebeePlanId === planId;
  const professionalPlan = planId.includes('professional');

  return (
    <RadioButtonOption
      appendedElement={
        <div className="text-muted small flex-grow-1">
          {professionalPlan
            ? `All of TimeZest's powerful features like team scheduling, custom email templates, and integration with
            Microsoft 365 Calendars.`
            : `Cost-effective scheduling with your clients. Includes buffer times, rescheduling & cancellation,
              reminders, and custom availability.`}
        </div>
      }
      label={
        <>
          <strong>{planName}</strong> &mdash; {planPricing}{' '}
          <PlanBadge currentPlan={currentPlan} annualPlan={annualPlan} />
        </>
      }
      checked={selected}
      disabled={false}
      name="chargebee_plan_id"
      value={planId}
      onChange={onChange}
    />
  );
};

export default PlanChoice;

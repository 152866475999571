{
  "units": {
    "hour_one": "1\u00a0heure",
    "minute_one": "1\u00a0minute",
    "minute_other": "{{count}}\u00a0minutes",
    "hour_other": "{{count}}\u00a0heures"
  },
  "scheduling_ui": {
    "appointment_details": {
      "appointment": "Rendez-vous",
      "with": "Avec",
      "date": "Date",
      "ticket": "Ticket",
      "time": "Heure",
      "duration": "Dur\u00e9e"
    },
    "appointment_selector": {
      "instruction": "S\u00e9lectionnez une date et une heure pour votre {{appointment_name}} avec {{appointment_resources}}.",
      "instruction_reschedule": "S\u00e9lectionnez une nouvelle date et une heure pour votre {{appointment_name}} avec {{appointment_resources}}."
    },
    "date_selector": {
      "loading": "Recherche de dates ayant des rendez-vous disponibles..."
    },
    "select_prompt": {
      "prompt": "S\u00e9lectionnez une date pour voir les heures disponibles."
    },
    "timezone_selector": {
      "indicator": "Les heures sont affich\u00e9es pour le fuseau horaire de {{timezone}}.",
      "change": "Modifier"
    },
    "time_selector": {
      "no_available_time": {
        "notification": "Aucune heure disponible \u00e0 cette date.",
        "instruction": "Veuillez choisir un jour diff\u00e9rent."
      },
      "available_time": "Les heures affich\u00e9es ci-dessous sont dans le fuseau horaire de <strong>{{timezone}}</strong>. Si vous \u00eates dans un fuseau horaire diff\u00e9rent, vous pouvez le modifier ci-dessous."
    }
  }
}

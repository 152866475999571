import type { FC } from 'react';
import { useContext } from 'react';

import styled from 'styled-components';

import ConfigContext from '../ConfigContext';
import { updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import { useParentWidthAsFallback } from '../Blocks/utilities';
import type { IndexedMjmlColumn } from '../EmailTemplate';

import ColorInput from './Inputs/Color';
import SpaceInput from './Inputs/Space';
import Slider from './Inputs/Slider';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';

const ColumnPropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

interface Props {
  node: IndexedMjmlColumn;
}

const ColumnProperties: FC<Props> = ({ node }) => {
  const { readOnly } = useContext(ConfigContext);

  const dispatch = useAppDispatch();

  const handleOnChange = (property: string) => (value: any) => {
    dispatch(updateProperty({ elementId: node.id, property, value }));
  };

  const nodeWidth = useParentWidthAsFallback(node.width, node.parentId as string, '%');

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this column and all its content. Are you sure?"
    >
      <ColumnPropertiesContainer>
        <PropertiesCollapsible title="COLUMN PROPERTIES">
          <ColorInput
            id={`${node.id}-background-color`}
            label="Background Color"
            name="backgroundColor"
            value={node.backgroundColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('backgroundColor')}
          />

          <SpaceInput
            id={`${node.id}-padding`}
            label="Padding"
            value={node.padding || {}}
            disabled={readOnly}
            onInput={handleOnChange('padding')}
          />

          <Slider
            id={node.id}
            value={nodeWidth}
            label={`Width: ${nodeWidth}`}
            min={1}
            max={100}
            step={1}
            unit="%"
            disabled={readOnly}
            onInput={handleOnChange('width')}
          />
        </PropertiesCollapsible>
      </ColumnPropertiesContainer>
    </PropertiesContainer>
  );
};

export default ColumnProperties;

import type { FC } from 'react';

import Alert from '@/Alert';
import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import Settings from './Settings';

export type TicketStatus = {
  id: string;
  name: string;
  closed: boolean;
  active: boolean;
};

export type IntegrationSettings = {
  afterCancelPsaStatusId: string;
  afterCreatePsaStatusId: string;
  afterSchedulePsaStatusId: string;
  noResponsePsaStatusId: string;
  clientId: string;
  clientSecret: string;
  resourceServer: string;
  authServer: string;
  connected: boolean;
  errors: Record<string, string>;
};

interface Props {
  integration: IntegrationSettings;
  ticketStatuses: TicketStatus[];
}

const HaloPsaIntegration: FC<Props> = ({ integration, ticketStatuses }) => {
  return (
    <>
      <Header title="HaloPSA Integration" />
      <Flash />
      {integration.errors.base && (
        <Alert
          color="red"
          className="mb-3"
          icon="circle-xmark"
          title="TimeZest was unable to connect to your HaloPSA instance with the credentials provided."
          content={<p className="mb-0">Reason from HaloPSA: {integration.errors.base}</p>}
        />
      )}
      <Settings integration={integration} ticketStatuses={ticketStatuses} />
    </>
  );
};

export default asScreen(HaloPsaIntegration);

import type { FC } from 'react';

import Form from '@/Form';
import Row from '@/ui/Row';
import TextAreaRow from '@/ui/TextAreaRow';

interface Props {
  token: string;
  scheduled: boolean;
}

const CancellationForm: FC<Props> = ({ scheduled, token }) => {
  return (
    <>
      {scheduled ? (
        <>
          <h4 className="fw-bold mb-4 ps-3">Cancel this Appointment</h4>
          <Form url={`/scheduling_requests/${token}/cancel`} method="post">
            <div className="px-3">
              <TextAreaRow
                label="Cancellation reason:"
                name="scheduling_request[cancellation_reason]"
                helpText={null}
                value={null}
                note="This cancellation reason will be communicated to the client."
              />
            </div>

            <div className="px-3">
              <Row>
                <input
                  type="submit"
                  value="Cancel Appointment"
                  className="btn btn-primary"
                  data-disable-with="Cancel Appointment"
                />
              </Row>
            </div>
          </Form>
        </>
      ) : (
        <div className="px-3">
          <a href={`/scheduling_requests/${token}/cancel`} className="btn btn-primary" data-method="post">
            Cancel this Scheduling Request
          </a>
        </div>
      )}
    </>
  );
};

export default CancellationForm;

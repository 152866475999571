import type { FC } from 'react';

import Alert from '@/Alert';

import ActiveMembers from '../ActiveMembers';
import InactiveMembers from '../InactiveMembers';

import type { Member } from '@models/TeamMember';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  activatedMembers: Member[];
  unactivatedMembers: Member[];
}

const MembersList: FC<Props> = ({ activatedMembers, unactivatedMembers }) => {
  return (
    <>
      <p className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp;&nbsp;
        <ActiveMembers activatedMembers={activatedMembers} unactivatedMembers={unactivatedMembers} />
      </p>

      <Alert
        className="mb-3"
        icon="circle-xmark"
        color="red"
        title={<InactiveMembers activatedMembers={activatedMembers} unactivatedMembers={unactivatedMembers} />}
        content="You can still add this team to TimeZest, and activate these members later. Until they are activated, TimeZest
          won't schedule for them."
      />

      <input type="submit" value="Add Team to TimeZest >" className="btn btn-primary" />
    </>
  );
};

export default MembersList;

import type { FC } from 'react';
import { useContext, useState } from 'react';

import PlainTextRow from '@/ui/PlainTextRow';
import RadioButtonRow from '@/ui/RadioButtonRow';
import TextInputRow from '@/ui/TextInputRow';
import Row from '@/ui/Row';
import AccountContext from '@/AccountContext';

import InputGroup from '@shared/ui/Inputs/InputGroup';
import { psaName } from '@shared/utilities';

interface Props {
  id: string;
  externalName: string | null;
  name: string;
  slug: string;
  schedulingAlgorithm: string;
  errors: { [attr: string]: string };
  allowUrlBasedScheduling: boolean;
}

const DetailsConfig: FC<Props> = ({
  id,
  externalName,
  name,
  slug: initialSlug,
  schedulingAlgorithm: initialSchedulingAlgorithm,
  errors,
  allowUrlBasedScheduling,
}) => {
  const { psaType } = useContext(AccountContext);

  const [schedulingAlgorithm, setSchedulingAlgorithm] = useState(initialSchedulingAlgorithm);
  const [slug, setSlug] = useState(initialSlug);
  const { host } = useContext(AccountContext);

  const handleSchedulingAlgorithmChange = (newSchedulingAlgorithm: string): void => {
    setSchedulingAlgorithm(newSchedulingAlgorithm);
  };

  return (
    <>
      <PlainTextRow label="Name" helpText={`The name of this team as configured in ${psaName(psaType)}.`}>
        <div className="fw-bold">{name}</div>
      </PlainTextRow>
      <TextInputRow
        name="psa_team[external_name]"
        value={externalName}
        label="External Name"
        helpText="The name of this team we'll use when communicating with your clients. If left blank, TimeZest will use the internal name."
        error={errors.externalName}
      />
      {allowUrlBasedScheduling && (
        <Row
          label="URL"
          helpText={
            <>
              For URL-triggered scheduling, this slug is what identifies this member in the URL - e.g.&nbsp;
              <code>level-1-helpdesk</code>.
            </>
          }
        >
          <InputGroup
            name="psa_team[slug]"
            value={slug}
            error={errors.slug}
            prepend={`${host}/`}
            append="/<appointment type>"
            onChange={e => setSlug(e.target.value)}
          />
        </Row>
      )}
      <RadioButtonRow
        id={`team_${id}`}
        name="psa_team[scheduling_algorithm]"
        label="Scheduling Algorithm"
        value={schedulingAlgorithm}
        options={[
          {
            value: 'round_robin',
            label: 'Round-Robin',
            appendedElement:
              'TimeZest will allocate appointments to members of the team on a round-robin basis, so they each receive approximately the same number of appointments.',
          },
          {
            value: 'max_availability',
            label: 'Maximum Availability',
            appendedElement:
              'TimeZest will offer the maximum possible number of available appointments to clients, even if this results in certain members receiving a large number of appointments.',
          },
        ]}
        onChange={handleSchedulingAlgorithmChange}
      />
    </>
  );
};

export default DetailsConfig;

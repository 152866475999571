import type { FC } from 'react';

import Alert from '@shared/Alert';

interface Props {
  unauthorized: boolean;
  error?: string;
}

const ErrorDetail: FC<Props> = ({ error, unauthorized }) => {
  if (unauthorized) {
    return (
      <Alert
        icon="circle-xmark"
        color="red"
        title="TimeZest was unable to create the Insight, because the API user did not have the necessary permissions."
        content={
          <p className="mb-0">
            Ensure that the security level for the TimeZest API user has been set correctly. Update permissions for{' '}
            <strong>TimeZest Security Level</strong> in the <strong>Security Levels</strong> section of{' '}
            <strong>Resources/Users (HR)</strong> panel, and try again.
          </p>
        }
      />
    );
  }

  return (
    <Alert
      icon="circle-xmark"
      color="red"
      content={
        <>
          <h5 className="d-inline-block">
            TimeZest was unable to create the Insight, because Autotask rejected the request with error:
          </h5>
          <span>{error}</span>
        </>
      }
    />
  );
};

export default ErrorDetail;

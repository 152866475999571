import type { FC } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetTeamsQuery } from '@graphql/generated';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  teamId: string | undefined;
  templateMode?: boolean;
  onTeamChange: (id: string) => void;
}

const TeamSelect: FC<Props> = ({ teamId, error, readOnly, templateMode, onTeamChange }) => {
  const queryResult = useGetTeamsQuery({ skip: templateMode });

  const teams = queryResult.data?.microsoftTeams.teams || [];
  const team = teams.find(team => team.id === teamId);

  const handleTeamChange = (value: string) => {
    if (value !== teamId) {
      onTeamChange(value);
    }
  };

  return (
    <>
      <SelectRowFromGraphQL
        name="team_id"
        label="Team"
        helpText="TimeZest will post the message to the selected channel in the selected team."
        field="microsoftTeams.teams"
        loadingMessage="Loading Teams from Microsoft Teams..."
        emptyDataMessage="Microsoft Teams (Messaging) integration has not been installed in any of your teams."
        queryErrorMessage="Error loading Teams from Microsoft Teams."
        prompt="Select a team..."
        value={teamId || ''}
        error={error}
        readOnly={readOnly}
        templateMode={templateMode}
        queryResult={queryResult}
        onChange={handleTeamChange}
      />
      <input type="hidden" name="team_name" value={team?.name || ''} />
    </>
  );
};

export default TeamSelect;

import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityStatusSelectQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const STATUS_HELP_TEXT =
  'TimeZest will search for an existing opportunity with this status, and will use this status for an opportunity that it creates in ConnectWise PSA.';
interface Props {
  error: string | undefined;
  readOnly?: boolean;
  statusId: string;
  templateMode?: boolean;
}

const StatusSelect: FC<Props> = ({ statusId: initialStatusId, error, readOnly, templateMode }) => {
  const [statusId, setStatusId] = useStateFromProp(initialStatusId);

  const queryResult = useGetOpportunityStatusSelectQuery({ skip: templateMode });

  const status = (queryResult.data?.connectWise.opportunityStatuses || []).find(s => s.id.toString() === statusId);

  const handleStatusChange = (value: string) => {
    if (value !== statusId) {
      setStatusId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      helpText={STATUS_HELP_TEXT}
      label="Status"
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity status details from ConnectWise PSA because the security role does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the <strong>Table Setup</strong> permission in the <strong>System</strong> section of the
          security role has <strong>Opportunities / Status</strong> in the <strong>Allow Access to These</strong>{' '}
          column.
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity statuses from ConnectWise PSA."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        helpText={STATUS_HELP_TEXT}
        label="Status"
        name="status_id"
        field="connectWise.opportunityStatuses"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Use default value configured in ConnectWise PSA"
        emptyDataMessage="No opportunity statuses found."
        templateMode={templateMode}
        readOnly={readOnly}
        error={error}
        value={statusId}
        queryErrorMessage={queryErrorMessage}
        queryResult={queryResult}
        onChange={handleStatusChange}
      />

      <input type="hidden" name="status_name" value={status?.name || ''} />
    </>
  );
};

export default StatusSelect;

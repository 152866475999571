import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import type { IconName } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  className?: string;
  disabled?: boolean;
  icon?: IconName;
  text?: string;
}

const SubmitButton: FC<PropsWithChildren<Props>> = ({ children, className, disabled, icon, text }) => {
  const [clicked, setClicked] = useState(false);

  const handleSubmit = (e: React.MouseEvent) => {
    if (clicked) {
      e.preventDefault();
      return false;
    }

    setClicked(true);
  };

  return (
    <button className={className} disabled={disabled} type="submit" onClick={handleSubmit}>
      {children ? (
        children
      ) : (
        <>
          {text}
          {icon && (
            <>
              &nbsp; <FontAwesomeIcon icon={icon} />
            </>
          )}
        </>
      )}
    </button>
  );
};

export default SubmitButton;

import type {
  Action,
  HaloPsaCreateTicketAction,
  HaloPsaCreateAppointmentAction,
  HaloPsaUpdateTicketStatusAction,
  HaloPsaUpdateTicketToPresetStatusAction,
  HaloPsaCreateActionOnTicketAction,
  HaloPsaCreateUserAction,
} from '@graphql/generated';

export function isHaloPSACreateAppointment(action: Action): action is HaloPsaCreateAppointmentAction {
  return action.slug === 'halo_psa/create_appointment';
}

export function isHaloPSACreateActionOnTicket(action: Action): action is HaloPsaCreateActionOnTicketAction {
  return action.slug === 'halo_psa/create_action_on_ticket';
}

export function isHaloPSACreateTicket(action: Action): action is HaloPsaCreateTicketAction {
  return action.slug === 'halo_psa/create_ticket';
}

export function isHaloPSACreateUser(action: Action): action is HaloPsaCreateUserAction {
  return action.slug === 'halo_psa/create_user';
}

export function isHaloPSAUpdateTicketStatus(action: Action): action is HaloPsaUpdateTicketStatusAction {
  return action.slug === 'halo_psa/update_ticket_status';
}

export function isHaloPSAUpdateTicketToPresetStatus(action: Action): action is HaloPsaUpdateTicketToPresetStatusAction {
  return action.slug === 'halo_psa/update_ticket_to_preset_status';
}

import type { FC } from 'react';

interface Props {
  activeUsers: number;
}

const Totals: FC<Props> = ({ activeUsers }) => {
  return (
    <div className="card bg-light mb-3">
      <div className="card-body py-2">
        <div className="row">
          <div className="col-6">
            <span className="fw-bold">Total Active Users:</span>
            &nbsp;
            {activeUsers}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Totals;

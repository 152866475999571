import type { FC } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface Props {
  text: string | JSX.Element;
  icon: JSX.Element;
}

const Overlay: FC<Props> = ({ text, icon }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          {typeof text === 'string' ? (
            <span className="d-block text-start">{text}</span>
          ) : (
            <div className="d-grid gap-1 text-start">{text}</div>
          )}
        </Tooltip>
      }
    >
      {icon}
    </OverlayTrigger>
  );
};

export default Overlay;

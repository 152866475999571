import { forwardRef } from 'react';

import type { WorkflowActionProps } from '@/ui/Workflow/types';

import TaskStateSelect from './TaskStateSelect';
import TaskTypeSelect from './TaskTypeSelect';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { ServiceNowUpdateTaskStateAction } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import useStateFromProp from '@shared/hooks/useStateFromProp';

import FormError from '../../FormError';
import { useUpdateTaskStateForServiceNow } from '../../hooks';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update the state of an associated task to the configured status.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When no ServiceNow integration is configured.</li>
    </ul>
  </>
);

const UpdateTaskState = forwardRef<HTMLFormElement, WorkflowActionProps<ServiceNowUpdateTaskStateAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const [taskType, setTaskType] = useStateFromProp(action.taskType);
    const [taskStateId, setTaskStateId] = useStateFromProp<number | null>(action.psaStateId);

    const { errors: mutationErrors, loading, succeeded, submit } = useUpdateTaskStateForServiceNow();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        taskType: (data.get('task_type') || '') as string,
        psaStateId: Number(data.get('psa_state_id') || ''),
        psaStateName: (data.get('psa_state_name') || '') as string,
      };
    });

    const handleChangeTaskType = (value: string) => {
      setTaskType(value);
      setTaskStateId(null);
    };

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="chat-plus"
            summary={summary(action.taskType)}
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <TaskTypeSelect
              error={errors.task_type}
              taskType={action.taskType}
              onTaskTypeChange={handleChangeTaskType}
            />
            <TaskStateSelect
              error={errors.psa_state_name}
              taskType={taskType}
              taskStateId={taskStateId}
              onTaskStateChange={setTaskStateId}
            />

            {errors.base && (
              <div className="row mt-minus-3 mb-3">
                <div className="col-sm-10 offset-md-2">
                  <div className="invalid-feedback d-block">{errors.base}</div>
                </div>
              </div>
            )}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

function summary(taskType: string): string {
  switch (taskType) {
    case 'incident':
      return 'Update state for a ServiceNow Incident.';
    case 'problem':
      return 'Update state for a ServiceNow Problem.';
    case 'change_request':
      return 'Update state for a ServiceNow Change Request.';
    default:
      return 'Update state for a ServiceNow Incident/Problem/Change Request.';
  }
}

export default UpdateTaskState;

import type { FC } from 'react';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  name: string;
  project: boolean;
}

const ClosedStatusWarning: FC<Props> = ({ project, name }) => {
  const permission = project ? 'Close Project Ticket' : 'Close Service Ticket';

  return (
    <div className="small text-info mt-1">
      <FontAwesomeIcon icon="exclamation-triangle" />
      &nbsp; As the <strong>{name}</strong> status closes a ticket, ensure that the TimeZest API user has the{' '}
      <strong>{permission}</strong> permission for this to work correctly.
    </div>
  );
};

export default ClosedStatusWarning;

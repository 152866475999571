import type { FC } from 'react';

interface Props {
  name: string;
}

const UpdateStatus: FC<Props> = ({ name }) => {
  return (
    <div className="small">
      &ndash;&nbsp;Update ticket status to <strong>{name}</strong> after the client selects a time.
    </div>
  );
};

export default UpdateStatus;

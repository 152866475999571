import type { FC } from 'react';

interface Props {
  name: string;
}

const NoResponseStatus: FC<Props> = ({ name }) => {
  return (
    <div className="small">
      &ndash;&nbsp;If the configured duration for the appointment type passes without the client selecting an
      appointment, update the ticket status to <strong>{name}</strong>.
    </div>
  );
};

export default NoResponseStatus;

import type { FC } from 'react';
import { forwardRef } from 'react';

import CompanySearch from '@/ui/CompanySearch';

import FormError from '../FormError';
import { useCreateContactForAutotask } from '../hooks';

import Action from '@shared/Action';
import RadioButtonRow from '@ui/RadioButtonRow';
import Row from '@ui/Row';
import type { AutotaskCreateContactAction, CreateContactMode } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import ErrorBoundary from '@shared/ErrorBoundary';
import useFormSubmit from '@shared/hooks/useFormSubmit';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">When TimeZest executes this action, it will create a new contact in Autotask.</p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When there is already a contact associated with the scheduling request - for example, the contact associated
        with an existing Autotask ticket.
      </li>
      <li>When there is already an existing contact with the client&apos;s email address in Autotask.</li>
      <li>
        When <strong>all</strong> of the users being scheduled do not use Autotask as their calendar.
      </li>
      <li>When no Autotask integration is configured.</li>
    </ul>
    <p>
      When this action is included in a workflow, TimeZest will ask the user for their name, email address and company
      name during scheduling.
    </p>
  </>
);

interface Props {
  modes: { value: CreateContactMode; label: string }[];
}

const CreateContact = forwardRef<HTMLFormElement, WorkflowActionProps<AutotaskCreateContactAction, Props>>(
  ({ action, readOnly, modes: availableModes, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateContactForAutotask();
    const [mode, setMode] = useStateFromProp(action.mode);
    const modes = availableModes.map(m => ({ ...m, disabled: readOnly }));

    const handleModeChange = (newMode: CreateContactMode): void => {
      setMode(newMode);
    };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        companyId: (data.get('psa_company_id') || '') as string,
        companyName: (data.get('psa_company_name') || '') as string,
        mode: data.get('mode') as CreateContactMode,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="user-b"
            summary={
              <>
                Create a <strong>contact</strong> in Autotask.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="mode"
              label="New Contact Handling"
              value={mode || 'only_known_email'}
              options={modes}
              helpText="This setting determines what TimeZest will do if it can't match an existing contact."
              error={errors.mode}
              onChange={handleModeChange}
            />
            {mode === 'use_catchall' && (
              <CatchAllCompany
                companyId={action.psaCompanyId || null}
                companyName={action.psaCompanyName || null}
                error={errors.psa_company_id}
                readOnly={readOnly}
              />
            )}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateContact;

interface CatchAllCompanyProps {
  companyId: string | null;
  companyName: string | null;
  error: string | null;
  readOnly: boolean;
}

const CatchAllCompany: FC<CatchAllCompanyProps> = ({ companyName, companyId, error, readOnly }) => {
  const [selectedCompanyName, setSelectedCompanyName] = useStateFromProp(companyName, companyName => companyName || '');

  const handleChange = (_, newCompanyName: string) => {
    setSelectedCompanyName(newCompanyName);
  };

  return (
    <Row label="Catch-all Company">
      <input type="hidden" name="psa_company_name" value={selectedCompanyName} />

      <CompanySearch
        name="psa_company_id"
        value={companyId ? parseInt(companyId, 10) : null}
        displayName={selectedCompanyName || ''}
        error={error || ''}
        readOnly={readOnly}
        onChange={handleChange}
      />
    </Row>
  );
};

import type { FC, PropsWithChildren } from 'react';

interface Props {
  label: string;
}

const BasicDetailRow: FC<PropsWithChildren<Props>> = ({ label, children }) => {
  return (
    <div className="row mb-3 px-3">
      <div className="col-2">{label}</div>
      <div className="col-10">{children}</div>
    </div>
  );
};

export default BasicDetailRow;

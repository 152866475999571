import type { FC } from 'react';

import Duration from '@/ui/Duration';
import type { Unit } from '@/ui/Duration';

import type { Attribute, PossibleValues } from '../types';

import CompanySearch from './CompanySearch';
import DateSelect from './DateSelect';
import StandardValueControl from './StandardValueControl';

export interface ValueControlProps {
  attribute: Attribute | undefined;
  value: string;
  valueName: string;
  index: number;
  possibleValues: PossibleValues;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onValueChange: (newValue: string, newValueName?: string) => void;
}

const DURATION_UNITS = ['minutes', 'hours'] as Unit[];

const ValueControl: FC<ValueControlProps> = props => {
  const { attribute, index, possibleValues, valueName, ...commonProps } = props;

  if (!attribute) return;

  switch (attribute.specialControlType) {
    case 'company':
      return (
        <div className="position-relative">
          <CompanySearch displayName={valueName || ''} selectedAttributeId={attribute.value} {...commonProps} />
        </div>
      );
    case 'datepicker':
      return <DateSelect index={index} {...commonProps} />;
    case 'duration':
      return (
        <Duration
          name={`filter-${index}-duration-control`}
          initialValue={Number(props.value)}
          unit="minutes"
          units={DURATION_UNITS}
          onKeyDown={props.onKeyDown}
          onChange={props.onValueChange}
        />
      );
    default:
      return (
        <StandardValueControl
          attribute={attribute}
          index={index}
          possibleValues={possibleValues}
          valueName={valueName}
          {...commonProps}
        />
      );
  }
};

export default ValueControl;

import type { FC } from 'react';

import Badge, { type BadgeColor } from '@/ui/Badge';

import { psaTeamName, teamMembers } from '@shared/utilities';
import type { TeamType } from '@models/Team';

const BADGE_COLOR: Record<string, BadgeColor> = {
  halo_psa: 'green',
  service: 'green',
  project: 'yellow',
};

interface Props {
  deletedInPsa: boolean;
  fetched: boolean;
  id: number;
  members: string[];
  name: string;
  teamType: TeamType;
}

const PsaTeam: FC<Props> = ({ deletedInPsa, fetched, id, members, name, teamType }) => {
  const fetchFailureMessage =
    teamType === 'halo_psa' ? 'Could not fetch members from HaloPSA' : 'Could not fetch members from ConnectWise PSA';
  const deletedTeamBadge = teamType === 'halo_psa' ? 'Deleted in HaloPSA' : 'Deleted in ConnectWise PSA';

  return (
    <a className="team" href={`/settings/teams/${teamType}/${id}`}>
      <div className="card mb-2">
        <div className="card-body py-2">
          <div className="d-flex">
            <div className="title flex-grow-1">
              <strong>{name}</strong>
              &nbsp;
              <Badge color={BADGE_COLOR[teamType]}>{psaTeamName(teamType)}</Badge>
              {deletedInPsa && <Badge color="red">{deletedTeamBadge}</Badge>}
            </div>
          </div>
          <div className="info">
            <div className="small text-muted">{fetched ? <>{teamMembers(members)}</> : <>{fetchFailureMessage}</>}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default PsaTeam;

import type { FC } from 'react';

import RadioButtonRow from '@/ui/RadioButtonRow';
import Expander from '@/ui/Expander';

import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import TextInputRow from '@ui/TextInputRow';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { PhoneNumberField } from '@models/AppointmentType';

type Action = String;
type Trigger = String;

export type Fields = Record<string, [Trigger, Action]>;

interface Props {
  appointmentType: AppointmentTypeModel;
  canExpand: boolean;
  dirty: boolean;
  fieldsErrors: Record<string, Errors>;
  expanded: boolean;
  requiredFields: Fields;
  url: string;
  onExpand: (expanded: boolean) => void;
}

const FieldsConfig: FC<Props> = ({
  appointmentType,
  canExpand,
  dirty,
  fieldsErrors,
  expanded,
  requiredFields,
  url,
  onExpand,
}) => {
  const disabledCompanyName = !required(requiredFields, 'company.name');
  const disabledCustomerEmail = !required(requiredFields, 'client.email');
  const disabledCustomerName = !required(requiredFields, 'client.firstName', 'client.lastName', 'client.name');
  const disabledCustomerPhone = !required(requiredFields, 'client.phoneNumber', 'client.phoneType');
  const disabledIssueDescription = !required(requiredFields, 'appointment.issueDescription');
  const [companyVisible, setCompanyVisible] = useStateFromProp(appointmentType.companyNameField.visible);
  const [phoneVisible, setPhoneVisible] = useStateFromProp(appointmentType.phoneNumberField.visible);
  const [issueVisible, setIssueVisible] = useStateFromProp(appointmentType.issueDescriptionField.visible);
  const [phoneMode, setPhoneMode] = useStateFromProp(appointmentType.phoneNumberField.mode);

  const handleChangePhoneNumber = (newValue: PhoneNumberField['mode']) => {
    setPhoneVisible(newValue !== 'off' ? 'if_not_known' : 'never');
    setPhoneMode(newValue as PhoneNumberField['mode']);
  };

  return (
    <Expander
      title="Fields"
      summary="Configure input fields for appointment confirmation form."
      url={url}
      method="PATCH"
      icon="events"
      hasErrors={fieldsHasErrors(fieldsErrors)}
      expanded={expanded}
      canExpand={canExpand}
      dirty={dirty}
      onExpand={onExpand}
    >
      <TextInputRow
        name="appointment_type[customer_name_field][label]"
        value={appointmentType.customerNameField.label || ''}
        label="Client Name"
        helpText="Label used for customer name input on scheduling confirmation form."
        disabled={disabledCustomerName}
        error={error(fieldsErrors, 'customerNameField', 'label')}
        warning={
          disabledCustomerName
            ? 'Clients will only be asked for this field when it is required by an action in the workflow'
            : undefined
        }
      />
      <hr />
      <TextInputRow
        name="appointment_type[customer_email_field][label]"
        value={appointmentType.customerEmailField.label || ''}
        label="Client Email"
        helpText="Label used for customer email input on scheduling confirmation form."
        disabled={disabledCustomerEmail}
        error={error(fieldsErrors, 'customerEmailField', 'label')}
        warning={
          disabledCustomerEmail
            ? 'Clients will only be asked for this field when it is required by an action in the workflow'
            : undefined
        }
      />
      <hr />
      <TextInputRow
        name="appointment_type[company_name_field][label]"
        value={appointmentType.companyNameField.label || ''}
        label="Company Name"
        helpText="Label used for company name input on scheduling confirmation form."
        disabled={disabledCompanyName}
        error={error(fieldsErrors, 'companyNameField', 'label')}
        warning={
          disabledCompanyName
            ? 'Clients will only be asked for this field when it is required by an action in the workflow'
            : undefined
        }
      />
      <RadioButtonRow
        name="appointment_type[company_name_field][visible]"
        value={companyVisible}
        label=""
        options={[
          { label: 'Ask end user if not known', value: 'if_not_known', disabled: disabledCompanyName },
          { label: 'Never ask', value: 'never', disabled: disabledCompanyName },
        ]}
        warning={
          companyVisible === 'never' && !disabledCompanyName ? warning('company.name', requiredFields) : undefined
        }
        onChange={setCompanyVisible}
      />
      <hr />
      <TextInputRow
        name="appointment_type[phone_number_field][label]"
        value={appointmentType.phoneNumberField.label || ''}
        label="Phone Number"
        helpText="Label used for customer phone input on scheduling confirmation form."
        disabled={disabledCustomerPhone}
        error={error(fieldsErrors, 'phoneNumberField', 'label')}
        warning={
          disabledCustomerPhone
            ? 'Clients will only be asked for this field when it is required by an action in the workflow'
            : undefined
        }
      />
      <RadioButtonRow
        name="appointment_type[phone_number_field][mode]"
        value={phoneVisible === 'never' ? 'off' : phoneMode}
        label=""
        options={[
          { label: 'Require from end user if not known', value: 'required', disabled: disabledCustomerPhone },
          { label: 'Ask end user if not known', value: 'optional', disabled: disabledCustomerPhone },
          { label: 'Never ask', value: 'off', disabled: disabledCustomerPhone },
        ]}
        error={error(fieldsErrors, 'phoneNumberField', 'mode')}
        warning={phoneVisible === 'never' ? warning('client.phoneNumber', requiredFields) : undefined}
        onChange={handleChangePhoneNumber}
      />
      <hr />
      <TextInputRow
        name="appointment_type[issue_description_field][label]"
        value={appointmentType.issueDescriptionField?.label || ''}
        label="Issue Description"
        helpText="Label used for issue description input on scheduling confirmation form."
        disabled={disabledIssueDescription}
        error={error(fieldsErrors, 'issueDescriptionField', 'text')}
        warning={
          disabledIssueDescription
            ? 'Clients will only be asked for this field when it is required by an action in the workflow'
            : undefined
        }
      />
      <RadioButtonRow
        name="appointment_type[issue_description_field][visible]"
        value={appointmentType.issueDescriptionField.visible}
        label=""
        options={[
          { label: 'Ask end user if not known', value: 'if_not_known', disabled: disabledIssueDescription },
          { label: 'Never ask', value: 'never', disabled: disabledIssueDescription },
        ]}
        warning={
          issueVisible === 'never' && !disabledIssueDescription
            ? warning('appointment.issueDescription', requiredFields)
            : undefined
        }
        onChange={setIssueVisible}
      />
    </Expander>
  );
};

export function fieldsHasErrors(errors: Record<string, Errors>): boolean {
  return Object.values(errors).some(f => Object.keys(f).length > 0);
}

function error(errors: Record<string, Errors>, field: string, attribute: string): string | undefined {
  const field_errors = errors[field];
  if (!field_errors) return;

  return field_errors[attribute];
}

function required(requiredFields: Fields, ...fields: string[]): boolean {
  return !!fields.find(f => requiredFields[f]);
}

function warning(field: string, requiredFields: Fields): JSX.Element {
  const [trigger, action] = requiredFields[field];

  return (
    <p className="mb-0">
      The value for this field is used by the <strong>{action}</strong> action in the <strong>{trigger}</strong>{' '}
      trigger, and this appointment type may not function as expected when it is not present. We recommend careful
      testing.
    </p>
  );
}

export default FieldsConfig;

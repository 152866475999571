import type { FC } from 'react';
import { useState, useEffect, useCallback } from 'react';

import TeamSelect from '@/haloPsa/TeamSelect';
import Form from '@/Form';

import Members from '@ui/NewHaloPsaTeam/Members';
import Row from '@ui/Row';
import type { Member } from '@models/TeamMember';

const NewHaloPsaTeam: FC = () => {
  const [teamId, setTeamId] = useState<number | undefined>();

  const [teamLoading, setTeamLoading] = useState(false);
  const [activatedMembers, setActivatedMembers] = useState<Member[]>([]);
  const [unactivatedMembers, setUnactivatedMembers] = useState<Member[]>([]);

  const constructTeamUrl = useCallback((): string => {
    return teamId ? `/halopsa/teams/${teamId}` : '';
  }, [teamId]);

  const loadTeam = useCallback((): void => {
    setTeamLoading(true);

    fetch(constructTeamUrl(), { headers: { Accept: 'application/json' } })
      .then(response => response.json())
      .then(json => {
        const members = json.members.map(m => {
          const { active: schedulable, ...rest } = m;
          return { schedulable, ...rest };
        });

        setActivatedMembers(members.filter((m: Member) => m.schedulable));
        setUnactivatedMembers(members.filter((m: Member) => !m.schedulable));
        setTeamLoading(false);
      });
  }, [constructTeamUrl]);

  const handleTeamIdChange = (newTeamId: number | undefined): void => {
    setTeamId(newTeamId);
  };

  useEffect(() => {
    if (teamId) {
      loadTeam();
    }
  }, [loadTeam, teamId]);

  return (
    <Form url="/settings/teams/halo_psa">
      <TeamSelect
        name="psa_team[team_id]"
        selectedTeamId={teamId}
        label="HaloPSA Team"
        onTeamIdChange={handleTeamIdChange}
      />
      <Row>
        <Members
          loading={teamLoading}
          selectedTeamId={teamId}
          activatedMembers={activatedMembers}
          unactivatedMembers={unactivatedMembers}
        />
      </Row>
    </Form>
  );
};

export default NewHaloPsaTeam;

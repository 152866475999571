import { useTranslation } from 'react-i18next';

export default function useDisplayDuration(): (durationMins: number) => string {
  const { t } = useTranslation('translation', { keyPrefix: 'units' });

  return function displayDuration(durationMins: number) {
    if (durationMins > 60 && durationMins % 30 === 0) {
      return t('hour', { count: durationMins / 60 });
    } else if (durationMins >= 60) {
      const hours = Math.floor(durationMins / 60);
      const hoursDisplay = t('hour', { count: hours });
      const minutes = durationMins - hours * 60;

      return minutes === 0 ? `${hoursDisplay}` : `${hoursDisplay}, ${t('minute', { count: minutes })}`;
    } else {
      return t('minute', { count: durationMins });
    }
  };
}

import { forwardRef } from 'react';

import type { Unit } from '@/ui/DurationRow';
import DurationRow from '@/ui/DurationRow';

import { useCreateEventForOffice365 } from '../hooks';
import FormError from '../FormError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { Office365CreateEventAction, EventType } from '@graphql/generated';
import type { Content } from '@shared/NoteEditor';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import Row from '@ui/Row';
import SelectRow from '@ui/SelectRow';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create an event in Microsoft 365 <strong>only</strong> for those users
      who have Microsoft 365 configured as their calendar to write to.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When the scheduling request has been cancelled.</li>
      <li>When no Microsoft 365 integration is configured.</li>
    </ul>
    <p>
      This is a <strong>critical action</strong>, which means that if it doesn&apos;t complete successfully, no actions
      for this trigger after it will be executed.
    </p>
  </>
);

const REMINDER_UNITS: Unit[] = ['minutes', 'hours', 'days'];

interface Props {
  eventTypes: { name: string; value: string }[];
}

const CreateEvent = forwardRef<HTMLFormElement, WorkflowActionProps<Office365CreateEventAction, Props>>(
  ({ eventTypes, action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateEventForOffice365();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        description: (data.get('description') || '') as string,
        eventType: data.get('event_type') as EventType,
        reminderMins: Number(data.get('reminder_mins') || ''),
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="calendar-plus"
            summary="Create an event in the scheduled user's Microsoft 365 Calendar."
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <SelectRow
              name="event_type"
              label="Show Time as"
              error={errors.event_type}
              disabled={readOnly}
              helpText="TimeZest will create the event as this type in Microsoft 365."
              options={eventTypes}
              value={action.eventType}
            />

            <DurationRow
              name="reminder_mins"
              label="Reminder"
              disabled={readOnly}
              error={errors.reminder_mins}
              helpText="If this value is set, TimeZest will set a reminder to display this amount of time before the appointment on the created event in Microsoft 365."
              value={action.reminderMins || 0}
              units={REMINDER_UNITS}
            />

            <Row label="Description" width={10}>
              <NoteEditor
                name="description"
                initialValue={(action.description as Content) || defaultTemplate}
                readOnly={readOnly}
              />
            </Row>
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateEvent;

import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityCategorySelectForAutotaskQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const HELP_TEXT = 'TimeZest will create the opportunity in Autotask with this category.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  categoryId: string | undefined;
  templateMode?: boolean;
}

const CategorySelect: FC<Props> = ({ categoryId: initialCategoryId, error, readOnly, templateMode }) => {
  const queryResult = useGetOpportunityCategorySelectForAutotaskQuery({ skip: templateMode });

  const [categoryId, setCategoryId] = useStateFromProp(initialCategoryId, initialCategoryId => initialCategoryId || '');

  const categories = queryResult.data?.autotask.opportunityCategories || [];
  const category = categories.find(c => c.id === categoryId);

  const handleCategoryChange = (value: string) => {
    if (value !== categoryId) {
      setCategoryId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      label="Category"
      helpText={HELP_TEXT}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity category details from Autotask because the API user does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the security level grants <strong>Sales & Opportunities</strong> under the{' '}
          <strong>Admin</strong> section.
          <a
            href="https://help.timezest.com/en/articles/4840136-creating-a-security-level-for-autotask"
            target="_blank"
            rel="noreferrer"
            className="d-flex align-items-center d-block w-75 m-0 mt-3"
          >
            You can find full details here <FontAwesomeIcon icon="external-link" size="sm" className="ms-1" />
          </a>
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity categories from Autotask."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        name="psa_category_id"
        label="Category"
        loadingMessage="Loading opportunity categories from Autotask..."
        field="autotask.opportunityCategories"
        emptyDataMessage="No opportunity categories found."
        prompt="Use default value configured in Autotask"
        error={error}
        readOnly={readOnly}
        templateMode={templateMode}
        value={categoryId}
        helpText={HELP_TEXT}
        queryResult={queryResult}
        queryErrorMessage={queryErrorMessage}
        onChange={handleCategoryChange}
      />
      <input type="hidden" name="psa_category_name" value={category?.name || ''} />
    </>
  );
};

export default CategorySelect;

import type { FC } from 'react';

import type { FieldProps } from './Field';
import Field from './Field';
import Input from './Input';

type Spacing = {
  setAll?: boolean;
  all?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
};

interface Props extends Omit<FieldProps, 'className' | 'error'> {
  value: Spacing;
  disabled: boolean;
  onInput?: (value: Spacing) => void;
}

const Space: FC<Props> = ({ id, label, value, disabled, onInput }) => {
  const setIndividually = !value.setAll;

  const handleChangeAll = (newValue: string) => {
    if (onInput) onInput({ ...value, all: newValue });
  };

  const handleChange = (property: string) => (newValue: any) => {
    if (onInput) onInput({ ...value, [property]: newValue });
  };

  return setIndividually ? (
    <Field
      id={id}
      label={
        <div className="d-flex align-items-center justify-content-between">
          <label className="form-label" htmlFor={id}>
            {label}
          </label>
          <label className="form-check form-switch text-muted small" htmlFor={`${id}-set-all`}>
            Set Individually
            <input
              id={`${id}-set-all`}
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={setIndividually}
              disabled={disabled}
              onChange={() => handleChange('setAll')(!value.setAll)}
            />
          </label>
        </div>
      }
    >
      <div className="row">
        <Input
          id={`${id}-top`}
          className="mb-3 col-sm-6"
          label="Top"
          value={value.top || '0px'}
          type="number"
          unit="px"
          min={0}
          disabled={disabled}
          onInput={handleChange('top')}
          onChange={handleChange('top')}
        />
        <Input
          id={`${id}-bottom`}
          className="mb-3 col-sm-6"
          label="Bottom"
          value={value.bottom || '0px'}
          type="number"
          unit="px"
          min={0}
          disabled={disabled}
          onChange={handleChange('bottom')}
          onInput={handleChange('bottom')}
        />
        <Input
          id={`${id}-left`}
          className="mb-0 col-sm-6"
          label="Left"
          value={value.left || '0px'}
          type="number"
          unit="px"
          min={0}
          disabled={disabled}
          onChange={handleChange('left')}
          onInput={handleChange('left')}
        />
        <Input
          id={`${id}-right`}
          className="mb-0 col-sm-6"
          label="Right"
          value={value.right || '0px'}
          type="number"
          unit="px"
          min={0}
          disabled={disabled}
          onChange={handleChange('right')}
          onInput={handleChange('right')}
        />
      </div>
    </Field>
  ) : (
    <Input
      id={id}
      label={
        <div className="d-flex align-items-center justify-content-between">
          <label className="form-label" htmlFor={id}>
            {label}
          </label>
          <label className="form-check form-switch text-muted small" htmlFor={`${id}-set-all`}>
            Set Individually
            <input
              id={`${id}-set-all`}
              className="form-check-input"
              type="checkbox"
              role="switch"
              disabled={disabled}
              checked={setIndividually}
              onChange={() => handleChange('setAll')(!value.setAll)}
            />
          </label>
        </div>
      }
      value={value.all || '0px'}
      type="number"
      unit="px"
      min={0}
      disabled={disabled}
      onChange={handleChangeAll}
      onInput={handleChangeAll}
    />
  );
};

export default Space;

import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import ReactPaginate from 'react-paginate';
import { captureException } from 'shared/sentry';

import { ErrorMessage } from '@/ui/ErrorMessage';
import { getCSRFToken, throwOnError, throwOnNonValidationError } from '@/utilities';
import PlanContext from '@/PlanContext';
import asScreen from '@/Screen';
import Flash from '@/Flash';
import Header from '@/Header';

import Totals from './Totals';
import NoUsers from './NoUsers';
import UsersFilter from './Filter';
import UserListItem from './UserListItem';

import type { User } from '@graphql/generated';
import { useGetUsersTqlQuery } from '@graphql/generated';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type { Filter } from '@models/Filter';
import { generateTQLString } from '@shared/ui/Filter/utilities';
import type { UserWithAvailabilityModel } from '@models/User';
import type Availability from '@models/Availability';

type MetaData = {
  totalPages?: number;
  totalActiveUsers: number;
};

interface Props {
  initialActiveUsers: number;
  tqlFilters: Filter[];
}

const PAGE_SIZE = 20;

const UsersIndex: FC<Props> = ({ initialActiveUsers, tqlFilters }) => {
  const plan = useContext(PlanContext);

  const [processingUsers, setProcessingUsers] = useState<User[]>([]);
  const [offset, setOffset] = useState(0);
  const [tqlString, setTqlString] = useState(generateTQLString(tqlFilters));
  const [error, setError] = useState<string | null>(null);

  const {
    data,
    error: requestError,
    loading,
  } = useGetUsersTqlQuery({
    variables: { filter: tqlString, limit: PAGE_SIZE, offset },
    onCompleted(data) {
      setUsers(data?.timezest.users.nodes);
    },
  });

  const [users, setUsers] = useState<User[]>([]);
  const [activeUsers, setActiveUsers] = useState<number>(initialActiveUsers);

  useEffect(() => {
    setOffset(0);
  }, [tqlString]);

  const requestsData = data?.timezest.users;

  const { page: currentPage, pages: pageCount } = requestsData || { page: 1, pages: 1 };

  const rescueFromError = (error: Error): void => {
    setError('TimeZest encountered an unexpected error. Please reload the page and try again.');
    captureException(error);
  };

  const updateMetaData = (meta: MetaData): void => {
    setActiveUsers(meta.totalActiveUsers);
  };

  const updateUser = (data: UserWithAvailabilityModel): void => {
    const { email } = data.user;
    const index = users.findIndex(u => u.email.toLowerCase() === email.toLowerCase());

    if (index < 0) return;

    const initialUsers = users.slice();
    const newUser: User = {
      ...data.user,
      availability: {
        ...(data.availability as Availability),
        availabilityBlocks: [...data.availabilityBlocks],
      },
    };

    initialUsers[index] = newUser;

    setUsers(initialUsers);
  };

  const activateUser = async (user: User): Promise<void> => {
    if (processingUsers.includes(user)) return;

    setError(null);
    setProcessingUsers([...processingUsers, user]);

    return fetch('/settings/activate', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
      body: JSON.stringify({ user_email: user.email, user_id: user.id }),
    })
      .then(throwOnNonValidationError)
      .then(resp => resp.json())
      .then(json => {
        if (json.error) {
          setError(json.error);
        } else {
          updateUser(json['user']);
          updateMetaData(json['meta']);
        }
      })
      .catch(rescueFromError)
      .finally(() => setProcessingUsers(processingUsers.filter(u => u !== user)));
  };

  const deactivateUser = async (user: User): Promise<void> => {
    if (processingUsers.includes(user)) return;

    setError(null);
    setProcessingUsers([...processingUsers, user]);

    return fetch(`/settings/activate/${user.id}`, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
    })
      .then(throwOnError)
      .then(resp => resp.json())
      .then(json => {
        updateUser(json['user']);
        updateMetaData(json['meta']);
      })
      .catch(rescueFromError)
      .finally(() => setProcessingUsers(processingUsers.filter(u => u !== user)));
  };

  const sortedUsers = users.slice().sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Header
        title="Users"
        headerButton={{ url: '/settings/users/invitations', color: 'blue', icon: 'plus', text: 'Invite New User' }}
      />
      <Flash />
      <div className="d-grid gap-2">
        <Totals activeUsers={activeUsers} />

        <UsersFilter tqlFilters={tqlFilters} onUpdateTqlString={setTqlString} />

        {loading ? (
          <div className="d-grid gap-2">
            <div className="loader">
              <FontAwesomeIcon spin icon="circle-notch" />
              &nbsp;&nbsp; Loading users...
            </div>
          </div>
        ) : (
          <>
            {error && <ErrorMessage className="mb-3">{error}</ErrorMessage>}
            {requestError && (
              <ErrorMessage className="mb-3">
                TimeZest encountered an unexpected error. Please reload the page and try again
              </ErrorMessage>
            )}
            {sortedUsers.length > 0 ? (
              sortedUsers.map((user, idx) => (
                <UserListItem
                  key={idx}
                  index={idx}
                  user={user}
                  activateUser={activateUser.bind(null, user)}
                  deactivateUser={deactivateUser.bind(null, user)}
                  showAvailability={user.schedulable}
                  availabilityAllowed={plan.allowsCustomAvailability}
                />
              ))
            ) : (
              <NoUsers filtering={!!tqlString} />
            )}
            {pageCount > 1 && (
              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={currentPage}
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center mb-0 mt-3"
                disableInitialCallback={true}
                forcePage={currentPage - 1}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                activeClassName="active"
                onPageChange={({ selected }) => {
                  setOffset(selected * PAGE_SIZE);
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default asScreen(UsersIndex);

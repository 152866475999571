import type { FC } from 'react';

import Badge from '@ui/Badge';
import type { Member } from '@models/TeamMember';

interface Props extends Member {
  addMember: () => void;
}

const PotentialMember: FC<Props> = ({ name, schedulable, email, addMember }) => {
  const handleAddClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    addMember();
  };

  return (
    <div className="card mb-3">
      <div className="card-body py-2">
        <div className="fw-bold">
          {name}
          &nbsp;
          {schedulable && <Badge color="green">Active</Badge>}
        </div>
        <div className="small">{email}</div>
        {schedulable && (
          <button type="button" className="btn btn-sm btn-link p-0" onClick={handleAddClick}>
            Add to this Team
          </button>
        )}
      </div>
    </div>
  );
};

export default PotentialMember;

import type { FC } from 'react';

import classNames from 'classnames';

import Badge from '@/ui/Badge';
import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import type { ApiKey } from '@models/ApiKey';
import Alert from '@shared/Alert';

interface Props {
  apiKeys: ApiKey[];
}

const ApiKeys: FC<Props> = ({ apiKeys }) => {
  const hasApiKeys = apiKeys.length > 0;

  return (
    <>
      <Header title="API Keys" headerButton={{ url: '/settings/api_keys/new', text: '+ New API Key', color: 'blue' }} />
      <Flash />
      <Alert
        icon="circle-info"
        color="blue"
        className="mb-3 mt-0"
        content={
          <>
            Full documentation for the TimeZest API can be found at{' '}
            <a target="_blank" rel="noopener" href="https://developer.timezest.com">
              https://developer.timezest.com
            </a>
            .
          </>
        }
      />
      {hasApiKeys ? (
        <>
          {apiKeys.map((ak, i) => (
            <div key={i} className={classNames('py-2', { 'border-top': i === 0 })}>
              <a href={`/settings/api_keys/${ak.id}`}>{ak.name}</a> &nbsp;{' '}
              {ak.permissions === 'read_only' ? (
                <Badge color="blue">Read only</Badge>
              ) : (
                <Badge color="green">Read and Write</Badge>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className="border-top border-bottom text-muted mt-3 py-2 text-center">No API keys have been created.</div>
      )}
    </>
  );
};

export default asScreen(ApiKeys);

import type { FC } from 'react';

import Row from '@/ui/Row';

const InsightsUpgrade: FC = () => {
  return (
    <Row label="Upgrade to Insights" withInput={false}>
      <>
        <p className="text-muted">
          Your TimeZest account was created before Autotask Insights were available. In order to display the TimeZest
          widget alongside tickets in Autoask, we need to update your TimeZest setup.
        </p>
        <p className="text-muted">Click button below to continue with upgrade.</p>
        <a className="btn btn-primary" rel="nofollow" data-method="post" href="/settings/integrations/autotask/upgrade">
          Upgrade to Insights
        </a>
      </>
    </Row>
  );
};

export default InsightsUpgrade;

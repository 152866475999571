import type { FC } from 'react';
import { useContext } from 'react';

import classNames from 'classnames';

import { DirtyContext } from '@/DirtyContext';

import PlainTextRow from '../PlainTextRow';

import RadioButtonOption from './RadioButtonOption';

import useStateFromProp from '@shared/hooks/useStateFromProp';
import Row from '@ui/Row';

interface Option {
  disabled?: boolean;
  hidden?: boolean;
  label: JSX.Element | string;
  value: string;
  appendedElement?: string | JSX.Element;
}

interface Props {
  error?: string;
  helpText?: string | JSX.Element | null;
  warning?: string | JSX.Element | null;
  id?: string;
  label: string;
  name: string;
  readOnly?: boolean;
  options: Option[];
  value: string;

  onChange?: (newValue: string) => void;
}

const RadioButtonRow: FC<Props> = ({
  error,
  helpText,
  id,
  label,
  name,
  readOnly,
  options,
  warning,
  value: initialValue,

  onChange,
}) => {
  const { handleDirty } = useContext(DirtyContext);
  const [value, setValue] = useStateFromProp(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleDirty) {
      handleDirty();
    }

    const newValue = event.target.value;
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  if (readOnly) {
    return (
      <PlainTextRow label={label} helpText="">
        {options.find(option => option.value === value)?.label}
      </PlainTextRow>
    );
  }

  return (
    <Row label={label} helpText={helpText} withInput={false}>
      <>
        {options
          .filter(option => !option.hidden)
          .map(option => (
            <RadioButtonOption
              checked={option.value === value}
              disabled={option.disabled || false}
              id={id}
              key={option.value}
              label={option.label}
              name={name}
              appendedElement={option.appendedElement}
              value={option.value}
              onChange={handleChange}
            />
          ))}
        <div className={classNames('invalid-feedback', { 'd-block': !!error })}>{error}</div>
        {warning && <div className="small text-muted mt-1">{warning}</div>}
      </>
    </Row>
  );
};

export default RadioButtonRow;

import type { UIState } from './uiReducer';

const clearInsertionPoint: (state: UIState) => UIState = state => {
  return {
    ...state,
    insertionPoint: null,
  };
};

export default clearInsertionPoint;

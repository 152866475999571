import type { FC } from 'react';

import Row from '@/ui/Row';

import { withArticle } from '@shared/text';

interface Props {
  name: string;
}

const PlaceholderCalendar: FC<Props> = ({ name }) => {
  return (
    <Row label={name} withInput={false}>
      <p className="mb-0">This user is not associated with {withArticle(name)} account.</p>
    </Row>
  );
};

export default PlaceholderCalendar;

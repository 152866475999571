import type { FC } from 'react';

import asScreen from '@/Screen';
import Row from '@/ui/Row';
import Header from '@/Header';
import Flash from '@/Flash';

import PersonalPageStatus from './PersonalPageStatus';
import PasswordSettings from './PasswordSettings';
import TwoFactorAuthenticationSettings from './TwoFactorAuthenticationSettings';
import LoginActivities from './LoginActivities';

import type { LoginActivityModel } from '@models/User';

interface Props {
  errors: Record<string, string>;
  loginActivities: LoginActivityModel[];
}

const Profile: FC<Props> = ({ errors, loginActivities }) => {
  return (
    <>
      <Header title="My Profile" />
      <Flash />
      <Row
        label="Personal Page"
        helpText="A personal page allows the end user to choose, from a list of configured appointment types, which appointment type they'd like to book."
      >
        <PersonalPageStatus />
      </Row>
      <hr />
      <PasswordSettings errors={errors} />
      <TwoFactorAuthenticationSettings />
      <hr />
      <LoginActivities loginActivities={loginActivities} />
    </>
  );
};

export default asScreen(Profile);

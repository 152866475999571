import type { FC } from 'react';
import { Fragment } from 'react';

import map from 'lodash/map';

import Form from '@/Form';

import type { QuickEditActionInput, Trigger } from '@graphql/generated';

interface Props {
  actions: QuickEditActionInput[];
  appointmentTypeIds: number[];
  trigger: Trigger;
  url: string;
}

const SubmitButton: FC<Props> = ({ actions, appointmentTypeIds, trigger, url }) => {
  return (
    <Form url={url} className="d-inline-block">
      {actions.map((props: QuickEditActionInput, i: number) => (
        <Fragment key={i}>
          <input type="hidden" name="quick_edit[actions][][type]" value={props.type} />
          {map(props.params, (value, attr) => (
            <input
              key={`${attr}-${i}`}
              type="hidden"
              name={`quick_edit[actions][][params][${attr}]`}
              value={value?.toString()}
            />
          ))}
        </Fragment>
      ))}
      {appointmentTypeIds.map(id => (
        <input key={id} type="hidden" name="quick_edit[appointment_type_id][]" value={id} />
      ))}
      <input type="hidden" name="quick_edit[trigger][type]" value={trigger.slug} />
      <input type="hidden" name="quick_edit[trigger][time_mins]" value={trigger.timeMins || ''} />
      <button type="submit" className="btn btn-primary">
        Apply these Changes &gt;&gt;
      </button>
    </Form>
  );
};

export default SubmitButton;

import { useContext, type FC } from 'react';

import PlanContext from '@/PlanContext';
import AccountContext from '@/AccountContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import { hasCapabilityFromIntegration } from '@shared/utilities';

interface Props {
  newPlanAllowsCustomAvailability: boolean;
}

const CustomAvailability: FC<Props> = ({ newPlanAllowsCustomAvailability }) => {
  const { integrations } = useContext(AccountContext);
  const { allowsCustomAvailability: currentPlanAllowsCustomAvailability } = useContext(PlanContext);

  if (!newPlanAllowsCustomAvailability && currentPlanAllowsCustomAvailability) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp; You&apos;ll no longer be able to set custom availability for user, and TimeZest will use their
        availability set in {hasCapabilityFromIntegration(integrations, 'canProvideAvailability')}.
      </div>
    );
  }

  if (newPlanAllowsCustomAvailability && !currentPlanAllowsCustomAvailability) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; You&apos;ll be able to define custom availability for each user in TimeZest.
      </div>
    );
  }
};

export default CustomAvailability;

import type { FC } from 'react';

import Row from '@/ui/Row';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type DomainModel from '@models/Domain';

interface Props {
  domains: DomainModel[];
}

const CustomDomains: FC<Props> = ({ domains }) => {
  const emptyDomains = domains.length === 0;

  return (
    <Row
      label="Custom Domains"
      helpText={
        <>
          <p>Add a custom domain to allow TimeZest to send emails from any address on that domain.</p>
          <p className="mb-0">
            <a href="/settings/domains/new">Add a custom domain</a>
          </p>
        </>
      }
      withInput={false}
    >
      {emptyDomains ? (
        <div className="text-muted">No custom domains have been added and verified.</div>
      ) : (
        <>
          {domains.map((domain, i) => (
            <div key={`domain_${i}`}>
              <div className={`d-flex pb-1 ${i === 0 ? '' : 'border-top pt-1'}`}>
                <div className="flex-grow-1">
                  <a href={`/settings/domains/${domain.id}`}>{domain.name}</a>
                </div>
                <div>
                  {domain.verified ? (
                    <span className="text-success">
                      <FontAwesomeIcon icon="check" />
                      &nbsp; Verified
                    </span>
                  ) : (
                    <span className="text-danger">
                      <FontAwesomeIcon icon="times" />
                      &nbsp; Not Verified
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </Row>
  );
};

export default CustomDomains;

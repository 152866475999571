import type { FC } from 'react';

import asScreen from '@/Screen';
import YesNoConfirmation from '@/YesNoConfirmation';
import Header from '@/Header';
import Flash from '@/Flash';

import Settings from './Settings';

import Alert from '@shared/Alert';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

import UsersList from '../../UsersList';

export type PartialDay = 'available' | 'blocked';

type IntegrationSettings = {
  apiKey: string;
  persisted: boolean;
  partialDay: PartialDay;
  subdomain: string;
  errors: Record<string, string>;
};

interface Props {
  integration: IntegrationSettings;
  users: { connected: boolean; name: string }[];
  usersCount: number;
  usersPerPage: number;
}

const BambooHR: FC<Props> = ({ integration, users, usersCount, usersPerPage }) => {
  return (
    <>
      <Header title="BambooHR Integration" />
      <Flash />
      {integration.persisted ? (
        <div className="d-flex">
          <div className="flex-grow-1">
            <span className="text-success">
              <FontAwesomeIcon icon="circle-check" /> &nbsp;&nbsp;
              <span className="fw-bold">TimeZest has been successfully connected to your BambooHR instance.</span>
            </span>
          </div>
          <YesNoConfirmation url="/settings/integrations/bamboo_hr" text="Remove This Integration" />
        </div>
      ) : (
        <div className="d-flex">
          <div className="flex-grow-1 fw-bold pt-2">
            TimeZest has not yet been connected with your BambooHR instance.
          </div>
        </div>
      )}
      <hr />
      {integration.errors.base && (
        <Alert
          color="red"
          className="mb-3"
          icon="circle-xmark"
          title="TimeZest was unable to connect to your BambooHR instance with the API Key and subdomain provided."
          content={<p className="mb-0">Reason from BambooHR: {integration.errors.base}</p>}
        />
      )}
      <Settings
        apiKey={integration.apiKey}
        errors={integration.errors}
        partialDay={integration.partialDay}
        persisted={integration.persisted}
        subdomain={integration.subdomain}
      />
      {integration.persisted && (
        <>
          <hr />
          <UsersList
            users={users}
            usersCount={usersCount}
            usersPerPage={usersPerPage}
            connectedMessage="This user is associated with a BambooHR user account."
            notConnectedMessage="This user is not associated with a BambooHR user account."
          />
        </>
      )}
    </>
  );
};

export default asScreen(BambooHR);

import type { FC } from 'react';

import type AppointmentTypeModel from '@models/AppointmentType';

interface Props {
  appointmentType: AppointmentTypeModel;
  allowsRescheduling: boolean;
}

const Summary: FC<Props> = ({ appointmentType, allowsRescheduling }) => {
  let bufferTimes = '';
  let allowsReschedulePermission = '';

  if (appointmentType.bufferBeforeMins > 0 && appointmentType.bufferAfterMins > 0) {
    bufferTimes = `${appointmentType.bufferBeforeMins} minutes buffer before and ${appointmentType.bufferAfterMins} minutes after.`;
  } else if (appointmentType.bufferBeforeMins > 0) {
    bufferTimes = `${appointmentType.bufferBeforeMins} minutes buffer before.`;
  } else if (appointmentType.bufferAfterMins > 0) {
    bufferTimes = `${appointmentType.bufferAfterMins} minutes buffer after.`;
  }

  if (!allowsRescheduling) {
    allowsReschedulePermission =
      'Rescheduling and Cancellation are only available on the TimeZest Advanced and TimeZest Professional plans.';
  } else {
    const negator = appointmentType.clientCanReschedule ? '' : 'not ';
    allowsReschedulePermission = `Client rescheduling & cancellation ${negator} permitted`;
  }

  return (
    <>{`${appointmentType.clientCanReschedule ? `${allowsReschedulePermission};` : ''} Offer appointments every ${
      appointmentType.schedulingIncrementMins
    } minutes. ${bufferTimes}`}</>
  );
};

export default Summary;

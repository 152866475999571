import { useContext, type FC } from 'react';

import { formatDistance } from 'date-fns';
import classNames from 'classnames';

import CurrentUserContext from '@/CurrentUserContext';

import Status from '../Status';

import type { SchedulingRequest } from '@graphql/generated';

interface Props {
  hasPagination: boolean;
  requests: SchedulingRequest[];
}

const SchedulingRequestsList: FC<Props> = ({ hasPagination, requests }) => {
  const { role } = useContext(CurrentUserContext);

  return (
    <table className={classNames('border-bottom table-transparent table', { 'mb-4': hasPagination })}>
      <thead>
        <tr>
          <th className="border-top-0 border-bottom ps-0 pt-0">Ticket / Activity</th>
          <th className="border-top-0 border-bottom pt-0">End User</th>
          <th className="border-top-0 border-bottom pt-0">Resource</th>
          <th className="border-top-0 border-bottom pt-0">Appointment</th>
          <th className="border-top-0 border-bottom pe-0 pt-0 text-right">Status</th>
        </tr>
      </thead>

      <tbody>
        {requests.map(request => (
          <tr key={request.id}>
            <td className="ps-0" width="20%">
              <a href={`/scheduling_requests/${request.token}`}>{request.description || 'No Ticket/Activity'}</a>
            </td>

            <td width="30%">
              {getClientName(request.client)}

              {request.company && <span>&mdash;{request.company.name} </span>}

              <div className="small text-muted">{request.client?.email}</div>
            </td>

            <td width="20%">
              <span>{request.resource}</span>
            </td>

            <td width="15%">
              {request.appointmentType.internalName}
              <div className="small text-muted">{request.appointmentType.durationMins} minutes</div>
            </td>

            <td className="text-capitalize pe-0 text-right" width="15%">
              <Status status={request.status} />
              <div className="small text-muted text-lowercase">
                {formatDistance(new Date(request.updatedAt), new Date(), { addSuffix: true })}
              </div>
              {role === 'administrator' && requestCancellable(request) && (
                <div className="small">
                  <a href={`/scheduling_requests/${request.token}/cancel/new`}>Cancel</a>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SchedulingRequestsList;

function requestCancellable(request: { status: string; agreedStartTime?: string | null }): boolean {
  if (request.status === 'cancelled') return false;
  if (!request.agreedStartTime) return true;

  return new Date(request.agreedStartTime) > new Date();
}

function getClientName(client: SchedulingRequest['client']) {
  if (!client) return '';

  const { firstName, lastName } = client;

  if (!lastName) return firstName;

  return `${firstName} ${lastName}`;
}

import type { FC } from 'react';
import { forwardRef, useContext, useMemo } from 'react';

import type { WorkflowActionProps } from '@/ui/Workflow/types';

import TeamSelect from './TeamSelect';
import ChannelSelect from './ChannelSelect';

import ErrorBoundary from '@shared/ErrorBoundary';
import Action from '@shared/Action';
import type Errors from '@models/Errors';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { TeamsSendMessageToChannelAction } from '@graphql/generated';
import type { Content } from '@shared/NoteEditor';
import NoteEditor from '@shared/NoteEditor';
import Row from '@ui/Row';
import useFormSubmit from '@shared/hooks/useFormSubmit';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import FormError from '../../FormError';
import { useSendMessageToChannelForMSTeams } from '../../hooks';
import BaseError from '../../BaseError';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will interpolate actual values into the message, and post it to the
      specified team and channel in Microsoft Teams.
    </p>
    <p className="mb-3">
      TimeZest will skip executing this action if no longer connected to a Microsoft Teams integration.
    </p>
  </>
);

interface SummaryProps {
  team: string;
  channel: string;
}

const Summary: FC<SummaryProps> = ({ team, channel }) => {
  if (team && channel)
    return (
      <>
        Post a message to the <b>{channel}</b> channel of the <b>{team}</b> team in Microsoft Teams.
      </>
    );

  return <>Post a message to a Microsoft Teams channel.</>;
};

interface Props {
  errors?: Errors;
}

const SendMessageToChannel = forwardRef<HTMLFormElement, WorkflowActionProps<TeamsSendMessageToChannelAction, Props>>(
  ({ action, errors: initialErrors, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const initialNote = useMemo(() => {
      return (action.message || []) as Content;
    }, [action.message]);
    const [teamId, setTeamId] = useStateFromProp(action.teamId, teamId => teamId || '');
    const { errors: responseErrors, loading, succeeded, submit } = useSendMessageToChannelForMSTeams();
    const errors = loading || succeeded ? {} : { ...responseErrors, ...initialErrors, ...action.errors };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        channelId: (data.get('channel_id') || '') as string,
        channelName: (data.get('channel_name') || '') as string,
        teamName: (data.get('team_name') || '') as string,
        teamId: data.get('team_id') as string,
        message: (data.get('message') || '') as string,
      };
    });

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="message"
            summary={<Summary team={action.teamName} channel={action.channelName} />}
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={responseErrors} />
            <TeamSelect
              teamId={teamId}
              error={errors.team_name}
              readOnly={readOnly}
              templateMode={templateMode}
              onTeamChange={setTeamId}
            />
            <ChannelSelect
              channelId={action.channelId || ''}
              teamId={teamId}
              error={errors.channel_id || errors.channel_name}
              readOnly={readOnly}
              templateMode={templateMode}
            />

            <Row label="Message" width={10}>
              <NoteEditor name="message" initialValue={initialNote} readOnly={readOnly} />
            </Row>

            {errors.base && <BaseError errorMessage={errors.base} />}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default SendMessageToChannel;

import type { FC } from 'react';

import type { Member } from '@models/TeamMember';
import { sentence } from '@shared/text';

interface Props {
  activatedMembers: Member[];
  unactivatedMembers: Member[];
}

const InactiveMembers: FC<Props> = ({ activatedMembers, unactivatedMembers }) => {
  if (unactivatedMembers.length > 0 && unactivatedMembers.length === 0) {
    return <>All {unactivatedMembers.length} members of this team have not been activated in TimeZest.</>;
  } else if (activatedMembers.length === 0 && unactivatedMembers.length === 0) {
    return <>There are no members assigned to this team in HaloPSA.</>;
  }

  const names = unactivatedMembers.map(m => m.name);
  let text = '';

  if (names.length === 1) {
    text = `${names[0]} has not been activated in TimeZest.`;
  } else if (names.length > 1 && names.length < 4) {
    text = sentence(names).concat(' have not been activated in TimeZest.');
  } else {
    text = sentence(
      names.slice(0, 2).concat(`${unactivatedMembers.length - 2} other members have not been activated in TimeZest.`)
    );
  }

  return <>{text}</>;
};

export default InactiveMembers;

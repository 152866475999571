import type { Index } from './templateReducer';
import type { UIState } from './uiReducer';

const iterateLayerSelection: (
  state: UIState,
  selectedElementId: string,
  initialSelectedElementId: string,
  index: Index
) => UIState = (state, selectedElementId, initialSelectedElementId, index) => {
  const element = index[selectedElementId];

  let elementId = '';

  if (element.type === 'mjml-section') {
    elementId = initialSelectedElementId;
  } else {
    elementId = element.parentId as string;
  }

  return {
    ...state,
    selectedElementId: elementId,
  };
};

export default iterateLayerSelection;

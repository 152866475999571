import type { FC } from 'react';

import MailSendingConfiguration, {
  type MailSendingConfigurationProps,
} from '@/screens/EmailSettings/Show/MailSendingConfiguration';
import Row from '@/ui/Row';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type DomainModel from '@models/Domain';

interface Props {
  allowsCustomerSmtpServer: boolean;
  domains: DomainModel[];
  error: string;
  mailSendingConfiguration: MailSendingConfigurationProps;
}

const MailSendingMode: FC<Props> = ({ allowsCustomerSmtpServer, domains, error, mailSendingConfiguration }) => {
  return (
    <>
      {allowsCustomerSmtpServer ? (
        <MailSendingConfiguration domains={domains} fromLocalPartError={error} {...mailSendingConfiguration} />
      ) : (
        <Row label="Custom Domains">
          <div className="mb-0">
            <div className="fw-bold text-danger mb-3">
              Only TimeZest Professional subscribers can use custom domains.
            </div>
            <p>
              TimeZest can use custom domains to send its emails, allowing TimeZest emails to come from any email
              address on your domain, improving delivery and giving customers confidence.
            </p>
            <p className="mb-0">
              <a className="btn btn-danger" href="/settings/account">
                Upgrade Now &nbsp; <FontAwesomeIcon icon="angle-right" />
              </a>
            </p>
          </div>
        </Row>
      )}
    </>
  );
};

export default MailSendingMode;

import type { FC, ChangeEvent } from 'react';
import { useRef, useState, useEffect } from 'react';

import { HexColorPicker } from 'react-colorful';
import classNames from 'classnames';

import type { FieldProps } from './Field';
import Field from './Field';

import InputGroup from '@shared/ui/Inputs/InputGroup';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props extends Omit<FieldProps, 'className' | 'error'> {
  name: string;
  value: string;
  disabled: boolean;
  onInput?: (value: string) => void;
}

const Color: FC<Props> = ({ id, label, value: initialValue, name, disabled, onInput }) => {
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const handlerRef = useRef<HTMLButtonElement>(null);
  const [color, setColor] = useStateFromProp(initialValue, v => (v ? v.substring(1) : ''));
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent): void => {
    if (!colorPickerRef.current?.contains(e.target as Node) && !handlerRef.current?.contains(e.target as Node)) {
      setPickerOpen(false);
    }
  };

  const handleColorChange = (value: string) => {
    const newColor = processColorHex(value);

    if (onInput) onInput(newColor !== null ? newColor : initialValue);

    if (initialValue === newColor) setColor(initialValue.substring(1));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    value = purifyColorHex(value);

    if (value.length > 6) {
      value = value.substring(0, 6);
    }

    setColor(value);
  };

  const handleBlur = () => {
    handleColorChange(color);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleColorChange(color);
    }
  };

  return (
    <Field id={id} label={label}>
      <div className="ColorPicker">
        <div className="position-relative">
          <InputGroup
            id={id}
            className={classNames('rounded-end-1', { 'text-muted': disabled })}
            name={name}
            value={color || ''}
            disabled={disabled}
            prepend="#"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleOnKeyDown}
          />

          <button
            type="button"
            ref={handlerRef}
            className={classNames('ColorPicker__Handler', { 'pe-none': disabled })}
            style={{ backgroundColor: validHexCode(color.length) ? `#${color}` : '' }}
            onClick={() => setPickerOpen(true)}
          />
        </div>

        {pickerOpen && (
          <div className="ColorPicker__Palette" style={{ zIndex: 10 }} ref={colorPickerRef}>
            <HexColorPicker color={initialValue} onChange={handleColorChange} />
          </div>
        )}
      </div>
    </Field>
  );
};

export default Color;

function purifyColorHex(hexCode: string): string {
  const regex = /[^a-f0-9]/g;

  // hexCode is lowercased here to cover scenerio where user pastes hex code from somewhere that may include capital letters.
  return hexCode.toLowerCase().replace(regex, '');
}

function processColorHex(hexCode: string): string | null {
  if (!hexCode) return '';

  let colorCode: string | null = null;

  switch (hexCode.length) {
    case 1:
      colorCode = Array(6).fill(hexCode).join('');
      break;
    case 3:
      colorCode = hexCode
        .split('')
        .map(char => `${char}${char}`)
        .join('');
      break;
    case 6:
    case 7:
      colorCode = hexCode;
      break;
  }

  if (colorCode && !colorCode.includes('#')) {
    colorCode = `#${colorCode}`;
  }

  return colorCode;
}

function validHexCode(hexCodeLength: number): boolean {
  if (hexCodeLength === 3 || hexCodeLength === 6) return true;

  return false;
}

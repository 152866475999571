import type { FC } from 'react';
import { useEffect, useState } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import FontAwesomeIcon from '@/FontAwesomeIcon';
import type { TimeElapsedTriggerModel } from '@/models';

import Form from './Form';

import ConfirmationModal from '@ui/ConfirmationModal';
import type { Trigger } from '@graphql/generated';
import { useDeleteTriggerMutation, useUpdateTriggerMutation } from '@graphql/generated';
import { refetchOnSuccess } from '@shared/apollo';

const EDITABLE_TRIGGERS = [
  'time_zest/time_after_appointment',
  'time_zest/time_before_appointment',
  'time_zest/time_elapsed_without_scheduling',
];

const ICONS = {
  'autotask/appointment_requested_from_insight': 'chrome-cast',
  'autotask/appointment_url_generated_from_insight': 'attachment',
  'connect_wise/appointment_requested_from_pod': 'chrome-cast',
  'connect_wise/appointment_url_generated_from_pod': 'attachment',
  'time_zest/time_after_appointment': 'forward-time',
  'time_zest/time_before_appointment': 'backward-time',
  'time_zest/time_elapsed_without_scheduling': 'wall-clock',
  'time_zest/client_schedules_appointment': 'calendar-tick',
  'time_zest/client_reschedules_appointment': 'schedule',
  'time_zest/client_cancels_appointment': 'calendar-block',
  'time_zest/technician_cancels_appointment': 'calendar-block',
};

interface Props {
  trigger: Trigger | TimeElapsedTriggerModel;
  readOnly: boolean;
  deletable?: boolean;
  onChange?: (timeMins: number) => void;
  onEditToggle?: (editing: boolean) => void;
}

const Name: FC<Props> = ({ deletable, trigger, readOnly, onChange, onEditToggle }) => {
  const editable = EDITABLE_TRIGGERS.includes(trigger.slug);
  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [updated, setUpdated] = useState(false);
  const [deleteTrigger] = useDeleteTriggerMutation({
    awaitRefetchQueries: true,
    refetchQueries: refetchOnSuccess('updateTrigger', 'getWorkflowTriggers'),
  });
  const [updateTrigger, { data }] = useUpdateTriggerMutation({
    awaitRefetchQueries: true,
    refetchQueries: refetchOnSuccess('updateTrigger', 'getWorkflowTriggers'),
    onCompleted: data => {
      if (data?.updateTrigger.errors.length > 0) return;

      setEditMode(false);
      if (onEditToggle) onEditToggle(false);
    },
  });

  const handleEditModeChange = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setEditMode(!editMode);

    if (onEditToggle) onEditToggle(!editMode);
  };

  const handleDeleteModeChange = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    setDeleteMode(!deleteMode);
  };

  const handleDelete = () => {
    deleteTrigger({ variables: { triggerId: trigger.id } });
  };

  const handleUpdate = (newAttributes: { timeMins: number }) => {
    if (onChange) {
      onChange(newAttributes.timeMins);
      handleEditModeChange();
    } else {
      updateTrigger({ variables: { ...newAttributes, triggerId: trigger.id } }).then(({ data }) => {
        if ((data?.updateTrigger.errors || []).length > 0) setError(data!.updateTrigger.errors[0]);
      });
    }
  };

  useEffect(() => {
    if (!data?.updateTrigger.trigger) return;

    setUpdated(true);

    const timer = setTimeout(() => {
      setUpdated(false);
    }, 3000);

    return function () {
      clearTimeout(timer);
    };
  }, [data?.updateTrigger.trigger]);

  return (
    <div className="Trigger">
      <div className="Trigger__Icon">
        <i className={`budicon budicon-${ICONS[trigger.slug] || 'gear'}`} />
      </div>
      <div className="Trigger__Name d-flex">
        {editMode ? (
          <Form
            trigger={trigger as TimeElapsedTriggerModel}
            error={error}
            onCancel={handleEditModeChange}
            onSubmit={handleUpdate}
          />
        ) : (
          <>
            <div className="flex-grow-1">
              {trigger.name}:
              {!readOnly && editable && (
                <button className="Trigger__EditIcon text-muted ms-1" onClick={handleEditModeChange}>
                  <FontAwesomeIcon icon="pen" />
                </button>
              )}
            </div>

            {!readOnly && (typeof deletable === 'undefined' || deletable) && (
              <OverlayTrigger placement="left" overlay={<Tooltip>Delete this trigger</Tooltip>}>
                <button className="Trigger__DeleteIcon text-muted me-2" onClick={handleDeleteModeChange}>
                  <i className="budicon budicon-trash" />
                </button>
              </OverlayTrigger>
            )}
          </>
        )}
      </div>
      {deleteMode && (
        <ConfirmationModal
          ariaLabel="Confirm Trigger Deletion"
          isOpen={true}
          title="Delete this trigger"
          description={trigger.actions.length ? 'Delete this trigger and all its actions?' : 'Are you sure?'}
          cancelLabel="No"
          confirmLabel="Yes"
          onConfirm={handleDelete}
          onClose={handleDeleteModeChange}
        />
      )}
      {updated && (
        <div className="Trigger__Badge">
          <div className="text-success">
            <FontAwesomeIcon icon="check" className="me-1" />
            Trigger Saved&nbsp;
          </div>
        </div>
      )}
    </div>
  );
};

export default Name;

import type { FC } from 'react';

interface Props {
  usesCustomTimezone: boolean;
  customTimezone: string | undefined | null;
}

const TimeZone: FC<Props> = ({ usesCustomTimezone, customTimezone }) => {
  if (usesCustomTimezone) {
    return (
      <span className="small">
        <strong>Custom time zone</strong>:&nbsp;{customTimezone}
      </span>
    );
  }

  return null;
};

export default TimeZone;

import { type FC, useEffect } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import IntegrationStatus from './IntegrationStatus';

import { refreshCurrentPage } from '@shared/utilities';

import UsersList from '../../UsersList';

export type User = {
  email: string;
  externalId: string;
  id: number;
  name: string;
  resourcePageEnabled: boolean;
  role: string;
  schedulable: boolean;
  slug: string;
};

export type IntegrationSettings = {
  state: 'authorized' | 'connected' | 'consented' | 'rejected';
  processing: boolean;
  progress: number | null;
};

interface Props {
  authUrl: string;
  integration: IntegrationSettings | null;
  users: { connected: boolean; name: string }[];
  usersCount: number;
  usersPerPage: number;
  usersToDeactivate: User[];
  usersToCalendarSwitch: User[];
}

const Office365Calendar: FC<Props> = ({
  authUrl,
  integration,
  users,
  usersCount,
  usersPerPage,
  usersToCalendarSwitch,
  usersToDeactivate,
}) => {
  const integrationIsNotRejected = integration?.state !== 'rejected';

  // This useEffect is responsible for refreshing the page every 5 seconds to visually update the progress bar completion rate.
  useEffect(() => {
    if (integrationIsNotRejected && integration?.processing) {
      refreshCurrentPage(5000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header title="Microsoft 365 Calendar Integration" />
      <Flash />
      <IntegrationStatus
        integrationIsNotRejected={integrationIsNotRejected}
        authUrl={authUrl}
        integration={integration}
        usersToDeactivate={usersToDeactivate}
        usersToCalendarSwitch={usersToCalendarSwitch}
      />
      <hr />
      <p>
        TimeZest&apos;s integration with Microsoft 365 allows TimeZest to create appointments and read availability from
        users&apos; Microsoft 365 calendars.
      </p>
      <p>
        Availability source can be changed by administrators on an{' '}
        <a href="/settings/users">individual user settings page</a> or by each user on their{' '}
        <strong>My Availability</strong> page (if <strong>custom user availability</strong> on{' '}
        <a href="/settings/security">Security & Permissions</a> is allowed).
      </p>
      {integration && (
        <UsersList
          users={users}
          usersCount={usersCount}
          usersPerPage={usersPerPage}
          connectedMessage="This user is associated with Microsoft 365 user account."
          notConnectedMessage="This user is not associated with Microsoft 365 user account."
        />
      )}
    </>
  );
};

export default asScreen(Office365Calendar);

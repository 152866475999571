import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';

import Row from '@ui/Row';
import TextInput from '@shared/ui/Inputs/TextInput';

interface Props {
  label: string;
  name: string;
  value: string | null;
  error?: string;
  helpText?: string;
  width?: number;
}

const PasswordInputRow: FC<Props> = ({ error, label, name, value: initialValue, width, helpText }) => {
  const { handleDirty } = useContext(DirtyContext);
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleDirty) {
      handleDirty();
    }

    setValue(event.target.value);
  };

  return (
    <Row label={label} helpText={helpText} width={width} labelFor={name}>
      <TextInput
        type="password"
        data-lpignore="true"
        id={name}
        name={name}
        error={error}
        value={value || ''}
        onChange={handleChange}
      />
    </Row>
  );
};

export default PasswordInputRow;

import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityStagesForConnectWiseQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const STAGE_HELP_TEXT = 'TimeZest will create the opportunity in ConnectWise PSA with this stage.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  stageId: string;
  templateMode?: boolean;
}

const StageSelect: FC<Props> = ({ stageId: initialStageId, error, readOnly, templateMode }) => {
  const [stageId, setStageId] = useStateFromProp(initialStageId);

  const queryResult = useGetOpportunityStagesForConnectWiseQuery({ skip: templateMode });

  const stage = (queryResult.data?.connectWise.opportunityStages || []).find(s => s.id.toString() === stageId);

  const handleStageChange = (value: string) => {
    if (value !== stageId) {
      setStageId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      helpText={STAGE_HELP_TEXT}
      label="Stage"
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity stage details from ConnectWise PSA because the security role does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the <strong>Table Setup</strong> permission in the <strong>System</strong> section of the
          security role has <strong>Opportunities / Sales Stage</strong> in the <strong>Allow Access to These</strong>{' '}
          column.
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity stages from ConnectWise PSA."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        helpText={STAGE_HELP_TEXT}
        label="Stage"
        name="stage_id"
        field="connectWise.opportunityStages"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Use default value configured in ConnectWise PSA"
        emptyDataMessage="No opportunity stages found."
        templateMode={templateMode}
        readOnly={readOnly}
        error={error}
        value={stageId}
        queryErrorMessage={queryErrorMessage}
        queryResult={queryResult}
        onChange={handleStageChange}
      />

      <input type="hidden" name="stage_name" value={stage?.name || ''} />
    </>
  );
};

export default StageSelect;

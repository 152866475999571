import type { FC } from 'react';

import IntegrationCardContent from './IntegrationCardContent';

export interface Props {
  connected: boolean;
  description: string;
  integrationName: string;
  integrationUrl: string;
  logoClass: string;
  upgrade: boolean;
  upgradeUrl: string;
}

const IntegrationCard: FC<Props> = props => {
  const { integrationUrl, ...rest } = props;

  return (
    <div className="col-4 mb-gutter">
      {props.upgrade ? (
        <IntegrationCardContent {...rest} />
      ) : (
        <a className="integration" href={integrationUrl}>
          <IntegrationCardContent {...rest} />
        </a>
      )}
    </div>
  );
};

export default IntegrationCard;

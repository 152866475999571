import type { FC } from 'react';

import ErrorAlert from './ErrorAlert';

import type { InsightUpgradeResultType } from '..';

const InsightUpgradeResult: FC<{ insightUpgradeResult: InsightUpgradeResultType }> = ({ insightUpgradeResult }) => {
  if (!insightUpgradeResult) return;

  if (insightUpgradeResult.status === 'unauthorized') {
    return (
      <ErrorAlert>
        <h5 className="mb-3">
          TimeZest was unable to upgrade your account to Vendor Insights, because the API Member did not have the
          necessary permissions.
        </h5>
        <p className="mb-0">
          Ensure that the security level for the TimeZest API Member has been set correctly. Update permissions for{' '}
          <strong>TimeZest Security Level</strong> in the <strong>Security Levels</strong> section of{' '}
          <strong>Resources/Users (HR)</strong> panel, and try again.
        </p>
      </ErrorAlert>
    );
  }

  if (insightUpgradeResult.status === 'bad_credentials') {
    return (
      <ErrorAlert>
        <h5 className="mb-3">
          TimeZest was unable to upgrade your account to Vendor Insights, because Autotask rejected the credentials that
          we used to authenticate.
        </h5>
        <p className="mb-0">
          Provide correct credentials on <a href="/settings/integrations/autotask">Autotask API</a> page, then try
          again.
        </p>
      </ErrorAlert>
    );
  }

  if (insightUpgradeResult.status === 'server_problem') {
    return (
      <ErrorAlert>
        <h5 className="d-inline-block">
          TimeZest was unable to upgrade your account to Vendor Insights, because Autotask rejected the request with
          error:
        </h5>
        <span>{insightUpgradeResult.error}</span>
      </ErrorAlert>
    );
  }

  if (insightUpgradeResult.status === 'not_found') {
    return (
      <ErrorAlert>
        <h5 className="d-inline-block">
          TimeZest was unable to upgrade your account to Vendor Insights, as the insight functionality is a new feature
          in Autotask 2021.1, and this version has not yet been rolled out to your Autotask region.
        </h5>
        <p>
          You can see Autotask&apos;s release schedule{' '}
          <a href="https://ww1.autotask.net/help/Content/0_RELEASE_NOTES/ReleaseMaterials.html">here.</a>
        </p>
      </ErrorAlert>
    );
  }

  return (
    <ErrorAlert>
      <h5 className="d-inline-block">
        TimeZest was unable to upgrade your account to Vendor Insights, because of the following error:
      </h5>
      <span>{insightUpgradeResult.error}</span>
    </ErrorAlert>
  );
};

export default InsightUpgradeResult;

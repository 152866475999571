import type { FC, PropsWithChildren } from 'react';

interface Props {
  id: string;
  label: string | JSX.Element;
  className?: string;
}

const Field: FC<PropsWithChildren<Props>> = ({ children, id, label, className }) => {
  return (
    <div className={className || 'mb-3'}>
      {typeof label === 'string' ? (
        <label className="form-label mb-2" htmlFor={id}>
          {label}
        </label>
      ) : (
        label
      )}
      {children}
    </div>
  );
};

export type { Props as FieldProps };

export default Field;

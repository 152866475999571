import type { FC } from 'react';
import { useState } from 'react';

import YesNoConfirmation from '@/YesNoConfirmation';

import type Reference from '@models/Reference';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
interface Props {
  url: string;
  processing: boolean;
  processingProgress: number | null;
  psaName: string | null;
  usersToCalendarSwitch: Reference[];
  usersToDeactivate: Reference[];
}

const Office365CalendarConnectionProgress: FC<Props> = ({
  processing,
  processingProgress,
  psaName,
  url,
  usersToCalendarSwitch,
  usersToDeactivate,
}) => {
  const [confirmationShown, setConfirmationShown] = useState(false);

  return (
    <>
      {processing ? (
        <div className="text-success">
          <FontAwesomeIcon spin icon="circle-notch" />
          &nbsp;&nbsp;
          <span className="fw-bold">
            TimeZest is connecting your scheduleable users with their Microsoft 365 calendars.
          </span>
          <div className="mt-2 progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-success"
              role="progressbar"
              aria-valuenow={processingProgress || 0}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${processingProgress}%` }}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <div className="flex-grow-1">
            <span className="text-success">
              <FontAwesomeIcon icon="circle-check" />
              &nbsp;&nbsp;
              <span className="fw-bold">TimeZest has been successfully connected to your Microsoft 365 tenant.</span>
            </span>
          </div>
          <YesNoConfirmation className="d-flex" url={url} onClick={shown => setConfirmationShown(shown)} />
        </div>
      )}
      {confirmationShown && (
        <>
          {usersToDeactivate.length > 0 && (
            <div className="mt-3">
              <p>
                These users will be deactivated, as each TimeZest user requires at least one calendar to be able to be
                scheduled:
              </p>
              <ul>
                {usersToDeactivate.map(u => (
                  <li key={u.id}>{u.name}</li>
                ))}
              </ul>
            </div>
          )}
          {usersToCalendarSwitch.length > 0 && (
            <div className="mt-3">
              <p>These users will have their calendar switched back to {psaName}</p>
              <ul>
                {usersToCalendarSwitch.map(u => (
                  <li key={u.id}>{u.name}</li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Office365CalendarConnectionProgress;

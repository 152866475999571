import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextInput from '@shared/ui/Inputs/TextInput';

import { extractNumber } from '../../utilities';

const DownArrow = styled.div`
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  z-index: 10;
`;

const PickerContainer = styled.div`
  right: 0;
  top: 10;
  top: 36px;
  width: 100%;
  z-index: 10;
  text-align: left;
  overflow-y: auto;
  height: 150px;
`;

const Option = styled.div`
  padding: 6px 12px;
  cursor: pointer;
  background-color: #ffffff;
  &:hover {
    background-color: var(--tz-grey-200);
  }
`;

interface Props {
  value: string;
  data: string[];
  disabled: boolean;
  unit?: string;
  onChange: (value: string) => void;
}

const InputAndPicker: FC<Props> = ({ data, value: initialValue, unit, disabled, onChange }) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [value, setValue] = useState(extractNumber(initialValue, unit));
  const [pressedEnter, setPressedEnter] = useState(false);

  const PickerRef = useRef<HTMLDivElement>(null);
  const InputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialValue) setValue(extractNumber(initialValue, unit));
  }, [initialValue, unit]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const triggerOnChange = (newValue?: string) => {
    if (extractNumber(initialValue, unit) !== (newValue || value)) {
      onChange(`${newValue || value}${unit || ''}`);
    }
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      triggerOnChange();

      setOpenPicker(false);
      setPressedEnter(true);
    }
  };

  const handleClickOutside = (e: MouseEvent): void => {
    if (!PickerRef.current?.contains(e.target as Node) && !InputRef.current?.contains(e.target as Node)) {
      setOpenPicker(false);
    }
  };

  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  const handleChooseOption = (optionValue: string) => {
    triggerOnChange(optionValue);
    setOpenPicker(false);
  };

  const handleOnBlur = () => {
    if (!pressedEnter) triggerOnChange();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (pressedEnter) setPressedEnter(false);
  };

  return (
    <>
      <div ref={PickerRef}>
        <DownArrow className="position-absolute">
          <FontAwesomeIcon
            className={classNames('w-100', { 'pe-none': disabled })}
            icon="chevron-down"
            onClick={() => setOpenPicker(true)}
          />
        </DownArrow>

        {openPicker && (
          <PickerContainer className="position-absolute">
            {data.map((item, index) => (
              <Option
                key={index}
                style={{ backgroundColor: item === value ? '#dcdcdc' : '' }}
                onClick={() => handleChooseOption(item)}
              >
                {item}
              </Option>
            ))}
          </PickerContainer>
        )}
      </div>

      <TextInput
        ref={InputRef}
        name="input-and-picker"
        className="position-relative"
        value={value}
        disabled={disabled}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onClick={handleOpenPicker}
        onKeyDown={keyDownHandler}
      />
    </>
  );
};

export default InputAndPicker;

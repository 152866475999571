import type { FC, PropsWithChildren } from 'react';

import Alert from '@/Alert';
import Row from '@/ui/Row';

const ErrorAlert: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Row width={12} label="">
      <Alert icon="circle-xmark" color="red" content={children as JSX.Element} />
    </Row>
  );
};

export default ErrorAlert;

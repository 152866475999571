import type { Attribute, PossibleValues } from './types';

import type { Filter } from '@models/Filter';

export function generateTQLString(updatedFilters: Filter[]): string {
  const values = updatedFilters.map((f: Filter) => `${f.attribute}~${f.operator}~${f.value}`);

  return values.join('~AND~');
}

export function assignTQLStringToURL(tqlString: string) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set('filter', tqlString);

  const url = [window.location.pathname, searchParams.toString()].filter(Boolean).join('?');

  window.history.pushState({ path: url }, '', url);
}

export function getValueAndValueName(possibleValues: PossibleValues, attributes: Attribute[]): [string, string] {
  const valueLists = possibleValues[attributes[0].value].values;

  if (valueLists.length > 0) {
    return [valueLists[0].value.toString(), valueLists[0].name.toString()];
  }

  return ['', ''];
}

import type {
  Action,
  ServiceNowUpdateTaskStateAction,
  ServiceNowUpdateTaskToPresetStateAction,
  ServiceNowWriteNoteToTaskAction,
} from '@graphql/generated';

export function isServiceNowWriteNoteToTaskAction(action: Action): action is ServiceNowWriteNoteToTaskAction {
  return action.slug === 'service_now/write_note_to_task';
}

export function isServiceNowUpdateTaskState(action: Action): action is ServiceNowUpdateTaskStateAction {
  return action.slug === 'service_now/update_task_state';
}

export function isServiceNowUpdateTaskToPresetStateAction(
  action: Action
): action is ServiceNowUpdateTaskToPresetStateAction {
  return action.slug === 'service_now/update_task_to_preset_state';
}

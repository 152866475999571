import type { FC } from 'react';

import CancellationVariables from './CancellationVariables';
import NotificationVariables from './NotificationVariables';
import ConfirmationVariables from './ConfirmationVariables';
import TechnicianReschedulingConfirmationVariables from './TechnicianReschedulingConfirmationVariables';
import TechnicianConfirmationVariables from './TechnicianConfirmationVariables';
import TechnicianCancellationVariables from './TechnicianCancellationVariables';
import ReminderVariables from './ReminderVariables';

import type { TemplateTypes } from '@models/EmailTemplateModel';

interface Props {
  templateType: TemplateTypes;
}

const VariablesList: FC<Props> = ({ templateType }) => {
  switch (templateType) {
    case 'cancellation':
      return <CancellationVariables />;
    case 'confirmation':
      return <ConfirmationVariables />;
    case 'notification':
      return <NotificationVariables />;
    case 'reminder':
      return <ReminderVariables />;
    case 'technician_cancellation':
      return <TechnicianCancellationVariables />;
    case 'technician_confirmation':
      return <TechnicianConfirmationVariables />;
    case 'technician_rescheduling_confirmation':
      return <TechnicianReschedulingConfirmationVariables />;
    default:
      return null;
  }
};

export default VariablesList;

import type { FC } from 'react';

import { extractNumber } from '@/EmailEditor/utilities';

import type { FieldProps } from './Field';

import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props extends Omit<FieldProps, 'className' | 'error'> {
  value: string;
  min: number;
  max: number;
  step: number;
  unit: string;
  disabled: boolean;
  onInput?: (value: string) => void;
}

const Slider: FC<Props> = ({ id, label, value: initialValue, min, max, step, unit, disabled, onInput }) => {
  const [value, setValue] = useStateFromProp(initialValue, v => extractNumber(v, unit));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (onInput) onInput(`${e.target.value}${unit || ''}`);
  };

  return (
    <>
      <label className="form-label mb-2" htmlFor={`${id}-width`}>
        {label}
      </label>
      <input
        className="form-range"
        value={value}
        type="range"
        id={`${id}-width`}
        name={`${id}-width`}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        onChange={handleOnChange}
        onInput={handleOnChange}
      />
    </>
  );
};

export default Slider;

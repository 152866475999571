import type { FC } from 'react';

import type AppointmentTypeModel from '@models/AppointmentType';

interface Props {
  appointmentType: AppointmentTypeModel;
  allowsShareableUrls: boolean;
}

const Summary: FC<Props> = ({ allowsShareableUrls, appointmentType }) => {
  const { slug } = appointmentType;

  if (!allowsShareableUrls) {
    return <>Shareable URLs are only available on the TimeZest Professional plan.</>;
  }

  if (slug && slug === '') {
    return <>Not configured for URL-based scheduling.</>;
  }

  switch (appointmentType.shareableUrlTicketMode) {
    case 'existing_ticket':
      return <>Not configured for shareable URLs.</>;
    case 'new_ticket':
      return <>Configured for shareable URLs to schedule appointments with new tickets.</>;
    case 'without_ticket':
      return <>Configured for shareable URLs to schedule appointments without tickets.</>;
  }
};

export default Summary;

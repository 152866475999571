import type { FC } from 'react';
import { useRef, useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextInput from '@shared/ui/Inputs/TextInput';
import MultipleEmailAddressesControl from '@shared/MultipleEmailAddresses';

import Form from '../../../Form';

interface Props {
  referralUrl: string;
}

const Referrals: FC<Props> = ({ referralUrl }) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState('');
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!formRef.current) return;

    setError('');

    const data = new FormData(formRef.current);
    const emails = data.getAll('emails[]');
    const valid = emails.length >= 1;

    if (valid) {
      formRef.current?.requestSubmit();
    } else {
      setError('Please enter a valid email address.');
    }
  };

  return (
    <>
      <Header title="Love TimeZest? Refer a friend and you'll get $200!" />
      <Flash />
      <div className="row">
        <div className="col-8">
          <Form url="/refer" ref={formRef}>
            <div className="fw-bold">Copy and share your personal link:</div>
            <div className="position-relative d-flex align-items-center mt-2">
              <TextInput
                value={referralUrl}
                name="referrals"
                className="flex-grow-1 bg-white"
                disabled={true}
                readOnly={true}
              />
              <span className="position-absolute end-0">
                {copied && (
                  <>
                    <div className="badge bg-light align-text-top text-black">Copied to Clipboard</div>&nbsp;&nbsp;
                  </>
                )}
                <CopyToClipboard text={referralUrl} onCopy={(): void => setCopied(true)}>
                  <FontAwesomeIcon icon="copy" className="text-muted" style={{ cursor: 'pointer' }} />
                </CopyToClipboard>
                <span className="me-3" />
              </span>
            </div>
            <hr />
            <div className="fw-bold">Or invite friends by email to try TimeZest:</div>
            <div className="mt-2">
              <MultipleEmailAddressesControl
                disabled={false}
                emailAddresses={[]}
                error={error || ''}
                limit={5}
                name="emails[]"
                placeholder="Type email addresses"
              />
            </div>
            <div className="d-flex mt-2">
              <div className="flex-grow-1" />
              <input
                type="submit"
                value="Send Invitation Emails"
                data-disable-with="Sending"
                className="btn btn-primary"
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </div>

        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div className="fw-bold mb-3">How it works:</div>
              <div className="d-flex">
                <div className="referral-sprite referral-step-1" />
                <div className="flex-grow-1">1. Share your link with another MSP that can use TimeZest.</div>
              </div>
              <hr />
              <div className="d-flex">
                <div className="referral-sprite referral-step-2" />
                <div className="flex-grow-1">2. They sign up, and become a paying TimeZest customer.</div>
              </div>
              <hr />
              <div className="d-flex">
                <div className="referral-sprite referral-step-3" />
                <div className="flex-grow-1">
                  3. They get 15% off for their first year, and you get a $200 Amazon gift card.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default asScreen(Referrals);

import difference from 'lodash/difference';
import concat from 'lodash/fp/concat';
import omit from 'lodash/omit';

import type { Index, TemplateState } from './templateReducer';
import { getElementFromIndex } from './utilities';

const deleteElement: (state: TemplateState, elementId: string) => TemplateState = (state, elementId) => {
  const element = getElementFromIndex(state, elementId);

  const parent = getElementFromIndex(state, element.parentId as string);

  const newParentNode = { ...parent, childIds: difference(parent.childIds, [elementId]) };

  // Total collection of children id(s) to be removed
  const childrenToBeRemoved = findAllChildrenIdsToRemove(state.index, elementId);

  return {
    ...state,
    index: {
      ...omit(state.index, concat(childrenToBeRemoved, elementId)),
      [parent.id]: newParentNode,
    },
  };
};

export default deleteElement;

const findAllChildrenIdsToRemove = (index: Index, rootRemoveElementId: string): string[] => {
  // Immediate children ids of root remove element
  const immediateChildIds = index[rootRemoveElementId].childIds;

  if (immediateChildIds.length === 0) {
    return [];
  } else {
    // Grandchildren ids of the root remove element
    const deeperChildIds = immediateChildIds.map(id => index[id].childIds).reduce((acc, curr) => [...acc, ...curr]);

    return concat(immediateChildIds, deeperChildIds);
  }
};

import type { FC } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import NewServiceTeam from './NewServiceTeam';
import NewProjectTeam from './NewProjectTeam';
import NewHaloPsaTeam from './NewHaloPsaTeam';

const TEAM_TITLES = {
  project: 'Add New Project Team',
  service: 'Add New Service Team',
  halo_psa: 'Add New HaloPSA Team',
};

interface Props {
  psaTeamType: 'service' | 'project' | 'halo_psa';
}

const NewPsaTeam: FC<Props> = ({ psaTeamType }) => {
  return (
    <>
      <Header title={TEAM_TITLES[psaTeamType]} subtitle="Teams" />
      <Flash />
      {psaTeamType === 'service' && <NewServiceTeam />}
      {psaTeamType === 'project' && <NewProjectTeam />}
      {psaTeamType === 'halo_psa' && <NewHaloPsaTeam />}
    </>
  );
};

export default asScreen(NewPsaTeam);

import type { ContentState, ContentBlock, RawDraftContentBlock } from 'draft-js';
import { genKey, convertFromRaw } from 'draft-js';
import { getEntityRanges } from 'draft-js-utils';
import size from 'lodash/size';

import type VariableModel from '@models/Variable';

export type Content = Paragraph[];

export type Leaf = Text | Variable;

export interface Paragraph {
  type: 'paragraph';
  children: Leaf[];
  condition?: string;
}

interface Text {
  type?: never;
  text: string;
}

interface Variable {
  type: 'variable';
  variable: string;
}

export function serialize(contentState: ContentState): Content {
  const blocks = contentState.getBlocksAsArray();
  return processBlocks(blocks, contentState);
}

function processBlocks(blocks: ContentBlock[], contentState: ContentState): Content {
  // Procedurally process individual blocks
  return blocks.map(block => {
    const data = block.getData ? block.getData().toJS() : {};

    if (data.isConditional) {
      const condition = (data.condition === 'present' ? data.variable : `!${data.variable}`) as string;

      return { type: 'paragraph', children: processBlockContent(block, contentState), condition };
    } else {
      return { type: 'paragraph', children: processBlockContent(block, contentState) };
    }
  });
}

function processBlockContent(block: ContentBlock, contentState: ContentState): Leaf[] {
  const entityPieces = getEntityRanges(block.getText(), block.getCharacterList());

  const entities: Leaf[] = entityPieces.map(([entityKey, stylePieces]) => {
    let entity = entityKey ? contentState.getEntity(entityKey) : null;

    if (entity) {
      const variable = entity.getData().mention.variable;

      return { type: 'variable', variable: variable };
    } else {
      const text: string = stylePieces.map(([text, _]) => text).join('');

      return { text };
    }
  });

  return entities;
}

export function deserialize(content: Content, allVariables: VariableModel[]): ContentState {
  let entityMap = {};
  let entityKey = 0;

  let blocks = content.map((paragraph: Paragraph): RawDraftContentBlock => {
    let offset = 0;
    let text = '';
    let entityRanges: { offset: number; length: number; key: number }[] = [];

    paragraph.children.forEach(leaf => {
      if (isText(leaf)) {
        text += leaf.text;
        offset += size(leaf.text);
      } else if (isVariable(leaf)) {
        const name = allVariables.find(v => v.variable === leaf.variable)?.name || 'Unknown Variable';

        entityMap[entityKey] = {
          type: '{mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              name: name,
              variable: leaf.variable,
            },
          },
        };

        entityRanges.push({ offset: offset, length: name.length, key: entityKey });

        entityKey++;
        text += name;
        offset += name.length;
      } else {
        throw 'Unknown leaf found';
      }
    });

    let data;

    if (paragraph.condition) {
      const condition = paragraph.condition.charAt(0) === '!' ? 'not present' : 'present';
      const variable = paragraph.condition.charAt(0) === '!' ? paragraph.condition.substring(1) : paragraph.condition;

      data = {
        isConditional: true,
        variable,
        condition,
      };
    }

    return {
      data: data || {},
      depth: 0,
      entityRanges: entityRanges,
      inlineStyleRanges: [],
      key: genKey(),
      text,
      type: 'unstyled',
    };
  });

  return convertFromRaw({ blocks, entityMap });
}

function isVariable(leaf: Leaf): leaf is Variable {
  return (leaf.type && leaf.type === 'variable') || false;
}

function isText(leaf: Leaf): leaf is Text {
  return leaf.type === undefined;
}

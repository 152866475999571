import type { FC } from 'react';

interface Props {
  filtering: boolean;
}

const NoAppointmentTypes: FC<Props> = ({ filtering }) => {
  if (filtering) {
    return <span>No appointment types match this filter.</span>;
  } else {
    return <span>No appointment types have been created.</span>;
  }
};

export default NoAppointmentTypes;

import type { FC } from 'react';

import EmailTemplateSelect from '@/ui/EmailTemplateSelect';
import TextRow from '@/ui/TextRow';

interface Props {
  allowsCustomEmails: boolean;
  name: string;
  value: string | number;
  templateType: 'all' | 'confirmation' | 'cancellation' | 'notification' | 'reminder';
  label: string;
  helpText: string;
  onNameChange?: (name: string) => void;
}

const EmailControl: FC<Props> = ({ allowsCustomEmails, name, value, templateType, label, helpText, onNameChange }) => {
  return (
    <>
      {allowsCustomEmails ? (
        <EmailTemplateSelect
          name={name}
          value={value as number}
          templateType={templateType}
          label={label}
          helpText={helpText}
          onNameChange={onNameChange}
        />
      ) : (
        <TextRow
          label={label}
          value={value as string}
          helpText="Custom email templates are only available to TimeZest Professional subscribers."
        />
      )}
    </>
  );
};

export default EmailControl;

import type { FC } from 'react';

import { sentence } from '@shared/text';
import type { CalendarModel, UserCalendarModel } from '@models/Calendar';

interface Props {
  calendars: CalendarModel[];
}

const UserCalendarsSummary: FC<Props> = ({ calendars }) => {
  const enabledCalendars = calendars
    .filter(c => !!c.id)
    .filter(c => (c as UserCalendarModel).enabledToRead)
    .map(c => c.name);

  let summary = '';

  if (enabledCalendars.length === 0) {
    summary = 'All calendars are disabled.';
  } else if (enabledCalendars.length === 1) {
    summary = `${enabledCalendars[0]} calendar is enabled.`;
  } else {
    summary = `${sentence(enabledCalendars)} calendars are enabled.`;
  }

  return <>{summary}</>;
};

export default UserCalendarsSummary;

import type { FC } from 'react';
import { useContext, useMemo, useState } from 'react';

import classNames from 'classnames';

import { paddingStyle, selectElementHandler } from '../utilities';

import imagePlaceholder from './email-editor-no-image-placeholder.png';

import { useSortable } from '@dnd-kit/sortable';

import ConfigContext from '../../ConfigContext';
import { useAppDispatch, useAppSelector } from '../../hooks';
import DragHandle from '../../DragHandle';
import type { IndexedMjmlImage } from '../../EmailTemplate';
import { extractNumber } from '../../utilities';

interface Props {
  node: IndexedMjmlImage;
  selected: boolean;
}

const MjmlImage: FC<Props> = ({ node, selected }) => {
  const { readOnly } = useContext(ConfigContext);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: {
      type: 'mjml-image',
    },
  });

  const draggingElement = useAppSelector(state => state.ui.draggingElement);
  const index = useAppSelector(state => state.template.index);
  const selectedElementId = useAppSelector(state => state.ui.selectedElementId);
  const initialSelectedElementId = useAppSelector(state => state.ui.initialSelectedElementId);

  const [hovering, setHovering] = useState(false);

  const dispatch = useAppDispatch();

  const style = useMemo(() => {
    return {
      zIndex: selected ? 999 : 'unset',
      transition,
      opacity: isDragging ? 0.5 : 1,
      margin: `0 ${node.alignment === 'right' ? 0 : 'auto'} 0 ${node.alignment === 'left' ? 0 : 'auto'}`,
      padding: paddingStyle(node.padding),
      maxWidth: '100%',
    };
  }, [node.padding, node.alignment, selected, transition, isDragging]);

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  return (
    <div
      role="presentation"
      id={node.id}
      ref={setNodeRef}
      className={classNames('position-relative', 'EmailEditor__Element', {
        'EmailEditor__Element--highlighted': (hovering && !draggingElement) || selected,
      })}
      onClick={selectElementHandler(dispatch, node.id, index, selectedElementId, initialSelectedElementId)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      <img
        style={style}
        className="d-block pe-none"
        height={extractNumber(node.height || '', 'px') || 'auto'}
        width={extractNumber(node.width || '', 'px') || 'auto'}
        src={node.url || imagePlaceholder}
        alt={node.altText}
      />
      <div className="EmailEditor__Element_Identifier">
        Image
        <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
          <DragHandle selected={selected} />
        </span>
      </div>
    </div>
  );
};

export default MjmlImage;

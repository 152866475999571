import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';
import SelectRow from '@/ui/SelectRow';

import PlaceholderCalendar from './PlaceholderCalendar';
import UserCalendar from './UserCalendar';

import type { CalendarModel, UserCalendarModel } from '@models/Calendar';
import { idStringifier } from '@shared/text';

interface Props {
  calendars: CalendarModel[];
}

const CalendarsConfig: FC<Props> = ({ calendars }) => {
  const persistedCalendars = calendars.filter(c => !!c.id) as UserCalendarModel[];
  const sortedCalenders = calendars.sort((a, b) => a.name.localeCompare(b.name));

  const context = useContext(DirtyContext);
  const [primaryCalendar, setPrimaryCalendar] = useState(persistedCalendars.find(c => c.enabledToWrite)?.id);

  const handleSelectWriteableCalendar = (value: string | undefined): void => {
    if (context.handleDirty) context.handleDirty();

    setPrimaryCalendar(Number(value));
  };

  const readableCalendars = persistedCalendars.filter(c => c.enabledToRead).map(c => c.id);
  const selecatableCalendars = persistedCalendars.sort((a, b) => a.name.localeCompare(b.name)).filter(c => !c.readOnly);

  return (
    <>
      {sortedCalenders.map((c, index) =>
        c.id ? (
          <UserCalendar
            key={index}
            calendar={c as UserCalendarModel}
            enabledToRead={readableCalendars.indexOf(c.id!) >= 0}
          />
        ) : (
          <PlaceholderCalendar key={index} name={c.name} />
        )
      )}
      <SelectRow
        label="Primary Calendar"
        helpText="TimeZest will use this calendar as the primary calendar to write new appointments to."
        name={`calendars[${primaryCalendar}][enabled_to_write]`}
        value={primaryCalendar || 0}
        options={[...selecatableCalendars.map(idStringifier)]}
        onChange={handleSelectWriteableCalendar}
      />
    </>
  );
};

export default CalendarsConfig;

import type { FC } from 'react';
import { useState } from 'react';

import RadioButtonRow from './ui/RadioButtonRow';

import TextInput from '@shared/ui/Inputs/TextInput';

interface Props {
  error: string;
  useSingleExternalEmail: boolean;
  singleExternalEmail: string | null;
}

const AccountExternalEmail: FC<Props> = ({
  error,
  useSingleExternalEmail: initialUseSingleExternalEmail,
  singleExternalEmail: initialSingleExternalEmail,
}) => {
  const [useSingleExternalEmail, setUseSingleExternalEmail] = useState(initialUseSingleExternalEmail);
  const [singleExternalEmail, setSingleExternalEmail] = useState(initialSingleExternalEmail);

  const handleUseSingleExternalEmailChange = (value: string): void => {
    setUseSingleExternalEmail(value === 'true');
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSingleExternalEmail(e.target.value);
  };

  return (
    <RadioButtonRow
      label="External Email Address"
      name="account[use_single_external_email]"
      error={error}
      helpText={
        <div className="text-muted">
          <p className="mb-1">
            If you don&apos;t wish for your clients to know the individual email addresses of users they work with, you
            can specify a single external email address, such as your main support email, and TimeZest will use that
            instead.
          </p>
          <p className="mb-0">
            <strong>Note:</strong> When a client adds an invitation from TimeZest to their calendar, Microsoft Outlook
            and other programs will send their acceptance notification to this address.
          </p>
        </div>
      }
      value={useSingleExternalEmail.toString()}
      options={[
        { label: "Use each users' individual email address when communicating with clients.", value: 'false' },
        {
          label: 'Use a single email address for every user:',
          value: 'true',
          appendedElement: (
            <TextInput
              className="mt-1"
              name="account[single_external_email]"
              type="text"
              value={singleExternalEmail || ''}
              placeholder="support@yourcompany.com"
              disabled={!useSingleExternalEmail}
              onChange={handleEmailChange}
            />
          ),
        },
      ]}
      onChange={handleUseSingleExternalEmailChange}
    />
  );
};

export default AccountExternalEmail;

import { useEffect, useState } from 'react';

interface FetchWithTriggerState {
  data: Record<string, any> | null;
  error: string | null;
  loading: boolean;
}

type MergeCallback = (existing: Record<string, any>, received: Record<string, any>) => Record<string, any>;

export default function useFetchWithTrigger(
  url: string,
  trigger: boolean,
  mergeData?: MergeCallback
): [{ [key: string]: any }, string | null, boolean] {
  const [fetchState, setFetchState] = useState<FetchWithTriggerState>({ data: null, error: null, loading: false });

  useEffect(() => {
    if (!trigger) return;

    const controller = new AbortController();
    setFetchState(({ data: prevData }) => {
      return {
        data: mergeData ? prevData : [],
        error: null,
        loading: true,
      } as FetchWithTriggerState;
    });

    fetch(url, {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      signal: controller.signal,
    })
      .then(resp => resp.json())
      .then(json => {
        if (controller.signal.aborted) return;

        if (json.error) {
          setFetchState({ data: null, error: json.error, loading: false });
        } else {
          setFetchState(({ data: prevData }) => {
            return {
              data: mergeData ? mergeData(prevData || {}, json) : json,
              error: null,
              loading: false,
            } as FetchWithTriggerState;
          });
        }
      })
      .catch(error => {
        if (controller.signal.aborted) return;

        setFetchState({ data: null, error, loading: false });
      });

    return (): void => {
      controller.abort();
    };
  }, [trigger, url, mergeData, setFetchState]);

  return [fetchState?.data || {}, fetchState.error, fetchState.loading];
}

import { RichUtils } from 'draft-js';

import type { EditorPlugin } from '@draft-js-plugins/editor';

export const richTextPlugin: EditorPlugin = {
  handleKeyCommand: (command, editorState, eventTimeStamp, pluginFunctions) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      pluginFunctions.setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  },
};

import type { FC } from 'react';

import PlainTextRow from '@/ui/PlainTextRow';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetChannelQuery } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  teamId: string | undefined;
  channelId: string | undefined;
  templateMode?: boolean;
}

const ChannelSelect: FC<Props> = ({ teamId, error, channelId: initialChannelId, readOnly, templateMode }) => {
  const queryResult = useGetChannelQuery({ variables: { teamId: teamId || '' }, skip: templateMode || !teamId });

  const [channelId, setChannelId] = useStateFromProp(initialChannelId);
  const channels = queryResult.data?.microsoftTeams.channels || [];
  const channel = channels.find(c => c.id === channelId);

  const handleChannelChange = (value: string | undefined) => {
    setChannelId(value);
  };

  if (!teamId) {
    return (
      <PlainTextRow label="Channel" helpText="">
        Select a Team to see available channels.
      </PlainTextRow>
    );
  }

  return (
    <>
      <SelectRowFromGraphQL
        label="Channel"
        name="channel_id"
        helpText=" "
        field="microsoftTeams.channels"
        loadingMessage="Loading Channels from Microsoft Teams..."
        emptyDataMessage="No channels have been defined for this team."
        prompt="Select a channel..."
        queryErrorMessage={`Error loading Channels from Microsoft Teams: ${error}.`}
        error={error}
        readOnly={readOnly}
        templateMode={templateMode}
        value={channelId || ''}
        queryResult={queryResult}
        onChange={handleChannelChange}
      />

      <input type="hidden" name="channel_name" value={channel?.name || ''} />
    </>
  );
};

export default ChannelSelect;

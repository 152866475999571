import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When it executes this action, TimeZest will update the primary resource on an Autotask ticket associated with the
      scheduling request to the scheduled user.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When no scheduled users have been determined for the scheduling request (i.e. prior to the client selecting a
        time).
      </li>
      <li>
        When the scheduled user does not have a valid service desk role assigned, as this is required by Autotask.
      </li>
      <li>
        When there is no Autotask ticket associated with the scheduling request, as Autotask requires one to create a
        ticket.
      </li>
      <li>When no Autotask integration is configured.</li>
    </ul>
    <p>
      When using panel scheduling, the first resource for the scheduling request will determine the scheduled user that
      becomes the primary resource.
    </p>
  </>
);

const UpdateTicketPrimaryResource: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="user-b"
      summary={
        <>
          Update the <strong>primary resource</strong> for the ticket to the scheduled user in Autotask.
        </>
      }
      readOnly={readOnly}
    >
      <p>Update the primary resource for the ticket to the scheduled user in Autotask.</p>
    </Action>
  </ErrorBoundary>
);

export default UpdateTicketPrimaryResource;

import type { FC } from 'react';

import CopyToClipboard from '@/CopyToClipboard';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type DomainModel from '@models/Domain';

interface Props {
  domain: DomainModel;
}

const CustomDomainDetails: FC<Props> = ({
  domain: { dkimHost, dkimValue, dkimVerified, id, name, returnPathVerified },
}) => {
  return (
    <div>
      <p>
        The following DNS records are required for TimeZest to be able to send from this You&apos;ll need to consult
        your DNS provider for instructions on how to add them.
      </p>
      <div className="card mb-3">
        <div className="card-body">
          <table>
            <thead>
              <tr className="border-bottom">
                <th className="pb-2 pe-2 ps-0 pt-2">Hostname</th>
                <th className="p-2">Type</th>
                <th className="p-2">Value</th>
                <th className="p-2 pe-0">Verified?</th>
              </tr>
            </thead>
            <tbody className="align-top">
              <tr className="border-bottom">
                <td className="pb-2 pe-2 ps-0 pt-2">
                  <span className="text-monospace">{dkimHost.replace(`.${name}`, '')}</span>
                  &nbsp;
                  <CopyToClipboard text={dkimHost.replace(`.${name}`, '')} />
                </td>
                <td className="p-2">
                  <span className="text-monospace">TXT</span>
                </td>
                <td className="w-50 p-2">
                  <span className="text-monospace text-break">{dkimValue}</span>
                  &nbsp;
                  <CopyToClipboard text={dkimValue} />
                </td>
                <td className="p-2 pe-0">
                  {dkimVerified ? (
                    <span className="text-success">
                      <FontAwesomeIcon icon="check" />
                      &nbsp; Verified
                    </span>
                  ) : (
                    <span className="text-danger">
                      <FontAwesomeIcon icon="times" />
                      &nbsp; Not Verified
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="pb-2 pe-2 ps-0 pt-2">
                  <span className="text-monospace">pmbounces</span>
                  &nbsp;
                  <CopyToClipboard text="pmbounces" />
                </td>
                <td className="p-2">
                  <span className="text-monospace">CNAME</span>
                </td>
                <td className="w-50 p-2">
                  <span className="text-monospace text-break">pm.mtasv.net</span>
                  &nbsp;
                  <CopyToClipboard text="pm.mtasv.net" />
                </td>
                <td className="p-2 pe-0">
                  {returnPathVerified ? (
                    <span className="text-success">
                      <FontAwesomeIcon icon="check" />
                      &nbsp; Verified
                    </span>
                  ) : (
                    <span className="text-danger">
                      <FontAwesomeIcon icon="times" />
                      &nbsp; Not Verified
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <a className="btn btn-primary" rel="nofollow" data-method="post" href={`/settings/domains/${id}/verify`}>
        Verify DNS Records &nbsp; <FontAwesomeIcon icon="angle-right" />
      </a>
    </div>
  );
};

export default CustomDomainDetails;

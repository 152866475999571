import type { FC } from 'react';

import { renderSingularOrPluralActiveMember, renderSingularOrPluralInactiveMember } from './utilities';

import type { Member } from '@models/TeamMember';

interface Props {
  members: Member[];
}

const MembershipSummary: FC<Props> = ({ members }) => {
  let summary = '';
  let activeMemberCounter = 0;
  let inactiveMemberCounter = 0;

  members.forEach(member => {
    if (member.schedulable) {
      activeMemberCounter++;
    } else {
      inactiveMemberCounter++;
    }
  });

  if (activeMemberCounter === 0 && inactiveMemberCounter === 0) {
    summary = 'There are currently no users assigned to this team.';
  } else if (activeMemberCounter === 0 && inactiveMemberCounter !== 0) {
    summary = renderSingularOrPluralInactiveMember(inactiveMemberCounter) + '.';
  } else if (inactiveMemberCounter === 0 && activeMemberCounter !== 0) {
    summary = renderSingularOrPluralActiveMember(activeMemberCounter) + '.';
  } else {
    summary =
      renderSingularOrPluralActiveMember(activeMemberCounter) +
      '; ' +
      renderSingularOrPluralInactiveMember(inactiveMemberCounter) +
      '.';
  }

  return <>{summary}</>;
};

export default MembershipSummary;

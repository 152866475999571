import type { FC } from 'react';

import IntegrationStatus from './IntegrationStatus';

interface Props {
  connected: boolean;
  description: string;
  integrationName: string;
  logoClass: string;
  upgrade: boolean;
  upgradeUrl: string;
}

const IntegrationCardContent: FC<Props> = ({
  connected,
  description,
  integrationName,
  logoClass,
  upgrade,
  upgradeUrl,
}) => (
  <div className="card">
    <div className="card-body">
      <div className={`${logoClass}-logo`} />
      {upgrade ? (
        <>
          <div className="integration-info text-danger">
            Upgrade to TimeZest Professional to integrate with {integrationName}.
          </div>
          <div className="integration-status">
            <a href={upgradeUrl}>Upgrade Now</a>
          </div>
        </>
      ) : (
        <>
          <div className="integration-info">{description}</div>
          <div className="integration-status">
            <IntegrationStatus connected={connected} />
          </div>
        </>
      )}
    </div>
  </div>
);

export default IntegrationCardContent;

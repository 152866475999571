import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityStagesQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const HELP_TEXT = 'TimeZest will create the opportunity in Autotask with this stage.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  stageId: string | undefined;
  templateMode?: boolean;
}

const StageSelect: FC<Props> = ({ stageId: initialStageId, error, readOnly, templateMode }) => {
  const queryResult = useGetOpportunityStagesQuery({ skip: templateMode });

  const [stageId, setStageId] = useStateFromProp(initialStageId, initialStageId => initialStageId || '');

  const stages = queryResult.data?.autotask.opportunityStages || [];
  const stage = stages.find(s => s.id === stageId);

  const handleStageChange = (value: string) => {
    if (value !== stageId) {
      setStageId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      label="Stage"
      helpText={HELP_TEXT}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity stage details from Autotask because the API user does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the security level grants <strong>Full Access</strong> to the <strong>CRM</strong>{' '}
          permissions.
          <a
            href="https://help.timezest.com/en/articles/4840136-creating-a-security-level-for-autotask"
            target="_blank"
            rel="noreferrer"
            className="d-flex align-items-center d-block w-75 m-0 mt-3"
          >
            You can find full details here <FontAwesomeIcon icon="external-link" size="sm" className="ms-1" />
          </a>
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity stages from Autotask."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        name="psa_stage_id"
        label="Stage"
        loadingMessage="Loading opportunity stages from Autotask..."
        value={stageId || '29682769'}
        field="autotask.opportunityStages"
        emptyDataMessage="No opportunity stages found."
        readOnly={readOnly}
        templateMode={templateMode}
        error={error}
        helpText={HELP_TEXT}
        queryResult={queryResult}
        queryErrorMessage={queryErrorMessage}
        onChange={handleStageChange}
      />
      <input type="hidden" name="psa_stage_name" value={stage?.name || ''} />
    </>
  );
};

export default StageSelect;

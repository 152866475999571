import { useContext, type FC } from 'react';

import Header from '@/Header';
import Flash from '@/Flash';
import asScreen from '@/Screen';
import Form from '@/Form';
import EmailEditor from '@/EmailEditor';
import AccountContext from '@/AccountContext';

import type Variable from '@models/Variable';
import type { EmailTemplate } from '@models/EmailTemplate';

interface Props {
  availableVariables: Variable[];
  error: string;
  initialTemplate: EmailTemplate;
  logoUrl: string | null;
}

const DefaultEmailTemplate: FC<Props> = ({ availableVariables, error, initialTemplate, logoUrl }) => {
  const { brandColor } = useContext(AccountContext);

  return (
    <>
      <Header title="Default Email Template" />
      <Flash />

      <Form method="patch" url="/settings/default_email_template">
        <div className="mb-3">
          <div className="bg-light">
            <EmailEditor
              availableVariables={availableVariables}
              initialTemplate={initialTemplate}
              logoUrl={logoUrl}
              name="account[default_email_template]"
              brandColor={brandColor}
              readOnly={false}
            />
          </div>
          {error && <div className="invalid-feedback">{error}</div>}
        </div>

        <div className="d-flex align-items-center gap-2">
          <button type="submit" className="btn btn-primary">
            Save changes
          </button>
          <div className="flex-grow-1">
            <a href="/settings/email">Cancel</a>
          </div>
        </div>
      </Form>
    </>
  );
};

export default asScreen(DefaultEmailTemplate);

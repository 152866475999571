import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update an Autotask contact associated with the scheduling request with
      the phone number supplied by the client.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When the phone number given by the client does not appear to be a plausible phone number.</li>
      <li>When entering the phone number is optional, and the client chooses not to give one.</li>
      <li>When no Autotask contact is associated with the scheduling request.</li>
      <li>When your TimeZest plan does not support updating contacts.</li>
      <li>When no Autotask integration is configured.</li>
    </ul>
    <p>
      When this action is included in a workflow, TimeZest will ask the client for the phone number during scheduling.
    </p>
  </>
);

const UpdateContactPhoneNumber: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="telephone"
      summary="Update the contact's phone number in Autotask."
      readOnly={readOnly}
    >
      <p>Update the phone number of the contact associated with the ticket in Autotask.</p>
    </Action>
  </ErrorBoundary>
);

export default UpdateContactPhoneNumber;

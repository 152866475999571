import type { FC } from 'react';

import NewTeam from '@ui/NewTeam';

const NewProjectTeam: FC = () => {
  return (
    <NewTeam
      teamUrl={(boardId, teamId): string => `/connectwise/project_boards/${boardId}/teams/${teamId}`}
      boardLabel="Project Board"
      teamLabel="Project Team"
      createUrl="/settings/teams/project"
      type="project"
    />
  );
};

export default NewProjectTeam;

import { Fragment, useState } from 'react';
import type { FC } from 'react';

import { ConfigContext } from '@/EmailEditor/EmailEditor';

import { Context as AvailableOptionsContext } from './AvailableOptions';
import Trigger from './Trigger';

import type { EmailTemplate } from '@models/EmailTemplate';
import type VariableModel from '@models/Variable';
import type { Trigger as TriggerModel } from '@graphql/generated';
import { useCreateTriggerMutation, useGetWorkflowTriggersQuery } from '@graphql/generated';
import type { TimeElapsedTriggerModel } from '@models/TimeElapsedTriggerModel';
import Select from '@shared/ui/Select';
import VariablesContext from '@shared/VariablesContext';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  appointmentTypeId: number;
  triggers: (TriggerModel | TimeElapsedTriggerModel)[];
  readOnly: boolean;
  availableTriggerOptions: { name: string; slug: string }[];
  availableActionOptions: { name: string; slug: string }[];
  availableLanguages: [string, string][];
  availableVariables: VariableModel[];
  logoUrl: string | null;
  defaultEmailTemplate?: EmailTemplate;
}

const Workflow: FC<Props> = ({
  appointmentTypeId,
  triggers: initialTriggers,
  readOnly,
  availableTriggerOptions,
  availableActionOptions,
  availableLanguages,
  availableVariables,
  defaultEmailTemplate,
  logoUrl,
}) => {
  const { data, refetch } = useGetWorkflowTriggersQuery({
    fetchPolicy: 'no-cache',
    variables: { appointmentTypeId },
    skip: readOnly,
  });
  const defaultTrigger = availableTriggerOptions.length && !readOnly ? availableTriggerOptions[0].slug : '';

  const [newTrigger, setNewTrigger] = useState(defaultTrigger);
  const [triggers, setTriggers] = useStateFromProp(data?.timezest.triggers, triggers => triggers || initialTriggers);
  const [createTrigger, { data: triggerData, loading: triggerLoading, error: triggerError }] = useCreateTriggerMutation(
    {
      refetchQueries: ['getWorkflowTriggers'],
      variables: {
        appointmentTypeId,
        type: newTrigger,
        timeMins: null,
      },
    }
  );

  const errors = triggerData?.createTrigger.errors || [];

  const handleTriggerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setNewTrigger(e.target.value);
  };

  const handleTriggerCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    createTrigger();
  };

  const handleRefetchWorkflowTriggers = () => {
    refetch().then(res => {
      setTriggers(res.data?.timezest.triggers);
    });
  };

  const [timeElapsedTriggers, normalTrigger] = triggersSplitter(triggers);
  const sortedTimeElapsedTriggers = timeElapsedTriggers.sort((a, b) => {
    if (a.timeMins && b.timeMins) {
      return a.timeMins - b.timeMins;
    }

    return 0;
  });

  const processedTriggers = normalTrigger.concat(sortedTimeElapsedTriggers) as TriggerModel[];

  return (
    <div>
      {!readOnly && (
        <>
          <h5>Add new trigger</h5>

          <div className="row">
            <div className="col-sm-2">
              <label htmlFor="trigger-type" className="label col-form-label fw-bold">
                Trigger Type
              </label>
            </div>

            <div className="col-sm-6">
              <Select
                id="trigger-type"
                name="selected-action"
                disabled={readOnly}
                value={newTrigger}
                options={availableTriggerOptions.map((trigger, index) => ({
                  name: trigger.name,
                  value: trigger.slug,
                  key: trigger.slug + index,
                }))}
                onChange={handleTriggerChange}
              />
            </div>

            <div className="col-sm-4">
              {triggerLoading ? (
                <button disabled className="btn btn-primary">
                  Adding trigger...
                </button>
              ) : (
                <button className="btn btn-primary" disabled={readOnly} onClick={handleTriggerCreate}>
                  Add Trigger
                </button>
              )}
            </div>
            {(errors.length > 0 || triggerError) && (
              <div className="col-sm-12">
                {errors.map((error, i) => (
                  <p key={i} className="text-danger mb-0 mt-1">
                    {error}
                  </p>
                ))}
                {triggerError && <p className="text-danger mb-0 mt-1">{triggerError.message}</p>}
              </div>
            )}
          </div>
          <hr />
        </>
      )}
      <AvailableOptionsContext.Provider value={{ availableLanguages }}>
        <VariablesContext.Provider
          value={{ variables: availableVariables.sort((a, b) => a.name.localeCompare(b.name)) }}
        >
          <ConfigContext.Provider
            value={{
              defaultEmailTemplate,
              logoUrl,
              readOnly,
            }}
          >
            <WorkflowActionContext.Provider
              value={{
                expandable: true,
                initialExpanded: 'new',
                actionReviewable: false,
                templateMode: false,
                refetchWorkflowTriggers: handleRefetchWorkflowTriggers,
              }}
            >
              {processedTriggers.length > 0 ? (
                processedTriggers.map((trigger, index) => (
                  <Fragment key={trigger.id}>
                    {index > 0 && <hr className="mt-4" />}
                    <Trigger
                      trigger={trigger}
                      availableActions={availableActionOptions}
                      readOnly={readOnly}
                      succeeded={!!triggerData && triggerData.createTrigger.trigger?.id === trigger.id}
                    />
                  </Fragment>
                ))
              ) : (
                <span className="text-muted"> No triggers have been defined for this workflow.</span>
              )}
            </WorkflowActionContext.Provider>
          </ConfigContext.Provider>
        </VariablesContext.Provider>
      </AvailableOptionsContext.Provider>
    </div>
  );
};

export default Workflow;

function triggersSplitter(triggers: { timeMins?: number | null }[]) {
  const isTimeElapsedTrigger = (t: TriggerModel | TimeElapsedTriggerModel) => t.slug.indexOf('time_elapsed') !== -1;

  return triggers.reduce(
    ([timeElapsedTrigger, normalTrigger], trigger) => {
      return isTimeElapsedTrigger(trigger as TriggerModel)
        ? [[...timeElapsedTrigger, trigger], normalTrigger]
        : [timeElapsedTrigger, [...normalTrigger, trigger]];
    },
    [[], []]
  );
}

import type { FC } from 'react';
import { forwardRef } from 'react';

import type { WorkflowActionProps } from '@/ui/Workflow/types';

import StatusSelect from './StatusSelect';

import ErrorBoundary from '@shared/ErrorBoundary';
import Action from '@shared/Action';
import type { AutotaskUpdateTicketStatusAction } from '@graphql/generated';
import RadioButtonRow from '@ui/RadioButtonRow';
import useFormSubmit from '@shared/hooks/useFormSubmit';

import FormError from '../../FormError';
import { useUpdateTicketStatusForAutotask } from '../../hooks';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update the status in Autotask of the ticket associated with this
      scheduling request to the status configured for the{' '}
      <a href="/settings/integrations/autotask">Autotask integration</a>.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no ticket associated with this scheduling request.</li>
      <li>When there is no status configured in TimeZest for the update.</li>
      <li>When the configured status cannot be found in Autotask, or is marked as &apos;inactive&apos;.</li>
      <li>When no Autotask integration is configured.</li>
    </ul>
  </>
);

const UpdateTicketStatus = forwardRef<HTMLFormElement, WorkflowActionProps<AutotaskUpdateTicketStatusAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useUpdateTicketStatusForAutotask();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        skipIfClosed: (data.get('skip_if_closed') as string) === 'true',
        statusId: data.get('psa_status_id') as string,
        statusName: data.get('status_name') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="task-download"
            summary={<Summary name={action.statusName} />}
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <StatusSelect
              name="psa_status_id"
              statusName="status_name"
              label="Status"
              helpText="TimeZest will update the service ticket to this status."
              error={errors.psa_status_id}
              readOnly={readOnly}
              selectedStatusId={action.psaStatusId}
              selectedStatusName={action.statusName}
            />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="skip_if_closed"
              value={(action.skipIfClosed || false).toString()}
              label="Skip If Closed"
              helpText=""
              options={[
                {
                  label: 'Always update the status of the associated ticket ',
                  value: 'false',
                  disabled: readOnly,
                },
                {
                  label: 'Skip updating the status of the associated ticket if it is closed',
                  value: 'true',
                  disabled: readOnly,
                },
              ]}
              error={errors.skip_if_closed}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

const Summary: FC<{ name: string }> = ({ name }) => (
  <>
    Update the ticket to{' '}
    {name ? (
      <>
        the <strong>{name}</strong>
      </>
    ) : (
      'a new'
    )}
    &nbsp;status in Autotask.
  </>
);

export default UpdateTicketStatus;

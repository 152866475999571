import type { FC } from 'react';
import { useContext } from 'react';

import { DirtyContext } from '@/DirtyContext';

import useStateFromProp from '@shared/hooks/useStateFromProp';
import Row from '@ui/Row';
import Select from '@shared/ui/Select';

interface Props {
  disabled?: boolean;
  error?: string;
  helpText?: string | JSX.Element | null;
  label: string;
  name: string;
  options: { name: string; value: string; disabled?: boolean }[];
  value: string | number;
  warning?: string;
  width?: number;
  onChange?: (value: string | undefined, name: string | undefined) => void;
}

const SelectRow: FC<Props> = ({
  disabled,
  error,
  helpText,
  label,
  name,
  options,
  value: initialValue,
  warning,
  width,
  onChange,
}) => {
  const [value, setValue] = useStateFromProp(initialValue);
  const { handleDirty } = useContext(DirtyContext);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (handleDirty) {
      handleDirty();
    }

    setValue(event.target.value);

    if (onChange) {
      const el = event.target as HTMLSelectElement;
      onChange(el.value, el.options[el.selectedIndex].innerText);
    }
  };

  return (
    <Row labelFor={name} label={label} helpText={helpText} width={width}>
      <Select
        name={name}
        id={name}
        error={error}
        value={value}
        disabled={disabled}
        options={options}
        onChange={handleChange}
      />
      {warning && <small className="form-text text-warning">{warning}</small>}
    </Row>
  );
};

export default SelectRow;

import type { FC } from 'react';
import { useState } from 'react';

import asScreen from '@/Screen';

import AppointmentTypesFilter from './Filter';
import AppointmentTypesList from './AppointmentTypesList';

import type { Filter } from '@models/Filter';
import { generateTQLString } from '@shared/ui/Filter/utilities';

interface Props {
  tqlFilters: Filter[];
}

const TqlAppointmentTypes: FC<Props> = ({ tqlFilters }) => {
  const [tqlString, setTqlString] = useState(generateTQLString(tqlFilters));

  return (
    <>
      <AppointmentTypesFilter tqlFilters={tqlFilters} onUpdateTqlString={setTqlString} />
      <AppointmentTypesList tqlString={tqlString} />
    </>
  );
};

export default asScreen(TqlAppointmentTypes);

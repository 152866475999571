import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import ConfigContext from './ConfigContext';
import Button from './Tools/Button';
import Section from './Tools/Section';
import Text from './Tools/Text';
import { useAppDispatch, useAppSelector } from './hooks';
import Properties from './Properties';
import { selectElement, updateProperty } from './actions';
import type { Index } from './Reducers/templateReducer';
import Logo from './Tools/Logo';
import ColorInput from './Properties/Inputs/Color';
import Spacer from './Tools/Spacer';
import Field from './Properties/Inputs/Field';
import NewToolsContainer from './Tools/NewToolsContainer';
import type { IndexedMjmlBody, IndexedMjmlNode } from './EmailTemplate';
import Image from './Tools/Image';

const ToolBarAndPropertiesContainer = styled.div`
  width: 320px;
`;

const ToolbarContainer = styled.div`
  background: #f8f8f8;
  padding: 20px;
  padding-bottom: 0px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 2px 0px;
  height: 750px;
`;

const findMjmlBody = (rootNode: IndexedMjmlNode, index: Index): IndexedMjmlBody => {
  let currentNode = rootNode;

  if (currentNode.type === 'mjml-body') {
    return currentNode;
  } else {
    currentNode = index[currentNode.childIds[0]];

    const mjmlBodyNode = findMjmlBody(currentNode, index);
    return mjmlBodyNode;
  }
};

const Toolbar: FC<{}> = () => {
  const { readOnly } = useContext(ConfigContext);

  const [bodyBgColor, setBodyBgColor] = useState('');
  const [bodyId, setBodyId] = useState('');

  const dispatch = useAppDispatch();

  const selectedElementId = useAppSelector(state => state.ui.selectedElementId);
  const rootId = useAppSelector(state => state.template.rootId);
  const index = useAppSelector(state => state.template.index);

  useEffect(() => {
    if (index[rootId]) {
      const mjmlBodyNode = findMjmlBody(index[rootId], index);

      setBodyBgColor(mjmlBodyNode.backgroundColor as string);
      setBodyId(mjmlBodyNode.id);
    }
  }, [rootId, index]);

  // Remove selection when Esc pressed.
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        dispatch(selectElement({ selectedElementId: null }));
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [dispatch]);

  return (
    <ToolBarAndPropertiesContainer className="position-relative">
      <Properties selectedElementId={selectedElementId} />

      <ToolbarContainer style={{ overflowX: 'hidden' }}>
        <div style={{ width: '275px' }} className="mx-auto">
          <Field
            id="new_elements_tool"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <label className="form-label" htmlFor="new_elements_tool">
                  Content
                </label>
                <p className="small text-black-50 m-0 mb-2 p-0 text-end">Drag to insert</p>
              </div>
            }
          >
            <NewToolsContainer readOnly={readOnly}>
              <Text />
              <Button />
              <Logo />
              <Spacer />
              <Image />
            </NewToolsContainer>
          </Field>

          <hr className="mb-3 mt-2" />

          <Field
            id="new_sections"
            label={
              <div className="d-flex align-items-center justify-content-between">
                <label className="form-label" htmlFor="new_sections">
                  Layout
                </label>
                <p className="small text-black-50 m-0 mb-2 p-0 text-end">Drag to insert</p>
              </div>
            }
          >
            <div className={classNames({ 'pe-none opacity-50': readOnly })}>
              <Section />
            </div>
          </Field>

          <hr className="mb-3 mt-2" />

          <ColorInput
            name="bodyBackgroundColor"
            value={bodyBgColor}
            id={`${bodyId}-background-color`}
            label="Body Background Color"
            disabled={readOnly}
            onInput={(value: string) => {
              dispatch(updateProperty({ elementId: bodyId, property: 'backgroundColor', value }));
            }}
          />
        </div>
      </ToolbarContainer>
    </ToolBarAndPropertiesContainer>
  );
};

export default Toolbar;

import { useState, type FC } from 'react';

import DomainFields from './DomainFields';

import type DomainModel from '@models/Domain';

import RadioButtonRow from '../../../ui/RadioButtonRow';

export interface MailSendingConfigurationProps {
  emailDomainId: number;
  fromLocalPart: string | null;
  mailSendingMode: string;
}

interface Props {
  domains: DomainModel[];
  fromLocalPartError: string | null;
}

const MailSendingConfiguration: FC<Props & MailSendingConfigurationProps> = ({
  domains,
  emailDomainId,
  fromLocalPart,
  fromLocalPartError,
  mailSendingMode: initialMailSendingMode,
}) => {
  const verifiedDomains = domains.filter(d => d.verified);

  const [mailSendingMode, setMailSendingMode] = useState(initialMailSendingMode);

  const handleMailSendingModeChange = (value: string): void => {
    setMailSendingMode(value);
  };

  return (
    <>
      <RadioButtonRow
        label="Mail Sending Mode"
        name="account[mail_sending_mode]"
        helpText={
          <>
            <p>
              TimeZest can send emails from a domain you control. This allows you to choose the &quot;From&quot; address
              for emails, and can also improve deliverability.
            </p>
            {verifiedDomains.length === 0 && (
              <p className="mb-0">To activate the custom domain option, add and verify at least one custom domain.</p>
            )}
          </>
        }
        options={[
          {
            label: (
              <>
                Send emails from <strong>noreply@timezest.com</strong> using TimeZest&apos;s SMTP server.
              </>
            ),
            value: 'postmark',
          },
          { label: 'Send email using a custom domain.', value: 'domain', disabled: verifiedDomains.length === 0 },
        ]}
        value={mailSendingMode}
        onChange={handleMailSendingModeChange}
      />
      {mailSendingMode === 'domain' && (
        <DomainFields
          domains={verifiedDomains}
          emailDomainId={emailDomainId}
          fromLocalPart={fromLocalPart}
          fromLocalPartError={fromLocalPartError}
        />
      )}
    </>
  );
};

export default MailSendingConfiguration;

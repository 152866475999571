import type { FC } from 'react';

import Badge from '@/ui/Badge';

import { teamMembers } from '@shared/utilities';

interface Props {
  id: number;
  members: string[];
  name: string;
}

const TimeZestTeam: FC<Props> = ({ id, members, name }) => {
  return (
    <a className="team" href={`/settings/teams/timezest/${id}`}>
      <div className="card mb-2">
        <div className="card-body py-2">
          <div className="d-flex">
            <div className="title flex-grow-1">
              <strong>{name}</strong>
              &nbsp;
              <Badge color="blue">TimeZest-Defined Team</Badge>
            </div>
          </div>
          <div className="info">
            <div className="small text-muted">{teamMembers(members)}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default TimeZestTeam;

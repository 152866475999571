import { createContext } from 'react';

import type VariableModel from '@models/Variable';

interface Props {
  variables: VariableModel[];
}

const VariablesContext = createContext<Props>({ variables: [] });

export default VariablesContext;

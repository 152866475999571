import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';

import type { CustomAttribute } from '@models/CustomAttribute';
import { capitalize, pluralize } from '@shared/text';

interface Props {
  customAttributes: CustomAttribute[];
}

const CustomAttributesList: FC<Props> = ({ customAttributes }) => {
  const { totalUsers } = useContext(AccountContext);

  return (
    <>
      {customAttributes.map((ca, i) => (
        <div key={i} className="row">
          <div className="col-sm-2 py-3">
            {i === 0 ? <div className="fw-bold">Custom Attributes</div> : <>&nbsp;</>}
          </div>
          <div className="col-sm-5 py-3">
            <a href={`/settings/custom_attributes/${ca.id}`}>{ca.name}</a>
            <div className="small text-muted">
              {ca.usersSetCount} {pluralize('user', ca.usersSetCount)} with values set, {totalUsers - ca.usersSetCount}{' '}
              {pluralize('user', totalUsers - ca.usersSetCount)} with no value set.
            </div>
          </div>
          <div className="col-sm-1 py-3">{capitalize(ca.type)}</div>
          <div className="col-sm-4 py-3">{ca.description}</div>
          {i === customAttributes.length - 1 ? null : (
            <>
              <div className="col-sm-2" />
              <div className="col-sm-10 border-bottom" />
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default CustomAttributesList;

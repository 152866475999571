import type { FC } from 'react';

const PlanOffice365: FC = () => {
  return (
    <>
      <p>
        You have signed up to TimeZest with your Microsoft 365 account. Integration with Microsoft 365 Calendars is
        available only on <strong> TimeZest Professional</strong>.
      </p>
      <p className="mb-0">Contact TimeZest support to discuss any plan changes.</p>
    </>
  );
};

export default PlanOffice365;

import { useCallback, useEffect, useRef, useState } from 'react';

export type StateChangeCallback<T> = (state: T) => void;

export default function useStateWithCallback<T>(
  initialState: T
): [T, (state: T, callback?: StateChangeCallback<T>) => void] {
  const [state, setState] = useState<T>(initialState);
  const callbackRef = useRef<StateChangeCallback<T> | null | undefined>(null);
  const setStateWithCallback = useCallback((state: T, callback?: StateChangeCallback<T>): void => {
    callbackRef.current = callback;
    setState(state);
  }, []);

  useEffect(() => {
    if (!callbackRef.current) return;

    callbackRef.current(state);
    callbackRef.current = null;
  }, [state]);

  return [state, setStateWithCallback];
}

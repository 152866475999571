import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update owner for ServiceNow Incident/Problem/Change Request.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When no ServiceNow integration is configured.</li>
    </ul>
    <p>
      When using panel scheduling, the first resource for the scheduling request will determine the scheduled user to
      whom the ticket will be assigned.
    </p>
  </>
);

const UpdateTaskOwner: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="user-b"
      summary={
        <>
          Update <strong>owner</strong> for a ServiceNow Incident/Problem/Change Request.
        </>
      }
      readOnly={readOnly}
    >
      <p>Update owner for a ServiceNow Incident/Problem/Change Request.</p>
    </Action>
  </ErrorBoundary>
);

export default UpdateTaskOwner;

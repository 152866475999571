import type { FC } from 'react';

import PlainTextRow from '@/ui/PlainTextRow';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetTaskStatesForServiceNowQuery } from '@graphql/generated';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  taskType: string;
  taskStateId: number | null;
  templateMode?: boolean;
  onTaskStateChange: (value: number) => void;
}

const HELP_TEXT = 'TimeZest will update the state of an associated task to the configured status.';

const TaskStateSelect: FC<Props> = ({
  taskType,
  error,
  taskStateId,
  readOnly,
  templateMode,
  onTaskStateChange: handleTaskStateChange,
}) => {
  const queryResult = useGetTaskStatesForServiceNowQuery({
    variables: { taskType },
    skip: templateMode || !taskType,
  });

  const taskStates = queryResult.data?.serviceNow.taskStates || [];
  const selectedTaskState = taskStates.find(state => Number(state.id) === taskStateId);

  if (!taskType) {
    return (
      <PlainTextRow label="Task State" helpText={HELP_TEXT}>
        Select a task type to see available task states.
      </PlainTextRow>
    );
  }

  return (
    <>
      <SelectRowFromGraphQL
        label="Task State"
        name="psa_state_id"
        helpText={HELP_TEXT}
        field="serviceNow.taskStates"
        loadingMessage="Loading task states from ServiceNow..."
        emptyDataMessage="No task states found."
        prompt="Select a task state..."
        queryErrorMessage={`Error loading task states from ServiceNow: ${error}.`}
        error={error}
        readOnly={readOnly}
        templateMode={templateMode}
        value={(taskStateId || '').toString()}
        queryResult={queryResult}
        onChange={(value: string) => handleTaskStateChange(Number(value))}
      />

      <input type="hidden" name="psa_state_name" value={selectedTaskState?.name || ''} />
    </>
  );
};

export default TaskStateSelect;

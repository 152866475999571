import type { FC } from 'react';
import { useState } from 'react';

import CopyToClipboard from '@/CopyToClipboard';
import SearchSelect from '@/ui/SearchSelect';

import type AppointmentTypeModel from '@models/AppointmentType';
import useDisplayDuration from '@shared/hooks/useDisplayDuration';

interface ResourceModel {
  id: string | number;
  name: string;
  slug: string | null;
}

interface Props {
  appointmentTypes: AppointmentTypeModel[];
  host: string | null;
  initialResourceId: number | null;
  maxResources: number;
  resources: ResourceModel[];
}

const AppointmentTypeUrls: FC<Props> = ({ appointmentTypes, host, initialResourceId, maxResources, resources }) => {
  const displayDuration = useDisplayDuration();

  const selectedResourceId = initialResourceId || resources[0]?.id;
  const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>(
    selectedResourceId ? [selectedResourceId.toString()] : []
  );

  const handleSelectionChange = (selectedResourceIds: string[]): void => {
    setSelectedResourceIds(selectedResourceIds);
  };

  const selectedResourceSlugs = resources
    .filter(resource => selectedResourceIds.indexOf(resource.id.toString()) >= 0)
    .map(r => r.slug)
    .join(',');

  return (
    <>
      <div className="d-flex mb-3">
        <div className="fw-bold me-2 pt-2">Show URLs for scheduling all these resource(s):</div>
        <div className="flex-grow-1">
          <SearchSelect
            name="resources"
            initialItemId={initialResourceId?.toString() || resources[0]?.id.toString()}
            items={resources.map(r => ({ id: r.id.toString(), name: r.name }))}
            maxSelectedItems={maxResources}
            minSelectedItems={1}
            handleChange={handleSelectionChange}
          />
        </div>
      </div>
      <div>
        {appointmentTypes.map(appointmentType => (
          <div key={appointmentType.id} className="card mb-2">
            <div className="card-header">
              <strong>{appointmentType.internalName}</strong>
              {appointmentType.externalName && (
                <span className="text-muted">&nbsp;/&nbsp;{appointmentType.externalName}</span>
              )}
              &nbsp; ({displayDuration(appointmentType.durationMins)}) &nbsp;
            </div>
            <div className="card-body">
              <p className="small mb-0">
                <strong className="text-muted">Shareable URL:</strong>
                &nbsp;&nbsp;
                {`${host}/${selectedResourceSlugs}/${appointmentType.slug}`}
                &nbsp;&nbsp;
                <CopyToClipboard text={`${host}/${selectedResourceSlugs}/${appointmentType.slug}`} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AppointmentTypeUrls;

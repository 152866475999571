import type { FC } from 'react';
import { useState } from 'react';

import TextInput from '@shared/ui/Inputs/TextInput';

interface Props {
  onSearchTermChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBox: FC<Props> = ({ onSearchTermChange }) => {
  const [value, setValue] = useState('');

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onSearchTermChange(e);
  };

  return (
    <div className="card bg-light mb-3">
      <div className="card-body py-2">
        <form className="form-inline d-flex align-items-center">
          <label htmlFor="boards-filter">Only show boards whose name contains:</label>
          <div className="flex-grow-1 ms-3">
            <TextInput
              id="boards-filter"
              name="board-search"
              className="w-100"
              value={value}
              onChange={handleSearchTermChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBox;

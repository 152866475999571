import type { FC } from 'react';

import Templates from './Templates';

import type { EmailTemplateModel } from '@models/EmailTemplateModel';
import Alert from '@shared/Alert';

interface Props {
  emailTemplates: EmailTemplateModel[];
}

const EmailTemplates: FC<Props> = ({ emailTemplates }) => {
  const hasLegacyTemplates = emailTemplates.some(template => template.legacy);

  return (
    <>
      {hasLegacyTemplates && (
        <Alert
          className="mb-3 mt-0"
          icon="triangle-exclamation"
          color="yellow"
          content="TimeZest will no longer support legacy templates after May 30, 2024. You will need to convert these templates, indicated by (legacy badge), to email templates that use our modern WYSIWYG email editor for them to continue to work."
        />
      )}
      <Templates emailTemplates={emailTemplates} />
    </>
  );
};

export default EmailTemplates;

import type { FC } from 'react';

import Row from '@/ui/Row';
import Switch from '@/ui/Switch';

import type { UserCalendarModel } from '@models/Calendar';

interface Props {
  calendar: UserCalendarModel;
  enabledToRead: boolean;
}

const UserCalendar: FC<Props> = ({ calendar, enabledToRead }) => {
  const { id, name, enabledToDisplay, enabledToModify, privateCalendar } = calendar;

  return (
    <Row label={name}>
      <Switch
        value={enabledToRead}
        attr={`calendars[${id}][enabled_to_read]`}
        namespace="calendars-settins"
        label="Appointments on this calendar block customers from scheduling conflicting appointments"
        disabled={false}
      />
      <>
        <Switch
          value={enabledToDisplay}
          attr={`calendars[${id}][enabled_to_display]`}
          namespace="calendars-settings"
          label="Appointments on this calendar will be displayed in Dispatch HQ"
          disabled={false}
        />
        <Switch
          value={enabledToModify}
          attr={`calendars[${id}][enabled_to_modify]`}
          namespace="calendars-settings"
          label="Appointments on this calendar will be modifiable in Dispatch HQ"
          disabled={false}
        />
        <Switch
          value={privateCalendar}
          attr={`calendars[${id}][private_calendar]`}
          namespace="calendars-settings"
          label='All appointments on this calendar will appear as "Private Appointment" in Dispatch HQ'
          disabled={false}
        />
      </>
    </Row>
  );
};

export default UserCalendar;

import type { FC } from 'react';

import type { Filter } from '@models/Filter';
import FilterControl from '@shared/ui/Filter';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import usePersistedFilters from '@shared/hooks/usePersistedFilters';

const ATTRIBUTES = [
  { name: 'Name', value: 'agent.name', type: 'string' as const },
  {
    name: 'Email',
    value: 'agent.email',
    type: 'string' as const,
  },
  { name: 'URL Slug', value: 'agent.url_slug', type: 'string' as const },
  { name: 'Role', value: 'agent.role', type: 'equality' as const },
  { name: 'Active', value: 'agent.schedulable', type: 'boolean' as const },
  { name: '2FA Enabled', value: 'agent.two_factor_enabled', type: 'boolean' as const },
];

const POSSIBLE_VALUES = {
  'agent.name': {
    values: [],
  },
  'agent.email': {
    values: [],
  },
  'agent.url_slug': { values: [], loading: false },
  'agent.role': {
    values: [
      { name: 'User', value: 'user' },
      { name: 'Administrator', value: 'administrator' },
    ],
  },
  'agent.schedulable': {
    values: [
      { name: 'True', value: 'true' },
      { name: 'False', value: 'false' },
    ],
  },
  'agent.two_factor_enabled': {
    values: [
      { name: 'True', value: 'true' },
      { name: 'False', value: 'false' },
    ],
  },
};

interface Props {
  tqlFilters: Filter[];
  onUpdateTqlString: (tqlString: string) => void;
}

const UsersFilter: FC<Props> = ({ tqlFilters, onUpdateTqlString }) => {
  const persistedFilters = usePersistedFilters(tqlFilters, POSSIBLE_VALUES);

  const [filters, setFilters] = useStateWithCallback<Filter[]>(persistedFilters);

  return (
    <FilterControl
      attributes={ATTRIBUTES}
      filters={filters}
      possibleValues={POSSIBLE_VALUES}
      onSetFilter={setFilters}
      onUpdateTqlString={onUpdateTqlString}
    />
  );
};

export default UsersFilter;

import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import ConfigContext from '../ConfigContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import DragHandle from '../DragHandle';
import type { IndexedMjmlSpacer } from '../EmailTemplate';
import { selectDraggingElement, selectSelectedElementId } from '../Reducers/uiReducer';
import { selectTemplateIndex } from '../Reducers/templateReducer';

import { paddingStyle, selectElementHandler } from './utilities';

import { useSortable } from '@dnd-kit/sortable';

interface Props {
  node: IndexedMjmlSpacer;
  selected: boolean;
}

const MjmlSpacer: FC<Props> = ({ node, selected }) => {
  const { readOnly } = useContext(ConfigContext);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: {
      type: 'mjml-spacer',
    },
  });

  const [hovering, setHovering] = useState(false);

  const dispatch = useAppDispatch();

  const draggingElement = useAppSelector(selectDraggingElement);
  const index = useAppSelector(selectTemplateIndex);
  const { initialSelectedElementId, selectedElementId } = useAppSelector(selectSelectedElementId);

  useEffect(() => {
    if (!selected) {
      setHovering(false);
    }
  }, [selected]);

  const style = useMemo(() => {
    return {
      transition,
      zIndex: selected ? 999 : 'unset',
      opacity: isDragging ? 0.5 : 1,
      padding: paddingStyle(node.padding),
      height: `${node.height}`,
      backgroundColor: `${node.backgroundColor}`,
      width: '100%',
    };
  }, [node.padding, node.height, node.backgroundColor, selected, transition, isDragging]);

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  /* eslint-disable jsx-a11y/mouse-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  return (
    <div
      id={node.id}
      ref={setNodeRef}
      style={style}
      className={classNames({
        EmailEditor__Element: true,
        'EmailEditor__Element--highlighted': (hovering && !draggingElement) || selected,
      })}
      onClick={selectElementHandler(dispatch, node.id, index, selectedElementId, initialSelectedElementId)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="EmailEditor__Element_Identifier">
        Spacer
        <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
          <DragHandle selected={selected} />
        </span>
      </div>
    </div>
  );
};

export default MjmlSpacer;

import type { FC } from 'react';
import { forwardRef, useContext } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';
import RadioButtonRow from '@/ui/RadioButtonRow';

import { useUpdateTicketStatusForHaloPsa } from '../hooks';
import FormError from '../FormError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';
import type { HaloPsaUpdateTicketStatusAction } from '@graphql/generated';
import { useGetTicketStatusesForHaloPsaQuery } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update a HaloPSA service ticket to a selected status.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no ticket associated with this scheduling request.</li>
    </ul>
  </>
);

const UpdateTicketStatus = forwardRef<HTMLFormElement, WorkflowActionProps<HaloPsaUpdateTicketStatusAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const [ticketStatusId, setTicketStatusId] = useStateFromProp(action['haloPsaTicketStatusId']);

    const { errors: mutationErrors, loading, succeeded, submit } = useUpdateTicketStatusForHaloPsa();
    const queryResult = useGetTicketStatusesForHaloPsaQuery({ skip: templateMode });

    const ticketStatuses = queryResult.data?.haloPsa.ticketStatuses || [];
    const selectedTicketStatus = ticketStatuses.find(s => s.id === ticketStatusId) || ticketStatuses[0];

    const handleTicketStatusChange = (value: string) => {
      if (Number(value) !== ticketStatusId) {
        setTicketStatusId(Number(value));
      }
    };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        skipIfClosed: (data.get('skip_if_closed') as string) === 'true',
        psaStatusId: (selectedTicketStatus?.id.toString() || '') as string,
        psaStatusName: (selectedTicketStatus?.name || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="task-download"
            summary={<Summary name={action.psaStatusName} />}
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <SelectRowFromGraphQL
              label="Status"
              loadingMessage="Loading ticket statuses from HaloPSA..."
              field="haloPsa.ticketStatuses"
              emptyDataMessage="No ticket statuses found."
              helpText="TimeZest will update the service ticket to this status."
              name="psa_status_id"
              queryErrorMessage="Error loading ticket statuses from HaloPSA."
              error={errors.psa_status_id || errors.psa_status_name}
              value={ticketStatusId || ''}
              readOnly={readOnly}
              templateMode={templateMode}
              queryResult={queryResult}
              onChange={handleTicketStatusChange}
            />

            <input type="hidden" value={selectedTicketStatus?.name || ''} name="psa_status_name" />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="skip_if_closed"
              value={(action.skipIfClosed || false).toString()}
              label="Skip If Closed"
              helpText=""
              options={[
                {
                  label: 'Always update the status of the associated ticket',
                  value: 'false',
                  disabled: readOnly,
                },
                {
                  label: 'Skip updating the status of the associated ticket if it is closed',
                  value: 'true',
                  disabled: readOnly,
                },
              ]}
              error={errors.skip_if_closed}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

const Summary: FC<{ name: string | undefined }> = ({ name }) => (
  <>
    Update the ticket to{' '}
    {name ? (
      <>
        the <strong>{name}</strong>
      </>
    ) : (
      'a new'
    )}
    &nbsp;status in HaloPSA.
  </>
);

export default UpdateTicketStatus;

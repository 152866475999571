import type { FC } from 'react';
import { useState } from 'react';

import LocationAndStatusRow from '@/ui/LocationAndStatusRow';
import Expander from '@/ui/Expander';

import type { ShareableUrlAppointmentType } from '@models/AppointmentType';
import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import RadioButtonRow from '@ui/RadioButtonRow';

import ConnectWiseActivityOptions from '../../../connectWise/ActivityOptions';

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  canExpand: boolean;
  dirty: boolean;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
}

export function connectWiseHasErrors(errors: Errors): boolean {
  return !!(
    errors.newActivityPsaStatusId ||
    errors.newActivityPsaTypeId ||
    errors.newActivityPsaScheduleStatusId ||
    errors.newActivityPsaLocationId ||
    errors.newAppointmentPsaLocationId ||
    errors.newAppointmentPsaStatusId ||
    errors.shareableUrlAppointmentType
  );
}

const ConnectWiseConfig: FC<Props> = ({ appointmentType, canExpand, dirty, errors, expanded, onExpand }) => {
  const [shareableUrlAppointmentType, setShareableUrlAppointmentType] = useState<ShareableUrlAppointmentType>(
    appointmentType.shareableUrlAppointmentType
  );

  const summaryAppointment =
    appointmentType.shareableUrlAppointmentType === 'appointment' ? 'schedule entry' : 'activity';

  const helpText = (
    <>
      <div className="mb-1">This setting determines the type of appointment TimeZest will make in ConnectWise PSA.</div>
      {appointmentType.shareableUrlTicketMode === 'without_ticket' && (
        <>
          To select the schedule entry option, change the <strong>Shareable URLs</strong> option in the{' '}
          <strong>URL-Based Scheduling &amp; Shareable URLs</strong> section to create/require a service ticket, as
          ConnectWise PSA requires a service ticket for a schedule entry.
        </>
      )}
    </>
  );

  return (
    <Expander
      title="ConnectWise PSA Options"
      summary={`Create a new ${summaryAppointment} in ConnectWise PSA.`}
      url={`/settings/appointment_types/${appointmentType.id}/classic/connect_wise`}
      method="PATCH"
      icon="gear"
      hasErrors={connectWiseHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      <RadioButtonRow
        name="appointment_type[shareable_url_appointment_type]"
        value={appointmentType.shareableUrlAppointmentType}
        error={errors.shareableUrlAppointmentType}
        label="Created Appointment"
        helpText={helpText}
        options={[
          {
            label: (
              <>
                Create a <strong>schedule entry</strong> in the scheduled users&apos; ConnectWise PSA calendar
              </>
            ),
            value: 'appointment',
            disabled: appointmentType.shareableUrlTicketMode === 'without_ticket',
          },
          {
            label: (
              <>
                Create an <strong>activity</strong> in the scheduled users&apos; ConnectWise PSA calendar
              </>
            ),
            value: 'activity',
          },
        ]}
        onChange={(type: ShareableUrlAppointmentType) => setShareableUrlAppointmentType(type)}
      />

      {shareableUrlAppointmentType === 'activity' && (
        <>
          <ConnectWiseActivityOptions
            typeFieldName="appointment_type[new_activity_psa_type_id]"
            statusFieldName="appointment_type[new_activity_psa_status_id]"
            selectedStatusId={appointmentType?.newActivityPsaStatusId || 0}
            selectedTypeId={appointmentType?.newActivityPsaTypeId || 0}
            typeError={errors.newActivityPsaTypeId}
            statusError={errors.newActivityPsaStatusId}
          />
          <LocationAndStatusRow
            appointmentType="activity"
            locationFieldName="appointment_type[new_activity_psa_location_id]"
            statusFieldName="appointment_type[new_activity_psa_schedule_status_id]"
            selectedScheduleStatusId={appointmentType.newActivityPsaScheduleStatusId?.toString() || ''}
            selectedServiceLocationId={appointmentType.newActivityPsaLocationId?.toString() || ''}
          />
        </>
      )}
      {shareableUrlAppointmentType === 'appointment' && (
        <LocationAndStatusRow
          locationFieldName="appointment_type[new_appointment_psa_location_id]"
          statusFieldName="appointment_type[new_appointment_psa_status_id]"
          selectedScheduleStatusId={appointmentType.newAppointmentPsaStatusId?.toString() || ''}
          selectedServiceLocationId={appointmentType.newAppointmentPsaLocationId?.toString() || ''}
        />
      )}
    </Expander>
  );
};

export default ConnectWiseConfig;

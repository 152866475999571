import type { FC } from 'react';

import Flash from '@/Flash';
import Form from '@/Form';
import Header from '@/Header';
import asScreen from '@/Screen';
import Row from '@/ui/Row';
import TextInputRow from '@/ui/TextInputRow';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  domain: { errors: Record<string, string>; name: string };
}

const NewCustomDomain: FC<Props> = ({ domain }) => {
  return (
    <>
      <Header title="Add a Custom Domain" />
      <Flash />
      <Form url="/settings/domains" method="post">
        <TextInputRow
          error={domain.errors.domain}
          helpText={
            <>
              The domain (e.g. <code>example.com</code>) that you wish TimeZest to send emails from. You&apos;ll need to
              have access to its DNS records.
            </>
          }
          label="Domain"
          name="domain[domain]"
          value={domain.name}
        />
        <hr />

        <Row>
          <button name="button" type="submit" className="btn btn-primary">
            Add this Domain &nbsp;
            <FontAwesomeIcon icon="angle-right" />
          </button>
        </Row>
      </Form>
    </>
  );
};

export default asScreen(NewCustomDomain);

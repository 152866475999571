import { useState, type FC } from 'react';

import Row from '@/ui/Row';
import CopyToClipboard from '@/CopyToClipboard';
import TextInputRow from '@/ui/TextInputRow';
import PasswordInputRow from '@/ui/PasswordInputRow';
import Form from '@/Form';

import InputGroup from '@shared/ui/Inputs/InputGroup';

import type { IntegrationSettings } from '.';

interface Props {
  integration: IntegrationSettings;
  webhookToken: string;
}

const Settings: FC<Props> = ({ integration, webhookToken }) => {
  const [host, setHost] = useState(integration.host);

  return (
    <>
      <p>These are the credentials and settings that TimeZest will use to connect to your instance of ServiceNow.</p>

      <Form url="/settings/integrations/service_now" method="patch">
        <Row label="Pre-shared Key" width={6} labelFor="service_now_integration[preshared_key]">
          <InputGroup
            disabled={true}
            append={
              <span className="input-group-text">
                <CopyToClipboard text={integration.presharedKey} />
              </span>
            }
            name="service_now_integration[preshared_key]"
            value={integration.presharedKey}
          />
        </Row>

        <Row label="Account Token" width={6} labelFor="service_now_integration[account][webhook_token]">
          <InputGroup
            disabled={true}
            append={
              <span className="input-group-text">
                <CopyToClipboard text={webhookToken} />
              </span>
            }
            name="service_now_integration[account][webhook_token]"
            value={webhookToken}
          />
        </Row>

        <Row label="Hostname" width={6} labelFor="service_now_integration[host]">
          <InputGroup
            prepend="https://"
            name="service_now_integration[host]"
            value={host}
            error={integration.errors.host}
            onChange={e => setHost(e.target.value)}
          />
        </Row>

        <TextInputRow
          label="Username"
          name="service_now_integration[username]"
          width={6}
          value={integration.username}
          error={integration.errors.username}
        />

        <PasswordInputRow
          label="Password"
          name="service_now_integration[password]"
          width={6}
          value={integration.password}
          error={integration.errors.password}
        />

        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default Settings;

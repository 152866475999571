import type { FC } from 'react';
import { useContext, useState } from 'react';

import RadioButtonOption from '@/ui/RadioButtonRow/RadioButtonOption';

import VariablesContext from '@shared/VariablesContext';
import Modal from '@ui/Modal';
import Select from '@shared/ui/Select';

interface Props {
  isOpen: boolean;
  conditional: boolean;
  variable?: string;
  condition?: string;
  onClose: () => void;
  onSave: (value: { isConditional: boolean; variable?: string; condition?: string }) => void;
}

const ConditionModal: FC<Props> = ({ isOpen, onClose, onSave, ...props }) => {
  const { variables } = useContext(VariablesContext);

  const [conditional, setConditional] = useState(props.conditional);
  const [variable, setVariable] = useState(props.variable || variables[0]?.variable);
  const [condition, setCondition] = useState(props.condition || 'present');

  const onConditionalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditional(event.target.value === 'true');
  };

  const onVariableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setVariable(event.target.value);
  };

  const onConditionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCondition(event.target.value);
  };

  const onSaveClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onSave({ variable, condition, isConditional: conditional });
  };

  return (
    <Modal
      ariaLabel="Paragraph condition configuration"
      className="ui-confirmation-modal w-50"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h5 className="title mb-3">Conditional Paragraph</h5>
      <RadioButtonOption
        label="Always include this paragraph in the note."
        name="conditional"
        value="false"
        disabled={false}
        checked={!conditional}
        onChange={onConditionalChange}
      />
      <RadioButtonOption
        label="Only include this paragraph in the note when:"
        name="conditional"
        value="true"
        disabled={false}
        checked={conditional}
        appendedElement={
          <div className="d-flex align-items-center">
            <Select
              className="d-inline-block w-auto"
              disabled={!conditional}
              value={variable}
              name="variable"
              options={variables.map(v => ({ name: v.name, value: v.variable }))}
              onChange={onVariableChange}
            />
            &nbsp;
            <Select
              className="d-inline-block w-auto"
              disabled={!conditional}
              value={condition || 'present'}
              name="presence-status"
              options={[
                { name: 'is present', value: 'present' },
                { name: 'is not present', value: 'not present', key: 'notpresent' },
              ]}
              onChange={onConditionChange}
            />
          </div>
        }
        onChange={onConditionalChange}
      />
      <div className="mt-3">
        <button className="btn btn-primary" onClick={onSaveClick}>
          Save Changes
        </button>
        <button className="btn btn-link" onClick={onClose}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConditionModal;

import type { FC } from 'react';

import type { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention';

interface Props {
  mention: MentionData;
  theme?: MentionPluginTheme;
  isFocused: boolean;
  searchValue?: string;
  selectMention: (mention: MentionData | null) => void;
}

const Entry: FC<Props> = props => {
  const {
    mention,
    theme: _theme,
    searchValue: _searchValue,
    isFocused: _isFocused,
    selectMention: _selectMention,
    ...parentProps
  } = props;

  return <div {...parentProps}>{mention.name}</div>;
};

export default Entry;

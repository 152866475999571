import type { FC } from 'react';

import YesNoConfirmation from '@/YesNoConfirmation';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  authUrl: string;
  integrationAvailable: boolean;
}

const IntegrationStatus: FC<Props> = ({ authUrl, integrationAvailable }) => {
  if (!integrationAvailable) {
    return (
      <div className="d-flex">
        <div className="flex-grow-1 fw-bold pt-2">TimeZest has not yet been connected with your Zoom account.</div>
        <div>
          <a href={authUrl} className="btn btn-primary">
            <FontAwesomeIcon icon="sign-in-alt" /> &nbsp; Authenticate with Zoom
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <span className="text-success">
          <FontAwesomeIcon icon="check" /> &nbsp; Authenticate with Zoom &nbsp;{' '}
          <span className="fw-bold">TimeZest has been successfully connected to your Zoom account.</span>
        </span>
      </div>
      <YesNoConfirmation url="/settings/integrations/zoom" text="Remove this Integration" />
    </div>
  );
};

export default IntegrationStatus;

import type { FC, PropsWithChildren } from 'react';

import type { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention';

interface Props {
  entityKey: string;
  mention: MentionData;
  theme: MentionPluginTheme;
  className: string;
  decoratedText: string;
}

const Variable: FC<PropsWithChildren<Props>> = ({ ...props }) => {
  return <span className={props.className}>{props.children}</span>;
};

export default Variable;

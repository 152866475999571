import type { FC } from 'react';
import { useContext, useState } from 'react';

import RadioButtonRow from '@/ui/RadioButtonRow';
import TextInputRow from '@/ui/TextInputRow';
import Row from '@/ui/Row';
import AccountContext from '@/AccountContext';

import InputGroup from '@shared/ui/Inputs/InputGroup';
import type { PsaType } from '@models/Integration';

interface Props {
  id: string;
  internalName: string;
  externalName: string | null;
  slug: string;
  schedulingAlgorithm: string;
  errors: { [attr: string]: string };
  integration: PsaType;
}

const TeamDetailsConfig: FC<Props> = ({
  id,
  internalName,
  externalName,
  slug: initialSlug,
  schedulingAlgorithm: initialSchedulingAlgorithm,
  errors,
  integration,
}) => {
  const { host } = useContext(AccountContext);

  const [schedulingAlgorithm, setSchedulingAlgorithm] = useState(initialSchedulingAlgorithm);
  const [slug, setSlug] = useState(initialSlug);

  const handleSchedulingAlgorithmChange = (newSchedulingAlgorithm: string): void => {
    setSchedulingAlgorithm(newSchedulingAlgorithm);
  };

  return (
    <>
      <TextInputRow
        name="time_zest_team[internal_name]"
        value={internalName}
        label="Internal Name"
        helpText={`The name of the team used within TimeZest and ${integration}.`}
        error={errors.internalName}
      />
      <TextInputRow
        name="time_zest_team[external_name]"
        value={externalName}
        label="External Name"
        helpText="The name of this team we'll use when communicating with your clients. If left blank, TimeZest will use the internal name."
        error={errors.externalName}
      />

      <Row
        label="URL"
        helpText={
          <>
            For URL-triggered scheduling, this slug is what identifies this member in the URL - e.g.&nbsp;
            <code>level-1-helpdesk</code>.
          </>
        }
      >
        <InputGroup
          name="time_zest_team[slug]"
          value={slug || ''}
          error={errors.slug}
          prepend={`${host}/`}
          append="/<appointment type>"
          onChange={e => setSlug(e.target.value)}
        />
      </Row>

      <RadioButtonRow
        id={`team_${id}`}
        name="time_zest_team[scheduling_algorithm]"
        label="Scheduling Algorithm"
        value={schedulingAlgorithm}
        options={[
          {
            value: 'round_robin',
            label: 'Round-Robin',
            appendedElement:
              'TimeZest will allocate appointments to members of the team on a round-robin basis, so they each receive approximately the same number of appointments.',
          },
          {
            value: 'max_availability',
            label: 'Maximum Availability',
            appendedElement:
              'TimeZest will offer the maximum possible number of available appointments to clients, even if this results in certain members receiving a large number of appointments.',
          },
        ]}
        onChange={handleSchedulingAlgorithmChange}
      />
    </>
  );
};

export default TeamDetailsConfig;

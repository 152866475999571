import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';

import Row from '@ui/Row';
import TextInput from '@shared/ui/Inputs/TextInput';

interface Props {
  label: string;
  name: string;
  value: string | null;
  disabled?: boolean;
  password?: boolean;
  error?: string;
  helpText?: string | JSX.Element | null;
  warning?: string | JSX.Element;
  width?: number;
}

const TextInputRow: FC<Props> = ({
  disabled,
  error,
  warning,
  helpText,
  password,
  label,
  name,
  value: initialValue,
  width,
}) => {
  const { handleDirty } = useContext(DirtyContext);
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleDirty) {
      handleDirty();
    }

    setValue(event.target.value);
  };

  return (
    <Row label={label} helpText={helpText} width={width} labelFor={name}>
      <TextInput
        type={password ? 'password' : 'text'}
        data-lpignore="true"
        disabled={disabled}
        id={name}
        name={name}
        error={error}
        value={value || ''}
        onChange={handleChange}
      />
      {warning && <div className="small text-muted mt-1">{warning}</div>}
    </Row>
  );
};

export default TextInputRow;

import type { FC } from 'react';
import { useMemo } from 'react';

import Trigger from '@/ui/Workflow/Trigger/Name';

import type { Trigger as TriggerModel } from '@graphql/generated';
import { convert } from '@shared/text';

interface Props {
  trigger: TriggerModel;
  onChange: (timeMins: number) => void;
}

const TriggerForm: FC<Props> = ({ trigger: initialTrigger, onChange }) => {
  const trigger = useMemo(() => {
    return { ...initialTrigger, name: displayTriggerName(initialTrigger.name, initialTrigger.timeMins || 0) };
  }, [initialTrigger]);

  return (
    <>
      <p>Customize the trigger and action to be added to these appointment types:</p>
      <Trigger trigger={trigger} deletable={false} readOnly={false} onChange={onChange} />
    </>
  );
};

function displayTriggerName(name: string, timeMins: number) {
  const [duration, unit] = getTimeTuple(timeMins);
  return name.replace('%{duration}', duration).replace('%{unit}', unit);
}

function getTimeTuple(minutes: number) {
  if (minutes < 60) {
    return [minutes.toString(), minutes === 1 ? 'minute' : 'minutes'];
  } else if (minutes < 24 * 60) {
    const hours = convert(minutes, 60);
    return [hours.toString(), hours === 1 ? 'hour' : 'hours'];
  } else {
    const days = convert(minutes, 24 * 60);
    return [days.toString(), days === 1 ? 'day' : 'days'];
  }
}

export default TriggerForm;

import type { FC } from 'react';

interface Props {
  filtering: boolean;
}

const NoSchedulingRequests: FC<Props> = ({ filtering }) => {
  return (
    <div className="d-flex mb-4">
      <div className="w-100">
        <table className="table-transparent mb-0 table">
          <tbody>
            <tr>
              <td className="border-top-0 ps-0" colSpan={5}>
                {filtering ? <NoFilteredSchedulingRequests /> : <NoUnfilteredSchedulingRequests />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NoSchedulingRequests;

const NoUnfilteredSchedulingRequests: FC = () => {
  return (
    <>
      <span>There are currently no scheduling requests. </span>

      <span className="mb-0">
        To schedule a meeting, select the member and appointment type in the TimeZest pod, and click
        &quot;Schedule&quot;. TimeZest will take it from there.
      </span>
    </>
  );
};

const NoFilteredSchedulingRequests: FC = () => {
  return <span>No scheduling requests match this filter.</span>;
};

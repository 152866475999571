import type { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import './Row.scss';

interface Props {
  label?: string | JSX.Element;
  labelFor?: string;
  width?: number;
  helpText?: string | JSX.Element | null;
  withInput?: boolean;
  lastItem?: boolean;
}

const Row: FC<PropsWithChildren<Props>> = ({ children, helpText, label, labelFor, lastItem, withInput, width }) => {
  const showFormLabel = typeof withInput === 'undefined' ? true : withInput;
  const noBottomMargin = typeof lastItem === 'undefined' ? false : lastItem;
  const defaultWidth = helpText ? 6 : 10;
  const inputWidth = width ? width : defaultWidth;
  const helpWidth = 10 - inputWidth;

  return (
    <div className={classNames('row', { 'mb-3': !noBottomMargin })}>
      <div className="col-sm-2">
        <label htmlFor={labelFor} className={classNames('fw-bold', { 'col-form-label': showFormLabel })}>
          {label}
        </label>
      </div>
      <div className={`col-sm-${inputWidth}`}>{children}</div>
      {helpWidth > 0 && <div className={`col-sm-${helpWidth} Row__HelpText`}>{helpText}</div>}
    </div>
  );
};

export default Row;

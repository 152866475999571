import { createContext } from 'react';

export type FeatureFlagContextType = {
  useDispatchHq: boolean;
  useNewUi: boolean;
  useParallelRequests: boolean;
  useViralMarketing: boolean;
};

export const NULL_FEATURE_FLAG: FeatureFlagContextType = {
  useDispatchHq: false,
  useNewUi: false,
  useParallelRequests: false,
  useViralMarketing: false,
};

export default createContext<FeatureFlagContextType>(NULL_FEATURE_FLAG);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from '../../../config/locales/en/translations.json';
import frTranslation from '../../../config/locales/fr/translations.json';
import deTranslation from '../../../config/locales/de/translations.json';
import esTranslation from '../../../config/locales/es/translations.json';
import nlTranslation from '../../../config/locales/nl/translations.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  de: {
    translation: deTranslation,
  },
  es: {
    translation: esTranslation,
  },
  nl: {
    translation: nlTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    fallbackNS: 'translation',
    supportedLngs: ['de', 'en', 'es', 'fr', 'nl'],
    resources: resources,
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

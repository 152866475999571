import invariant from 'ts-invariant';

import type { IndexedMjmlNode } from '../EmailTemplate';

import type { TemplateState } from './templateReducer';

export function getElementFromIndex(state: TemplateState, id: string): IndexedMjmlNode {
  const element = state.index[id];

  invariant(element, `Element with ID '${id}' not found in index.`);

  return element;
}

export function insertChildId(childIds: string[], id: string, index: number) {
  return [...childIds.slice(0, index), id, ...childIds.slice(index)];
}

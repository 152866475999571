import type { FC } from 'react';

interface Props {
  url?: string | null;
  method?: 'POST' | 'PATCH';
  dirty: boolean;
  disabled?: boolean;
  confirming: boolean;
  setConfirming: React.Dispatch<React.SetStateAction<boolean>>;
  onExpand: (expanded: boolean) => void;
  onDirtyStatusUpdate?: (isDirty: boolean) => void;
}

const ExpanderCommands: FC<Props> = ({
  url,
  method,
  dirty,
  disabled,
  confirming,
  setConfirming,
  onExpand,
  onDirtyStatusUpdate,
}) => {
  const handleYesClick = (event: React.MouseEvent | React.KeyboardEvent): void => {
    event.preventDefault();
    setConfirming(false);
    onExpand(false);

    if (onDirtyStatusUpdate) onDirtyStatusUpdate(false);
  };

  const handleNoClick = (event: React.MouseEvent | React.KeyboardEvent): void => {
    event.preventDefault();
    setConfirming(false);
  };

  const handleCancelClick = (event: React.MouseEvent): void => {
    event.preventDefault();

    if (dirty) {
      setConfirming(true);
    } else {
      onExpand(false);
    }
  };

  return (
    <>
      <div className="mt-1">
        {confirming ? (
          <div className="text-sm">
            Discard these changes? &nbsp;
            <button className="btn btn-sm btn-link text-decoration-none px-0" onClick={handleYesClick}>
              Yes
            </button>
            &nbsp;&nbsp;
            <button className="btn btn-link btn-sm text-decoration-none ps-0" onClick={handleNoClick}>
              No
            </button>
          </div>
        ) : (
          <button className="btn btn-sm btn-link text-decoration-none" onClick={handleCancelClick}>
            Cancel
          </button>
        )}
      </div>
      <div className="pt-1">
        {url && method ? (
          <input
            name="expander_save_button"
            className="btn btn-sm btn-primary"
            type="submit"
            value="Save & Close"
            disabled={disabled}
          />
        ) : (
          <button type="button" className="btn btn-sm btn-primary" disabled={disabled} onClick={handleCancelClick}>
            Close
          </button>
        )}
      </div>
    </>
  );
};

export default ExpanderCommands;

import type { FC } from 'react';

import Flash from '@/Flash';
import Header from '@/Header';

const UpdatePrompt: FC = () => {
  return (
    <>
      <Header title="Custom Attributes" />
      <Flash />
      <div className="card">
        <div className="card-body">
          <h5 className="text-danger">Custom Attributes are only available to TimeZest Professional subscribers.</h5>
          <p>
            With custom attributes, you can define attributes, and give them a unique value for each user - their job
            title, direct phone number, or Zoom personal URL. Once defined, you can then use these values in your email
            templates.
          </p>
          <p className="mb-0">
            <a href="/settings/account" className="btn btn-danger">
              Upgrade Now &nbsp;
              <span className="fa fa-angle-right" />
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default UpdatePrompt;

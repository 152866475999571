import { generateId } from '../utilities';

import type { Index, TemplateState } from './templateReducer';

import type { EmailTemplate, MjmlNode } from '@models/EmailTemplate';

const addNodeToIndex = (index: Index, node: MjmlNode, parentId: string | null): string => {
  const thisId = generateId();

  const { children, ...attrs } = node;

  if (children) {
    const childIds = (children as MjmlNode[]).map(node => addNodeToIndex(index, node, thisId));
    index[thisId] = { parentId, childIds, id: thisId, ...attrs };
  } else {
    index[thisId] = { parentId, childIds: [], id: thisId, ...attrs };
  }

  return thisId;
};

const loadTemplate: (template: EmailTemplate) => TemplateState = template => {
  const editorId = `editor-${generateId()}`;

  let index = {};

  const rootId = addNodeToIndex(index, template, null);

  return {
    index,
    rootId,
    editorId,
  };
};

export default loadTemplate;

import type { FC } from 'react';

import Header from '@/Header';

import type { SchedulingRequest } from './Show';

interface Props {
  canCancelAppointment: boolean;
  schedulingRequest: SchedulingRequest;
}

const SchedulingRequestHeader: FC<Props> = ({ canCancelAppointment, schedulingRequest }) => {
  const { ticket, appointmentTypeName, issueDescription } = schedulingRequest;

  const headerTitle = ticket
    ? `${appointmentTypeName} for Ticket ${ticket.number} - ${ticket.summary}`
    : `${appointmentTypeName} - ${issueDescription}`;

  return (
    <div className="px-2">
      {canCancelAppointment ? (
        <Header
          title={headerTitle}
          headerButton={{
            url: `/scheduling_requests/${schedulingRequest.token}/cancel/new`,
            icon: 'times',
            color: 'yellow',
            text: schedulingRequest.scheduled ? 'Cancel Appointment' : 'Cancel Request',
          }}
        />
      ) : (
        <Header title={headerTitle} />
      )}
    </div>
  );
};

export default SchedulingRequestHeader;

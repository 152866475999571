import type { FC } from 'react';

import CheckoutExisting from '@/CheckoutExisting';
import Form from '@/Form';

interface Props {
  buttonName: string;
  cardRequired: boolean;
  chargebeeSite: string;
  newPlanId: string;
}

const Checkout: FC<Props> = ({ buttonName, cardRequired, chargebeeSite, newPlanId }) => {
  return (
    <div>
      {cardRequired ? (
        <CheckoutExisting
          site={chargebeeSite}
          buttonText={buttonName}
          url={`/chargebee/checkout_existing?chargebee_plan_id=${newPlanId}`}
          disabled={false}
        />
      ) : (
        <Form url="/settings/account/upgrade" method="post">
          <input type="hidden" name="chargebee_plan_id" id="chargebee_plan_id" value={newPlanId} />
          <input type="submit" className="btn btn-primary" data-disable-with={buttonName} value={buttonName} />
        </Form>
      )}
    </div>
  );
};

export default Checkout;

import type { FC } from 'react';

import classNames from 'classnames';

import FontAwesomeIcon from './FontAwesomeIcon';

type Icon = 'lightbulb' | 'triangle-exclamation' | 'circle-xmark' | 'circle-check' | 'circle-info';

type Color = 'green' | 'blue' | 'yellow' | 'red' | 'aqua';

interface Props {
  className?: string;
  color: Color;
  content?: string | JSX.Element;
  details?: 'notice' | 'alert' | 'warning';
  icon: Icon | null;
  small?: boolean;
  title?: string | JSX.Element;
}

const Alert: FC<Props> = ({ className = 'my-3', color, content, details, icon: iconName, small = false, title }) => {
  return (
    <div
      style={{ width: `${small && 'max-content'}` }}
      className={`callout callout-${color} ${className} dark:text-zinc-200`}
      role="alert"
      aria-details={details}
    >
      <div className="callout-body">
        {iconName && <FontAwesomeIcon className={`me-2 ${color}`} icon={iconName} size="lg" />}

        <div>
          {title && <h5 className={classNames({ 'mb-3': content })}>{title}</h5>}

          {content}
        </div>
      </div>
    </div>
  );
};

export default Alert;

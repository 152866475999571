import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import size from 'lodash/fp/size';

import { useAppDispatch, useAppSelector } from './hooks';
import {
  clearInsertionPoint,
  insertElement,
  moveElement,
  selectElement,
  setDraggingElement,
  setInsertionPoint,
} from './actions';
import ElementDragOverlay from './ElementDragOverlay';
import { accepts } from './EmailTemplate';

import { DndContext, DragOverlay, PointerSensor, pointerWithin, useSensor, useSensors } from '@dnd-kit/core';
import type { ClientRect, DragEndEvent, DragOverEvent } from '@dnd-kit/core';

interface HoveredOverElementProps {
  el: HTMLElement;
  rect: { width: number; height: number };
}

type VerticalInsertionPosition = 'above' | 'below';
type HorizontalInsertionPosition = 'left' | 'right';

const detectVerticalPosition = (
  element: HTMLElement,
  elementHeight: number,
  mouseCursorVerticalPosition: number
): VerticalInsertionPosition => {
  const offset = element.getBoundingClientRect().top;
  const mouseLocationOnElement = mouseCursorVerticalPosition - offset;

  if (mouseLocationOnElement > elementHeight / 2) {
    return 'below';
  } else {
    return 'above';
  }
};

const detectHorizontalPosition = (
  element: HTMLElement,
  elementWidth: number,
  mouseCursorHorizontalPosition: number
): HorizontalInsertionPosition => {
  const offset = element.getBoundingClientRect().left;
  const mouseLocationOnElement = mouseCursorHorizontalPosition - offset;

  if (mouseLocationOnElement > elementWidth / 2) {
    return 'right';
  } else {
    return 'left';
  }
};

const determineDirection = (nodeType: string) => {
  if (nodeType === 'mjml-section') {
    return 'horizontal';
  } else {
    return 'vertical';
  }
};

const detectInsertPosition = (
  hoveredOverElementInfo: HoveredOverElementProps,
  mousePosition: { top: number; left: number },
  parentElementType: string
): HorizontalInsertionPosition | VerticalInsertionPosition => {
  if (determineDirection(parentElementType) === 'horizontal') {
    return detectHorizontalPosition(hoveredOverElementInfo.el, hoveredOverElementInfo.rect.width, mousePosition.left);
  } else {
    return detectVerticalPosition(hoveredOverElementInfo.el, hoveredOverElementInfo.rect.height, mousePosition.top);
  }
};

const DragDropManager: FC<PropsWithChildren> = ({ children }) => {
  const index = useAppSelector(state => state.template.index);
  const insertionPoint = useAppSelector(state => state.ui.insertionPoint);
  const [mouseCursorPosition, setMouseCursorPosition] = useState({ top: 0, left: 0 });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const detectMousePosition = (event: MouseEvent) => {
      setMouseCursorPosition({ top: event.clientY, left: event.clientX });
    };

    document.addEventListener('mousemove', detectMousePosition);

    return (): void => {
      document.removeEventListener('mousemove', detectMousePosition);
    };
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    dispatch(setDraggingElement({ draggingElement: false }));

    if (!over) return;
    if (!insertionPoint) return;

    if ((active.id as string).match(/^new\-/)) {
      // Insert new element
      dispatch(
        insertElement({
          parentId: insertionPoint.parentId,
          type: active.data.current?.['type'],
          insertionIndex: insertionPoint.insertionIndex,
          defaultProperties: active.data.current?.['defaultProperties'] || {},
        })
      );
    } else {
      // Move existing element
      dispatch(
        moveElement({
          elementId: active.id as string,
          parentId: insertionPoint.parentId,
          insertionIndex: insertionPoint.insertionIndex,
        })
      );
    }

    dispatch(clearInsertionPoint());
  };

  const handleDragMove = (event: DragOverEvent) => {
    const { over, active } = event;

    const rect = over?.rect as ClientRect;

    if (!over?.data.current || !active.data.current) {
      dispatch(clearInsertionPoint());
      return;
    }

    if (accepts(over.data.current['type'], active.data.current['type'])) {
      dispatch(setInsertionPoint({ parentId: over.id as string, insertionIndex: size(index[over.id].childIds) }));
    } else {
      dispatch(clearInsertionPoint());

      const overNode = index[over.id];
      const parentNode = index[overNode.parentId || 'none'];

      // Check if the immediate parent accepts
      if (accepts(parentNode.type, active.data.current['type'])) {
        const el = document.getElementById(over.id as string) as HTMLElement | null;

        if (!el) return;

        const position = detectInsertPosition({ el, rect }, mouseCursorPosition, parentNode.type);

        const indexInParent = parentNode.childIds.indexOf(over.id as string);
        const insertionIndex = position === 'left' || position === 'above' ? indexInParent : indexInParent + 1;

        dispatch(setInsertionPoint({ parentId: parentNode.id as string, insertionIndex }));
      } else {
        let possibleParentNode = parentNode;
        let possibleChildNode;

        while (!accepts(possibleParentNode.type, active.data.current['type'])) {
          if (possibleParentNode.type === active.data.current['type']) {
            possibleChildNode = possibleParentNode;
          }

          if (!possibleParentNode.parentId) {
            return;
          }

          possibleParentNode = index[possibleParentNode.parentId as string];
        }

        const el = document.getElementById(possibleChildNode.id as string) as HTMLElement | null;

        if (!el) return;

        const { width, height } = el.getBoundingClientRect();

        const position = detectInsertPosition(
          { el, rect: { width, height } },
          mouseCursorPosition,
          possibleParentNode.type
        );

        const indexInParent = possibleParentNode.childIds.indexOf(possibleChildNode.id);
        const insertionIndex = position === 'left' || position === 'above' ? indexInParent : indexInParent + 1;

        dispatch(setInsertionPoint({ parentId: possibleParentNode.id as string, insertionIndex }));
      }
    }
  };

  const handleDragStart = ({ active }: DragOverEvent) => {
    if (active.id.toString().match(/^new\-/)) {
      dispatch(selectElement({ selectedElementId: active.id as string }));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      {children}
      <DragOverlay dropAnimation={null}>
        <ElementDragOverlay />
      </DragOverlay>
    </DndContext>
  );
};

export default DragDropManager;

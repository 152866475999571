import type { FC } from 'react';

import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { updateProperty } from '@/EmailEditor/actions';

import InputAndPicker from '../shared/InputAndPicker';

import type { FontFamily } from '@models/EmailTemplate';
import Select from '@shared/ui/Select';

const FONT_FAMILY = ['Sans-serif', 'Serif', 'Mono'];

const FONT_SIZE = [
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30',
  '32',
  '34',
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '72',
];

interface Props {
  nodeId: string;
  disabled: boolean;
  fontFamily?: FontFamily;
  fontSize?: string;
}

const FontControl: FC<Props> = ({ nodeId, fontFamily, fontSize, disabled }) => {
  const dispatch = useDispatch();

  return (
    <div className="mb-3">
      <div className="mb-2">Font</div>
      <div className="input-group position-relative">
        <Select
          ariaLabel="Font family"
          className={classNames('w-50', { 'text-muted': disabled })}
          value={fontFamily || 'Sans-serif'}
          disabled={disabled}
          name={`${nodeId}-email-editor-font-control`}
          options={FONT_FAMILY.map((font, index) => ({ name: font, value: font, key: index.toString() }))}
          onChange={e =>
            dispatch(updateProperty({ elementId: nodeId, property: 'font.family', value: e.target.value }))
          }
        />
        <InputAndPicker
          value={fontSize || '12px'}
          data={FONT_SIZE}
          unit="px"
          disabled={disabled}
          onChange={(newValue: string) => {
            dispatch(
              updateProperty({
                elementId: nodeId,
                property: 'font.size',
                value: newValue,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default FontControl;

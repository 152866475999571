import { type FC, useContext } from 'react';

import Header from '@/Header';
import asScreen from '@/Screen';
import Flash from '@/Flash';
import PlanContext from '@/PlanContext';

import EmailTemplate from './NewEmailEditor';
import OldEmailEditor from './OldEmailEditor';

import Alert from '@shared/Alert';
import type { EmailTemplateModel } from '@models/EmailTemplateModel';
import type Variable from '@models/Variable';
import type AppointmentType from '@models/AppointmentType';
import type { CustomAttribute } from '@models/CustomAttribute';
import { displayDuration } from '@shared/text';

const AVAILABLE_LANGUAGES = [
  ['de', 'Deutsch'],
  ['en', 'English'],
  ['es', 'Español'],
  ['fr', 'Français'],
  ['nl', 'Nederlands'],
] as [string, string][];

interface Props {
  appointmentTypes: AppointmentType[];
  availableVariables: Variable[];
  customAttributes: CustomAttribute[];
  emailTemplate: EmailTemplateModel;
  logoUrl: string | null;
}

const IndividualEmailTemplate: FC<Props> = ({
  appointmentTypes,
  availableVariables,
  customAttributes,
  emailTemplate,
  logoUrl,
}) => {
  const { allowsCustomEmails } = useContext(PlanContext);

  const appointmentTypeNames = appointmentTypes.map(at => `${at.internalName} (${displayDuration(at.durationMins)})`);

  return (
    <>
      <Header title={emailTemplate.name} subtitle="Email Template" />
      <Flash />

      {emailTemplate.readOnly && (
        <Alert
          className="mb-3 mt-0"
          color="blue"
          icon="circle-info"
          content="This email template is built-in to TimeZest, so cannot be edited or deleted."
        />
      )}

      {!emailTemplate.readOnly && emailTemplate.legacy && (
        <Alert
          className="mb-3 mt-0"
          color="yellow"
          icon="triangle-exclamation"
          content={
            <>
              This email template is on an unsupported version. Please follow this{' '}
              <a
                href="https://help.timezest.com/en/collections/4074172-starting-with-the-new-email-editor"
                rel="noopener"
                target="_blank'"
              >
                Help Center
              </a>{' '}
              doc to create a new email template and apply it to the appointment types that use this unsupported
              template.
            </>
          }
        />
      )}

      <div className="border-0">
        <div className="row mb-0">
          {emailTemplate.legacy ? (
            <OldEmailEditor
              appointmentTypes={appointmentTypeNames}
              customAttributes={customAttributes}
              errors={emailTemplate.errors}
              htmlTemplate={emailTemplate.templateHtml}
              name={emailTemplate.name}
              readOnly={emailTemplate.readOnly}
              subjectTemplate={emailTemplate.subjectTemplate}
              templateType={emailTemplate.templateType}
              url={`/settings/email_templates/${emailTemplate.id}`}
            />
          ) : (
            <div className="col-12">
              <EmailTemplate
                appointmentTypes={appointmentTypeNames}
                availableLanguages={AVAILABLE_LANGUAGES}
                availableVariables={availableVariables}
                emailTemplate={{ ...emailTemplate, readOnly: emailTemplate.readOnly || !allowsCustomEmails }}
                logoUrl={logoUrl}
                url={`/settings/email_templates/${emailTemplate.id}`}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default asScreen(IndividualEmailTemplate);

import type { FC } from 'react';

import type AppointmentTypeModel from '@models/AppointmentType';

interface Props {
  appointmentType: AppointmentTypeModel;
  allowsOnlineMeetings: boolean;
}

const Summary: FC<Props> = ({ appointmentType, allowsOnlineMeetings }) => {
  if (!allowsOnlineMeetings) {
    return <>Upgrade to TimeZest Professional for online meetings.</>;
  }

  const videoCallingModes = appointmentType.videoCallingModes.map(v => v.type);

  if (videoCallingModes.includes('zoom_video')) {
    return <>Schedule a Zoom online meeting for appointments.</>;
  } else if (videoCallingModes.includes('teams_video')) {
    return <>Schedule a Microsoft Teams online meeting for appointments.</>;
  } else {
    return <>No online meeting scheduled for these appointments.</>;
  }
};

export default Summary;

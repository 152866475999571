import type { FC } from 'react';
import { useContext, useState } from 'react';

import Expander from '@/ui/Expander';
import Row from '@/ui/Row';
import PlanContext from '@/PlanContext';
import AccountContext from '@/AccountContext';

import Summary from './Summary';

import type AppointmentTypeModel from '@models/AppointmentType';
import type { Unit } from '@ui/DurationRow';
import DurationRow from '@ui/DurationRow';
import type Errors from '@models/Errors';
import RadioButtonRow from '@ui/RadioButtonRow';
import TextInputRow from '@ui/TextInputRow';
import InputGroup from '@shared/ui/Inputs/InputGroup';
import { hasIntegration, hasIntegrationCapability, psaName } from '@shared/utilities';
import type { IntegrationModel } from '@models/Integration';

export function appointmentDetailsHasErrors(errors: Errors): boolean {
  return !!(errors.internalName || errors.externalName || errors.durationMins || errors.slug);
}

const DURATION_UNITS: Unit[] = ['minutes', 'hours'];

interface Props {
  url: string;
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  expanded: boolean;
  canExpand: boolean;
  onExpand: (expanded: boolean) => void;
  dirty: boolean;
}

const AppointmentDetailsConfig: FC<Props> = ({
  url,
  appointmentType,
  errors,
  expanded,
  canExpand,
  onExpand,
  dirty,
}) => {
  const { allowsShareableUrls, allowsContactUpdating } = useContext(PlanContext);
  const { psaType, host, integrations } = useContext(AccountContext);

  const { workflow } = appointmentType;
  const [askClientForPhoneNumber, setAskClientForPhoneNumber] = useState(appointmentType.askClientForPhoneNumber);
  const [appointmentSlug, setAppointmentSlug] = useState(appointmentType.slug);

  const canUpdatePhone = askClientForPhoneNumber;
  const contactUpdateable = allowsContactUpdating && canUpdatePhone && !workflow;

  const canUpdateContact = contactUpdateable && hasIntegrationCapability(integrations, 'canAccessUpdateContact');
  const canUpdateTicketContact =
    contactUpdateable && hasIntegrationCapability(integrations, 'canAccessUpdateTicketContact');
  const canUpdateTicketOwner = hasIntegrationCapability(integrations, 'canAccessUpdateTicketOwner') && !workflow;

  const handleAskClientForPhoneNumberChange = (newMode: string): void => {
    setAskClientForPhoneNumber(newMode === 'true');
  };

  return (
    <Expander
      title="Appointment Details"
      summary={<Summary appointmentType={appointmentType} />}
      url={url}
      method="PATCH"
      icon="events"
      hasErrors={appointmentDetailsHasErrors(errors)}
      expanded={expanded}
      canExpand={canExpand}
      dirty={dirty}
      onExpand={onExpand}
    >
      <TextInputRow
        name="appointment_type[internal_name]"
        value={appointmentType.internalName}
        label="Internal Name"
        helpText="This name is used to identify this appointment type within your company, such as in the TimeZest pod, and in ticket notes."
        error={errors.internalName}
      />
      <TextInputRow
        name="appointment_type[external_name]"
        value={appointmentType.externalName}
        label="External Name"
        helpText="This name is used with clients, during the scheduling process, and in emails sent to them. If it's blank, TimeZest will use the internal name."
        error={errors.externalName}
      />
      {allowsShareableUrls && (
        <TextInputRow
          name="appointment_type[description]"
          value={appointmentType.description}
          label="Description"
          helpText="If set, this will be displayed to end users on personal / team pages when they select the appointment type they wish."
          error={errors.description}
        />
      )}

      <Row
        label="URL"
        helpText={
          <>
            For URL-based scheduling and shareable URLs, this slug identifies the appointment type in URLs &mdash; e.g.{' '}
            <code>phone-call-30</code>.
          </>
        }
      >
        <InputGroup
          name="appointment_type[slug]"
          value={appointmentSlug || ''}
          prepend={`${host}/<resource>/`}
          error={errors.slug}
          onChange={e => setAppointmentSlug(e.target.value)}
        />
      </Row>
      <DurationRow
        name="appointment_type[duration_mins]"
        value={appointmentType.durationMins}
        label="Duration"
        helpText="The duration of the appointment to be scheduled."
        error={errors.durationMins}
        units={DURATION_UNITS}
      />

      {!appointmentType.workflow && (
        <>
          <hr className="my-4" />
          <RadioButtonRow
            name="appointment_type[ask_client_for_phone_number]"
            value={appointmentType.askClientForPhoneNumber.toString()}
            label="Best Contact Number"
            helpText="TimeZest can ask clients for the best contact number when they schedule, and include this
            in the internal note it creates on the ticket."
            options={[
              { label: 'Ask the client for their best contact number.', value: 'true' },
              { label: "Don't ask the client for their best contact number", value: 'false' },
            ]}
            onChange={handleAskClientForPhoneNumberChange}
          />
        </>
      )}
      {canUpdateTicketContact && (
        <>
          <hr className="my-4" />
          <RadioButtonRow
            name="appointment_type[update_ticket_contact]"
            value={appointmentType.updateTicketContact.toString()}
            label="Update Ticket Contact Number"
            helpText="This setting controls whether TimeZest will update the Phone Number field on the ticket, if the client provides a valid phone number."
            options={[
              { label: "Don't update the Phone Number field on the ticket.", value: 'false' },
              {
                label: 'Update the Phone Number field on the ticket when the client provides a valid phone number.',
                value: 'true',
              },
            ]}
          />
        </>
      )}
      {psaType && canUpdateContact && (
        <>
          <hr className="mb-4" />
          <RadioButtonRow
            name="appointment_type[update_contact]"
            value={appointmentType.updateContact.toString()}
            label="Update contact details"
            helpText={`This setting controls whether TimeZest updates the Phone Number on the client's contact record in
                       ${psaName(psaType)}, if the client provides a valid phone number.`}
            options={[
              { label: "Don't update the contact record.", value: 'false' },
              {
                label:
                  'Update the relevant phone number fields on the contact record, when the client provides a valid phone number.',
                value: 'true',
              },
            ]}
          />
        </>
      )}
      {psaType && canUpdateTicketOwner && (
        <>
          <hr className="mb-4" />
          <RadioButtonRow
            name="appointment_type[update_ticket_owner]"
            value={appointmentType.updateTicketOwner.toString()}
            label={`Update ${ticketOwnerLabel(integrations)}`}
            helpText={`This setting controls whether TimeZest updates the ${ticketOwnerLabel(integrations)}
                       in ${psaName(psaType)} with the scheduled user.`}
            options={[
              {
                label: `Don’t update the ${ticketOwnerLabel(integrations)}.`,
                value: 'false',
              },
              {
                label: `Update ${ticketOwnerLabel(integrations)} with scheduled user.`,
                value: 'true',
              },
            ]}
          />
        </>
      )}
    </Expander>
  );
};

export default AppointmentDetailsConfig;

function ticketOwnerLabel(integrations: IntegrationModel[]): string {
  return hasIntegration(integrations, 'autotask') ? 'primary resource' : 'ticket owner';
}

import { forwardRef, useContext } from 'react';

import ServiceCallOptions from '@/autotask/ServiceCallOptions';

import FormError from '../FormError';
import { useCreateServiceCallForAutotask } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import type { AutotaskCreateServiceCallAction } from '@graphql/generated';
import Row from '@ui/Row';
import type { Content } from '@shared/Editor/serialization';
import ErrorBoundary from '@shared/ErrorBoundary';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create an service call in Autotask <strong>only</strong> for those
      users who have Autotask configured as their calendar to write to.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When the scheduling request has been cancelled.</li>
      <li>When no Autotask integration is configured.</li>
    </ul>
    <p>
      This is a <strong>critical action</strong>, which means that if it doesn&apos;t complete successfully, no actions
      for this trigger after it will be executed.
    </p>
  </>
);

const CreateServiceCall = forwardRef<HTMLFormElement, WorkflowActionProps<AutotaskCreateServiceCallAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const [psaStatusName, setPsaStatusName] = useStateFromProp(action.psaStatusName);
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateServiceCallForAutotask();
    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        description: (data.get('description') || '') as string,
        psaStatusId: data.get('psa_status_id') as string,
        psaStatusName: psaStatusName || '',
      };
    });

    const handleStatusChange = (value: string | undefined, name: string | undefined): void => {
      setPsaStatusName(name || '');
    };

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="calendar-plus"
            summary={
              <>
                Create a <strong>service call</strong> for the scheduled user(s) in Autotask.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <Row label="Description" width={10}>
              <NoteEditor
                name="description"
                initialValue={(action.description as Content) || defaultTemplate}
                readOnly={readOnly}
              />
            </Row>

            <ServiceCallOptions
              selectedStatusId={action.psaStatusId || ''}
              fieldName="psa_status_id"
              readOnly={readOnly}
              error={errors.psa_status_id}
              templateMode={templateMode}
              onChange={handleStatusChange}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateServiceCall;

import { forwardRef, useContext } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import FormError from '../FormError';
import { useCreateMeetingForHubSpot } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';
import type { Content as NoteContent } from '@shared/NoteEditor';
import type { Content as LineContent } from '@shared/LineEditor';
import LineEditor, { defaultTemplate as defaultLineTemplate } from '@shared/LineEditor';
import NoteEditor from '@shared/NoteEditor';
import Row from '@ui/Row';
import { type HubSpotCreateMeetingAction, useGetMeetingTypesForHubSpotQuery } from '@graphql/generated';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DEFAULT_NOTE_TEMPLATE: NoteContent = [{ type: 'paragraph', children: [{ text: '' }] }];

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a meeting in HubSpot, and associate it with the scheduling
      request.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When no HubSpot integration is configured.</li>
    </ul>
  </>
);

const CreateMeeting = forwardRef<HTMLFormElement, WorkflowActionProps<HubSpotCreateMeetingAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);
    const [meetingTypeId, setMeetingTypeId] = useStateFromProp(action.psaMeetingTypeId);
    const [meetingTypeName, setMeetingTypeName] = useStateFromProp(action.psaMeetingTypeName);
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateMeetingForHubSpot();
    const queryResult = useGetMeetingTypesForHubSpotQuery();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        description: (data.get('description') || '') as string,
        psaMeetingTypeId: meetingTypeId,
        psaMeetingTypeName: meetingTypeName,
        title: (data.get('title') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    const handleMeetingTypeChange = (value: string) => {
      const name = queryResult.data?.hubSpot.meetingTypes.find(a => a.id.toString() === value)?.name || '';

      setMeetingTypeId(value);
      setMeetingTypeName(name);
    };

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="chat-plus"
            summary={
              <>
                Create a <strong>meeting</strong> in HubSpot.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <input type="hidden" name="psa_meeting_type_id" value={meetingTypeId || ''} />
            <input type="hidden" name="psa_meeting_type_name" value={meetingTypeName || ''} />

            <FormError action={action} errors={errors} />
            <Row label="Title" helpText="TimeZest will create a new meeting in HubSpot with this title.">
              <LineEditor
                name="title"
                initialValue={(action.title as LineContent) || defaultLineTemplate}
                readOnly={readOnly}
              />
            </Row>

            <SelectRowFromGraphQL
              loadingMessage="Loading from HubSpot..."
              name="psa_appointment_type_id"
              label="Meeting Type"
              emptyDataMessage="No meeting types found."
              field="hubSpot.meetingTypes"
              helpText="TimeZest will create a new meeting in HubSpot with this meeting type."
              queryErrorMessage="Error loading meeting types from HubSpot."
              prompt="(none)"
              readOnly={readOnly}
              templateMode={templateMode}
              error={errors.psa_meeting_type_id || errors.psa_meeting_type_name}
              value={meetingTypeId || ''}
              queryResult={queryResult}
              onChange={handleMeetingTypeChange}
            />

            <Row label="Description" width={10}>
              <NoteEditor
                name="description"
                initialValue={(action.description as NoteContent) || DEFAULT_NOTE_TEMPLATE}
                readOnly={readOnly}
              />
            </Row>
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateMeeting;

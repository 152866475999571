import type { FC } from 'react';
import { useContext, useMemo } from 'react';

import type { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';

import Paragraph from './Paragraph';

import Editor from '@shared/Editor';
import { deserialize as deserializeContent, serialize as serializeContent } from '@shared/Editor/serialization';
import VariablesContext from '@shared/VariablesContext';
import type { Content } from '@shared/Editor/serialization';

interface Props {
  name: string;
  initialValue: Content;
  readOnly: boolean;
}

const NoteEditor: FC<Props> = ({ name, initialValue, readOnly }) => {
  const { variables } = useContext(VariablesContext);
  const value = useMemo(() => {
    return EditorState.createWithContent(deserializeContent(initialValue, variables));
  }, [initialValue, variables]);

  const serialize = (contentState: ContentState): string => {
    return JSON.stringify(serializeContent(contentState));
  };

  return <Editor name={name} initialValue={value} paragraph={Paragraph} readOnly={readOnly} serialize={serialize} />;
};

export const defaultTemplate: Content = [
  {
    type: 'paragraph',
    children: [
      { text: 'TimeZest will use this template for the note it creates. You can insert variables like ' },
      { type: 'variable', variable: 'client.name' },
      { text: ' or ' },
      { type: 'variable', variable: 'client.phone_number' },
      { text: ' by pressing Shift + { , and TimeZest will replace them with actual values when it creates the note.' },
    ],
  },
  {
    type: 'paragraph',
    condition: 'online_meeting.start_url',
    children: [
      {
        text: "It's also possible to include paragraphs conditionally - for example, this paragraph will only be included when the ",
      },
      { type: 'variable', variable: 'online_meeting.start_url' },
      { text: ' has been given a value. You can modify the condition by clicking on the grey area at right.' },
    ],
  },
];

export { Content };

export default NoteEditor;

import { forwardRef } from 'react';

import FormError from '../FormError';
import { useCreateContactForHubSpot } from '../hooks';

import Action from '@shared/Action';
import RadioButtonRow from '@ui/RadioButtonRow';
import type { HubSpotCreateContactAction, CreateContactMode } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import ErrorBoundary from '@shared/ErrorBoundary';
import useFormSubmit from '@shared/hooks/useFormSubmit';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">When TimeZest executes this action, it will find or create a new contact in HubSpot.</p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When there is already an existing contact with the client&apos;s email address in HubSpot.</li>
      <li>When no HubSpot integration is configured.</li>
    </ul>
  </>
);

const CreateContact = forwardRef<HTMLFormElement, WorkflowActionProps<HubSpotCreateContactAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateContactForHubSpot();
    const [mode, setMode] = useStateFromProp(action.mode);

    const handleModeChange = (newMode: CreateContactMode): void => {
      setMode(newMode);
    };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return { mode: data.get('mode') as CreateContactMode };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="user-b"
            summary={
              <>
                Find or create a <strong>contact</strong> in HubSpot.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="mode"
              label="New Contact Handling"
              value={mode || 'create_company_if_not_found'}
              options={[
                {
                  value: 'create_company_if_not_found',
                  label:
                    'Attempt to match the contact to a company with the same email domain in HubSpot or create a new company if not found',
                },
                {
                  value: 'leave_company_blank',
                  label: 'Leave the company associated with the new contact blank',
                },
              ]}
              helpText="This setting determines what TimeZest will do if it can't match an existing contact."
              error={errors.mode}
              onChange={handleModeChange}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateContact;

import type { FC } from 'react';
import { useContext, useState } from 'react';

import styled from 'styled-components';

import ConfigContext from '../ConfigContext';
import { updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import { extractNumber } from '../utilities';
import type { IndexedMjmlSpacer } from '../EmailTemplate';

import ColorInput from './Inputs/Color';
import Input from './Inputs/Input';
import SpaceInput from './Inputs/Space';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';

const SpacerPropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

interface Props {
  node: IndexedMjmlSpacer;
}

const SpacerProperties: FC<Props> = ({ node }) => {
  const { readOnly } = useContext(ConfigContext);
  const [error, setError] = useState<string | undefined>();

  const dispatch = useAppDispatch();

  const handleOnChange = (property: string) => (newValue: any) => {
    if (property === 'height') {
      const numericalValue = Number(extractNumber(newValue, 'px'));

      setError(numericalValue < 1 ? "Spacer's height must be equal or greater than 1" : undefined);

      if (numericalValue >= 1) dispatch(updateProperty({ elementId: node.id, property, value: newValue }));

      return;
    }

    dispatch(updateProperty({ elementId: node.id, property, value: newValue }));
  };

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this Spacer. Are you sure?"
    >
      <SpacerPropertiesContainer>
        <PropertiesCollapsible title="SPACER PROPERTIES">
          <ColorInput
            name="backgroundColor"
            value={node.backgroundColor || ''}
            id={`${node.id}-bg-color`}
            label="Background Color"
            disabled={readOnly}
            onInput={handleOnChange('backgroundColor')}
          />
          <Input
            value={node.height || '1'}
            type="number"
            min={1}
            step={1}
            id={`${node.id}-height`}
            label="Height"
            unit="px"
            error={error}
            disabled={readOnly}
            onChange={handleOnChange('height')}
            onInput={handleOnChange('height')}
          />
          <SpaceInput
            id={`${node.id}-padding`}
            label="Padding"
            value={node.padding || {}}
            disabled={readOnly}
            onInput={handleOnChange('padding')}
          />
        </PropertiesCollapsible>
      </SpacerPropertiesContainer>
    </PropertiesContainer>
  );
};

export default SpacerProperties;

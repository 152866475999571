import type { FC } from 'react';

import ReactPaginate from 'react-paginate';

interface Props {
  itemsCount: number;
  itemsPerPage: number;
}

const Pagination: FC<Props> = ({ itemsCount, itemsPerPage }) => {
  const pageCount = Math.ceil(itemsCount / itemsPerPage);

  const params = new URLSearchParams(window.location.search);
  const currentPage = Number(params.get('page') || 1);

  return (
    <>
      {pageCount > 1 ? (
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={currentPage}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center mb-0 mt-3"
          disableInitialCallback={true}
          forcePage={currentPage - 1}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={({ selected }) =>
            (window as any).Turbolinks.visit(window.location.pathname + `?page=${selected + 1}`)
          }
        />
      ) : null}
    </>
  );
};

export default Pagination;

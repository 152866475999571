import { forwardRef } from 'react';

import FormError from '../FormError';
import { useCreateNoteOnTicketForAutotask } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { AutotaskCreateNoteOnTicketAction } from '@graphql/generated';
import type { Content } from '@shared/NoteEditor';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import Row from '@ui/Row';
import TextInputRow from '@ui/TextInputRow';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const defaultTitle = 'Note Title';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a note on the Autotask ticket associated with the scheduling
      request, interpolating actual values for variables into the note body.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no Autotask ticket associated with the scheduling request.</li>
      <li>When no Autotask integration is configured.</li>
    </ul>
  </>
);

const CreateNoteOnTicket = forwardRef<HTMLFormElement, WorkflowActionProps<AutotaskCreateNoteOnTicketAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateNoteOnTicketForAutotask();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        template: (data.get('template') || '') as string,
        titleTemplate: (data.get('title_template') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="chat-plus"
            summary={
              <>
                Add a <strong>note</strong> to a Autotask Ticket.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <TextInputRow
              label="Title"
              name="title_template"
              value={action.titleTemplate || defaultTitle}
              disabled={readOnly}
              error={errors.title_template}
            />

            <Row label="Note Content" width={10}>
              <NoteEditor
                name="template"
                initialValue={(action.template as Content) || defaultTemplate}
                readOnly={readOnly}
              />
            </Row>
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateNoteOnTicket;

import type { FC } from 'react';

import Row from '@/ui/Row';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import Error from './Error';

import { useGetOptionsForAutotaskQuery } from '@graphql/generated';

const TYPE_HELP_TEXT = 'TimeZest will create the new service ticket with this ticket type.';
const QUEUE_HELP_TEXT = 'TimeZest will create the new service ticket in this queue.';
const CATEGORY_HELP_TEXT = 'TimeZest will create the new service ticket with this category.';
const PRIORITY_HELP_TEXT = 'TimeZest will create the new service ticket with this priority.';
const STATUS_HELP_TEXT = 'TimeZest will create the new service ticket with this status.';
const SOURCE_HELP_TEXT = 'TimeZest will use this as the source value for the new service ticket.';

function name(modelName: string, attrName: string): string {
  return `${modelName}[${attrName}]`;
}

interface Props {
  modelName: string;
  categoryId: number | null;
  categoryIdAttr: string;
  categoryIdError?: string;
  queueId: number | null;
  queueIdAttr: string;
  queueIdError?: string;
  priorityId: number | null;
  priorityIdAttr: string;
  priorityIdError?: string;
  sourceId: number | null;
  sourceIdAttr: string;
  sourceIdError?: string;
  statusId: number | null;
  statusIdAttr: string;
  statusIdError?: string;
  typeId: number | null;
  typeIdAttr: string;
  typeIdError?: string;
  disabled?: boolean;
  readOnly: boolean;
  templateMode?: boolean;
}

const ServiceTicketOptions: FC<Props> = (props: Props) => {
  const queryResult = useGetOptionsForAutotaskQuery({ skip: props.templateMode });
  const { error } = queryResult;

  const errorType = error?.graphQLErrors[0].extensions?.type;

  if (errorType) {
    return (
      <Row label="Service Ticket Options" width={6}>
        <Error errorType={errorType as string} />
      </Row>
    );
  }

  return (
    <>
      <SelectRowFromGraphQL
        label="Queue"
        loadingMessage="Loading from Autotask..."
        field="autotask.queues"
        emptyDataMessage="No queues found."
        name={name(props.modelName, props.queueIdAttr)}
        error={props.queueIdError}
        value={(props.queueId || '').toString()}
        readOnly={props.readOnly}
        disabled={props.disabled}
        templateMode={props.templateMode}
        helpText={QUEUE_HELP_TEXT}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        label="Source"
        loadingMessage="Loading from Autotask..."
        field="autotask.sources"
        emptyDataMessage="No sources found."
        name={name(props.modelName, props.sourceIdAttr)}
        error={props.sourceIdError}
        value={(props.sourceId || '').toString()}
        readOnly={props.readOnly}
        disabled={props.disabled}
        templateMode={props.templateMode}
        helpText={SOURCE_HELP_TEXT}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        label="Priority"
        loadingMessage="Loading from Autotask..."
        field="autotask.priorities"
        emptyDataMessage="No priorities found."
        name={name(props.modelName, props.priorityIdAttr)}
        error={props.priorityIdError}
        value={(props.priorityId || '').toString()}
        readOnly={props.readOnly}
        disabled={props.disabled}
        templateMode={props.templateMode}
        helpText={PRIORITY_HELP_TEXT}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        label="Status"
        loadingMessage="Loading from Autotask..."
        field="autotask.ticketStatuses"
        emptyDataMessage="No ticket statuses found."
        name={name(props.modelName, props.statusIdAttr)}
        error={props.priorityIdError}
        value={(props.statusId || '').toString()}
        readOnly={props.readOnly}
        disabled={props.disabled}
        templateMode={props.templateMode}
        helpText={STATUS_HELP_TEXT}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        label="Category"
        loadingMessage="Loading from Autotask..."
        field="autotask.categories"
        name={name(props.modelName, props.categoryIdAttr)}
        error={props.categoryIdError}
        value={(props.categoryId || '').toString()}
        prompt="Use default category configured in Autotask."
        readOnly={props.readOnly}
        disabled={props.disabled}
        templateMode={props.templateMode}
        helpText={CATEGORY_HELP_TEXT}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        label="Ticket Type"
        loadingMessage="Loading from Autotask..."
        field="autotask.ticketTypes"
        name={name(props.modelName, props.typeIdAttr)}
        error={props.typeIdError}
        value={(props.typeId || '').toString()}
        readOnly={props.readOnly}
        disabled={props.disabled}
        templateMode={props.templateMode}
        helpText={TYPE_HELP_TEXT}
        queryResult={queryResult}
      />
    </>
  );
};

export default ServiceTicketOptions;

import { useEffect } from 'react';

import copy from 'copy-to-clipboard';

interface Props {
  text: string;
}

export default function CopyToClipboardInBackground(props: Props): null {
  useEffect(() => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(props.text).catch(() => copy(props.text));
    } else {
      copy(props.text);
    }
  }, [props.text]);

  return null;
}

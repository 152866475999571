import invariant from 'ts-invariant';

import type { TemplateState } from './templateReducer';
import { getElementFromIndex } from './utilities';

const moveElement: (
  state: TemplateState,
  elementId: string,
  parentId: string,
  insertionIndex: number
) => TemplateState = (state, elementId, parentId, insertionIndex) => {
  const element = getElementFromIndex(state, elementId);

  invariant(element.parentId, `Attempting to move element '${elementId}' without parentId`);

  if (element.parentId === parentId) {
    // Move within same parent.
    const parent = getElementFromIndex(state, element.parentId);
    const beforeSiblingIds = parent.childIds.slice(0, insertionIndex).filter(id => id !== elementId);
    const afterSiblingIds = parent.childIds.slice(insertionIndex).filter(id => id !== elementId);

    const newChildIds = [...beforeSiblingIds, elementId, ...afterSiblingIds];

    return {
      ...state,
      index: {
        ...state.index,
        [parent.id]: { ...parent, childIds: newChildIds },
      },
    };
  } else {
    // Move between parents
    const oldParent = getElementFromIndex(state, element.parentId);
    const newParent = getElementFromIndex(state, parentId);

    const newChildIds = [
      ...newParent.childIds.slice(0, insertionIndex),
      elementId,
      ...newParent.childIds.slice(insertionIndex),
    ];

    return {
      ...state,
      index: {
        ...state.index,
        [elementId]: { ...element, parentId: parentId },
        [oldParent.id]: { ...oldParent, childIds: oldParent.childIds.filter(id => id !== elementId) },
        [newParent.id]: { ...newParent, childIds: newChildIds },
      },
    };
  }
};

export default moveElement;

import type { FC } from 'react';

import Flash from '@/Flash';
import Header from '@/Header';
import asScreen from '@/Screen';
import Pagination from '@/Pagination';

import TeamsList from './TeamsList';

import type { Team } from '@models/Team';

interface Props {
  teams: Team[];
  teamsCount: number;
  teamsPerPage: number;
}

const Teams: FC<Props> = ({ teams, teamsCount, teamsPerPage }) => {
  return (
    <>
      <Header
        title="Teams"
        headerButton={{ url: '/settings/teams/new', color: 'blue', icon: 'plus', text: 'New Team' }}
      />
      <Flash />
      {teams.length === 0 ? (
        <p>No teams have been created yet.</p>
      ) : (
        <>
          <TeamsList teams={teams} />
          <Pagination itemsCount={teamsCount} itemsPerPage={teamsPerPage} />
        </>
      )}
    </>
  );
};

export default asScreen(Teams);

import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import type { TimeElapsedTriggerModel } from '@/models';

import Actions, { isCreateable } from '../Actions';

import Name from './Name';
import './trigger.css';

import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { Action, DefaultAction, Trigger } from '@graphql/generated';
import { GetActionPositionDocument, useCreateActionMutation } from '@graphql/generated';
import { client, refetchOnSuccess } from '@shared/apollo';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import Select from '@shared/ui/Select';

interface Props {
  trigger: Trigger | TimeElapsedTriggerModel;
  availableActions: { name: string; slug: string }[];
  readOnly: boolean;
  succeeded: boolean;
}

const TriggerWithActions: FC<Props> = ({ trigger, availableActions, readOnly, succeeded }) => {
  const defaultAction = availableActions.length && !readOnly ? availableActions[0].slug : '';
  const [actions, setActions] = useStateFromProp(trigger.actions);
  const [error, setError] = useState<string | null>(null);
  const [newActionType, setNewActionType] = useState(defaultAction);
  const [editingTrigger, setEditingTrigger] = useState(false);
  const [createAction, { error: actionError, loading }] = useCreateActionMutation({
    refetchQueries: refetchOnSuccess('createAction', 'getWorkflowTriggers'),
  });

  const sortedAvailableActions = availableActions.sort((a, b) => a.name.localeCompare(b.name));

  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!succeeded || !triggerRef.current) return;

    triggerRef.current.scrollIntoView({ behavior: 'smooth' });
    triggerRef.current.style.scrollMargin = '50px';
  }, [succeeded, triggerRef]);

  const handleNewAction = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isCreateable(newActionType)) {
      return createAction({ variables: { triggerId: trigger.id, type: newActionType } }).then(({ data }) => {
        if (!data?.createAction?.action) {
          const action = sortedAvailableActions.find(action => action.slug === newActionType);
          setError(`TimeZest could not add '${action?.name}' action. Please reload the page and try again.`);
        }
      });
    }

    const position = await getActionPosition(trigger.id, newActionType);

    if (position !== null) {
      const action: Action = { id: 0, triggerId: trigger.id, slug: newActionType } as DefaultAction;
      setActions(insertAction(actions, position, action));
    } else {
      const action = sortedAvailableActions.find(action => action.slug === newActionType);
      setError(`TimeZest could not add '${action?.name}' action. Please reload the page and try again.`);
    }
  };

  const handleSelectAction = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    setError(null);
    setNewActionType(e.target.value);
  };

  const handleTriggerEditToggle = (editing: boolean) => {
    setEditingTrigger(editing);
  };

  const disableAddNewActionButton = actions?.some(a => a.id === 0);

  return (
    <div ref={triggerRef} className={classNames({ flash: succeeded })}>
      <Name trigger={trigger} readOnly={readOnly} onEditToggle={handleTriggerEditToggle} />
      <Actions actions={actions} readOnly={readOnly || editingTrigger} />
      {!readOnly && sortedAvailableActions.length > 0 && (
        <>
          <div className="ActionSpacer__Main">
            <FontAwesomeIcon icon="angle-down" />
          </div>
          <div className="NewAction__Container">
            <i className="budicon budicon-plus-sign NewAction__Icon" />

            <Select
              className="w-auto"
              disabled={readOnly}
              name="selected-action"
              value={newActionType}
              options={sortedAvailableActions.map((trigger, index) => ({
                name: trigger.name,
                value: trigger.slug,
                key: trigger.slug + index,
              }))}
              onChange={handleSelectAction}
            />

            {loading ? (
              <button disabled className="btn btn-primary ms-2">
                Adding...
              </button>
            ) : (
              <button
                className="btn btn-primary ms-2"
                disabled={readOnly || disableAddNewActionButton}
                onClick={handleNewAction}
              >
                Add New Action
              </button>
            )}
          </div>
        </>
      )}

      {(actionError || error) && (
        <div className="text-danger d-flex mt-3 ps-3">
          <div className="me-2">
            <FontAwesomeIcon icon="times" />
          </div>

          <p className="mb-0">{actionError?.message || error}</p>
        </div>
      )}
    </div>
  );
};

function insertAction(actions: Action[], index: number, action: Action): Action[] {
  const updatedActions = actions.slice();
  updatedActions.splice(index, 0, action);
  return updatedActions;
}

function getActionPosition(triggerId: number, type: string): Promise<number | null> {
  return client
    .query({
      fetchPolicy: 'no-cache',
      query: GetActionPositionDocument,
      variables: { triggerId, type },
    })
    .then(({ data }) => data.timezest.actionPosition)
    .catch(() => null);
}

export default TriggerWithActions;

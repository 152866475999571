import type { FC } from 'react';

import Expander from '@/ui/Expander';

import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import SelectRow from '@ui/SelectRow';
import { EVENT_TYPES } from '@shared/constants';

export function office365HasErrors(errors: Errors): boolean {
  return !!errors.o365_event_visibility;
}

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  canExpand: boolean;
  dirty: boolean;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
}

const Office365Config: FC<Props> = ({ appointmentType, canExpand, dirty, errors, expanded, onExpand }) => {
  return (
    <Expander
      title="Microsoft 365 Options"
      summary="Create a new event in Microsoft 365."
      url={`/settings/appointment_types/${appointmentType.id}/classic/office365`}
      method="PATCH"
      icon="gear"
      hasErrors={office365HasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      <SelectRow
        name="appointment_type[o365_event_visibility]"
        value={appointmentType.o365EventVisibility}
        label="Show Time As"
        helpText="The setting determines the show as option for Microsoft 365 Calendar event."
        options={EVENT_TYPES}
      />
    </Expander>
  );
};

export default Office365Config;

import type { FC } from 'react';

interface Props {
  userId: number;
}

const ConfigLink: FC<Props> = ({ userId }) => {
  if (!userId) return null;

  return (
    <a href={`/settings/users/${userId}`} className="text-decoration-none">
      Configure
    </a>
  );
};

export default ConfigLink;

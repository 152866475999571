import { forwardRef, useContext } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';
import SelectRow from '@/ui/SelectRow';

import { useCreateAppointmentForHaloPsa } from '../hooks';
import FormError from '../FormError';
import { HALO_PSA_REMINDER_TIMES } from '../constants';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import Action from '@shared/Action';
import type { Content as LineContent } from '@shared/LineEditor';
import LineEditor, { defaultTemplate as defaultLineTemplate } from '@shared/LineEditor';
import type { Content } from '@shared/NoteEditor';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import Row from '@ui/Row';
import type { HaloPsaCreateAppointmentAction } from '@graphql/generated';
import { useGetAppointmentTypesForHaloPsaQuery } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create an appointment in HaloPSA for each resource in TimeZest who
      have HaloPSA set as their writeable calendar.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following case:</p>
    <ul className="mb-3">
      <li>When none of the scheduled users is configured to have HaloPSA as their calendar.</li>
    </ul>
  </>
);

const CreateAppointment = forwardRef<HTMLFormElement, WorkflowActionProps<HaloPsaCreateAppointmentAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const [appointmentTypeId, setAppointmentTypeId] = useStateFromProp(action.psaAppointmentTypeId, id =>
      id?.toString()
    );

    const reminderTimes = HALO_PSA_REMINDER_TIMES.map(r => ({ ...r, disabled: readOnly }));

    const { errors: mutationErrors, loading, succeeded, submit } = useCreateAppointmentForHaloPsa();
    const queryResult = useGetAppointmentTypesForHaloPsaQuery({ skip: templateMode });

    const appointmentTypes = queryResult.data?.haloPsa.appointmentTypes || [];
    const selectedAppointmentType =
      appointmentTypes.find(a => a.id.toString() === appointmentTypeId) || appointmentTypes[0];

    const handleAppointmentTypeChange = (value: string) => {
      if (value !== appointmentTypeId) {
        setAppointmentTypeId(value);
      }
    };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        psaAppointmentTypeId: Number(selectedAppointmentType?.id || ''),
        psaAppointmentTypeName: (selectedAppointmentType?.name || '') as string,
        reminderMinutes: Number(data.get('reminder_minutes') || '0'),
        subject: (data.get('subject') || '') as string,
        note: (data.get('note') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="calendar-plus"
            summary={
              <>
                Create an <strong>appointment</strong> in HaloPSA
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <SelectRowFromGraphQL
              loadingMessage="Loading from HaloPSA..."
              name="psa_appointment_type_id"
              label="Appointment Type"
              emptyDataMessage="No appointment types found."
              field="haloPsa.appointmentTypes"
              helpText="TimeZest will create a new appointment in HaloPSA with this appointment type."
              queryErrorMessage="Error loading appointment types from HaloPSA."
              readOnly={readOnly}
              templateMode={templateMode}
              error={errors.psa_appointment_type_id || errors.psa_appointment_type_name}
              value={appointmentTypeId}
              queryResult={queryResult}
              onChange={handleAppointmentTypeChange}
            />

            <input type="hidden" value={selectedAppointmentType?.name || ''} name="psa_appointment_type_name" />

            <Row label="Subject">
              <LineEditor
                name="subject"
                initialValue={(action.subject || defaultLineTemplate) as LineContent}
                error={errors.subject}
                readOnly={readOnly}
              />
            </Row>

            <Row label="Notes" width={10}>
              <NoteEditor name="note" initialValue={(action.note as Content) || defaultTemplate} readOnly={readOnly} />
            </Row>

            <SelectRow
              helpText="A reminder prior to the appointment by this amount of time will be sent to the clients."
              label="Alert"
              name="reminder_minutes"
              options={reminderTimes}
              value={(action.reminderMinutes || '0').toString()}
              error={errors.reminder_mins}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateAppointment;

import { createCable } from '@anycable/web';

export default createCable({
  tokenRefresher: async transport => {
    let response = await fetch('/websockets/cable_url.json');
    let data = await response.json();

    transport.setURL(data['cable_url_jwt']);
  },
});

import { type FC, useContext } from 'react';

import AccountContext from '@/AccountContext';
import Row from '@/ui/Row';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const TwoFactorAuthentication: FC = () => {
  const { twoFactorForced } = useContext(AccountContext);

  return (
    <Row label="Two-Factor Authentication" width={6}>
      {twoFactorForced ? (
        <div className="text-success">
          <FontAwesomeIcon icon="check" /> &nbsp; 2FA is required for all users.
        </div>
      ) : (
        <div className="text-danger">
          <FontAwesomeIcon icon="times" /> &nbsp; 2FA is not required for all users.
        </div>
      )}
      <p className="small text-muted">
        Two-factor authentication provides additional security when logging in to TimeZest. TimeZest supports 2FA with
        authenticator apps such as Google Authenticator, or Authy.
      </p>
      {twoFactorForced ? (
        <a
          className="btn btn-danger"
          data-confirm="Are you sure?"
          rel="nofollow"
          data-method="delete"
          href="/settings/security/two_factor"
        >
          Do Not Require 2FA for All Users
        </a>
      ) : (
        <a
          className="btn btn-primary"
          data-confirm="Are you sure?"
          rel="nofollow"
          data-method="post"
          href="/settings/security/two_factor"
        >
          Require 2FA for All Users
        </a>
      )}
    </Row>
  );
};

export default TwoFactorAuthentication;

import type { FC } from 'react';
import { useContext, useState } from 'react';

import flow from 'lodash/fp/flow';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';

import SearchSelect from '@/ui/SearchSelect';
import PlanContext from '@/PlanContext';
import asScreen from '@/Screen';
import AccountContext from '@/AccountContext';
import CurrentUserContext from '@/CurrentUserContext';

import UrlBasedUrl from './UrlBasedUrl';
import ShareableUrl from './ShareableUrl';
import UrlError from './UrlError';

import type { ResourceModel } from '@models/AppointmentType';
import type AppointmentTypeModel from '@models/AppointmentType';
import { hasIntegrationCapability } from '@shared/utilities';

interface Props {
  appointmentType: AppointmentTypeModel;
  resources: ResourceModel[];
}

const UrlGenerator: FC<Props> = ({ appointmentType, resources }) => {
  const { allowsPanelScheduling, allowsUrlBasedScheduling, allowsShareableUrls, maxResources } =
    useContext(PlanContext);
  const { host, integrations } = useContext(AccountContext);

  const { schedulable, id } = useContext(CurrentUserContext);

  const initialResourceId = schedulable ? id : null;

  const selectedResourceId = initialResourceId || resources[0]?.id || '';

  const canAccessUrlBasedScheduling = hasIntegrationCapability(integrations, 'canAccessUrlBasedScheduling');
  const showUrlBasedSchedulingUrl = allowsUrlBasedScheduling && canAccessUrlBasedScheduling;

  const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>(
    selectedResourceId ? [selectedResourceId.toString()] : []
  );

  const selectedResourceSlugs = flow([
    filter((resource: ResourceModel) => selectedResourceIds.indexOf(resource.id.toString()) >= 0),
    sortBy((resource: ResourceModel) => selectedResourceIds.indexOf(resource.id.toString())),
    map((resource: ResourceModel) => resource.slug),
  ])(resources).join(',');

  const handleSelectionChange = (selectedResourceIds: string[]): void => {
    setSelectedResourceIds(selectedResourceIds);
  };

  return (
    <div className="card mb-4 mt-3 border-0 bg-transparent">
      <div className="card-body ps-0 pt-0">
        <div className="d-flex mb-3">
          <div className="fw-bold me-2 pt-2">Show URLs for scheduling all these resource(s):</div>
          <div className="flex-grow-1">
            <SearchSelect
              name="resources"
              initialItemId={selectedResourceId.toString()}
              items={resources.map(r => ({ id: r.id.toString(), name: r.name }))}
              maxSelectedItems={allowsPanelScheduling ? maxResources : 1}
              minSelectedItems={1}
              handleChange={handleSelectionChange}
            />
          </div>
        </div>

        {(allowsShareableUrls || showUrlBasedSchedulingUrl) && (
          <>
            <hr className="mb-3" />
            {appointmentType.slug && host && selectedResourceSlugs ? (
              <>
                {showUrlBasedSchedulingUrl && (
                  <UrlBasedUrl
                    appointmentTypeSlug={appointmentType.slug}
                    host={host}
                    resourceSlugs={selectedResourceSlugs}
                  />
                )}
                {allowsShareableUrls && (
                  <ShareableUrl appointmentType={appointmentType} host={host} resourceSlugs={selectedResourceSlugs} />
                )}
              </>
            ) : (
              <UrlError appointmentTypeSlug={appointmentType.slug} host={host} resourceSlugs={selectedResourceSlugs} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { UrlGenerator };
export default asScreen(UrlGenerator);

import type { ChangeEvent } from 'react';
import { forwardRef, useContext } from 'react';

import type { Unit } from '@/ui/Duration';
import Duration from '@/ui/Duration';
import type { WorkflowActionProps } from '@/ui/Workflow/types';

import StatusSelect from './StatusSelect';
import StageSelect from './StageSelect';
import CategorySelect from './CategorySelect';

import Action from '@shared/Action';
import type { Content, Content as LineContent } from '@shared/LineEditor';
import LineEditor from '@shared/LineEditor';
import Row from '@ui/Row';
import type { AutotaskFindOrCreateOpportunityAction } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import ErrorBoundary from '@shared/ErrorBoundary';
import InputGroup from '@shared/ui/Inputs/InputGroup';
import useFormSubmit from '@shared/hooks/useFormSubmit';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import FormError from '../../FormError';
import { useCreateOpportunityForAutotask } from '../../hooks';

const DETAILS = (
  <>
    <p className="mb-1">
      When this action appears in a workflow, TimeZest will first attempt to find an opportunity in Autotask associated
      with the contact that is in the specified status. If one is found, this opportunity will be associated with the
      current scheduling request.
    </p>
    <p className="mb-1">
      If an opportunity is not found, TimeZest will create a new opportunity in Autotask with the specified field
      values.
    </p>
    <p className="mb-1">The action will be skipped in the following circumstances:</p>
    <ul className="mb-1">
      <li>Where there is already an Autotask opportunity associated with the scheduling request.</li>
      <li>Where there is no Autotask contact associated with the scheduling request.</li>
      <li>Where there is no Autotask company associated with the scheduling request.</li>
      <li>Where the scheduled user does not have a Autotask account.</li>
      <li>Where there is no scheduled user for the scheduling request.</li>
    </ul>
  </>
);

const CLOSED_DATE_UNITS: Unit[] = ['days', 'weeks'];

const DEFAULT_TITLE_VALUE: Content = {
  type: 'paragraph',
  children: [{ text: 'Opportunity Created from TimeZest' }],
};

const FindOrCreateOpportunityForAutotask = forwardRef<
  HTMLFormElement,
  WorkflowActionProps<AutotaskFindOrCreateOpportunityAction>
>(({ action, readOnly, saveable }, ref) => {
  const { templateMode } = useContext(WorkflowActionContext);

  const { errors: mutationErrors, loading, succeeded, submit } = useCreateOpportunityForAutotask();

  const [probability, setProbability] = useStateFromProp(action.probability, p => (p ? p.toString() : '0'));
  const [amount, setAmount] = useStateFromProp(action.amountCents, a => parseFloat((a / 100).toString()).toFixed(2));
  const [cost, setCost] = useStateFromProp(action.costCents, c => parseFloat((c / 100).toString()).toFixed(2));
  const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
    return {
      title: data.get('title') as string,
      psaStatusId: data.get('psa_status_id') as string,
      psaStatusName: data.get('psa_status_name') as string,
      psaStageId: data.get('psa_stage_id') as string,
      psaStageName: data.get('psa_stage_name') as string,
      psaCategoryId: data.get('psa_category_id') as string,
      psaCategoryName: (data.get('psa_category_name') as string) || null,
      duration: Number(data.get('duration')),
      probability: Number(data.get('probability')),
      amountCents: Number(data.get('amount')) * 100,
      costCents: Number(data.get('cost')) * 100,
    };
  });

  const handleChangeProbability = (e: ChangeEvent<HTMLInputElement>) => {
    setProbability(e.target.value);

    if (Number(e.target.value) < 0) {
      setProbability('0');
    } else if (Number(e.target.value) > 100) {
      setProbability('100');
    }
  };

  const errors = action.errors || mutationErrors;

  return (
    <ErrorBoundary>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Action
          action={action}
          details={DETAILS}
          icon="task-plus"
          summary={
            <>
              Find or create an <strong>opportunity</strong> in Autotask.
            </>
          }
          readOnly={readOnly}
          saveable={saveable}
          saving={loading}
          succeeded={succeeded}
        >
          <FormError action={action} errors={errors} />

          <Row label="Title" width={6}>
            <LineEditor
              name="title"
              initialValue={(action.title || DEFAULT_TITLE_VALUE) as LineContent}
              readOnly={readOnly}
              error={errors.title}
            />
          </Row>

          <StatusSelect
            error={errors.status}
            statusId={action.psaStatusId || '1'}
            templateMode={templateMode}
            readOnly={readOnly}
          />

          <StageSelect
            error={errors.stage}
            stageId={action.psaStageId || '29682769'}
            templateMode={templateMode}
            readOnly={readOnly}
          />

          <CategorySelect
            error={errors.category}
            categoryId={action.psaCategoryId || ''}
            templateMode={templateMode}
            readOnly={readOnly}
          />

          <Row
            label="Projected Close Date"
            width={6}
            helpText="TimeZest will set the projected close date of the created opportunity to this amount of time after its creation."
          >
            <Duration
              name="duration"
              initialValue={action.duration || 0}
              unit="days"
              units={CLOSED_DATE_UNITS}
              error={errors.duration}
              disabled={readOnly}
            />
          </Row>

          <Row label="Probability" width={6}>
            <InputGroup
              name="probability"
              type="number"
              min={0}
              max={100}
              value={probability}
              append="%"
              readOnly={readOnly}
              onChange={handleChangeProbability}
            />
          </Row>

          <Row label="Total Revenue" width={6}>
            <InputGroup
              name="amount"
              type="number"
              min={0.0}
              step={0.01}
              value={amount}
              prepend="$"
              readOnly={readOnly}
              onChange={e => setAmount(e.target.value)}
            />
          </Row>

          <Row label="Total Cost" width={6}>
            <InputGroup
              name="cost"
              type="number"
              min={0.0}
              step={0.01}
              value={cost}
              prepend="$"
              readOnly={readOnly}
              onChange={e => setCost(e.target.value)}
            />
          </Row>
        </Action>
      </form>
    </ErrorBoundary>
  );
});

export default FindOrCreateOpportunityForAutotask;

import type { FC } from 'react';
import { forwardRef } from 'react';

import FormError from '../FormError';
import { useUpdateTicketStatusForConnectWise } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { ConnectWiseUpdateTicketStatusAction } from '@graphql/generated';
import PlainTextRow from '@ui/PlainTextRow';
import RadioButtonRow from '@ui/RadioButtonRow';
import SelectRow from '@ui/SelectRow';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const STATUS_NAMES = [
  { value: 'after_cancel', name: 'Status after cancellation', summaryName: 'after-cancellation' },
  { value: 'after_create', name: ' Status after creation', summaryName: 'after-creation' },
  { value: 'after_schedule', name: 'Status after scheduling', summaryName: 'after-scheduling' },
  { value: 'no_response', name: 'No-Response status', summaryName: 'no-response' },
];

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will update the status in ConnectWise PSA of a ticket associated with this
      scheduling request to the status configured for its board in{' '}
      <a href="/settings/integrations/connect_wise">ConnectWise Integration</a> page.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no ticket associated with this scheduling request.</li>
      <li>When there is no status configured in TimeZest for the service/project board the ticket belongs to.</li>
      <li>When the configured status cannot be found in ConnectWise PSA, or is marked as &apos;inactive&apos;.</li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
  </>
);

const Summary: FC<{ selectedStatusType: string }> = ({ selectedStatusType }) => {
  const statusDisplay = STATUS_NAMES.find(sn => sn.value === selectedStatusType)?.summaryName;

  return (
    <>
      Update the <strong>service ticket</strong> in ConnectWise PSA to the <strong>{statusDisplay}</strong> status
      configured for its board.
    </>
  );
};

const UpdateTicketStatus = forwardRef<HTMLFormElement, WorkflowActionProps<ConnectWiseUpdateTicketStatusAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useUpdateTicketStatusForConnectWise();

    const HELP_TEXT = (
      <>
        TimeZest will move the service ticket to the status configured for its board on the{' '}
        <a href="/settings/integrations/connect_wise">ConnectWise Integration</a> page.
      </>
    );

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        skipIfClosed: (data.get('skip_if_closed') as string) === 'true',
        statusType: (data.get('status_type') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="collapse-right"
            summary={<Summary selectedStatusType={action.statusType} />}
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            {readOnly ? (
              <PlainTextRow label="Status" helpText={HELP_TEXT}>
                {STATUS_NAMES.find(sn => sn.value === action.statusType)?.name}
              </PlainTextRow>
            ) : (
              <SelectRow
                label="Status"
                helpText={HELP_TEXT}
                name="status_type"
                options={STATUS_NAMES}
                error={errors.status_name}
                value={action.statusType}
              />
            )}

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="skip_if_closed"
              value={(action.skipIfClosed || false).toString()}
              label="Skip If Closed"
              helpText=""
              options={[
                {
                  label: 'Always update the status of the associated ticket ',
                  value: 'false',
                  disabled: readOnly,
                },
                {
                  label: 'Skip updating the status of the associated ticket if it is closed',
                  value: 'true',
                  disabled: readOnly,
                },
              ]}
              error={errors.skip_if_closed}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default UpdateTicketStatus;

import type { FC } from 'react';
import { useContext, useMemo } from 'react';

import orderBy from 'lodash/fp/orderBy';

import AccountContext from '@/AccountContext';

import type { PsaType } from '@models/Integration';
import FilterControl from '@shared/ui/Filter';
import { idStringifier } from '@shared/text';
import type Reference from '@models/Reference';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import usePersistedFilters from '@shared/hooks/usePersistedFilters';
import type { Filter } from '@models/Filter';
import { psaName } from '@shared/utilities';

const COMPANY_MAP = {
  connect_wise: 'connectwise_psa_company_id',
  autotask: 'autotask_company_id',
  halo_psa: 'halo_psa_company_id',
};

const TICKET_MAP = {
  connect_wise: 'connectwise_psa_ticket_number',
  autotask: 'autotask_ticket_number',
  halo_psa: 'halo_psa_ticket_number',
};

const ATTRIBUTES = (psaType: PsaType) => [
  { name: 'Appointment Type', value: 'scheduling_request.appointment_type_id', type: 'id' as const },
  {
    name: 'Company',
    value: `scheduling_request.${COMPANY_MAP[psaType]}`,
    type: 'id' as const,
    specialControlType: 'company' as const,
  },
  { name: 'End User Name', value: 'scheduling_request.end_user_name', type: 'string' as const },
  { name: 'End User Email', value: 'scheduling_request.end_user_email', type: 'string' as const },
  {
    name: 'Scheduling Request Created',
    value: 'scheduling_request.created_at',
    type: 'datetime' as const,
    specialControlType: 'datepicker' as const,
  },
  { name: 'Status', value: 'scheduling_request.status', type: 'equality' as const },
  ...(psaType !== 'office_365'
    ? [
        {
          name: 'Ticket Number',
          value: `scheduling_request.${TICKET_MAP[psaType]}`,
          type: 'string' as const,
        },
      ]
    : []),
  {
    name: 'Appointment Start Time',
    value: 'scheduling_request.selected_start_time',
    type: 'datetime' as const,
    specialControlType: 'datepicker' as const,
  },
];

const POSSIBLE_VALUES = (appointmentTypes: Reference[], psaType: PsaType) => {
  return {
    'scheduling_request.appointment_type_id': {
      values: orderBy(['name', 'durationMins'], ['asc', 'asc'], appointmentTypes).map(idStringifier),
    },
    [`scheduling_request.${COMPANY_MAP[psaType]}`]: { values: [] },
    'scheduling_request.end_user_name': { values: [] },
    'scheduling_request.end_user_email': { values: [] },
    'scheduling_request.status': {
      values: [
        { name: 'New', value: 'new' },
        { name: 'Sent', value: 'sent' },
        { name: 'Scheduled', value: 'scheduled' },
        { name: 'Cancelled', value: 'cancelled' },
        { name: 'Closed', value: 'closed' },
      ],
    },
    [`scheduling_request.${TICKET_MAP[psaType]}`]: { values: [] },
    'scheduling_request.selected_start_time': { values: [] },
    'scheduling_request.created_at': { values: [] },
  };
};

interface Props {
  appointmentTypes: Reference[];
  tqlFilters: Filter[];
  onUpdateTqlString: (tqlString: string) => void;
}

const DashboardFilter: FC<Props> = ({ appointmentTypes, tqlFilters, onUpdateTqlString }) => {
  const { psaType } = useContext(AccountContext);

  const possibleValues = useMemo(() => {
    return POSSIBLE_VALUES(appointmentTypes, psaType);
  }, [appointmentTypes, psaType]);

  const persistedFilters = usePersistedFilters(tqlFilters, possibleValues);

  const [filters, setFilters] = useStateWithCallback<Filter[]>(persistedFilters);

  return (
    <>
      <FilterControl
        attributes={ATTRIBUTES(psaType)}
        filters={filters}
        possibleValues={possibleValues}
        onSetFilter={setFilters}
        onUpdateTqlString={onUpdateTqlString}
      />
      <input type="hidden" id="filter-psa-name" value={psaName(psaType)} />
    </>
  );
};

export default DashboardFilter;

import type { FC } from 'react';

import classNames from 'classnames';

import ExpanderCommands from './ExpanderCommands';
import ExpanderIcon from './ExpanderIcon';
import ExpanderSummary from './ExpanderSummary';

interface Props {
  url?: string | null;
  title: string;
  icon: string;
  summary: string | JSX.Element;
  canExpand: boolean;
  expanded: boolean;
  disabled?: boolean;
  method?: 'POST' | 'PATCH';
  confirming: boolean;
  dirty: boolean;
  setConfirming: React.Dispatch<React.SetStateAction<boolean>>;
  onExpand: (expanded: boolean) => void;
  onDirtyStatusUpdate?: (isDirty: boolean) => void;
}

const ExpanderHeader: FC<Props> = ({
  url,
  expanded,
  canExpand,
  icon,
  title,
  summary,
  confirming,
  method,
  disabled,
  dirty,
  setConfirming,
  onExpand,
  onDirtyStatusUpdate,
}) => {
  const handleExpandClick = (event: React.MouseEvent | React.KeyboardEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (!expanded && canExpand) {
      onExpand(true);
      setConfirming(false);
    }
  };

  const handleClick = expanded ? undefined : handleExpandClick;

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames('card-header d-flex', { 'border-bottom-0': !expanded })}
      onClick={handleClick}
      onKeyDown={e => {
        if (e.key === 'Enter' && handleClick) handleClick(e);
      }}
    >
      <ExpanderIcon icon={icon} />
      <ExpanderSummary title={title} summary={summary} />
      {expanded && (
        <ExpanderCommands
          url={url}
          disabled={disabled}
          confirming={confirming}
          dirty={dirty}
          method={method}
          setConfirming={setConfirming}
          onExpand={onExpand}
          onDirtyStatusUpdate={onDirtyStatusUpdate}
        />
      )}
    </div>
  );
};

export default ExpanderHeader;

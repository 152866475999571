import type { FC } from 'react';

import type { Unit } from './Duration';
import Duration from './Duration';

import Row from '@ui/Row';

interface Props {
  name: string;
  value: number;
  label: string;
  helpText: string | JSX.Element | null;
  units: Unit[];
  hintText?: string | JSX.Element;
  disabled?: boolean;
  error?: string;
}

const DurationRow: FC<Props> = ({ name, value, label, helpText, hintText, disabled, error, units }) => {
  return (
    <Row label={label} helpText={helpText}>
      <Duration name={name} initialValue={value} unit="minutes" disabled={disabled} error={error} units={units} />
      {hintText && <p className="mb-0 mt-3">{hintText}</p>}
    </Row>
  );
};

export type { Unit };

export default DurationRow;

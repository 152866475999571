import type {
  Action,
  ConnectWiseCreateActivityAction,
  ConnectWiseCreateContactAction,
  ConnectWiseCreateNoteOnTicketAction,
  ConnectWiseCreateScheduleEntryAction,
  ConnectWiseCreateServiceTicketAction,
  ConnectWiseMoveTicketToBoardAndStatusAction,
  ConnectWiseUpdateTicketStatusAction,
  ConnectWiseFindOrCreateOpportunityAction,
} from '@graphql/generated';

export function isConnectWiseCreateActivityAction(action: Action): action is ConnectWiseCreateActivityAction {
  return action.slug === 'connect_wise/create_activity';
}

export function isConnectWiseCreateContactAction(action: Action): action is ConnectWiseCreateContactAction {
  return action.slug === 'connect_wise/create_contact';
}

export function isConnectWiseCreateNoteOnTicketAction(action: Action): action is ConnectWiseCreateNoteOnTicketAction {
  return action.slug === 'connect_wise/create_note_on_ticket';
}

export function isConnectWiseCreateServiceTicketAction(action: Action): action is ConnectWiseCreateServiceTicketAction {
  return action.slug === 'connect_wise/create_service_ticket';
}

export function isConnectWiseCreateScheduleEntryAction(action: Action): action is ConnectWiseCreateScheduleEntryAction {
  return action.slug === 'connect_wise/create_schedule_entry';
}

export function isConnectWiseFindOrCreateOpportunityAction(
  action: Action
): action is ConnectWiseFindOrCreateOpportunityAction {
  return action.slug === 'connect_wise/find_or_create_opportunity';
}

export function isConnectWiseMoveTicketToBoardAndStatusAction(
  action: Action
): action is ConnectWiseMoveTicketToBoardAndStatusAction {
  return action.slug === 'connect_wise/move_ticket_to_board_and_status';
}

export function isConnectWiseUpdateTicketStatusAction(action: Action): action is ConnectWiseUpdateTicketStatusAction {
  return action.slug === 'connect_wise/update_ticket_status';
}

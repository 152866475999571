import type { FC } from 'react';
import { useContext, useState } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetStatusesForAutotaskQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

interface Props {
  label: string;
  name: string;
  statusName: string;
  helpText: string;
  readOnly: boolean;
  error?: string | null;
  selectedStatusId: string;
  selectedStatusName: string;
}

const StatusSelect: FC<Props> = ({
  selectedStatusId,
  selectedStatusName,
  label,
  helpText,
  readOnly,
  error,
  statusName,
  name,
}) => {
  const { templateMode } = useContext(WorkflowActionContext);

  const queryResult = useGetStatusesForAutotaskQuery({ skip: templateMode });

  const [statusId, setStatusId] = useState(selectedStatusId?.toString() || '');
  const [localStatusName, setLocalStatusName] = useState(selectedStatusName || '');

  const handleStatusChange = (value: string): void => {
    const newStatusId = value;
    const newStatusName = queryResult.data?.autotask.ticketStatuses?.find(
      status => status.id.toString() === newStatusId
    )?.name;

    setStatusId(newStatusId);
    setLocalStatusName(newStatusName || '');
  };

  const queryErrorMessage = (
    <RowError
      label={label}
      helpText={helpText}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve status details from Autotask because the API user does not have sufficient
        permissions."
      unauthorizedRemediation={
        <span>
          Please check the API user has correct permissions assigned as&nbsp;
          <a
            href="https://help.timezest.com/en/articles/4840136-creating-a-security-level-for-autotask"
            target="_blank"
            rel="noreferrer"
          >
            described here
          </a>
          .
        </span>
      }
      error="TimeZest encountered an unexpected error. Please reload the page and try again."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        name={name}
        label={label}
        helpText={helpText}
        error={error || undefined}
        value={statusId || ''}
        field="autotask.ticketStatuses"
        readOnly={readOnly}
        templateMode={templateMode}
        queryResult={queryResult}
        queryErrorMessage={queryErrorMessage}
        onChange={handleStatusChange}
      />
      <input type="hidden" name={statusName} value={localStatusName} />
    </>
  );
};

export default StatusSelect;

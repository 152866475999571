import type { FC } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetHaloPsaTeamsForHaloPsaQuery } from '@graphql/generated';

interface Props {
  name: string;
  label: string;
  selectedTeamId: number | undefined;
  error?: string;
  onTeamIdChange: (id: number | undefined) => void;
}

// This component can only be used within another component which sits
// within an ApolloProvider component which provides access to the
// Apollo client through the context.
const TeamSelect: FC<Props> = ({ name, label, selectedTeamId, error, onTeamIdChange }) => {
  const queryResult = useGetHaloPsaTeamsForHaloPsaQuery({});

  const handleTeamChange = (value: string | undefined): void => {
    const id = value || undefined;

    if (onTeamIdChange) onTeamIdChange(Number(id));
  };

  return (
    <SelectRowFromGraphQL
      name={name}
      label={label}
      helpText=""
      error={error}
      value={selectedTeamId?.toString() || ''}
      loadingMessage="Loading from HaloPSA..."
      emptyDataMessage="No Teams have been defined in HaloPSA."
      queryErrorMessage="Error loading teams from HaloPSA."
      field="haloPsa.teams"
      prompt="Select a team..."
      queryResult={queryResult}
      onChange={handleTeamChange}
    />
  );
};

export default TeamSelect;

import type { FC } from 'react';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  attributeName: string;
  initialLoading: boolean;
  operatorName: string;
  unapplied: boolean;
  applied: boolean;
  valueName: string;
  valueFormatter?: (value: string) => void;
}

const FilterHeader: FC<Props> = ({
  attributeName,
  initialLoading,
  operatorName,
  applied,
  unapplied,
  valueName,
  valueFormatter,
}) => {
  const eventualValueName = () => {
    if (valueFormatter) return valueFormatter(valueName);

    return valueName;
  };

  if (initialLoading) {
    return (
      <div className="d-flex align-items-center gap-2">
        <FontAwesomeIcon spin icon="spinner" />
        <span>Loading</span>
      </div>
    );
  }

  if (unapplied) {
    return <span>Filter is not applied</span>;
  }

  if (applied) {
    return (
      <span className="text-start">
        <strong>{attributeName}</strong>
        {` ${operatorName?.toLowerCase()} ${eventualValueName()}`}
      </span>
    );
  }

  return <span className="text-start">{`${attributeName} ${operatorName?.toLowerCase()} ${eventualValueName()}`}</span>;
};

export default FilterHeader;

// DEPRECATED

import type { FC } from 'react';
import { useState, useRef } from 'react';

import classNames from 'classnames';

import { only } from './utilities';
import Form from './Form';

interface Props {
  className?: string;
  text?: JSX.Element | string;
  url?: string;
  onClick?: (confirmationShown: boolean) => void;
  onConfirm?: () => void;
}

const YesNoConfirmation: FC<Props> = ({ className, text, url, onClick, onConfirm }) => {
  const classes = classNames('btn', 'btn-sm', 'btn-link', 'p-0', 'text-decoration-none', className);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [confirmationShown, setConfirmationShown] = useState(false);

  const handleClick = only(() => {
    setConfirmationShown(true);
    if (onClick) onClick(true);
  });
  const handleNoClick = only(() => {
    setConfirmationShown(false);
    if (onClick) onClick(false);
  });
  const handleYesClick = only(() => {
    setConfirmationShown(false);
    if (formRef) formRef.current?.submit();
    if (onConfirm) onConfirm();
  });

  if (confirmationShown) {
    return (
      <span className={className}>
        Are you sure? &ensp;
        {url ? (
          <>
            <button className={classes} onClick={handleYesClick}>
              Yes
            </button>
            <Form url={url} ref={formRef} method="delete" className="d-none" />
          </>
        ) : (
          <button className={classes} onClick={handleYesClick}>
            Yes
          </button>
        )}
        &ensp;
        <button className={classes} onClick={handleNoClick}>
          No
        </button>
      </span>
    );
  }

  return (
    <button className={classes} onClick={handleClick}>
      {text || 'Remove'}
    </button>
  );
};

export default YesNoConfirmation;

import { useContext, type FC } from 'react';

import UserSchedule from '@/ui/UserSchedule';
import UserTimeZone from '@/UserTimeZone';
import AccountContext from '@/AccountContext';

import type { AvailabilityBlock } from '@models/Availability';
import type AvailabilityModel from '@models/Availability';
import TextRow from '@ui/TextRow';
import { ErrorMessage } from '@ui/ErrorMessage';
import type { RailsTimezone } from '@models/Timezone';
import { psaName as getPsaName } from '@shared/utilities';

interface Props {
  availabilityAllowed: boolean;
  availability: AvailabilityModel;
  availabilityBlocks: AvailabilityBlock[];
  errors: { [attr: string]: string };
  timezones: RailsTimezone[];
  psaUserId: string | null;
}

const AvailabilityConfig: FC<Props> = ({
  availabilityAllowed,
  availability,
  availabilityBlocks,
  errors,
  timezones,
  psaUserId,
}) => {
  const { psaType } = useContext(AccountContext);

  const psaName = getPsaName(psaType);

  return (
    <>
      <UserTimeZone
        allZones={timezones}
        psaName={psaName}
        psaTimeZone={availability.psaTimezone || ''}
        timeZone={availability.usesCustomTimezone ? availability.customTimezone : availability.psaTimezone || ''}
        usesCustomTimeZone={availability.usesCustomTimezone}
        allowsPsaTimeZone={availability.allowsPsaAvailability}
      />
      {availabilityAllowed ? (
        <UserSchedule
          allowsPsaAvailability={availability.allowsPsaAvailability}
          availabilityBlocks={availabilityBlocks}
          errors={errors}
          psaName={psaName}
          psaCalendarName={availability.psaCalendarName || ''}
          usesCustomAvailability={availability.usesCustomAvailability}
        />
      ) : (
        <TextRow
          label="Availability"
          helpText={
            <>
              <div className="small text-danger mb-3">
                Subscribers to the TimeZest Advanced plan can set custom availability for when their members can be
                scheduled with TimeZest.
              </div>
              <div>
                <a href="/settings/account" className="btn btn-danger">
                  Upgrade Now &gt;
                </a>
              </div>
            </>
          }
        >
          {psaUserId ? (
            <>
              {availability.psaCalendarName ? (
                <p>
                  Availability for this member is set in {psaName} in the
                  <strong>{availability.psaCalendarName || '(Not set)'}</strong> calendar.
                </p>
              ) : (
                <p>Availability for this member is set in the calendar in {psaName}.</p>
              )}
            </>
          ) : (
            <p>Availability for this member is not set as they are not associated with any member in {psaName}.</p>
          )}
        </TextRow>
      )}
      {availability.allowsPsaAvailability && (
        <TextRow
          label="Holidays"
          helpText={
            <>
              {availability.psaCalendarName ? (
                <>
                  These holidays are configured in {psaName}. You can change them by editing the{' '}
                  {availability.psaCalendarName} calendar. Only holidays in the next 12 months are shown.
                </>
              ) : (
                <>Holidays are set in {psaName}. You can configure them by assigning a calendar with holidays list.</>
              )}
            </>
          }
        >
          {availability.psaHolidayListConfigured ? (
            <>
              <p>TimeZest won&apos;t offer any appointments with this user on the following holidays:</p>
              {(availability.psaHolidays || []).map((h, i) => (
                <div key={i} className="d-flex">
                  <div className="flex-grow-1">
                    <strong>{h.name}</strong>&nbsp;
                    {!h.allDay && <div className="badge=grey badge-text-aligned">Part-day Holiday</div>}
                  </div>
                  <div>{h.date}</div>
                </div>
              ))}
            </>
          ) : (
            <ErrorMessage>
              {availability.psaCalendarName ? (
                <p>
                  This user is configured in {psaName} to use the <strong>{availability.psaCalendarName}</strong>{' '}
                  calendar, but this calendar is not configured with a holiday list.
                </p>
              ) : (
                <p>This user does not have calendar assigned in {psaName}.</p>
              )}

              <p className="mb-0">
                We recommend configuring a holiday list on every calendar so that TimeZest knows which days not to offer
                appointments on.
              </p>
            </ErrorMessage>
          )}
        </TextRow>
      )}
    </>
  );
};

export default AvailabilityConfig;

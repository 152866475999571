import { type FC, useContext, useState } from 'react';

import Flash from '@/Flash';
import Header from '@/Header';
import Form from '@/Form';
import AccountContext from '@/AccountContext';
import asScreen from '@/Screen';
import InputGroupRow from '@/ui/InputGroupRow';
import Row from '@/ui/Row';

import FileInput from '@shared/ui/Inputs/FileInput';

const HELP_TEXT = (
  <>
    The color TimeZest will use to match the user interface your clients see to your brand. Enter a HTML color value -
    e.g. <code>#cd6155</code>
  </>
);

interface Props {
  errors: Record<string, string>;
  logoUrl: string | null;
}

const Branding: FC<Props> = ({ errors, logoUrl }) => {
  const { brandColor: initialBrandColor } = useContext(AccountContext);

  const [brandColor, setBrandColor] = useState(initialBrandColor);

  return (
    <>
      <Header title="Branding" />
      <Flash />
      <Form enctype="multipart/form-data" method="patch" url="/settings/branding">
        <FileInput
          error={errors.logo}
          label="Logo"
          helpText="We recommend uploading your logo in PNG format, with a white or transparent background, and a minimum size of 400 x 400 pixels."
          name="account[logo]"
        >
          <>{logoUrl && <img src={logoUrl} width="200" className="mb-3" alt="brand-logo" />}</>
        </FileInput>
        <InputGroupRow
          prepend="#"
          label="Brand Color"
          value={brandColor}
          name="account[brand_color]"
          helpText={HELP_TEXT}
          onChange={e => setBrandColor(e.target.value)}
        />
        <hr />
        <Row>
          <input className="btn btn-primary" type="submit" value="Save Changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(Branding);

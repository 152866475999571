import type { FC, PropsWithChildren } from 'react';

interface Props {
  id: string;
}

const Mjml: FC<PropsWithChildren<Props>> = ({ children, id }) => {
  return (
    <div key={id} className="d-flex" style={{ minHeight: '712.391px' }}>
      {children}
    </div>
  );
};

export default Mjml;

import { useContext, type FC } from 'react';

import Expander from '@/ui/Expander';
import { displayAvailability } from '@/utilities';
import PlanContext from '@/PlanContext';

import Config from './Config';

import type Errors from '@models/Errors';
import type AppointmentTypeModel from '@models/AppointmentType';
import type { RailsTimezone } from '@models/Timezone';
import type { AvailabilityBlock } from '@models/Availability';

interface Props {
  appointmentType: AppointmentTypeModel;
  dirty: boolean;
  errors: Errors;
  expanded: boolean;
  canExpand: boolean;
  timezones: RailsTimezone[];
  url: string;
  onExpand: (expanded: boolean) => void;
}

const AvailabilityConfig: FC<Props> = ({
  appointmentType,
  canExpand,
  dirty,
  expanded,
  errors,
  timezones,
  url,
  onExpand,
}) => {
  const { allowsCustomAvailability } = useContext(PlanContext);

  return (
    <Expander
      title="Appointment Type Schedule"
      summary={summary(
        appointmentType.availabilityBlocks || [],
        appointmentType.availabilityMode,
        allowsCustomAvailability
      )}
      url={url}
      method="PATCH"
      icon="wall-clock"
      hasErrors={availabilityHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      {allowsCustomAvailability ? (
        <div className="mb-4">
          <Config appointmentType={appointmentType} errors={errors} timezones={timezones} />
        </div>
      ) : (
        <>
          <p className="text-danger">
            Custom appointment type availability is only available to subscribers to the TimeZest Professional plan.
          </p>
          <p>
            <a href="/settings/account">Upgrade Now</a>
          </p>
        </>
      )}
    </Expander>
  );
};

export function availabilityHasErrors(errors: Errors): boolean {
  return !!(errors.availabilityBlocks || errors.availabilityMode || errors.availabilityTimezone);
}

function summary(
  availabilityBlocks: AvailabilityBlock[],
  mode: AppointmentTypeModel['availabilityMode'],
  allowed: boolean
) {
  if (!allowed) return 'No appointment type scheduled configured';

  const availabilityText = displayAvailability(availabilityBlocks || [], true) || 'not available to be scheduled';

  switch (mode) {
    case 'none':
      return 'No appointment type scheduled configured.';
    case 'combine':
      return `Combine with resource availability: ${availabilityText}.`;
    case 'override':
      return `Ignore resource availability: ${availabilityText}.`;
  }
}

export default AvailabilityConfig;

import { createContext } from 'react';

import type { EmailTemplate } from '../../models/EmailTemplate';

export interface ConfigContextProps {
  defaultEmailTemplate?: EmailTemplate;
  logoUrl: string | null;
  readOnly: boolean;
}

export default createContext<ConfigContextProps>({
  defaultEmailTemplate: undefined,
  logoUrl: '',
  readOnly: false,
});

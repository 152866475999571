import type { FC } from 'react';

import Boards from '@/ui/Boards';

import ErrorAlert from './ErrorAlert';

import type { QueryStatus } from '..';

interface Props {
  query: QueryStatus;
}

const PresetStatuses: FC<Props> = ({ query }) => {
  if (query.badCredentials) {
    return (
      <ErrorAlert>
        <>
          <h5 className="mb-3">
            TimeZest could not retrieve board details from your instance of ConnectWise PSA because the configured
            credentials were rejected.
          </h5>
          <p className="mb-0">
            Check that the API Member in ConnectWise PSA is still configured correctly, and the API credentials are
            correctly set in TimeZest in <a href="/settings/integrations/connect_wise">ConnectWise API settings</a>.
          </p>
        </>
      </ErrorAlert>
    );
  }

  if (query.notConfigured) {
    return (
      <ErrorAlert>
        <>
          <h5 className="mb-3">
            TimeZest could not retrieve the list of boards from your instance of ConnectWise PSA, as no API credentials
            are configured.
          </h5>
          <p className="mb-0">
            You can configure these API credentials in{' '}
            <a href="/settings/integrations/connect_wise">ConnectWise API settings</a>.
          </p>
        </>
      </ErrorAlert>
    );
  }

  if (query.notSupported) {
    return (
      <ErrorAlert>
        <h5>This functionality is only available to TimeZest accounts configured to integrate with ConnectWise PSA.</h5>
      </ErrorAlert>
    );
  }

  if (query.unauthorized) {
    return (
      <ErrorAlert>
        <h5 className="mb-3">
          TimeZest could not retrieve board details from your instance of ConnectWise PSA because the API member is
          missing necessary permissions.
        </h5>
        <p className="mb-0">
          Please ensure that access to &apos;Project / Project Board&apos; and &apos;Service / Service Board&apos; setup
          tables is allowed in the permissions for the &apos;Table Setup&apos; permission in the &apos;System&apos;
          section, as&nbsp;
          <a href="https://help.timezest.com/en/articles/3158493-creating-a-custom-security-role-in-connectwise-manage-for-timezest">
            detailed here
          </a>
          .
        </p>
      </ErrorAlert>
    );
  }

  return (
    <div>
      <h5 className="mb-3">Preset Statuses</h5>
      <Boards />
    </div>
  );
};

export default PresetStatuses;

import { type FC, useContext } from 'react';

import PlanContext from '@/PlanContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  newPlanAllowsTeamScheduling: boolean;
}

const TeamScheduling: FC<Props> = ({ newPlanAllowsTeamScheduling }) => {
  const { allowsTeamScheduling: currentPlanAllowsTeamScheduling } = useContext(PlanContext);

  if (newPlanAllowsTeamScheduling && !currentPlanAllowsTeamScheduling) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; You&apos;ll be able to schedule appointments to teams, and not just individual users.
      </div>
    );
  }

  if (!newPlanAllowsTeamScheduling && currentPlanAllowsTeamScheduling) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp; You&apos;ll no longer be able to schedule appointments with teams.
      </div>
    );
  }
};

export default TeamScheduling;

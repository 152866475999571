import { type FC, useContext } from 'react';

import AccountContext from '@/AccountContext';
import Flash from '@/Flash';
import Header from '@/Header';
import asScreen from '@/Screen';

import PlanChangingAnnouncement from './PlanChangingAnnouncement';
import TeamScheduling from './FeatureChanges/TeamScheduling';
import Rescheduling from './FeatureChanges/Rescheduling';
import CustomDomain from './FeatureChanges/CustomDomain';
import CustomAvailability from './FeatureChanges/CustomAvailability';
import CustomEmail from './FeatureChanges/CustomEmail';
import Office365Calendars from './FeatureChanges/Office365Calendars';
import UrlBasedScheduling from './FeatureChanges/UrlBasedScheduling';
import BillingInformation from './BillingInformation';
import Checkout from './Checkout';

import type Plan from '@models/Plan';

export type InvoiceEstimateModel = {
  amountDue: number;
  credits: number;
  nextBillingAt: string | null;
};

export type CreditNoteEstimatesModel = {
  estimates: number;
  estimateTotal: number;
};

type PlanChange = {
  cardRequired: boolean;
  downgrading: boolean;
  changingPlan: boolean;
  newPlan: Plan;
};

interface Props {
  card: { type: string; last4: string } | null;
  chargebeeSite: string;
  hasCustomEmails: boolean;
  planChange: PlanChange;
  subscriptionRenewsAt: string | null;
  useCustomDomain: boolean;
  invoiceEstimate: InvoiceEstimateModel | null;
  creditNoteEstimates: CreditNoteEstimatesModel | null;
  nextEstimateTotal: number | null;
}

const AccountUpgrade: FC<Props> = ({
  card,
  chargebeeSite,
  creditNoteEstimates,
  hasCustomEmails,
  planChange,
  subscriptionRenewsAt,
  useCustomDomain,
  invoiceEstimate,
  nextEstimateTotal,
}) => {
  const { status } = useContext(AccountContext);

  const buttonName = status === 'active' ? 'Change Subscription' : 'Start Subscription';

  return (
    <>
      <Header title="Confirm Subscription Change" />
      <Flash />
      <p>
        When you click <strong>{buttonName}</strong>:
      </p>

      <PlanChangingAnnouncement
        changingPlan={planChange.changingPlan}
        downgrading={planChange.downgrading}
        newPlanName={planChange.newPlan.name}
        subscriptionRenewsAt={subscriptionRenewsAt}
      />

      <div className="mb-3">
        <TeamScheduling newPlanAllowsTeamScheduling={planChange.newPlan.allowsTeamScheduling} />
        <Rescheduling newPlanAllowsRescheduling={planChange.newPlan.allowsRescheduling} />
        <CustomDomain
          newPlanAllowsCustomerSmtpServer={planChange.newPlan.allowsCustomerSmtpServer}
          useCustomDomain={useCustomDomain}
        />
        <CustomAvailability newPlanAllowsCustomAvailability={planChange.newPlan.allowsCustomAvailability} />
        <CustomEmail
          hasCustomEmails={hasCustomEmails}
          newPlanAllowsCustomEmails={planChange.newPlan.allowsCustomEmails}
        />
        <UrlBasedScheduling newPlanUrlBasedScheduling={planChange.newPlan.allowsUrlBasedScheduling} />
        <Office365Calendars newPlanAllowsOffice365Calendars={planChange.newPlan.allowsOffice365Calendars} />
      </div>

      <BillingInformation
        card={card}
        invoiceEstimate={invoiceEstimate}
        creditNoteEstimates={creditNoteEstimates}
        nextEstimateTotal={nextEstimateTotal}
        activeAccount={status === 'active'}
      />

      <Checkout
        cardRequired={planChange.cardRequired}
        newPlanId={planChange.newPlan.id}
        chargebeeSite={chargebeeSite}
        buttonName={buttonName}
      />
    </>
  );
};

export default asScreen(AccountUpgrade);

import type { FC } from 'react';
import { useContext, useState } from 'react';

import Expander from '@/ui/Expander';
import PlanContext from '@/PlanContext';

import Summary from './Summary';
import TechnicianConfirmationEmailSelect from './TechnicianConfirmationEmailSelect';
import EmailControl from './EmailControl';

import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import SelectRow from '@ui/SelectRow';
import TextAreaRow from '@ui/TextAreaRow';
import type Reference from '@models/Reference';

export function notificationsHasErrors(errors: Errors): boolean {
  return !!(errors.calendarTemplate || errors.notificationTemplateId || errors.confirmationTemplateId);
}

interface Props {
  url: string;
  appointmentType: AppointmentTypeModel;
  defaultNotificationTemplateName: string;
  defaultConfirmationTemplateName: string;
  defaultCancellationTemplateName: string;
  notificationTemplateName: string;
  confirmationTemplateName: string;
  cancellationTemplateName: string;
  customAttributes: Reference[];
  errors: Errors;
  expanded: boolean;
  canExpand: boolean;
  onExpand: (expanded: boolean) => void;
  onMessagesExpand: () => void;
  dirty: boolean;
}

const NotificationsConfig: FC<Props> = props => {
  const {
    appointmentType,
    errors,
    customAttributes,
    canExpand,
    expanded,
    dirty,
    defaultNotificationTemplateName,
    defaultConfirmationTemplateName,
    defaultCancellationTemplateName,
    onExpand,
  } = props;

  const { allowsCustomEmails } = useContext(PlanContext);

  const [notificationTemplateName, setNotificationTemplateName] = useState(props.notificationTemplateName);
  const [confirmationTemplateName, setConfirmationTemplateName] = useState(props.confirmationTemplateName);
  const [cancellationTemplateName, setCancellationTemplateName] = useState(props.cancellationTemplateName);

  return (
    <Expander
      title="Notifications & Emails"
      summary={<Summary {...{ notificationTemplateName, confirmationTemplateName, cancellationTemplateName }} />}
      url={`/settings/appointment_types/${appointmentType.id}/classic/notifications`}
      method="PATCH"
      icon="email"
      hasErrors={notificationsHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      <EmailControl
        allowsCustomEmails={allowsCustomEmails}
        name="appointment_type[notification_template_id]"
        value={allowsCustomEmails ? appointmentType.notificationTemplateId : defaultNotificationTemplateName}
        templateType="notification"
        label="Notification Template"
        helpText="TimeZest will use this email when sending the initial email to the client asking them to select a time."
        onNameChange={setNotificationTemplateName}
      />
      <EmailControl
        allowsCustomEmails={allowsCustomEmails}
        name="appointment_type[confirmation_template_id]"
        value={allowsCustomEmails ? appointmentType.confirmationTemplateId : defaultConfirmationTemplateName}
        templateType="confirmation"
        label="Confirmation Template"
        helpText="TimeZest will use this template to confirm the client's selected appointment time."
        onNameChange={setConfirmationTemplateName}
      />

      <EmailControl
        allowsCustomEmails={allowsCustomEmails}
        name="appointment_type[cancellation_template_id]"
        value={allowsCustomEmails ? appointmentType.cancellationTemplateId : defaultCancellationTemplateName}
        templateType="cancellation"
        label="Cancellation Template"
        helpText="TimeZest will use this template to confirm the cancellation of an appointment."
        onNameChange={setCancellationTemplateName}
      />
      <TechnicianConfirmationEmailSelect {...props} />
      <hr />
      <TextAreaRow
        name="appointment_type[calendar_template]"
        value={appointmentType.calendarTemplate}
        label="Calendar Template"
        helpText="The Template TimeZest will use for the description of the calendar invite sent to clients."
        rows={8}
        error={errors.calendarTemplate}
      />
      {allowsCustomEmails ? (
        <>
          <hr />
          <SelectRow
            name="appointment_type[location_custom_attribute_id]"
            value={
              appointmentType.locationCustomAttributeId ? appointmentType.locationCustomAttributeId.toString() : ''
            }
            label="Custom Attribute in Location Field"
            helpText="If you wish TimeZest to use the value of a custom attribute in the location field of the calendar invite - for example, to give the link to an online meeting, select it here."
            options={customAttributes.map(c => {
              return { name: c.name, value: c.id.toString() };
            })}
          />
        </>
      ) : null}
    </Expander>
  );
};

export default NotificationsConfig;

import type { FC } from 'react';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const IntegrationStatus: FC<{ connected: boolean }> = ({ connected }) => {
  if (connected) {
    return (
      <span className="text-success">
        <FontAwesomeIcon icon="check" className="me-2" />
        Connected
      </span>
    );
  }

  return <span className="text-muted">Not Connected</span>;
};

export default IntegrationStatus;

import { type FC, useContext } from 'react';

import PlanContext from '@/PlanContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  newPlanAllowsCustomerSmtpServer: boolean;
  useCustomDomain: boolean;
}

const CustomDomain: FC<Props> = ({ newPlanAllowsCustomerSmtpServer, useCustomDomain }) => {
  const { allowsCustomerSmtpServer: currentPlanAllowsCustomerSmtpServer } = useContext(PlanContext);

  if (!newPlanAllowsCustomerSmtpServer && currentPlanAllowsCustomerSmtpServer) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp;
        {useCustomDomain ? (
          <>
            TimeZest will no longer use your configured custom email domain, but will send all emails from{' '}
            <strong>noreply@timezest.com</strong>
          </>
        ) : (
          <>
            You won&apos;t be able to configure TimeZest to use a custom email domain, and all TimeZest emails will be
            sent from <strong>noreply@timezest.com</strong>
          </>
        )}
      </div>
    );
  }

  if (newPlanAllowsCustomerSmtpServer && !currentPlanAllowsCustomerSmtpServer) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; You&apos;ll be able to configure TimeZest to use a custom email domain, so client emails can come from an
        address on your domain.
      </div>
    );
  }
};

export default CustomDomain;

import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When it executes this action, TimeZest will update the ticket owner on a ConnectWise PSA ticket associated with
      the scheduling request to the scheduled user.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When no scheduled users have been determined for the scheduling request (i.e. prior to the client selecting a
        time).
      </li>
      <li>When there is no ConnectWise ticket associated with the scheduling request.</li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
    <p>
      When using panel scheduling, the first resource for the scheduling request will determine the scheduled user that
      becomes the primary resource.
    </p>
  </>
);

const UpdateTicketOwner: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action
      action={action}
      details={DETAILS}
      icon="user-b"
      summary="Update owner for ConnectWise PSA Ticket"
      readOnly={readOnly}
    >
      <p>Update the ticket owner in ConnectWise PSA to the scheduled user.</p>
    </Action>
  </ErrorBoundary>
);

export default UpdateTicketOwner;

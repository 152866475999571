import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';

import upperCase from 'lodash/fp/upperCase';
import classNames from 'classnames';

import ConfirmationModal from '@/ui/ConfirmationModal';

import { capitalize } from '@shared/text';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

import { deleteElement, selectElement } from '../../actions';
import { useAppDispatch } from '../../hooks';

interface Props {
  selectedElementId: string;
  elementType: string;
  deleteDescription: string;
  disabled: boolean;
}

const PropertiesContainer: FC<PropsWithChildren<Props>> = ({
  selectedElementId,
  elementType,
  deleteDescription,
  disabled,
  children,
}) => {
  const [showConfirmationDeleteElement, setShowConfirmationDeleteElement] = useState(false);

  const dispatch = useAppDispatch();

  const handleConfirmDeleteElement = () => {
    dispatch(deleteElement({ elementId: selectedElementId }));
    dispatch(selectElement({ selectedElementId: null }));
    setShowConfirmationDeleteElement(false);
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-2 mb-2">
        <h6 className="fw-bold flex-grow-1 m-0 ps-2">{upperCase(elementType.slice(elementType.indexOf('-') + 1))}</h6>
        <div className="d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            className={classNames({ 'pe-none': disabled })}
            style={{ cursor: 'pointer' }}
            icon="trash-alt"
            opacity={disabled ? 0.5 : 1}
            onClick={() => setShowConfirmationDeleteElement(true)}
          />
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            className="ms-3"
            icon="times"
            onClick={() => dispatch(selectElement({ selectedElementId: null }))}
          />
        </div>
      </div>
      {children}
      <ConfirmationModal
        title={`Delete ${capitalize(elementType.slice(elementType.indexOf('-') + 1))}`}
        description={deleteDescription}
        ariaLabel={`Delete ${capitalize(elementType.slice(elementType.indexOf('-') + 1))}`}
        isOpen={showConfirmationDeleteElement}
        onConfirm={handleConfirmDeleteElement}
        onClose={() => setShowConfirmationDeleteElement(false)}
      />
    </>
  );
};

export default PropertiesContainer;

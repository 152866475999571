import type { FC } from 'react';

import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import type { AppointmentTypeTemplate as AppointmentTypeTemplateModel } from '@models/AppointmentTypeTemplate';
import { sentence } from '@shared/text';
import useDisplayDuration from '@shared/hooks/useDisplayDuration';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface ValidTemplateProps {
  template: AppointmentTypeTemplateModel;
  allowsOnlineMeetings: boolean;
}

const ICON_REFERENCE = {
  0: 'circle-plus',
  1: 'clock',
};

const ValidTemplate: FC<ValidTemplateProps> = ({ template, allowsOnlineMeetings }) => {
  const params = omitBy({ internal_name: template.name, external_name: template.externalName }, isEmpty);
  const query = new URLSearchParams(params);

  const displayDuration = useDisplayDuration();

  const descriptions: string[] = [];

  const videoCallingModes = template.videoCallingModes.map(v => v.type);

  let bufferTime = '';

  let shareableUrlAppointmentTypeAndVideoCallingModes = appointmentMode(
    template.requiredIntegrations[0],
    template.shareableUrlAppointmentType
  );

  if (!template.bufferAfterMins && !template.bufferBeforeMins) {
    bufferTime += 'No buffer times defined.';
  }

  if (template.bufferBeforeMins && template.bufferBeforeMins > 0) {
    bufferTime += `${displayDuration(template.bufferBeforeMins)} buffer before${template.bufferAfterMins ? '' : '.'}`;
  }

  if (template.bufferAfterMins && template.bufferAfterMins > 0) {
    bufferTime += `${template.bufferBeforeMins ? ' and ' : ''} ${displayDuration(
      template.bufferAfterMins
    )} buffer after.`;
  }

  if (allowsOnlineMeetings && videoCallingModes.includes('zoom_video')) {
    shareableUrlAppointmentTypeAndVideoCallingModes += ' with Zoom online meeting.';
  } else if (allowsOnlineMeetings && videoCallingModes.includes('teams_video')) {
    shareableUrlAppointmentTypeAndVideoCallingModes += ' with Microsoft Teams online meeting.';
  } else {
    shareableUrlAppointmentTypeAndVideoCallingModes += '.';
  }

  descriptions.push(shareableUrlAppointmentTypeAndVideoCallingModes, bufferTime);

  return (
    <a className="appointment-type" href={`/workflows/${template.slug}/review?${query.toString()}`}>
      <div className="card">
        <div className="appointment-type-header rounded-top px-4 py-2">
          <p className="fw-bold mb-0">{template.name}</p>
          <p className="text-muted small mb-0 mt-1">{displayDuration(template.durationMins)}</p>
        </div>

        <div className="card-body px-4 py-2">
          <div className="small">{template.description}</div>

          <ul className="small text-muted list-unstyled position-relative d-grid mb-0 mt-2 gap-2">
            {descriptions.map((description, index) => (
              <li key={description} className="d-flex align-items-start justify-content-start p-0">
                <FontAwesomeIcon
                  className="me-2"
                  icon={ICON_REFERENCE[index]}
                  color="#aaa"
                  style={{ marginTop: '2px', minWidth: '15px' }}
                />
                <span>{description}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </a>
  );
};

interface TemplateMissingIntegrationsProps {
  template: AppointmentTypeTemplateModel;
  missingIntegrations: string[];
}

const INTEGRATION_DISPLAY_NAMES = {
  'Office365Calendar::Integration': 'Microsoft 365',
  'MicrosoftTeams::Integration': 'Microsoft Teams (Meetings)',
  'MicrosoftTeams::MessagingIntegration': 'Microsoft Teams (Messaging)',
  'Zoom::Integration': 'Zoom',
  'ConnectWise::Integration': 'ConnectWise PSA',
  'HaloPsa::Integration': '',
  'Autotask::Integration': 'Autotask',
};

function renderIntegrations(missingIntegrations: string[]): string {
  return sentence(missingIntegrations.map(i => INTEGRATION_DISPLAY_NAMES[i]));
}

const TemplateMissingIntegrations: FC<TemplateMissingIntegrationsProps> = ({ template, missingIntegrations }) => {
  return (
    <div className="card">
      <div className="appointment-type-header rounded-top fw-bold text-muted px-4 py-2">{template.name}</div>
      <div className="appointment-type-description-list small text-muted px-4 py-2">
        This template cannot be copied into your account as it requires the {renderIntegrations(missingIntegrations)}
        {missingIntegrations.length === 1 ? ' integration which is ' : ' integrations which are '}
        not connected.
      </div>
    </div>
  );
};

interface Props2 {
  template: AppointmentTypeTemplateModel;
  availableIntegrations: string[];
  allowsOnlineMeetings: boolean;
}

const AppointmentTypeTemplate: FC<Props2> = ({ template, availableIntegrations, allowsOnlineMeetings }) => {
  const missingIntegrations = template.requiredIntegrations.filter(i => !availableIntegrations.includes(i));

  if (missingIntegrations.length === 0) {
    return <ValidTemplate template={template} allowsOnlineMeetings={allowsOnlineMeetings} />;
  } else {
    return <TemplateMissingIntegrations template={template} missingIntegrations={missingIntegrations} />;
  }
};

export default AppointmentTypeTemplate;

function appointmentMode(integrationType: string, shareableUrlAppointmentType: string): string {
  switch (integrationType) {
    case 'Autotask::Integration':
      return shareableUrlAppointmentType === 'activity' ? 'Creates a company to-do' : 'Creates a service call';
    case 'ConnectWise::Integration':
      return shareableUrlAppointmentType === 'activity' ? 'Creates an activity' : 'Creates a schedule entry';
    case 'HaloPsa::Integration':
      return 'Creates an appointment';
    case 'Office365Calendar::Integration':
      return 'Creates an Office 365 Event';
    default:
      throw new Error(`${integrationType} is not supported`);
  }
}

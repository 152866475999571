import type { FC } from 'react';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const CARD_TYPES = {
  visa: ['Visa card', 'cc-visa'],
  mastercard: ['MasterCard', 'cc-mastercard'],
  american_express: ['American Express card', 'cc-amex'],
  discover: ['Discover card', 'cc-discover'],
  jcb: ['JCB card', 'cc-jcb'],
};

interface Props {
  cardType: string;
  last4: string;
}

const CardType: FC<Props> = ({ cardType, last4 }) => {
  const [cardName, cardIcon] = CARD_TYPES[cardType];

  return (
    <>
      <FontAwesomeIcon icon={cardIcon} />
      &nbsp;&nbsp;{cardName} with last 4 digits <strong>{last4}</strong>
    </>
  );
};

export default CardType;

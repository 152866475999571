import type { FC } from 'react';

import Form from '@/Form';
import TextInputRow from '@/ui/TextInputRow';
import SelectRow from '@/ui/SelectRow';
import Row from '@/ui/Row';
import CopyToClipboard from '@/CopyToClipboard';
import InputGroupRow from '@/ui/InputGroupRow';
import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import type { ApiKey } from '@models/ApiKey';

interface Props {
  apiKey: ApiKey;
}

const ApiKeysForm: FC<Props> = ({ apiKey }) => {
  const hasApiKey = apiKey.key !== null;
  const submitUrl = hasApiKey ? `/settings/api_keys/${apiKey.id}` : '/settings/api_keys/';

  const AVAILABLE_OPTIONS = [
    { name: 'Read only', value: 'read_only' },
    { name: 'Read and write', value: 'read_write' },
  ];

  return (
    <>
      <Header
        title={hasApiKey ? 'Edit API Key' : 'Create new API Key'}
        headerButton={
          hasApiKey
            ? {
                color: 'red',
                method: 'delete',
                text: 'Delete API Key',
                url: `/settings/api_keys/${apiKey.id}`,
              }
            : undefined
        }
      />
      <Flash />

      <Form url={submitUrl} {...formMethod(hasApiKey)}>
        <TextInputRow label="Name" name="api_key[name]" value={apiKey.name} width={6} error={apiKey.errors.name} />
        <SelectRow
          width={6}
          label="Permissions"
          name="api_key[permissions]"
          value={apiKey.permissions}
          options={AVAILABLE_OPTIONS}
        />

        {hasApiKey && (
          <InputGroupRow
            label="Key"
            value={apiKey.key}
            name="api_key[key]"
            disabled={true}
            width={6}
            append={
              <span className="input-group-text">
                <CopyToClipboard text={apiKey.key} />
              </span>
            }
          />
        )}

        <Row>
          <input className="btn btn-primary" type="submit" value="Save" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(ApiKeysForm);

function formMethod(hasApiKey: boolean) {
  if (hasApiKey) {
    return { method: 'patch' as const };
  }
}

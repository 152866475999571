import type { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

interface Props {
  readOnly: boolean;
}

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const NewToolsContainer: FC<PropsWithChildren<Props>> = ({ readOnly, children }) => {
  return (
    <Container
      className={classNames({
        'pe-none opacity-75': readOnly,
      })}
    >
      {children}
    </Container>
  );
};

export default NewToolsContainer;

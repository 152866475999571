import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityStatusSelectForAutotaskQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const HELP_TEXT = 'TimeZest will create the opportunity in Autotask with this status.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  statusId: string | undefined;
  templateMode?: boolean;
}

const StatusSelect: FC<Props> = ({ statusId: initialStatusId, error, readOnly, templateMode }) => {
  const queryResult = useGetOpportunityStatusSelectForAutotaskQuery({ skip: templateMode });

  const [statusId, setStatusId] = useStateFromProp(initialStatusId, initialStatusId => initialStatusId || '');

  const statuses = queryResult.data?.autotask.opportunityStatuses || [];
  const status = statuses.find(s => s.id === statusId);

  const handleStatusChange = (value: string) => {
    if (value !== statusId) {
      setStatusId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      label="Status"
      helpText={HELP_TEXT}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity status details from Autotask because the API user does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the security level grants <strong>Full Access</strong> to the <strong>CRM</strong>{' '}
          permissions.
          <a
            href="https://help.timezest.com/en/articles/4840136-creating-a-security-level-for-autotask"
            target="_blank"
            rel="noreferrer"
            className="d-flex align-items-center d-block w-75 m-0 mt-3"
          >
            You can find full details here <FontAwesomeIcon icon="external-link" size="sm" className="ms-1" />
          </a>
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity statuses from Autotask."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        name="psa_status_id"
        label="Status"
        loadingMessage="Loading opportunity statuses from Autotask..."
        field="autotask.opportunityStatuses"
        emptyDataMessage="No opportunity statuses found."
        readOnly={readOnly}
        templateMode={templateMode}
        error={error}
        value={statusId}
        queryErrorMessage={queryErrorMessage}
        queryResult={queryResult}
        helpText={HELP_TEXT}
        onChange={handleStatusChange}
      />
      <input type="hidden" name="psa_status_name" value={status?.name || ''} />
    </>
  );
};

export default StatusSelect;

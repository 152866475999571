import { useMemo } from 'react';

import type { Filter } from '@models/Filter';
import type { PossibleValues } from '@shared/ui/Filter/types';

export default function usePersistedFilters(tqlFilters: Filter[], possibleValues: PossibleValues) {
  const persistedFilters = useMemo(() => {
    return tqlFilters.map(f => {
      if (f.attribute.includes('company')) {
        return {
          ...f,
          persisted: true,
        };
      }

      return {
        ...f,
        valueName: possibleValues[f.attribute].values?.find(v => v.value === f.value)?.name || f.value,
        persisted: true,
      };
    });
  }, [tqlFilters, possibleValues]);

  return persistedFilters;
}

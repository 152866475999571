import type { FC } from 'react';

import type { LoginActivityModel } from '@models/User';

interface Props {
  twoFactorEnabled: boolean;
  lastLogins: LoginActivityModel[];
}

const UserSecuritySummary: FC<Props> = ({ twoFactorEnabled, lastLogins }) => {
  let summary = twoFactorEnabled ? '2FA enabled' : '2FA disabled';
  const successfulLogins = lastLogins.filter(l => l.succeeded);
  const lastLogin = successfulLogins[0];

  if (lastLogin) {
    const date = new Date(lastLogin.date);
    summary = `${summary}; Last login on ${date.toLocaleString()}.`;
  } else {
    summary = `${summary}; Never logged in.`;
  }

  return <>{summary}</>;
};

export default UserSecuritySummary;

import type { TemplateState } from './templateReducer';
import { getElementFromIndex } from './utilities';

import type { Paragraph } from '@models/EmailTemplate';

const updateTextContent: (state: TemplateState, elementId: string, content: Paragraph[]) => TemplateState = (
  state,
  elementId,
  content
) => {
  const element = getElementFromIndex(state, elementId);

  if (element.type !== 'mjml-button' && element.type !== 'mjml-text') {
    throw 'Attempting to update non-text node with content';
  }

  return {
    ...state,
    index: { ...state.index, [elementId]: { ...element, content: [...content] } },
  };
};

export default updateTextContent;

import type { FC } from 'react';
import React, { useContext } from 'react';

import classNames from 'classnames';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import last from 'lodash/fp/last';

import ConfigContext from './ConfigContext';
import { AddContentButton, EmptyColumnPlaceholder } from './Blocks/MjmlColumn';
import { useAppSelector } from './hooks';
import Logo from './Tools/Logo';
import Button from './Tools/Button';
import Text from './Tools/Text';
import SectionWithOneColumn from './Tools/Section/SectionWithOneColumn';
import SectionWithTwoColumns from './Tools/Section/SectionWithTwoColumns';
import SectionWithThreeColumns from './Tools/Section/SectionWithThreeColumns';
import SectionWithFourColumns from './Tools/Section/SectionWithFourColumns';
import { renderNode } from './Preview';
import DragHandle from './DragHandle';
import { paddingStyle, useLogo } from './Blocks/utilities';
import type { Index } from './Reducers/templateReducer';
import { fontStacks } from './Blocks/MjmlText';
import Spacer from './Tools/Spacer';
import Image from './Tools/Image';
import type { IndexedMjmlNode } from './EmailTemplate';
import imagePlaceholder from './Blocks/MjmlImage/email-editor-no-image-placeholder.png';

import type { LeafNode, Paragraph } from '@models/EmailTemplate';

const ELEMENT_DRAG_OVERLAY_CLASS_NAMES = 'EmailEditor__Element EmailEditor__Element--highlighted';
const SECTION_DRAG_OVERLAY_CLASS_NAMES = 'EmailEditor__Section EmailEditor__Section--highlighted';

interface LogoData {
  url: string;
  width: number;
  height: number;
}

const ElementDragOverlay: FC = () => {
  const { logoUrl } = useContext(ConfigContext);
  const logo = useLogo(logoUrl);
  const selectedElementId = useAppSelector(state => state.ui.selectedElementId);
  const index = useAppSelector(state => state.template.index);

  if (!selectedElementId) return <>Not yet detected element</>;

  return selectedElementId?.match(/^new\-/)
    ? renderToolDragOverlay(selectedElementId)
    : renderElementDragOverlay(index, selectedElementId, logo);
};

export default ElementDragOverlay;

const renderToolDragOverlay = (id: string) => {
  switch (id) {
    case 'new-logo':
      return <Logo />;
    case 'new-button':
      return <Button />;
    case 'new-text':
      return <Text />;
    case 'new-section-1':
      return <SectionWithOneColumn />;
    case 'new-section-2':
      return <SectionWithTwoColumns />;
    case 'new-section-3':
      return <SectionWithThreeColumns />;
    case 'new-section-4':
      return <SectionWithFourColumns />;
    case 'new-spacer':
      return <Spacer />;
    case 'new-image':
      return <Image />;
    default:
      return <>Unknown Tool</>;
  }
};

const renderElementDragOverlay = (index: Index, selectedElementId: string, logo: LogoData) => {
  const selectedElement: IndexedMjmlNode = index[selectedElementId];
  const alignment = selectedElement['alignment'] ? selectedElement['alignment'] : '';

  const ALIGNMENT_CLASSNAME = classNames(
    { 'ms-auto': alignment === 'right' },
    { 'ml-auto': alignment === 'left' },
    { 'mx-auto': alignment === 'center' || !alignment }
  );

  switch (selectedElement.type) {
    case 'mjml-section':
      return (
        <div
          id={selectedElementId}
          className={SECTION_DRAG_OVERLAY_CLASS_NAMES}
          style={{
            backgroundColor: selectedElement.backgroundColor,
            padding: paddingStyle(selectedElement.padding),
            width: selectedElement.width,
            margin: '0 auto',
          }}
        >
          <div style={{ margin: '0 auto', display: 'flex' }}>
            {selectedElement.childIds.map(childId => renderNode(index, childId, null, selectedElementId))}
          </div>
          <div className="EmailEditor__Section_Identifier">
            <span>
              <DragHandle selected={true} />
            </span>
            Section
          </div>
        </div>
      );
    case 'mjml-column':
      return (
        <div
          id={selectedElementId}
          className={classNames(ELEMENT_DRAG_OVERLAY_CLASS_NAMES, {
            'w-100': true,
          })}
          style={{ backgroundColor: selectedElement.backgroundColor }}
        >
          {React.Children.count(selectedElement.childIds) ? (
            <div style={{ zIndex: 999, padding: paddingStyle(selectedElement.padding) }}>
              {selectedElement.childIds.map(childId => renderNode(index, childId, null, selectedElementId))}
            </div>
          ) : (
            <EmptyColumnPlaceholder style={{ zIndex: 999, padding: paddingStyle(selectedElement.padding) }}>
              <p className="m-0">No content here. Drag content from right.</p>
              <AddContentButton>Add Content</AddContentButton>
            </EmptyColumnPlaceholder>
          )}

          <div className="EmailEditor__Element_Identifier">
            Column
            <span>
              <DragHandle selected={true} />
            </span>
          </div>
        </div>
      );
    case 'mjml-logo':
      return (
        <div
          id={selectedElementId}
          style={{ padding: paddingStyle(selectedElement.padding) }}
          className={ELEMENT_DRAG_OVERLAY_CLASS_NAMES}
        >
          <img width={logo.width} height={logo.height} className="d-block mx-auto" src={logo.url} alt="account-logo" />
          <div className="EmailEditor__Element_Identifier">
            Logo
            <span>
              <DragHandle selected={true} />
            </span>
          </div>
        </div>
      );
    case 'mjml-text':
      return (
        <div
          id={selectedElementId}
          style={{
            backgroundColor: selectedElement.backgroundColor,
            fontSize: selectedElement.font?.size,
            fontFamily: fontStacks[selectedElement.font?.family || 'Sans-serif'],
            color: selectedElement.text?.color,
            textAlign: selectedElement.text?.alignment,
            padding: paddingStyle(selectedElement.padding),
          }}
          className={ELEMENT_DRAG_OVERLAY_CLASS_NAMES}
        >
          {renderDraftEditorContent(selectedElement.content!)}

          <div className="EmailEditor__Element_Identifier">
            Text
            <span>
              <DragHandle selected={true} />
            </span>
          </div>
        </div>
      );
    case 'mjml-button':
      return (
        <div
          id={selectedElementId}
          className={`${ELEMENT_DRAG_OVERLAY_CLASS_NAMES} d-flex`}
          style={{
            display: 'flex',
            padding: paddingStyle(selectedElement.outerPadding),
            backgroundColor: selectedElement.backgroundColor,
          }}
        >
          <button
            className={classNames('my-0 rounded border-0', ALIGNMENT_CLASSNAME)}
            style={{
              textAlign: 'initial',
              backgroundColor: selectedElement.buttonColor,
              color: selectedElement.textColor,
              padding: paddingStyle(selectedElement.innerPadding),
            }}
          >
            {renderDraftEditorContent(selectedElement.content!)}
          </button>
          <div className="EmailEditor__Element_Identifier">
            Button
            <span>
              <DragHandle selected={true} />
            </span>
          </div>
        </div>
      );
    case 'mjml-spacer':
      return (
        <div
          id={selectedElementId}
          style={{
            padding: paddingStyle(selectedElement.padding),
            height: `${selectedElement.height}`,
            backgroundColor: `${selectedElement.backgroundColor}`,
          }}
          className={ELEMENT_DRAG_OVERLAY_CLASS_NAMES}
        >
          <div className="EmailEditor__Element_Identifier">
            Spacer
            <span>
              <DragHandle selected={true} />
            </span>
          </div>
        </div>
      );
    case 'mjml-image':
      return (
        <div id={selectedElementId} className={ELEMENT_DRAG_OVERLAY_CLASS_NAMES}>
          <img
            style={{
              margin: `0 ${selectedElement.alignment === 'right' ? 0 : 'auto'} 0 ${
                selectedElement.alignment === 'left' ? 0 : 'auto'
              }`,
              maxWidth: '100%',
              padding: paddingStyle(selectedElement.padding),
            }}
            className={classNames('d-block pe-none my-0', ALIGNMENT_CLASSNAME)}
            height={selectedElement.height}
            width={selectedElement.width}
            src={selectedElement.url || imagePlaceholder}
            alt={selectedElement.altText}
          />
          <div className="EmailEditor__Element_Identifier">
            Image
            <span>
              <DragHandle selected={true} />
            </span>
          </div>
        </div>
      );
    default:
      return <>Not yet detected element</>;
  }
};

function renderDraftEditorContent(content: Paragraph[]): JSX.Element[] {
  const allLeafNodes = flatten(content.map(paragraph => [...paragraph.children]));

  const totalContentBlock = last(allLeafNodes)!.paragraphIndex + 1;

  const renderedContent = Array(totalContentBlock)
    .fill(0)
    .map((_, index) => {
      const allLeafNodesOfThisBlock = filter((leafNode: LeafNode) => {
        return leafNode.paragraphIndex === index;
      }, allLeafNodes);

      return (
        <div key={index} style={{ marginLeft: '0.8px', whiteSpace: 'nowrap' }} className="text-break">
          {renderBlockContent(allLeafNodesOfThisBlock)}
        </div>
      );
    });

  return renderedContent;
}

function renderBlockContent(leafNodes: LeafNode[]): JSX.Element[] {
  const content = leafNodes.map((leaf, index) => {
    switch (leaf.type) {
      case 'text':
        if (!leaf.text) {
          return <div key={index}>&#8205;</div>;
        }

        if (leaf.url)
          return (
            <span
              key={index}
              style={{
                color: 'var(--tz-blue-700)',
              }}
              className={classNames(
                'm-0',
                'text-decoration-underline',
                { 'fw-bold': leaf.bold },
                { 'fst-italic': leaf.italic }
              )}
            >
              {leaf.text}
            </span>
          );

        return (
          <span
            key={index}
            style={{ whiteSpace: 'pre-wrap' }}
            className={classNames(
              'm-0',
              { 'fw-bold': leaf.bold },
              { 'fst-italic': leaf.italic },
              { 'text-decoration-underline': leaf.underline }
            )}
          >
            {leaf.text}
          </span>
        );

      case 'variable':
        return (
          <span
            key={index}
            className={classNames(
              'm-0',
              'DraftEditor__VariableSpan',
              { 'fw-bold': leaf.bold },
              { 'fst-italic': leaf.italic },
              { 'text-decoration-underline': leaf.underline }
            )}
            style={{}}
          >
            {leaf.name}
          </span>
        );

      default:
        return <div />;
    }
  });

  return content;
}

import type { FC, PropsWithChildren } from 'react';

import { PlainText } from '../utilities';

import Row from './Row';

interface Props {
  label: string;
  helpText: string | JSX.Element;
}

const PlainTextRow: FC<PropsWithChildren<Props>> = ({ children, helpText, label }) => {
  return (
    <Row label={label} helpText={helpText}>
      <PlainText>{children}</PlainText>
    </Row>
  );
};

export default PlainTextRow;

import type { FC } from 'react';

import Badge, { type BadgeColor } from '@/ui/Badge';

import { capitalize } from '@shared/text';

const TEMPLATE_TYPES = { cancellation: 'red', confirmation: 'green', notification: 'blue', reminder: 'yellow' };

interface Props {
  templateType: string;
}

const TemplateBadge: FC<Props> = ({ templateType }) => {
  let badgeColor: BadgeColor = 'sky';

  if (templateType in TEMPLATE_TYPES) {
    badgeColor = TEMPLATE_TYPES[templateType];
  }

  return <Badge color={badgeColor}>{capitalize(templateType)}</Badge>;
};

export default TemplateBadge;

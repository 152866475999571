import type { FC } from 'react';

import { getCSRFToken } from './utilities';

interface Props {
  site: string;
  buttonText: string;
  url: string;
  disabled: boolean;
}

const onChargebeeSuccess = () => {
  window.location.href = '/settings/account?refresh=true';
};

const CheckoutExisting: FC<Props> = ({ site, buttonText, url, disabled }) => {
  const openChargebeeHostedPage = async () => {
    const resp = await fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
    });

    return resp.json();
  };

  const handleClick = (): void => {
    (window as any).Chargebee.init({ site: site }).openCheckout({
      hostedPage: openChargebeeHostedPage,
      success: onChargebeeSuccess,
    });
  };

  return (
    <button className="btn btn-primary" disabled={disabled} onClick={handleClick}>
      {buttonText}
    </button>
  );
};

export default CheckoutExisting;

import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';

import type AppointmentTypeModel from '@models/AppointmentType';
import RadioButtonRow from '@ui/RadioButtonRow';
import { hasIntegration } from '@shared/utilities';

interface Props {
  appointmentType: AppointmentTypeModel;
}

const TechnicianConfirmationEmailSelect: FC<Props> = ({ appointmentType }) => {
  const { integrations } = useContext(AccountContext);

  const helpText = (
    <>
      <p className="mb-1">
        This setting controls the confirmation email which TimeZest sends to the scheduled user, after a client has
        selected an appointment time.
      </p>
      {hasIntegration(integrations, 'connect_wise') && (
        <p className="mb-0">
          We advise not having an attached calendar invite if you use calendar sync between Outlook and ConnectWise
          Manage, to avoid appointments appearing twice.
        </p>
      )}
    </>
  );

  return (
    <RadioButtonRow
      name="appointment_type[send_technician_confirmation]"
      value={appointmentType.sendTechnicianConfirmation}
      label="Technician Confirmation Email"
      helpText={helpText}
      options={[
        {
          label: (
            <>
              Send the scheduled user(s) a confirmation email <strong>with</strong> a calendar invite attached
            </>
          ),
          value: 'yes',
        },
        {
          label: (
            <>
              Send the scheduled user(s) a confirmation email <strong>without</strong> a calendar invite attached
            </>
          ),
          value: 'yes_without_invite',
        },
        {
          label: <>Don&apos;t send the scheduled user a confirmation email.</>,
          value: 'no',
        },
      ]}
    />
  );
};

export default TechnicianConfirmationEmailSelect;

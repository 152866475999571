import type { FC } from 'react';
import { useState, useEffect, useCallback } from 'react';

import BoardSelect from '@/connectWise/BoardSelect';
import TeamSelect from '@/connectWise/TeamSelect';
import Form from '@/Form';

import Members from './Members';

import Row from '@ui/Row';
import type { Member } from '@models/TeamMember';

interface Props {
  boardLabel: string;
  teamLabel: string;
  createUrl: string;
  teamUrl: (boardId: number, teamId: number) => string;
  type: 'project' | 'service';
}

const NewTeam: FC<Props> = ({ boardLabel, teamLabel, createUrl, type, teamUrl }) => {
  const [boardId, setBoardId] = useState<number | undefined>();
  const [teamId, setTeamId] = useState<number | undefined>();

  const [teamLoading, setTeamLoading] = useState(false);
  const [activatedMembers, setActivatedMembers] = useState<Member[]>([]);
  const [unactivatedMembers, setUnactivatedMembers] = useState<Member[]>([]);

  const constructTeamUrl = useCallback((): string => {
    return boardId && teamId ? teamUrl(boardId, teamId) : '';
  }, [boardId, teamId, teamUrl]);

  const loadTeam = useCallback((): void => {
    setTeamLoading(true);

    fetch(constructTeamUrl(), { headers: { Accept: 'application/json' } })
      .then(response => response.json())
      .then(json => {
        const members = json.members.map(m => {
          const { active: schedulable, ...rest } = m;
          return { schedulable, ...rest };
        });

        setActivatedMembers(members.filter((m: Member) => m.schedulable));
        setUnactivatedMembers(members.filter((m: Member) => !m.schedulable));
        setTeamLoading(false);
      });
  }, [constructTeamUrl]);

  const handleBoardIdChange = (newBoardId: number | undefined): void => {
    const id = newBoardId === 0 ? undefined : newBoardId;
    setBoardId(id);
  };

  const handleTeamIdChange = (newTeamId: number | undefined): void => {
    setTeamId(newTeamId);
  };

  useEffect(() => {
    if (boardId && teamId) {
      loadTeam();
    }
  }, [boardId, loadTeam, teamId]);

  return (
    <Form url={createUrl}>
      <BoardSelect
        label={boardLabel}
        helpText=""
        name="psa_team[board_id]"
        type={type}
        selectedBoardId={boardId?.toString() || null}
        onBoardIdChange={handleBoardIdChange}
      />

      <TeamSelect
        boardId={boardId}
        name="psa_team[team_id]"
        type={type}
        selectedTeamId={teamId}
        label={teamLabel}
        onTeamIdChange={handleTeamIdChange}
      />

      <Row label="" helpText="">
        <Members
          loading={teamLoading}
          selectedTeamId={boardId ? teamId : undefined}
          activatedMembers={activatedMembers}
          unactivatedMembers={unactivatedMembers}
        />
      </Row>
    </Form>
  );
};

export default NewTeam;

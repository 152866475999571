import type { FC } from 'react';

import Row from './Row';

import type { InputProps, NumberInputProps } from '@models/InputControl';
import InputGroup from '@shared/ui/Inputs/InputGroup';

interface Props extends InputProps {
  label: string;
  width?: number;
  helpText?: string | JSX.Element;
  prepend?: string | JSX.Element;
  append?: string | JSX.Element;
}

const InputGroupRow: FC<Props & NumberInputProps> = props => {
  const { helpText, label, width, ...rest } = props;

  return (
    <Row label={label} labelFor={props.name} width={width} helpText={helpText}>
      <InputGroup {...rest} />
    </Row>
  );
};

export default InputGroupRow;

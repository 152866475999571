import type { FC } from 'react';

import DateSelectControl from '@shared/ui/DateSelect';

interface Props {
  index: number;
  value: string;
  onValueChange: (newValue: string) => void;
}

const DateSelect: FC<Props> = ({ index, value, onValueChange }) => {
  const handleDateChange = () => {
    onValueChange(document.getElementsByName(`filter-date-selector-${index}`)[0]['value']);
  };

  return (
    <DateSelectControl
      name={`filter-date-selector-${index}`}
      calendarType="gregory"
      disabled={false}
      value={value ? new Date(value) : null}
      minDetail="year"
      formatCalendarTitle={formatCalendarTitle}
      formatDisplayDate={formatDisplayDate}
      formatWeekday={formatWeekday}
      handleDateChange={handleDateChange}
    />
  );
};

export default DateSelect;

function formatDisplayDate(date: Date) {
  return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' });
}

function formatCalendarTitle(date: Date, locale: string) {
  return date.toLocaleDateString(locale, { month: 'long', year: 'numeric' });
}

function formatWeekday(date: Date, locale: string) {
  return date.toLocaleDateString(locale, { weekday: 'short' });
}

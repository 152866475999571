import type { ApolloError, DocumentNode, OperationVariables, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type Reference from '@models/Reference';

export interface Result {
  data: any;
  error?: { message: string; type: string };
  loading: boolean;
}

export function convertError(error: ApolloError): { message: string; type: string } {
  return { message: error?.toString(), type: (error?.graphQLErrors || [])[0]?.extensions?.type as string };
}

export function useAutotaskQuery<T extends { autotask: any }, V extends OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<T, V>
): Result {
  const { data, error, loading } = useQuery<T, V>(query, options);

  return {
    data: data?.['autotask'],
    error: error
      ? { message: error?.toString(), type: (error?.graphQLErrors || [])[0]?.extensions?.type as string }
      : undefined,
    loading,
  };
}

export function useConnectWiseQuery<T extends { connectWise: any }, V extends OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<T, V>
): Result {
  const { data, error, loading } = useQuery<T, V>(query, options);

  return {
    data: data?.['connectWise'],
    error: error ? convertError(error) : undefined,
    loading,
  };
}

export interface AutotaskCompanyData {
  autotask: {
    company: Reference;
  };
}

export interface AutotaskCompanyVariables {
  companyId?: number;
}

export interface ConnectWiseCompanyData {
  connectWise: {
    company: Reference;
  };
}

export interface ConnectWiseCompanyVariables {
  companyId?: number;
}

export interface ConnectWiseAppointmentOptionsData {
  connectWise: {
    id: string;
    scheduleStatuses: Reference[];
    serviceLocations: Reference[];
  };
}

import { useContext, type FC } from 'react';

import Office365CalendarConnectionProgress from '@/Office365CalendarConnectionProgress';
import AccountContext from '@/AccountContext';

import { psaName } from '@shared/utilities';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

import type { IntegrationSettings, User } from '.';

interface Props {
  authUrl: string;
  integration: IntegrationSettings | null;
  integrationIsNotRejected: boolean;
  usersToDeactivate: User[];
  usersToCalendarSwitch: User[];
}

const IntegrationStatus: FC<Props> = ({
  authUrl,
  integration,
  integrationIsNotRejected,
  usersToCalendarSwitch,
  usersToDeactivate,
}) => {
  const { psaType } = useContext(AccountContext);

  if (!integration)
    return (
      <div className="d-flex">
        <div className="flex-grow-1 fw-bold pt-2">
          TimeZest has not yet been connected with your Microsoft 365 tenant.
        </div>
        <div>
          <a href={authUrl} className="btn btn-primary flex-shrink-0">
            <FontAwesomeIcon icon="sign-in-alt" />
            &nbsp; Authenticate with Microsoft 365
          </a>
        </div>
      </div>
    );

  if (integrationIsNotRejected) {
    return (
      <Office365CalendarConnectionProgress
        url="/settings/integrations/office_365_calendar"
        processing={integration.processing}
        processingProgress={integration.progress}
        psaName={psaName(psaType)}
        usersToCalendarSwitch={usersToCalendarSwitch}
        usersToDeactivate={usersToDeactivate}
      />
    );
  } else {
    return (
      <div className="d-flex">
        <div className="flex-grow-1 text-danger pt-2">
          <FontAwesomeIcon icon="times" />
          &nbsp;&nbsp;
          <span className="fw-bold">TimeZest was not able to connect with your Microsoft 365 tenant.</span>
        </div>
        <a href={authUrl} className="btn btn-primary flex-shrink-0">
          <FontAwesomeIcon icon="sign-in-alt" />
          &nbsp; Authenticate with Microsoft 365 to retry
        </a>
      </div>
    );
  }
};

export default IntegrationStatus;

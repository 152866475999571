import type { FC } from 'react';

import { getCSRFToken } from './utilities';

interface Props {
  site: string;
}

const closeChargebeeModal = () => {
  window.location.href = '/settings/account';

  // Temporarily disable all menu navigation links to prevent user going to another page during the execution window.location.href after the user closes chargebee modal
  const buttons = document.getElementsByTagName('a');

  for (const keys of Object.keys(buttons)) {
    let button = buttons[keys];

    if (button.href.includes('settings')) {
      button.style.pointerEvents = 'none';
    }
  }
};

const openChargebeeHostedPage = async () => {
  const resp = await fetch('/chargebee/update_card', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': getCSRFToken(),
    },
  });

  return resp.json();
};

const UpdateCard: FC<Props> = ({ site }) => {
  const handleClick = (): void => {
    (window as any).Chargebee.init({ site: site }).openCheckout({
      hostedPage: openChargebeeHostedPage,
      close: closeChargebeeModal,
    });
  };

  return (
    <button className="btn btn-link btn-inline text-decoration-none" onClick={handleClick}>
      Update Payment Card
    </button>
  );
};

export default UpdateCard;

import { type FC, Fragment } from 'react';

import { Action } from '@/ui/Workflow/Actions';

import type { TimeElapsedTriggerModel } from '@models/TimeElapsedTriggerModel';
import type { Trigger as TriggerModel } from '@graphql/generated';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const ICONS = {
  'autotask/appointment_requested_from_insight': 'chrome-cast',
  'autotask/appointment_url_generated_from_insight': 'attachment',
  'connect_wise/appointment_requested_from_pod': 'chrome-cast',
  'connect_wise/appointment_url_generated_from_pod': 'attachment',
  'time_zest/time_after_appointment': 'forward-time',
  'time_zest/time_before_appointment': 'backward-time',
  'time_zest/time_elapsed_without_scheduling': 'wall-clock',
  'time_zest/client_schedules_appointment': 'calendar-tick',
  'time_zest/client_reschedules_appointment': 'schedule',
  'time_zest/client_cancels_appointment': 'calendar-block',
  'time_zest/technician_cancels_appointment': 'calendar-block',
};

interface Props {
  trigger: TriggerModel | TimeElapsedTriggerModel;
  formRefSetter: (i: number) => (el: HTMLFormElement) => void;
}

const TriggerWithActions: FC<Props> = ({ trigger, formRefSetter }) => {
  return (
    <div>
      <div className="Trigger">
        <div className="Trigger__Icon">
          <i className={`budicon budicon-${ICONS[trigger.slug] || 'gear'}`} />
        </div>
        <div className="Trigger__Name d-flex">{trigger.name}</div>
      </div>
      {trigger.actions.map(action => (
        <Fragment key={action.id}>
          <div className="ActionSpacer__Main">
            <FontAwesomeIcon icon="angle-down" />
          </div>
          <Action action={action} readOnly={false} saveable={false} ref={formRefSetter(action.id)} />
        </Fragment>
      ))}
    </div>
  );
};

export default TriggerWithActions;

import type { FC } from 'react';

import TeamMember from '@/TeamMember';

import type { Member } from '@models/TeamMember';

interface Props {
  members: Member[];
  removeMember: (memberId: number) => void;
}

const Members: FC<Props> = ({ members, removeMember }) => {
  if (members.length === 0) {
    return <div className="form-control-plaintext">There are currently no users assigned to this team.</div>;
  }

  return (
    <div className="d-grid gap-3">
      {members.map(member => {
        const boundRemoveMember = removeMember.bind(null, member.id);
        return <TeamMember key={member.id} removeMember={boundRemoveMember} {...member} />;
      })}
    </div>
  );
};

export default Members;

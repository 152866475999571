import type { FC } from 'react';

import type { CreditNoteEstimatesModel } from '..';

interface Props {
  creditNoteEstimates: CreditNoteEstimatesModel;
  hasCard: boolean;
}

const CreditNoteEstimates: FC<Props> = ({ creditNoteEstimates, hasCard }) => {
  const provideCardDetails = !hasCard && creditNoteEstimates.estimateTotal === 0;

  return (
    <>
      <p>
        &mdash; You will receive a credit of
        <strong>
          US
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(creditNoteEstimates.estimates)}
        </strong>
        against your next invoice(s).
      </p>
      {provideCardDetails && (
        <p>However, you will need to provide card details for a credit card to be billed for future renewals.</p>
      )}
    </>
  );
};

export default CreditNoteEstimates;

import type { FC } from 'react';

import Flash from '@/Flash';
import Header from '@/Header';
import asScreen from '@/Screen';

import CustomAttributesList from './CustomAttributesList';

import type { CustomAttribute } from '@models/CustomAttribute';

interface Props {
  customAttributes: CustomAttribute[];
}

const CustomAttributes: FC<Props> = ({ customAttributes }) => {
  return (
    <>
      <Header
        title="Custom Attributes"
        headerButton={{
          url: '/settings/custom_attributes/new',
          icon: 'plus',
          color: 'blue',
          text: 'New Custom Attribute',
        }}
      />

      <Flash />

      <div className="py-0">
        {customAttributes.length === 0 ? (
          <>
            <p className="fw-bold">No Custom Attributes have been defined.</p>
            <p>
              Custom Attributes allow you to define attributes which can be included in confirmation email templates.
              Every user in your organisation can have different values for each attribute.
            </p>
            <p>
              You could use them to include information like the location or job title of your users. If you use a
              video-conferencing solution like Zoom or WebEx, you could use custom attributes to include the URL of a
              meeting room in your emails to clients.
            </p>
          </>
        ) : (
          <CustomAttributesList customAttributes={customAttributes} />
        )}
      </div>
    </>
  );
};

export default asScreen(CustomAttributes);

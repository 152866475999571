import { useContext, type FC } from 'react';

import { displayAvailability } from '@/utilities';
import AccountContext from '@/AccountContext';

import type { AvailabilityBlock } from '@models/Availability';
import type AvailabilityModel from '@models/Availability';
import { psaName } from '@shared/utilities';

interface Props {
  availability: AvailabilityModel;
  availabilityBlocks: AvailabilityBlock[];
  availabilityAllowed: boolean;
}

const UserAvailabilitySummary: FC<Props> = ({ availability, availabilityBlocks, availabilityAllowed }) => {
  const { psaType } = useContext(AccountContext);

  let summary = '';

  if (availability.usesCustomAvailability) {
    const availabilityText = displayAvailability(availabilityBlocks, availabilityAllowed);
    summary = availabilityText
      ? `Custom availability: ${availabilityText}`
      : 'Custom Availability: not available to be scheduled.';
  } else {
    summary = `Using availability configured in ${psaName(psaType)}.`;
  }

  if (availability.usesCustomTimezone) {
    summary = summary + `Custom time zone: ${availability.customTimezone}`;
  }

  return <>{summary}</>;
};

export default UserAvailabilitySummary;

import type { FC } from 'react';
import { useRef } from 'react';

import type { TimeElapsedTriggerModel } from '@/models';

import type { Unit } from '@ui/Duration';
import Duration from '@ui/Duration';

const TIME_UNITS: Unit[] = ['minutes', 'hours', 'days'];

interface Props {
  trigger: TimeElapsedTriggerModel;
  error: string | null;
  onCancel?: () => void;
  onSubmit?: (value: { timeMins: number }) => void;
}

const Form: FC<Props> = ({ error, trigger, onCancel, onSubmit }) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleCancelClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (onCancel) onCancel();
  };

  const handleSubmitClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!formRef.current) return;

    const data = new FormData(formRef.current);

    if (onSubmit) onSubmit({ timeMins: Number(data.get('trigger[timeMins]') || 0) });
  };

  return (
    <>
      <form className="Trigger__Form d-flex align-items-center gap-1" ref={formRef}>
        <div>{prefix(trigger.slug)}</div>
        <Duration
          className="w-auto"
          name="trigger[timeMins]"
          initialValue={trigger.timeMins || 0}
          unit="minutes"
          units={TIME_UNITS}
          error=""
        />
        <div>{suffix(trigger.slug)}:</div>
        <div>
          <button className="btn btn-sm btn-primary" onClick={handleSubmitClick}>
            Save
          </button>
          <button className="btn btn-sm btn-link" onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      </form>
      {error && <p className="mb-0 form-text text-danger">{error}</p>}
    </>
  );
};

function prefix(type: string): string {
  switch (type) {
    case 'time_zest/time_after_appointment':
      return 'At';
    case 'time_zest/time_before_appointment':
      return 'At';
    case 'time_zest/time_elapsed_without_scheduling':
      return 'When';
    default:
      throw Error(`${type} is not supported`);
  }
}

function suffix(type: string): string {
  switch (type) {
    case 'time_zest/time_after_appointment':
      return 'after the end of the appointment';
    case 'time_zest/time_before_appointment':
      return 'before the start of the appointment';
    case 'time_zest/time_elapsed_without_scheduling':
      return 'has elapsed without the client scheduling an appointment';
    default:
      throw Error(`${type} is not supported`);
  }
}

export default Form;

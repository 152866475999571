import { forwardRef, useContext, useEffect, useState } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';
import Row from '@/ui/Row';
import RadioButtonRow from '@/ui/RadioButtonRow';

import { useCreateTicketForHaloPsa } from '../hooks';
import FormError from '../FormError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';
import type { Content as LineContent } from '@shared/LineEditor';
import LineEditor, { defaultTemplate as defaultLineTemplate } from '@shared/LineEditor';
import type { HaloPsaCreateTicketAction } from '@graphql/generated';
import { useGetTicketTypesForHaloPsaQuery } from '@graphql/generated';
import Alert from '@shared/Alert';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a ticket in HaloPSA, and associate it with the scheduling
      request
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following case:</p>
    <ul className="mb-3">
      <li>When no HaloPSA integration is configured.</li>
    </ul>
  </>
);

const CreateTicket = forwardRef<HTMLFormElement, WorkflowActionProps<HaloPsaCreateTicketAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const [showAlert, setShowAlert] = useState(false);

    const queryResult = useGetTicketTypesForHaloPsaQuery({ skip: templateMode });
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateTicketForHaloPsa();

    const [ticketTypeId, setTicketTypeId] = useStateFromProp(action['haloPsaTicketType']);

    const ticketTypes = queryResult.data?.haloPsa.ticketTypes || [];
    const selectedTicketType = ticketTypes.find(t => t.id.toString() === ticketTypeId) || ticketTypes[0];

    useEffect(() => {
      if (!queryResult.loading) {
        setShowAlert(selectedTicketType?.hasDefaultValues === false);
      }
    }, [ticketTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeTicketType = (value: string) => {
      if (value !== ticketTypeId) {
        setTicketTypeId(value);
      }
    };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        createAlways: data.get('create_always') === 'true',
        psaTypeId: (selectedTicketType?.id.toString() || '') as string,
        psaTypeName: (selectedTicketType?.name || '') as string,
        summary: (data.get('summary') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="task-plus"
            summary={
              <>
                Create a <strong>ticket</strong> in HaloPSA
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
            disabled={!selectedTicketType?.hasDefaultValues}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="create_always"
              value={(action.createAlways || false).toString()}
              label="Creation Mode"
              readOnly={readOnly}
              options={[
                { label: 'Always create a new service ticket.', value: 'true', disabled: readOnly },
                {
                  label: "Create a service ticket only when one doesn't exist.",
                  value: 'false',
                  disabled: readOnly,
                },
              ]}
              error={errors.create_always}
            />

            <SelectRowFromGraphQL
              loadingMessage="Loading from HaloPSA..."
              name="psa_type_id"
              label="Ticket Type"
              emptyDataMessage="No ticket types found."
              field="haloPsa.ticketTypes"
              helpText="TimeZest will create a new ticket in HaloPSA with this ticket type."
              queryErrorMessage="Error loading ticket types from HaloPSA."
              readOnly={readOnly}
              templateMode={templateMode}
              error={errors.psa_type_id || errors.psa_type_name}
              value={ticketTypeId}
              queryResult={queryResult}
              onChange={handleChangeTicketType}
            />

            <input type="hidden" value={selectedTicketType?.name || ''} name="psa_type_name" />

            {showAlert && (
              <Row label="" width={6}>
                <Alert
                  icon="triangle-exclamation"
                  color="yellow"
                  className="mt-0"
                  content={
                    <>
                      <p>
                        TimeZest won&apos;t be able to create a ticket of this type, as the ticket type is configured in
                        HaloPSA to require values for certain fields which TimeZest is unable to supply.
                      </p>
                      <p className="mb-0">
                        To make this ticket type usable with TimeZest, change the fields so that they are optional, or
                        have a default value.
                      </p>
                    </>
                  }
                />
              </Row>
            )}

            <Row label="Summary" helpText="TimeZest will create a new ticket in HaloPSA with this summary.">
              <LineEditor
                name="summary"
                initialValue={(action.summary || defaultLineTemplate) as LineContent}
                readOnly={readOnly}
                error={errors.summary}
              />
            </Row>
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateTicket;

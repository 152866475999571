import type { FC } from 'react';

import Form from '@/Form';
import CopyAppointmentType from '@/CopyAppointmentType';
import asScreen from '@/Screen';
import TextInputRow from '@/ui/TextInputRow';
import Row from '@/ui/Row';

interface Props {
  appointmentType: {
    internalName: string | null;
    externalName: string | null;
  };
  appointmentTypes: { name: string; id: string; durationMins: number }[];
  copyFromExistingId: string;
  errors: Record<string, string>;
  workflowTemplateId: string;
  workflowTemplates: { name: string; id: string; internal: boolean }[];
  submitPath: string;
}

const NewAppointmentType: FC<Props> = ({
  appointmentType,
  appointmentTypes,
  copyFromExistingId,
  errors,
  workflowTemplateId,
  workflowTemplates,
  submitPath,
}) => {
  return (
    <Form url={submitPath}>
      <TextInputRow
        name="appointment_type[internal_name]"
        value={appointmentType.internalName}
        label="Internal Name"
        helpText="The name of this appointment type we'll use when communicating with your clients. If left blank, TimeZest will use the internal name."
        error={errors?.internalName}
      />
      <TextInputRow
        name="appointment_type[external_name]"
        value={appointmentType.externalName}
        label="External Name"
        helpText="If set, this name is used with clients, during the scheduling process, and in emails sent to them."
        error={errors?.externalName}
      />
      <CopyAppointmentType
        appointmentTypes={appointmentTypes}
        copyFromExistingId={copyFromExistingId}
        workflowTemplateId={workflowTemplateId}
        workflowTemplates={workflowTemplates}
      />

      <hr />

      <Row label="">
        <input
          className="btn btn-primary"
          type="submit"
          value="Create Appointment Type"
          data-disable-with="Submitting"
        />
      </Row>
    </Form>
  );
};

export default asScreen(NewAppointmentType);

import { forwardRef, useContext, useMemo, useState } from 'react';

import LocationAndStatusRow from '@/ui/LocationAndStatusRow';

import FormError from '../FormError';
import { useCreateScheduleEntryForConnectWise } from '../hooks';

import ReminderRow from './ReminderRow';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { ConnectWiseCreateScheduleEntryAction } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a schedule entry in ConnectWise PSA <strong>only</strong> for
      those users who have ConnectWise PSA configured as their calendar to write to.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>When the scheduling request has been cancelled.</li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
    <p>
      This is a <strong>critical action</strong>, which means that if it doesn&apos;t complete successfully, no actions
      for this trigger after it will be executed.
    </p>
  </>
);

const CreateScheduleEntry = forwardRef<HTMLFormElement, WorkflowActionProps<ConnectWiseCreateScheduleEntryAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const [formEnabled, setFormEnabled] = useState(false);
    const [psaLocationName, setPsaLocationName] = useStateFromProp(action.psaServiceLocationName);
    const [psaStatusName, setPsaStatusName] = useStateFromProp(action.psaScheduleStatusName);
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateScheduleEntryForConnectWise();
    const handleOptionsLoaded = useMemo(() => {
      return () => setFormEnabled(true);
    }, [setFormEnabled]);

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      const reminder = data.get('psa_reminder_id');

      return {
        locationId: (data.get('psa_service_location_id') || '') as string,
        locationName: psaLocationName || '',
        reminderId: reminder ? (reminder as string) : null,
        statusId: (data.get('psa_schedule_status_id') || '') as string,
        statusName: psaStatusName || '',
      };
    });

    const handleLocationChange = (value: string | undefined, name: string | undefined): void => {
      setPsaLocationName(name || '');
    };

    const handleStatusChange = (value: string | undefined, name: string | undefined): void => {
      setPsaStatusName(name || '');
    };

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="calendar-plus"
            summary={
              <>
                Create a <strong>schedule entry</strong> in the scheduled user&apos;s ConnectWise PSA calendar.
              </>
            }
            readOnly={readOnly || !formEnabled}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <LocationAndStatusRow
              readOnly={readOnly}
              locationError={errors.psa_service_location_id}
              locationFieldName="psa_service_location_id"
              statusError={errors.psa_schedule_status_id}
              statusFieldName="psa_schedule_status_id"
              selectedScheduleStatusId={action.psaScheduleStatusId || null}
              selectedServiceLocationId={action.psaServiceLocationId || null}
              templateMode={templateMode}
              onLoaded={handleOptionsLoaded}
              onLocationChange={handleLocationChange}
              onStatusChange={handleStatusChange}
            />

            <ReminderRow
              readOnly={readOnly}
              error={errors.scheduleEntryReminderId}
              value={action.psaReminderId}
              templateMode={templateMode}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateScheduleEntry;

// DEPRECATED
import type { FC } from 'react';
import { useState } from 'react';

import copy from 'copy-to-clipboard';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  text: string;
}

const CopyToClipboard: FC<Props> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (event: React.MouseEvent) => {
    event.preventDefault();
    copy(text);
    setCopied(true);
    window.setTimeout(() => setCopied(false), 3000);
  };

  return (
    <span className="position-relative d-inline-block fs-6">
      <FontAwesomeIcon icon="copy" className="text-muted" style={{ cursor: 'pointer' }} onClick={handleCopy} />
      &nbsp;&nbsp;
      {copied && <span className="position-absolute badge bg-light text-dark bottom-0">Copied to Clipboard</span>}
    </span>
  );
};

export default CopyToClipboard;

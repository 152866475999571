import { compact } from './text';

interface TimeProps {
  date?: Date | null;
  hours?: number | null;
  minutes?: number | null;
}

export function shortDate(date: Date): string {
  return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
}

export function shortTime(date: Date): string {
  const time = date.toLocaleTimeString('en-US', { hourCycle: 'h12', hour: 'numeric', minute: '2-digit' });
  return compact(time.toLowerCase());
}

export default class Time {
  static regex = /^(\d{1,2})(:?(\d{1,2}))?\s*(p?)/i;
  static parse(string: string): Time | null {
    if (string.trim() === '') return null;

    const parsed = string.match(Time.regex);
    if (!parsed) return new Time({ date: new Date(NaN) });

    let hours = parseInt(parsed[1], 10);
    hours += hours < 12 && parsed[4] ? 12 : 0;

    return new Time({ hours: hours, minutes: parseInt(parsed[3], 10) || 0 });
  }

  private value: Date;

  constructor({ date = null, hours = null, minutes = null }: TimeProps = {}) {
    const value = date ? new Date(date.valueOf()) : new Date();

    if (hours !== null) value.setHours(hours);
    if (minutes !== null) value.setMinutes(minutes);

    value.setSeconds(0, 0);

    this.value = value;
  }

  isValid(): boolean {
    return !Number.isNaN(this.value.valueOf());
  }

  isAfter(time: Time): boolean {
    const d1 = new Date();
    const d2 = new Date();

    d1.setHours(this.value.getHours(), this.value.getMinutes(), 0, 0);
    d2.setHours(time.value.getHours(), time.value.getMinutes(), 0, 0);

    return d1 > d2;
  }

  toString(): string {
    return this.isValid() ? this.value.toLocaleTimeString('en-GB').replace(/:\d\d$/, '') : '';
  }

  dayInWeek(): number {
    return this.value.getDay();
  }
}

export const getDateDaysBeforeNow = (days: number) => new Date(Date.now() - days * 24 * 60 * 60 * 1000);

import type { FC } from 'react';

import type { PsaType } from '@models/Integration';

interface Props {
  integrations: PsaType[];
}

const StatusConfigurationLink: FC<Props> = ({ integrations }) => {
  if (integrations.includes('autotask')) {
    return <a href="/settings/integrations/autotask">Preset Statuses</a>;
  }

  if (integrations.includes('connect_wise')) {
    return <a href="/settings/integrations/connect_wise">Preset Statuses</a>;
  }

  if (integrations.includes('halo_psa')) {
    return <a href="/settings/integrations/halo_psa">Preset Statuses</a>;
  }
};

export default StatusConfigurationLink;

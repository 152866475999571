import 'regenerator-runtime/runtime';
import bsCustomFileInput from 'bs-custom-file-input';
import ReactRailsUJS from 'react_ujs';

import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import '@shared/sentry';

import '../utils/i18n';
import { client } from '../shared/apollo';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

TimeAgo.addDefaultLocale(en);

window.apolloClient = client;

// Support component names relative to this directory:
import * as Components from '../admin_ui/**/[[:alnum:]]+.tsx';
const components = Components.filenames.reduce((manifest, fileName, i) => {
  const name = fileName
    .replace('../admin_ui/', '')
    .replace(/\.tsx?$/, '')
    .replace(/\/index$/, '');
  manifest[name] = Components.default[i].default;
  return manifest;
}, {});
ReactRailsUJS.getConstructor = className => components[className];

document.addEventListener('turbolinks:load', () => {
  bsCustomFileInput.init();

  if (document.querySelector('.cf-turnstile') && window.turnstile) {
    window.turnstile.render('.cf-turnstile');
  }
});

document.addEventListener('turbolinks:before-cache', () => {
  if (window.turnstile && typeof window.turnstile.remove === 'function') {
    window.turnstile.remove();
  }
});

import { useContext } from 'react';

import { CompositeDecorator } from 'draft-js';

import { LinkColorContext } from '../Blocks/MjmlText';

import { getEntityStrategy } from './util';

const VariableSpan = ({ offsetkey, children }) => {
  return (
    <span data-offset-key={offsetkey} className="DraftEditor__VariableSpan">
      {children}
    </span>
  );
};

const LinkSpan = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  const { linkColor } = useContext(LinkColorContext);

  return (
    <a href={url} className="text-decoration-underline" style={{ color: linkColor || '#0082f9' }}>
      {children}
    </a>
  );
};

export const decorators = new CompositeDecorator([
  { strategy: getEntityStrategy('LINK'), component: LinkSpan },
  { strategy: getEntityStrategy('{mention'), component: VariableSpan },
]);

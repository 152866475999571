import type { FC } from 'react';

import Action from '@shared/Action';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <p>
    When TimeZest executes this action, it will mark the scheduling request as closed, and clients will no longer be
    able to use it to schedule appointments, or to reschedule or cancel existing appointments.
  </p>
);

const CloseSchedulingRequest: FC<WorkflowActionProps> = ({ action, readOnly }) => (
  <ErrorBoundary>
    <Action action={action} details={DETAILS} icon="lock" summary="Close Scheduling Request" readOnly={readOnly}>
      <p>
        Close scheduling request and prevent scheduling new appointments for it, or rescheduling/cancellation of
        existing appointments.
      </p>
    </Action>
  </ErrorBoundary>
);

export default CloseSchedulingRequest;

import unset from 'lodash/fp/unset';

import type { TemplateState } from './templateReducer';
import { getElementFromIndex } from './utilities';

const deleteProperty: (state: TemplateState, elementId: string, property: string) => TemplateState = (
  state,
  elementId,
  property
) => {
  const element = getElementFromIndex(state, elementId);

  const properties = unset(property, element);
  return { ...state, index: { ...state.index, [elementId]: properties } };
};

export default deleteProperty;

import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityRatingsQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const RATING_HELP_TEXT = 'TimeZest will create the opportunity in ConnectWise PSA with this rating.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  ratingId: string;
  templateMode?: boolean;
}

const RatingSelect: FC<Props> = ({ ratingId: initialRatingId, error, readOnly, templateMode }) => {
  const [ratingId, setRatingId] = useStateFromProp(initialRatingId);

  const queryResult = useGetOpportunityRatingsQuery({ skip: templateMode });

  const rating = (queryResult.data?.connectWise.opportunityRatings || []).find(r => r.id.toString() === ratingId);

  const handleRatingChange = (value: string) => {
    if (value !== ratingId) {
      setRatingId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      label="Rating"
      helpText={RATING_HELP_TEXT}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity rating details from ConnectWise PSA because the security role does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the <strong>Table Setup</strong> permission in the <strong>System</strong> section of the
          security role has <strong>Opportunities / Rating</strong> in the <strong>Allow Access to These</strong>{' '}
          column.
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity ratings from ConnectWise PSA."
    />
  );

  return (
    <>
      <SelectRowFromGraphQL
        helpText={RATING_HELP_TEXT}
        label="Rating"
        name="rating_id"
        field="connectWise.opportunityRatings"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Use default value configured in ConnectWise PSA"
        emptyDataMessage="No opportunity ratings found."
        templateMode={templateMode}
        readOnly={readOnly}
        error={error}
        value={ratingId}
        queryErrorMessage={queryErrorMessage}
        queryResult={queryResult}
        onChange={handleRatingChange}
      />

      <input type="hidden" name="rating_name" value={rating?.name || ''} />
    </>
  );
};

export default RatingSelect;

import { EditorState, SelectionState } from 'draft-js';

import type { EditorPlugin, PluginFunctions } from '@draft-js-plugins/editor';

export const atomicEntityPlugin: EditorPlugin = {
  // Modify the selection so that it always selects a complete entity, not a partial
  // part of one, and also treats the entity as a single character that can't have
  // text added to it internally.
  onChange: (editorState: EditorState, _pluginFunctions: PluginFunctions) => {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();

    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();

    const focusKey = selection.getFocusKey();
    const focusOffset = selection.getFocusOffset();

    const isBackward = selection.getIsBackward();

    let newAnchorOffset = selection.getAnchorOffset();
    let newFocusOffset = selection.getFocusOffset();

    const anchorBlock = content.getBlockForKey(anchorKey);
    const anchorEntityKey = anchorBlock.getEntityAt(anchorOffset);

    if (anchorKey === focusKey && anchorOffset === focusOffset) {
      // Single point caret
      if (anchorEntityKey) {
        anchorBlock.findEntityRanges(
          character => character.getEntity() === anchorEntityKey,
          (start, end) => {
            if (anchorOffset === start) {
              newAnchorOffset = start;
              newFocusOffset = start;
            } else {
              newAnchorOffset = start;
              newFocusOffset = end;
            }
          }
        );
      }
    } else {
      // Multiple character selection
      if (anchorEntityKey) {
        anchorBlock.findEntityRanges(
          character => character.getEntity() === anchorEntityKey,
          (start, end) => {
            if (isBackward) {
              newAnchorOffset = end;
            } else {
              newAnchorOffset = start;
            }
          }
        );
      }

      const focusBlock = content.getBlockForKey(focusKey);
      const focusEntityKey = focusBlock.getEntityAt(focusOffset);

      if (focusEntityKey) {
        focusBlock.findEntityRanges(
          character => character.getEntity() === focusEntityKey,
          (start, end) => {
            if (isBackward) {
              newFocusOffset = start;
            } else {
              newFocusOffset = end;
            }
          }
        );
      }
    }

    if (newAnchorOffset !== anchorOffset || newFocusOffset !== focusOffset) {
      const newSelection = SelectionState.createEmpty(selection.getAnchorKey()).merge({
        anchorKey,
        anchorOffset: newAnchorOffset,
        focusKey,
        focusOffset: newFocusOffset,
        isBackward: isBackward,
        hasFocus: selection.getHasFocus(),
      });

      return EditorState.forceSelection(editorState, newSelection);
    } else {
      return editorState;
    }
  },

  // Ignore the drop operation when dragging some text, or something from outside the editor
  // onto an entity, as that would split the entity.
  handleDrop: (dropSelection, _data, _dragType, pluginFunctions) => {
    const anchorKey = dropSelection.getAnchorKey();
    const anchorOffset = dropSelection.getAnchorOffset();
    const content = pluginFunctions.getEditorState().getCurrentContent();

    const anchorBlock = content.getBlockForKey(anchorKey);
    const anchorEntityKey = anchorBlock.getEntityAt(anchorOffset);

    return anchorEntityKey ? 'handled' : 'not-handled';
  },
};

// DEPRECATED

import type { FC } from 'react';

import { ErrorMessage } from '@/ui/ErrorMessage';
import Row from '@/ui/Row';
import { PlainText } from '@/utilities';

interface Props {
  label: string;
  helpText: string;
  error: string | JSX.Element;
  type?: string | undefined;
  unauthorizedError?: string | JSX.Element;
  unauthorizedRemediation?: string | JSX.Element;
  width?: number;
}

const RowError: FC<Props> = ({ label, helpText, type, unauthorizedError, unauthorizedRemediation, error, width }) => {
  if (!type || type !== 'unauthorized') {
    return (
      <Row helpText={helpText} label={label} width={width}>
        <PlainText>
          <p className="text-danger mb-0">{error}</p>
        </PlainText>
      </Row>
    );
  }

  return (
    <Row helpText={helpText} label={label} width={width}>
      <ErrorMessage>
        <p className="mb-0">{unauthorizedError}</p>

        <p className="mb-0 mt-3">{unauthorizedRemediation}</p>
      </ErrorMessage>
    </Row>
  );
};

export default RowError;

import { useContext, type FC } from 'react';

import Alert from '@/Alert';

import type { Action } from '@graphql/generated';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

interface Props {
  action: Action;
  errors: Record<string, string>;
}

const FormError: FC<Props> = ({ action, errors }) => {
  const { actionReviewable } = useContext(WorkflowActionContext);

  if (Object.keys(errors).length === 0 || actionReviewable) return null;

  return (
    <Alert
      icon="circle-xmark"
      color="red"
      title={
        <>
          TimeZest was unable to {action.id > 0 ? 'update' : 'create'} this action because&nbsp;
          {errors.server ? (
            <>it encountered an error when saving this action. Please contact support.</>
          ) : (
            <>of the following errors:</>
          )}
        </>
      }
      className="mb-4"
    />
  );
};

export default FormError;

import type {
  Action,
  TeamsSendMessageToScheduledUsersAction,
  TeamsSendMessageToChannelAction,
} from '@graphql/generated';

export function isTeamsSendMessageToChannelAction(action: Action): action is TeamsSendMessageToChannelAction {
  return action.slug === 'teams_messaging/send_message_to_channel';
}

export function isTeamsSendMessageToScheduledUsers(action: Action): action is TeamsSendMessageToScheduledUsersAction {
  return action.slug === 'teams_messaging/send_message_to_scheduled_users';
}

import type { FC, PropsWithChildren } from 'react';

import Alert from '@/Alert';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  className?: string;
}

export const ErrorMessage: FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <Alert
    icon={null}
    color="red"
    className={className || 'm-0'}
    content={
      <div className="d-flex align-items-start">
        <FontAwesomeIcon className="me-2" icon="circle-xmark" color="#dc3545" size="lg" />
        <span>{children}</span>
      </div>
    }
  />
);

import type { FC } from 'react';
import { StrictMode, useMemo } from 'react';

import WorkflowComponent from '@/ui/Workflow';
import asScreen from '@/Screen';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, concat } from '@apollo/client';
import type VariableModel from '@models/Variable';
import type { TimeElapsedTriggerModel } from '@models/TimeElapsedTriggerModel';
import type { Trigger as TriggerModel } from '@graphql/generated';
interface Props {
  availableLanguages: [string, string][];
  availableVariables: VariableModel[];
  appointmentTypeId: number;
  accountHeader: string;
  accountId: number;
  logoUrl: string | null;
  triggers: (TriggerModel | TimeElapsedTriggerModel)[];
  graphqlUrl?: string;
}

const Workflow: FC<Props> = ({
  availableLanguages,
  availableVariables,
  appointmentTypeId,
  accountHeader,
  accountId,
  logoUrl,
  triggers,
  graphqlUrl,
}) => {
  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: concat(accountMiddleware(accountId, accountHeader), new HttpLink({ uri: graphqlUrl })),
    });
  }, [accountHeader, accountId, graphqlUrl]);

  return (
    <StrictMode>
      <ApolloProvider client={client}>
        <WorkflowComponent
          appointmentTypeId={appointmentTypeId}
          readOnly={true}
          triggers={triggers}
          availableActionOptions={[]}
          availableTriggerOptions={[]}
          availableLanguages={availableLanguages}
          availableVariables={availableVariables}
          logoUrl={logoUrl}
        />
      </ApolloProvider>
    </StrictMode>
  );
};

const accountMiddleware = (accountId: number, accountHeader: string) =>
  new ApolloLink((operation, forward) => {
    operation.setContext((context: Record<string, any>) => {
      return {
        headers: { ...context.headers, [accountHeader]: accountId },
      };
    });
    return forward(operation);
  });

export default asScreen(Workflow);

import type { FC } from 'react';

import classNames from 'classnames';

import Badge from '@ui/Badge';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type { Member } from '@models/TeamMember';

interface Props extends Member {
  removeMember: () => void;
}

const TeamMember: FC<Props> = ({ email, schedulable, name, removeMember }) => {
  const handleRemoveClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    removeMember();
  };

  return (
    <div className={classNames('card')}>
      <div className="card-body py-2">
        <div className="d-flex">
          <div className="fw-bold flex-grow-1">
            {name}
            &nbsp;
            {schedulable && <Badge color="green">Active</Badge>}
          </div>
          <button type="button" className="btn btn-link m-0 p-0 d-inline-flex" onClick={handleRemoveClick}>
            <span className="small">Remove</span>
          </button>
        </div>
        <div className="small mb-1">{email}</div>
        {!schedulable && (
          <div className="flex-grow-1 text-danger text-right small">
            <FontAwesomeIcon icon="exclamation-triangle" />
            &nbsp;
            <span>This team member is not activated in TimeZest, so cannot be scheduled.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMember;

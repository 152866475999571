import type { FC } from 'react';
import { useContext } from 'react';

import { DirtyContext } from '@/DirtyContext';
import RadioButtonRow from '@/ui/RadioButtonRow';
import AccountContext from '@/AccountContext';

import CompanySearch from '@ui/CompanySearch';
import type { ShareableUrlContactHandling } from '@models/AppointmentType';
import type AppointmentTypeModel from '@models/AppointmentType';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import { psaName } from '@shared/utilities';

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: { [attr: string]: string };
  dirty: boolean;
}

const ContactHandlingModeRow: FC<Props> = ({ appointmentType, errors }) => {
  const { psaType } = useContext(AccountContext);
  const { handleDirty } = useContext(DirtyContext);
  const [catchAllCompanyName, setCatchAllCompanyName] = useStateFromProp(
    appointmentType.catchallPsaCompanyName,
    name => name || ''
  );
  const [shareableUrlContactHandling, setShareableUrlContactHandling] = useStateFromProp(
    appointmentType.shareableUrlContactHandling,
    contactHandling => contactHandling || 'create_new'
  );

  const handleContactHandlingChange = (newValue: string): void => {
    if (handleDirty) handleDirty();

    setShareableUrlContactHandling(newValue as ShareableUrlContactHandling);
  };

  return (
    <RadioButtonRow
      name="appointment_type[shareable_url_contact_handling]"
      value={shareableUrlContactHandling}
      label="New Contact Handling"
      helpText="When a client schedules an appointment with a shareable URL, this setting determines what TimeZest will do if it can't match an existing contact."
      options={[
        {
          label:
            "Create a new contact for the client, associate it with an existing company where email domains match, and create a new company where they don't.",
          value: 'create_new',
        },
        {
          label: 'Create a new contact in the following catch-all company:',
          value: 'use_catchall',
          appendedElement: psaType && (
            <div className="mb-2 mt-1">
              <input type="hidden" name="appointment_type[catchall_psa_company_name]" value={catchAllCompanyName} />
              <CompanySearch
                name="appointment_type[catchall_psa_company_id]"
                value={appointmentType.catchallPsaCompanyId ? Number(appointmentType.catchallPsaCompanyId) : null}
                displayName={catchAllCompanyName}
                readOnly={shareableUrlContactHandling !== 'use_catchall'}
                error={errors.catchallPsaCompanyId}
                onChange={(_id, name) => setCatchAllCompanyName(name)}
              />
            </div>
          ),
        },
        {
          label: `Reject the appointment unless the email domain can be matched with an existing company in ${psaName(psaType)}.`,
          value: 'only_known_domain',
        },
        {
          label: `Reject the appointment unless the email address can be matched with an existing contact in ${psaName(psaType)}.`,
          value: 'only_known_email',
        },
      ]}
      onChange={handleContactHandlingChange}
    />
  );
};

export default ContactHandlingModeRow;

import type { FC } from 'react';

import type { ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';

type DailyStatistic = {
  date: string;
  total_active_users: number;
  estimated_maximum_users: number;
};

interface Props {
  data: DailyStatistic[];
}

const CHART_OPTIONS: ChartOptions<'bar'> = {
  plugins: {
    legend: { position: 'bottom' as const },
  },
  responsive: true,
  scales: {
    x: {
      ticks: {
        callback: function (val: number, _index) {
          const date = new Date(this.getLabelForValue(val));

          if (date.getDate() === 1) {
            return date.toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' });
          } else {
            return '';
          }
        },
      },
    },
    y: {
      stacked: false,
    },
  },
  maintainAspectRatio: false,
};

const PenetrationGraph: FC<Props> = ({ data }) => {
  const sortedData = data.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const chartData = {
    labels: sortedData.map(item =>
      new Date(item.date).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' })
    ),
    datasets: [
      {
        label: 'Active Users',
        data: sortedData.map(item => item.total_active_users),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Estimated Maximum Users',
        data: sortedData.map(item => item.estimated_maximum_users),
        backgroundColor: '#444444',
        stack: 'Stack 0',
      },
    ],
  };

  return <Bar options={CHART_OPTIONS} data={chartData} />;
};

export default PenetrationGraph;

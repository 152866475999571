import { useEffect, useState } from 'react';

import { iterateLayerSelection, selectElement } from '../actions';
import { useAppSelector } from '../hooks';
import type { Index } from '../Reducers/templateReducer';
import type { AppDispatch } from '../Store';

import logoPlaceholder from './MjmlLogo/account-logo-placeholder.png';

import type { Padding } from '@models/EmailTemplate';

// eslint-disable-next-line max-params
export function selectElementHandler(
  dispatch: AppDispatch,
  nodeId: string,
  index: Index,
  selectedElementId: string | null,
  initialSelectedElementId: string | null
) {
  return (event: React.MouseEvent) => {
    const selected = nodeId === selectedElementId;

    event.preventDefault();
    event.stopPropagation();

    if (event.shiftKey) {
      if (
        // 3 reasons to trigger this if block:
        // - No element is selected
        // - Element selected must not be element to insert new block
        // - The node that is being hovered is not the same as the root selected element, and it is not currently selected.
        !selectedElementId ||
        selectedElementId?.match(/^new\-/) ||
        (nodeId !== initialSelectedElementId && !selected)
      ) {
        dispatch(selectElement({ selectedElementId: nodeId }));
      } else {
        dispatch(
          iterateLayerSelection({
            selectedElementId: selectedElementId as string,
            initialSelectedElementId: nodeId,
            index: index,
          })
        );
      }
    } else if (!selected) {
      dispatch(selectElement({ selectedElementId: nodeId }));
    }
  };
}

export const paddingStyle = (padding: Padding | undefined): string => {
  if (!padding) return '10px';

  if (padding.setAll) {
    return `${padding.all}`;
  } else {
    return `${padding.top} ${padding.right} ${padding.bottom} ${padding.left}`;
  }
};

export const useParentWidthAsFallback = (width: string | undefined, parentId: string, unit: string): string => {
  const index = useAppSelector(state => state.template.index);

  if (!width) return `${(100 / index[parentId].childIds.length).toFixed(2)}${unit}`;

  return width;
};

const getLogoDimensions = async (url: string): Promise<{ width: number; height: number }> => {
  const img = new Image();

  img.src = url;

  await img.decode();

  const logoRatio = img.naturalWidth / img.naturalHeight;
  const canvasRatio = 400 / 150;

  if (logoRatio > canvasRatio) {
    return { width: 400, height: 400 / logoRatio };
  } else {
    return { width: logoRatio * 150, height: 150 };
  }
};

export const useLogo = (logoURL: string | null): { url: string; width: number; height: number } => {
  const [logoData, setLogoData] = useState({ url: '', width: 0, height: 0 });

  useEffect(() => {
    if (!logoURL) {
      setLogoData({ url: logoPlaceholder, width: 150, height: 150 });
    } else {
      getLogoDimensions(logoURL).then(d => setLogoData({ url: logoURL, width: d.width, height: d.height }));
    }
  }, [logoURL]);

  return logoData;
};

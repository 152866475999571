import type { FC } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';
import { convertError } from '@/queries';

import RowError from '@shared/ui/RowError';
import { useGetReminderTimesForConnectWiseQuery } from '@graphql/generated';

const REMINDER_HELP_TEXT =
  'When TimeZest creates an appointment in ConnectWise PSA, this will determine the reminder of the created appointment.';

interface Props {
  readOnly: boolean;
  error: string | null;
  value?: string | null;
  templateMode?: boolean;
}

const ReminderRow: FC<Props> = ({ readOnly, error, value, templateMode }) => {
  const queryResult = useGetReminderTimesForConnectWiseQuery({ skip: templateMode });

  const queryErrorMessage = (
    <RowError
      width={6}
      label=""
      helpText={REMINDER_HELP_TEXT}
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve reminder time options from ConnectWise PSA because the API user does not have
      sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure the API user has access to the <strong>Scheduling / Reminder Time</strong> setup table for the
          <strong>Table Setup</strong> permission in the <strong>System</strong> section as&nbsp;
          <a
            href="https://help.timezest.com/en/articles/3158493-creating-a-custom-security-role-in-connectwise-manage-for-timezest"
            target="_blank"
            rel="noreferrer"
          >
            described here
          </a>
          .
        </>
      }
      error="TimeZest encountered an unexpected error. Please reload the page and try again"
    />
  );

  return (
    <SelectRowFromGraphQL
      error={error || ''}
      name="psa_reminder_id"
      label="Reminder"
      field="connectWise.reminderTimes"
      value={value || ''}
      helpText={REMINDER_HELP_TEXT}
      loadingMessage="Loading from ConnectWise PSA..."
      prompt="Use the default specified in ConnectWise PSA"
      readOnly={readOnly}
      templateMode={templateMode}
      queryResult={queryResult}
      queryErrorMessage={queryErrorMessage}
    />
  );
};

export default ReminderRow;

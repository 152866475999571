import { useRef, useState, type FC } from 'react';

import Row from '@/ui/Row';
import ConfirmationModal from '@/ui/ConfirmationModal';

const DeleteCustomAttribute: FC<{ id: number }> = ({ id }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const deleteButtonRef = useRef<HTMLAnchorElement>(null);

  const handleDelete = () => {
    setOpenDeleteModal(false);

    deleteButtonRef.current?.click();
  };

  return (
    <Row
      label="Dangerous Actions"
      withInput={false}
      width={7}
      helpText={
        <button className="btn btn-danger" onClick={() => setOpenDeleteModal(true)}>
          Delete Custom Attribute
        </button>
      }
    >
      Delete this custom attribute,
      <strong>as well as all the data entered for it for each user.</strong>
      <ConfirmationModal
        title="You are about to delete this custom attribute."
        description="Are you sure you wish to delete this custom attribute?"
        isOpen={openDeleteModal}
        ariaLabel="Delete Custom Attribute"
        onConfirm={handleDelete}
        onClose={() => setOpenDeleteModal(false)}
      />
      <a
        ref={deleteButtonRef}
        className="btn btn-danger d-none"
        rel="nofollow"
        data-method="delete"
        href={`/settings/custom_attributes/${id}`}
      >
        Delete Custom Attribute
      </a>
    </Row>
  );
};

export default DeleteCustomAttribute;

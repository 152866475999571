import type { FC } from 'react';
import { useContext, useMemo } from 'react';

import { DirtyContext } from '@/DirtyContext';
import { timezonesForSelect } from '@/utilities';
import AccountContext from '@/AccountContext';

import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import AvailabilityCalendar from '@ui/AvailabilityCalendar';
import Row from '@ui/Row';
import RadioButtonRow from '@ui/RadioButtonRow';
import SelectRow from '@ui/SelectRow';
import type { RailsTimezone } from '@models/Timezone';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  timezones: RailsTimezone[];
}

const AVAILABLE_MODES = [
  { label: 'Use the availability defined on the resource(s) being scheduled', value: 'none' },
  { label: 'Combine the availability of the resources being scheduled with the schedule below', value: 'combine' },
  {
    label: 'Ignore the availability of the resources being scheduled, and only use the schedule below',
    value: 'override',
  },
];

const Config: FC<Props> = ({ appointmentType, errors, timezones: initialTimezones }) => {
  const context = useContext(DirtyContext);
  const { countryCode } = useContext(AccountContext);
  const [mode, setMode] = useStateFromProp(appointmentType.availabilityMode);

  const timezones = useMemo(() => {
    return timezonesForSelect(countryCode, initialTimezones);
  }, [countryCode, initialTimezones]);

  const handleAvailabilityChange = () => {
    if (context.handleDirty) context.handleDirty();
  };

  const handleModeChange = (newMode: 'none' | 'combine' | 'override') => {
    setMode(newMode);
    if (context.handleDirty) context.handleDirty();
  };

  return (
    <>
      <RadioButtonRow
        label="Availability Mode"
        name="appointment_type[availability_mode]"
        error={errors['availabilityMode']}
        options={AVAILABLE_MODES}
        value={mode}
        onChange={handleModeChange}
      />
      <SelectRow
        label="Time zone"
        helpText="Time zone to use for calculating appointment type availability based on the schedule below."
        name="appointment_type[availability_timezone]"
        disabled={mode === 'none'}
        error={errors['availabilityTimezone']}
        options={timezones}
        value={appointmentType.availabilityTimezone || 'Eastern Time (US & Canada)'}
        onChange={handleAvailabilityChange}
      />
      <Row label="">
        <p className="small mb-0">
          Click and drag to create a new period of availability. Drag an existing availability period outside the border
          to delete it.
        </p>
        {errors['availabilityBlocks'] && <p className="text-danger small mb-0 mt-1">{errors['availabilityBlocks']}</p>}
      </Row>
      <AvailabilityCalendar
        availabilityBlocks={appointmentType.availabilityBlocks}
        enabled={mode !== 'none'}
        name="appointment_type[availability_blocks]"
        onChange={handleAvailabilityChange}
      />
    </>
  );
};

export default Config;

import type { FC } from 'react';
import { forwardRef } from 'react';

import RadioButtonRow from '@/ui/RadioButtonRow';

import FormError from '../FormError';
import { useWriteNoteToTaskForServiceNow } from '../hooks';
import BaseError from '../BaseError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { ServiceNowWriteNoteToTaskAction } from '@graphql/generated';
import type { Content } from '@shared/NoteEditor';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import Row from '@ui/Row';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const OPTIONS = [
  { value: 'work_note', label: 'Write a work note (visible only to agents)' },
  { value: 'comment', label: 'Write an additional comment (visible to agents and end users)' },
];

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will write an additional comment or work note against a incident, problem
      or change request, and interpolate actual values for variables into the note body.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When no ServiceNow integration is configured.</li>
      <li>When no incident, problem or change request is associated with the scheduling request.</li>
    </ul>
  </>
);

const Summary: FC<{ noteType: string }> = ({ noteType }) => {
  const noteTypeName = noteType === 'work_note' ? 'work note' : 'additional comment';

  const article = noteType === 'work_note' ? 'a' : 'an';

  return (
    <>
      Add {article} <strong>{noteTypeName}</strong> to a ServiceNow Incident/Problem/Change Request.
    </>
  );
};

const WriteNoteToTask = forwardRef<HTMLFormElement, WorkflowActionProps<ServiceNowWriteNoteToTaskAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useWriteNoteToTaskForServiceNow();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        noteType: (data.get('note_type') || '') as string,
        template: (data.get('template') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="chat-plus"
            summary={<Summary noteType={action.noteType || 'work_note'} />}
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <Row label="Note Content" width={10}>
              <NoteEditor
                name="template"
                initialValue={(action.template as Content) || defaultTemplate}
                readOnly={readOnly}
              />
            </Row>

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              label="Note Type "
              name="note_type"
              value={action.noteType || 'work_note'}
              options={OPTIONS}
              error={errors.recipients}
              readOnly={readOnly}
            />

            {errors.base && <BaseError errorMessage={errors.base} />}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default WriteNoteToTask;

import {
  type BlockMap,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
  convertFromHTML,
  type DraftHandleValue,
} from 'draft-js';

import type Variable from '@models/Variable';

type VariableEntity = {
  blockKey: string;
  name: string;
  value: string;
  startOffset: number;
  endOffset: number;
};

const buildContentStateFromPastedHtml = (html: string): ContentState => {
  const parsedHtml = convertFromHTML(html);

  const contentStateFromHtml = ContentState.createFromBlockArray(parsedHtml.contentBlocks, parsedHtml.entityMap);

  return contentStateFromHtml;
};

const escapeSpecialCharacters = (text: string) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const findVariablesInPastedText = (blockMap: BlockMap, variablesList: Record<string, Variable>): VariableEntity[] => {
  const variableNameMatchingPattern = new RegExp(
    `(${Object.keys(variablesList).map(escapeSpecialCharacters).join('|')})`,
    'gm'
  );

  const extractedVariableEntities: VariableEntity[] = [];

  blockMap.forEach(block => {
    if (!block) return;

    const blockKey = block.getKey();
    const text = block.getText();

    const matches = text.matchAll(variableNameMatchingPattern);

    for (const match of matches) {
      const variableText = match[0];

      const matchIndex = match.index || 0;

      // Create a variable entity from matched text
      const variableEntity: VariableEntity = {
        blockKey,
        name: variableText,
        value: variablesList[`${variableText}`].variable,
        startOffset: matchIndex,
        endOffset: matchIndex + variableText.length,
      };

      extractedVariableEntities.push(variableEntity);
    }
  });

  return extractedVariableEntities;
};

const insertVariableEntitiesIntoContentState = (
  contentState: ContentState,
  variableEntities: VariableEntity[]
): ContentState => {
  const contentStateWithVariableEntities = variableEntities.reduce((currentContentState, currentVariableEntity) => {
    const { blockKey, name, value, startOffset, endOffset } = currentVariableEntity;

    const entityKey = currentContentState
      .createEntity('{mention', 'IMMUTABLE', {
        mention: {
          name,
          variable: value,
        },
      })
      .getLastCreatedEntityKey();

    const selection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: startOffset,
      focusOffset: endOffset,
    });

    return Modifier.replaceText(currentContentState, selection, name, undefined, entityKey);
  }, contentState);

  return contentStateWithVariableEntities;
};

// Function to merge pasted content with existing content in the editor
export const buildNewEditorStateByMergedContent = (
  html: string,
  variablesList: Record<string, Variable>,
  editorState: EditorState
): EditorState => {
  const contentStateFromPastedHtml = buildContentStateFromPastedHtml(html);
  const blockMap = contentStateFromPastedHtml.getBlockMap();

  const variableEntities = findVariablesInPastedText(blockMap, variablesList);

  const contentStateWithEntities = insertVariableEntitiesIntoContentState(contentStateFromPastedHtml, variableEntities);

  const newState = Modifier.replaceWithFragment(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    contentStateWithEntities.getBlockMap()
  );

  return EditorState.push(editorState, newState, 'insert-characters');
};

export const handlePaste =
  (setEditorState: (newEditorState: EditorState) => void, keyedByNameVariablesList: Record<string, Variable>) =>
  (_text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
    if (html) {
      const newEditorState = buildNewEditorStateByMergedContent(html, keyedByNameVariablesList, editorState);

      setEditorState(newEditorState);

      return 'handled';
    }

    return 'not-handled';
  };

export const handleBeforeInput =
  (setEditorState: (newEditorState: EditorState) => void) =>
  (chars: string, editorState: EditorState): DraftHandleValue => {
    if (chars === '. ') {
      const selection = editorState.getSelection();

      const newEditorState = EditorState.set(editorState, {
        currentContent: Modifier.replaceText(editorState.getCurrentContent(), selection, ' '),
      });

      setEditorState(newEditorState);

      return 'handled';
    }

    return 'not-handled';
  };

import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { useAppSelector } from '../hooks';
import type { IndexedMjmlNode } from '../EmailTemplate';

import ButtonProperties from './ButtonProperties';
import ColumnProperties from './ColumnProperties';
import LogoProperties from './LogoProperties';
import SectionProperties from './SectionProperties';
import SpacerProperties from './SpacerProperties';
import TextProperties from './TextProperties';
import ImageProperties from './ImageProperties';

interface Props {
  selectedElementId: string | null;
}

const PropertiesScreen = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  background-color: #f6f6f6;
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const Properties: FC<Props> = ({ selectedElementId: initialSelectedElementId }) => {
  const [selectedElementId, setSelectedElementId] = useState(initialSelectedElementId);
  const [openPropertyScreen, setOpenPropertyScreen] = useState<boolean>();

  const index = useAppSelector(state => state.template.index);

  const style = useMemo(() => {
    return {
      transition: index[selectedElementId || ''] ? 'left 0.3s cubic-bezier(0.85, 0.01, 0.4, 1)' : '0s',
      left: openPropertyScreen ? 0 : '320px',
      maxWidth: '320px',
      width: selectedElementId && !selectedElementId.match(/^new\-/) ? '100%' : '0px',
    };
  }, [index, openPropertyScreen, selectedElementId]);

  useEffect(() => {
    if (initialSelectedElementId) {
      setSelectedElementId(initialSelectedElementId);

      if (!openPropertyScreen && !initialSelectedElementId.match(/^new\-/)) setOpenPropertyScreen(true);
    } else {
      const timeout = setTimeout(() => {
        setSelectedElementId(null);
      }, 500);
      setOpenPropertyScreen(false);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [initialSelectedElementId, openPropertyScreen]);

  const selectedElement = selectedElementId ? index[selectedElementId] : null;

  return (
    <PropertiesScreen className="position-absolute" style={style}>
      {selectedElement && renderPropertiesScreen(selectedElement)}
    </PropertiesScreen>
  );
};

export default Properties;

function renderPropertiesScreen(selectedElement: IndexedMjmlNode) {
  switch (selectedElement.type) {
    case 'mjml-button':
      return <ButtonProperties node={selectedElement} />;
    case 'mjml-column':
      return <ColumnProperties node={selectedElement} />;
    case 'mjml-section':
      return <SectionProperties node={selectedElement} />;
    case 'mjml-text':
      return <TextProperties node={selectedElement} />;
    case 'mjml-logo':
      return <LogoProperties node={selectedElement} />;
    case 'mjml-spacer':
      return <SpacerProperties node={selectedElement} />;
    case 'mjml-image':
      return <ImageProperties node={selectedElement} />;
    default:
      return <div>Unknown element type &apos;{selectedElement.type}&apos;</div>;
  }
}

import type { FC } from 'react';
import { useContext, useState } from 'react';

import FontAwesomeIcon from '@/FontAwesomeIcon';
import Row from '@/ui/Row';
import AccountContext from '@/AccountContext';

import { capitalize, withArticle } from '@shared/text';
import SelectRow from '@ui/SelectRow';
import TextAreaRow from '@ui/TextAreaRow';
import TextInputRow from '@ui/TextInputRow';
import InputGroup from '@shared/ui/Inputs/InputGroup';
import type { AttributeModel } from '@models/User';
import type { IntegrationModel } from '@models/Integration';

interface Props {
  attributes: AttributeModel[];
  attributesAllowed: boolean;
  errors: { [attr: string]: string };
  integrationMembers: number[];
  integrations: IntegrationModel[];
  role: string;
  roles: string[];
  slug: string;
}

const DetailsConfig: FC<Props> = ({
  attributes,
  attributesAllowed,
  errors,
  integrationMembers,
  integrations,
  role,
  roles,
  slug: initialSlug,
}) => {
  const { host } = useContext(AccountContext);

  const [slug, setSlug] = useState(initialSlug);

  return (
    <>
      <SelectRow
        name="user[role]"
        value={role}
        label="Role"
        helpText="Administrators may configure all aspects of TimeZest; Users may only change their own settings."
        options={roles.map(role => {
          return { name: capitalize(role), value: role };
        })}
      />
      <Row
        label="URL"
        helpText={
          <>
            For URL-triggered scheduling, this slug is what identifies this user in the URL - e.g.&nbsp;
            <code>laura-smith</code>.
          </>
        }
      >
        <InputGroup
          name="user[slug]"
          value={slug}
          prepend={`${host}/`}
          append="/<appointment type>"
          error={errors.slug}
          onChange={e => setSlug(e.target.value)}
        />
      </Row>

      {attributesAllowed &&
        (attributes || []).map((attr, i) => {
          const Component = attr.attr_type === 'text' ? TextAreaRow : TextInputRow;

          return (
            <Component
              key={i}
              name={`user[custom_attributes][${attr.name}]`}
              value={attr.value}
              label={attr.description}
              helpText={
                <>
                  Custom attribute available in confirmation email templates as&nbsp;
                  <code>{`{{custom_attribute.${attr.name}}}`}</code>.
                </>
              }
            />
          );
        })}
      {integrations.length > 0 && (
        <>
          <hr />
          <div className="row mb-4">
            <div className="col-sm-2 fw-bold">Integrations</div>
            <div className="col-sm-10">
              {integrations.map(integration => {
                const assigned = integrationMembers.indexOf(integration.id) >= 0;

                return assigned ? (
                  <div key={integration.id}>
                    <span className="text-success">
                      <FontAwesomeIcon icon="check" className="me-2" />
                      This user is associated with {withArticle(integration.name)} user account.
                    </span>
                  </div>
                ) : (
                  <div key={integration.id}>
                    <span className="text-danger">
                      <FontAwesomeIcon icon="times" className="me-2" />
                      This user is not associated with {withArticle(integration.name)} user account.
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsConfig;

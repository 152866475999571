import type { FC } from 'react';
import { Fragment, StrictMode, useMemo, useRef, useState } from 'react';

import ConfigContext from '@/EmailEditor/ConfigContext';
import asScreen from '@/Screen';
import Form from '@/Form';
import Flash from '@/Flash';
import Header from '@/Header';

import Trigger from './Trigger';

import type VariableModel from '@models/Variable';
import type { TimeElapsedTriggerModel } from '@models/TimeElapsedTriggerModel';
import type { Trigger as TriggerModel } from '@graphql/generated';
import VariablesContext from '@shared/VariablesContext';
import { Context as AvailableOptionsContext } from '@ui/Workflow/AvailableOptions';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';
import type { EmailTemplate } from '@models/EmailTemplate';
import useRefs from '@shared/hooks/useRefs';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import Alert from '@shared/Alert';
import { sentence } from '@shared/text';
import { LANGUAGES } from '@shared/constants';

const AVAILABLE_LANGUAGES = LANGUAGES.reduce(
  (languages, { code, name }) => {
    languages.push([code, name]);
    return languages;
  },
  [] as [string, string][]
);

interface Props {
  availableVariables: VariableModel[];
  defaultEmailTemplate?: EmailTemplate;
  errors: Record<string, string[]>[];
  logoUrl: string | null;
  triggers: (TriggerModel | TimeElapsedTriggerModel)[];
  workflowTemplateName: string;
}

const ImportWorkflow: FC<Props> = ({
  availableVariables,
  defaultEmailTemplate,
  errors,
  logoUrl,
  triggers,
  workflowTemplateName,
}) => {
  const processedTriggers = useMemo(() => {
    return triggerActionsWithTempIds(triggers);
  }, [triggers]);

  const actionsLength = useMemo(() => {
    return triggers.reduce((total, trigger) => {
      return total + trigger.actions.length;
    }, 0);
  }, [triggers]);

  const [expandedActionId, setSelectedActionId] = useState<number | undefined>(undefined);
  const formRef = useRef<HTMLFormElement>(null);
  const [getRef, setRef] = useRefs<HTMLFormElement>(actionsLength);
  const [fields, setFields] = useStateWithCallback<Record<string, string>>({});

  const handleSubmit = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();

    const data = getData(processedTriggers, getRef);
    setFields(data, () => {
      if (formRef.current) formRef.current.submit();
    });
  };

  return (
    <StrictMode>
      <Header title="Review New Appointment Type" subtitle={workflowTemplateName} />
      <Flash />
      <>
        {errors && errors.length > 0 ? (
          <>
            {errors.map(objectErrors =>
              Object.values(objectErrors).map((error, index) => (
                <Alert key={`error_${index}`} color="red" icon="circle-xmark" content={sentence(error)} />
              ))
            )}
            <p className="mt-3">
              Go to the <a href="/settings/integrations">integrations</a> page to configure the missing integrations.
            </p>
          </>
        ) : (
          <>
            <p>
              TimeZest has set some default values for some of the actions in the appointment type workflow you are
              about to import into your account. Please review these actions, marked with &quot;Needs review&quot; to
              ensure that the selected values meet your needs, then click &quot;Complete import&quot;.
            </p>
            <p>You can always make additional changes to the appointment type later.</p>
            <div className="row mb-3">
              <div className="col-sm-6">
                <Form ref={formRef} url={window.location.href}>
                  {Object.keys(fields).map(name => (
                    <input type="hidden" key={name} name={name} value={fields[name]} />
                  ))}
                  <input
                    type="button"
                    value="Complete Import"
                    className="btn btn-primary"
                    data-disable-with="Complete Import"
                    onClick={handleSubmit}
                  />
                </Form>
              </div>
            </div>
            <AvailableOptionsContext.Provider value={{ availableLanguages: AVAILABLE_LANGUAGES }}>
              <VariablesContext.Provider
                value={{ variables: availableVariables.sort((a, b) => a.name.localeCompare(b.name)) }}
              >
                <ConfigContext.Provider
                  value={{
                    defaultEmailTemplate,
                    logoUrl,
                    readOnly: false,
                  }}
                >
                  <WorkflowActionContext.Provider
                    value={{
                      actionReviewable: true,
                      expandedActionId,
                      expandable: true,
                      initialExpanded: 'withErrors',
                      templateMode: false,
                      handleActionId: setSelectedActionId,
                    }}
                  >
                    {triggerActionsWithTempIds(triggers).map((trigger, index) => (
                      <Fragment key={trigger.slug + index}>
                        {index > 0 && <hr className="mt-4" />}
                        <Trigger trigger={trigger} formRefSetter={setRef} />
                      </Fragment>
                    ))}
                  </WorkflowActionContext.Provider>
                </ConfigContext.Provider>
              </VariablesContext.Provider>
            </AvailableOptionsContext.Provider>
          </>
        )}
      </>
    </StrictMode>
  );
};

export default asScreen(ImportWorkflow);

function triggerActionsWithTempIds(
  triggers: (TriggerModel | TimeElapsedTriggerModel)[]
): (TriggerModel | TimeElapsedTriggerModel)[] {
  let id = 0;

  const processedTriggers = triggers.map(t => {
    return {
      ...t,
      actions: [
        ...t.actions.map(a => {
          const action = { ...a, id };
          id++;

          return action;
        }),
      ],
    };
  });

  return processedTriggers;
}

function getData(
  triggers: (TriggerModel | TimeElapsedTriggerModel)[],
  getRef: (index: number) => HTMLFormElement | null
) {
  const data = {};

  triggers.forEach((trigger, triggerIndex) => {
    data[`workflow[triggers][${triggerIndex}][type]`] = trigger.slug;

    trigger.actions.forEach((action, actionIndex) => {
      data[`workflow[triggers][${triggerIndex}][actions][${actionIndex}][type]`] = action.slug;

      const actionForm = getRef(action.id);

      if (!actionForm) return;

      const actionAttributes = new FormData(actionForm).entries();

      for (const [name, value] of actionAttributes) {
        const key = name.replace(/[\[\]']+/g, '');
        data[`workflow[triggers][${triggerIndex}][actions][${actionIndex}][attributes][${key}]`] = value;
      }
    });
  });

  return data;
}

import type { FC } from 'react';

interface Props {
  project: boolean;
}

const Badge: FC<Props> = ({ project }) => {
  if (project) {
    return <span className="badge-yellow align-middle">Project Board</span>;
  } else {
    return <span className="badge-green align-middle">Service Board</span>;
  }
};

export default Badge;

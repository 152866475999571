import type { FC } from 'react';

import classNames from 'classnames';

import type { FieldProps } from './Field';
import Field from './Field';

import SelectControl from '@shared/ui/Select';

interface Props extends Omit<FieldProps, 'className' | 'error'> {
  options: { name: string; value: string }[];
  value: string;
  disabled: boolean;
  onChange?: (value: string) => void;
}

const Select: FC<Props> = ({ id, label, options, value, disabled, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(e.currentTarget.value);
  };

  return (
    <Field id={id} label={label}>
      <SelectControl
        className={classNames('rounded-1 py-1 ps-1', { 'text-muted': disabled })}
        value={value}
        disabled={disabled}
        name={`${id}-select`}
        options={options}
        onChange={handleChange}
      />
    </Field>
  );
};

export default Select;

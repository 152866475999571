import type { FC } from 'react';

import './RadioButtonRow.scss';

interface Props {
  checked: boolean;
  disabled: boolean;
  id?: string;
  label: JSX.Element | string;
  name: string;
  value: string;
  appendedElement?: string | JSX.Element;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonOption: FC<Props> = ({ checked, disabled, id, label, name, value, appendedElement, onChange }) => {
  const idPrefix = `${id ? id + '_' : ''}${name}`;

  return (
    <div className="form-check mb-2" key={value}>
      <div className="mb-1">
        <input
          checked={checked}
          className="form-check-input"
          disabled={disabled}
          id={`${idPrefix}_${value}`}
          name={name}
          type="radio"
          value={value}
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor={`${idPrefix}_${value}`}>
          {label}
        </label>
      </div>
      {appendedElement}
    </div>
  );
};

export default RadioButtonOption;

import type { FC } from 'react';
import { useContext } from 'react';

import styled from 'styled-components';

import ConfigContext from '../ConfigContext';
import { updateProperty } from '../actions';
import { useAppDispatch } from '../hooks';
import type { IndexedMjmlText } from '../EmailTemplate';

import ColorInput from './Inputs/Color';
import FontControl from './controls/FontControl';
import ButtonGroup from './Inputs/ButtonGroup';
import SpaceInput from './Inputs/Space';
import { PropertiesCollapsible } from './shared/PropertiesCollapsible';
import PropertiesContainer from './shared/PropertiesContainer';
import ConditionalSetter from './shared/ConditionalSetter';

const ALIGNMENTS = [
  { icon: 'align-left', value: 'left' },
  { icon: 'align-center', value: 'center' },
  { icon: 'align-right', value: 'right' },
  { icon: 'align-justify', value: 'justify' },
];

const TextPropertiesContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f6f6f6;
`;

interface Props {
  node: IndexedMjmlText;
}

const TextProperties: FC<Props> = ({ node }) => {
  const { readOnly } = useContext(ConfigContext);
  const dispatch = useAppDispatch();

  const handleOnChange = (property: string) => (newValue: any) => {
    dispatch(updateProperty({ elementId: node.id, property, value: newValue }));
  };

  return (
    <PropertiesContainer
      selectedElementId={node.id}
      elementType={node.type}
      disabled={readOnly}
      deleteDescription="You are about to delete this text block. Are you sure?"
    >
      <TextPropertiesContainer>
        <PropertiesCollapsible title="TEXT PROPERTIES">
          <FontControl nodeId={node.id} fontFamily={node.font?.family} fontSize={node.font?.size} disabled={readOnly} />
          <ButtonGroup
            id={`${node.id}-text-alignment`}
            label="Alignment"
            value={node.text?.alignment || 'left'}
            options={ALIGNMENTS}
            disabled={readOnly}
            onChange={handleOnChange('text.alignment')}
          />
          <ColorInput
            id={`${node.id}-text-color`}
            label="Text Color"
            name="textColor"
            value={node.text?.color || '#000000'}
            disabled={readOnly}
            onInput={handleOnChange('text.color')}
          />
          <ColorInput
            id={`${node.id}-link-color`}
            label="Link Color"
            name="linkColor"
            value={node.text?.linkColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('text.linkColor')}
          />
          <ColorInput
            id={`${node.id}-background-color`}
            label="Background Color"
            name="backgroundColor"
            value={node.backgroundColor || ''}
            disabled={readOnly}
            onInput={handleOnChange('backgroundColor')}
          />
          <SpaceInput
            id={`${node.id}-padding`}
            label="Padding"
            value={node.padding || {}}
            disabled={readOnly}
            onInput={handleOnChange('padding')}
          />
        </PropertiesCollapsible>

        <PropertiesCollapsible title="DISPLAY CONDITIONS">
          <ConditionalSetter nodeId={node.id} nodeType={node.type} condition={node.condition} disabled={readOnly} />
        </PropertiesCollapsible>
      </TextPropertiesContainer>
    </PropertiesContainer>
  );
};

export default TextProperties;

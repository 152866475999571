import type { FC } from 'react';

import ErrorDetail from './ErrorDetail';

import type { InsightCreationResultType } from '..';

interface Props {
  insightCreationResult: InsightCreationResultType;
}

const InsightCreationResult: FC<Props> = ({ insightCreationResult }) => {
  return (
    <>
      {insightCreationResult && insightCreationResult.status !== 'ok' && (
        <ErrorDetail
          unauthorized={insightCreationResult.status === 'unauthorized'}
          error={insightCreationResult.error}
        />
      )}
    </>
  );
};

export default InsightCreationResult;

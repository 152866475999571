import type { ChangeEvent, FC } from 'react';
import { useContext, useState } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';
import Form from '@/Form';
import RadioButtonOption from '@/ui/RadioButtonRow/RadioButtonOption';
import AccountContext from '@/AccountContext';

import ConnectWiseTeamDescription from './ConnectWiseTeamDescription';
import TimeZestTeamDescription from './TimeZestTeamDescription';
import HaloPSATeamDescription from './HaloPSATeamDescription';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const NewTeam: FC = () => {
  const { psaType } = useContext(AccountContext);
  const [teamType, setTeamType] = useState(psaType === 'connect_wise' ? 'ServiceTeam' : 'HaloPsaTeam');

  const handleSelectTeam = (e: ChangeEvent<HTMLInputElement>) => {
    setTeamType(e.target.value);
  };

  return (
    <>
      <Header title="New Team" subtitle="Teams" />
      <Flash />
      <Form url="/settings/teams" method="post">
        <p className="fw-bold">What type of team do you wish to add?</p>
        <div className="d-grid mb-3 gap-2">
          {psaType === 'connect_wise' && (
            <>
              <RadioButtonOption
                appendedElement={<ConnectWiseTeamDescription teamType="ServiceTeam" />}
                checked={teamType === 'ServiceTeam'}
                disabled={false}
                label="Service Team"
                name="team[type]"
                value="ServiceTeam"
                onChange={handleSelectTeam}
              />
              <RadioButtonOption
                appendedElement={<ConnectWiseTeamDescription teamType="ProjectTeam" />}
                checked={teamType === 'ProjectTeam'}
                disabled={false}
                label="Project Team"
                name="team[type]"
                value="ProjectTeam"
                onChange={handleSelectTeam}
              />
            </>
          )}
          {psaType === 'halo_psa' && (
            <RadioButtonOption
              appendedElement={<HaloPSATeamDescription />}
              checked={teamType === 'HaloPsaTeam'}
              disabled={false}
              label="HaloPSA Team"
              name="team[type]"
              value="HaloPsaTeam"
              onChange={handleSelectTeam}
            />
          )}
          <RadioButtonOption
            appendedElement={<TimeZestTeamDescription />}
            checked={teamType === 'TimeZestTeam'}
            disabled={false}
            label="TimeZest-Defined Team"
            name="team[type]"
            value="TimeZestTeam"
            onChange={handleSelectTeam}
          />
        </div>
        <button name="button" className="btn btn-primary" type="submit">
          Next &nbsp; <FontAwesomeIcon icon="angle-right" />
        </button>
      </Form>
    </>
  );
};

export default asScreen(NewTeam);

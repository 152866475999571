import { type FC, useContext } from 'react';

import PlanContext from '@/PlanContext';

import { formatDateLong } from '@shared/text';

interface Props {
  changingPlan: boolean;
  downgrading: boolean;
  newPlanName: string;
  subscriptionRenewsAt: string | null;
}

const PlanChangingAnnouncement: FC<Props> = ({ changingPlan, downgrading, newPlanName, subscriptionRenewsAt }) => {
  const { name } = useContext(PlanContext);

  if (changingPlan && downgrading) {
    return (
      <p>
        You will be able to continue using all features of your <strong>{name}</strong> plan until{' '}
        <strong>{formatDateLong(subscriptionRenewsAt)}</strong>.
        <br />
        After this, you will see the following changes:
      </p>
    );
  }

  if (changingPlan) {
    return (
      <p>
        &mdash; You&apos;ll be subscribed to the <strong>{newPlanName}</strong> plan.
      </p>
    );
  }

  return (
    <p>
      &mdash; Your TimeZest subscription will switch to the <strong>{newPlanName}</strong> plan.
    </p>
  );
};

export default PlanChangingAnnouncement;

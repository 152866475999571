import type { FC } from 'react';

import ActiveMembersList from './ActiveMembersList';
import InactiveMembersList from './InactiveMembersList';
import MembersList from './MembersList';

import type { Member } from '@models/TeamMember';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  activatedMembers: Member[];
  unactivatedMembers: Member[];
  loading: boolean;
  selectedTeamId: number | undefined;
}

const Members: FC<Props> = ({ loading, selectedTeamId, activatedMembers, unactivatedMembers }) => {
  if (loading) {
    return (
      <>
        <FontAwesomeIcon spin icon="circle-notch" />
        &nbsp;&nbsp; Loading from ConnectWise PSA...
      </>
    );
  }

  if (selectedTeamId === undefined) {
    return null;
  }

  if (unactivatedMembers.length === 0 && activatedMembers.length > 0) {
    return <ActiveMembersList activatedMembers={activatedMembers} unactivatedMembers={unactivatedMembers} />;
  } else if (activatedMembers.length === 0) {
    return <InactiveMembersList activatedMembers={activatedMembers} unactivatedMembers={unactivatedMembers} />;
  } else {
    return <MembersList activatedMembers={activatedMembers} unactivatedMembers={unactivatedMembers} />;
  }
};

export default Members;

import type { FC } from 'react';

import AccountContext, { NULL_ACCOUNT } from '@/AccountContext';

import ConfigContext from './ConfigContext';
import EmailEditor from './EmailEditor';

import type { EmailTemplate } from '@models/EmailTemplate';
import type Variable from '@models/Variable';
import VariablesContext from '@shared/VariablesContext';

interface Props {
  name: string;
  initialTemplate: EmailTemplate;
  availableVariables: Variable[];
  defaultEmailTemplate?: EmailTemplate;
  logoUrl: string | null;
  readOnly: boolean;
}

const EmailTemplateEditor: FC<Props> = ({
  availableVariables,
  defaultEmailTemplate,
  initialTemplate,
  logoUrl,
  name,
  readOnly,
}) => {
  const provided = {
    defaultEmailTemplate,
    logoUrl,
    readOnly,
  };

  return (
    <VariablesContext.Provider value={{ variables: availableVariables.sort((a, b) => a.name.localeCompare(b.name)) }}>
      <ConfigContext.Provider value={provided}>
        <EmailEditor initialTemplate={initialTemplate} name={name} />
      </ConfigContext.Provider>
    </VariablesContext.Provider>
  );
};

const EmailTemplateEditorWithBrand: FC<Props & { brandColor: string }> = ({ brandColor, ...props }) => {
  return (
    <AccountContext.Provider value={{ ...NULL_ACCOUNT, brandColor }}>
      <EmailTemplateEditor {...props} />
    </AccountContext.Provider>
  );
};

export default EmailTemplateEditorWithBrand;
export { EmailTemplateEditor };

import type { ForwardedRef } from 'react';

import useForwardedRef from './useForwardedRef';

export default function useFormSubmit<P, A>(
  action: A,
  ref: ForwardedRef<HTMLFormElement>,
  submit: (action: A, params: P) => void,
  transformer: (data: FormData) => P
) {
  const formRef = useForwardedRef(ref);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formRef.current) return;
    const data = new FormData(formRef.current);
    submit(action, transformer(data));
  };

  return { formRef, handleSubmit };
}

{
  "units": {
    "hour_one": "1 uur",
    "minute_one": "1 minuut",
    "minute_other": "{{count}} minuten",
    "hour_other": "{{count}} uur"
  },
  "scheduling_ui": {
    "appointment_details": {
      "appointment": "Afspraak",
      "with": "Met",
      "date": "Datum",
      "ticket": "Ticket",
      "time": "Tijd",
      "duration": "Duur"
    },
    "appointment_selector": {
      "instruction": "Plan een datum en een tijd voor je {{appointment_name}} met {{appointment_resources}}.",
      "instruction_reschedule": "Selecteer een nieuwe datum en tijd voor je {{appointment_name}} met {{appointment_resources}}."
    },
    "date_selector": {
      "loading": "Aan het zoeken naar datums met beschikbare afspraken..."
    },
    "select_prompt": {
      "prompt": "Selecteer een datum om de beschikbare tijden te zien."
    },
    "timezone_selector": {
      "indicator": "Alle tijden zijn in {{timezone}}",
      "change": "Wijzigen"
    },
    "time_selector": {
      "no_available_time": {
        "notification": "Er zijn geen tijden beschikbaar op deze datum.",
        "instruction": "Kies een andere dag."
      },
      "available_time": "De tijden hieronder zijn in de tijdzone <strong>{{timezone}}</strong>. Als je je in een andere tijdzone bevindt, kun je dit hieronder wijzigen."
    }
  }
}

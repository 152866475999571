import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import difference from 'lodash/difference';
import orderBy from 'lodash/orderBy';

import type { RailsTimezone } from '@models/Timezone';
import type { AvailabilityBlock } from '@models/Availability';

export const tw = (strings, ...values) => String.raw({ raw: strings }, ...values);

export function only(fn: () => any) {
  return function (event: React.MouseEvent): void {
    event.preventDefault();
    fn();
  };
}

export function getCSRFToken(): string {
  return document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content || '';
}

export function throwOnError(response: Response): Response {
  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response;
}

export function throwOnNonValidationError(response: Response): Response {
  if (!response.ok && response.status !== 422) {
    throw Error(response.statusText);
  }

  return response;
}

export function displayAvailability(
  availabilityBlocks: AvailabilityBlock[],
  availabilityAllowed: boolean
): string | null {
  if (!availabilityAllowed) return null;

  const allDays = {
    monday: { name: 'Mon', blocks: new Array<AvailabilityBlock>() },
    tuesday: { name: 'Tue', blocks: new Array<AvailabilityBlock>() },
    wednesday: { name: 'Wed', blocks: new Array<AvailabilityBlock>() },
    thursday: { name: 'Thu', blocks: new Array<AvailabilityBlock>() },
    friday: { name: 'Fri', blocks: new Array<AvailabilityBlock>() },
    saturday: { name: 'Sat', blocks: new Array<AvailabilityBlock>() },
    sunday: { name: 'Sun', blocks: new Array<AvailabilityBlock>() },
  };

  availabilityBlocks.forEach(block => allDays[block.day].blocks.push(block));

  const days = Object.values(allDays)
    .filter(day => day.blocks.length > 0)
    .map(d => {
      const blocks = d.blocks.sort((b1, b2) => b1.startTime.localeCompare(b2.startTime));
      const times = blocks.map(b => `${convertTo12H(b.startTime)}-${convertTo12H(b.endTime)}`);
      return { name: d.name, times };
    });

  if (days.length === 0) return null;

  const groupedDays = days.reduce((acc, day) => {
    const matchingDay = acc.find(d => isEqual(d.times, day.times));

    if (matchingDay) {
      matchingDay.days.push(day.name);
    } else {
      acc.push({ days: [day.name], times: day.times });
    }

    return acc;
  }, new Array<{ days: string[]; times: string[] }>());

  return groupedDays.map(g => `${g.days.join(', ')}: ${g.times.join(', ')}`).join('; ');
}

function convertTo12H(time: string): string {
  const [hours, minutes] = time.split(':');
  const h = parseInt(hours, 10);
  const ampm = h < 12 || h === 24 ? 'am' : 'pm';
  return `${h % 12 || 12}${minutes !== '00' ? ':' + minutes : ''}${ampm}`;
}

export function Option(props: { object: { id: number | string; name: string } }): JSX.Element {
  return <option value={props.object.id}>{props.object.name}</option>;
}

export function PlainText(props: React.PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <div className={`form-control-plaintext${props.className ? ` ${props.className}` : ''}`}>{props.children}</div>
  );
}

export function renderSingularOrPluralInactiveMember(inactiveCount: number): string {
  return inactiveCount <= 1 ? `${inactiveCount} inactive user` : `${inactiveCount} inactive users`;
}

export function renderSingularOrPluralActiveMember(activeCount: number): string {
  return activeCount <= 1 ? `${activeCount} active user` : `${activeCount} active users`;
}

const PRIORITIZED_TIMEZONES = {
  US: [
    'Alaska',
    'Central Time (US & Canada)',
    'Eastern Time (US & Canada)',
    'Hawaii',
    'Mountain Time (US & Canada)',
    'Pacific Time (US & Canada)',
  ],
  CA: [
    'Atlantic Time (Canada)',
    'Central Time (US & Canada)',
    'Eastern Time (US & Canada)',
    'Newfoundland',
    'Mountain Time (US & Canada)',
    'Pacific Time (US & Canada)',
  ],
  AU: ['Adelaide', 'Brisbane', 'Darwin', 'Hobart', 'Melbourne', 'Perth', 'Sydney'],
  UK: ['London'],
  NZ: ['Wellington'],
};

function timezoneToOption(timezone: RailsTimezone) {
  if (!timezone.value) return { name: timezone.display, value: 'list-separator', disabled: true };

  return { name: timezone.display, value: timezone.value };
}

export function timezonesForSelect(countryCode: string, allZones: RailsTimezone[]) {
  const sortedAllZones = orderBy(allZones, ['offset'], ['desc']);
  const prioritizedTimezonesInCountry = PRIORITIZED_TIMEZONES[countryCode];

  if (!prioritizedTimezonesInCountry) return allZones.map(timezone => timezoneToOption(timezone));

  const prioritizedTimezones = filter(sortedAllZones, timezone => {
    if (prioritizedTimezonesInCountry.includes(timezone.value)) return true;

    return false;
  });

  const remainingTimezones = difference(sortedAllZones, prioritizedTimezones);

  return [
    ...prioritizedTimezones,
    { id: '', display: '---------------------------------------------', value: '', offset: 0 },
    ...remainingTimezones,
  ].map(timezone => timezoneToOption(timezone));
}

import { type FC, useContext } from 'react';

import AccountContext from './AccountContext';
import CurrentUserContext from './CurrentUserContext';
import FlashContext from './FlashContext';

import Alert from '@shared/Alert';

const Flash: FC = () => {
  const { role } = useContext(CurrentUserContext);
  const { lastPaymentSucceeded } = useContext(AccountContext);
  const flash = useContext(FlashContext);

  if (flash.length === 0) {
    return;
  }

  const [type, message] = flash[0];

  if (type === 'notice') {
    return <Alert icon="circle-check" color="green" content={message} className="mb-3" details="notice" />;
  }

  if (type === 'alert') {
    return <Alert icon="circle-xmark" color="red" content={message} className="mb-3" details="alert" />;
  }

  if (role === 'administrator' && !lastPaymentSucceeded) {
    return (
      <Alert
        className="mb-3"
        color="red"
        content={
          <>
            Please update your payment details in <a href="/settings/account">Account & Billing</a> settings.
          </>
        }
        details="alert"
        icon="triangle-exclamation"
        title="TimeZest was unable to charge your credit card for a recent payment."
      />
    );
  }
};

export default Flash;

import type { FC } from 'react';

import TimeZestTeam from './TimeZestTeam';
import PsaTeam from './PsaTeam';

import type { Team } from '@models/Team';

interface Props {
  teams: Team[];
}

const TeamsList: FC<Props> = ({ teams }) => {
  return (
    <>
      {teams.map((t, i) => (
        <div key={i}>
          {t.teamType === 'timezest' ? (
            <TimeZestTeam id={t.id} members={t.members} name={t.name} />
          ) : (
            <PsaTeam
              deletedInPsa={t.deletedInPsa}
              fetched={t.fetched}
              id={t.id}
              members={t.members}
              name={t.name}
              teamType={t.teamType}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default TeamsList;

import type { FC } from 'react';
import { useContext, useState } from 'react';

import classNames from 'classnames';

import { DirtyContext } from '@/DirtyContext';

import Row from '@ui/Row';

interface Props {
  error?: string;
  helpText: string | JSX.Element | null;
  label: string;
  name: string;
  note?: string;
  rows?: number;
  disabled?: boolean;
  value: string | null;
}

const TextAreaRow: FC<Props> = ({ error, helpText, label, name, note, rows, disabled, value: initialValue }) => {
  const [value, setValue] = useState(initialValue || '');
  const { handleDirty } = useContext(DirtyContext);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (handleDirty) {
      handleDirty();
    }

    setValue(event.target.value);
  };

  return (
    <Row label={label} helpText={helpText}>
      <>
        <textarea
          style={{ resize: disabled ? 'none' : 'vertical' }}
          disabled={disabled}
          name={name}
          id={name}
          className={classNames('form-control', { 'is-invalid': error })}
          value={value || ''}
          rows={rows}
          data-lpignore="true"
          onChange={handleChange}
        />
        {note && <p className="small form-text text-muted mb-0">{note}</p>}
        <div className="invalid-feedback">{error}</div>
      </>
    </Row>
  );
};

export default TextAreaRow;

import type { UIState } from './uiReducer';

const setInsertionPoint: (state: UIState, parentId: string, insertionIndex: number) => UIState = (
  state,
  parentId,
  insertionIndex
) => {
  return {
    ...state,
    insertionPoint: { parentId, insertionIndex },
  };
};

export default setInsertionPoint;

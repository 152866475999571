import { forwardRef, useContext } from 'react';

import ServiceTicketOptions from '@/autotask/ServiceTicketOptions';

import FormError from '../FormError';
import { useCreateServiceTicketForAutotask } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import RadioButtonRow from '@ui/RadioButtonRow';
import SummaryRow from '@ui/SummaryRow';
import type { AutotaskCreateServiceTicketAction, SummaryMode } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a new service ticket in Autotask with the configured values.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When there is already a ticket associated with the scheduling request, unless &apos;Always create&apos; is
        selected.
      </li>
      <li>
        When there is no Autotask contact associated with the scheduling request, as Autotask requires one to create a
        ticket.
      </li>
      <li>When no Autotask integration is configured.</li>
    </ul>
    <p>
      If the &apos;Require the client to provide a description of their issue&apos; option is selected, TimeZest will
      ask the client for this when they schedule.
    </p>
  </>
);

const CreateServiceTicket = forwardRef<HTMLFormElement, WorkflowActionProps<AutotaskCreateServiceTicketAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const { errors: mutationErrors, loading, succeeded, submit } = useCreateServiceTicketForAutotask();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        categoryId: (data.get('[psa_category_id]') || '') as string,
        createAlways: data.get('create_always') === 'true',
        priorityId: (data.get('[psa_priority_id]') || '') as string,
        queueId: (data.get('[psa_queue_id]') || '') as string,
        sourceId: (data.get('[psa_source_id]') || '') as string,
        statusId: (data.get('[psa_status_id]') || '') as string,
        summaryMode: data.get('new_entity_summary_mode') as SummaryMode,
        summaryText: (data.get('new_entity_summary') || '') as string,
        typeId: (data.get('[psa_type_id]') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="task-plus"
            summary={
              <>
                Create a <strong>service ticket</strong> in Autotask.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="create_always"
              value={(action.createAlways || false).toString()}
              label="Creation Mode"
              readOnly={readOnly}
              options={[
                { label: 'Always create a new service ticket.', value: 'true', disabled: readOnly },
                {
                  label: "Create a service ticket only when one isn't associated with the scheduling request.",
                  value: 'false',
                  disabled: readOnly,
                },
              ]}
              error={errors.create_always}
            />

            <SummaryRow
              readOnly={readOnly}
              error={errors.summary_mode || errors.summary_text}
              appointmentNames={['Autotask service ticket']}
              summary={action.summaryText || ''}
              summaryMode={action.summaryMode || 'ask_client'}
            />

            <ServiceTicketOptions
              modelName=""
              disabled={readOnly}
              categoryId={Number(action.psaCategoryId)}
              categoryIdAttr="psa_category_id"
              categoryIdError={errors.psa_category_id}
              queueId={Number(action.psaQueueId)}
              queueIdAttr="psa_queue_id"
              queueIdError={errors.psa_queue_id}
              priorityId={Number(action.psaPriorityId)}
              priorityIdAttr="psa_priority_id"
              priorityIdError={errors.psa_priority_id}
              sourceId={Number(action.psaSourceId)}
              sourceIdAttr="psa_source_id"
              sourceIdError={errors.psa_source_id}
              statusId={Number(action.psaStatusId)}
              statusIdAttr="psa_status_id"
              statusIdError={errors.psa_status_id}
              typeId={Number(action.psaTypeId)}
              typeIdAttr="psa_type_id"
              typeIdError={errors.psa_type_id}
              readOnly={readOnly}
              templateMode={templateMode}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateServiceTicket;

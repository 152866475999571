import type { FC } from 'react';

import Header from '@/Header';
import asScreen from '@/Screen';
import Flash from '@/Flash';
import Form from '@/Form';
import TextInputRow from '@/ui/TextInputRow';
import RadioButtonRow from '@/ui/RadioButtonRow';
import SelectRow from '@/ui/SelectRow';
import CopyEmailTemplate, { type CopyEmailTemplateProps } from '@/CopyEmailTemplate';
import Row from '@/ui/Row';

import TemplateTypeSubText from './TemplateTypeSubText';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import type { EmailTemplateModel } from '@models/EmailTemplateModel';

const AVAILABLE_LANGUAGES = [
  {
    name: 'Deutsch',
    value: 'de',
  },
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Español',
    value: 'es',
  },
  {
    name: 'Français',
    value: 'fr',
  },
  {
    name: 'Nederlands',
    value: 'nl',
  },
];

const TEMPLATE_TYPE_OPTIONS = [
  {
    label: 'Notification template',
    value: 'notification',
    appendedElement: (
      <TemplateTypeSubText
        text="The initial email sent to clients notifying them of the request, and providing the link they can click to choose
        a time."
      />
    ),
  },
  {
    label: 'Confirmation template',
    value: 'confirmation',
    appendedElement: (
      <TemplateTypeSubText
        text="The email sent to clients after they have selected a time, and confirming details of the appointment, with an
      attached calendar invite."
      />
    ),
  },
  {
    label: 'Reminder template',
    value: 'reminder',
    appendedElement: (
      <TemplateTypeSubText
        text="If you've configured any reminders to be sent, this email can be sent to clients to remind them if they've not
      chosen a time."
      />
    ),
  },
  {
    label: 'Cancellation template',
    value: 'cancellation',
    appendedElement: (
      <TemplateTypeSubText text="When a user or a client cancels an appointment, this email will be sent to confirm the cancellation." />
    ),
  },
];

interface Props {
  copyEmailTemplate: CopyEmailTemplateProps;
  emailTemplate: EmailTemplateModel;
}

const NewEmailTemplate: FC<Props> = ({
  copyEmailTemplate,
  emailTemplate: { errors, language, name, templateType },
}) => {
  return (
    <>
      <Header title="New Email Template" subtitle="Email Templates" />
      <Flash />

      <Form url="/settings/email_templates" method="post">
        <TextInputRow
          error={errors['name']?.[0]}
          label="Template Name"
          name="email_template[name]"
          value={name}
          width={6}
        />
        <RadioButtonRow
          helpText={<>&nbsp;</>}
          label="Type"
          name="email_template[template_type]"
          options={TEMPLATE_TYPE_OPTIONS}
          value={templateType}
        />
        <SelectRow
          label="Language"
          name="email_template[language]"
          options={AVAILABLE_LANGUAGES}
          value={language}
          width={6}
        />

        <Row label="Copy Existing Template">
          <CopyEmailTemplate {...copyEmailTemplate} />
        </Row>

        <hr />

        <Row>
          <button name="button" type="submit" className="btn btn-primary">
            Next &nbsp;
            <FontAwesomeIcon icon="angle-right" className="mt-1" />
          </button>
        </Row>
      </Form>
    </>
  );
};

export default asScreen(NewEmailTemplate);

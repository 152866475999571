import type { FC } from 'react';

import useDisplayDuration from '@shared/hooks/useDisplayDuration';
import { ordinalize } from '@shared/text';
import type ReminderModel from '@models/Reminder';

interface Props {
  reminders: ReminderModel[];
}

const Summary: FC<Props> = ({ reminders: initialReminders }) => {
  const displayDuration = useDisplayDuration();

  switch (initialReminders.length) {
    case 0:
      return <>No reminders are configured.</>;

    case 1: {
      const minutes = initialReminders[0].remindAfterMins;
      return <>1st reminder configured to send after {displayDuration(minutes)}; No 2nd reminder.</>;
    }

    default: {
      const reminders = initialReminders.map((r, i) => {
        return `${ordinalize(i + 1)} reminder configured to send after ${displayDuration(r.remindAfterMins)}`;
      });
      return <>{reminders.join('; ')}.</>;
    }
  }
};

export default Summary;

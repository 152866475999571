import type { FC } from 'react';
import { useContext, useState } from 'react';

import UnsavedConfirmationModal from '@/ui/UnsavedConfirmationModal';
import Expander from '@/ui/Expander';
import { DirtyContext } from '@/DirtyContext';
import PersonalTeamPage from '@/ui/PersonalTeamPage';
import asScreen from '@/Screen';
import AccountContext from '@/AccountContext';
import PlanContext from '@/PlanContext';
import Flash from '@/Flash';
import Header from '@/Header';
import { UsageStats } from '@/screens/UsageStats';

import AvailabilityConfig from './AvailabilityConfig';
import CalendarsConfig from './CalendarsConfig';
import DetailsConfig from './DetailsConfig';
import SecurityConfig from './SecurityConfig';
import UserCalendarsSummary from './UserCalendarsSummary';
import UserDetailsSummary from './UserDetailsSummary';
import UserSecuritySummary from './UserSecuritySummary';
import UserAvailabilitySummary from './UserAvailabilitySummary';

import type { AvailabilityBlock } from '@models/Availability';
import type AvailabilityModel from '@models/Availability';
import type { AttributeModel, LoginActivityModel } from '@models/User';
import type UserModel from '@models/User';
import type { RailsTimezone } from '@models/Timezone';
import type AppointmentTypeModel from '@models/AppointmentType';
import type { CalendarModel } from '@models/Calendar';

interface Props {
  user: UserModel;
  userErrors: { [attr: string]: string };
  attributes: AttributeModel[];
  availabilityBlocks: AvailabilityBlock[];
  availabilityErrors: { [attr: string]: string };
  availability: AvailabilityModel;
  calendars: CalendarModel[];
  integrationMembers: number[];
  lastLogins: LoginActivityModel[];
  psaUserId: string | null;
  roles: string[];
  timezones: RailsTimezone[];
  appointmentTypes: AppointmentTypeModel[];
  appointmentTypeIds: number[];
}

const User: FC<Props> = ({
  user,
  userErrors,
  attributes,
  availabilityBlocks,
  availabilityErrors,
  availability,
  calendars,
  integrationMembers,
  lastLogins,
  psaUserId,
  roles,
  timezones,
  appointmentTypes,
  appointmentTypeIds,
}) => {
  const { integrations } = useContext(AccountContext);
  const hasUserDetailsErrors = !!(userErrors.role || userErrors.slug);
  const hasAvailabilityErrors = Object.keys(availabilityErrors).some(e => !!availabilityErrors[e]);
  const hasAppointmentTypeIdsError = !!userErrors.appointmentTypeIds;

  const plan = useContext(PlanContext);

  const [dirty, setDirty] = useState(false);
  const [openExpander, setOpenExpander] = useState<string | null>(
    (hasUserDetailsErrors && 'userDetails') ||
      (hasAvailabilityErrors && 'availability') ||
      (hasAppointmentTypeIdsError && 'personalPage') ||
      null
  );

  const canExpand = openExpander === null || dirty === false;

  const handleToggleExpander = (section: string) => (expanded: boolean) => {
    setOpenExpander(expanded ? section : null);
  };

  return (
    <DirtyContext.Provider
      value={{
        handleDirty() {
          setDirty(true);
        },
      }}
    >
      <Header
        title={user.name}
        subtitle="User"
        headerButton={{
          color: 'red',
          icon: 'trash-alt',
          method: 'delete',
          text: 'Delete User',
          url: `/settings/users/${user.id}`,
        }}
      />
      <Flash />

      <UsageStats name={user.name} resourceId={user.externalId} />

      <Expander
        title="User Details"
        summary={<UserDetailsSummary user={user} attributes={attributes} attributesAllowed={plan.allowsCustomEmails} />}
        url={`/settings/users/${user.id}`}
        method="PATCH"
        icon="user-b"
        hasErrors={hasUserDetailsErrors}
        canExpand={canExpand}
        expanded={openExpander === 'userDetails'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('userDetails')}
      >
        <DetailsConfig
          role={user.role}
          roles={roles}
          slug={user.slug}
          attributes={attributes}
          attributesAllowed={plan.allowsCustomEmails}
          errors={userErrors}
          integrations={integrations}
          integrationMembers={integrationMembers}
        />
      </Expander>
      <Expander
        title="Availability"
        summary={
          <UserAvailabilitySummary
            availability={availability}
            availabilityBlocks={availabilityBlocks}
            availabilityAllowed={plan.allowsCustomAvailability}
          />
        }
        url={`/settings/users/${user.id}/availability`}
        method="PATCH"
        icon="wall-clock"
        hasErrors={hasAvailabilityErrors}
        canExpand={canExpand}
        expanded={openExpander === 'availability'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('availability')}
      >
        <AvailabilityConfig
          availabilityAllowed={plan.allowsCustomAvailability}
          availability={availability}
          availabilityBlocks={availabilityBlocks}
          errors={availabilityErrors}
          psaUserId={psaUserId}
          timezones={timezones}
        />
      </Expander>
      <Expander
        title="Calendars"
        summary={<UserCalendarsSummary calendars={calendars} />}
        url={user.schedulable ? `/settings/users/${user.id}/calendars` : null}
        method="PATCH"
        icon="calendar-favorite"
        hasErrors={false}
        canExpand={canExpand}
        expanded={openExpander === 'calendar'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('calendar')}
      >
        <CalendarsConfig calendars={calendars} />
      </Expander>
      <Expander
        title="Security"
        summary={<UserSecuritySummary twoFactorEnabled={user.twoFactorEnabled} lastLogins={lastLogins} />}
        icon="lock"
        hasErrors={false}
        canExpand={canExpand}
        expanded={openExpander === 'security'}
        dirty={dirty}
        onDirtyStatusUpdate={setDirty}
        onExpand={handleToggleExpander('security')}
      >
        <SecurityConfig lastLogins={lastLogins} twoFactorEnabled={user.twoFactorEnabled} userId={user.id} />
      </Expander>
      {plan.allowsShareableUrls && (
        <Expander
          title="Personal Page"
          summary={
            user.resourcePageEnabled
              ? `Personal page enabled. ${appointmentTypeIds.length} appointment ${
                  appointmentTypeIds.length > 1 ? 'types' : 'type'
                }  available for end users to book.`
              : 'Personal page disabled.'
          }
          url={plan.allowsShareableUrls && !!user.slug ? `/settings/users/${user.id}/resource_page` : null}
          method="PATCH"
          icon="layout"
          hasErrors={hasAppointmentTypeIdsError}
          canExpand={canExpand}
          expanded={openExpander === 'personalPage'}
          dirty={dirty}
          onDirtyStatusUpdate={setDirty}
          onExpand={handleToggleExpander('personalPage')}
        >
          {(() => {
            if (plan.allowsShareableUrls && !!user.slug) {
              return (
                <PersonalTeamPage
                  appointmentTypes={appointmentTypes}
                  appointmentTypeIds={appointmentTypeIds}
                  enabled={user.resourcePageEnabled}
                  error={userErrors.appointmentTypeIds}
                  page="user"
                  resourceSlug={user.slug}
                />
              );
            } else if (plan.allowsShareableUrls) {
              return (
                <p className="mb-4">
                  You need to assign a URL slug in the{' '}
                  <button
                    className="btn btn-link btn-inline text-decoration-none"
                    onClick={() => handleToggleExpander('userDetails')(true)}
                  >
                    User Details
                  </button>{' '}
                  section to enable the personal page for this user.
                </p>
              );
            } else {
              return (
                <>
                  <p className="fw-bold">Personal pages are only available to TimeZest Professional subscribers.</p>
                  <p className="mb-4">
                    They allow your end users to select the appointment type they wish to book from a list you
                    configure, giving your end users the flexibility to choose the appointment type they need.
                  </p>
                </>
              );
            }
          })()}
        </Expander>
      )}

      <UnsavedConfirmationModal dirty={dirty} />
    </DirtyContext.Provider>
  );
};

export default asScreen(User);

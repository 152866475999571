import { useContext, useEffect, useState } from 'react';

import type { Action as ActionModel } from '@graphql/generated';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

export default function useReviewableAction(action: ActionModel, expanded: boolean) {
  const { actionReviewable, expandedActionId } = useContext(WorkflowActionContext);

  const [needsReview, setNeedsReview] = useState(actionReviewable && action.reviewable);
  const [reviewed, setShowCheckIcon] = useState(actionReviewable && !action.reviewable);

  useEffect(() => {
    if (typeof expandedActionId === 'undefined') return;

    const hideNeedsReviewWhenClickOnSelf = !expanded && needsReview && expandedActionId === action.id;
    const hideNeedsReviewWhenClickOnOthers = expanded && needsReview && expandedActionId !== action.id;

    if (hideNeedsReviewWhenClickOnSelf || hideNeedsReviewWhenClickOnOthers) {
      setNeedsReview(false);
      setShowCheckIcon(true);
    }
  }, [expanded, expandedActionId, needsReview, action.id]);

  return { needsReview, reviewed };
}

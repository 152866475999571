import type { FC } from 'react';

interface Props {
  teamType: 'ProjectTeam' | 'ServiceTeam';
}

const ConnectWiseTeamDescription: FC<Props> = ({ teamType }) => {
  return (
    <div className="form-text text-muted small">
      Use an existing {teamType} team already defined in your ConnectWise PSA instance, and managed from there.
    </div>
  );
};

export default ConnectWiseTeamDescription;

import { createContext } from 'react';

interface Props {
  expandable: boolean;
  initialExpanded: 'all' | 'new' | 'none' | 'withErrors';
  actionReviewable: boolean;
  templateMode: boolean;
  expandedActionId?: number;
  handleActionId?: (id: number) => void;
  refetchWorkflowTriggers?: () => void;
}

export const WorkflowActionContext = createContext<Props>({
  expandable: false,
  initialExpanded: 'none',
  actionReviewable: false,
  expandedActionId: undefined,
  templateMode: false,
  handleActionId: undefined,
});

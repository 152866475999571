import { forwardRef, useContext, useMemo, useState } from 'react';

import ServiceTicketOptions from '@/connectWise/ServiceTicketOptions';

import FormError from '../FormError';
import { useCreateServiceTicketForConnectWise } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import RadioButtonRow from '@ui/RadioButtonRow';
import SummaryRow from '@ui/SummaryRow';
import type { ConnectWiseCreateServiceTicketAction, SummaryMode } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a new service ticket in ConnectWise PSA with the configured
      values.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When there is already a ticket associated with the scheduling request, unless &apos;Always create&apos; is
        selected.
      </li>
      <li>
        When there is no ConnectWise PSA contact associated with the scheduling request, as ConnectWise PSA requires one
        to create a ticket.
      </li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
    <p>
      If the &apos;Require the client to provide a description of their issue&apos; option is selected, TimeZest will
      ask the client for this when they schedule.
    </p>
  </>
);

const CreateServiceTicket = forwardRef<HTMLFormElement, WorkflowActionProps<ConnectWiseCreateServiceTicketAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { templateMode } = useContext(WorkflowActionContext);

    const { errors: mutationErrors, loading, succeeded, submit } = useCreateServiceTicketForConnectWise();
    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        boardId: data.get('[psa_board_id]') as string,
        createAlways: data.get('create_always') === 'true',
        priorityId: (data.get('[psa_priority_id]') || '') as string,
        sourceId: (data.get('[psa_source_id]') || '') as string,
        statusId: (data.get('[psa_status_id]') || '') as string,
        subTypeId: data.get('[psa_sub_type_id]') as string | null,
        summaryMode: data.get('new_entity_summary_mode') as SummaryMode,
        summaryText: (data.get('new_entity_summary') || '') as string,
        typeId: (data.get('[psa_type_id]') || '') as string,
      };
    });

    const [formEnabled, setFormEnabled] = useState(false);
    const handleOptionsLoaded = useMemo(() => {
      return () => setFormEnabled(true);
    }, [setFormEnabled]);

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="task-plus"
            summary={
              <>
                Create a <strong>service ticket</strong> in ConnectWise PSA.
              </>
            }
            readOnly={readOnly || !formEnabled}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="create_always"
              value={(action.createAlways || false).toString()}
              label="Creation Mode"
              readOnly={readOnly}
              options={[
                { label: 'Always create a new service ticket.', value: 'true', disabled: readOnly },
                {
                  label: "Create a service ticket only when one isn't associated with the scheduling request.",
                  value: 'false',
                  disabled: readOnly,
                },
              ]}
              error={errors.create_always}
            />

            <SummaryRow
              id={`action_${action.id.toString()}`}
              readOnly={readOnly}
              error={errors.summary_mode || errors.summary_text}
              appointmentNames={['ConnectWise PSA service ticket']}
              summary={action.summaryText || null}
              summaryMode={action.summaryMode || 'ask_client'}
            />

            <ServiceTicketOptions
              modelName=""
              disabled={readOnly}
              boardId={action.psaBoardId ? Number(action.psaBoardId) : null}
              boardIdAttr="psa_board_id"
              boardIdError={errors.psa_board_id}
              priorityId={action.psaPriorityId ? Number(action.psaPriorityId) : null}
              priorityIdAttr="psa_priority_id"
              priorityIdError={errors.psa_priority_id}
              statusId={action.psaStatusId ? Number(action.psaStatusId) : null}
              statusIdAttr="psa_status_id"
              statusIdError={errors.psa_status_id}
              sourceId={action.psaSourceId ? Number(action.psaSourceId) : null}
              sourceIdAttr="psa_source_id"
              sourceIdError={errors.psa_source_id}
              subTypeIdAttr="psa_sub_type_id"
              subTypeId={action.psaSubTypeId ? Number(action.psaSubTypeId) : null}
              subTypeIdError={errors.psa_sub_type_id}
              typeIdAttr="psa_type_id"
              typeIdError={errors.psa_type_id}
              typeId={action.psaTypeId ? Number(action.psaTypeId) : null}
              readOnly={readOnly}
              templateMode={templateMode}
              onLoaded={handleOptionsLoaded}
            />
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateServiceTicket;

import type { FC } from 'react';

import { formatDateLong } from '@shared/text';

import type { InvoiceEstimateModel } from '..';
import CardType from '../../Show/Billing/CardType';

interface Props {
  card: { type: string; last4: string } | null;
  invoiceEstimate: InvoiceEstimateModel;
}

const InvoiceEstimate: FC<Props> = ({ card, invoiceEstimate }) => {
  const { amountDue, nextBillingAt, credits } = invoiceEstimate;

  if (card && amountDue > 0) {
    return (
      <>
        <div>
          &mdash; Your&nbsp;
          <CardType cardType={card.type} last4={card.last4} /> will be charged{' '}
          <strong>US{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountDue)}</strong>{' '}
          immediately.
        </div>
        <div className="small text-muted mb-3">
          {nextBillingAt && (
            <>
              This amount is the difference in price from your current subscription, prorated to your next renewal on{' '}
              {formatDateLong(nextBillingAt)}.
            </>
          )}
        </div>
      </>
    );
  }

  if (amountDue === 0) {
    return (
      <p>
        &mdash; You do not have to pay anything right now, as you have credit of{' '}
        <strong>US{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(credits)}</strong> to
        cover new charges, however you will need to provide a credit card to be billed in the future.
      </p>
    );
  }

  return (
    <p>
      &mdash; You will need to provide a credit card, which will be charged{' '}
      <strong>US{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountDue)}</strong>{' '}
      immediately.
    </p>
  );
};

export default InvoiceEstimate;

import type { FC } from 'react';

import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetOpportunityTypesForConnectWiseQuery } from '@graphql/generated';
import RowError from '@shared/ui/RowError';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const TYPE_HELP_TEXT = 'TimeZest will create the opportunity in ConnectWise PSA with this type.';

interface Props {
  error: string | undefined;
  readOnly?: boolean;
  typeId: string;
  templateMode?: boolean;
}

const TypeSelect: FC<Props> = ({ typeId: initialTypeId, error, readOnly, templateMode }) => {
  const [typeId, setTypeId] = useStateFromProp(initialTypeId);

  const queryResult = useGetOpportunityTypesForConnectWiseQuery({ skip: templateMode });

  const type = (queryResult.data?.connectWise.opportunityTypes || []).find(t => t.id.toString() === typeId);

  const handleTypesChange = (value: string) => {
    if (value !== typeId) {
      setTypeId(value);
    }
  };

  const queryErrorMessage = (
    <RowError
      helpText={TYPE_HELP_TEXT}
      label="Type"
      type={queryResult.error && convertError(queryResult.error).type}
      unauthorizedError="TimeZest could not retrieve opportunity type details from ConnectWise PSA because the security role does not have sufficient permissions."
      unauthorizedRemediation={
        <>
          Please ensure that the <strong>Table Setup</strong> permission in the <strong>System</strong> section of the
          security role has <strong>Opportunities / Type</strong> in the <strong>Allow Access to These</strong> column.
        </>
      }
      error="Something went wrong when TimeZest attempted to load opportunity types from ConnectWise PSA."
    />
  );

  const options = (s: { id: number; description: string }) => {
    return { name: s.description, value: s.id.toString() };
  };

  return (
    <>
      <SelectRowFromGraphQL
        helpText={TYPE_HELP_TEXT}
        label="Type"
        name="type_id"
        field="connectWise.opportunityTypes"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Use default value configured in ConnectWise PSA"
        emptyDataMessage="No opportunity types found."
        templateMode={templateMode}
        readOnly={readOnly}
        error={error}
        value={typeId}
        queryErrorMessage={queryErrorMessage}
        queryResult={queryResult}
        optionRenderer={options}
        onChange={handleTypesChange}
      />

      <input type="hidden" name="type_description" value={type?.description || ''} />
    </>
  );
};

export default TypeSelect;

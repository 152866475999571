import type { FC } from 'react';

import ActiveMembers from '../ActiveMembers';

import type { Member } from '@models/TeamMember';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  activatedMembers: Member[];
  unactivatedMembers: Member[];
}

const ActiveMembersList: FC<Props> = ({ activatedMembers, unactivatedMembers }) => {
  return (
    <>
      <p className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp;&nbsp;
        <ActiveMembers activatedMembers={activatedMembers} unactivatedMembers={unactivatedMembers} />
      </p>
      <input type="submit" value="Add Team to TimeZest >" className="btn btn-primary" />
    </>
  );
};

export default ActiveMembersList;

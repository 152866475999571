import type { ContentState } from 'draft-js';
import { EditorState, Modifier, RichUtils } from 'draft-js';

export const upsertLink = (editorState: EditorState, url: string): [string, EditorState] => {
  const contentState = editorState.getCurrentContent();

  const contentStateWithEntity = contentState.createEntity('LINK', 'IMMUTABLE', { url: url });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

  return [entityKey, newEditorState];
};

export const upsertVariable = (
  editorState: EditorState,
  variableValue: string,
  variableName: string
): [ContentState, EditorState] => {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  const contentStateWithEntity = content.createEntity('{mention', 'IMMUTABLE', {
    mention: {
      name: variableName,
      variable: variableValue,
    },
  });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const textWithEntity = Modifier.replaceText(content, selection, variableName, undefined, entityKey);

  const newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');

  return [textWithEntity, newEditorState];
};

export const getLinkUrl = (editorState: EditorState | undefined): string => {
  if (!editorState) return '';

  const [contentState, entityKey] = getEntityKeyAndContentState(editorState);

  if (!entityKey || contentState.getEntity(entityKey).getType() !== 'LINK') return '';

  const url = contentState.getEntity(entityKey).getData().url;

  // When url length is 0, we want to display it as it is, and not forcing the input box to jump back to "https://"" text
  if (url.length === 0) {
    return '';
  }

  return url ? url : 'https://';
};

export const isCursorAtLink = (editorState: EditorState | undefined): boolean => {
  if (!editorState) return false;

  const [contentState, entityKey] = getEntityKeyAndContentState(editorState);

  return entityKey ? contentState.getEntity(entityKey).getType() === 'LINK' : false;
};

export const isNormalTextHighlighted = (editorState: EditorState | undefined): boolean => {
  if (!editorState) return false;

  const [_, entityKey] = getEntityKeyAndContentState(editorState);

  return !editorState?.getSelection().isCollapsed() && !entityKey;
};

export const getEntityKeyAndContentState = (editorState: EditorState): [ContentState, string] => {
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithEntity = contentState.getBlockForKey(startKey);
  const entityKey = blockWithEntity.getEntityAt(startOffset);

  return [contentState, entityKey];
};

export const removeLink = (editorState: EditorState): EditorState => {
  return RichUtils.toggleLink(editorState, editorState.getSelection(), null);
};

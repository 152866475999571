import type { FC } from 'react';
import { forwardRef } from 'react';

import FormError from '../FormError';
import { useCreateNoteOnTicketForConnectWise } from '../hooks';
import BaseError from '../BaseError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import type { ConnectWiseCreateNoteOnTicketAction } from '@graphql/generated';
import type { Content } from '@shared/NoteEditor';
import NoteEditor, { defaultTemplate } from '@shared/NoteEditor';
import Row from '@ui/Row';
import Switch from '@ui/Switch';
import ErrorBoundary from '@shared/ErrorBoundary';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will create a note on the ConnectWise PSA ticket associated with the
      scheduling request, interpolating actual values for variables into the note body.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul>
      <li>When there is no ConnectWise PSA ticket associated with the scheduling request.</li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
  </>
);

interface SummaryProps {
  discussionNote: boolean | undefined;
  internalNote: boolean | undefined;
  resolutionNote: boolean | undefined;
}

const Summary: FC<SummaryProps> = ({ discussionNote, internalNote, resolutionNote }) => {
  let description: string[] = [];

  if (discussionNote) {
    description.push('discussion');
  }

  if (internalNote) {
    description.push('internal');
  }

  if (resolutionNote) {
    description.push('resolution');
  }

  const adjectives = description.join('/');
  const article = adjectives[0] === 'i' ? 'an' : 'a';

  return (
    <>
      Add {article} <strong>{adjectives} note</strong> to a ConnectWise PSA ticket.
    </>
  );
};

interface Props {
  template: Content;
}

const CreateNoteOnTicket = forwardRef<HTMLFormElement, WorkflowActionProps<ConnectWiseCreateNoteOnTicketAction, Props>>(
  ({ template, action, readOnly, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateNoteOnTicketForConnectWise();

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        discussionNote: data.get('discussion_note') === 'true',
        internalNote: data.get('internal_note') === 'true',
        resolutionNote: data.get('resolution_note') === 'true',
        template: (data.get('template') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="chat-plus"
            summary={
              <Summary
                internalNote={action.internalNote}
                discussionNote={action.discussionNote}
                resolutionNote={action.resolutionNote}
              />
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <Row label="Note Content" width={10}>
              <NoteEditor name="template" initialValue={template || defaultTemplate} readOnly={readOnly} />
            </Row>

            <Row label="Note Type" withInput={false}>
              <Switch
                value={action.internalNote || false}
                attr="internal_note"
                namespace={action.id.toString()}
                label="Internal Note"
                disabled={readOnly}
                error={errors.internal_note}
              />
              <Switch
                value={action.discussionNote || false}
                attr="discussion_note"
                namespace={action.id.toString()}
                label="Discussion Note"
                disabled={readOnly}
                error={errors.discussion_note}
              />
              <Switch
                value={action.resolutionNote || false}
                attr="resolution_note"
                namespace={action.id.toString()}
                label="Resolution Note"
                disabled={readOnly}
                error={errors.resolution_note}
              />
            </Row>
            {errors.base && <BaseError errorMessage={errors.base} />}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateNoteOnTicket;

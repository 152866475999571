import type { FC } from 'react';
import { useState } from 'react';

import { Controlled as CodeMirror } from 'react-codemirror2';
import type { Editor, EditorChange } from 'codemirror';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neo.css';
import 'codemirror/mode/htmlmixed/htmlmixed.js';

import './stylesheets/codemirror_overrides.css';

interface Props {
  value: string;
  readOnly: boolean;
  onChange?: (newValue: string) => void;
}

const HtmlEditor: FC<Props> = ({ value: initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleBeforeChange = (_editor: Editor, _data: EditorChange, value: string): void => {
    setValue(value);
    if (onChange) onChange(value);
  };

  return (
    <>
      <CodeMirror
        value={value}
        options={{ mode: 'htmlmixed', theme: 'neo', readOnly: 'nocursor' }}
        onBeforeChange={handleBeforeChange}
      />
      <input id="templateHtml" type="hidden" name="email_template[template_html]" value={value} />
    </>
  );
};

export default HtmlEditor;

import type { FC } from 'react';

import SectionWithOneColumn from './SectionWithOneColumn';
import SectionWithTwoColumns from './SectionWithTwoColumns';
import SectionWithThreeColumns from './SectionWithThreeColumns';
import SectionWithFourColumns from './SectionWithFourColumns';

const Section: FC<{}> = () => {
  return (
    <div className="d-flex flex-column gap-2">
      <SectionWithOneColumn />
      <SectionWithTwoColumns />
      <SectionWithThreeColumns />
      <SectionWithFourColumns />
    </div>
  );
};

export default Section;

import type { FC } from 'react';
import { forwardRef, useContext } from 'react';

import EmailTemplateEditor, { ConfigContext } from '@/EmailEditor/EmailEditor';
import PlanContext from '@/PlanContext';

import { useSendEmailForTimeZest } from '../hooks';
import { INITIAL_CALENDAR_TEMPLATE, INITIAL_TEMPLATE, RECIPIENTS } from '../constants';
import FormError from '../FormError';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { EmailTemplate } from '@models/EmailTemplate';
import Action from '@shared/Action';
import type { Content as LineContent } from '@shared/LineEditor';
import LineEditor, { defaultTemplate as defaultLineTemplate } from '@shared/LineEditor';
import type { Content as NoteContent } from '@shared/NoteEditor';
import NoteEditor from '@shared/NoteEditor';
import RadioButtonRow from '@ui/RadioButtonRow';
import Row from '@ui/Row';
import SelectRow from '@ui/SelectRow';
import type { TimeZestSendEmailAction } from '@graphql/generated';
import { TimeZestSendEmailFromTemplateRecipientsType } from '@graphql/generated';
import ErrorBoundary from '@shared/ErrorBoundary';

import { Context as AvailableOptionsContext } from '../../AvailableOptions';
import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">
      When TimeZest executes this action, it will send an email to the client, or <strong>all</strong> scheduled users,
      using the configured template. Templates can be edited in <a href="/settings/email">Email Settings</a>.
    </p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When configured to send to scheduled users, and TimeZest has not yet selected which users are to be scheduled.
      </li>
    </ul>
    <p>
      TimeZest decides which users to schedule (primarily when scheduling one user from a team), based on availability,
      when these users select a time for their appointment. For triggers which run before the client has selected a
      time, these users will not be known.
    </p>
  </>
);

const Summary: FC<{ recipients: string; guestsListAllowed: boolean }> = ({ recipients, guestsListAllowed }) => (
  <>Send an email to the {recipient(recipients, guestsListAllowed)}.</>
);

const SendEmail = forwardRef<HTMLFormElement, WorkflowActionProps<TimeZestSendEmailAction>>(
  ({ action, readOnly, saveable }, ref) => {
    const { availableLanguages } = useContext(AvailableOptionsContext);
    const { defaultEmailTemplate } = useContext(ConfigContext);
    const { allowsGuestInvitations: guestsListAllowed } = useContext(PlanContext);

    const recipientsList = guestsListAllowed
      ? RECIPIENTS
      : RECIPIENTS.filter(r => r.value !== TimeZestSendEmailFromTemplateRecipientsType.ClientWithGuests);
    const recipientOptions = recipientsList.map(r => ({ ...r, disabled: readOnly }));
    const { errors: mutationErrors, loading, succeeded, submit } = useSendEmailForTimeZest();
    const [calendarInvite, setCalendarInvite] = useStateFromProp(action.attachCalendarInvite);
    const [recipients, setRecipients] = useStateFromProp(
      action.recipients,
      recipients => recipients || (guestsListAllowed ? 'client_with_guests' : 'client')
    );

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      const attachCalendarInvite = data.get('attach_calendar_invite') === 'true';
      const calendarTemplate = attachCalendarInvite
        ? ((data.get('calendar_template') || '') as string)
        : JSON.stringify(action.calendarTemplate || INITIAL_CALENDAR_TEMPLATE);

      return {
        attachCalendarInvite,
        calendarTemplate,
        language: data.get('language') as string,
        location: data.get('location') as string,
        recipients: data.get('recipients') as TimeZestSendEmailFromTemplateRecipientsType,
        subject: data.get('subject') as string,
        templateJson: data.get('template_json') as Record<string, any>,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="email"
            summary={
              <Summary
                recipients={action.recipients || (guestsListAllowed ? 'client_with_guests' : 'client')}
                guestsListAllowed={guestsListAllowed}
              />
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}_recipients`}
              label="Recipient"
              name="recipients"
              value={!guestsListAllowed ? (recipients === 'client_with_guests' ? 'client' : recipients) : recipients}
              options={recipientOptions}
              error={errors.recipients}
              readOnly={readOnly}
              onChange={r => setRecipients(r as TimeZestSendEmailFromTemplateRecipientsType)}
            />

            <SelectRow
              label="Language"
              name="language"
              options={(availableLanguages || []).map(([value, label]) => ({ name: label, value: value }))}
              error={errors.language}
              disabled={readOnly}
              value={action.language || 'en'}
              helpText="The language setting tells TimeZest how to translate the variables it inserts into the template."
            />

            <Row label="Subject">
              <LineEditor
                name="subject"
                initialValue={(action.subject || defaultLineTemplate) as LineContent}
                error={errors.subject}
                readOnly={readOnly}
              />
            </Row>

            <Row label="Compose">
              <EmailTemplateEditor
                initialTemplate={(action.templateJson as EmailTemplate) || defaultEmailTemplate || INITIAL_TEMPLATE}
                name="template_json"
              />
            </Row>

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              label="Calendar Invite"
              name="attach_calendar_invite"
              value={(calendarInvite || false).toString()}
              options={[
                { label: 'Send email with a calendar invite attached.', value: 'true', disabled: readOnly },
                { label: 'Send email without a calendar invite attached.', value: 'false', disabled: readOnly },
              ]}
              error={errors.attach_calendar_invite}
              readOnly={readOnly}
              onChange={newValue => setCalendarInvite(newValue === 'true')}
            />

            {calendarInvite && (
              <>
                <Row label="Calendar Template" width={10}>
                  <NoteEditor
                    name="calendar_template"
                    initialValue={(action.calendarTemplate || INITIAL_CALENDAR_TEMPLATE) as NoteContent}
                    readOnly={readOnly}
                  />
                </Row>

                <Row
                  label="Location"
                  helpText={
                    <>
                      TimeZest will use value of this custom attribute in the location field of the calendar invite sent
                      to the {recipient(recipients, guestsListAllowed)}.
                    </>
                  }
                >
                  <LineEditor
                    name="location"
                    initialValue={(action.location || defaultLineTemplate) as LineContent}
                    readOnly={readOnly}
                  />
                </Row>
              </>
            )}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

function recipient(recipients: string, guestsListAllowed: boolean): string {
  switch (recipients) {
    case 'client':
      return 'client';
    case 'client_with_guests':
      if (guestsListAllowed) {
        return 'client and any invited guests';
      }

      return 'client';
    default:
      return 'scheduled user(s)';
  }
}

export default SendEmail;

import type { FC, KeyboardEvent } from 'react';

import classNames from 'classnames';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

import type { ItemModel } from '.';

interface Props extends ItemModel {
  maxSelectedItems?: number;
  minSelectedItems?: number;
  selectedItems: ItemModel[];
  onUpdateSelectedItems: (items: ItemModel[]) => void;
}

const SelectedItem: FC<Props> = ({
  id,
  name,
  type,
  minSelectedItems,
  maxSelectedItems,
  selectedItems,
  onUpdateSelectedItems,
}) => {
  type NewType = KeyboardEvent<HTMLButtonElement>;

  const handleRemoveItem = ({ id, event }: { id: string; event: React.MouseEvent | NewType }) => {
    event.preventDefault();
    event.stopPropagation();

    const updatedSelectedItems = selectedItems.filter(item => item.id !== id);
    onUpdateSelectedItems(updatedSelectedItems);
  };

  const handleClick = event => {
    handleRemoveItem({ event, id });
  };

  const handleKeyDown = event => {
    if (event.key !== 'Enter') return;

    handleRemoveItem({ event, id });
  };

  const canBeRemoved = typeof minSelectedItems !== 'undefined' ? minSelectedItems < selectedItems.length : true;
  const multi = typeof maxSelectedItems !== 'undefined' && maxSelectedItems > 1;

  return (
    <div key={id} className={classNames('select-selection', { multi })}>
      {name}

      {type && type === 'team' && <FontAwesomeIcon icon="user-friends" className="team-icon" />}

      {canBeRemoved && (
        <button type="button" onClick={handleClick} onKeyDown={handleKeyDown}>
          <FontAwesomeIcon icon="times" className="remove-icon" />
        </button>
      )}
    </div>
  );
};

export default SelectedItem;

import type { FC } from 'react';
import { useEffect } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { useGetBoardsForConnectWiseQuery } from '@graphql/generated';

interface Props {
  name: string;
  label: string;
  helpText: string;
  type: 'service' | 'project';
  selectedBoardId: string | null;
  disabled?: boolean;
  readOnly?: boolean;
  error?: string;
  templateMode?: boolean;
  onBoardIdChange?: (id: number | undefined, name: string | undefined) => void;
  onLoaded?: () => void;
}

// This component can only be used within another component which sits
// within an ApolloProvider component which provides access to the
// Apollo client through the context.
const BoardSelect: FC<Props> = ({
  disabled,
  error,
  label,
  helpText,
  name,
  type,
  selectedBoardId,
  templateMode,
  readOnly,
  onBoardIdChange,
  onLoaded,
}) => {
  const queryResult = useGetBoardsForConnectWiseQuery({
    skip: templateMode,
    variables: {
      excludeProjects: type === 'service',
      excludeServices: type === 'project',
    },
  });

  const loaded = !queryResult.loading && !queryResult.error && queryResult.data;

  const handleBoardChange = (value: string | undefined): void => {
    const id = typeof value !== 'undefined' ? value : undefined;
    const boards = queryResult?.data?.connectWise?.queues;
    const board = ((boards as any) || []).find(s => s.id.toString() === id);

    if (onBoardIdChange) onBoardIdChange(Number(id), board?.name);
  };

  useEffect(() => {
    if (loaded && onLoaded) onLoaded();
  }, [loaded, onLoaded]);

  return (
    <SelectRowFromGraphQL
      name={name}
      value={(selectedBoardId || '').toString()}
      queryErrorMessage={`Error loading ${type} teams from ConnectWise PSA.`}
      loadingMessage="Loading from ConnectWise PSA..."
      error={error}
      prompt="Select a board..."
      templateMode={templateMode}
      field="connectWise.queues"
      queryResult={queryResult}
      label={label}
      helpText={helpText}
      disabled={disabled}
      readOnly={readOnly}
      onChange={handleBoardChange}
    />
  );
};

export default BoardSelect;

import { type FC, useContext } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';
import Form from '@/Form';
import TextInputRow from '@/ui/TextInputRow';
import AccountContext from '@/AccountContext';
import Row from '@/ui/Row';

import { psaName } from '@shared/utilities';

interface Props {
  errors: Record<string, string>;
  name: string;
  externalName: string;
}

const NewTimezestTeam: FC<Props> = ({ errors, name, externalName }) => {
  const { psaType } = useContext(AccountContext);

  return (
    <>
      <Header title="Add New TimeZest-Defined Team" subtitle="Teams" />
      <Flash />

      <Form url="/settings/teams/timezest">
        <TextInputRow
          label="Internal Name"
          name="time_zest_team[internal_name]"
          helpText={`The name of the team used within TimeZest and ${psaName(psaType)}.`}
          error={errors.internalName}
          value={name}
        />

        <TextInputRow
          label="External Name"
          name="time_zest_team[external_name]"
          helpText="The name of this team we'll use when communicating with your clients. If left blank, TimeZest will use the internal name."
          value={externalName}
        />

        <hr />

        <Row>
          <input
            className="btn btn-primary"
            type="submit"
            value="Create New Team"
            data-disable-with="Create New Team"
          />
        </Row>
      </Form>
    </>
  );
};

export default asScreen(NewTimezestTeam);

{
  "units": {
    "minute_one": "1 minute",
    "minute_other": "{{count}} minutes",
    "hour_one": "1 hour",
    "hour_other": "{{count}} hours"
  },
  "scheduling_ui": {
    "appointment_details": {
      "appointment": "Appointment",
      "with": "With",
      "date": "Date",
      "ticket": "Ticket",
      "time": "Time",
      "duration": "Duration"
    },
    "appointment_selector": {
      "instruction": "Select a date and time for your {{appointment_name}} with {{appointment_resources}}.",
      "instruction_reschedule": "Select a new date and time for your {{appointment_name}} with {{appointment_resources}}."
    },
    "date_selector": {
      "loading": "Searching for dates with available appointments..."
    },
    "select_prompt": {
      "prompt": "Select a date to see available times."
    },
    "timezone_selector": {
      "indicator": "All times are in {{timezone}} time.",
      "change": "Change"
    },
    "time_selector": {
      "no_available_time": {
        "notification": "There are no times available on this date.",
        "instruction": "Please choose a different day."
      },
      "available_time": "Times shown below are in the <strong>{{timezone}}</strong> time zone. If you're in a different timezone, you can change this below."
    }
  }
}

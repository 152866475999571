import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';

import Row from '@ui/Row';
import NumberInput from '@shared/ui/Inputs/NumberInput';

interface Props {
  label: string;
  name: string;
  value: number | null;
  disabled?: boolean;
  error?: string;
  helpText?: string | JSX.Element | null;
  warning?: string | JSX.Element;
  min?: number;
  max?: number;
  step?: number;
}

const TextInputRow: FC<Props> = ({
  disabled,
  error,
  warning,
  helpText,
  label,
  max,
  min,
  name,
  step,
  value: initialValue,
}) => {
  const { handleDirty } = useContext(DirtyContext);
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleDirty) {
      handleDirty();
    }

    setValue(event.target.value !== '' ? Number(event.target.value) : null);
  };

  return (
    <Row label={label} helpText={helpText}>
      <NumberInput
        data-lpignore="true"
        disabled={disabled}
        id={name}
        name={name}
        error={error}
        max={max}
        min={min}
        step={step}
        value={value !== null ? value.toString() : min?.toString() || ''}
        onChange={handleChange}
      />
      {warning && <div className="small text-muted mt-1">{warning}</div>}
    </Row>
  );
};

export default TextInputRow;

import type { FC } from 'react';

import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import { PlainText } from '../utilities';

import { useGetTeamsForConnectWiseQuery } from '@graphql/generated';

interface Props {
  name: string;
  boardId: number | undefined;
  label: string;
  type: 'service' | 'project';
  selectedTeamId: number | undefined;
  error?: string;
  onTeamIdChange: (id: number | undefined) => void;
}

// This component can only be used within another component which sits
// within an ApolloProvider component which provides access to the
// Apollo client through the context.
const TeamSelect: FC<Props> = ({ name, type, label, boardId, selectedTeamId, error, onTeamIdChange }) => {
  const queryResult = useGetTeamsForConnectWiseQuery({
    variables: {
      board: boardId || 0,
      includeServiceTeams: type === 'service',
      includeProjectTeams: type === 'project',
    },
    skip: boardId === undefined,
  });

  const handleTeamChange = (value: string | undefined): void => {
    const id = value || undefined;

    if (onTeamIdChange) onTeamIdChange(Number(id));
  };

  if (boardId === undefined) {
    return <PlainText>Please select a board.</PlainText>;
  }

  return (
    <SelectRowFromGraphQL
      name={name}
      label={label}
      helpText=""
      error={error}
      value={selectedTeamId?.toString() || ''}
      loadingMessage="Loading from ConnectWise PSA..."
      emptyDataMessage={`No Teams have been defined for this ${type} board in ConnectWise PSA.`}
      queryErrorMessage={`Error loading ${type} teams from ConnectWise PSA.`}
      field={`connectWise.${type}Teams`}
      prompt={`Select a ${type} team...`}
      queryResult={queryResult}
      onChange={handleTeamChange}
    />
  );
};

export default TeamSelect;

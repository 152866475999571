import { type FC, useEffect } from 'react';

import asScreen from '@/Screen';
import Header from '@/Header';
import Flash from '@/Flash';

import QuickEdits from './QuickEdits';
import UsersList from './UsersList';

import { refreshCurrentPage } from '@shared/utilities';

import IntegrationStatus from '../../IntegrationStatus';
import type { IntegrationSettings } from '../../type';

export type QuickEdit = { name: string; description: string; url: string };
export type User = {
  name: string;
  integrationMember: {
    integrationUserId: string;
    hasAppInstalled: boolean;
    processing: boolean;
  } | null;
};

interface TeamsMessagingSettings extends IntegrationSettings {
  persisted: boolean;
  teamsInstalled: boolean;
  quickEdits: QuickEdit[];
}

interface Props {
  authUrl: string;
  integration: TeamsMessagingSettings | null;
  users: User[];
  usersCount: number;
  usersPerPage: number;
}

const TeamsMessaging: FC<Props> = ({ authUrl, integration, users, usersCount, usersPerPage }) => {
  const integrationIsProcessing = integration ? integration.processing : false;
  const showInstructionMessage = !integration || (integration.persisted && integration.teamsInstalled);
  const showAssociationMessage = integration && !integration.teamsInstalled;
  const processingMember = integration ? users.some(u => u.integrationMember?.processing) : false;

  useEffect(() => {
    if (integrationIsProcessing) {
      refreshCurrentPage(5000);
    }

    if (processingMember) {
      refreshCurrentPage(2000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header title="Microsoft Teams (Messaging) integration" />
      <Flash />
      <IntegrationStatus
        integration={integration}
        authUrl={authUrl}
        removeIntegrationUrl="/settings/integrations/teams_messaging"
        integrationIsProcessing={integrationIsProcessing}
      />
      <hr />
      {showInstructionMessage && (
        <>
          <p>
            TimeZest&apos;s Microsoft Teams (Messaging) integration allows posting messages to Microsoft Teams channels
            and users – for example, to notify a channel that an appointment has been scheduled or cancelled.
          </p>
          <p>
            Posting messages to Microsoft Teams channels and users is configured on a per-appointment-type basis &mdash;
            after connecting the integration, go to <a href="/settings/appointment_types">Appointment Types</a> to
            configure it for your appointment types.
            <QuickEdits quickEdits={integration?.quickEdits || []} />
          </p>
        </>
      )}
      {showAssociationMessage && (
        <>
          {integration.processing ? (
            <p>TimeZest is currently associating users with their Teams user accounts.</p>
          ) : (
            <p>TimeZest has finished associating users with their Teams user accounts successfully.</p>
          )}
          <p>To enable posting messages to channels and users you need to install TimeZest Teams application.</p>
          <a className="btn btn-primary" href="/settings/integrations/teams_messaging/install_app">
            Install TimeZest Teams app
          </a>
        </>
      )}
      {integration && (
        <>
          <hr />
          <UsersList users={users} usersCount={usersCount} usersPerPage={usersPerPage} />
        </>
      )}
    </>
  );
};

export default asScreen(TeamsMessaging);

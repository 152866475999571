import type { FC } from 'react';

import PotentialMember from '@/ui/PotentialMembers/PotentialMember';

import type { Member } from '@models/TeamMember';

interface Props {
  actualSearchTerm: string;
  searchTerm: string;
  potentialMembers: Member[];
  addMember: (memberId: number) => void;
}

const PotentialMembers: FC<Props> = ({ actualSearchTerm, searchTerm, potentialMembers, addMember }) => {
  if (actualSearchTerm !== '' && potentialMembers.length === 0) {
    return <div className="mb-3 small text-muted">No matching users were found.</div>;
  } else if (searchTerm === '') {
    return (
      <div className="mb-3 small text-muted">
        Add users to this team by searching for them by their name or email address.
      </div>
    );
  } else {
    return (
      <>
        {potentialMembers.map(member => {
          const boundAddMember = addMember.bind(null, member.id);
          return <PotentialMember key={member.id} addMember={boundAddMember} {...member} />;
        })}
      </>
    );
  }
};

export default PotentialMembers;

// DEPRECATED

import type { FC } from 'react';

import Form from '@/Form';

import type { IconName } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  text: string;
  icon?: IconName;
  url: string;
  color: 'red' | 'blue' | 'yellow';
  method?: 'get' | 'post' | 'delete' | 'patch';
}

const COLOR = {
  red: 'btn-danger',
  blue: 'btn-primary',
  yellow: 'btn-warning',
};

const HeaderButton: FC<Props> = ({ icon, url, color, text, method = 'get' }) => {
  return (
    <>
      {method === 'get' ? (
        <a className={`btn ${COLOR[color]}`} href={url}>
          {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
          {text}
        </a>
      ) : (
        <Form className="d-inline-block" method={method} url={url}>
          <button
            className={`btn ${COLOR[color]}`}
            {...(method === 'delete' && { 'data-confirm': 'Are you sure?' })}
            type="submit"
          >
            {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
            {text}
          </button>
        </Form>
      )}
    </>
  );
};

export default HeaderButton;

import type { FC } from 'react';

import Badge from '@ui/Badge';

interface Props {
  role: string;
}

const Role: FC<Props> = ({ role }) => {
  if (role !== 'administrator') return null;

  return <Badge color="blue">Administrator</Badge>;
};

export default Role;

import type { FC } from 'react';

import useDisplayDuration from '@shared/hooks/useDisplayDuration';
import type { AppointmentType } from '@graphql/generated';

interface Props {
  appointmentType: AppointmentType;
}

const Header: FC<Props> = ({ appointmentType }) => {
  const displayDuration = useDisplayDuration();

  return (
    <div className="appointment-type-header rounded-top px-4 py-2">
      <div className="text-truncate">
        <strong>{appointmentType.internalName}</strong>
        {appointmentType.externalName && (
          <span className="text-muted">&nbsp;/&nbsp;{appointmentType.externalName}</span>
        )}
      </div>

      <p className="text-muted small mb-0 mt-1">{displayDuration(appointmentType.durationMins)}</p>
    </div>
  );
};

export default Header;

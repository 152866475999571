import { type FC, useEffect } from 'react';

import asScreen from '@/Screen';
import Flash from '@/Flash';
import Header from '@/Header';

import type { IntegrationSettings } from '../type';
import IntegrationStatus from '../IntegrationStatus';

import { refreshCurrentPage } from '@shared/utilities';

import UsersList from '../../UsersList';

interface Props {
  authUrl: string;
  integration: IntegrationSettings | null;
  users: { connected: boolean; name: string }[];
  usersCount: number;
  usersPerPage: number;
}

const HubSpot: FC<Props> = ({ authUrl, integration, users, usersCount, usersPerPage }) => {
  const integrationIsProcessing = integration ? integration.processing : false;

  useEffect(() => {
    if (integrationIsProcessing) {
      refreshCurrentPage(5000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header title="HubSpot Integration" />
      <Flash />

      <IntegrationStatus
        integration={integration}
        authUrl={authUrl}
        removeIntegrationUrl="/settings/integrations/hub_spot"
        integrationIsProcessing={integrationIsProcessing}
      />

      <hr />

      <p>
        TimeZest&apos;s integration with HubSpot allows TimeZest to create contacts and meetings in your HubSpot
        account.
      </p>
      <p>
        Adding a HubSpot contact and meeting to an appointment is configured on a per-appointment type basis. After
        connecting the integration, go to <a href="/settings/appointment_types">Appointment Types</a> to configure it
        for your appointment types.
      </p>

      {integration && (
        <>
          <hr />
          <UsersList
            users={users}
            usersCount={usersCount}
            usersPerPage={usersPerPage}
            connectedMessage="This user is associated with a HubSpot user account."
            notConnectedMessage="This user is not associated with a HubSpot user account."
          />
        </>
      )}
    </>
  );
};

export default asScreen(HubSpot);

import type {
  Action,
  AutotaskCreateCompanyTodoAction,
  AutotaskCreateContactAction,
  AutotaskCreateNoteOnTicketAction,
  AutotaskCreateServiceCallAction,
  AutotaskCreateServiceTicketAction,
  AutotaskUpdateTicketStatusAction,
  AutotaskUpdateTicketToPresetStatusAction,
  AutotaskFindOrCreateOpportunityAction,
} from '@graphql/generated';

export function isAutotaskCreateCompanyTodoAction(action: Action): action is AutotaskCreateCompanyTodoAction {
  return action.slug === 'autotask/create_company_todo';
}

export function isAutotaskCreateContactAction(action: Action): action is AutotaskCreateContactAction {
  return action.slug === 'autotask/create_contact';
}

export function isAutotaskCreateNoteOnTicketAction(action: Action): action is AutotaskCreateNoteOnTicketAction {
  return action.slug === 'autotask/create_note_on_ticket';
}

export function isAutotaskCreateServiceCallAction(action: Action): action is AutotaskCreateServiceCallAction {
  return action.slug === 'autotask/create_service_call';
}

export function isAutotaskCreateServiceTicketAction(action: Action): action is AutotaskCreateServiceTicketAction {
  return action.slug === 'autotask/create_service_ticket';
}

export function isAutotaskFindOrCreateOpportunityAction(
  action: Action
): action is AutotaskFindOrCreateOpportunityAction {
  return action.slug === 'autotask/find_or_create_opportunity';
}

export function isAutotaskUpdateTicketStatusAction(action: Action): action is AutotaskUpdateTicketStatusAction {
  return action.slug === 'autotask/update_ticket_status';
}

export function isAutotaskUpdateTicketToPresetStatusAction(
  action: Action
): action is AutotaskUpdateTicketToPresetStatusAction {
  return action.slug === 'autotask/update_ticket_to_preset_status';
}

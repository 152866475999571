import type { FC } from 'react';
import { forwardRef, useContext } from 'react';

import CompanySearch from '@/ui/CompanySearch';
import { convertError } from '@/queries';
import SelectRowFromGraphQL from '@/SelectRowFromGraphQL';

import FormError from '../FormError';
import { useCreateContactForConnectWise } from '../hooks';

import useFormSubmit from '@shared/hooks/useFormSubmit';
import Action from '@shared/Action';
import Row from '@ui/Row';
import RadioButtonRow from '@ui/RadioButtonRow';
import type { ConnectWiseCreateContactAction, CreateContactMode } from '@graphql/generated';
import { useGetCompanyPropsQuery } from '@graphql/generated';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import RowError from '@shared/ui/RowError';
import ErrorBoundary from '@shared/ErrorBoundary';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';

import type { WorkflowActionProps } from '../../types';

const DETAILS = (
  <>
    <p className="mb-1">When TimeZest executes this action, it will create a new contact in ConnectWise PSA.</p>
    <p className="mb-1">TimeZest will skip executing this action in the following cases:</p>
    <ul className="mb-1">
      <li>
        When there is already a contact associated with the scheduling request - for example, the contact associated
        with an existing ConnectWise PSA ticket.
      </li>
      <li>When there is already an existing contact with the client&apos;s email address in ConnectWise PSA.</li>
      <li>
        When <strong>all</strong> of the users being scheduled do not use ConnectWise PSA as their calendar.
      </li>
      <li>When no ConnectWise PSA integration is configured.</li>
    </ul>
    <p>
      When this action is included in a workflow, TimeZest will ask the user for their name, email address and company
      name during scheduling.
    </p>
  </>
);
interface Props {
  modes: { value: CreateContactMode; label: string }[];
}

const CreateContact = forwardRef<HTMLFormElement, WorkflowActionProps<ConnectWiseCreateContactAction, Props>>(
  ({ action, readOnly, modes: availableModes, saveable }, ref) => {
    const { errors: mutationErrors, loading, succeeded, submit } = useCreateContactForConnectWise();
    const [mode, setMode] = useStateFromProp(action.mode);
    const modes = availableModes.map(m => ({ ...m, disabled: readOnly }));

    const handleModeChange = (newMode: CreateContactMode): void => {
      setMode(newMode);
    };

    const { formRef, handleSubmit } = useFormSubmit(action, ref, submit, data => {
      return {
        mode: data.get('mode') as CreateContactMode,
        companyId: (data.get('psa_company_id') || '') as string,
        companyStatusId: (data.get('psa_company_status_id') || '') as string,
        companyTypeId: (data.get('psa_company_type_id') || '') as string,
        companyName: (data.get('psa_company_name') || '') as string,
      };
    });

    const errors = action.errors || mutationErrors;

    return (
      <ErrorBoundary>
        <form ref={formRef} onSubmit={handleSubmit}>
          <Action
            action={action}
            details={DETAILS}
            icon="user-b"
            summary={
              <>
                Create a <strong>contact</strong> in ConnectWise PSA.
              </>
            }
            readOnly={readOnly}
            saveable={saveable}
            saving={loading}
            succeeded={succeeded}
          >
            <FormError action={action} errors={errors} />

            <RadioButtonRow
              id={`action_${action.id.toString()}`}
              name="mode"
              label="New Contact Handling"
              value={mode || 'only_known_email'}
              options={modes}
              error={errors.mode}
              helpText={`This setting controls what TimeZest will do if an existing contact cannot be matched
                   to the user via their email address.`}
              onChange={handleModeChange}
            />

            {mode === 'create_new' && (
              <NewContactAndCompany
                companyStatus={action.psaCompanyStatusId || null}
                companyType={action.psaCompanyTypeId || null}
                errors={errors}
                readOnly={readOnly}
              />
            )}
            {mode === 'use_catchall' && (
              <CatchAllCompanySelect
                companyId={action.psaCompanyId || null}
                companyName={action.psaCompanyName || null}
                error={errors.psa_company_id}
                readOnly={readOnly}
              />
            )}
          </Action>
        </form>
      </ErrorBoundary>
    );
  }
);

export default CreateContact;

interface ContactAndCompanyProps {
  companyType: string | null;
  companyStatus: string | null;
  errors: Record<string, string>;
  readOnly: boolean;
}

const NewContactAndCompany: FC<ContactAndCompanyProps> = ({
  companyType,
  companyStatus: initialCompanyStatus,
  errors,
  readOnly,
}) => {
  const { templateMode } = useContext(WorkflowActionContext);

  const queryResult = useGetCompanyPropsQuery({ skip: templateMode });
  const error = queryResult.error ? convertError(queryResult.error) : undefined;
  const [companyStatus, setCompanyStatus] = useStateFromProp(initialCompanyStatus, status => status || '');

  const selectedStatus = companyStatus
    ? queryResult.data?.connectWise.companyStatuses.find(s => s.id.toString() === companyStatus.toString())
    : undefined;
  const warning = selectedStatus?.disallowSavingFlag;

  const handleStatusChange = (newStatus: string): void => {
    setCompanyStatus(newStatus);
  };

  if (error) {
    return (
      <RowError
        label="Company Type and Status"
        helpText="This setting controls the company type and status which TimeZest will use when creating a new company record in ConnectWise PSA."
        width={6}
        type={error.type}
        unauthorizedError={
          <>
            TimeZest will use the default values for company type and status configured in ConnectWise PSA, as it was
            unable to retrieve available options from there. This is because the API user is missing the necessary
            permissions.
          </>
        }
        unauthorizedRemediation={
          <>
            To fix this, ensure the API user has access to the correct setup tables, as&nbsp;
            <a
              href="https://help.timezest.com/en/articles/3158493-creating-a-custom-security-role-in-connectwise-manage-for-timezest"
              target="_blank"
              rel="noreferrer"
            >
              detailed here
            </a>
            .
          </>
        }
        error="TimeZest encountered an unexpected error. Please reload the page and try again"
      />
    );
  }

  return (
    <>
      <SelectRowFromGraphQL
        name="psa_company_type_id"
        label="Company Type"
        helpText="This setting controls the company type which TimeZest will use when creating a new company record in ConnectWise PSA."
        field="connectWise.companyTypes"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Use default company type configured in ConnectWise PSA."
        error={errors.psa_company_type_id}
        readOnly={!!errors.psa_company_type_id || readOnly || false}
        templateMode={templateMode}
        value={companyType || undefined}
        queryResult={queryResult}
      />

      <SelectRowFromGraphQL
        name="psa_company_status_id"
        label="Company Status"
        helpText="This setting controls the company status which TimeZest will use when creating a new company record in ConnectWise PSA."
        field="connectWise.companyStatuses"
        loadingMessage="Loading from ConnectWise PSA..."
        prompt="Use default company status configured in ConnectWise PSA."
        error={errors.psa_company_status_id}
        readOnly={!!errors.psa_company_status_id || readOnly || false}
        templateMode={templateMode}
        value={companyStatus || undefined}
        warning={
          warning
            ? `
          This company status is configured to disallow the creation of tickets.
          Selecting this status will prevent TimeZest from creating tickets for them.
        `
            : ''
        }
        queryResult={queryResult}
        onChange={handleStatusChange}
      />
    </>
  );
};

interface CatchAllCompanySelectProps {
  companyId: string | null;
  companyName: string | null;
  error: string | null;
  readOnly: boolean;
}

const CatchAllCompanySelect: FC<CatchAllCompanySelectProps> = ({ companyName, companyId, error, readOnly }) => {
  const [selectedCompanyName, setSelectedCompanyName] = useStateFromProp(companyName, companyName => companyName || '');

  const handleCompanyNameChange = (_, newCompanyName: string): void => {
    setSelectedCompanyName(newCompanyName);
  };

  return (
    <Row label="Catch-all Company">
      <input type="hidden" name="psa_company_name" value={selectedCompanyName || ''} />

      <CompanySearch
        name="psa_company_id"
        value={companyId ? parseInt(companyId, 10) : null}
        displayName={selectedCompanyName || ''}
        error={error || ''}
        readOnly={readOnly}
        onChange={handleCompanyNameChange}
      />
    </Row>
  );
};

import type { FC } from 'react';

import UsersList from './UsersList';

import type User from '@models/User';

interface Props {
  usersWithValues: (User & { value: string })[];
  usersWithoutValues: User[];
}

const Users: FC<Props> = ({ usersWithValues, usersWithoutValues }) => {
  return (
    <>
      <div className="row">
        <div className="col-2 fw-bold">Users</div>
        <div className="col-5 fw-bold">Users with values for this Attribute:</div>
        <div className="col-5 fw-bold">Users without values set:</div>
      </div>

      <div className="row mb-0 mt-2">
        <div className="col-2">&nbsp;</div>
        <div className="col-5">
          <UsersList users={usersWithValues} />
        </div>
        <div className="col-5">
          <UsersList users={usersWithoutValues} />
        </div>
      </div>
    </>
  );
};

export default Users;

import type { FC } from 'react';

import Row from '@/ui/Row';
import TextInputRow from '@/ui/TextInputRow';
import PasswordInputRow from '@/ui/PasswordInputRow';
import Form from '@/Form';

import TicketStatuses from './TicketStatuses';

import type { TicketStatus } from '@models/TicketStatus';

import type { IntegrationSettings } from '..';

interface Props {
  integration: IntegrationSettings;
  ticketStatuses: TicketStatus[];
}

const Settings: FC<Props> = ({ integration, ticketStatuses }) => {
  return (
    <>
      <p>These are the credentials that TimeZest will use to connect to your Autotask account.</p>

      <Form url="/settings/integrations/autotask" method="patch">
        <TextInputRow
          name="autotask_integration[username]"
          label="Username (key)"
          width={6}
          value={integration.username}
          error={integration.errors.username}
        />

        <PasswordInputRow
          name="autotask_integration[password]"
          label="Password (secret)"
          width={6}
          value={integration.password}
          error={integration.errors.password}
        />

        {integration.connected && (
          <>
            <hr />
            <TicketStatuses
              afterCancelPsaStatusId={integration.afterCancelPsaStatusId}
              afterCreatePsaStatusId={integration.afterCreatePsaStatusId}
              afterSchedulePsaStatusId={integration.afterSchedulePsaStatusId}
              noResponsePsaStatusId={integration.noResponsePsaStatusId}
              ticketStatuses={ticketStatuses}
            />
          </>
        )}

        <Row label="">
          <input className="btn btn-primary" type="submit" value="Save Changes" data-disable-with="Saving" />
        </Row>
      </Form>
    </>
  );
};

export default Settings;

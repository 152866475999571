import type { FC } from 'react';
import { useContext, useMemo, useState } from 'react';

import orderBy from 'lodash/fp/orderBy';
import some from 'lodash/fp/some';
import uniq from 'lodash/fp/uniq';
import classNames from 'classnames';

import { DirtyContext } from '@/DirtyContext';
import CopyToClipboard from '@/CopyToClipboard';
import AccountContext from '@/AccountContext';

import type AppointmentTypeModel from '@models/AppointmentType';
import useDisplayDuration from '@shared/hooks/useDisplayDuration';
import useStateFromProp from '@shared/hooks/useStateFromProp';

interface Props {
  appointmentTypes: AppointmentTypeModel[];
  appointmentTypeIds: number[];
  enabled: boolean;
  error: string;
  resourceSlug: string;
  page: 'team' | 'user';
}

const PersonalTeamPage: FC<Props> = ({
  appointmentTypes,
  appointmentTypeIds: initialAppointmentTypeIds,
  enabled: initialEnabled,
  error,
  resourceSlug,
  page,
}) => {
  const displayDuration = useDisplayDuration();
  const { host } = useContext(AccountContext);

  const pageType = page === 'user' ? 'personal' : 'team';
  const url = `${host}/${resourceSlug}`;
  const appointmentTypeIdsDep = initialAppointmentTypeIds.join(',');
  const appointmentTypeIds = useMemo(() => appointmentTypeIdsDep.split(','), [appointmentTypeIdsDep]);

  const [enabled, setEnabled] = useState(initialEnabled);
  const [selectedAppointmentTypeIds, setSelectedAppointmentTypeIds] = useStateFromProp(appointmentTypeIds);

  const { handleDirty } = useContext(DirtyContext);

  const sortedAppointments = orderBy(['internalName', 'durationMins'], ['asc', 'asc'], appointmentTypes);
  const hasDisabledSwitch = some(['slug', null], appointmentTypes);

  const handleToggleEnablePersonalPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleDirty) handleDirty();

    setEnabled(e.target.checked);
  };

  const handleSelectAppointmentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleDirty) handleDirty();

    if (e.target.checked) {
      setSelectedAppointmentTypeIds(uniq([...selectedAppointmentTypeIds, e.target.value]));
    } else {
      setSelectedAppointmentTypeIds(selectedAppointmentTypeIds.filter(id => id !== e.target.value));
    }
  };

  return (
    <>
      <div className={classNames('form-check form-switch', enabled ? 'mb-3' : 'mb-4')}>
        <input
          className="form-check-input me-1"
          type="checkbox"
          role="switch"
          id="enablePersonalPage"
          checked={enabled}
          onChange={handleToggleEnablePersonalPage}
        />
        <label className="form-check-label mb-1" htmlFor="enablePersonalPage">
          Turn on the {pageType} page at{' '}
          <a href={url} target="_blank">
            {url}
          </a>
          &nbsp;&nbsp;
          <CopyToClipboard text={url} />
          for this {page}.
        </label>
        <p className="small text-muted mb-0">
          A {pageType} page allows the end user to choose, from a list of appointment types you configure, which
          appointment type they&apos;d like to book.
        </p>
      </div>

      {enabled && (
        <div style={{ marginLeft: '36px' }}>
          <p className="text-muted mb-3">Select the appointment types to show on the {pageType} page:</p>
          <div className={classNames('grid', error || hasDisabledSwitch ? 'mb-3' : 'mb-4')}>
            {sortedAppointments.map(at => (
              <div key={at.id} className="g-col-4 form-check form-switch d-flex gap-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  value={at.id.toString()}
                  id={at.id.toString()}
                  disabled={!at.slug}
                  checked={!!at.slug && selectedAppointmentTypeIds.includes(at.id.toString())}
                  onChange={handleSelectAppointmentType}
                />
                <div>
                  <label
                    className={classNames('form-check-label mb-1', { 'text-black-50': !at.slug })}
                    htmlFor={at.id.toString()}
                  >
                    {at.internalName}
                  </label>
                  <div className={classNames('small', !at.slug ? 'text-black-50' : 'text-muted')}>
                    {displayDuration(at.durationMins)}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {error && <div className="invalid-feedback d-block mb-4">{error}</div>}

          {hasDisabledSwitch && (
            <p className="text-primary mb-4">
              To enable an appointment type for inclusion in a {pageType} page, ensure it has a URL slug set on its
              Appointment Details page.
            </p>
          )}
        </div>
      )}

      <input type="hidden" name={`${page}[resource_page_enabled]`} value={enabled.toString()} />
      {enabled ? (
        selectedAppointmentTypeIds.map((id, i) => (
          <input key={i} type="hidden" name={`${page}[appointment_type_ids][]`} value={id} />
        ))
      ) : (
        <input type="hidden" name={`${page}[appointment_type_ids][]`} value="" />
      )}
    </>
  );
};

export default PersonalTeamPage;

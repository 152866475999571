import { useState, type FC } from 'react';

import classNames from 'classnames';

import type { MailSendingConfigurationProps } from './MailSendingConfiguration';

import TextInput from '@shared/ui/Inputs/TextInput';
import Select from '@shared/ui/Select';
import type DomainModel from '@models/Domain';

interface Props {
  domains: DomainModel[];
  fromLocalPartError: string | null;
}

const DomainFields: FC<Props & Omit<MailSendingConfigurationProps, 'mailSendingMode'>> = ({
  domains,
  emailDomainId: initialEmailDomainId,
  fromLocalPart: initialFromLocalPart,
  fromLocalPartError,
}) => {
  const sortedDomains = domains.sort((domainA, domainB) => domainA.name.localeCompare(domainB.name));

  const [emailDomainId, setEmailDomainId] = useState(initialEmailDomainId);
  const [fromLocalPart, setFromLocalPart] = useState(initialFromLocalPart);

  const handleFromLocalPartChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFromLocalPart(e.target.value);
  };

  const handleEmailDomainIdChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setEmailDomainId(parseInt(e.target.value, 10));
  };

  return (
    <div className="row mt-3">
      <div className="col-sm-2">
        <label className="col-form-label fw-bold" htmlFor="MailSendingModeFromAddress">
          From Address
        </label>
      </div>
      <div className="col-sm-6">
        <div className="input-group">
          <TextInput
            id="MailSendingModeFromAddress"
            className={classNames({ 'is-invalid': fromLocalPartError })}
            type="text"
            name="account[from_local_part]"
            value={fromLocalPart || ''}
            onChange={handleFromLocalPartChange}
          />
          <span className="input-group-text">@</span>
          <Select
            name="account[email_domain_id]"
            value={emailDomainId || ''}
            options={sortedDomains.map(d => ({
              name: d.name,
              value: d.id,
            }))}
            onChange={handleEmailDomainIdChange}
          />
        </div>
        <div className="invalid-feedback d-block">{fromLocalPartError}</div>
      </div>
      <div className="col-sm-4">
        <div className="small text-muted">
          Emails sent by TimeZest will be sent with this as the &quot;from&quot; address. It must be an email address
          from a verified domain.
        </div>
      </div>
    </div>
  );
};

export default DomainFields;

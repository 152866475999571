import type { FC } from 'react';

interface Props {
  icon: string;
}

const ExpanderIcon: FC<Props> = ({ icon }) => {
  return (
    <div className="text-muted fs-2 me-3">
      <i className={`budicon budicon-${icon}`} />
    </div>
  );
};

export default ExpanderIcon;

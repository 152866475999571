import type { FC } from 'react';

import asScreen from '@/Screen';
import AuditTrail from '@/ui/AuditTrail';
import Flash from '@/Flash';

import BasicDetails from '../BasicDetails';
import Header from '../Header';

import type Event from '@models/Event';

type DateAndTime = { time: string; date: string; timezone: string };
export type Time = { hour: number; minute: number; second: number };

type Resource = { name: string; role: string };
type ScheduledTime = DateAndTime;
type User = { name: string; email: string };
type LimitModel = {
  earliestDate: string | null;
  earliestTime: Time | null;
  earliestDateTime: string;
  latestDate: string | null;
  latestTime: Time | null;
  latestDateTime: string;
  timeClosed: boolean;
};

type Cancellation = { cancelledBy: string; cancelledAt: DateAndTime; cancellationReason: string };

export type SchedulingRequest = {
  appointmentTypeName: string;
  cancellation: Cancellation | null;
  clientGuestsList: string[] | null;
  client: User | null;
  companyName: string | null;
  durationMins: number;
  durationOverridden: boolean;
  events: Event[];
  issueDescription: string;
  limit: LimitModel;
  resources: Resource[];
  scheduled: boolean;
  status: string;
  scheduledTime: ScheduledTime | null;
  scheduledUsers: User[] | null;
  technicianGuestsList: string[] | null;
  timeClosed: DateAndTime | null;
  ticket: { number: string; summary: string } | null;
  token: string;
};

interface Props {
  canCancelAppointment: boolean;
  schedulingRequest: SchedulingRequest;
}

const SchedulingRequest: FC<Props> = ({ canCancelAppointment, schedulingRequest }) => {
  return (
    <>
      <Header schedulingRequest={schedulingRequest} canCancelAppointment={canCancelAppointment} />
      <Flash />
      <BasicDetails schedulingRequest={schedulingRequest} />

      <div className="row g-0">
        <div className="col-12">
          <AuditTrail events={schedulingRequest.events} />
        </div>
      </div>
    </>
  );
};

export default asScreen(SchedulingRequest);

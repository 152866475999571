import type { FC } from 'react';
import { StrictMode, useMemo, useState } from 'react';

import FontAwesomeIcon from '@/FontAwesomeIcon';
import asScreen from '@/Screen';

import ActionsForm from './ActionsForm';
import AppointmentTypes from './AppointmentTypes';
import TriggerForm from './TriggerForm';
import SubmitButton from './SubmitButton';

import VariablesContext from '@shared/VariablesContext';
import type { Trigger as TriggerModel, QuickEditActionInput } from '@graphql/generated';
import type VariableModel from '@models/Variable';

interface Props {
  actions: { type: string; params: Record<string, any> }[];
  appointmentTypes: { id: number; name: string; duration: number }[];
  availableVariables: VariableModel[];
  trigger: { name: string; type: string; params: Record<string, any> };
  url: string;
}

const QuickEdit: FC<Props> = ({ actions, appointmentTypes, availableVariables, trigger: triggerParams, url }) => {
  const [actionProps, setActionProps] = useState<QuickEditActionInput[]>([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState<number[]>([]);
  const [trigger, setTrigger] = useState({
    id: 1,
    name: triggerParams.name,
    priority: 0,
    slug: triggerParams.type,
    ...triggerParams.params,
  } as TriggerModel);
  const variables = useMemo(() => {
    return availableVariables.sort((a, b) => a.name.localeCompare(b.name));
  }, [availableVariables]);

  const handlePreview = (props: QuickEditActionInput[]) => {
    setActionProps(props);
    setPreviewMode(true);
  };

  const handleTriggerChange = (timeMins: number) => {
    setTrigger({ ...trigger, timeMins } as TriggerModel);
  };

  return (
    <StrictMode>
      <ol>
        <li>
          <AppointmentTypes
            appointmentTypes={appointmentTypes}
            disabled={previewMode}
            selectedAppointmentTypeIds={selectedAppointmentTypes}
            onChange={setSelectedAppointmentTypes}
          />
        </li>
        <li>
          <TriggerForm trigger={trigger} onChange={handleTriggerChange} />
          <div className="ActionSpacer__Main">
            <FontAwesomeIcon icon="angle-down" />
          </div>
          <VariablesContext.Provider value={{ variables }}>
            <ActionsForm actions={actions} readOnly={previewMode} onSubmit={handlePreview}>
              {validate => (
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  disabled={selectedAppointmentTypes.length === 0}
                  onClick={validate}
                >
                  Preview Changes &gt;&gt;
                </button>
              )}
            </ActionsForm>
          </VariablesContext.Provider>
          {previewMode && (
            <div className="mt-3">
              <SubmitButton
                actions={actionProps}
                appointmentTypeIds={selectedAppointmentTypes}
                trigger={trigger}
                url={url}
              />
              <button type="reset" className="btn btn-outline-secondary ms-2" onClick={() => setPreviewMode(false)}>
                Cancel
              </button>
            </div>
          )}
        </li>
      </ol>
    </StrictMode>
  );
};

export default asScreen(QuickEdit);

import type { FC } from 'react';

import type { Member } from '@models/TeamMember';
import { sentence } from '@shared/text';

interface Props {
  activatedMembers: Member[];
  unactivatedMembers: Member[];
}

const ActiveMembers: FC<Props> = ({ activatedMembers, unactivatedMembers }) => {
  if (activatedMembers.length > 0 && unactivatedMembers.length === 0) {
    return <>All {activatedMembers.length} members of this team are active in TimeZest.</>;
  }

  const names = activatedMembers.map(m => m.name);
  let text = '';

  if (names.length === 1) {
    text = `${names[0]} is activated in TimeZest`;
  } else if (names.length > 1 && names.length < 4) {
    text = sentence(names).concat(' are activated in TimeZest');
  } else {
    text = sentence(names.slice(0, 2).concat(`${names.length - 2} other members are activated in TimeZest.`));
  }

  return <>{text}</>;
};

export default ActiveMembers;

import type {
  ContentBlock,
  ContentState,
  DraftInlineStyle,
  RawDraftContentBlock,
  RawDraftInlineStyleRange,
} from 'draft-js';
import { convertFromRaw, genKey } from 'draft-js';
import size from 'lodash/size';

import type { LeafNode, LinkLeaf, Paragraph, TextLeaf, VariableLeaf } from '../../models/EmailTemplate';

// Serializes the ContentState of the draft.js editor into ordinary JS objects which
// meet the Paragraph[] type.
export const serialize: (contentState: ContentState) => Paragraph[] = contentState => {
  return contentState.getBlockMap().valueSeq().toArray().map(convertBlockToParagraph(contentState));
};

// Builds an Paragraph out of an immutable ContentBlock
const convertBlockToParagraph: (contentState: ContentState) => (block: ContentBlock, index: number) => Paragraph =
  contentState => (block, index) => {
    const text = block.getText();
    const characterList = block.getCharacterList();
    const firstCharacter = characterList.first();

    if (!firstCharacter)
      return {
        type: 'paragraph',
        children: [
          {
            type: 'text' as const,
            text: '',
            bold: false,
            italic: false,
            underline: false,
            paragraphIndex: index,
          },
        ],
      };

    let currentStyle = firstCharacter.getStyle()!;
    let currentEntityKey = firstCharacter.getEntity();

    let blockStart = 0;
    let blockEnd = 0;

    return {
      type: 'paragraph',
      children: [
        ...characterList.toArray().reduce((children, characterData) => {
          const thisStyle = characterData.getStyle();
          const thisEntityKey = characterData.getEntity();

          if (thisEntityKey === currentEntityKey && thisStyle === currentStyle) {
            blockEnd = blockEnd + 1;
            return children!;
          }

          const newNode = buildNode(text, blockStart, blockEnd, currentStyle, currentEntityKey, index, contentState);

          currentStyle = thisStyle;
          currentEntityKey = thisEntityKey;

          blockStart = blockEnd;
          blockEnd = blockEnd + 1;

          return [...children, newNode];
        }, [] as LeafNode[]),
        buildNode(text, blockStart, blockEnd, currentStyle, currentEntityKey, index, contentState),
      ],
    };
  };

// eslint-disable-next-line max-params
function buildNode(
  text: string,
  blockStart: number,
  blockEnd: number,
  currentStyle: DraftInlineStyle,
  currentEntityKey: string | null,
  paragraphIndex: number,
  contentState: ContentState
): LeafNode {
  if (currentEntityKey && contentState.getEntity(currentEntityKey).getType() === '{mention') {
    return {
      type: 'variable' as const,
      variable: contentState.getEntity(currentEntityKey).getData().mention.variable,
      name: contentState.getEntity(currentEntityKey).getData().mention.name,
      bold: currentStyle.includes('BOLD'),
      italic: currentStyle.includes('ITALIC'),
      underline: currentStyle.includes('UNDERLINE'),
      paragraphIndex,
    };
  } else {
    return {
      type: 'text' as const,
      text: text.substring(blockStart, blockEnd),
      bold: currentStyle.includes('BOLD'),
      italic: currentStyle.includes('ITALIC'),
      underline: currentStyle.includes('UNDERLINE'),
      paragraphIndex,
      ...(currentEntityKey && { url: contentState.getEntity(currentEntityKey).getData().url }),
    };
  }
}

export const deserialize: (content: Paragraph[]) => ContentState = content => {
  let entityMap = {};
  let entityKey = 0;

  let blocks = content.map((paragraph: Paragraph): RawDraftContentBlock => {
    let text = '';
    let entityRanges: { offset: number; length: number; key: number }[] = [];
    let inlineStyleRanges: RawDraftInlineStyleRange[] = [];
    let offset = 0;

    paragraph.children.forEach(leaf => {
      if (isText(leaf)) {
        text += leaf.text;

        pushInlineStyleRanges(inlineStyleRanges, leaf, offset, leaf.text.length);

        offset += size(leaf.text);
      } else if (isVariable(leaf)) {
        entityMap[entityKey] = {
          type: '{mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              name: leaf.name,
              variable: leaf.variable,
            },
          },
        };

        entityRanges.push({ offset: offset, length: leaf.name.length, key: entityKey });
        pushInlineStyleRanges(inlineStyleRanges, leaf, offset, leaf.name.length);

        entityKey++;
        text += leaf.name;
        offset += leaf.name.length;
      } else if (isLink(leaf)) {
        entityMap[entityKey] = {
          type: 'LINK',
          mutability: 'IMMUTABLE',
          data: {
            url: leaf.url,
          },
        };

        entityRanges.push({ offset: offset, length: leaf.text.length, key: entityKey });
        pushInlineStyleRanges(inlineStyleRanges, leaf, offset, leaf.text.length);

        entityKey++;
        text += leaf.text;
        offset += leaf.text.length;
      } else {
        throw 'Unknown leaf found';
      }
    });

    let data;

    return {
      data: data || {},
      depth: 0,
      entityRanges: entityRanges,
      inlineStyleRanges: inlineStyleRanges,
      key: genKey(),
      text,
      type: 'unstyled',
    };
  });

  return convertFromRaw({ blocks, entityMap });
};

function pushInlineStyleRanges(
  inlineStyleRanges: RawDraftInlineStyleRange[],
  leaf: LeafNode,
  offset: number,
  length: number
): void {
  if (leaf.bold) {
    inlineStyleRanges.push({
      offset,
      length: length,
      style: 'BOLD',
    });
  }

  if (leaf.italic) {
    inlineStyleRanges.push({
      offset,
      length: length,
      style: 'ITALIC',
    });
  }

  if (leaf.underline) {
    inlineStyleRanges.push({
      offset,
      length: length,
      style: 'UNDERLINE',
    });
  }
}

function isVariable(leaf: LeafNode): leaf is VariableLeaf {
  return (leaf.type && leaf.type === 'variable') || false;
}

function isText(leaf: LeafNode): leaf is TextLeaf {
  return leaf.type === 'text' && !leaf.url;
}

function isLink(leaf: LeafNode): leaf is LinkLeaf {
  return leaf.type === 'text' && !!leaf.url;
}

import type { FC } from 'react';

import NewTeam from '@ui/NewTeam';

const NewServiceTeam: FC = () => {
  return (
    <NewTeam
      teamUrl={(boardId, teamId): string => `/connectwise/service_boards/${boardId}/teams/${teamId}`}
      boardLabel="Service Board"
      teamLabel="Service Team"
      createUrl="/settings/teams/service"
      type="service"
    />
  );
};

export default NewServiceTeam;

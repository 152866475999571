import type { FC } from 'react';
import { useContext, useState } from 'react';

import { DirtyContext } from '@/DirtyContext';

import AvailabilityCalendar from '../AvailabilityCalendar';
import RadioButtonRow from '../RadioButtonRow';

import type { AvailabilityBlock } from '@models/Availability';

interface Props {
  availabilityBlocks?: AvailabilityBlock[] | null | undefined;
  errors: { [attr: string]: string };
  allowsPsaAvailability: boolean;
  psaName: string;
  psaCalendarName: string;
  usesCustomAvailability: boolean;
}

const UserSchedule: FC<Props> = ({
  availabilityBlocks,
  errors,
  allowsPsaAvailability,
  psaName,
  psaCalendarName,
  usesCustomAvailability: initialUsesCustomAvailability,
}) => {
  const [usesCustomAvailability, setUsesCustomAvailability] = useState(initialUsesCustomAvailability);
  const context = useContext(DirtyContext);

  const availabilityChange = (value: string): void => {
    if (context.handleDirty) context.handleDirty();

    setUsesCustomAvailability(value === 'true');
  };

  const availabilityCalendarChange = (): void => {
    if (context.handleDirty) context.handleDirty();
  };

  return (
    <div className="mb-4">
      <RadioButtonRow
        label="Availability"
        name="availability[uses_custom_availability]"
        helpText=" "
        value={usesCustomAvailability.toString()}
        options={[
          ...(allowsPsaAvailability
            ? [
                {
                  label: (
                    <>
                      Use the default availability configured in {psaName}, in the
                      <strong>&nbsp;{psaCalendarName}&nbsp;</strong>
                      calendar.
                    </>
                  ),
                  disabled: !allowsPsaAvailability,
                  value: 'false',
                },
              ]
            : []),
          {
            label: 'Use a custom availability for TimeZest:',
            value: 'true',
            appendedElement: (
              <>
                <div className="small mt-1">Click and drag to create a new period of availability.</div>
                <div className="small mb-3">
                  Drag an existing availability period outside the border to delete it.
                  {errors['availability_blocks'] && <div className="text-danger">{errors['availability_blocks']}</div>}
                </div>
              </>
            ),
          },
        ]}
        onChange={availabilityChange}
      />
      <AvailabilityCalendar
        availabilityBlocks={availabilityBlocks}
        enabled={usesCustomAvailability}
        name="availability[availability_blocks]"
        onChange={availabilityCalendarChange}
      />
    </div>
  );
};

export default UserSchedule;

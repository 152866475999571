import type { FC, PropsWithChildren } from 'react';

interface Props {
  name: string;
}

const Permission: FC<PropsWithChildren<Props>> = ({ children, name }) => {
  return (
    <tr className="permission-matrix-table-row">
      <td className="bg-transparent ps-3">{name}</td>
      {children}
    </tr>
  );
};

export default Permission;

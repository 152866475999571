import type { FC } from 'react';
import { useContext, useState } from 'react';

import AutotaskServiceTicketOptions from '@/autotask/ServiceTicketOptions';
import ConnectWiseServiceTicketOptions from '@/connectWise/ServiceTicketOptions';
import { DirtyContext } from '@/DirtyContext';
import RadioButtonRow from '@/ui/RadioButtonRow';
import AccountContext from '@/AccountContext';

import ContactHandlingModeRow from './ContactHandlingModeRow';

import type { ShareableUrlTicketMode } from '@models/AppointmentType';
import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import SummaryRow from '@ui/SummaryRow';
import { hasIntegration, hasIntegrationCapability } from '@shared/utilities';
import type { IntegrationModel } from '@models/Integration';

interface Props {
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  dirty: boolean;
  onDetailsExpand: () => void;
}

const Config: FC<Props> = ({ appointmentType, dirty, errors, onDetailsExpand }) => {
  const { handleDirty } = useContext(DirtyContext);
  const { integrations } = useContext(AccountContext);

  const [ticketMode, setTicketMode] = useState(appointmentType.shareableUrlTicketMode);

  const handleExpandDetails = (event: React.MouseEvent) => {
    event.preventDefault();
    onDetailsExpand();
  };

  const handleTicketModeChange = (value: string) => {
    if (handleDirty) handleDirty();

    setTicketMode(value as ShareableUrlTicketMode);
  };

  const helpText = (
    <>
      <div className="mb-1">
        Shareable URLs are URLs you can share &mdash; in emails, or on your website.{' '}
        {appointmentType.shareableUrlAppointmentType === 'activity' && "They don't need a pre-existing ticket."}
      </div>
      {appointmentType.shareableUrlAppointmentType === 'appointment' && (
        <>
          To use without creating a ticket, select &apos;Create{' '}
          {hasIntegration(integrations, 'autotask') ? 'a company to-do ' : 'an activity '}
          in the scheduled users&apos; {hasIntegration(integrations, 'autotask') ? 'Autotask' : 'ConnectWise PSA'}{' '}
          calendar&apos; in the{' '}
          <strong>{hasIntegration(integrations, 'autotask') ? 'Autotask' : 'ConnectWise PSA'} Options</strong> section.
        </>
      )}
    </>
  );

  return (
    <>
      {!appointmentType.slug ? (
        <div className="row">
          <div className="col-sm-12">
            <p>
              You need to assign URL slug in the{' '}
              <button className="btn btn-link btn-inline text-decoration-none" onClick={handleExpandDetails}>
                Appointment Details
              </button>
              &nbsp;section to make this appointment type available for URL-triggered scheduling.
            </p>
          </div>
        </div>
      ) : (
        <>
          {hasIntegrationCapability(integrations, 'canAccessTicketing') && (
            <>
              <RadioButtonRow
                label="Shareable URLs"
                helpText={helpText}
                name="appointment_type[shareable_url_ticket_mode]"
                options={[
                  {
                    label: 'Only allow this Appointment Type to be scheduled via URL with an existing ticket.',
                    value: 'existing_ticket',
                  },
                  {
                    label: (
                      <>
                        Allow clients to schedule this Appointment Type via shareable URL, and create a new&nbsp;
                        <strong>service ticket</strong>.
                      </>
                    ),
                    value: 'new_ticket',
                  },
                  {
                    label: 'Allow clients to schedule this Appointment Type, but do not create a ticket.',
                    value: 'without_ticket',
                    disabled: appointmentType.shareableUrlAppointmentType === 'appointment',
                  },
                ]}
                value={ticketMode}
                onChange={handleTicketModeChange}
              />

              {ticketMode !== 'existing_ticket' && (
                <ContactHandlingModeRow appointmentType={appointmentType} dirty={dirty} errors={errors} />
              )}
            </>
          )}

          {ticketMode !== 'existing_ticket' && (
            <SummaryRow
              appointmentNames={appointmentNames(integrations, appointmentType)}
              error={errors.newEntitySummary}
              modelName="appointment_type"
              summary={appointmentType.newEntitySummary}
              summaryMode={appointmentType.newEntitySummaryMode}
            />
          )}

          {hasIntegrationCapability(integrations, 'canAccessTicketing') && (
            <>
              {ticketMode === 'new_ticket' && (
                <>
                  {hasIntegration(integrations, 'autotask') && (
                    <AutotaskServiceTicketOptions
                      categoryId={appointmentType.newTicketPsaCategoryId}
                      categoryIdAttr="new_ticket_psa_category_id"
                      categoryIdError={errors.newTicketPsaCategoryId}
                      modelName="appointment_type"
                      priorityId={appointmentType.newTicketPsaPriorityId}
                      priorityIdAttr="new_ticket_psa_priority_id"
                      priorityIdError={errors.newTicketPsaPriorityId}
                      queueId={appointmentType.newTicketPsaQueueId}
                      queueIdAttr="new_ticket_psa_queue_id"
                      queueIdError={errors.newTicketPsaQueueId}
                      readOnly={false}
                      sourceId={appointmentType.newTicketPsaSourceId}
                      sourceIdAttr="new_ticket_psa_source_id"
                      sourceIdError={errors.newTicketPsaSourceId}
                      statusId={appointmentType.newTicketPsaStatusId}
                      statusIdAttr="new_ticket_psa_status_id"
                      statusIdError={errors.newTicketPsaStatusId}
                      typeId={appointmentType.newTicketPsaTypeId}
                      typeIdAttr="new_ticket_psa_type_id"
                      typeIdError={errors.newTicketPsaTypeId}
                    />
                  )}
                  {hasIntegration(integrations, 'connect_wise') && (
                    <ConnectWiseServiceTicketOptions
                      boardId={appointmentType.newTicketPsaQueueId}
                      boardIdAttr="new_ticket_psa_queue_id"
                      boardIdError={errors.newTicketPsaQueueId}
                      modelName="appointment_type"
                      priorityId={appointmentType.newTicketPsaPriorityId}
                      priorityIdAttr="new_ticket_psa_priority_id"
                      priorityIdError={errors.newTicketPsaPriorityId}
                      readOnly={false}
                      sourceId={appointmentType.newTicketPsaSourceId}
                      sourceIdAttr="new_ticket_psa_source_id"
                      sourceIdError={errors.newTicketPsaSourceId}
                      statusId={appointmentType.newTicketPsaStatusId}
                      statusIdAttr="new_ticket_psa_status_id"
                      statusIdError={errors.newTicketPsaStatusId}
                      subTypeId={appointmentType.newTicketPsaSubTypeId}
                      subTypeIdAttr="new_ticket_psa_sub_type_id"
                      subTypeIdError={errors.newTicketPsaSubTypeId}
                      typeId={appointmentType.newTicketPsaTypeId}
                      typeIdAttr="new_ticket_psa_type_id"
                      typeIdError={errors.newTicketPsaTypeId}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

function appointmentNames(integrations: IntegrationModel[], appointmentType: AppointmentTypeModel): string[] {
  const appointment = appointmentType.shareableUrlAppointmentType === 'appointment';

  return integrations
    .map(integration => {
      switch (integration.type) {
        case 'autotask':
          return appointment ? 'Autotask service ticket' : 'Autotask company to-do';
        case 'connect_wise':
          return appointment ? 'ConnectWise PSA service ticket' : 'ConnectWise PSA activity';
        case 'office_365':
          return 'Microsoft 365 event';
        default:
          return null;
      }
    })
    .filter(name => !!name) as string[];
}

export default Config;

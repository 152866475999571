import type { FC } from 'react';
import { useRef, useState } from 'react';

import classNames from 'classnames';

import TextInput from '../Inputs/TextInput';

import Calendar from './Calendar';

import type { CalendarType, MinDetail } from '@models/Calendar';
import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  calendarType?: CalendarType;
  disabled?: boolean;
  minDate?: Date;
  name: string;
  minDetail?: MinDetail;
  value: Date | null;
  formatCalendarTitle: (date: Date, locale: string, view: string) => JSX.Element | string;
  formatDisplayDate: (date: Date) => string;
  formatWeekday: (date: Date, locale: string) => string;
  handleDateChange: (date: Date | null) => void;
}

const DateSelect: FC<Props> = ({
  calendarType,
  disabled,
  minDate: initialMinDate,
  name,
  minDetail,
  value: initialValue,
  formatCalendarTitle,
  formatDisplayDate,
  formatWeekday,
  handleDateChange,
}) => {
  const textInput = useRef<HTMLInputElement>(null);
  const calendarRef = useRefWithClickOutside<HTMLDivElement>(() => setCalendarVisible(false));

  const [calendarVisible, setCalendarVisible] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<string>(initialValue ? formatDisplayDate(initialValue) : '');
  const [value, setValue] = useStateWithCallback<Date | null>(initialValue);

  const currentValue = (): string => {
    if (!value || !isValid()) return '';

    return new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
  };

  const assignValue = (value: Date | null): void => {
    setValue(value, (value: Date | null) => {
      const valid = value && !Number.isNaN(value.valueOf());

      handleDateChange(valid ? value : null);
      textInput.current?.focus();
    });
  };

  const isValid = (): boolean => {
    if (!value) return true;

    if (Number.isNaN(value.valueOf())) return false;
    if (!initialMinDate) return true;

    const date = new Date(value.valueOf());
    const minDate = new Date(initialMinDate.valueOf());

    date.setHours(0, 0, 0, 0);
    minDate.setHours(0, 0, 0, 0);

    return date >= minDate;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    const date = value ? new Date(value) : null;

    setCalendarVisible(false);
    setDisplayValue(value);
    assignValue(date);
  };

  const handleDropdownClick = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.preventDefault();

    if (disabled) return;

    setCalendarVisible(true);
  };

  return (
    <div ref={calendarRef} className="position-relative">
      <div>
        <TextInput
          name={`${name}_date_select`}
          className={classNames({ 'is-invalid': !isValid() })}
          ref={textInput}
          disabled={disabled}
          value={disabled ? '' : displayValue}
          onChange={handleChange}
        />

        <div
          className="position-absolute"
          style={{ top: '8px', right: '14px' }}
          role="button"
          tabIndex={0}
          onClick={handleDropdownClick}
          onKeyDown={handleDropdownClick}
        >
          <FontAwesomeIcon icon="calendar-days" height={15} className="date-select-calendar-icon" type="button" />
        </div>
      </div>
      <input type="hidden" name={name} disabled={disabled} value={currentValue()} />
      {calendarVisible && (
        <Calendar
          calendarType={calendarType}
          minDate={initialMinDate}
          minDetail={minDetail}
          value={value}
          formatCalendarTitle={formatCalendarTitle}
          formatDisplayDate={formatDisplayDate}
          formatWeekday={formatWeekday}
          setCalendarVisible={setCalendarVisible}
          setDisplayValue={setDisplayValue}
          setValue={assignValue}
          isValid={isValid}
        />
      )}
    </div>
  );
};

export default DateSelect;

import type { FC } from 'react';
import { useState } from 'react';

import { Pagination } from '@/ui/Pagination';

import NoSchedulingRequests from './NoSchedulingRequests';
import SchedulingRequestsList from './SchedulingRequestsList';

import { ErrorMessage } from '@ui/ErrorMessage';
import type { SchedulingRequest } from '@graphql/generated';
import { useGetSchedulingRequestsTqlQuery } from '@graphql/generated';

type Props = {
  tqlString: string;
};

const TqlSchedulingRequests: FC<Props> = ({ tqlString }) => {
  const [startingAfter, setStartingAfter] = useState('');
  const [endingBefore, setEndingBefore] = useState('');

  const {
    data,
    error,
    previousData: prevData,
    loading: isRequestsLoading,
  } = useGetSchedulingRequestsTqlQuery({
    fetchPolicy: 'no-cache',
    variables: { filter: tqlString, startingAfter, endingBefore },
  });

  const requestsData = (data || prevData)?.timezest.schedulingRequestsTql;

  if (!requestsData) return null;

  const { nodes: requests, prevPage, nextPage } = requestsData;
  const hasPagination = prevPage || nextPage;

  if (isRequestsLoading && !prevData)
    return (
      <div className="d-flex mb-4">
        <h6 className="m-auto">Loading scheduling requests...</h6>
      </div>
    );

  if (error) {
    return (
      <ErrorMessage>Something went wrong while retrieving scheduling requests. (Error: {error.message})</ErrorMessage>
    );
  }

  if (requests.length === 0) {
    return <NoSchedulingRequests filtering={!!tqlString} />;
  }

  return (
    <div className="d-flex mb-4">
      <div className="w-100 py-3">
        <SchedulingRequestsList hasPagination={!!hasPagination} requests={requestsData.nodes as SchedulingRequest[]} />

        {hasPagination ? (
          <Pagination
            prevPage={JSON.parse(prevPage || '{}').ending_before}
            nextPage={JSON.parse(nextPage || '{}').starting_after}
            onNextPage={setStartingAfter}
            onPreviousPage={setEndingBefore}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TqlSchedulingRequests;

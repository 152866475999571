import { useContext, type FC } from 'react';

import PlanContext from '@/PlanContext';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  newPlanUrlBasedScheduling: boolean;
}

const UrlBasedScheduling: FC<Props> = ({ newPlanUrlBasedScheduling }) => {
  const { allowsUrlBasedScheduling: currentPlanUrlBasedScheduling } = useContext(PlanContext);

  if (!newPlanUrlBasedScheduling && currentPlanUrlBasedScheduling) {
    return (
      <div className="text-danger">
        <FontAwesomeIcon icon="times" />
        &nbsp; You&apos;ll be able to use URL-based scheduling to give clients new ways to schedule appointments.
      </div>
    );
  }

  if (newPlanUrlBasedScheduling && !currentPlanUrlBasedScheduling) {
    return (
      <div className="text-success">
        <FontAwesomeIcon icon="check" />
        &nbsp; You&apos;ll no longer be able to URL-based scheduling, and existing URLs for scheduling will no longer
        function.
      </div>
    );
  }
};

export default UrlBasedScheduling;

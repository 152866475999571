const LENGTH = 10;
const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const CHARACTERS_LENGTH = CHARACTERS.length;

export const generateId = () => {
  let result = '';

  for (let i = 0; i < LENGTH; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS_LENGTH));
  }

  return result;
};

export const extractNumber = (value: string, unit: string | undefined): string => {
  return unit ? value.slice(0, value.indexOf(unit)) : value;
};

export const updateValueWithUnit = (value: string, unit: string | undefined): string => {
  return value ? `${value}${unit || ''}` : '';
};
